//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// ParseFeedback is a component for displaying parse feedback on text and procedures.
//

// 3rd party libraries
import React, { useMemo, useEffect, useState } from 'react';
import _debounce from 'lodash/fp/debounce';
import { useLazyQuery } from '@apollo/client';
import { Modal, Typography } from 'antd';
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined,
  WarningTwoTone
} from '@ant-design/icons';

// Local imports
import AppUtil from '@/utils/AppUtil';
import GraphQLErrors from '@/components/GraphQLErrors';
import AppConstants from '@/utils/AppConstants';

const { Text } = Typography;

// eslint-disable-next-line no-shadow
enum ParseResult {
  PASS = 'pass',
  FAIL = 'fail',
  UNDEFINED = 'undefined'
}

interface IProps {
  statement: string;
  scopeCode: string;
  language: string;
  departmentId: string;
  parseCallback?: (status: ParseResult) => void;
}

// Component implementation
function ParseFeedback({
  statement,
  scopeCode,
  language,
  departmentId,
  parseCallback = (_s) => {}
}: IProps) {
  const [parse, parseProps] = useLazyQuery(AppConstants.APIS.PARSE());
  const [parseResult, setParseResult] = useState<ParseResult>(
    ParseResult.UNDEFINED
  );

  const [modalOpen, setModalOpen] = useState(false);

  const maybeSetParseResult = (result: ParseResult) => {
    if (result !== parseResult) {
      setParseResult(result);
    }
  };

  const getErrorContent = (parseProps: any) => {
    const errorObj = AppUtil.safeParseJSON(
      parseProps.data.parseAlangText.error
    );
    let error = <span>No additional details</span>;
    if (errorObj?.parse_error?.user_error) {
      const parseError = errorObj.parse_error;
      error = parseError.user_error;
      if (
        parseError.text &&
        parseError.start_position !== null &&
        parseError.end_position !== null
      ) {
        const { text } = parseError;
        const s = parseError.start_position;
        const e = parseError.end_position;
        const before = text.slice(0, s);
        const during = text.slice(s, e);
        const after = text.slice(e, text.length);
        error = (
          <>
            <Typography.Title level={4}>
              Syntactically invalid input
            </Typography.Title>
            <Text type="danger">{error}</Text>
            <Typography.Paragraph>
              <blockquote>
                <span
                  style={{
                    whiteSpace: 'pre-line'
                  }}
                >
                  {before}
                  <Text mark>{during}</Text>
                </span>
                {after.split('\n').map((slug: string) => (
                  <span key={slug}>
                    {slug}
                    <br />
                  </span>
                ))}
              </blockquote>
            </Typography.Paragraph>
          </>
        );
      }
    }

    return error;
  };

  const debouncedChangeHandler = useMemo(
    () =>
      _debounce(500)((st: string, sc: string, lang: string) => {
        if (st.trim() || (st.trim() && sc.trim())) {
          parse({
            variables: {
              statement: st,
              scopeCode: sc || '',
              language: lang,
              departmentId
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only'
          });
        }
      }),
    []
  );

  useEffect(() => {
    debouncedChangeHandler(statement, scopeCode, language);
    return () => debouncedChangeHandler.cancel();
  }, [statement, scopeCode, language]);

  useEffect(() => {
    parseCallback(parseResult);
  }, [parseResult]);

  let parseStatus = <span key="empty_" />;
  if (parseProps.loading) {
    parseStatus = <LoadingOutlined data-cy="parse-loading" />;
  } else if (parseProps.error) {
    console.error('parse error', parseProps.error);
    parseStatus = (
      <div onClick={() => setModalOpen(true)} style={{ cursor: 'pointer' }}>
        <WarningTwoTone twoToneColor="#faad14" />
      </div>
    );
    maybeSetParseResult(ParseResult.UNDEFINED);
  } else if (parseProps.data) {
    if (parseProps.data.parseAlangText.error) {
      parseStatus = (
        // <Popover title="Syntactically invalid input" content={error}>
        <div onClick={() => setModalOpen(true)} style={{ cursor: 'pointer' }}>
          <ExclamationCircleTwoTone twoToneColor="#ec7f82" />
        </div>
        // </Popover>
      );
      maybeSetParseResult(ParseResult.FAIL);
    } else {
      parseStatus = (
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          data-cy={`parse-${ParseResult.PASS}`}
        />
      );
      maybeSetParseResult(ParseResult.PASS);
    }
  }
  return (
    <>
      <span className="parse-feedback" style={{ fontSize: '19px' }}>
        {parseStatus}
      </span>
      <Modal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={[]}
        className="parse-feedback-modal"
      >
        {parseProps.error && (
          <>
            <Typography.Title level={4}>Error verifying input</Typography.Title>
            <GraphQLErrors errors={parseProps.error} data-cy="parse-error" />
          </>
        )}
        {parseProps?.data?.parseAlangText?.error
          ? getErrorContent(parseProps)
          : null}
      </Modal>
    </>
  );
}

export { ParseFeedback, ParseResult };
