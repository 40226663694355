import { Button, Divider, Form, Modal, Space } from 'antd';
import React from 'react';
import './ScratchStepPopup.less';

interface IProps {
  onClick: () => void;
  onClose: () => void;
}

function ScratchStepPopup(props: IProps) {
  const { onClick, onClose } = props;

  return (
    <Modal
      open
      centered
      onCancel={onClose}
      className="scratch-step-popup"
      width="30%"
      closable
      footer={null}
    >
      <div className="scratch-step-form">
        <div className="heading">Scratch Step</div>
        <Form
          onFinish={() => {
            onClick();
            onClose();
          }}
          layout="vertical"
        >
          <Form.Item>Are you sure you want to scratch step?</Form.Item>
          <Form.Item className="actions">
            <Divider />
            <Space align="end" direction="horizontal">
              <Button key="back" onClick={onClose}>
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                data-cy="scratch-step-submit"
                htmlType="submit"
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default ScratchStepPopup;
