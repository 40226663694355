export const AnalyticsEvents = {
  PLAYGROUND: {
    NewPGNavigation: 'NewPGNavigation',
    NewPGHeader: 'NewPGHeader',
    PGHistoryButtonOpen: 'PGHistoryButtonOpen',
    PGHistoryButtonClose: 'PGHistoryButtonClose',
    PGKonciergeButtonOpen: 'PGKonciergeButtonOpen',
    PGKonciergeButtonClose: 'PGKonciergeButtonClose'
  }
};
