// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-prompt input {
  margin-top: 10px;
  width: 100%;
}
.app-prompt .help-text {
  color: #6f787e;
  font-size: 12px;
}
.app-prompt .ant-modal-confirm-btns {
  margin-top: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/utils/AppDialogs.less"],"names":[],"mappings":"AAQA;EAEI,gBAAA;EACA,WAAA;AARJ;AAKA;EAOI,cAAA;EACA,eAAA;AATJ;AACA;EAYI,2BAAA;AAVJ","sourcesContent":["//\n// Copyright (C) - Kognitos, Inc. All rights reserved\n//\n// CSS for AppDialogs\n//\n\n@import '@css/variables.less';\n\n.app-prompt {\n  input {\n    margin-top: 10px;\n    width: 100%;\n  }\n\n  .help-text {\n    color: @color-grey-1;\n    font-size: @fontSizeSmall;\n  }\n\n  .ant-modal-confirm-btns {\n    margin-top: 10px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
