// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scratch-step-form {
  max-width: 500px;
  margin: 0 auto;
  background-color: white;
}
.scratch-step-form .heading {
  margin-bottom: 16px;
}
.scratch-step-form .actions {
  margin-bottom: 12px;
  margin-top: -16px;
}
.scratch-step-form .actions .ant-space {
  display: flex;
  justify-content: end;
}
.scratch-step-form .ant-divider-horizontal {
  margin: 16px 0;
}
.scratch-step-popup {
  height: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/popups/ScratchStepPopup.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;EACA,uBAAA;AACF;AAJA;EAMI,mBAAA;AACJ;AAPA;EAUI,mBAAA;EACA,iBAAA;AAAJ;AACI;EACE,aAAA;EACA,oBAAA;AACN;AAfA;EAmBI,cAAA;AADJ;AAKA;EACE,mBAAA;AAHF","sourcesContent":[".scratch-step-form {\n  max-width: 500px;\n  margin: 0 auto;\n  background-color: white;\n\n  .heading {\n    margin-bottom: 16px;\n  }\n\n  .actions {\n    margin-bottom: 12px;\n    margin-top: -16px;\n    & .ant-space {\n      display: flex;\n      justify-content: end;\n    }\n  }\n\n  .ant-divider-horizontal {\n    margin: 16px 0;\n  }\n}\n\n.scratch-step-popup {\n  height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
