// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes inline-loader {
  0% {
    width: 50%;
    left: 0;
  }
  50% {
    width: 50%;
    left: 50%;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
.inline-infinite-loader {
  position: relative;
}
.inline-infinite-loader .loader {
  height: 2px;
  background: #07a6f3;
  position: absolute;
  bottom: 0;
  animation: inline-loader 1s infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/InlineInfiniteLoader.less"],"names":[],"mappings":"AAEA;EACE;IACE,UAAA;IACA,OAAA;EADF;EAGA;IACE,UAAA;IACA,SAAA;EADF;EAGA;IACE,QAAA;IACA,UAAA;EADF;AACF;AAIA;EACE,kBAAA;AAFF;AAIE;EACE,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,oCAAA;AAFJ","sourcesContent":["@import '@css/variables.less';\n\n@keyframes inline-loader {\n  0% {\n    width: 50%;\n    left: 0;\n  }\n  50% {\n    width: 50%;\n    left: 50%;\n  }\n  100% {\n    width: 0;\n    left: 100%;\n  }\n}\n\n.inline-infinite-loader {\n  position: relative;\n\n  & .loader {\n    height: 2px;\n    background: #07a6f3;\n    position: absolute;\n    bottom: 0;\n    animation: inline-loader 1s infinite;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
