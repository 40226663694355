/* eslint-disable no-underscore-dangle */
import {
  Button,
  Col,
  Divider,
  Layout,
  Modal,
  Row,
  Spin,
  Tooltip,
  Typography,
  message
} from 'antd';
import classNames from 'classnames';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { IUploadedResponse } from '@/hooks/useS3';
import {
  deleteTemplateIdForRequest,
  getTemplateIdForRequest
} from '@/pages/Playgrounds/templateUtils';
import templates from '@/pages/Playgrounds/templates';
import { currentUserDetailsVar } from '@/graphql/cache/user';
import {
  Request as RequestInterface,
  Worker,
  RequestQuestion as RequestQuestionInterface,
  Department,
  ReplaceStepAction,
  Procedure,
  SetWorkerArchivedMutation,
  SetWorkerArchivedMutationVariables
} from '../../generated/API';
import RequestQuestion from './RequestQuestion';
import './Request.less';
import { ampTrackEvent } from '../../analytics';
import {
  getQuestionAnswer,
  isQuestionAnswered,
  isRequestHandled,
  verifyQuestionValues
} from '../../utils/request';
import {
  IQuestionConfig,
  IQuestionValues,
  QuestionInputType
} from './interface';
import AppConstants from '../../utils/AppConstants';
import AppUtil from '../../utils/AppUtil';
import MarkdownRenderer from '../MarkdownRenderer';
import { ARCHIVE_WORKER } from '../../graphql/worker';
import { useAppDispatch } from '../../stores/hooks';
import { resetAnnotations } from '../../stores/slices/annotation';
import { ISentence } from '../../pages/PlaygroundV3/components/Editor/editorInterface';
import useRequestMethods from '../../hooks/useRequestMethods';

interface IRequestsProps {
  request: RequestInterface;
  worker: Worker;
  department?: Department;
  procedure?: Procedure;
  sentence?: ISentence;
  activeDocumentToken?: string;
  hideAnsweredQuestion: boolean;
  questionConfig: IQuestionConfig;
  dispatchContextPath: boolean;
  onSuccess?: () => void;
  suggestions?: {
    handleClick: (suggestion: string[]) => void;
  };
  archive?: {
    onSuccess?: (archiveStatus: boolean) => void;
  };
}

function Request(props: IRequestsProps) {
  const {
    request,
    worker,
    department,
    procedure,
    sentence,
    activeDocumentToken,
    hideAnsweredQuestion,
    questionConfig,
    dispatchContextPath,
    onSuccess,
    suggestions,
    archive
  } = props;
  const { questions: _questions } = request;
  const { email } = useReactiveVar(currentUserDetailsVar);

  const dispatch = useAppDispatch();

  const [questionValuesMap, setQuestionValuesMap] = useState<
    Record<string, IQuestionValues>
  >({});
  const [loadingQuestionIds, setLoadingQuestionIds] = useState(new Set());

  const { answerQuestion } = useRequestMethods(request);

  const [setWorkerArchived] = useMutation<
    SetWorkerArchivedMutation,
    SetWorkerArchivedMutationVariables
  >(ARCHIVE_WORKER);

  const pendingQuestions = _questions?.filter((question) => {
    return !isQuestionAnswered(question, request.answers!);
  });
  const questions = hideAnsweredQuestion ? pendingQuestions : _questions;

  const dispatchHighlightStepEvent = () => {
    const contextPath = request.contextPath!.map(
      (path: any) => `${path.ctxId}:${path.sentenceId}`
    );
    const event = new CustomEvent(AppConstants.EVENT_NAMES.STEP_HIGHLIGH_ADD, {
      detail: {
        contextPath,
        questionStep: AppUtil.getStepNodeFromQuestion(
          request.contextPath,
          request.contextId
        )
      }
    });
    window.dispatchEvent(event);
  };

  const dispatchRemoveHighlightStepEvent = () => {
    const event = new CustomEvent(
      AppConstants.EVENT_NAMES.STEP_HIGHLIGH_REMOVE,
      {
        detail: {
          questionStep: AppUtil.getStepNodeFromQuestion(
            request.contextPath,
            request.contextId
          )
        }
      }
    );
    window.dispatchEvent(event);
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      if (dispatchContextPath && request.contextPath?.length) {
        dispatchHighlightStepEvent();
      }
    }, 1000);

    return () => {
      if (dispatchContextPath && request.contextPath?.length) {
        dispatchRemoveHighlightStepEvent();
      }
    };
  }, [dispatchContextPath]);

  useEffect(() => {
    return () => {
      dispatch(resetAnnotations());
    };
  }, [request.id]);

  const resolveQuestion = async (
    question: RequestQuestionInterface,
    values: IQuestionValues
  ) => {
    setLoadingQuestionIds((prevState) => prevState.add(question.id));
    const newQuestionValuesMap = { ...questionValuesMap };
    delete newQuestionValuesMap[question.id];
    setQuestionValuesMap(newQuestionValuesMap);

    answerQuestion(question, values, values.remember || false)
      .then(() => {
        if (onSuccess) {
          onSuccess();
          deleteTemplateIdForRequest(email!);
        }
        loadingQuestionIds.delete(question.id);
      })
      .catch(() => {
        loadingQuestionIds.delete(question.id);
      });
  };

  useEffect(() => {
    const selfServeSampleFileHandler = (data: {
      detail: {
        question: RequestQuestionInterface;
        uploadResult: IUploadedResponse[];
      };
    }) => {
      resolveQuestion(data.detail.question, {
        inputType: QuestionInputType.VALUE_UPLOAD,
        remember: false,
        upload: data.detail.uploadResult
      });
    };
    document.addEventListener(
      AppConstants.SELF_SERVE.FILE_UPLOAD_EVENT,
      selfServeSampleFileHandler as any
    );

    return () =>
      document.removeEventListener(
        AppConstants.SELF_SERVE.FILE_UPLOAD_EVENT,
        selfServeSampleFileHandler as any
      );
  }, []);

  const onSubmit = (e: React.MouseEvent) => {
    e.stopPropagation();
    ampTrackEvent('ClickQuestionSubmit');
    Object.keys(questionValuesMap).forEach((key) => {
      if (!questionValuesMap[key].inputType) {
        questionValuesMap[key].inputType = QuestionInputType.NO_VALUE;
      }
    });
    return Object.keys(questionValuesMap)
      .filter((id) => verifyQuestionValues(questionValuesMap[id]))
      .forEach((id) => {
        resolveQuestion(
          questions!.find((q) => q.id === id)!,
          questionValuesMap[id]
        );
      });
  };

  const onValuesChange = (
    question: RequestQuestionInterface,
    allValues: IQuestionValues
  ) => {
    if (
      allValues.inputType === QuestionInputType.VALUE_UPLOAD &&
      verifyQuestionValues(allValues)
    ) {
      resolveQuestion(question, allValues);
    } else {
      setQuestionValuesMap((prevState) => {
        const shouldNotChangeValue =
          prevState[question.id]?.inputType === QuestionInputType.VALUE &&
          allValues.inputType === QuestionInputType.VALUE &&
          Object.keys(prevState).includes('secret') &&
          allValues?.secret !== prevState[question.id]?.secret;

        return {
          ...prevState,
          [question.id]: shouldNotChangeValue
            ? { ...allValues, value: prevState[question.id]?.value }
            : allValues
        };
      });
    }
  };

  if (questions?.length === 0 || !worker) {
    return null;
  }

  let disableSubmit = isRequestHandled(request);
  const areValuesValid = Object.values(questionValuesMap).some((e) =>
    verifyQuestionValues(e)
  );
  if (!areValuesValid) {
    disableSubmit = true;
  }

  if (questions?.length === 1) {
    const values = questionValuesMap[questions[0].id];
    if (values) {
      const questionType = values.inputType;
      if (questionType === QuestionInputType.VALUE_UPLOAD) {
        disableSubmit = true;
      }
    }
  }

  const renderSuggestions = () => {
    if (!suggestions) {
      return null;
    }

    const suggestionsList: Array<string[]> = [];

    if (request.suggestedAnswers?.length) {
      request.suggestedAnswers.forEach((suggestedAnswer) => {
        const action = suggestedAnswer.action as any;
        if (action?.__typename === 'ReplaceStepAction') {
          suggestionsList.push((action as ReplaceStepAction).newSteps!);
        }
      });
    }

    if (!suggestionsList.length) {
      return null;
    }

    return (
      <div>
        <Typography.Title level={5}>Suggestions</Typography.Title>
        {suggestionsList.map((suggestion, index) => (
          <Tooltip title="Use this in place of the above procedure">
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classNames([
                'suggestion-text',
                {
                  'suggestion-border-bottom':
                    index !== suggestionsList.length - 1
                }
              ])}
              onClick={(e) => {
                e.stopPropagation();
                suggestions.handleClick(suggestion);
              }}
            >
              <MarkdownRenderer>{suggestion.join('\n')}</MarkdownRenderer>
            </div>
          </Tooltip>
        ))}
      </div>
    );
  };

  const hasSuggestions = (request.suggestedAnswers || []).length > 0;

  const handleArchive = (archiveStatus: boolean) => {
    return setWorkerArchived({
      variables: {
        input: {
          id: worker.id,
          isArchived: archiveStatus
        }
      }
    })
      .then(() => {
        if (archiveStatus) {
          message.success(`Run archived successfully`);
        } else {
          message.success(`Run un-archived successfully`);
        }
        if (archive?.onSuccess) {
          archive.onSuccess(archiveStatus);
        }
      })
      .catch(() => message.error("Couldn't archive run, please try again"));
  };

  const onArchiveClick = (archiveState: boolean) => {
    if (archiveState) {
      Modal.confirm({
        title: `Archive the run and remove it from 'Runs' view?`,
        content: `Archived runs can be viewed in "Show all Runs" view`,
        icon: <ExclamationCircleFilled />,
        okText: 'Yes',
        onOk: () => handleArchive(archiveState),
        cancelText: 'No',
        okButtonProps: {
          // @ts-ignore
          'data-cy': 'delete-yes',
          danger: true,
          type: 'primary'
        }
      });
    } else {
      handleArchive(archiveState);
    }
  };

  const isSubmitButtonVisible = () => {
    const templateId = getTemplateIdForRequest(email!);
    const currentTemplateSampleFile = templates
      .find((i) => `${i.id}` === templateId)
      ?.questions?.find(
        (i) => JSON.stringify(i?.stepPath) === JSON.stringify(request?.stepPath)
      );
    if (currentTemplateSampleFile) {
      return false;
    }
    const filteredQuestions = questions?.filter((question) => {
      const type = question.__typename as any;
      return (
        type === 'DomainNotIntegratedQuestion' ||
        type === 'MissingIntegrationCredentialQuestion'
      );
    });
    if (filteredQuestions) {
      return filteredQuestions?.length === 0;
    }

    return true;
  };

  return (
    <div className={classNames(['run-requests'])}>
      {hasSuggestions && (
        <div
          className={classNames([
            'suggestions-group',
            { 'width-50': suggestions }
          ])}
        >
          {renderSuggestions()}
        </div>
      )}
      <div
        className={classNames([
          'question-group',
          { 'width-50': hasSuggestions }
        ])}
      >
        <Layout className="question">
          <Layout.Content>
            <div
              className={classNames('question-group', {
                multiple: questions!.length > 1
              })}
            >
              {questions!.map((question, index) => {
                const isLastQuestion = index + 1 === questions!.length;

                return (
                  <Spin
                    spinning={loadingQuestionIds.has(question.id)}
                    key={question.id}
                  >
                    <RequestQuestion
                      request={request}
                      question={question}
                      worker={worker}
                      department={department}
                      procedure={procedure}
                      sentence={sentence}
                      activeDocumentToken={activeDocumentToken}
                      config={questionConfig}
                      answerId={
                        getQuestionAnswer(question, request.answers!)?.id ||
                        undefined
                      }
                      onValuesChange={(values) =>
                        onValuesChange(question, {
                          ...questionValuesMap[question.id],
                          ...values
                        })
                      }
                      questionValues={questionValuesMap[question.id]}
                    />
                    {!isLastQuestion && (
                      <Divider
                        style={{
                          margin: 0
                        }}
                      />
                    )}
                  </Spin>
                );
              })}

              {isSubmitButtonVisible() ? (
                <Row justify="start">
                  <Col>
                    <Row justify="center" align="middle">
                      <Button
                        loading={loadingQuestionIds.size > 0}
                        size="middle"
                        type="primary"
                        data-cy="question-submit"
                        className="question-submit"
                        onClick={onSubmit}
                        disabled={disableSubmit}
                      >
                        Submit
                      </Button>
                    </Row>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Layout.Content>
        </Layout>
      </div>
      <div className={classNames(['archive-run'])}>
        {procedure?.id &&
          ((!worker.isArchived && (
            <Button
              loading={loadingQuestionIds.size > 0}
              size="middle"
              type="link"
              data-cy="procedure-run-archive"
              className="archive-run-submit"
              onClick={() => onArchiveClick(true)}
            >
              Do you want to archive this run ?
            </Button>
          )) ||
            (worker.isArchived && (
              <Button
                loading={loadingQuestionIds.size > 0}
                size="middle"
                type="link"
                data-cy="procedure-run-unarchive"
                className="unarchive-run-submit"
                onClick={() => onArchiveClick(false)}
              >
                This run is archived. Unarchive it ?
              </Button>
            )))}
      </div>
    </div>
  );
}

export default Request;
