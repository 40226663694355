import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UserAddOutlined } from '@ant-design/icons';
import AccessControlRoleSelector from '../AccessControlRoleSelector';
import {
  AccessControlRole,
  Department,
  UpsertUserToDepartmentMutation
} from '../../generated/API';
import Mutations from '../../graphql/Mutations';

interface IDepartmentCollaboratorState {
  email: string;
  role: AccessControlRole;
}

interface IDepartmentCollaboratorAddFormProps {
  department: Department;
  onSuccess?: () => void;
}

function DepartmentCollaboratorAddForm(
  props: IDepartmentCollaboratorAddFormProps
) {
  const { department, onSuccess } = props;

  const defaultFormState: IDepartmentCollaboratorState = {
    email: '',
    role: AccessControlRole.member
  };

  const [formState, setFormState] =
    useState<IDepartmentCollaboratorState>(defaultFormState);

  const onChange = (key: keyof IDepartmentCollaboratorState, value: any) => {
    const newState: any = { ...formState };
    newState[key] = value;
    setFormState(() => newState);
  };

  const [addCollaborator, addCollaboratorProps] =
    useMutation<UpsertUserToDepartmentMutation>(
      Mutations.AddDepartmentCollaborator()
    );

  const handleAdd = () => {
    if (!formState.email) {
      return;
    }

    addCollaborator({
      variables: {
        departmentId: department.id,
        userId: formState.email,
        role: formState.role
      }
    })
      .then(() => {
        message.success('Added successfully');
        onSuccess?.();
      })
      .catch(() => {
        message.info('Failed to add collaborator');
      });
  };

  return (
    <Form
      layout="horizontal"
      requiredMark={false}
      labelAlign="right"
      className="department-collaborators-form"
    >
      <Form.Item label="User" name="name">
        <Input
          value={formState.email}
          onChange={(e) => onChange('email', e.target.value)}
          placeholder="test@example.com"
          autoComplete="email"
          type="email"
          size="middle"
          disabled={addCollaboratorProps.loading}
        />
      </Form.Item>
      <Form.Item label="Role:" name="role" initialValue={formState.role}>
        <AccessControlRoleSelector
          value={formState.role}
          onChange={(value) => onChange('role', value)}
          disabled={addCollaboratorProps.loading}
        />
      </Form.Item>
      <Form.Item>
        <Button
          key="submit"
          type="primary"
          onClick={handleAdd}
          icon={<UserAddOutlined />}
          loading={addCollaboratorProps.loading}
          data-cy="department-share-ok"
        >
          Add User
        </Button>
      </Form.Item>
    </Form>
  );
}

export default DepartmentCollaboratorAddForm;
