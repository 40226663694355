//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// CSVTable is a component that renders a table from a CSV data source.
// The data source is comma separated, and the first line is the header line
//

// 3rd part libraries
import React, { useEffect, useState } from 'react';
import csv from 'csvtojson';
import { Table, Button, Form, Input, Divider, Tooltip } from 'antd';
import _groupBy from 'lodash/fp/groupBy';
import _pluck from 'lodash/fp/pluck';
import downloadCsv from 'download-csv';

// Component CSS
import classNames from 'classnames';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { isEqual } from 'lodash/fp';
import AppUtil from '@/utils/AppUtil';
import { useAppSelector } from '@/stores/hooks';
import { departmentQuerySelector } from '@/stores/slices/department';
import { parseCSV } from '@/utils/csv';
import styles from './CSVTable.module.less';
import Loader from './Loader';
import ExpandIcon from './icons/ExpandIcon';
import DownloadIcon from './icons/DownloadIcon';

interface ICSVInsertFormProps {
  onFinish: (values: any) => void;
  tableColumnNames: string[];
  loading: boolean;
  showInsertForm: boolean;
  setShowInsertForm: React.Dispatch<React.SetStateAction<boolean>>;
}

function InsertForm(props: ICSVInsertFormProps) {
  const {
    onFinish,
    tableColumnNames,
    loading,
    showInsertForm,
    setShowInsertForm
  } = props;
  const [form] = Form.useForm();
  const [formSubmitEnabled, setFormSubmitEnabled] = useState(false);

  const onCancel = () => {
    form.resetFields();
    setShowInsertForm(false);
  };

  return !showInsertForm ? (
    <Button
      type="link"
      onClick={(e) => {
        e.stopPropagation();
        form.resetFields();
        setShowInsertForm(true);
      }}
    >
      + insert new row
    </Button>
  ) : (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        initialValues={{}}
        onValuesChange={() => {
          form.validateFields().then((data: Record<string, string>) => {
            if (
              Object.values(data).some((value) => value && value.trim() !== '')
            ) {
              setFormSubmitEnabled(true);
            } else {
              setFormSubmitEnabled(false);
            }
          });
        }}
      >
        {tableColumnNames.map((name) => (
          <Form.Item key={name} label={name} name={name}>
            <Input />
          </Form.Item>
        ))}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formSubmitEnabled || loading}
            loading={loading}
          >
            Submit
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            onClick={onCancel}
            disabled={loading}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
      <Divider />
    </>
  );
}

interface ICSVEditFormProps {
  editRecord: Record<string, string>;
  onEditFinish: (data: any) => void;
  tableColumnNames: string[];
  editLoading: boolean;
  onCancel: () => void;
}

function EditForm(props: ICSVEditFormProps) {
  const { editRecord, onEditFinish, tableColumnNames, editLoading, onCancel } =
    props;
  const [form] = Form.useForm();
  const [formSubmitEnabled, setFormSubmitEnabled] = useState(false);

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={(data) => onEditFinish(data)}
        initialValues={editRecord}
        onValuesChange={() => {
          form.validateFields().then((data) => {
            if (!isEqual(data, editRecord)) {
              setFormSubmitEnabled(true);
            } else {
              setFormSubmitEnabled(false);
            }
          });
        }}
      >
        {tableColumnNames.map((name) => (
          <Form.Item key={name} label={name} name={name}>
            <Input />
          </Form.Item>
        ))}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formSubmitEnabled || editLoading}
            loading={editLoading}
          >
            Submit
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
            disabled={editLoading}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
      <Divider />
    </>
  );
}

interface ICSVTableProps {
  csvData: string;
  className?: string;
  onEdit?: (data: string, idx?: number) => Promise<unknown>;
  tableNumber?: number;
  hideHeader?: boolean;
  expandTable?: (downloadCSVData: Record<string, any>[]) => void;
}

// Component implementation
function CSVTable(props: ICSVTableProps) {
  const { department } = useAppSelector(departmentQuerySelector);
  const isPgV3 = AppUtil.isDocumentModelSupported(department);
  const [initialData, setInitialData] = useState(props.csvData);
  const [tableColumnNames, setTableColumnNames] = useState<string[]>([]); // [] of column names
  const [tableRows, setTableRows] = useState<any[]>(); // [ { cellObject} ]

  useEffect(() => {
    if (props.csvData) {
      setInitialData(props.csvData);
    }
  }, [props.csvData]);

  const onEdit = props.onEdit;
  const [form] = Form.useForm();
  const [showInsertForm, setShowInsertForm] = useState(false);
  const [insertLoading, setInsertLoading] = useState(false);

  const [toDeleteRowId, setToDeleteRowId] = useState<number>();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [toBeEditedRowId, setToBeEditedRowId] = useState<number>();
  const [editRecord, setEditRecord] = useState<Record<string, string>>();
  const [editLoading, setEditLoading] = useState(false);

  useEffect(() => {
    const data = initialData;
    csv({ flatKeys: true })
      .fromString(data)
      .then((results) => {
        // extract cols from first row.

        // TODO: Remove it in future, keeping it for backward compatibility in case of any issues with new algorithm
        // const colNames = data.split('\n')[0].trim().split(',');

        // Added New parse logic to handle comma in the column name
        const colNames = parseCSV(data)[0];

        const rows = results;
        rows.forEach((r, idx) => ({ ...r, key: idx }));

        setTableRows(rows);
        setTableColumnNames(colNames.map((colName) => colName.trim()));
      });
  }, [initialData]);

  // Ensure we have data
  if (!tableColumnNames.length) {
    return <Loader />;
  }

  const cols = tableColumnNames.map((cName, _idx) => ({
    title: cName,
    dataIndex: cName,
    render: (text: string) => <span>{text}</span>
  }));

  const handleInsert = (values: any) => {
    const newEntry = Object.values(values).join(',');
    const csv = initialData.split('\n');
    const colNames = csv[0];
    const oldData = csv.slice(1).filter((row) => row);
    const newData = [colNames, newEntry, ...oldData].join('\n');
    if (onEdit) {
      setInsertLoading(true);
      onEdit(newData, props.tableNumber)
        .then(() => {
          setShowInsertForm(false);
          setToBeEditedRowId(undefined);
          setToDeleteRowId(undefined);
          setInitialData(newData);
        })
        .finally(() => setInsertLoading(false));
    }
  };

  const handleEdit = (data: any) => {
    const headers = initialData.split('\n')[0];
    const csvData = initialData
      .split('\n')
      .slice(1)
      .map((old, idx) =>
        idx === toBeEditedRowId ? Object.values(data).join(',') : old
      );
    const newCsvString = [headers, ...csvData].join('\n');
    if (onEdit) {
      setEditLoading(true);
      onEdit(newCsvString, props.tableNumber)
        .then(() => {
          setToBeEditedRowId(undefined);
          setEditRecord(undefined);
          setInitialData(newCsvString);
        })
        .finally(() => setEditLoading(false));
    }
  };

  const handleDelete = () => {
    const csv = initialData.split('\n');
    const data = csv.slice(1);
    const updatedData = data.filter((_, idx) => idx !== toDeleteRowId);
    const newCSV = [csv[0], ...updatedData].join('\n');
    if (onEdit) {
      setDeleteLoading(true);
      onEdit(newCSV, props.tableNumber)
        .then(() => {
          setToDeleteRowId(undefined);
          setInitialData(newCSV);
        })
        .finally(() => {
          setDeleteLoading(false);
        });
    }
  };

  const actions = {
    title: 'Actions',
    dataIndex: 'Actions',
    render: (_: string, record: any, index: number) => {
      if (index === toDeleteRowId) {
        return (
          <span>
            Delete?{' '}
            {deleteLoading ? (
              <LoadingOutlined />
            ) : (
              <span>
                <Tooltip title="confirm">
                  <Button
                    disabled={deleteLoading}
                    danger
                    type="primary"
                    shape="circle"
                    size="small"
                    icon={<CheckOutlined />}
                    onClick={handleDelete}
                  />
                </Tooltip>
                <Tooltip title="cancel">
                  <Button
                    disabled={deleteLoading}
                    size="small"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setToDeleteRowId(undefined);
                    }}
                  />
                </Tooltip>
              </span>
            )}
          </span>
        );
      }
      return (
        <span
          style={{
            display: 'grid',
            gridGap: '4px',
            gridTemplateColumns: '1fr 1fr'
          }}
        >
          {!editRecord && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                form.resetFields();
                form.resetFields();
                setToBeEditedRowId(index);
                setEditRecord(record);
              }}
            >
              <Tooltip title="Edit">
                <EditOutlined />
              </Tooltip>
            </span>
          )}

          <span
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setToDeleteRowId(index);
            }}
          >
            <Tooltip title="Delete">
              <DeleteOutlined />
            </Tooltip>
          </span>
        </span>
      );
    }
  };

  const tableCols = onEdit && actions && !isPgV3 ? [...cols, actions] : cols;
  const downloadCSVData = [tableRows, tableCols];

  return (
    <div className={classNames(styles.csvTable, props.className)}>
      {!props.hideHeader && (
        <span className={styles.csvTableTitleContainer}>
          Table
          <span
            className={styles.tableExpandIcon}
            onClick={() => {
              downloadCsv(...downloadCSVData);
            }}
          >
            <DownloadIcon className={styles.actionButton} />
          </span>
          <span
            className={styles.tableExpandIcon}
            onClick={() => {
              props.expandTable?.(downloadCSVData as any);
            }}
          >
            <ExpandIcon className={styles.actionButton} />
          </span>
        </span>
      )}
      {!isPgV3 && onEdit && !editRecord && (
        <InsertForm
          showInsertForm={showInsertForm}
          setShowInsertForm={setShowInsertForm}
          onFinish={handleInsert}
          tableColumnNames={tableColumnNames}
          loading={insertLoading}
        />
      )}
      {!isPgV3 && editRecord && (
        <EditForm
          editRecord={editRecord}
          onEditFinish={handleEdit}
          tableColumnNames={tableColumnNames}
          editLoading={editLoading}
          onCancel={() => {
            setToBeEditedRowId(undefined);
            setEditRecord(undefined);
          }}
        />
      )}
      <span className={styles.customTableWrapper}>
        <Table
          pagination={false}
          columns={tableCols}
          dataSource={tableRows}
          components={{
            table: ({ children }: any) => {
              return (
                <table className={styles.antCustomTable}>{children}</table>
              ) as any;
            },
            header: {
              wrapper: ({ children }: any) =>
                (
                  <thead className="ant-custom-table-header-wrapper">
                    {children}
                  </thead>
                ) as any,
              row: ({ children }: any) =>
                (
                  <tr className="ant-custom-table-header-row">{children}</tr>
                ) as any,
              cell: (data: any) => {
                return (
                  <td
                    className={classNames([
                      styles.csvCustomTableHeaderCell,
                      styles.cell
                    ])}
                  >
                    {data.children}
                  </td>
                ) as any;
              }
            },
            body: {
              wrapper: ({ children }: any) =>
                (
                  <tbody className={styles.antCustomTableBodyWrapper}>
                    {children}
                  </tbody>
                ) as any,
              row: ({ children }: any) =>
                (
                  <tr className={styles.antCustomTableBodyRow}>{children}</tr>
                ) as any,
              cell: (data: any) => {
                return (
                  <td
                    className={classNames([
                      styles.csvCustomTableBodyCell,
                      styles.cell
                    ])}
                  >
                    {data.children}
                  </td>
                ) as any;
              }
            }
          }}
        />
      </span>
    </div>
  );
}

export default CSVTable;
