// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentExceptionStagingArea-module__documentStaging__Ht_Li {
  height: 100%;
  position: relative;
}
.DocumentExceptionStagingArea-module__content__IqwvP {
  position: relative;
  height: 93%;
  overflow: auto;
}
.DocumentExceptionStagingArea-module__footer__oIio_ {
  display: flex;
  justify-content: flex-end;
  background: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/exception/document-exception/DocumentExceptionStagingArea.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;AAEA;EACE,kBAAA;EACA,WAAA;EACA,cAAA;AAAF;AAGA;EACE,aAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;AADF","sourcesContent":[".documentStaging {\n  height: 100%;\n  position: relative;\n}\n\n.content {\n  position: relative;\n  height: 93%;\n  overflow: auto;\n}\n\n.footer {\n  display: flex;\n  justify-content: flex-end;\n  background: #fff;\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentStaging": `DocumentExceptionStagingArea-module__documentStaging__Ht_Li`,
	"content": `DocumentExceptionStagingArea-module__content__IqwvP`,
	"footer": `DocumentExceptionStagingArea-module__footer__oIio_`
};
export default ___CSS_LOADER_EXPORT___;
