import React from 'react';
import Icon from '@ant-design/icons';

function HomeSvg(props: any) {
  const { fill } = props;
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57778 20.2222H11.2444V14.8889H16.5778V20.2222H19.2444V12.2222L13.9111 8.22222L8.57778 12.2222V20.2222ZM6.8 22V11.3333L13.9111 6L21.0222 11.3333V22H14.8V16.6667H13.0222V22H6.8Z"
        fill={fill || '#303048'}
      />
    </svg>
  );
}

export default function HomeIcon(props: {
  style?: React.CSSProperties;
  fill?: string;
  height?: number;
  width?: number;
}) {
  return <Icon component={() => HomeSvg(props)} {...props} />;
}
