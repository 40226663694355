import useMergeState from '@/utils/reactUtils';
import * as React from 'react';
import { Button, Form, Input, message } from 'antd';
import { useMutation } from '@apollo/client';
import {
  GenerateIntegrationAuthorizationUrlMutation,
  GenerateIntegrationAuthorizationUrlMutationVariables,
  LearnBooksMutation,
  StoreIntegrationCredentialMutation
} from '@/generated/API';
import { isEmpty } from 'lodash';
import { useAppSelector } from '@/stores/hooks';
import { departmentQuerySelector } from '@/stores/slices/department';
import {
  GENERATE_AUTHORIZATION_URL,
  LEARN_BOOKS,
  STORE_INTEGRATION_CREDENTIAL
} from '@/graphql/mutations/books';
import { LIST_INTEGRATION_BY_DEPARTMENT } from '@/graphql/queries/books';
import classNames from 'classnames';
import styles from './IntegrationForm.module.less';

interface IntegrationFormFields {
  label: string;
  // eslint-disable-next-line react/no-unused-prop-types
  key: string;
  type: string;
  description?: string | null;
  example?: string | null;
}

interface IFormField extends IntegrationFormFields {
  itemKey: string;
  value?: any;
}
interface IntegrationFormProps {
  integrationId: string;
  fields: IntegrationFormFields[];
  onclose: () => void;
  credentialData?: any;
  onIntegration?: (integrationId: string) => void;
  workerId?: string;
  procedureId?: string;
}

interface FormFieldProps extends IFormField {
  onChange?: (key: string, label: string, value: string) => void;
  readonly?: boolean;
  className?: string;
}

interface FormValue {
  label: string;
  value: string; // or any other type that `value` is supposed to be
}

interface FormValues {
  [key: string]: FormValue;
}

const processFormValues = (formValues: FormValues) => {
  return Object.entries(formValues).map(([key, value]) => ({
    key,
    value: value.value,
    label: value.label
  }));
};

const getOldFieldValue = (key: string, credentialData: any) => {
  const field = credentialData?.find((item: any) => item.key === key);
  return field?.value;
};

export function FormField(props: FormFieldProps) {
  const {
    label,
    itemKey,
    type,
    description,
    example,
    value,
    readonly,
    onChange,
    className
  } = props;

  const handleChange = (itemKey: string, label: string, value: string) => {
    if (onChange) {
      onChange(itemKey, label, value);
    }
  };
  return (
    <Form.Item
      className={classNames(styles.formField, {
        [className as string]: className
      })}
      hasFeedback
      colon={false}
      labelAlign="left"
      help={description}
      label={label}
      name={itemKey}
      rules={[{ required: !readonly }]}
    >
      <Input
        placeholder={example!}
        type={type}
        readOnly={readonly}
        defaultValue={value}
        value={value}
        onChange={(e) => handleChange(itemKey, label, e.target.value)}
      />
    </Form.Item>
  );
}

function IntegrationForm(props: IntegrationFormProps) {
  const {
    fields,
    onclose,
    integrationId,
    onIntegration,
    credentialData,
    workerId,
    procedureId
  } = props;
  const { department } = useAppSelector(departmentQuerySelector);

  const [formValues, setFormValues] = useMergeState({});
  const [oldFormValues, setOldFormValues] = useMergeState({});

  React.useEffect(() => {
    if (credentialData) {
      const data = credentialData.reduce(
        (acc: any, curr: any) => {
          const { oldValues, editValues } = acc;
          acc.oldValues = {
            ...oldValues,
            [curr.key]: curr.value
          };
          acc.editValues = {
            ...editValues,
            [curr.key]: {
              value: curr.value,
              label: curr.label
            }
          };

          return acc;
        },
        { oldValues: {}, editValues: {} }
      );
      setOldFormValues(data.oldValues);
      setFormValues(data.editValues);
    }
  }, [credentialData]);

  const handleChange = (key: string, label: string, value: string) => {
    setFormValues({
      [key]: {
        value,
        label
      }
    });
  };

  const [storeIntegration, { loading }] =
    useMutation<StoreIntegrationCredentialMutation>(
      STORE_INTEGRATION_CREDENTIAL
    );
  const [learnBook] = useMutation<LearnBooksMutation>(LEARN_BOOKS);
  const [generateAuthUrl] =
    useMutation<GenerateIntegrationAuthorizationUrlMutation>(
      GENERATE_AUTHORIZATION_URL
    );

  const handleSubmit = () => {
    if (!isEmpty(formValues)) {
      storeIntegration({
        variables: {
          input: {
            integrationId,
            departmentId: department.id,
            data: processFormValues(formValues)
          }
        },

        onCompleted: (data) => {
          // 1. Refresh GetIntegrtaionByDepartment Query
          learnBook({
            variables: {
              ids: [integrationId],
              departmentId: department.id
            },
            onCompleted: () => {
              if (!data.storeIntegrationCredential?.redirectionFlowRequired) {
                message.success('Book integrated successfully');
              }

              if (
                !data.storeIntegrationCredential?.redirectionFlowRequired &&
                onIntegration
              ) {
                onIntegration(integrationId);
              }
            },
            refetchQueries: [
              {
                query: LIST_INTEGRATION_BY_DEPARTMENT,
                variables: {
                  departmentId: department.id
                }
              }
            ],
            onError: (error) => {
              message.error(
                `Unable to learn book,
                  ${error.message}`
              );
            }
          });
          if (data.storeIntegrationCredential?.redirectionFlowRequired) {
            message.loading('Generating Authentication Url');
            const variables: GenerateIntegrationAuthorizationUrlMutationVariables =
              {
                integrationId,
                departmentId: department.id
              };
            if (workerId) {
              variables.workerId = workerId;
            }
            if (procedureId) {
              variables.procedureId = procedureId;
            }

            generateAuthUrl({
              variables,
              onCompleted: (data) => {
                message.success('Redirecting to authorization page');
                window.open(
                  data.generateIntegrationAuthorizationUrl?.url!,
                  '_self'
                );
              },
              onError: (error) => {
                message.error(
                  `Unable to generate authorization url,
                  ${error.message}`
                );
              }
            });
          }
        },
        onError: (error) => {
          message.error(
            `Unable to store integration credentials,
            ${error.message}`
          );
        }
      });
    }
  };

  const handleCancel = () => {
    onclose();
  };

  return (
    <div className={styles.integrationForm}>
      <div className={styles.integrationFormContainer}>
        <Form layout="vertical" initialValues={oldFormValues}>
          {fields?.map((field) => (
            <FormField
              {...field}
              itemKey={field.key}
              onChange={handleChange}
              value={getOldFieldValue(field.key, credentialData)}
            />
          ))}
        </Form>
      </div>
      <div className={styles.submitContainer}>
        <Button
          type="default"
          onClick={handleCancel}
          className={styles.cancelBtn}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
          data-cy="learn-book-submit"
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default IntegrationForm;
