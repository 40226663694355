// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FullMapPopup-module__mapContainer__EFlCi {
  height: 85vh;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/popups/FullMapPopup/FullMapPopup.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF","sourcesContent":[".mapContainer {\n  height: 85vh;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapContainer": `FullMapPopup-module__mapContainer__EFlCi`
};
export default ___CSS_LOADER_EXPORT___;
