import React from 'react';
import { Tooltip } from 'antd';
import { showPopup } from '@/stores/slices/appPopup';
import { useRunCtxV2 } from '@/provider/runv2';
import { useApolloClient } from '@apollo/client';
import { GET_LOCAL_DOCUMENT_PREVIEW } from '@/graphql/queries/playground';
import { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import { useCustomCompareLayoutEffect } from 'use-custom-compare';
import { isEqual } from 'lodash';
import exportFromJSON from 'export-from-json';
import { useAppDispatch } from '../stores/hooks';
import AppConstants from '../utils/AppConstants';
import './AnswerConceptsTableViewer.less';
import styles from './AnswerS3FileViewer.module.less';
import TableIcon from './icons/TableIcon';
import AnswerConceptsTable from './playground2/AnswerConceptsTable';
import AnswerConceptsTableV2, {
  getTablesRowsData
} from './playground2/AnswerConceptsTableV2';

interface IProps {
  title: string;
  answer?: string;
  tableRowCpt?: any;
  showIcons?: boolean;
  onClick?: any;
  showInFactsTable?: boolean;
  showPreview?: boolean;
  expandDocumentPreview?: (open: boolean) => void;
  epoch?: number;
  showDebugDetails?: boolean;
  newFactsModelData?: any[];
}

export default function AnswerConceptsTableViewer(props: IProps) {
  const {
    title,
    answer,
    tableRowCpt,
    showIcons,
    onClick,
    showInFactsTable,
    showPreview,
    expandDocumentPreview,
    epoch,
    showDebugDetails,
    newFactsModelData
  } = props;
  const { worker } = useRunCtxV2();
  const dispatch = useAppDispatch();
  const client = useApolloClient();

  const localDocumentPreview = client.readQuery({
    query: GET_LOCAL_DOCUMENT_PREVIEW
  });

  const expandTable = (downloadJsonData?: {
    data: any;
    exportType: string;
  }) => {
    client.writeQuery({
      query: GET_LOCAL_DOCUMENT_PREVIEW,
      data: {
        getLocalDocumentPreview: {
          data: {
            type: FormattedAnswerTypeV2.TABLES_ROW,
            knowledgeId: worker.knowledgeId!,
            answer,
            tableRowCpt,
            newFactsModelData,
            epoch,
            downloadJsonData
          }
        }
      }
    });
    expandDocumentPreview?.(true);
  };

  // If table's rows are open in expanded mode and new data is still loading, we need to re-populate the table
  useCustomCompareLayoutEffect(
    () => {
      const docPreviewData =
        localDocumentPreview?.getLocalDocumentPreview?.data;
      if (
        newFactsModelData &&
        docPreviewData?.type === FormattedAnswerTypeV2.TABLES_ROW
      ) {
        const { tablesRowData } = getTablesRowsData(newFactsModelData);
        expandTable({
          data: tablesRowData,
          exportType: exportFromJSON.types.json
        });
      }
    },
    [newFactsModelData, localDocumentPreview?.getLocalDocumentPreview?.data],
    (prev, next) => isEqual(prev, next)
  );

  const show = () => {
    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.ANSWER_CONCEPTS_TABLE,
        popupParams: {
          title,
          knowledgeId: worker.knowledgeId!,
          answer,
          tableRowCpt
        }
      })
    );
  };

  const handleClick = () => {
    if (expandDocumentPreview) expandTable();
    else if (onClick) onClick();
    else show();
  };

  if (!worker) {
    return null;
  }

  if (showPreview) {
    if (newFactsModelData) {
      return (
        <AnswerConceptsTableV2
          newFactsModelData={newFactsModelData!}
          epoch={epoch}
          showDebugDetails={showDebugDetails}
          onExpandTable={expandTable}
        />
      );
    }
    return (
      <AnswerConceptsTable
        knowledgeId={worker.knowledgeId!}
        answer={answer}
        tableRowCpt={tableRowCpt}
        expandTable={expandTable}
        epoch={epoch}
        showDebugDetails={showDebugDetails}
      />
    );
  }

  return showInFactsTable ? (
    <span>
      <div className={styles.tableFactTableContainer} onClick={handleClick}>
        <TableIcon style={{ width: 22, height: 24, marginRight: 2 }} />
        <span className={styles.fileButton}>{title}</span>
      </div>
      {newFactsModelData ? (
        <AnswerConceptsTableV2
          newFactsModelData={newFactsModelData!}
          epoch={epoch}
          showDebugDetails={showDebugDetails}
          onExpandTable={expandTable}
          hideTable
        />
      ) : (
        <AnswerConceptsTable
          knowledgeId={worker.knowledgeId!}
          answer={answer}
          tableRowCpt={tableRowCpt}
          expandTable={expandTable}
          epoch={epoch}
          showDebugDetails={showDebugDetails}
          hideTable
        />
      )}
    </span>
  ) : (
    <div className="answer-concepts-viewer" data-cy="answer-table-viewer">
      <span onClick={handleClick} className="view-link">
        {showIcons ? (
          <Tooltip
            destroyTooltipOnHide
            title={title || 'view table'}
            color="#fff"
            overlayInnerStyle={{
              color: 'black'
            }}
          >
            <TableIcon style={{ height: '18px', width: '18px' }} />
          </Tooltip>
        ) : (
          title
        )}
      </span>
    </div>
  );
}
