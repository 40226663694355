import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
import Table from '../table/Table';
import './ExcelTable.less';

interface IProps {
  fileBlob: Blob;
}

function ExcelTable(props: IProps) {
  const { fileBlob } = props;

  const [loading, setLoading] = useState(false);
  const [sheetDataMap, setSheetDataMap] = useState<Record<string, any[]>>({});
  const [sheetHeaderMap, setSheetHeaderMap] = useState<Record<string, any[]>>(
    {}
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const arrayBuffer = await fileBlob.arrayBuffer();
      const workbook = read(arrayBuffer);

      let headerMap: Record<string, any[]> = {};
      const newDataMap = workbook.SheetNames.reduce<Record<string, any[]>>(
        (acc, cur) => {
          const headers = utils
            .sheet_to_csv(workbook.Sheets[cur])
            .split('\n')[0]
            .split(',');
          headerMap = { ...headerMap, [cur]: headers };
          acc[cur] = utils.sheet_to_json(workbook.Sheets[cur]);
          return acc;
        },
        {}
      );
      setSheetHeaderMap(headerMap);
      setSheetDataMap(newDataMap);

      setLoading(false);
    })();
  }, [fileBlob]);

  const getColumns = (columnNames: any[]) =>
    columnNames.map((key: any) => ({
      id: key,
      dataKey: key,
      title: key.includes('__EMPTY') ? '' : key, // Show '' in case of empty excel column title
      renderColumn: (value: any) =>
        typeof value === 'boolean' ? value.toString() : value
    }));

  return (
    <div className="excel-table">
      <Tabs
        defaultActiveKey="0"
        tabPosition="top"
        items={Object.keys(sheetDataMap).map((sheetName) => {
          const data = sheetDataMap[sheetName];
          const columnNames = sheetHeaderMap[sheetName];
          return {
            label: sheetName,
            key: sheetName,
            children: (
              <Table
                key={`${sheetName}`}
                data={data}
                loading={loading}
                columns={data.length > 0 ? getColumns(columnNames) : []}
                uniqueRowId="id"
                pagination={{}}
              />
            )
          };
        })}
      />
    </div>
  );
}

export default ExcelTable;
