export enum ProcessPermissionsEnum {
  ProcessReadAll = 'process:readAll',
  ProcessRead = 'process:read',
  ProcessCreate = 'process:create',
  ProcessUpdate = 'process:update',
  ProcessDelete = 'process:delete',
  ProcessUpdateOwner = 'process:upadteOwner'
}

export enum RunPermissionsEnum {
  RunReadAll = 'run:readAll',
  RunRead = 'run:read',
  RunCreate = 'run:create',
  RunUpdate = 'run:update',
  RunDelete = 'run:delete'
}

export enum PlaygroundPermissionsEnum {
  PlaygroundReadAll = 'playground:readAll',
  PlaygroundRead = 'playground:read',
  PlaygroundCreate = 'playground:create',
  PlaygroundUpdate = 'playground:update',
  PlaygroundDelete = 'playground:delete',
  PlaygroundCreateProcess = 'playground:createProcess'
}

export enum UserPermissionsEnum {
  UserRead = 'user:read',
  UserUpdate = 'user:update',
  UserDeleteOrgLevel = 'user:deleteOrgLevel',
  UserDeleteDepartmentLevel = 'user:deleteDepartmentLevel',
  UserCreateOrgLevel = 'user:createOrgLevel',
  UserCreateDepartmentLevel = 'user:createDepartmentLevel'
}

export enum ExceptionPermissionsEnum {
  ExceptionRead = 'exception:read',
  ExceptionUpdate = 'exception:update',
  ExceptionDelete = 'exception:delete'
}

export enum LearningsPermissionsEnum {
  LearningsRead = 'learnings:read',
  LearningsCreate = 'learnings:create',
  LearningsUpdate = 'learnings:update',
  LearningsDelete = 'learnings:delete'
}

export enum BookPermissionsEnum {
  BookRead = 'book:read',
  BookCreate = 'book:create',
  BookUpdate = 'book:update',
  BookDelete = 'book:delete',
  BookReadCredentials = 'book:readCredentials'
}

export enum DepartmentPermissionsEnum {
  DepartmentRead = 'department:read',
  DepartmentCreate = 'department:create',
  DepartmentUpdate = 'department:update',
  DepartmentDelete = 'department:delete',
  DepartmentBookRead = 'department:bookRead',
  DepartmentBookCreate = 'department:bookCreate',
  DepartmentBookUpdate = 'department:bookUpdate',
  DepartmentBookDelete = 'department:bookDelete',
  DepartmentUserRead = 'department:userRead',
  DepartmentUserCreate = 'department:userCreate',
  DepartmentUserUpdate = 'department:userUpdate',
  DepartmentAuditRead = 'department:auditRead',
  DepartmentLearningsRead = 'department:learningsRead',
  DepartmentLearningsCreate = 'department:learningsCreate',
  DepartmentLearningsUpdate = 'department:learningsUpdate',
  DepartmentLearningsDelete = 'department:learningsDelete'
}

export enum DashboardPermissionsEnum {
  DashboardReadOrgLevel = 'dashboard:readOrgLevel',
  DashboardReadDepartmentLevel = 'dashboard:readDepartmentLevel'
}

export enum OrgPermissionsEnum {
  OrgRead = 'org:read',
  OrgUpdate = 'org:update',
  OrgDelete = 'org:delete',
  OrgCreate = 'org:create'
}

// Make union of all permissions
export type PermissionEnum =
  | ProcessPermissionsEnum
  | RunPermissionsEnum
  | PlaygroundPermissionsEnum
  | UserPermissionsEnum
  | ExceptionPermissionsEnum
  | LearningsPermissionsEnum
  | BookPermissionsEnum
  | DepartmentPermissionsEnum
  | DashboardPermissionsEnum
  | OrgPermissionsEnum;

// return all Permissions
export const allPermissions = [
  ...Object.values(ProcessPermissionsEnum),
  ...Object.values(PlaygroundPermissionsEnum),
  ...Object.values(RunPermissionsEnum),
  ...Object.values(UserPermissionsEnum),
  ...Object.values(ExceptionPermissionsEnum),
  ...Object.values(LearningsPermissionsEnum),
  ...Object.values(BookPermissionsEnum),
  ...Object.values(DepartmentPermissionsEnum),
  ...Object.values(DashboardPermissionsEnum),
  ...Object.values(OrgPermissionsEnum)
];
