import { logEvent } from '@redux-beacon/amplitude';
import debug from 'debug';

const log = debug('amplitude');

export default {
  'department/fetchAll/fulfilledx': logEvent(() => {
    log('event.type = department.fulfilled');
    return {
      type: 'department.fulfilled'
    };
  })
  // 'todos/saveNewTodo/fulfilled': logEvent((action, prevState, nextState) => {
  //   log('event.type = saveNewTodo.fulfilled');
  //   return {
  //     type: 'saveNewTodo.fulfilled',
  //     properties: null
  //   };
  // }),
};
