import React, { Suspense } from 'react';
import Loader, { ILoaderType } from './Loader';

interface IProps {
  element: JSX.Element;
  loader?: JSX.Element;
}

function WithSuspense(props: IProps) {
  const { element, loader = <Loader type={ILoaderType.FULL_PAGE} /> } = props;
  return <Suspense fallback={loader}>{element}</Suspense>;
}

export default WithSuspense;
