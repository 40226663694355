import React from 'react';
import { useRunCtxV2 } from '@/provider/runv2';
import { Collapse, Empty } from 'antd';
import AppUtil from '@/utils/AppUtil';
import useLineFact, { FACT_STATUS, prepareFactKey } from '@/hooks/useLineFact';
import { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import { Step } from '@/generated/API';
import styles from '@/components/playground2/RunFactRendererV2.module.less';
import Loader, { ILoaderType } from '@/components/Loader';
import RunFactRendererV2 from '@/components/playground2/RunFactRendererV2';

export interface IStepConcept {
  name: string;
  ids: string[];
  _key: string;
  is_out_param: boolean;
  snapshot_id: string;
  list_id: string | null;
  fact_types?: FormattedAnswerTypeV2[];
  display_values?: (string | null)[];
  parent_fact_ids?: (string | null)[];
  is_location_present?: boolean[];
  are_relations_present?: boolean[];
  lexical_path: {
    full_name: string;
    location: {
      start: { line: number; position: number };
      end: { line: number; position: number };
    } | null;
  }[];
}

interface IFactsRendererProps {
  stepConcept: {
    name: string;
    _key: string;
    list_id: string | null;
    cpts: {
      id: string;
      fact_type?: string | null;
      display_value?: string | null;
      is_location_present?: boolean;
      parent_fact_id?: string | null;
      are_relations_present?: boolean;
      status: FACT_STATUS;
    }[];
  };
  step: Step;
  knowledgeId: string;
  expandDocumentPreview?: (open: boolean) => void;
  isDebugMode?: boolean;
}

const FactLoader = () => (
  <span className={styles.stringFactContainer}>
    <Loader
      className={styles.noPadding}
      message="Getting data"
      skeletonConfig={{ rows: 0 }}
      type={ILoaderType.SKELETON}
    />
  </span>
);

function FactsRenderer(props: IFactsRendererProps) {
  const { stepConcept, step, expandDocumentPreview, knowledgeId, isDebugMode } =
    props;
  const { epoch } = step;
  const { facts, factIds } = useLineFact({
    cptList: stepConcept.cpts as any,
    knowledgeId,
    epoch: epoch!
  });

  const firstFactKey = prepareFactKey(factIds[0], epoch!);
  const firstFact = facts[firstFactKey];
  if (!facts || !firstFact) {
    return <FactLoader />;
  }

  if (
    firstFact.fact_type === FormattedAnswerTypeV2.TABLES_ROW ||
    // A string is a valid json, hence we check for object type
    (firstFact.fact_type === FormattedAnswerTypeV2.JSON &&
      typeof firstFact.value === 'object')
  ) {
    const data = factIds.map((id) => facts[prepareFactKey(id, epoch!)]);
    return (
      <span data-cy="fact-value">
        <RunFactRendererV2
          fact={firstFact}
          factType={{ type: FormattedAnswerTypeV2.TABLES_ROW, answer: '' }}
          knowledgeId={knowledgeId}
          showInFactsTable
          expandDocumentPreview={expandDocumentPreview}
          epoch={epoch!}
          debug={isDebugMode}
          tablesRowDataNewFactsModel={data}
        />
      </span>
    );
  }

  let updatedFactIds = factIds;
  // This shows a loader for all the results after a LOADING status is found
  const loadingIndex = factIds.findIndex(
    (item) =>
      facts[prepareFactKey(item, epoch!)]?.status === FACT_STATUS.LOADING
  );

  if (loadingIndex >= 0) {
    updatedFactIds = factIds.slice(0, loadingIndex);
  }

  return (
    <div className={styles.lineFactContainer}>
      {updatedFactIds.map((id, idx) => {
        const key = prepareFactKey(id, epoch!);
        const fact = facts[key];

        let factType = fact.fact_type;
        if (!factType) {
          factType = FormattedAnswerTypeV2.STRING;
        } else if (
          factType === FormattedAnswerTypeV2.JSON &&
          typeof fact.value !== 'object'
        ) {
          factType = FormattedAnswerTypeV2.STRING;
        }

        let factTypeAnswer: any = fact.value;
        if (fact.fact_type === FormattedAnswerTypeV2.TABLE) {
          factTypeAnswer = [fact.value];
        } else if (!fact.fact_type) {
          factTypeAnswer = fact.display_value;
        }

        return fact && fact.status === FACT_STATUS.LOADING ? (
          <FactLoader />
        ) : (
          <span data-cy="fact-value" key={`${key}-${stepConcept._key}-${idx}`}>
            <RunFactRendererV2
              debug={isDebugMode}
              showRelations
              epoch={epoch!}
              key={id}
              fact={fact}
              factType={{
                type: factType,
                answer: factTypeAnswer || '-'
              }}
              knowledgeId={knowledgeId}
              showInFactsTable
              noTextMassage
              expandDocumentPreview={expandDocumentPreview}
            />
          </span>
        );
      })}
      {loadingIndex >= 0 && (
        <Loader
          className={styles.noPadding}
          message="Getting data"
          skeletonConfig={{ rows: 5 }}
          type={ILoaderType.SKELETON}
        />
      )}
    </div>
  );
}

interface IProps {
  step: Step;
  ghostCollapse?: boolean;
  expandDocumentPreview?: (open: boolean) => void;
  showEmpty?: boolean;
  isDebugMode?: boolean;
}

function LineFacts(props: IProps) {
  const {
    step,
    ghostCollapse = false,
    expandDocumentPreview,
    showEmpty = true,
    isDebugMode
  } = props;
  const { worker } = useRunCtxV2();
  if (!step) {
    return <Empty />;
  }

  const stepConcepts =
    (AppUtil.safeParseJSON(step.concepts) as IStepConcept[]) || [];
  if (!stepConcepts) {
    return <Empty />;
  }

  if (!worker) {
    return <Empty />;
  }

  const renderEmpty = () => (showEmpty ? <Empty /> : null);

  return (
    <div className="facts-table" data-cy="facts-table">
      <div className="table-body">
        {stepConcepts.map((rawSc, idx) => {
          const cpts = rawSc.ids.map((id, scIdx) => ({
            id,
            fact_type: rawSc?.fact_types?.[scIdx],
            display_value: rawSc?.display_values?.[scIdx],
            is_location_present: rawSc?.is_location_present?.[scIdx],
            parent_fact_id: rawSc?.parent_fact_ids?.[scIdx],
            are_relations_present: rawSc?.are_relations_present?.[scIdx],
            status: FACT_STATUS.INIT
          }));
          const sc = {
            list_id: rawSc.list_id,
            name: rawSc.name,
            snapshot_id: rawSc.snapshot_id,
            _key: `${rawSc.name}-${idx}`,
            lexical_path: rawSc.lexical_path,
            cpts
          };

          return (
            <span key={sc._key} className="fact-container" id={sc._key}>
              <Collapse
                bordered={false}
                defaultActiveKey={idx === 0 ? [sc._key] : []}
                accordion
                collapsible="header"
                ghost={ghostCollapse}
              >
                <Collapse.Panel
                  showArrow
                  header={
                    <span className="fact-name">
                      <span>
                        <span data-cy="fact-name">{sc.name}</span>
                      </span>
                      {/* {checked && (
                        <Tooltip title="visible in show all runs table">
                          <CheckCircleOutlined />
                        </Tooltip>
                      )} */}
                    </span>
                  }
                  key={sc._key}
                >
                  {cpts.length > 0 ? (
                    <FactsRenderer
                      step={step}
                      stepConcept={sc}
                      expandDocumentPreview={expandDocumentPreview}
                      isDebugMode={isDebugMode}
                      knowledgeId={worker.knowledgeId!}
                    />
                  ) : (
                    renderEmpty()
                  )}
                </Collapse.Panel>
              </Collapse>
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default LineFacts;
