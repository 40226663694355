import { FactAnswer } from '@/hooks/useLineFact';
import React, { useState, createContext, ReactNode, useMemo } from 'react';

export interface FactsContextProps {
  facts: Record<string, FactAnswer>;
  setFacts: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

export const FactsContext = createContext<FactsContextProps>({
  facts: {},
  setFacts: () => {}
});

export const FactsProvider = ({ children }: { children: ReactNode }) => {
  const [facts, setFacts] = useState<FactsContextProps['facts']>({});

  const contextValue = useMemo(() => ({ facts, setFacts }), [facts, setFacts]);
  return (
    <FactsContext.Provider value={contextValue}>
      {children}
    </FactsContext.Provider>
  );
};
