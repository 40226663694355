import React from 'react';
import './Container.less';

interface IProps {
  children: React.ReactNode;
  className?: String;
}

function Container(props: IProps) {
  const { children, className } = props;
  const clz = `container ${className || ''}`;
  return <div className={clz}>{children}</div>;
}

export default Container;
