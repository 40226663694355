//
// Copyright (C) - Kognitos. All rights reserved
//
// NewRun is the popup for creating a new run
//

// 3rd party libraries
import React, { useState } from 'react';
import { Form, Input, Modal, Switch } from 'antd';
import { useMutation } from '@apollo/client';

// Local imports
import i18n from '@utils/i18n';
import {
  Department,
  InvokeProcedure2MutationVariables,
  Procedure
} from '../generated/API';
import { INVOKE_PROCEDURE } from '../graphql/procedure';

// Component i18n
const i18nt = (s: string, d?: string) => i18n.t('NewRun', s, d);

// Component Prop Types
interface IProps {
  /**
   * flow under which to create a run
   */
  procedure: Procedure;
  /**
   * ID of the department to use
   */
  department: Department;
  /**
   * Success Callback -- called back w/ new flow
   */
  onSuccess: (data: any) => void;
  /**
   * Close Callback
   */
  onClose: () => void;
}

// Component implementation
function NewRun(props: IProps) {
  const { procedure, department, onSuccess, onClose } = props;

  // The new form instance
  const [theForm] = Form.useForm();
  // Is the popup visible
  const [visible, setVisible] = useState(true);
  // The form's field values
  const [formState, setFormState] = useState({
    name: '',
    debug: false
  });

  // New Flow Mutation
  // [ mutationFunc, { loading, error data }]
  const [invokeProcedure, invokeProcedureProps] = useMutation(INVOKE_PROCEDURE);

  const onOK = () => {
    theForm.validateFields().then(() => {
      const variables: InvokeProcedure2MutationVariables = {
        procedureId: procedure.id,
        name: formState.name,
        departmentId: department.id
      };

      invokeProcedure({ variables }).then(() => {
        onSuccess(invokeProcedureProps.data);
        onClose();
      });
    });
  };

  const onCancel = () => {
    setVisible(false);
    onClose();
  };

  const onChange = (key: any, value: any) => {
    const newState = { ...formState };
    // @ts-ignore
    newState[key] = value;
    setFormState(newState);
  };

  return (
    <Modal
      className="new-run-popup"
      title={i18nt('new_run', 'New Run')}
      centered
      open={visible}
      onOk={onOK}
      okButtonProps={{
        // @ts-ignore
        'data-cy': 'new-run-ok-btn',
        loading: invokeProcedureProps.loading
      }}
      onCancel={onCancel}
      width={600}
      keyboard={false}
      maskClosable={false}
    >
      <Form form={theForm} layout="vertical" requiredMark={false}>
        <Form.Item label={i18nt('name', 'Name')} name="name">
          <Input
            value={formState.name}
            data-cy="run-name"
            onChange={(e) => onChange('name', e.currentTarget.value)}
            placeholder={i18nt('optional', '(optional)')}
            disabled={invokeProcedureProps.loading}
          />
        </Form.Item>
        <Form.Item label="Debug" valuePropName="checked">
          {/* @ts-ignore */}
          <Switch
            onChange={(e) => {
              onChange('debug', e);
            }}
            checked={formState.debug}
          >
            Debug
          </Switch>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NewRun;
