import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  GetDepartmentQuery,
  GetDepartmentQueryVariables,
  LearnedAnswer,
  LearnedAnswerConnection
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Queries from '@/graphql/Queries';
import { FetchResult } from '@apollo/client';

export interface GetDepartmentLearnedAnswersParams
  extends GetDepartmentQueryVariables {}

interface IGetDepartmentLearnedAnswersData {
  getDepartment: GetDepartmentQuery['getDepartment'];
}

export const getDepartmentLearnedAnswers = createAsyncThunk<
  FetchResult<IGetDepartmentLearnedAnswersData>,
  GetDepartmentLearnedAnswersParams,
  AsyncThunkConfig
>('departmentLearnedAnswers/fetchAll', async ({ id }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.ListDepartmentLearnedAnswers(),
    variables: {
      id
    }
  })
);

const DepartmentLearnedAnswersAdapter = createEntityAdapter<LearnedAnswer>({
  selectId: (learning) => learning.id!
});

interface IState {
  loading: boolean;
  error: any;
}

export const departmentLearnedAnswersSlice = createSlice({
  name: 'departmentLearnedAnswers',
  initialState: DepartmentLearnedAnswersAdapter.getInitialState<IState>({
    loading: false,
    error: undefined
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDepartmentLearnedAnswers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getDepartmentLearnedAnswers.fulfilled,
      (state, { payload }) => {
        if (payload?.data?.getDepartment?.learnedAnswers) {
          const { items = [] } = payload.data.getDepartment
            .learnedAnswers as LearnedAnswerConnection;
          const learnedAnswers = items as LearnedAnswer[];
          DepartmentLearnedAnswersAdapter.setAll(state, learnedAnswers);
        }
        state.loading = false;
      }
    );
    builder.addCase(
      getDepartmentLearnedAnswers.rejected,
      (state, { payload }) => {
        state.error = payload as any;
        state.loading = false;
      }
    );
  }
});

export const departmentLearnedAnswersSelector =
  DepartmentLearnedAnswersAdapter.getSelectors<RootState>(
    (state) => state.departmentLearnedAnswers
  );

export const departmentLearnedAnswersQuerySelector = (state: RootState) => ({
  loading: state.departmentLearnedAnswers.loading,
  learnedAnswers: departmentLearnedAnswersSelector.selectAll(state),
  error: state.departmentLearnedAnswers.error
});

export default departmentLearnedAnswersSlice.reducer;
