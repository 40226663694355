/* eslint-disable no-underscore-dangle */
import { Button, Modal, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CheckOutlined,
  CloseCircleFilled,
  CopyOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {
  useMutation,
  useSubscription,
  useApolloClient,
  useLazyQuery
} from '@apollo/client';

import { last } from 'lodash';
import styles from './MiniPlaygroundPopup.module.less';
import {
  ContextualLearningInfo,
  CreateMiniPlaygroundMutation,
  CreateMiniPlaygroundMutationVariables,
  GetContextualLearningInfoByRequestQuery,
  GetContextualLearningInfoByRequestQueryVariables,
  OnSentenceUpdateSubscription,
  Procedure,
  Request,
  Worker
} from '../generated/API';
import {
  EditorSourceType,
  ISentence,
  SubDocument
} from '../pages/PlaygroundV3/components/Editor/editorInterface';
import AppUtil from '../utils/AppUtil';
import { GET_WORKER_MAP } from '../graphql/queries/playground';
import { CREATE_MINI_PLAYGROUND } from '../graphql/mutations/playground';
import Loader, { ILoaderType } from '../components/Loader';
import { ON_SENTENCE_MINI_PLAYGROUNDS_UPDATE } from '../graphql/subscriptions/playground';
import { EditorProvider } from '../pages/PlaygroundV3/components/Editor/EditorProvider';
import { RunProviderV2 } from '../provider/runv2';
import Editor from '../pages/PlaygroundV3/components/Editor/Editor';
import { GET_CONTEXTUAL_LEARNING_INFO_BY_REQUEST } from '../graphql/learning';
import ContextualLearningPreview from '../components/request/LearningMethod/ContextualLearningPreview';

export enum MiniPlaygroundModeType {
  CONTEXTUAL_LEARNING = 'CONTEXTUAL_LEARNING',
  TECHNIQUE = 'TECHNIQUE',
  DEFAULT = 'DEFAULT'
}

interface IProps {
  mode: MiniPlaygroundModeType;
  worker: Worker;
  procedure?: Procedure;
  sentence: ISentence;
  activeDocumentToken: string;
  request?: Request;
  resetDocument?: boolean;
  onClose: () => void;
  onMethodUse?: (editorText: string) => void;
}

function MiniPlaygroundPopup(props: IProps) {
  const {
    mode,
    worker,
    procedure,
    sentence,
    activeDocumentToken,
    request,
    resetDocument,
    onClose,
    onMethodUse
  } = props;

  const client = useApolloClient();

  let initialMiniPlaygrounds: SubDocument[] | null = null;
  if (!resetDocument) {
    initialMiniPlaygrounds = sentence.editorMiniPlaygrounds
      ? sentence.editorMiniPlaygrounds[sentence.token!]
      : sentence.miniPlaygrounds;
  }

  const [miniPlaygrounds, setMiniPlaygrounds] = useState<SubDocument[] | null>(
    initialMiniPlaygrounds
  );
  const [editorText, setEditorText] = useState<string | null>(null);
  const [contextualLearnings, setContextualLearnings] = useState<
    ContextualLearningInfo[] | null
  >(null);

  const workerMap = client.readQuery({
    query: GET_WORKER_MAP
  });
  const lineId = useMemo(
    () => workerMap?.getWorkerMap?.idMap[sentence.uiLineId],
    [workerMap, sentence.uiLineId]
  );

  const [createMiniPlayground, createMiniPlaygroundProps] = useMutation<
    CreateMiniPlaygroundMutation,
    CreateMiniPlaygroundMutationVariables
  >(CREATE_MINI_PLAYGROUND);

  const [getContextInfo, getContextInfoProps] = useLazyQuery<
    GetContextualLearningInfoByRequestQuery,
    GetContextualLearningInfoByRequestQueryVariables
  >(GET_CONTEXTUAL_LEARNING_INFO_BY_REQUEST, {
    variables: {
      requestId: request?.id!
    },
    onCompleted(data) {
      const contextualLearningInfo = data.getContextualLearningInfoByRequest;
      if (contextualLearningInfo) {
        setContextualLearnings(contextualLearningInfo.items || []);
      }
    },
    onError() {
      message.error('Failed to get contextual learning');
    }
  });

  useSubscription<OnSentenceUpdateSubscription>(
    ON_SENTENCE_MINI_PLAYGROUNDS_UPDATE,
    {
      variables: { workerId: worker.id, documentToken: activeDocumentToken },
      onData: ({ data }) => {
        const sentenceData = (
          data.data?.onSentenceUpdate?.sentences || []
        ).find((sd) => sd?.lineId === lineId);

        if ((sentenceData as any)?.miniPlaygrounds) {
          setMiniPlaygrounds((sentenceData as any).miniPlaygrounds);
        }
      },
      skip: !!miniPlaygrounds
    }
  );

  const miniPlayground = last(miniPlaygrounds);

  const handleNewMiniPlayground = async () => {
    try {
      await createMiniPlayground({
        variables: {
          input: {
            workerId: worker.id,
            lineId,
            lineToken: sentence.token!
          }
        }
      });
    } catch (e) {
      message.error('Failed to create a new mini playground');
    }
  };

  const handleMethodLearning = () => {
    onClose();
    onMethodUse?.(editorText || '');
  };

  useEffect(() => {
    if (!initialMiniPlaygrounds || initialMiniPlaygrounds.length === 0) {
      handleNewMiniPlayground();
    }
  }, [initialMiniPlaygrounds]);

  const handleEditorTextCopy = () => {
    if (editorText) {
      navigator.clipboard.writeText(editorText).then(() => {
        message.success(`Copied to clipboard`);
      });
    }
  };

  const validMiniPlaygrounds = (miniPlaygrounds || []).length > 0;

  const renderContent = () => {
    if (createMiniPlaygroundProps.loading || !validMiniPlaygrounds) {
      return (
        <Loader
          type={ILoaderType.SKELETON}
          skeletonConfig={{
            rows: 10
          }}
        />
      );
    }

    if (contextualLearnings) {
      return (
        <ContextualLearningPreview
          contextualLearning={last(contextualLearnings) || null}
          methodText={editorText || ''}
          onEditClick={() => setContextualLearnings(null)}
        />
      );
    }

    return (
      <RunProviderV2
        contextId="1"
        procedureId={procedure?.id}
        readOnly
        fetchWorker={false}
        waitTimeToRefetchNodes={0}
      >
        <EditorProvider
          worker={worker}
          source={EditorSourceType.MINI_PLAYGROUND}
          defaultDocumentToken={miniPlayground!.documentToken}
        >
          <Editor
            workerId={worker.id}
            hide={{
              header: false,
              outputPanel: true,
              chat: true,
              tabNavIfSingle: true
            }}
            hideActiveBlockOnOutsideClick={false}
            getEditorPlainText={(text) => setEditorText(text)}
          />
        </EditorProvider>
      </RunProviderV2>
    );
  };

  const renderFooterActions = () => {
    if (mode === MiniPlaygroundModeType.TECHNIQUE) {
      return (
        <Button
          type="primary"
          onClick={handleMethodLearning}
          disabled={!editorText}
          icon={<CheckOutlined />}
        >
          Done
        </Button>
      );
    }

    if (mode === MiniPlaygroundModeType.CONTEXTUAL_LEARNING) {
      if (contextualLearnings) {
        return (
          <Button type="primary" onClick={handleMethodLearning}>
            Use this method
          </Button>
        );
      }
      return (
        <Button
          type="primary"
          disabled={!editorText}
          loading={getContextInfoProps.loading}
          onClick={() => {
            getContextInfo();
          }}
        >
          Next
        </Button>
      );
    }

    return (
      <Button
        type="primary"
        icon={<CopyOutlined />}
        onClick={handleEditorTextCopy}
        disabled={!editorText}
      >
        Copy commands
      </Button>
    );
  };

  return (
    <Modal
      open
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
      footer={null}
      width={800}
      className={styles.miniPlaygroundPopup}
      destroyOnClose
    >
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.headerInfo}>
            <div className={styles.runInfo}>
              {procedure
                ? AppUtil.removeToFromName(procedure.name)
                : worker.name}{' '}
              / {moment(worker.createdAt).format('lll')}
            </div>
            <div className={styles.sentenceInfo}>{sentence.text}</div>
          </div>
          <CloseCircleFilled className={styles.closeIcon} onClick={onClose} />
        </div>

        <div className={styles.content}>{renderContent()}</div>

        <div className={styles.footer}>{renderFooterActions()}</div>
      </div>
    </Modal>
  );
}

export default MiniPlaygroundPopup;
