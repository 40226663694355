// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestIntegration-module__text__kpi0T {
  color: #2d404e;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}
.RequestIntegration-module__textContainer__JHf1v {
  margin-bottom: 20px;
}
.RequestIntegration-module__textContainer__JHf1v .anticon {
  margin-right: 10px;
  top: 2px;
  position: relative;
}
.RequestIntegration-module__integrationBtnContainer__BA7aW {
  margin: 0 auto 20px;
}
.RequestIntegration-module__bookCard__E7JQL {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/request/RequestIntegration/RequestIntegration.module.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAa;AACf;AACA;EACE,mBAAA;AACF;AAFA;EAGI,kBAAA;EACA,QAAA;EACA,kBAAA;AAEJ;AAEA;EACE,mBAAA;AAAF;AAGA;EACE,mBAAA;AADF","sourcesContent":[".text {\n  color: #2d404e;\n  font-family: Nunito;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px; /* 171.429% */\n}\n\n.textContainer {\n  margin-bottom: 20px;\n  :global(.anticon) {\n    margin-right: 10px;\n    top: 2px;\n    position: relative;\n  }\n}\n\n.integrationBtnContainer {\n  margin: 0 auto 20px;\n}\n\n.bookCard {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `RequestIntegration-module__text__kpi0T`,
	"textContainer": `RequestIntegration-module__textContainer__JHf1v`,
	"integrationBtnContainer": `RequestIntegration-module__integrationBtnContainer__BA7aW`,
	"bookCard": `RequestIntegration-module__bookCard__E7JQL`
};
export default ___CSS_LOADER_EXPORT___;
