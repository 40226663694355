import React, { useMemo, useState } from 'react';
import { Button, Modal, Pagination } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import CSVTable from '../components/CSVTable';
import { downloadCSV } from '../utils/csv';
import './AnswerCSVPopup.less';

interface IProps {
  title: string;
  data: string[];
  onClose: () => void;
  onEdit?: (data: string, idx?: number) => Promise<unknown>;
}

export default function AnswerCSVPopup(props: IProps) {
  const { title, data, onClose, onEdit } = props;

  const showPagination = data.length > 1;

  const [tableNumber, setTableNumber] = useState(0);

  const memoTable = useMemo(
    () => (
      <CSVTable
        onEdit={onEdit}
        csvData={data[tableNumber]}
        tableNumber={tableNumber}
        hideHeader
      />
    ),
    [data, tableNumber]
  );

  return (
    <Modal
      className="answer-csv-modal"
      title={
        <span className="header">
          <span className="header-title">
            {title}
            {showPagination && (
              <Pagination
                simple
                style={{ minWidth: '45vw' }}
                onChange={(data) => setTableNumber(data - 1)}
                total={data.length}
                defaultPageSize={1}
              />
            )}
          </span>
          <Button
            type="primary"
            onClick={() => downloadCSV(title, data[tableNumber])}
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
        </span>
      }
      centered
      open
      closable
      onCancel={(e) => {
        e.stopPropagation();
        onClose();
      }}
      footer={null}
      maskClosable={false}
    >
      {memoTable}
    </Modal>
  );
}
