import React, { useEffect } from 'react';
import { Map, GoogleApiWrapper, IMapProps } from 'google-maps-react';
// import { MarkerClusterer } from '@googlemaps/markerclusterer';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { appConfig } from '@/AppConfig';
import styles from './MapView.module.less';
import MapLegend from '../MapPreview/MapLegend';

dayjs.extend(localizedFormat);

const mapStyles = {
  width: '100%',
  height: '100%'
};

interface MapViewProps extends IMapProps {
  showMapLegend?: boolean;
  labelMap?: Record<string, string>;
  filteredList?: string[];
  onFilterChange?: any;
  lagendProps?: any;
  data?: any;
}

// IF no data, default to SF
const DEFAULT_MAP_LOCATION = { lat: 37.774546, lng: -122.433523 };
const COLOR_CONFIG = {
  lowwerBound: 3,
  upperBound: 5
};

function MapComponent(props: MapViewProps) {
  const {
    data,
    google,
    showMapLegend,
    lagendProps,
    onFilterChange,
    labelMap,
    filteredList
  } = props;

  const mapRef = React.useRef<any>(null);
  const markersRef = React.useRef<any>([]);

  const getColor = (clusterSize: number) => {
    let color = 'green';
    if (
      clusterSize >= COLOR_CONFIG.lowwerBound &&
      clusterSize <= COLOR_CONFIG.upperBound
    ) {
      color = 'yellow';
    } else if (clusterSize > 5) {
      color = 'red';
    }

    return color;
  };
  const createCircleIcon = (clusterSize: number) => {
    const color = getColor(clusterSize);
    const circle = {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 12, // Size of the circle
      fillColor: color,
      fillOpacity: 0.8,
      strokeWeight: 1,
      strokeColor: 'white'
    };
    return circle;
  };

  const clearMarkers = () => {
    if (markersRef.current === null) return;

    // Loop through the markers and set map to null for each
    markersRef.current.forEach((marker: any) => marker.setMap(null));

    // Clear the markers array
    markersRef.current = [];
  };

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.map;

      const initMap = async () => {
        // Create an InfoWindow instance
        const infowindow = new google.maps.InfoWindow();
        clearMarkers();
        const markers = data.features.map((feature: any) => {
          const [lng, lat] = feature.geometry.coordinates;
          const position = { lat, lng };
          const label = feature.properties.Label;

          // Assuming you have a property in your GeoJSON that indicates cluster size
          const clusterSize = feature.properties.Count;

          // FIXME: This map is designed to paint points with the some label with a color(deined business logic),
          // Should be moved out of this component and should be passed a parameter
          const marker = new google.maps.Marker({
            map,
            position,
            icon: createCircleIcon(clusterSize),
            label: {
              text: labelMap?.[label],
              color: getColor(clusterSize) === 'yellow' ? 'black' : 'white',
              fontSize: '10px', // Adjust font size as needed
              fontWeight: 'bold' // Optional: makes the label text bold
            }
          });

          // markers can only be keyboard focusable when they have click listeners
          // open info window when marker is clicked
          marker.addListener('click', () => {
            // Get properties of the clicked feature
            const clusterSize = feature.properties.Count;
            const coordinates = feature.geometry.coordinates;

            // Set content for the InfoWindow
            infowindow.setContent(
              `Count: ${clusterSize}<br>
              Vehicle: ${feature.properties.Label}<br>
             Collection Time: ${dayjs(feature.properties.ReceivedTime).format(
               'lll'
             )}<br>
             Coordinates: ${coordinates[1]}, ${coordinates[0]}`
            );

            // Position the InfoWindow at the clicked feature's location
            infowindow.setPosition({
              lat: coordinates[1],
              lng: coordinates[0]
            });

            // Open the InfoWindow
            infowindow.open(map);
          });

          return marker;
        });

        return markers;
      };

      initMap().then((markers) => {
        // Create the MarkerClusterer instance
        // const markerClusterer = new MarkerClusterer({ markers, map });

        markersRef.current.push(...markers);

        // Clean up
        return () => {
          // Clean up the markers and the marker clusterer when the component unmounts or dependencies change
          // markerClusterer.clearMarkers();
          clearMarkers();
        };
      });
    }
  }, [data, mapRef, google, labelMap]);

  const onMapReady = (_mapProps: any, map: any) => {
    // Access the native map object here
    const options = {
      fullscreenControl: false,
      zoomControl: false,
      // mapTypeControl: false,
      streetView: false,
      streetViewControl: false,
      zoom: 11
    };
    map.setOptions(options);
  };

  let firstPoint;
  if (data?.features?.[0]?.geometry.coordinates) {
    const point = data?.features?.[0]?.geometry.coordinates;
    firstPoint = {
      lat: point[1],
      lng: point[0]
    };
  }

  return (
    <div className={styles.mapView}>
      <Map
        ref={mapRef}
        google={props.google}
        style={mapStyles}
        initialCenter={firstPoint || DEFAULT_MAP_LOCATION}
        onReady={onMapReady}
      />
      {showMapLegend && (
        <MapLegend
          {...lagendProps}
          filteredList={filteredList}
          label={labelMap}
          onFilterChange={onFilterChange}
        />
      )}
    </div>
  );
}

// TODO: If we more than one customer ask for map solution we should go to MapBox
// As google doesn't allow rendering logic to be changed outside of their mapref, cannot be parameterized
const MapView = GoogleApiWrapper({
  apiKey: appConfig?.googleMap?.apiKey
})(MapComponent);

export default React.memo(MapView);
