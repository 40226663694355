import { gql } from '@apollo/client';

export const LIST_DEPARTMENTS = gql(`
  query ListDepartments(
    $limit: Int = 1000
    $nextToken: String
    $deptFilter: ListDepartmentsFilter
  ) {
    listDepartments(
      limit: $limit
      nextToken: $nextToken
      deptFilter: $deptFilter
    ) {
      items {
        id
        owner
        organizationId
        name
        draftKnowledgeId
        publishedKnowledgeId
        workerId
        local
        state
        errors
        createdAt
        isDeleted
        brainVersion
        features
        organization {
          id
          name
        }
        draftKnowledge {
          id
          brainVersion
        }
      }
      nextToken
    }
  }
`);

export const listAllDepartmentsQuery = /* GraphQL */ `
  query ListAllDepartments($limit: Int = 1000, $nextToken: String) {
    listAllDepartments(limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        local
        createdAt
        brainVersion
        features
        organization {
          id
          name
        }
      }
      nextToken
    }
  }
`;

export const getDepartmentQuery = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      owner
      organizationId
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      brainVersion
      features
      organization {
        id
        name
      }
      draftKnowledge {
        id
        brainVersion
      }
    }
  }
`;

export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      id
      owner
      organizationId
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      brainVersion
      features
      organization {
        id
        name
      }
      draftKnowledge {
        id
        brainVersion
      }
    }
  }
`;

export const upgradeDepartment = /* GraphQL */ `
  mutation UpgradeDepartment($departmentId: ID!, $brainVersion: String) {
    upgradeDepartment(
      departmentId: $departmentId
      brainVersion: $brainVersion
    ) {
      id
      owner
      organizationId
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      brainVersion
      features
      organization {
        id
        name
      }
      draftKnowledge {
        id
        brainVersion
      }
    }
  }
`;

export const rollbackDepartment = /* GraphQL */ `
  mutation RollbackDepartment($departmentId: ID!) {
    rollbackDepartment(departmentId: $departmentId) {
      id
      owner
      organizationId
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      brainVersion
      features
      organization {
        id
        name
      }
      draftKnowledge {
        id
        brainVersion
      }
    }
  }
`;
