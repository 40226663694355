// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconBookCard-module__iconBookCard__uY0gC {
  display: flex;
  gap: 8px;
  flex: 1 0 0;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--grey-1, #ebf2f4);
  background: #fff;
  min-width: 208px;
  overflow: hidden;
  cursor: pointer;
}
.IconBookCard-module__left__ykSQW {
  display: flex;
  align-items: center;
  padding: 16px;
  overflow: hidden;
  min-width: 68px;
}
.IconBookCard-module__icon__N4YOE {
  border-radius: 20px;
  background: var(--grey-1, #ebf2f4);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset;
  display: flex;
  width: 36px;
  height: 36px;
  padding: 10px 6px;
  justify-content: center;
  align-items: center;
}
.IconBookCard-module__disabled__oerok {
  opacity: 0.5;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/components/IconBookCard/IconBookCard.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,wCAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;EACA,eAAA;AAAF;AAEA;EACE,mBAAA;EACA,kCAAA;EACA,qDAAA;EACA,aAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;AAAF;AAGA;EACE,YAAA;EACA,mBAAA;AADF","sourcesContent":[".iconBookCard {\n  display: flex;\n  gap: 8px;\n  flex: 1 0 0;\n  align-items: center;\n  border-radius: 16px;\n  border: 1px solid var(--grey-1, #ebf2f4);\n  background: #fff;\n  min-width: 208px;\n  overflow: hidden;\n  cursor: pointer;\n}\n\n.left {\n  display: flex;\n  align-items: center;\n  padding: 16px;\n  overflow: hidden;\n  min-width: 68px;\n}\n.icon {\n  border-radius: 20px;\n  background: var(--grey-1, #ebf2f4);\n  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset;\n  display: flex;\n  width: 36px;\n  height: 36px;\n  padding: 10px 6px;\n  justify-content: center;\n  align-items: center;\n}\n\n.disabled {\n  opacity: 0.5;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBookCard": `IconBookCard-module__iconBookCard__uY0gC`,
	"left": `IconBookCard-module__left__ykSQW`,
	"icon": `IconBookCard-module__icon__N4YOE`,
	"disabled": `IconBookCard-module__disabled__oerok`
};
export default ___CSS_LOADER_EXPORT___;
