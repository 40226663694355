import React from 'react';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Space
} from 'antd';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import Mutations from '../graphql/Mutations';
import {
  CreateOrganizationMutation,
  Organization,
  OrganizationBillingStatus
} from '../generated/API';
import './CreateOrganizationPopup.less';
import { updateOrganization, userSelector } from '../stores/slices/user';
import { useAppDispatch } from '../stores/hooks';

interface IProps {
  onSuccess?: (org: Organization | null) => void;
  onClose: () => void;
  readonly?: boolean;
}

function CreateOrganizationPopup(props: IProps) {
  const { onSuccess, onClose, readonly } = props;

  const onCancel = () => {
    onClose();
  };

  const { username, organization } = useSelector(userSelector);
  const dispatch = useAppDispatch();

  const [create, { loading }] = useMutation<CreateOrganizationMutation>(
    Mutations.CreateOrganization()
  );

  const onFinish = (values: any) => {
    create({
      variables: {
        input: {
          name: values.name,
          owner: values.owner
        }
      }
    })
      .then((resp) => {
        if (resp.data?.createOrganization) {
          const newOrganization = resp.data.createOrganization;
          dispatch(updateOrganization(newOrganization));
          message.success(
            `'${newOrganization.name}' organization created successfully`
          );
          if (onSuccess) {
            onSuccess(newOrganization);
          }
        }
        onClose();
      })
      .catch((e) => {
        console.error('org create error', e);
        message.error(e.message || 'Failed to create a new organization', 5);
      });
  };

  return (
    <Modal
      title={readonly ? 'Organization' : 'Create Organization'}
      centered
      open
      onCancel={onCancel}
      footer={null}
      keyboard={false}
      maskClosable={false}
      className="create-organization-popup"
    >
      <div>
        <Form onFinish={onFinish} layout="vertical" requiredMark={!readonly}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Invalid organization name' }]}
            initialValue={readonly ? organization.data?.name : ''}
          >
            <Input
              placeholder="Organization name"
              autoComplete="off"
              spellCheck={false}
              autoFocus
              disabled={readonly}
              data-cy="create-organization-name-input"
            />
          </Form.Item>

          <Form.Item label="Owner" name="owner" initialValue={username}>
            <Input disabled />
          </Form.Item>

          {readonly && (
            <Form.Item label="Created At">
              <DatePicker
                disabled
                value={
                  organization.data ? dayjs(organization.data.createdAt) : null
                }
              />
            </Form.Item>
          )}

          {readonly && (
            <Form.Item label="Billing Status">
              <Input
                disabled
                value={
                  organization?.data?.billingStatus ===
                  OrganizationBillingStatus.UNPAID.valueOf()
                    ? 'Unpaid'
                    : 'Paid'
                }
              />
            </Form.Item>
          )}

          <Form.Item className="actions">
            <Divider />
            <Space>
              <Button key="back" onClick={onClose}>
                Cancel
              </Button>
              {!readonly && (
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                >
                  Submit
                </Button>
              )}
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default CreateOrganizationPopup;
