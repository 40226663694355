// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s3-file-viewer-popup.ant-modal {
  height: 80%;
  overflow: auto;
  pointer-events: auto;
  background-color: white;
}
.s3-file-viewer-popup .ant-modal-body {
  padding: 0;
  height: calc(100% - 64px);
}
.s3-file-viewer-popup .ant-modal-content {
  box-shadow: none;
  padding: 0;
  height: 100%;
}
.s3-file-viewer-popup .document-viewer {
  height: 100%;
}
.s3-file-viewer-popup .document-viewer .ant-card {
  height: 100%;
}
.s3-file-viewer-popup .document-viewer .ant-card .ant-card-head {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}
.s3-file-viewer-popup .document-viewer .ant-card .ant-card-body {
  margin-top: 16px;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/popups/S3FileViewerPopup.less"],"names":[],"mappings":"AAGE;EACE,WAAA;EACA,cAAA;EACA,oBAAA;EACA,uBAAA;AAFJ;AAHA;EASI,UAAA;EACA,yBAAA;AAHJ;AAME;EACE,gBAAA;EACA,UAAA;EACA,YAAA;AAJJ;AAOE;EACE,YAAA;AALJ;AAMI;EACE,YAAA;AAJN;AAKM;EACE,gBAAA;EACA,MAAA;EACA,UAAA;EACA,uBAAA;AAHR;AAMM;EACE,gBAAA;EACA,YAAA;AAJR","sourcesContent":["@import '@css/variables.less';\n\n.s3-file-viewer-popup {\n  &.ant-modal {\n    height: 80%;\n    overflow: auto;\n    pointer-events: auto;\n    background-color: white;\n  }\n\n  .ant-modal-body {\n    padding: 0;\n    height: calc(100% - 64px);\n  }\n\n  & .ant-modal-content {\n    box-shadow: none;\n    padding: 0;\n    height: 100%;\n  }\n\n  & .document-viewer {\n    height: 100%;\n    & .ant-card {\n      height: 100%;\n      & .ant-card-head {\n        position: sticky;\n        top: 0;\n        z-index: 1;\n        background-color: white;\n      }\n\n      & .ant-card-body {\n        margin-top: 16px;\n        height: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
