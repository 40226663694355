import { gql, useSubscription } from '@apollo/client';
import { Typography, Avatar, Tag } from 'antd';
import React from 'react';
import { onWorkerStatus } from '@/generated/graphql/subscriptions';
import { OnWorkerStatusSubscription, Worker } from '@/generated/API';
import { Comment } from '@ant-design/compatible';
import './WorkerStatus.less';
import classNames from 'classnames';
import RunDebugDetails from './RunDebugDetails';
import Loader from './Loader';

const DEFAULT_MOOD = '😐';

interface IProps {
  worker: Worker;
}

function WorkerStatusInternal(props: IProps) {
  const { worker } = props;

  // const status = useState(null);
  const { data } = useSubscription<OnWorkerStatusSubscription>(
    gql(onWorkerStatus),
    {
      variables: { id: worker.id },
      skip: worker.id === null,
      shouldResubscribe: true
    }
  );

  const { mood, icons, operation, what } = data?.onWorkerStatus
    ? data.onWorkerStatus
    : {
        mood: DEFAULT_MOOD,
        icons: [''],
        operation: ``,
        what: ``
      };

  return (
    <Comment
      className={classNames('worker-status', {
        'author-center': !operation || !what,
        'author-margin': !what
      })}
      actions={[]}
      author={<span>Brain {icons}</span>}
      avatar={
        <RunDebugDetails worker={worker} workerId={worker.id} showCreatedAt>
          <Avatar size="large">{mood}</Avatar>
        </RunDebugDetails>
      }
      content={
        <span className="content">
          <Typography.Text strong className="operation">
            {operation}
          </Typography.Text>
          {what ? (
            <Tag>
              <Typography.Text ellipsis>{what}</Typography.Text>
            </Tag>
          ) : null}
        </span>
      }
    />
  );
}

function WorkerStatus(props: IProps) {
  const { worker } = props;

  if (!worker) {
    return <Loader message="Loading worker..." />;
  }

  return <WorkerStatusInternal worker={worker} />;
}

export default WorkerStatus;
