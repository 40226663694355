import React from 'react';
import Icon from '@ant-design/icons';

function PlayCircleSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM12 20.3036C7.41518 20.3036 3.69643 16.5848 3.69643 12C3.69643 7.41518 7.41518 3.69643 12 3.69643C16.5848 3.69643 20.3036 7.41518 20.3036 12C20.3036 16.5848 16.5848 20.3036 12 20.3036Z"
        fill="#F57F2A"
      />
      <path
        d="M15.6629 11.8505L10.7879 8.3058C10.7612 8.28643 10.7297 8.27482 10.6968 8.27227C10.664 8.26972 10.631 8.27632 10.6017 8.29135C10.5723 8.30637 10.5477 8.32923 10.5305 8.35739C10.5134 8.38555 10.5043 8.41792 10.5044 8.45089V15.5402C10.5045 15.5731 10.5136 15.6054 10.5308 15.6334C10.548 15.6615 10.5726 15.6843 10.6019 15.6993C10.6312 15.7143 10.6641 15.7209 10.6969 15.7184C10.7297 15.716 10.7612 15.7045 10.7879 15.6853L15.6629 12.1384C15.686 12.1221 15.7047 12.1005 15.7177 12.0755C15.7307 12.0504 15.7374 12.0226 15.7374 11.9944C15.7374 11.9662 15.7307 11.9384 15.7177 11.9134C15.7047 11.8883 15.686 11.8667 15.6629 11.8505Z"
        fill="#F57F2A"
      />
    </svg>
  );
}

export default function PlaygroundPlayIcon(props: {
  style?: React.CSSProperties;
}) {
  return <Icon component={PlayCircleSvg} {...props} />;
}
