import React, { useEffect } from 'react';
import { Menu, message, Divider, Input, Space } from 'antd';
import AppConstants from '@utils/AppConstants';
import { useAppDispatch, useAppSelector } from '@/stores/hooks';
import {
  departmentQuerySelector,
  selectDepartment
} from '@/stores/slices/department';
import { useLocation, useNavigate } from 'react-router-dom';
import { showPopup } from '@/stores/slices/appPopup';
import AppUtil from '@/utils/AppUtil';
import { useSelector } from 'react-redux';
import routes from '@/utils/routes';
import {
  Department,
  ListDepartmentsQuery,
  ListDepartmentsQueryVariables
} from '@/generated/API';
import { userSelector } from '@/stores/slices/user';
import { useLazyQuery } from '@apollo/client';
import { LIST_DEPARTMENTS } from '@/graphql/department';
import classnames from 'classnames';
import { DepartmentPermissionsEnum } from '@/utils/permissionConstants';
import { uniqBy } from 'lodash';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import styles from './DepartmentSelectorMenu.module.less';
import AccessControl from '../AccessControl/AccessControl';

interface IProps {
  hide: () => void;
  departments: Department[];
  setDepartments: React.Dispatch<React.SetStateAction<Department[]>>;
}

function DepartmentSelectorMenu(props: IProps) {
  const { hide, departments, setDepartments } = props;
  const location = useLocation();

  const { organization, username } = useSelector(userSelector);
  const { department } = useAppSelector(departmentQuerySelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const canCreateDepartment = AppUtil.canCreateDepartment(
    username,
    organization.data
  );

  const [fetchDepartmentsQuery, { loading }] =
    useLazyQuery<ListDepartmentsQuery>(LIST_DEPARTMENTS, {
      onError(error) {
        message.error(error.message);
      }
    });

  const fetchDepartments = async (
    searchValue: string | null
  ): Promise<Department[]> => {
    const variables: ListDepartmentsQueryVariables = {
      limit: 10
    };
    if (searchValue) {
      variables.deptFilter = {
        name: searchValue
      };
    }

    const query = await fetchDepartmentsQuery({
      variables
    });

    const deps = (query.data?.listDepartments?.items || []) as Department[];
    setDepartments(deps);
    return deps;
  };

  useEffect(() => {
    if (departments.length === 0) {
      fetchDepartments(null);
    }
  }, []);

  const handleDepartmentChange = (dep: Department) => {
    dispatch(selectDepartment(dep));
    message.success('Department changed successfully');
    hide();
    if (location.pathname.includes('playground')) {
      navigate(routes.playground.getPlaygroundPageUrl(dep.id));
    } else if (location.pathname.includes('process')) {
      navigate(routes.procedure.getProcedureHomePageUrl());
    }
  };

  const departmentsToShow = uniqBy(
    [department, ...departments].filter(Boolean),
    'id'
  );

  const renderContent = () => {
    if (loading) {
      return (
        <Menu.Item className={styles.menuItem} key="none" disabled>
          <Space>
            <LoadingOutlined /> Loading recent departments...
          </Space>
        </Menu.Item>
      );
    }

    if (departments.length === 0) {
      <Menu.Item className={styles.menuItem} key="none" disabled>
        No Departments
      </Menu.Item>;
    }

    return departmentsToShow.map((dept) => (
      <Menu.Item
        className={classnames([
          styles.menuItem,
          { [styles.activeItem]: department?.id === dept.id }
        ])}
        data-cy={dept.name}
        key={dept.id}
        onClick={() => {
          handleDepartmentChange(dept);
        }}
      >
        {dept.name}
      </Menu.Item>
    ));
  };

  return (
    <span className={styles.departmentMenu}>
      <Menu
        className={styles.menuContainer}
        {...{
          'data-cy': loading ? 'department-selector-content-spinner' : undefined
        }}
      >
        <span className={styles.topMenu}>
          <Input
            prefix={
              <SearchOutlined
                style={{ color: '#C2C2D0', marginRight: '8px' }}
              />
            }
            className={styles.departmentSearch}
            size="middle"
            placeholder="Search by name"
            onChange={(e) => {
              if (e.target.value === '') {
                fetchDepartments(null);
              } else {
                fetchDepartments(e.target.value);
              }
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            autoFocus
            spellCheck={false}
            autoComplete="off"
            data-cy="departments-selector-search"
            allowClear
          />
          <Divider className={styles.menuDivider} />
          {renderContent()}
        </span>
        <span className={styles.bottomMenu}>
          <Divider className={styles.menuDivider} />
          {canCreateDepartment && (
            <AccessControl
              requiredPermissions={[DepartmentPermissionsEnum.DepartmentCreate]}
            >
              <Menu.Item
                className={styles.menuItem}
                key="create-new"
                data-cy="create-new-department"
                onClick={() => {
                  dispatch(
                    showPopup({
                      popupId: AppConstants.POPUPS.CREATE_DEPARTMENT,
                      popupParams: {
                        onSuccess: handleDepartmentChange
                      }
                    })
                  );
                  hide();
                }}
              >
                Create New
              </Menu.Item>
            </AccessControl>
          )}
          <AccessControl
            requiredPermissions={[DepartmentPermissionsEnum.DepartmentRead]}
          >
            <Menu.Item
              className={styles.menuItem}
              key="view-all"
              data-cy="department-selector-view-all"
              onClick={() => {
                navigate(routes.department.getDepartmentsPageUrl());
                hide();
              }}
            >
              View All
            </Menu.Item>
          </AccessControl>
        </span>
      </Menu>
    </span>
  );
}

export default DepartmentSelectorMenu;
