// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 32px;
}
.answer-concepts-table-modal {
  max-width: 80%;
  max-height: 80%;
  width: unset !important;
}
.answer-concepts-table-modal .ant-modal-content {
  max-width: 100%;
}
.answer-concepts-table-modal .ant-modal-content .ant-modal-body {
  overflow: auto;
  max-height: 75vh;
}
.answer-concepts-table-modal .ant-modal-header {
  padding-bottom: 8px;
  border-bottom: 1px solid lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/popups/AnswerConceptsTablePopup.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAEA;EACE,cAAA;EACA,eAAA;EACA,uBAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AAEI;EACE,cAAA;EACA,gBAAA;AAAN;AAVA;EAeI,mBAAA;EACA,kCAAA;AAFJ","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-right: 32px;\n}\n\n.answer-concepts-table-modal {\n  max-width: 80%;\n  max-height: 80%;\n  width: unset !important;\n\n  & .ant-modal-content {\n    max-width: 100%;\n\n    & .ant-modal-body {\n      overflow: auto;\n      max-height: 75vh;\n    }\n  }\n\n  .ant-modal-header {\n    padding-bottom: 8px;\n    border-bottom: 1px solid lightgray;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
