// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditorLineResult-module__resultText__OrBpM {
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  color: #748f9e;
}
.EditorLineResult-module__loopResult__FXnA7 {
  display: flex;
  align-items: center;
}
.EditorLineResult-module__editorLineResult__jaNVJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  max-width: 200px;
  user-select: none;
  cursor: pointer;
  margin-right: 20px;
}
.EditorLineResult-module__editorLineResult-MINI_PLAYGROUND__OA1Uz {
  cursor: not-allowed;
  margin-right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PlaygroundV3/components/EditorLineResult/EditorLineResult.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AADF;AAIA;EACE,mBAAA;EACA,kBAAA;AAFF","sourcesContent":[".resultText {\n  font-weight: 300;\n  font-style: italic;\n  font-size: 14px;\n  color: #748f9e;\n}\n\n.loopResult {\n  display: flex;\n  align-items: center;\n}\n\n.editorLineResult {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: max-content;\n  max-width: 200px;\n  user-select: none;\n  cursor: pointer;\n  margin-right: 20px;\n}\n\n.editorLineResult-MINI_PLAYGROUND {\n  cursor: not-allowed;\n  margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultText": `EditorLineResult-module__resultText__OrBpM`,
	"loopResult": `EditorLineResult-module__loopResult__FXnA7`,
	"editorLineResult": `EditorLineResult-module__editorLineResult__jaNVJ`,
	"editorLineResult-MINI_PLAYGROUND": `EditorLineResult-module__editorLineResult-MINI_PLAYGROUND__OA1Uz`
};
export default ___CSS_LOADER_EXPORT___;
