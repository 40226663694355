import { IExceptionSelectedFilters } from '../pages/exceptions/interface';
import AppUtil from './AppUtil';

const FILTERS_LOCAL_STORAGE_KEY = 'EXCEPTION_CENTRE_LOCAL_FILTERS';

export const prepareExceptionCentreUrlParams = (
  filters: IExceptionSelectedFilters,
  activeExceptionId: string | null
): URLSearchParams => {
  const params = new URLSearchParams();
  Object.entries(filters).forEach(([key, value]) => {
    if (value && value.length > 0) {
      params.append(key, value.join(','));
    }
  });

  if (activeExceptionId) {
    params.set('activeExceptionId', activeExceptionId);
  }

  return params;
};

export const getExceptionCentreFiltersFromSearchString = (
  searchString: string
): IExceptionSelectedFilters => {
  const params = new URLSearchParams(searchString);

  const filters: IExceptionSelectedFilters = {
    process: [],
    state: [],
    dateRange: [],
    assignee: [],
    typename: [],
    searchText: []
  };
  params.forEach((value, key) => {
    if (key === 'searchText') {
      filters[key] = [value];
    } else {
      (filters as any)[key] = value.split(',');
    }
  });
  return filters;
};

export const saveExceptionCentreFilters = (
  filters: IExceptionSelectedFilters
) => {
  AppUtil.setInLocalStorage(FILTERS_LOCAL_STORAGE_KEY, JSON.stringify(filters));
};

export const getExceptionCentreLocalFilters =
  (): IExceptionSelectedFilters | null => {
    const localData = AppUtil.getFromLocalStorage(FILTERS_LOCAL_STORAGE_KEY);
    if (localData) {
      return JSON.parse(localData);
    }
    return null;
  };

export const getActiveExceptionIdFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('activeExceptionId');
};

export const getDocumentExceptionFile = (uiConcepts: string): string | null => {
  const concepts = AppUtil.safeParseJSON(uiConcepts);
  const fileConcept = concepts.find((concept: any) =>
    concept.fact_types.includes('FILE')
  );
  return fileConcept?.ids ? fileConcept.ids[0] : null;
};

export const getDocumentUrlFromContextMetadata = (
  contextMetadata: string | null
): string | null => {
  if (!contextMetadata) return null;
  const conceptMetaData = AppUtil.safeParseJSON(contextMetadata);
  let s3Url = null;
  if (conceptMetaData?.fact_types?.length) {
    if (conceptMetaData.fact_types.includes('FILE')) {
      s3Url = conceptMetaData.display_values?.[0] || null;
    }
  }
  return s3Url;
};
