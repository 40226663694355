import { useRunCtxV2 } from '@/provider/runv2';
import FormattingUtil, { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
// import { Step } from '@/generated/API';
import useFactV2 from '@/hooks/useFactV2';
import React from 'react';
import './ShortAnswer.less';
import RunFactRendererV2 from '@/components/playground2/RunFactRendererV2';
import { getFactType } from '@/utils/facts';
import AppUtil from '@/utils/AppUtil';
import { TabKeys } from '@/pages/PlaygroundV3/components/OutputPanel/OutputPanel';
import { BulbFilled } from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';
import { departmentQuerySelector } from '@/stores/slices/department';
import { useAppSelector } from '@/stores/hooks';
import { nodeId } from './RunContextUtil';

interface IProps {
  step: any; // TODO: Need to be changed from any to actual type, changed to any so that it works with both playground v2 and v3
  onClick?: any;
}

function ShortAnswerWrapper(props: IProps) {
  const { step, onClick } = props;
  const answer = step.answer;

  const { department, loading: departmentLoading } = useAppSelector(
    departmentQuerySelector
  );
  const { worker, openLongAnswerForNode, showLongAnswerForNode } =
    useRunCtxV2();
  const pgV3 =
    AppUtil.isDocumentModelSupported(department) &&
    AppUtil.isDocumentModel(worker);

  let cptIds: string[] = [];
  // Answer can be null or an object
  const parsedAnswer = answer ? FormattingUtil.parseBrainValue(answer) : {};
  // const editableValue = getTerminalValue(parsedAnswer);
  if (parsedAnswer?.concept) {
    cptIds = [parsedAnswer?.concept?.id];
  }
  if (parsedAnswer?.concepts) {
    parsedAnswer.concepts.items.forEach((cpt: any) => {
      const ans = cpt ? FormattingUtil.decodeBrainValue(cpt) : {};
      cptIds.push(ans?.concept?.id);
    });
  }

  const {
    getFactById,
    loading,
    ids: currentCpts
  } = useFactV2({
    ids: cptIds,
    knowledgeId: worker!.knowledgeId!,
    epoch: step.epoch
  });

  if (departmentLoading && !department) {
    return null;
  }

  if (!worker) {
    return null;
  }

  if (!parsedAnswer) {
    return null;
  }

  const handleShowLongAnswer: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (pgV3) {
      if (onClick) {
        onClick(step, TabKeys.Results);
      }
    } else {
      const id = nodeId(step);
      if (showLongAnswerForNode !== id) {
        openLongAnswerForNode(id);
      } else {
        openLongAnswerForNode('');
      }
    }
  };

  const allFacts = currentCpts.map((id) => getFactById(id, step.epoch));

  if (allFacts.length === 0) {
    return <Tooltip title="No result">-</Tooltip>;
  }

  // if (allFacts.filter((i) => i).length < currentCpts.length) {
  //   return <span>...</span>;
  // }

  const allFactTypes = allFacts.map((fact) => getFactType(fact));
  const firstFact = allFacts[0];
  const factType = allFactTypes[0];

  if (firstFact && factType.type === FormattedAnswerTypeV2.GEO_JSON_DICT) {
    return (
      <RunFactRendererV2
        fact={firstFact}
        factType={factType}
        showIcons
        knowledgeId={worker?.knowledgeId!}
        onClick={() => onClick(step, TabKeys.Results, true)}
      />
    );
  }

  if (firstFact && factType.type === FormattedAnswerTypeV2.TABLES_ROW) {
    const text = `view ${cptIds.length} ${
      parsedAnswer.concepts?.name || 'values'
    }`;
    return (
      <RunFactRendererV2
        fact={firstFact}
        factType={factType}
        knowledgeId={worker?.knowledgeId!}
        showIcons={pgV3}
        title={text}
        stepAnswer={answer!}
      />
    );
  }

  const areAllFactsTables =
    allFactTypes.filter((ft) => ft.type === FormattedAnswerTypeV2.TABLE)
      .length === allFacts.length;
  if (factType.type === FormattedAnswerTypeV2.TABLE && areAllFactsTables) {
    return (
      <Badge
        color="#99B3B8"
        count={allFacts.length > 1 ? allFacts.length : 0}
        size="small"
      >
        <RunFactRendererV2
          fact={firstFact!}
          factType={{ ...factType, answer: allFactTypes.map((i) => i.answer) }}
          knowledgeId={worker?.knowledgeId!}
          showIcons={pgV3}
          // onEdit={
          //   pgV3
          //     ? undefined
          //     : (data, idx?: number): Promise<unknown> => {
          //         if (!data) {
          //           return new Promise((_, reject) => {
          //             reject();
          //           });
          //         }
          //         if (!listId) {
          //           const id = parsedAnswer.concept.id;
          //           return updateFact(id, data, 'table').then(() =>
          //             refetchFacts([id])
          //           );
          //         }
          //         return updateFactWithMultipleValues(
          //           listId,
          //           idx!,
          //           data,
          //           'table'
          //         ).then(() => refetchFacts([listId]));
          //       }
          // }
        />
      </Badge>
    );
  }

  if (parsedAnswer.concepts) {
    const text = `view ${cptIds.length} ${parsedAnswer.concepts?.name}`;
    return (
      <div
        data-cy={text}
        className="short-answer view-link"
        onClick={handleShowLongAnswer}
      >
        {pgV3 ? (
          <Tooltip
            destroyTooltipOnHide
            title={text}
            color="#fff"
            overlayInnerStyle={{
              color: 'black'
            }}
          >
            <BulbFilled
              style={{ color: '#748f9e', width: '16px', height: '16px' }}
            />
          </Tooltip>
        ) : (
          text
        )}
      </div>
    );
  }

  if (loading) {
    return <div className="short-answer">...</div>;
  }

  if (factType.type === FormattedAnswerTypeV2.S3) {
    return firstFact ? (
      <RunFactRendererV2
        fact={firstFact}
        factType={factType}
        knowledgeId={worker?.knowledgeId!}
        showIcons={!!pgV3}
      />
    ) : null;
  }

  if (firstFact?.locations) {
    return (
      <div className="short-answer view-link" onClick={handleShowLongAnswer}>
        {pgV3 ? (
          <Tooltip
            destroyTooltipOnHide
            title="view answer"
            color="#fff"
            overlayInnerStyle={{
              color: 'black'
            }}
          >
            <BulbFilled
              style={{ color: '#748f9e', width: '16px', height: '16px' }}
            />
          </Tooltip>
        ) : (
          'view answer'
        )}
      </div>
    );
  }

  if (
    factType.type === FormattedAnswerTypeV2.LONG ||
    factType.type === FormattedAnswerTypeV2.MARKDOWN ||
    factType.type === FormattedAnswerTypeV2.SRC_CONCEPT ||
    factType.type === FormattedAnswerTypeV2.SENSITIVE
  ) {
    return (
      <div
        className="short-answer view-link"
        data-cy="short-answer-view-link"
        onClick={handleShowLongAnswer}
      >
        {pgV3 ? (
          <Tooltip
            destroyTooltipOnHide
            title="view answer"
            color="#fff"
            overlayInnerStyle={{
              color: 'black'
            }}
          >
            <BulbFilled
              style={{ color: '#748f9e', width: '16px', height: '16px' }}
            />
          </Tooltip>
        ) : (
          'view answer'
        )}
      </div>
    );
  }

  return firstFact ? (
    <RunFactRendererV2
      fact={firstFact}
      factType={factType}
      knowledgeId={worker?.knowledgeId!}
      showIcons={!!pgV3}
    />
  ) : null;
}

const ShortAnswer = (props: IProps) => {
  const { step } = props;
  const { worker } = useRunCtxV2();
  if (!step) {
    return null;
  }

  const answer = step.answer;
  if (!answer) {
    return null;
  }

  if (!worker) {
    return null;
  }

  return <ShortAnswerWrapper {...props} />;
};

export default ShortAnswer;
