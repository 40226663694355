//
//  Copyright (C) - Kognitos. All rights reserved
//
// Simple i18n util class ...
//

// 3rd party libraries
import _get from 'lodash/fp/get';

// Local imports
import i18nStrings from '../i18n/en_us.json';

const i18n = {
  // Translate a string. Looks for the translation under the given moduleName.
  // If not found, looks under common.
  // If not found, returns the defaultValue
  t(moduleName, key, defaultValue) {
    let ret = _get(`${moduleName}.${key}`, i18nStrings);
    if (!ret) {
      ret = _get(`common.${key}`, i18nStrings);
    }
    return ret || defaultValue;
  }
};

export default i18n;
