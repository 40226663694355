import { useContext, useState } from 'react';
import { FactsContext } from '@/pages/PlaygroundV3/components/FactsContext/FactsProvider';
import { useLazyQuery } from '@apollo/client';
import { GET_HISTORICAL_FACTS } from '@/graphql/queries/fact';
import FormattingUtil, { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import { useCustomCompareLayoutEffect } from 'use-custom-compare';
import { isEqual } from 'lodash';
import AppUtil from '@/utils/AppUtil';
import { message } from 'antd';
import { Fact, GetHistoricalFactsQuery } from '../generated/API';

export enum FACT_STATUS {
  INIT = 'INIT',
  LOADING = 'LOADING',
  FETCHED = 'FETCHED'
}

export interface FactAnswer extends Fact {
  status: FACT_STATUS;
  fact_type?: FormattedAnswerTypeV2;
  display_value?: string;
  is_location_present?: boolean;
  parent_fact_id?: string;
  are_relations_present?: boolean;
}

interface IProps {
  cptList: FactAnswer[];
  knowledgeId: string | null;
  epoch?: number;
  dontFetch?: boolean;
}

// takes an array of facts from sentence execution data
// returns -> facts object and ids array
// Initializes facts in context
// checks all facts for _large_value_, __src_concept__, __sensitive__ and parentFactId
// creates a queue of facts-to-fetch from the above
// Now it keeps on fetching in batches of LOADING_BATCH_SIZE
// For table's rows, we only care about _large_value_ so If any row has a _large_value_ we fetch those facts at once, and render

export const prepareFactKey = (id: string, epoch?: number) =>
  `${id}-${epoch || 0}`;

const LOADING_BATCH_SIZE = 10;

function useLineFact(props: IProps) {
  const { facts, setFacts } = useContext(FactsContext);
  const { cptList, epoch, knowledgeId, dontFetch } = props;
  const factIds = cptList.map((i) => i.id);

  // This state will keep on fetching
  const [factIdsToFetch, setFactIdsToFetch] = useState<string[]>([]);

  const [fetchFacts] = useLazyQuery<GetHistoricalFactsQuery>(
    GET_HISTORICAL_FACTS,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      onCompleted: (resp) => {
        if (resp?.getHistoricalFacts) {
          const responseFacts = (resp?.getHistoricalFacts! as Fact[]) || [];
          const oldFacts = { ...facts };
          const factsMap = responseFacts.reduce<Record<string, FactAnswer>>(
            (acc, cur) => {
              if (cur && cur.dereferencedId) {
                const key = prepareFactKey(cur.dereferencedId, epoch);
                const fact = oldFacts[key];
                let parsedValue = FormattingUtil.parseBrainValue(cur.value!);

                // For table's rows the parsing is different
                if (
                  fact?.fact_type === FormattedAnswerTypeV2.TABLES_ROW ||
                  fact?.fact_type === FormattedAnswerTypeV2.JSON
                ) {
                  parsedValue = AppUtil.parseWithoutNaN(cur.value);
                }

                // For result type we need the result value from object
                if (
                  fact?.fact_type === FormattedAnswerTypeV2.RESULT &&
                  typeof parsedValue === 'object'
                ) {
                  parsedValue = parsedValue.result;
                }

                acc[key] = {
                  ...fact,
                  ...cur,
                  fact_type:
                    fact.fact_type || (cur.type as FormattedAnswerTypeV2),
                  value: parsedValue,
                  status: FACT_STATUS.FETCHED
                };
              }
              return acc;
            },
            {}
          );
          const updatedIds = factIdsToFetch.slice(LOADING_BATCH_SIZE);
          setFacts((prev) => {
            const updatedFacts = { ...prev, ...factsMap };
            const fetchIds = updatedIds.slice(0, LOADING_BATCH_SIZE);
            fetchIds.forEach((id) => {
              updatedFacts[prepareFactKey(id, epoch)] = {
                ...updatedFacts[prepareFactKey(id, epoch)],
                status: FACT_STATUS.LOADING
              };
            });
            return updatedFacts;
          });
          setFactIdsToFetch(updatedIds);

          if (updatedIds.length > 0) {
            fetchFacts({
              variables: {
                factIds: updatedIds
                  .slice(0, LOADING_BATCH_SIZE)
                  .map((id) => ({ id, epoch: epoch || 0 })),
                knowledgeId
              }
            });
          }
        }
      },
      onError() {
        message.error('Something went wrong, please refresh the page');
      }
    }
  );

  useCustomCompareLayoutEffect(
    () => {
      const oldFacts = facts;

      const newFacts = cptList.reduce((acc, curr) => {
        const key = prepareFactKey(curr.id, epoch);
        acc[key] = {
          ...curr,
          value: curr.display_value,
          // @ts-ignore
          // the spread below overwrites the status value causes a ts error, but that is what I intend to do, hance ignored
          status: FACT_STATUS.INIT,
          ...oldFacts?.[key]
        };
        return acc;
      }, {} as any);

      const allFacts = { ...oldFacts, ...newFacts };

      const idsToFetch = cptList
        .filter((fact) => {
          const factData = allFacts[prepareFactKey(fact.id, epoch)];
          if (factData.status === FACT_STATUS.FETCHED) return false;

          const isFactTypeUnknown =
            factData && (factData.fact_type == null || !factData.fact_type);

          const isFactValueNull = factData && factData.value == null;

          const isFactValueNotSent =
            typeof factData.value === 'string' &&
            (factData.value.includes('__large_value_') ||
              factData.value.includes('__src_concept__') ||
              factData.value.includes('__sensitive__'));

          const valueUnknown = isFactValueNull || isFactValueNotSent;

          const isLocationUnknown =
            fact.fact_type === FormattedAnswerTypeV2.STRING &&
            fact.is_location_present &&
            !factData?.locations;

          const areRelationsPresent = fact.are_relations_present;

          // Filter fact ids to fetch if
          // 1. it is a table's row
          // 2. it is a json
          // 3. it has locations
          // 4. value unknown
          return (
            fact.fact_type === FormattedAnswerTypeV2.TABLES_ROW ||
            fact.fact_type === FormattedAnswerTypeV2.GEO_JSON_DICT ||
            fact.fact_type === FormattedAnswerTypeV2.JSON ||
            isLocationUnknown ||
            areRelationsPresent ||
            valueUnknown ||
            isFactTypeUnknown
          );
        })
        .map((fact) => fact.id);

      if (idsToFetch.length > 0) {
        const fetchIds = idsToFetch.slice(0, LOADING_BATCH_SIZE);
        const updatedFacts = { ...allFacts };
        fetchIds.forEach((id) => {
          updatedFacts[prepareFactKey(id, epoch)] = {
            ...updatedFacts[prepareFactKey(id, epoch)],
            status: FACT_STATUS.LOADING
          };
        });
        setFacts((prev) => ({ ...prev, ...updatedFacts }));
        setFactIdsToFetch(() => idsToFetch);

        if (!dontFetch) {
          fetchFacts({
            variables: {
              factIds: fetchIds.map((id) => ({ id, epoch: epoch || 0 })),
              knowledgeId
            }
          });
        }
      } else {
        setFacts((prev) => ({ ...prev, ...allFacts }));
        setFactIdsToFetch(() => idsToFetch);
      }
    },
    [cptList, epoch, dontFetch],
    (prev, next) => isEqual(prev, next)
  );

  return { facts, factIds };
}

export default useLineFact;
