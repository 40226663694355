// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubDocumentPopover-module__container__Yn6dX {
  position: absolute;
  display: none;
  top: 100%;
  z-index: 1;
  width: 250px;
  min-height: 80px;
  padding: 15px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  right: 50px;
}
.SubDocumentPopover-module__visible__rYRtV {
  display: block;
}
.SubDocumentPopover-module__procedureItem__VI18P {
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #fff;
  display: block;
  border-radius: 3px;
}
.SubDocumentPopover-module__procedureItem__VI18P:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
`, "",{"version":3,"sources":["webpack://./src/pages/PlaygroundV3/components/SubDocumentPopupover/SubDocumentPopover.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,oHAAA;EAEA,WAAA;AAAF;AAEA;EACE,cAAA;AAAF;AAGA;EACE,eAAA;EACA,YAAA;EACA,6BAAA;EACA,cAAA;EACA,kBAAA;AADF;AAEE;EACE,qCAAA;AAAJ","sourcesContent":[".container {\n  position: absolute;\n  display: none;\n  top: 100%;\n  z-index: 1;\n  width: 250px;\n  min-height: 80px;\n  padding: 15px;\n  border-radius: 6px;\n  background: #ffffff;\n  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),\n    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);\n  right: 50px;\n}\n.visible {\n  display: block;\n}\n\n.procedureItem {\n  cursor: pointer;\n  padding: 5px;\n  border-bottom: 1px solid #fff;\n  display: block;\n  border-radius: 3px;\n  &:hover {\n    background-color: rgba(0, 0, 0, 0.04);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SubDocumentPopover-module__container__Yn6dX`,
	"visible": `SubDocumentPopover-module__visible__rYRtV`,
	"procedureItem": `SubDocumentPopover-module__procedureItem__VI18P`
};
export default ___CSS_LOADER_EXPORT___;
