import React, { useEffect } from 'react';
import { Typography } from 'antd';
import classnames from 'classnames';
import AppUtil from '@/utils/AppUtil';
import { uniqBy } from 'lodash';
import styles from './SubDocumentPopover.module.less';
import { SubDocument } from '../Editor/editorInterface';

interface SubDocumentPopoverProps {
  documentTokens: SubDocument[];
  visible: boolean;
  hidePopover: () => void;
  onProcedureClick: (token: SubDocument) => void;
}

function SubDocumentPopover(props: SubDocumentPopoverProps) {
  const {
    documentTokens: initialDocumentTokens,
    visible,
    onProcedureClick,
    hidePopover
  } = props;
  const popoverConatiner = React.useRef<HTMLDivElement | null>(null);

  const documentTokens = uniqBy(initialDocumentTokens, 'tokenName');

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      // Do whatever when esc is pressed
      hidePopover();
    }
  };

  useEffect(() => {
    // On click of editorcontainer hide the output panel
    const handleClickOutside = (event: any) => {
      if (
        popoverConatiner.current &&
        !popoverConatiner.current.contains(event.target)
      ) {
        hidePopover();
      }
    };
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [popoverConatiner.current]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div
      className={classnames(styles.container, { [styles.visible]: visible })}
      contentEditable={false}
      ref={popoverConatiner}
    >
      {documentTokens?.map((i) => (
        <Typography.Text
          key={AppUtil.removeToFromName(i.processName)}
          data-cy={AppUtil.removeToFromName(i.processName)}
          className={styles.procedureItem}
          onClick={(e) => {
            e.stopPropagation();
            onProcedureClick(i);
          }}
        >
          {AppUtil.removeToFromName(i.processName)}
        </Typography.Text>
      ))}
    </div>
  );
}

export default SubDocumentPopover;
