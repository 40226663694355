import React from 'react';
import './RunItemDetailsV2.less';
import Loader from '@/components/Loader';
import { ContextConnection, Step } from '../../../generated/API';
import RunContextV3 from './RunContextV3';

interface IProps {
  step: Step;
  context: ContextConnection;
  readOnly: boolean;
}

// Component implementation
function RunItemDetailsV2(props: IProps) {
  const { step, context, readOnly } = props;
  if (step && step?.childContextIdList?.length! > 0 && !context) {
    return (
      <span style={{ height: 28 }}>
        <Loader />
      </span>
    );
  }

  let stepsBlock;
  if (step?.childContextIdList?.length && context?.items?.length) {
    stepsBlock = context.items.map((item) => (
      <RunContextV3
        key={item?.id}
        context={item}
        isRoot={false}
        readOnly={readOnly}
      />
    ));
  }

  if (!stepsBlock) {
    return null;
  }

  return (
    <div className="run-item-details-container">
      <div className="run-item-details">{stepsBlock}</div>
    </div>
  );
}

export default RunItemDetailsV2;
