import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import AppConstants from '@utils/AppConstants';
import S3FileUpload from '@/components/Upload';
import './NewRunItemV3.less';
import {
  CloseOutlined,
  LoadingOutlined,
  PlayCircleFilled
} from '@ant-design/icons';
import classNames from 'classnames';
import useEditorTabKey from '@/hooks/useEditorTabKey';
import reactStringReplace from 'react-string-replace';
import AppUtil from '../../../utils/AppUtil';
import { useRunCtxV2 } from '../../../provider/runv2';
import usePlaygroundServiceCommands from '../../../hooks/usePlaygroundServiceCommands';
import { INewStepItemConfig } from './RunContextV3';
import InlineInfiniteLoader from '../../../components/InlineInfiniteLoader';
import { useRefresh } from '../../../provider/refresh';

enum KEY_PRESS_EVENTS {
  PASTE = 'PASTE'
}

interface IProps {
  newStepItemConfig: INewStepItemConfig;
}

function OnInput(this: any) {
  this.style.height = 0;
  this.style.height = `${this.scrollHeight}px`;
}

const getFilesObj = (fileList: { s3Url: string }[]) =>
  fileList
    .map((i) => i.s3Url.match(AppConstants.PATTERNS.S3_URI)?.[0])
    .filter((i) => i)
    .reduce((acc, curr) => {
      const currSplit = curr!.split('/');
      return { ...acc, [currSplit[currSplit.length - 1]]: curr };
    }, {});

const replaceFileUrlWithName = (
  filesObj: Record<string, string>,
  text: string
) => {
  let newText = text;
  Object.keys(filesObj).forEach((key) => {
    newText = newText.replaceAll(filesObj[key], key);
  });
  return newText;
};

const getFilesAndText = (fileList: { s3Url: string }[], oldText: string) => {
  const filesObj: Record<string, string> = getFilesObj(fileList);
  const text = replaceFileUrlWithName(
    filesObj,
    AppUtil.prepareFilesUploadCommand(fileList, oldText)
  );
  return { filesObj, text };
};

function NewRunItem2(props: IProps) {
  const { newStepItemConfig } = props;
  const { enableTabToIndent } = useEditorTabKey();

  const {
    onClose = () => {},
    closeable = false,
    defaultValue = '',
    placeholder,
    onSubmit
  } = newStepItemConfig;

  const [text, setText] = useState('');
  const [filesObj, setFilesObj] = useState<Record<string, string>>({});
  const [url, setUrl] = useState('');
  const [json, setJson] = useState('');
  const [markdown, setMarkdown] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailHtml, setEmailHtml] = useState('');
  const [isLoading, setLoading] = useState(false);

  const { worker } = useRunCtxV2();
  const { sendSteps } = usePlaygroundServiceCommands();
  const { refresh } = useRefresh();

  const formatText = (text: string) => {
    let formattedText: string = text;
    if (formattedText.match(AppConstants.PATTERNS.S3_URI)) {
      const filesObj: Record<string, string> = {};
      formattedText = reactStringReplace(
        formattedText,
        AppConstants.PATTERNS.S3_URI,
        (match) => {
          if (match) {
            const urlSplit = match.split('/');
            const fileName = urlSplit[urlSplit.length - 1];
            filesObj[fileName] = `s3://${match}`;
            return `"${fileName}"`;
          }
          return match;
        }
      ).join('');
      setFilesObj(filesObj);
    }
    if (formattedText.match(AppConstants.PATTERNS.HTTP_URI)) {
      formattedText = reactStringReplace(
        formattedText,
        AppConstants.PATTERNS.HTTP_URI,
        (match) => {
          setUrl(match);
          return '"URL"';
        }
      ).join('');
    }
    if (formattedText.match(AppConstants.PATTERNS.JSON)) {
      formattedText = reactStringReplace(
        formattedText,
        AppConstants.PATTERNS.JSON,
        (match) => {
          setJson(JSON.parse(`"{${match}}"`));
          return '"JSON"';
        }
      ).join('');
    }
    if (formattedText.match(AppConstants.PATTERNS.MARKDOWN_TEXT)) {
      formattedText = reactStringReplace(
        formattedText,
        AppConstants.PATTERNS.MARKDOWN_TEXT,
        (match) => {
          setMarkdown(`"""${match}"""`);
          return '<#>';
        }
      ).join('');
    }
    if (formattedText.match(AppConstants.PATTERNS.EMAIL_BODY)) {
      formattedText = reactStringReplace(
        formattedText,
        AppConstants.PATTERNS.EMAIL_BODY,
        (match) => {
          setEmailBody(match);
          return 'the email body is "BODY"';
        }
      ).join('');
    }
    if (formattedText.match(AppConstants.PATTERNS.EMAIL_HTML)) {
      formattedText = reactStringReplace(
        formattedText,
        AppConstants.PATTERNS.EMAIL_HTML,
        (match) => {
          setEmailHtml(match);
          return 'the email html is "HTML"';
        }
      ).join('');
    }
    setText(formattedText);
  };

  useEffect(() => {
    if (defaultValue) {
      formatText(defaultValue);
    }
  }, [defaultValue]);

  const submitMultilineCmd = async (text: string) => {
    setLoading(true);
    try {
      if (onSubmit) {
        await onSubmit(text);
      } else {
        await sendSteps(text);
      }
      onClose?.();
      setFilesObj({});
      setUrl('');
      setJson('');
      setMarkdown('');
      setEmailBody('');
      setEmailHtml('');
    } catch (e) {
      console.error('sendCommands error', e);
      message.error((e as any)?.message || 'Error while sending commands');
      setLoading(false);
      setTimeout(() => {
        refresh();
      }, 1000);
    }
  };

  const handleClose = () => {
    onClose();
    setText('');
  };

  const handleSubmit = (text: string) => {
    let parsedText = text;
    if (Object.keys(filesObj).length > 0) {
      Object.keys(filesObj).forEach((key) => {
        parsedText = parsedText.replaceAll(key, filesObj[key]);
      });
    }
    if (url) {
      parsedText = parsedText.replace('URL', url);
    }
    if (json) {
      parsedText = parsedText.replace('JSON', json);
    }
    if (markdown) {
      parsedText = parsedText.replace('<#>', markdown);
    }
    if (emailBody) {
      parsedText = parsedText.replace('BODY', emailBody);
    }
    if (emailHtml) {
      parsedText = parsedText.replace('HTML', emailHtml);
    }
    submitMultilineCmd(parsedText);
  };

  const onKeyDownHandler = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    switch (event.key) {
      case 'Escape': {
        event.preventDefault();
        event.stopPropagation();
        if (closeable) {
          handleClose();
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  // Resizes textarea height to match input rows
  useEffect(() => {
    const tx = document.getElementsByClassName('new-run-editable');
    if (tx.length > 0) {
      for (let i = 0; i < tx.length; i += 1) {
        enableTabToIndent(tx[i] as HTMLTextAreaElement);
        const initialHeight =
          !text || (text.length < 70 && !text.includes('\n'))
            ? 24
            : tx[i].scrollHeight;
        tx[i].setAttribute(
          'style',
          `height:${initialHeight}px;overflow-y:hidden;`
        );
        tx[i].addEventListener('input', OnInput);
      }
    }

    return () => {
      if (tx.length > 0) {
        for (let i = 0; i < tx.length; i += 1) {
          tx[i].removeEventListener('input', OnInput);
        }
      }
    };
  }, [text, isLoading]);

  // Sets the focus on render
  useEffect(() => {
    const tx = document.getElementsByClassName('new-run-editable');
    if (tx.length === 1) {
      (tx[0] as HTMLElement).focus();
    }
  }, []);

  const setProcedureFromChatHandler = (res: any) => {
    setText(res.detail);
  };

  useEffect(() => {
    document.addEventListener(
      'setProcedureFromChat',
      setProcedureFromChatHandler
    );

    return () => {
      document.removeEventListener(
        'setProcedureFromChat',
        setProcedureFromChatHandler
      );
    };
  }, []);

  const [keyPressEvent, setKeyPressEvent] = useState<KEY_PRESS_EVENTS>();

  useEffect(() => {
    if (keyPressEvent === KEY_PRESS_EVENTS.PASTE) {
      formatText(text);
      setKeyPressEvent(undefined);
    }
  }, [text]);

  const toolbar = (
    <div className="toolbar">
      {!isLoading && (
        <div data-cy="new-run-upload" className="new-run-upload">
          <S3FileUpload
            minimal
            scope={AppConstants.S3_FILE_SCOPE.WORKER}
            disabled={isLoading}
            scopeId={worker?.id}
            multiple
            onChange={(response) => {
              const formattedResponse = getFilesAndText(response, text);
              setText(formattedResponse.text);
              setFilesObj(formattedResponse.filesObj);
            }}
          />
        </div>
      )}

      <Button
        data-cy="run-input-submit"
        type="link"
        className="suffix"
        onClick={() => handleSubmit(text)}
      >
        {isLoading ? <LoadingOutlined /> : <PlayCircleFilled />}
      </Button>

      {closeable && (
        <CloseOutlined className="new-run-close" onClick={handleClose} />
      )}
    </div>
  );

  return (
    <InlineInfiniteLoader active={isLoading}>
      <span
        className={classNames('new-run-items-container', {
          disabled: isLoading
        })}
      >
        <span className="editable-container">
          <textarea
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            disabled={isLoading}
            onKeyDown={(e) => onKeyDownHandler(e)}
            className="new-run-editable"
            placeholder={placeholder}
            value={text}
            rows={1}
            onChange={(e) => setText(e.target.value)}
            onPaste={() => setKeyPressEvent(KEY_PRESS_EVENTS.PASTE)}
          />
          {toolbar}
        </span>
      </span>
    </InlineInfiniteLoader>
  );
}

export default NewRunItem2;
