import lazyWithRetry from '@/utils/lazyWithRetry';

export const AuthApp = lazyWithRetry(() => import('@/AuthRoute'));
export const Login = lazyWithRetry(() => import('@/pages/Login/Login'));
export const Signup = lazyWithRetry(() => import('@pages/Signup/Signup'));
export const VerifyLogin = lazyWithRetry(() => import('@/pages/VerifyLogin'));
export const DashboardPage = lazyWithRetry(() => import('@/pages/Dashboard'));
export const ProceduresPage = lazyWithRetry(
  () => import('@/pages/ProceduresPage')
);
export const DepartmentsPage = lazyWithRetry(
  () => import('@pages/DepartmentsPage')
);
export const DepartmentLayout = lazyWithRetry(
  () => import('@/pages/department/DepartmentLayout')
);
export const DepartmentBooks = lazyWithRetry(
  () => import('@/pages/department/DepartmentBooks')
);
export const DepartmentLearnings = lazyWithRetry(
  () => import('@/pages/department/DepartmentLearnings')
);
export const DepartmentHistory = lazyWithRetry(
  () => import('@/pages/department/DepartmentHistory')
);
export const DepartmentCollaborators = lazyWithRetry(
  () => import('@/pages/department/DepartmentCollaborators')
);
export const AllRunsPage = lazyWithRetry(() => import('@/pages/AllRunsPage'));
export const ProcedurePage = lazyWithRetry(
  () => import('@/pages/ProcedurePage')
);
export const InvalidRoute = lazyWithRetry(() => import('@/pages/InvalidRoute'));

export const PlaygroundV3 = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PlaygroundV3" */ '@/pages/PlaygroundV3/PlaygroundV3'
    )
);

export const RunViewLayout = lazyWithRetry(
  () => import('@/pages/RunViewLayout')
);
export const AdminPage = lazyWithRetry(() => import('@pages/AdminPage'));
export const RequestPage = lazyWithRetry(() => import('@pages/RequestPage'));
export const SchedulesPage = lazyWithRetry(() => import('@/pages/Schedules'));
export const ProcedureLearningsPage = lazyWithRetry(
  () => import('@/pages/ProcedureLearnings')
);
export const RunView = lazyWithRetry(() => import('@/pages/RunView'));
export const ProcedureShare = lazyWithRetry(
  () => import('@/pages/ProcedureShare')
);

export const HomePage = lazyWithRetry(
  () => import(/* webpackChunkName: "PlaygroundV3" */ '@/pages/Home/Home')
);

export const ExceptionsCentrePage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "ExceptionsCentrePage" */ '@/pages/exceptions/ExceptionsCentrePage'
    )
);

export const BooksPage = lazyWithRetry(
  () => import(/* webpackChunkName: "BooksPage" */ '@/pages/books/BooksPage')
);

export const OAuthPage = lazyWithRetry(
  () => import(/* webpackChunkName: "OAuthPage" */ '@/pages/oAuth/OAuthPage')
);

export const PlaygroundsPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PlaygroundV3" */ '@/pages/Playgrounds/Playgrounds'
    )
);

export const NewPythonProcessPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "PlaygroundV3" */ '@/pages/NewPythonProcess')
);
