// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WidgetManager-module__widgetStyle__Y31rJ {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-weight: 500;
  padding: 4px 6px;
  margin: 0;
  line-height: 16px;
  cursor: pointer;
  border-radius: 4px;
  background: #f0f5ff;
}
.WidgetManager-module__linkRenderer__oVTzj:link,
.WidgetManager-module__linkRenderer__oVTzj:visited {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: underline;
}
.WidgetManager-module__linkRenderer__oVTzj:hover,
.WidgetManager-module__linkRenderer__oVTzj:active {
  color: black;
  text-decoration: underline;
}
.WidgetManager-module__markdownEditorContainer__MDpRa {
  height: 300px;
  border: none;
  background: transparent;
  border-radius: 8px;
}
.WidgetManager-module__urlEditorContainer__suyVj {
  display: flex;
}
.WidgetManager-module__actionButtonsContainer__yhGK1 {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}
.WidgetManager-module__actionButton__GN2UX {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PlaygroundV3/components/EditorWidgets/WidgetManager.module.less"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,SAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;AACF;AAEA;;EAEE,yBAAA;EACA,0BAAA;AAAF;AAGA;;EAEE,YAAA;EACA,0BAAA;AADF;AAIA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,kBAAA;AAFF;AAKA;EACE,aAAA;AAHF;AAMA;EACE,aAAA;EACA,yBAAA;EACA,eAAA;AAJF;AAOA;EACE,aAAA;EACA,mBAAA;AALF","sourcesContent":[".widgetStyle {\n  color: rgba(0, 0, 0, 0.85);\n  font-size: 12px;\n  font-weight: 500;\n  padding: 4px 6px;\n  margin: 0;\n  line-height: 16px;\n  cursor: pointer;\n  border-radius: 4px;\n  background: #f0f5ff;\n}\n\n.linkRenderer:link,\n.linkRenderer:visited {\n  color: rgba(0, 0, 0, 0.7);\n  text-decoration: underline;\n}\n\n.linkRenderer:hover,\n.linkRenderer:active {\n  color: black;\n  text-decoration: underline;\n}\n\n.markdownEditorContainer {\n  height: 300px;\n  border: none;\n  background: transparent;\n  border-radius: 8px;\n}\n\n.urlEditorContainer {\n  display: flex;\n}\n\n.actionButtonsContainer {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 8px;\n}\n\n.actionButton {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetStyle": `WidgetManager-module__widgetStyle__Y31rJ`,
	"linkRenderer": `WidgetManager-module__linkRenderer__oVTzj`,
	"markdownEditorContainer": `WidgetManager-module__markdownEditorContainer__MDpRa`,
	"urlEditorContainer": `WidgetManager-module__urlEditorContainer__suyVj`,
	"actionButtonsContainer": `WidgetManager-module__actionButtonsContainer__yhGK1`,
	"actionButton": `WidgetManager-module__actionButton__GN2UX`
};
export default ___CSS_LOADER_EXPORT___;
