// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapView-module__mapView__vavlT .gm-style-iw.gm-style-iw-c {
  max-width: 250px !important;
  color: black !important;
  font-weight: 500 !important;
  font-family: 'Nunito', 'sans-serif' !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/MapView/MapView.module.less"],"names":[],"mappings":"AAAA;EAEI,2BAAA;EACA,uBAAA;EACA,2BAAA;EACA,8CAAA;AAAJ","sourcesContent":[".mapView {\n  :global(.gm-style-iw.gm-style-iw-c) {\n    max-width: 250px !important;\n    color: black !important;\n    font-weight: 500 !important;\n    font-family: 'Nunito', 'sans-serif' !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapView": `MapView-module__mapView__vavlT`
};
export default ___CSS_LOADER_EXPORT___;
