import { Button } from 'antd';
import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import {
  GET_LOG_MANAGER,
  GET_LOOP_MANAGER,
  GET_SENTENCE_EXECUTION_DATA,
  GET_WORKER_MAP
} from '@/graphql/queries/playground';
import { useSlate } from 'slate-react';
import { Transforms, Node, Editor } from 'slate';
import LineAnswer from '@/details/runs/items/LineAnswer';
import classNames from 'classnames';
import styles from './EditorLineResult.module.less';
import { TabKeys } from '../OutputPanel/OutputPanel';
import {
  EditorSourceType,
  ExecutionStatus,
  ISentence
} from '../Editor/editorInterface';
import { getNodeAndPath } from '../RichTextEditor/utils';

export enum LoopDirection {
  PREVIOUS = 'PREVIOUS',
  NEXT = 'NEXT'
}

interface IEditorLineResultProps {
  workerId: string;
  sentence: ISentence;
  source: EditorSourceType;
  onAnswerClick: (
    e: ISentence | null,
    answerType: TabKeys,
    isIterationClick?: boolean
  ) => void;
  // onLoopNavigationClick: (e: ISentence, direction: LoopDirection) => void;
  activeDocumentToken?: string;
}
// enum AnswersType {
//   TEXT = 'text',
//   IMAGE = 'image',
//   TABLE = 'table',
//   DOCUMENT = 'document'
// }

// const getIcon = (type: string) => {
//   if (type === AnswersType.IMAGE) {
//     return <ImageIcon style={{ height: '16px', width: '16px' }} />;
//   }
//   if (type === AnswersType.TABLE) {
//     return <TableIcon style={{ height: '16px', width: '16px' }} />;
//   }
//   if (type === AnswersType.DOCUMENT) {
//     return <TableIcon style={{ height: '16px', width: '16px' }} />;
//   }
//   return <ResultIcon style={{ height: '16px', width: '16px' }} />;
// };

interface IAnswerProps {
  sentence: ISentence;
  onClick: any;
}
function Answer({ sentence, onClick }: IAnswerProps) {
  if (sentence.editorAnswers) {
    const modifiedSentence = {
      ...sentence,
      answer: sentence.editorAnswers[sentence.token!]
    };
    return <LineAnswer step={modifiedSentence} onClick={onClick} />;
  }
  return <LineAnswer step={sentence} onClick={onClick} />;
}

export default function EditorLineResult(props: IEditorLineResultProps) {
  const { workerId, sentence, onAnswerClick, activeDocumentToken, source } =
    props;
  const editor = useSlate();
  const client = useApolloClient();

  const [getSentenceExecutionData] = useLazyQuery<any>(
    GET_SENTENCE_EXECUTION_DATA
  );

  const loopManagerQuery = client.readQuery({
    query: GET_LOOP_MANAGER
  });

  const updateLoopIndex = (uiLineId: string, direction: LoopDirection) => {
    const loopManager = loopManagerQuery?.getLoopManager?.data?.[uiLineId];
    if (loopManager) {
      const cIndex =
        direction === LoopDirection.NEXT
          ? loopManager.currentLoopIndex + 1
          : loopManager.currentLoopIndex - 1;
      client.writeQuery({
        query: GET_LOOP_MANAGER,
        data: {
          getLoopManager: {
            data: {
              ...loopManagerQuery.getLoopManager.data,
              [uiLineId]: {
                ...loopManager,
                currentLoopToken: loopManager.loopResults[cIndex],
                currentLoopIndex: cIndex
              }
            }
          }
        },
        broadcast: true
      });

      // Fetch the data from token
      const token = loopManager.loopResults[cIndex];
      const variables: any = {
        workerId,
        token
      };
      if (activeDocumentToken && activeDocumentToken !== '') {
        variables.documentToken = activeDocumentToken;
      }
      getSentenceExecutionData({
        variables,
        onCompleted: (response) => {
          const sentences = response.getSentenceExecutionData;
          const workerMap = client.readQuery({
            query: GET_WORKER_MAP
          });

          const editorNodes = Array.from(Node.elements(editor)).map((n) => {
            return { node: n[0].children[0], path: n[1] };
          });

          const keepActiveblockFlag = sentences.some(
            (s: any) => s.status === ExecutionStatus.ERROR
          );

          Editor.withoutNormalizing(editor, () => {
            for (let i = 0; i < editorNodes.length; i += 1) {
              const editorNode = editorNodes[i].node as ISentence;
              const sentence = sentences.find(
                (s: any) =>
                  s.lineId ===
                  workerMap?.getWorkerMap?.idMap[editorNode.uiLineId]
              );
              if (sentence) {
                const updateItems: any = {};
                updateItems.status = sentence.status;
                updateItems.token = sentence.token;
                updateItems.subDocuments = sentence.subDocuments;
                const oldLogData = client.readQuery({ query: GET_LOG_MANAGER });
                client.writeQuery({
                  query: GET_LOG_MANAGER,
                  data: {
                    getLogManager: {
                      data: {
                        ...oldLogData?.getLogManager?.data,
                        [`${editorNode.uiLineId}-execution`]: sentence
                      }
                    }
                  }
                });
                if (sentence.iterationTokens.length > 0) {
                  updateItems.loopResultCount =
                    sentence?.iterationTokens?.length;
                  const d = {
                    [editorNode.uiLineId]: {
                      currentLoopIndex: sentence.iterationTokens.length - 1,
                      currentLoopToken:
                        sentence.iterationTokens[
                          sentence.iterationTokens.length - 1
                        ],
                      totalLoopCount: sentence.iterationTokens.length - 1,
                      loopResults: sentence.iterationTokens
                    }
                  };
                  const oldLoopManager = client.readQuery({
                    query: GET_LOOP_MANAGER
                  });
                  client.writeQuery({
                    query: GET_LOOP_MANAGER,
                    data: {
                      getLoopManager: {
                        data: {
                          ...oldLoopManager?.getLoopManager?.data,
                          ...d
                        }
                      }
                    }
                  });
                } else {
                  if (sentence?.token === '/0') {
                    updateItems.answers = sentence.answer;
                    updateItems.concepts = sentence.concepts;
                    updateItems.requests = sentence.requests;
                  } else {
                    // TODO: Need to check for loop result token
                    updateItems.editorAnswers = {
                      // @ts-ignore
                      ...editorNodes[i].node.editorAnswers,
                      [sentence.token]: sentence.answer
                    };
                    updateItems.editorConcepts = {
                      // @ts-ignore
                      ...editorNodes[i].node.editorConcepts,
                      [sentence.token]: sentence.concepts
                    };
                    if (sentence.requests.length > 0) {
                      updateItems.editorRequests = {
                        // @ts-ignore
                        ...editorNodes[i].node.editorRequests,
                        [sentence.token]: sentence.requests
                      };
                    }
                    if (sentence.subDocuments) {
                      updateItems.editorSubDocuments = {
                        // @ts-ignore
                        ...editorNodes[i].node.editorSubDocuments,
                        [sentence.token]: sentence.subDocuments
                      };
                    }
                    if (sentence.miniPlaygrounds) {
                      updateItems.editorMiniPlaygrounds = {
                        // @ts-ignore
                        ...editorNodes[i].node.editorMiniPlaygrounds,
                        [sentence.token]: sentence.miniPlaygrounds
                      };
                    }
                  }
                }
                Transforms.setNodes(
                  editor,
                  { ...editorNode, ...updateItems },
                  { at: [editorNodes[i].path[0], 0] }
                );
              }
            }
            // We do this here, so the update happens lateer
            const nodePath = getNodeAndPath(editor, uiLineId);
            Transforms.setNodes(
              editor,
              {
                ...nodePath.node,
                currentLoopIndex:
                  direction === LoopDirection.NEXT
                    ? loopManager.currentLoopIndex + 1
                    : loopManager.currentLoopIndex - 1
              },
              { at: nodePath.path }
            );
          });
          if (!keepActiveblockFlag) {
            onAnswerClick(null, TabKeys.Facts, true);
          }
        }
      });
    }
  };

  const currentLoopIndex =
    sentence?.currentLoppIndex ||
    loopManagerQuery?.getLoopManager?.data?.[sentence.uiLineId]
      ?.currentLoopIndex;
  const totalLoopCount =
    loopManagerQuery?.getLoopManager?.data?.[sentence.uiLineId]?.totalLoopCount;

  // const handleClick = (e: React.MouseEvent, type: TabKeys) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   onAnswerClick(sentence, type);
  // };
  const handleLoopNavigationClick = (
    e: React.MouseEvent,
    direction: LoopDirection
  ) => {
    e.preventDefault();
    e.stopPropagation();
    updateLoopIndex(sentence.uiLineId, direction);
  };

  return (
    <div
      className={classNames(
        styles.editorLineResult,
        styles[`editorLineResult-${source}`]
      )}
    >
      {/* If EditorAnswers are greater than 1 then it is loopResult */}
      {/* Request Check is not needed for iteration line, as if request is handled, we will not have iterationCount anyway */}
      {sentence?.loopResultCount ? (
        // Add previous and next button with current loop counter
        <div
          className={styles.loopResult}
          onClick={() => onAnswerClick(sentence, TabKeys.Results, true)}
          data-cy="loop-result"
        >
          <Button
            className="editor-line-result-button"
            type="text"
            shape="circle"
            disabled={currentLoopIndex === 0}
            icon={<LeftOutlined style={{ color: '#748F9E' }} />}
            onClick={(e) => {
              e.stopPropagation();
              handleLoopNavigationClick(e, LoopDirection.PREVIOUS);
            }}
          />
          <div className={styles.loopCounter}>{currentLoopIndex + 1}</div>
          <Button
            className="editor-line-result-button"
            type="text"
            shape="circle"
            disabled={totalLoopCount === currentLoopIndex}
            icon={<RightOutlined style={{ color: '#748F9E' }} />}
            onClick={(e) => {
              e.stopPropagation();
              handleLoopNavigationClick(e, LoopDirection.NEXT);
            }}
          />
        </div>
      ) : (
        <Answer sentence={sentence} onClick={onAnswerClick} />
      )}
      {/* {!isEmpty(sentence?.concepts) && (
        <Button
          className="editor-line-result-button"
          type="text"
          shape="circle"
          icon={<FactsIcon style={{ height: '16px', width: '16px' }} />}
          onClick={(e) => handleClick(e, TabKeys.Facts)}
        />
      )} */}
    </div>
  );
}
