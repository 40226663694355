import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Tooltip } from 'antd';
import { showPopup } from '@/stores/slices/appPopup';
import {
  GetRequestAnswerQuery,
  Request,
  RequestAnswer,
  Worker
} from '../../../generated/API';
import Queries from '../../../graphql/Queries';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import FormattingUtil from '../../../utils/FormattingUtil';
import './RunStepRequests.less';
import QuestionFilledIcon from '../../../components/icons/QuestionFilledIcon';
import CheckFilledIcon from '../../../components/icons/CheckFilledIcon';
import useRequestQuestionChoices from '../../../hooks/useRequestQuestionChoices';
import InlineFacts from '../../../components/facts/InlineFacts';
import {
  getQuestionAnswer,
  getQuestionInputValueFromAnswer,
  isQuestionAnswered,
  isRequestHandled
} from '../../../utils/request';
import AppConstants from '../../../utils/AppConstants';
import { departmentQuerySelector } from '../../../stores/slices/department';

interface RunStepRequestProps {
  request: Request;
  worker: Worker;
}

function RunStepRequestAnswered(props: RunStepRequestProps) {
  const { request, worker } = props;
  const { questions } = request;

  const [getAnswer, { data, loading }] = useLazyQuery<GetRequestAnswerQuery>(
    Queries.getRequestAnswer()
  );
  const answer = data?.getRequestAnswer;

  const questionChoices = useRequestQuestionChoices({
    question: questions![0],
    knowledgeId: worker?.knowledgeId!
  });

  const getAnswerText = (question: any) => {
    if (loading) {
      return 'Loading...';
    }

    let answerText = '...';
    if (answer) {
      const value = getQuestionInputValueFromAnswer(
        answer as RequestAnswer,
        true
      );
      const hasChoices = !!question.choices;

      answerText = value;

      if (hasChoices && value !== undefined) {
        answerText = questionChoices.choices[Number(value)];
      }
    }

    return answerText;
  };

  const getQuestionText = (question: any) => {
    const processed = FormattingUtil.processQuestionV1(
      question.text,
      question.lexicalPath
    );
    return (
      <InlineFacts
        knowledgeId={worker.knowledgeId!}
        text={processed?.text || ''}
      />
    );
  };

  const getMultipleQuestionsTitle = () => {
    const totalQuestions = questions!.length;
    const answeredQuestions = questions!.filter((question) =>
      isQuestionAnswered(question, request.answers!)
    ).length;
    return `${answeredQuestions}/${totalQuestions} answered`;
  };

  const getTitle = () => {
    if (questions!.length > 1) {
      return getMultipleQuestionsTitle();
    }

    if (questions!.length === 1) {
      const firstQuestion = questions![0];
      return (
        <>
          {getQuestionText(firstQuestion)}: {getAnswerText(firstQuestion)}
        </>
      );
    }

    return null;
  };

  return (
    <Tooltip
      title={getTitle()}
      onVisibleChange={(visible) => {
        if (visible) {
          if (questions?.length === 1) {
            getAnswer({
              variables: {
                id:
                  getQuestionAnswer(questions[0], request.answers!)?.id ||
                  undefined
              }
            });
          }
        }
      }}
    >
      <span data-cy="question-icon-answered" className="request-icon-answered">
        <CheckFilledIcon />
      </span>
    </Tooltip>
  );
}

function RunStepRequestPending(props: RunStepRequestProps) {
  const { request, worker } = props;
  const { questions } = request;

  const getTitle = () => {
    if (questions!.length > 1) {
      const totalQuestions = questions!.length;
      const answeredQuestions = questions!.filter((question) =>
        isQuestionAnswered(question, request.answers!)
      ).length;
      return `${answeredQuestions}/${totalQuestions} answered`;
    }

    if (questions!.length === 1) {
      const firstQuestion = questions![0] as any;
      const processed = FormattingUtil.processQuestionV1(
        firstQuestion.text,
        firstQuestion.lexicalPath
      );
      return (
        <InlineFacts
          knowledgeId={worker.knowledgeId!}
          text={processed?.text!}
        />
      );
    }

    return null;
  };

  return (
    <Tooltip title={getTitle()}>
      <span data-cy="question-icon-pending" className="request-icon-pending">
        <QuestionFilledIcon />
      </span>
    </Tooltip>
  );
}

interface IProps {
  requests: Request[];
  worker: Worker;
}

function RunStepRequests(props: IProps) {
  const { requests, worker } = props;
  const dispatch = useAppDispatch();
  const { department } = useAppSelector(departmentQuerySelector);

  const renderRequestIcon = (request: Request) => {
    const isAnswered = isRequestHandled(request);
    if (isAnswered) {
      return <RunStepRequestAnswered request={request} worker={worker} />;
    }
    if (!isAnswered) {
      return <RunStepRequestPending request={request} worker={worker} />;
    }
    return null;
  };

  return (
    <div className="run-step-requests">
      {requests.map((request) => {
        return (
          <div
            key={request.id}
            className="run-step-request"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                showPopup({
                  popupId: AppConstants.POPUPS.VIEW_REQUEST,
                  popupParams: {
                    request,
                    worker,
                    department
                  }
                })
              );
            }}
          >
            {renderRequestIcon(request)}
          </div>
        );
      })}
    </div>
  );
}

export default RunStepRequests;
