import React from 'react';
import { useRunCtxV2 } from '@/provider/runv2';
import { useAppSelector } from '@/stores/hooks';
import { departmentQuerySelector } from '@/stores/slices/department';
import { isRequestHandled } from '@/utils/request';
import { QuestionAnswerMode } from '@/components/request/interface';
import { Divider, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Request from '../../../components/request/Request';
import { Request as RequestInterface } from '../../../generated/API';
import routes from '../../../utils/routes';
import styles from './HandledRequests.module.less';
import {
  EditorSourceType,
  ISentence
} from '../../../pages/PlaygroundV3/components/Editor/editorInterface';

interface IHandledRequestsProps {
  step: ISentence;
  stepRequests: RequestInterface[];
  editor: {
    source: EditorSourceType;
    subDocumentToken: string;
  };
}

const { Title } = Typography;

function HandledRequests(props: IHandledRequestsProps) {
  const { step, stepRequests, editor } = props;
  const { worker, procedure } = useRunCtxV2();
  const { department } = useAppSelector(departmentQuerySelector);
  const navigate = useNavigate();

  const handledRequests = stepRequests.filter((request) =>
    isRequestHandled(request)
  );

  const hide = handledRequests.length === 0;

  if (hide) {
    return null;
  }

  const renderHandledRequests = () => {
    return handledRequests.map((request) => (
      <Request
        key={request.id}
        request={request}
        worker={worker}
        department={department}
        procedure={procedure || undefined}
        sentence={step}
        hideAnsweredQuestion={false}
        activeDocumentToken={editor.subDocumentToken}
        questionConfig={{
          answerMode: QuestionAnswerMode.SINGLE,
          allowDocumentExceptionEditingInPopup: true,
          autoSelectDocumentException: false
        }}
        dispatchContextPath={false}
        archive={{
          onSuccess: () => {
            if (procedure?.id) {
              const navTo = routes.procedure.getProcedurePageUrl(
                procedure?.departmentId,
                procedure?.id
              );
              navigate(navTo);
            }
          }
        }}
      />
    ));
  };

  return (
    <div className={styles.handledRequests}>
      {handledRequests.length < stepRequests.length && <Divider />}
      <Title level={5} style={{ margin: 0 }}>
        Previously answered questions
      </Title>
      {renderHandledRequests()}
    </div>
  );
}

export default HandledRequests;
