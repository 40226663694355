import { EditorSourceType } from '../pages/PlaygroundV3/components/Editor/editorInterface';
import AppConstants from './AppConstants';

export const isMiniPlaygroundOpen = (
  source: EditorSourceType,
  currentActivePopups: Record<string, object>
) => {
  return (
    source !== EditorSourceType.MINI_PLAYGROUND &&
    Object.keys(currentActivePopups).includes(
      AppConstants.POPUPS.MINI_PLAYGROUND
    )
  );
};
