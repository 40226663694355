import React from 'react';
import Icon from '@ant-design/icons';

interface SVGProps {
  fill?: string;
}

function BooksSvg(props: SVGProps) {
  const { fill } = props;
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.249 20.3494L19.2618 6.14678C19.2231 5.96105 19.1482 5.78479 19.0412 5.62811C18.9343 5.47143 18.7974 5.33744 18.6385 5.23381C18.4796 5.13019 18.3018 5.05899 18.1153 5.02429C17.9288 4.98959 17.7373 4.99209 17.5517 5.03164L13.3387 5.93707C12.9661 6.01867 12.6407 6.24411 12.4334 6.56435C12.2261 6.88459 12.1536 7.27374 12.2317 7.64715L15.2189 21.8498C15.2854 22.1738 15.4615 22.465 15.7176 22.6745C15.9736 22.884 16.2939 22.9989 16.6247 23C16.727 22.9999 16.8289 22.989 16.9289 22.9676L21.1419 22.0622C21.515 21.9804 21.8407 21.7545 22.0481 21.4337C22.2554 21.1129 22.3276 20.7232 22.249 20.3494ZM13.6402 7.35283V7.34473L17.8523 6.4447L18.1521 7.87306L13.9399 8.7794L13.6402 7.35283ZM14.236 10.1853L18.45 9.28072L18.7506 10.7118L14.5403 11.6172L14.236 10.1853ZM14.8337 13.0267L19.0476 12.1212L20.2446 17.8131L16.0307 18.7185L14.8337 13.0267ZM20.8404 20.6545L16.6283 21.5545L16.3286 20.1262L20.5407 19.2198L20.8404 20.6464V20.6545ZM10.7602 5.71927H6.44004C6.05812 5.71927 5.69184 5.87099 5.42178 6.14105C5.15172 6.41111 5 6.7774 5 7.15933V21.5599C5 21.9419 5.15172 22.3082 5.42178 22.5782C5.69184 22.8483 6.05812 23 6.44004 23H10.7602C11.1421 23 11.5084 22.8483 11.7784 22.5782C12.0485 22.3082 12.2002 21.9419 12.2002 21.5599V7.15933C12.2002 6.7774 12.0485 6.41111 11.7784 6.14105C11.5084 5.87099 11.1421 5.71927 10.7602 5.71927ZM6.44004 7.15933H10.7602V8.59939H6.44004V7.15933ZM6.44004 10.0394H10.7602V18.6798H6.44004V10.0394ZM10.7602 21.5599H6.44004V20.1199H10.7602V21.5599Z"
        fill={fill || '#303048'}
      />
    </svg>
  );
}

export default function BooksIcon(props: {
  style?: React.CSSProperties;
  fill?: string;
  height?: number;
  width?: number;
}) {
  return <Icon component={() => BooksSvg(props)} {...props} />;
}
