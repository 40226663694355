import JsFileDownloader from 'js-file-downloader';

export const downloadFile = (
  filename: string,
  url: string,
  onSuccess?: () => void,
  onFailure?: () => void
) => {
  new JsFileDownloader({
    contentType: false,
    url,
    filename
  })
    .then(() => {
      onSuccess?.();
    })
    .catch(() => {
      onFailure?.();
    });
};
