import AppUtil from './AppUtil';

const STORAGE_KEY = 'SELECTED_DEPARTMENT_ID';

export const setSelectedDepartmentId = (id: string) => {
  AppUtil.setInLocalStorage(STORAGE_KEY, id);
};

export const getSelectedDepartmentId = (): string | null => {
  const localValue = AppUtil.getFromLocalStorage(STORAGE_KEY);
  return localValue || null;
};

export const removeSelectedDepartmentId = () => {
  AppUtil.removeFromLocalStorage(STORAGE_KEY);
};
