import { Button, Input, Spin, Tooltip, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { TextAreaRef } from 'antd/es/input/TextArea';
import classNames from 'classnames';
import styles from './DocumentExceptionExtractions.module.less';
import {
  annotationSelector,
  deleteAnnotation,
  selectAnnotation,
  updateAnnotation
} from '../../../stores/slices/annotation';
import { useAppDispatch } from '../../../stores/hooks';
import { IAnnotation } from '../../document-annotations/AnnotationsCanvas';
import OpenInWindowIcon from '../../icons/OpenInWindowIcon';
import docExceptionOnboarding from '../../../assets/doc-exception/doc-exception-onboarding.gif';
import { getDocExceptionUsageCount } from '../../../utils/doc-exception';
import AppConstants from '../../../utils/AppConstants';

interface IStagingAnnotationInputProps {
  annotation: IAnnotation;
  selected: boolean;
  showDelete: boolean;
  disabled?: boolean;
  onOpenInWindowClick?: () => void;
}

function StagingAnnotationInput(props: IStagingAnnotationInputProps) {
  const { annotation, selected, showDelete, disabled, onOpenInWindowClick } =
    props;

  const [text, setText] = useState(annotation.textContent);

  const ref = useRef<TextAreaRef | null>(null);

  const showOpenInWindowIcon = onOpenInWindowClick !== undefined;

  useEffect(() => {
    setText(annotation.textContent);
  }, [annotation.textContent]);

  useEffect(() => {
    if (selected) {
      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [selected]);

  const dispatch = useAppDispatch();

  const deleteAnnotationById = (id: string) => {
    if (annotation.allowDelete) {
      dispatch(deleteAnnotation(id));
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value?.trim().length === 0) {
      deleteAnnotationById(annotation.id);
    }
    setText(value);
    dispatch(
      updateAnnotation({
        id: annotation.id,
        changes: {
          textContent: value
        }
      })
    );
  };

  const handleOnClick: React.MouseEventHandler<HTMLTextAreaElement> = () => {
    dispatch(selectAnnotation(annotation.id));
  };

  return (
    <div className={styles.annotationInput}>
      <div className={styles.textareaWrapper}>
        <Spin spinning={annotation.loading}>
          <Input.TextArea
            ref={ref}
            autoSize={{
              minRows: 3
            }}
            placeholder="Raw text"
            value={text}
            onChange={handleOnChange}
            onClick={handleOnClick}
            className={classNames(styles.textarea, {
              [styles.textareaExtraPadding]: showOpenInWindowIcon
            })}
            disabled={disabled}
          />
        </Spin>
        {showOpenInWindowIcon ? (
          <span
            className={styles.openInWindowIcon}
            onClick={onOpenInWindowClick}
          >
            <Tooltip title={text?.trim() ? 'Edit fields' : 'Mark fields'}>
              <OpenInWindowIcon />
            </Tooltip>
          </span>
        ) : null}
      </div>
      {showDelete && (
        <Button
          icon={<DeleteOutlined />}
          type="text"
          onClick={() => deleteAnnotationById(annotation.id)}
          disabled={disabled || !annotation.allowDelete}
        />
      )}
    </div>
  );
}

export interface IDocumentExceptionExtractionsProps {
  fieldName: string;
  showDefaultExtraction: boolean;
  showDelete: boolean;
  enableOnboarding: boolean;
  disabled?: boolean;
  onOpenInWindowClick?: () => void;
}

function DocumentExceptionExtractions(
  props: IDocumentExceptionExtractionsProps
) {
  const {
    fieldName,
    showDefaultExtraction,
    showDelete,
    disabled,
    onOpenInWindowClick,
    enableOnboarding
  } = props;
  const { annotations, selectedAnnotationId } = useSelector(annotationSelector);

  const [usageCount, setUsageCount] = useState(getDocExceptionUsageCount());

  const manualAnnotations = annotations.filter(
    (annotation) =>
      annotation?.id !== AppConstants.DOC_EXCEPTION.DEFAULT_ANNOTATION_KEY
  );

  const annotationsToShow = showDefaultExtraction
    ? annotations
    : manualAnnotations;

  useEffect(() => {
    setUsageCount(getDocExceptionUsageCount());
  }, [manualAnnotations]);

  const showOnboarding = enableOnboarding
    ? usageCount < AppConstants.DOC_EXCEPTION.ONBOARDING_MESSAGE_THRESHOLD
    : false;

  if (annotationsToShow.length === 0) {
    return null;
  }

  return (
    <div className={styles.extractionsWrapper}>
      <div className={styles.content}>
        <Typography.Text>{fieldName}</Typography.Text>
        {annotationsToShow.map((annotation) => (
          <React.Fragment key={annotation.id}>
            <StagingAnnotationInput
              annotation={annotation}
              selected={selectedAnnotationId === annotation.id}
              showDelete={showDelete}
              disabled={disabled}
              onOpenInWindowClick={onOpenInWindowClick}
            />
          </React.Fragment>
        ))}
      </div>

      {showOnboarding && (
        <div className={styles.onboardingContent}>
          <img
            src={docExceptionOnboarding}
            alt="Try document exception extraction"
            className={styles.onboardingImage}
          />
          <div className={styles.onboardingText}>
            Click and drag to create box around a section of the text you wish
            to get from the document.
          </div>
        </div>
      )}
    </div>
  );
}

export default DocumentExceptionExtractions;
