//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// fp-utils is a set of functional programming modules from lodash
//

// 3rd party libraries
import { Procedure } from '@/generated/API';
import _find from 'lodash/fp/find'; // data last
import * as R from 'ramda';

// eslint-disable-next-line import/prefer-default-export
export function findById(_id: string | number, data: any) {
  return _find((obj) => obj.id === _id, data);
}

export const filterFlows = (
  {
    sharedOnly,
    systemCreatedOnly,
    english,
    python,
    nodejs
  }: {
    sharedOnly: boolean;
    systemCreatedOnly: boolean;
    english: boolean;
    python: boolean;
    nodejs: boolean;
  },
  flows: Procedure[]
) =>
  R.pipe(
    R.filter((_: Procedure) => sharedOnly || !sharedOnly),
    R.filter(
      (f: Procedure) =>
        (!systemCreatedOnly && !f.bookReference) || systemCreatedOnly
    ),
    R.filter(
      R.cond([
        [(f) => english && f.language === 'english', () => english],
        [(f) => python && f.language === 'python', () => python],
        [(f) => nodejs && f.language === 'nodejs', () => nodejs],
        [() => !english && !python && !nodejs, () => true]
      ])
    )
  )(flows);

export const immutableSort = (array: any[], sortFunction: any) => {
  const tempArray = array.map((a) => a);
  tempArray.sort(sortFunction);
  return tempArray;
};
