import { Fact } from '@/generated/API';
import { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import React, { useCallback, useEffect, useState } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Empty, Tooltip, Typography } from 'antd';
import classnames from 'classnames';
import AppUtil from '@/utils/AppUtil';
import routes from '@/utils/routes';
import AnswerS3FileViewer from '../AnswerS3FileViewer';
import FactDocumentSummaryList from '../facts/FactDocumentSummary';
import AnswerMarkdownViewer from '../AnswerMarkdownViewer';
import SensitiveText from '../SensitiveText';
import FactCitation from './FactCitation';
import AnswerCSVViewer from '../AnswerCSVViewer';
import EditableStringFact from './EditableStringFact';
import EditableS3Fact from './EditableS3Fact';
import AnswerConceptsTableViewer from '../AnswerConceptsTableViewer';
import styles from './RunFactRendererV2.module.less';
import JsonViewer from '../JsonViewer';
import NewTabIcon from '../icons/NewTabIcon';
import MapPreview from '../MapPreview/MapPreview';
import MapIcon from '../icons/MapIcon';
import FactRelationsViewer from './FactRelationsViewer';

interface IProps {
  fact: Fact;
  factType: {
    type: FormattedAnswerTypeV2;
    answer: any;
  };
  knowledgeId: string | null;
  noTextMassage?: boolean;
  // This is to get step answer for table's row kind of fact
  stepAnswer?: string;
  // This is to get the step concepts for tables's row kind of fact
  tableRowCpt?: any;
  showEmpty?: boolean;
  // onEdit?: (data: string, idx?: number) => Promise<unknown>;
  // onDelete?: () => Promise<unknown>;
  showIcons?: boolean;
  title?: string;
  showRelations?: boolean;
  showInFactsTable?: boolean;
  onClick?: any;
  showPreview?: boolean;
  expandDocumentPreview?: (open: boolean) => void;
  epoch?: number;
  debug?: boolean;
  tablesRowDataNewFactsModel?: any[];
}

function RunFactRendererV2(props: IProps) {
  const {
    fact,
    factType,
    knowledgeId,
    noTextMassage,
    stepAnswer,
    tableRowCpt,
    showEmpty,
    // onEdit,
    // onDelete,
    showIcons,
    title,
    showRelations,
    showInFactsTable,
    onClick,
    showPreview,
    expandDocumentPreview,
    epoch,
    debug,
    tablesRowDataNewFactsModel
  } = props;

  const [showDebugDetails, setShowDebugDetails] = useState(false);

  const onDebugDetails = useCallback(
    (open: boolean) => {
      if (debug) {
        setShowDebugDetails(open);
      } else {
        setShowDebugDetails(false);
      }
    },
    [debug]
  );

  useEffect(() => {
    if (!debug) {
      onDebugDetails(false);
    }
  }, [debug, onDebugDetails]);

  if (factType.type === FormattedAnswerTypeV2.GEO_JSON_DICT) {
    return (
      <div
        onContextMenu={(e) => {
          if (debug) {
            e.preventDefault();
            onDebugDetails(!showDebugDetails);
          }
        }}
        className={styles.debuggerContainer}
      >
        <div
          data-cy="answer"
          className="short-answer view-link"
          onClick={(e) => e.stopPropagation()}
          onContextMenu={(e) => {
            if (debug) {
              e.preventDefault();
              onDebugDetails(!showDebugDetails);
            }
          }}
        >
          {showIcons ? (
            <div onClick={onClick}>
              <MapIcon />
            </div>
          ) : (
            <MapPreview data={factType.answer} />
          )}
        </div>
        {showDebugDetails && debug && (
          <>
            <br />
            <JsonViewer data={{ epoch, ...fact }} name="fact" />
          </>
        )}
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.TABLES_ROW) {
    return (
      <div
        data-cy="answer"
        className="short-answer view-link"
        onClick={(e) => e.stopPropagation()}
        onContextMenu={(e) => {
          if (debug) {
            e.preventDefault();
            onDebugDetails(!showDebugDetails);
          }
        }}
      >
        <AnswerConceptsTableViewer
          title={title || 'view table'}
          answer={stepAnswer!}
          tableRowCpt={tableRowCpt}
          showIcons={showIcons}
          onClick={onClick}
          showInFactsTable={showInFactsTable}
          showPreview={showPreview}
          expandDocumentPreview={expandDocumentPreview}
          epoch={epoch}
          showDebugDetails={showDebugDetails}
          newFactsModelData={tablesRowDataNewFactsModel}
        />
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.TABLE) {
    return (
      <div
        onContextMenu={(e) => {
          if (debug) {
            e.preventDefault();
            onDebugDetails(!showDebugDetails);
          }
        }}
        className={styles.debuggerContainer}
      >
        <div
          data-cy="answer"
          className="short-answer view-link"
          onClick={(e) => e.stopPropagation()}
        >
          <AnswerCSVViewer
            // onEdit={onEdit}
            title={
              Array.isArray(factType.answer) && factType.answer.length > 1
                ? 'View tables'
                : 'View table'
            }
            data={factType.answer}
            showIcons={showIcons}
            onClick={onClick}
            showInFactsTable={showInFactsTable}
            showPreview={showPreview}
            expandDocumentPreview={expandDocumentPreview}
          />
        </div>
        {showDebugDetails && debug && (
          <>
            <br />
            <JsonViewer data={{ epoch, ...fact }} name="fact" />
          </>
        )}
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.SRC_CONCEPT) {
    return (
      <div
        onContextMenu={(e) => {
          if (debug) {
            e.preventDefault();
            onDebugDetails(!showDebugDetails);
          }
        }}
      >
        <EditableStringFact
          // onEdit={onEdit}
          // onDelete={onDelete}
          factType={factType}
        >
          <div className={styles.stringFactContainer}>
            <FactCitation
              fact={fact}
              factType={factType}
              knowledgeId={knowledgeId}
              expandDocumentPreview={expandDocumentPreview}
            />
          </div>
        </EditableStringFact>
        {showDebugDetails && debug && (
          <JsonViewer data={{ epoch, ...fact }} name="fact" />
        )}
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.S3) {
    return (
      <div
        onContextMenu={(e) => {
          if (debug) {
            e.preventDefault();
            onDebugDetails(!showDebugDetails);
          }
        }}
      >
        <EditableS3Fact
          // onEdit={onEdit} onDelete={onDelete}
          factType={factType}
        >
          <div data-cy="answer" className="short-answer view-link">
            <AnswerS3FileViewer
              answer={factType.answer!}
              showFileIcon={showIcons}
              showInFactsTable={showInFactsTable}
              onClick={onClick}
              showPreview={showPreview}
              expandDocumentPreview={expandDocumentPreview}
            />
          </div>
          {showDebugDetails && debug && (
            <JsonViewer data={{ epoch, ...fact }} name="fact" />
          )}
        </EditableS3Fact>
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.RESULT) {
    const isDone = AppUtil.isResultTypeDone(factType.answer);
    return (
      <div
        onContextMenu={(e) => {
          if (debug) {
            e.preventDefault();
            onDebugDetails(!showDebugDetails);
          }
        }}
      >
        <span
          data-cy="answer"
          className={
            showInFactsTable ? styles.stringFactContainer : 'short-answer'
          }
          onClick={onClick}
        >
          {noTextMassage || !isDone ? (
            factType.answer.result || factType.answer
          ) : (
            <CheckCircleFilled
              style={{ color: '#748f9e', width: '16px', height: '16px' }}
              data-cy="answer-ok-icon"
            />
          )}
        </span>
        {showDebugDetails && debug && (
          <JsonViewer data={{ epoch, ...fact }} name="fact" />
        )}
      </div>
    );
  }

  if (fact && fact.locations) {
    return (
      <div
        onContextMenu={(e) => {
          if (debug) {
            e.preventDefault();
            onDebugDetails(!showDebugDetails);
          }
        }}
      >
        <EditableStringFact
          // onEdit={onEdit}
          // onDelete={onDelete}
          factType={factType}
        >
          <div
            data-cy="facts-with-locations"
            className={classnames(['answer-files', styles.stringFactContainer])}
          >
            <Typography.Text
              className={classnames([
                'fact-summary-text',
                { 'short-answer dim': !showInFactsTable }
              ])}
            >
              {factType.answer}
            </Typography.Text>
            <FactDocumentSummaryList
              fact={fact}
              showInPopup
              knowledgeId={knowledgeId}
              expandDocumentPreview={expandDocumentPreview}
            />
          </div>
        </EditableStringFact>
        {showDebugDetails && debug && (
          <JsonViewer data={{ epoch, ...fact }} name="fact" />
        )}
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.LONG) {
    return (
      <div
        onContextMenu={(e) => {
          if (debug) {
            e.preventDefault();
            onDebugDetails(!showDebugDetails);
          }
        }}
      >
        <EditableStringFact
          // onEdit={onEdit}
          // onDelete={onDelete}
          factType={factType}
        >
          <div className={styles.stringFactContainer} onClick={onClick}>
            {factType.answer}
            <FactDocumentSummaryList
              fact={fact}
              showInPopup
              knowledgeId={knowledgeId}
            />
          </div>
        </EditableStringFact>
        {showDebugDetails && debug && (
          <JsonViewer data={{ epoch, ...fact }} name="fact" />
        )}
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.MARKDOWN) {
    return (
      <div
        className={styles.stringFactContainer}
        onClick={onClick}
        onContextMenu={(e) => {
          if (debug) {
            e.preventDefault();
            onDebugDetails(!showDebugDetails);
          }
        }}
      >
        <AnswerMarkdownViewer answer={factType.answer} />
        {showDebugDetails && debug && (
          <JsonViewer data={{ epoch, ...fact }} name="fact" />
        )}
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.SENSITIVE) {
    return (
      <div
        onContextMenu={(e) => {
          if (debug) {
            e.preventDefault();
            onDebugDetails(!showDebugDetails);
          }
        }}
      >
        <EditableStringFact
          // onEdit={onEdit}
          // onDelete={onDelete}
          factType={factType}
        >
          <div className={styles.stringFactContainer} onClick={onClick}>
            <SensitiveText isSensitive>{factType.answer}</SensitiveText>
          </div>
        </EditableStringFact>
        {showDebugDetails && debug && (
          <JsonViewer data={{ epoch, ...fact }} name="fact" />
        )}
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.WORKER) {
    const data = factType.answer;
    return (
      <div>
        <div
          onContextMenu={(e) => {
            if (debug) {
              e.preventDefault();
              onDebugDetails(!showDebugDetails);
            }
          }}
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        >
          {showInFactsTable ? (
            <a
              rel="noreferrer"
              target="_blank"
              href={routes.procedure.getProcedureRunPageUrl(
                data.department_id,
                data.procedure_id,
                data.worker_id
              )}
              className={styles.workerContainer}
            >
              <NewTabIcon />
              <span className={styles.workerButton}>Open invoked run</span>
            </a>
          ) : (
            <Tooltip title="Invoked Run">
              <NewTabIcon />
            </Tooltip>
          )}
        </div>
        {showDebugDetails && debug && (
          <JsonViewer data={{ epoch, ...fact }} name="fact" />
        )}
      </div>
    );
  }

  const renderDefaultFact = () => {
    if (!factType.answer) {
      if (showEmpty) {
        return <Empty />;
      }
      return null;
    }

    return (
      <div className={styles.stringFactContainer}>
        {factType.answer}
        {showRelations && fact.relations?.length ? (
          <FactRelationsViewer
            fact={fact!}
            epoch={epoch!}
            knowledgeId={knowledgeId!}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div
      onContextMenu={(e) => {
        if (debug) {
          e.preventDefault();
          onDebugDetails(!showDebugDetails);
        }
      }}
    >
      <EditableStringFact
        //  onEdit={onEdit} onDelete={onDelete}
        factType={factType}
      >
        {showInFactsTable ? (
          renderDefaultFact()
        ) : (
          <Typography.Text
            data-cy="answer"
            className="short-answer dim fact-summary-text"
            onClick={onClick}
          >
            {factType.answer}
          </Typography.Text>
        )}
      </EditableStringFact>
      {showDebugDetails && debug && (
        <JsonViewer data={{ epoch, ...fact }} name="fact" />
      )}
    </div>
  );
}

export default RunFactRendererV2;
