import React from 'react';
import { Switch as AntSwitch, Tooltip } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';

interface ISwitchProps {
  checked: boolean;
  onChange: SwitchChangeEventHandler;
  title: string;
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;
}

function Switch({
  checked,
  onChange,
  title,
  checkedChildren,
  unCheckedChildren
}: ISwitchProps) {
  return (
    <Tooltip title={title} color="#323232">
      <AntSwitch
        checked={checked}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        onChange={onChange}
        style={{
          backgroundColor: checked ? '#1890ff' : '#728D9C'
        }}
      />
    </Tooltip>
  );
}

export default Switch;
