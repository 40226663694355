import React from 'react';
import Icon from '@ant-design/icons';

function OpenInWindow(props: any) {
  const { fill = '#8C8C8C' } = props;
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0V14.625H14.625V18H0V3.375H3.375V0H18ZM16.875 13.5V1.125H4.5V3.375H6.75V4.5H1.125V16.875H13.5V11.25H14.625V13.5H16.875ZM8.27051 10.5205L7.47949 9.72949L12.7002 4.5H9V3.375H14.625V9H13.5V5.2998L8.27051 10.5205Z"
        fill={fill}
      />
    </svg>
  );
}

export default function OpenInWindowIcon(props: {
  style?: React.CSSProperties;
}) {
  return <Icon component={() => OpenInWindow(props)} {...props} />;
}
