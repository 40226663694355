import { Organization, OrganizationBillingStatus } from '@/generated/API';
import {
  BookPermissionsEnum,
  PermissionEnum,
  PlaygroundPermissionsEnum,
  allPermissions
} from './permissionConstants';

const getOrgPermissions = (organization: Organization) => {
  // When backend is ready, this should be replaced with the actual permissions
  // return organization.permissions;
  // For now, this is a frontend specific implementation

  if (organization?.billingStatus === OrganizationBillingStatus.UNPAID) {
    return [
      ...Object.values(PlaygroundPermissionsEnum),
      BookPermissionsEnum.BookRead.valueOf()
    ];
  }

  // return [...Object.values(PlaygroundPermissionsEnum)];

  return allPermissions;
};

/**
 * Check if user has permission
 * @param currentUserPermission
 * @param permissions
 * @returns boolean
 */
const hasPermission = (
  currentUserPermission: string[],
  permissions: PermissionEnum[]
) => {
  const stringifiedPermissions = [...Object.values(permissions)].map(
    (permission) => permission.valueOf()
  );
  return currentUserPermission.some((permission) =>
    stringifiedPermissions.includes(permission)
  );
};

export const permissionUtil = {
  getOrgPermissions,
  hasPermission
};
