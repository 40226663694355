// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProcedureExceptionRoutingPopup-module__content__Pi8pm {
  padding: 0 0 30px 0;
}
.ProcedureExceptionRoutingPopup-module__processOwner__K4QXw {
  margin-bottom: 20px;
}
.ProcedureExceptionRoutingPopup-module__switchWrapper__vvAHc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.ProcedureExceptionRoutingPopup-module__collaboratorsWrapper__r0DZ0 {
  margin-top: 30px;
}
.ProcedureExceptionRoutingPopup-module__label__UUt9c {
  margin-bottom: 4px;
}
.ProcedureExceptionRoutingPopup-module__select__MOD2X {
  width: 100%;
}
.ProcedureExceptionRoutingPopup-module__note__tNp8a {
  color: #434343;
  font-size: 14px;
  font-weight: 400;
  margin-top: 40px;
}
.ProcedureExceptionRoutingPopup-module__notePoints__KkpFL {
  margin-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/popups/ProcedureExceptionRoutingPopup.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAEA;EACE,mBAAA;AAAF;AAGA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAIA;EACE,gBAAA;AAFF;AAKA;EACE,kBAAA;AAHF;AAMA;EACE,WAAA;AAJF;AAOA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AALF;AAQA;EACE,gBAAA;AANF","sourcesContent":[".content {\n  padding: 0 0 30px 0;\n}\n\n.processOwner {\n  margin-bottom: 20px;\n}\n\n.switchWrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.collaboratorsWrapper {\n  margin-top: 30px;\n}\n\n.label {\n  margin-bottom: 4px;\n}\n\n.select {\n  width: 100%;\n}\n\n.note {\n  color: #434343;\n  font-size: 14px;\n  font-weight: 400;\n  margin-top: 40px;\n}\n\n.notePoints {\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `ProcedureExceptionRoutingPopup-module__content__Pi8pm`,
	"processOwner": `ProcedureExceptionRoutingPopup-module__processOwner__K4QXw`,
	"switchWrapper": `ProcedureExceptionRoutingPopup-module__switchWrapper__vvAHc`,
	"collaboratorsWrapper": `ProcedureExceptionRoutingPopup-module__collaboratorsWrapper__r0DZ0`,
	"label": `ProcedureExceptionRoutingPopup-module__label__UUt9c`,
	"select": `ProcedureExceptionRoutingPopup-module__select__MOD2X`,
	"note": `ProcedureExceptionRoutingPopup-module__note__tNp8a`,
	"notePoints": `ProcedureExceptionRoutingPopup-module__notePoints__KkpFL`
};
export default ___CSS_LOADER_EXPORT___;
