import { Modal, Tooltip } from 'antd';
import React from 'react';
import CheckFilledIcon from '../components/icons/CheckFilledIcon';
import QuestionFilledIcon from '../components/icons/QuestionFilledIcon';
import Request from '../components/request/Request';
import {
  Department,
  Request as RequestInterface,
  Worker
} from '../generated/API';
import { isRequestHandled } from '../utils/request';
import './ViewRequestPopup.less';
import { QuestionAnswerMode } from '../components/request/interface';

interface IProps {
  request: RequestInterface;
  department: Department;
  worker: Worker;
  onClose: () => void;
}

function ViewRequestPopup(props: IProps) {
  const { request, worker, department, onClose } = props;

  return (
    <Modal
      className="view-question-popup"
      title={
        <div className="header">
          View Question{' '}
          {isRequestHandled(request) ? (
            <Tooltip title="Answered">
              <span>
                <CheckFilledIcon />
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Pending">
              <span>
                <QuestionFilledIcon />
              </span>
            </Tooltip>
          )}
        </div>
      }
      centered
      open
      footer={null}
      onCancel={onClose}
    >
      <div className="run-questions">
        <Request
          key={request.id}
          request={request}
          worker={worker}
          department={department}
          hideAnsweredQuestion={false}
          questionConfig={{
            answerMode: QuestionAnswerMode.SINGLE
          }}
          dispatchContextPath={false}
        />
      </div>
    </Modal>
  );
}

export default ViewRequestPopup;
