/**
 * This is just a renderer with no logic of data fetching
 * Use the old AnswerConceptsViewer.tsx if you want to handle the facts the old way
 */
import AppUtil from '@/utils/AppUtil';
import React from 'react';
import classNames from 'classnames';
import exportFromJSON from 'export-from-json';
import { LoadingOutlined } from '@ant-design/icons';
import { FACT_STATUS } from '@/hooks/useLineFact';
import { isEmpty } from 'lodash';
import styles from './AnswerConceptsTable.module.less';
import Table from '../table/Table';
import AnswerObjectViewer from '../AnswerObjectViewer';
import ExpandIcon from '../icons/ExpandIcon';
import JsonViewer from '../JsonViewer';
import DownloadIcon from '../icons/DownloadIcon';
import Loader, { ILoaderType } from '../Loader';

interface IProps {
  newFactsModelData: any[];
  hideHeader?: boolean;
  onExpandTable?: (downloadJsonData?: {
    data: any;
    exportType: string;
  }) => void;
  epoch?: number;
  showDebugDetails?: boolean;
  hideTable?: boolean;
}

const LOADING = '__LOADING__';

export const getTablesRowsData = (tablesRowsData: any[]) => {
  const loadingIndex = tablesRowsData.findIndex(
    (f) => f.status === FACT_STATUS.LOADING
  );
  const sliceIndex = loadingIndex === -1 ? tablesRowsData.length : loadingIndex;
  const data = tablesRowsData.slice(0, sliceIndex).map((fact) => {
    const data = fact?.value;
    // TODO: hack for concept info KOG-3728
    if (data?.['concept info']) {
      const obj = AppUtil.safeParseJSON(data['concept info'].data);
      return obj;
    }
    return data;
  });

  return { tablesRowData: data, loadingIndex };
};

function AnswerConceptsTableV2(props: IProps) {
  const {
    newFactsModelData,
    hideHeader,
    onExpandTable,
    epoch,
    showDebugDetails,
    hideTable
  } = props;

  // That is needed to check if we have any data that is an object rather than a string to get the columns.
  // The conversion from string to object is handled in the useLineFacts hook.
  const loadedFact = newFactsModelData.find((f) => typeof f.value === 'object');
  const colNames =
    loadedFact && !isEmpty(loadedFact.value)
      ? Object.keys(loadedFact.value)
      : undefined;

  const { tablesRowData, loadingIndex } = getTablesRowsData(newFactsModelData);

  const getColumns = () => {
    if (!colNames) {
      return [];
    }
    return colNames.map((key: any, idx: number) => ({
      id: `${key}-object-column-${idx}`,
      dataKey: key,
      title: key,
      renderColumn: (value: any) => {
        if (!value) {
          return '-';
        }
        if (value === LOADING) {
          return <LoadingOutlined />;
        }
        if (typeof value === 'object') {
          return <AnswerObjectViewer object={value} />;
        }
        return value;
      }
    }));
  };

  const tableData = tablesRowData;
  const tableCols = getColumns();

  const downloadData = {
    data: tableData,
    exportType: exportFromJSON.types.json
  };

  const getTable = () => (
    <div
      className={classNames([styles.answerConceptsTable])}
      data-cy="concepts-table"
    >
      {!hideHeader && (
        <span className={styles.cptsTableTitleContainer}>
          Table
          <span
            className={styles.tableExpandIcon}
            onClick={() => {
              exportFromJSON(downloadData);
            }}
          >
            <DownloadIcon className={styles.actionButton} />
          </span>
          <span
            data-cy="expand-concepts-table"
            className={styles.tableExpandIcon}
            onClick={() => {
              onExpandTable?.(downloadData);
            }}
          >
            <ExpandIcon className={styles.actionButton} />
          </span>
        </span>
      )}
      {loadingIndex !== 0 && (
        <Table
          bordered={false}
          loading={!colNames}
          uniqueRowId="id"
          data={tableData}
          columns={tableCols}
          components={{
            table: ({ children }: any) => {
              return (
                <table className={styles.antCustomTable}>{children}</table>
              ) as any;
            },
            header: {
              wrapper: ({ children }: any) =>
                (
                  <thead className="ant-custom-table-header-wrapper">
                    {children}
                  </thead>
                ) as any,
              row: ({ children }: any) =>
                (
                  <tr className="ant-custom-table-header-row">{children}</tr>
                ) as any,
              cell: (data: any) => {
                return (
                  <td
                    className={classNames([
                      styles.cell,
                      styles.cptsCustomTableHeaderCell
                    ])}
                  >
                    {data.children}
                  </td>
                ) as any;
              }
            },
            body: {
              wrapper: ({ children }: any) =>
                (
                  <tbody className={styles.antCustomTableBodyWrapper}>
                    {children}
                  </tbody>
                ) as any,
              row: ({ children }: any) =>
                (
                  <tr className={styles.antCustomTableBodyRow}>{children}</tr>
                ) as any,
              cell: (data: any) => {
                return (
                  <td
                    className={classNames([
                      styles.cell,
                      styles.cptsCustomTableBodyCell
                    ])}
                  >
                    {data.children}
                  </td>
                ) as any;
              }
            }
          }}
        />
      )}
      {loadingIndex >= 0 && (
        <span className={loadingIndex === 0 ? styles.loadingFactContainer : ''}>
          <Loader
            className={styles.noPadding}
            message="Getting data"
            skeletonConfig={{ rows: 5 }}
            type={ILoaderType.SKELETON}
          />
        </span>
      )}
    </div>
  );

  const getJsonViewer = () => {
    return <JsonViewer data={{ epoch, rows: newFactsModelData }} name="rows" />;
  };

  return (
    <span className={styles.tableContainer}>
      {!hideTable && getTable()}
      {showDebugDetails && getJsonViewer()}
    </span>
  );
}

export default AnswerConceptsTableV2;
