import React, { useEffect, useMemo, useState } from 'react';
import ChatPage from '@/components/koncierge-chat/ChatPage';
import { Modal, ModalFuncProps, Tabs } from 'antd';
import { useSubscription } from '@apollo/client';
import Loader, { ILoaderType } from '@/components/Loader';
import { isEmpty, isEqual } from 'lodash/fp';
import { DOMUtil } from '@/utils/DomUtil';
import { ON_WORKER_DOCUMENT_UPDATE } from '@/graphql/subscriptions/playground';
import AppUtil from '@/utils/AppUtil';
import TabPane from 'antd/es/tabs/TabPane';
import { useRunCtxV2 } from '@/provider/runv2';
import classNames from 'classnames';
import AppConstants from '@/utils/AppConstants';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import styles from './Editor.module.less';
import {
  EditorBlock,
  EditorSourceType,
  EditorTabsKey,
  ISentence
} from './editorInterface';
import EditorHeader from '../EditorHeader/EditorHeader';
import OutputPanel, { TabKeys } from '../OutputPanel/OutputPanel';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { constructSentence } from './editorUtil';
import EditorPreview from '../EditorPreview/EditorPreview';
import { useKogEditorContext } from './EditorProvider';

const { confirm } = Modal;

interface IEditorProps {
  workerId: string;
  hide?: {
    chat?: boolean;
    outputPanel?: boolean;
    header?: boolean;
    editorLineResult?: boolean;
    tabNavIfSingle?: boolean;
  };
  readOnly?: boolean;
  onActiveBlockChange?: (e: ISentence, answerType: TabKeys) => void;
  hideActiveBlockOnOutsideClick?: boolean;
  getEditorPlainText?: (editorText: string) => void;
}

export default function Editor(props: IEditorProps) {
  const {
    workerId,
    hide,
    readOnly = false,
    onActiveBlockChange,
    hideActiveBlockOnOutsideClick,
    getEditorPlainText
  } = props;

  const location = useLocation();
  const { worker, procedure } = useRunCtxV2();
  const {
    source,
    loading,
    editorStatus,
    editorTabs,
    debugMode,
    editorBlocks,
    subDocumentToken,
    activeBlock,
    activeOutputTab,
    sentences,
    editorValue,
    actions
  } = useKogEditorContext();

  const [activeBlockUpdateRequired, setActiveBlockUpdateRequired] =
    useState(false);

  const [outputPanelWidth, setOutputPanelWidth] = useState(0);

  // Refs
  const editorContainer = React.useRef<HTMLDivElement | null>(null);
  const outputContainer = React.useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let confirmModal: {
      destroy: (...args: any[]) => void;
      update?: (
        configUpdate:
          | ModalFuncProps
          | ((prevConfig: ModalFuncProps) => ModalFuncProps)
      ) => void;
    };
    if (
      !procedure &&
      worker &&
      !isEmpty(worker?.features) &&
      !AppUtil.isDocumentModel(worker)
    ) {
      confirmModal = confirm({
        title: 'Old Playground, no longer supported. ',
        icon: <ExclamationCircleFilled />,
        okText: 'Ok',
        content: 'Please create a new playground.',
        cancelButtonProps: { style: { display: 'none' } },
        onOk() {
          actions.updateEditorStatus({ readOnly: true });
        }
      });
    }

    return () => {
      confirmModal?.destroy();
    };
  }, [worker]);

  useEffect(() => {
    actions.fetchWorkerData(workerId);
  }, [workerId, subDocumentToken]);

  useEffect(() => {
    if (
      location.pathname.includes('run') &&
      source !== EditorSourceType.MINI_PLAYGROUND
    ) {
      actions.updateEditorStatus({ readOnly: true });
    } else {
      actions.updateEditorStatus({ readOnly });
    }
  }, [readOnly, location.pathname]);

  useSubscription(ON_WORKER_DOCUMENT_UPDATE, {
    variables: {
      workerId
    },
    skip: editorStatus.readOnly || !editorStatus.autoPilot,
    onData: ({ data }) => {
      const workerSentences =
        data?.data?.onWorkerDocumentUpdate?.sentences || [];
      const localSentence = constructSentence(workerSentences);
      if (
        workerSentences.length !== sentences.length ||
        !isEqual(sentences, localSentence)
      ) {
        // TODO: Need to check if we want to show Confirm popup before refreshing the content
        actions.fetchWorkerData(workerId);
      }
    },
    onError: (error) => {
      console.log(error);
    }
  });

  useEffect(() => {
    // On click of editorcontainer hide the output panel
    const handleClickOutside = (event: any) => {
      if (!hideActiveBlockOnOutsideClick) {
        return;
      }

      if (
        !(
          DOMUtil.hasClass(event.target, 'editor-line-result-button') ||
          DOMUtil.isChild(event.target, outputContainer.current)
        ) ||
        (outputContainer.current &&
          !outputContainer.current.contains(event.target))
      ) {
        actions.updateEditorStatus({ showOutputPanel: false });
        actions.updateActiveBlock(null);
      }
    };

    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [outputContainer.current, hideActiveBlockOnOutsideClick]);

  useEffect(() => {
    if (activeBlockUpdateRequired && !isEmpty(activeBlock)) {
      const findItem: EditorBlock | undefined = editorValue?.find(
        // @ts-ignore
        (i: EditorBlock) => {
          return i.children[0].uiLineId === activeBlock?.uiLineId;
        }
      );

      if (findItem && !isEqual(findItem?.children?.[0], activeBlock)) {
        actions.updateActiveBlock(findItem.children[0]);
        setActiveBlockUpdateRequired(false);
      }
    }
  }, [editorValue, activeBlockUpdateRequired]);

  useEffect(() => {
    const tabName = worker?.name || procedure?.name;
    if (tabName) {
      actions.updateEditorTabs(tabName);
    }
  }, [procedure?.name, worker]);

  const handleActiveBlockChange = (
    e: ISentence,
    answerType: TabKeys,
    isIterationClick?: boolean
  ) => {
    if (source === EditorSourceType.MINI_PLAYGROUND) {
      return;
    }

    if (e !== null) {
      actions.updateActiveBlock(e);
      actions.updateActiveOutputTab(answerType);
      if (onActiveBlockChange) {
        onActiveBlockChange(e, answerType);
      }
    }
    if (isIterationClick && e === null && !isEmpty(activeBlock)) {
      setActiveBlockUpdateRequired(true);
    }
    actions.updateEditorStatus({ showChat: false });
  };

  const shouldRenderOutputPanel = hide?.outputPanel
    ? false
    : editorStatus.showOutputPanel;
  const shouldRenderChat = hide?.chat ? false : editorStatus.showChat;

  const hideTabNav = hide?.tabNavIfSingle && editorTabs.length <= 1;

  useEffect(() => {
    const width = editorStatus.showOutputPanel ? 400 : 0;
    setOutputPanelWidth(width);
    // FIXME: On change of editor width, the ant tabs more button is not getting readjusted automatically
  }, [editorStatus.showOutputPanel]);

  useEffect(() => {
    const handler = (data: any) => {
      actions.expandDocumentPreview(data.detail.open);
    };
    document.addEventListener(
      AppConstants.FACTS.REQUEST_DOCUMENT_EXPAND_FROM_EXCEPTION_CENTER,
      handler
    );

    return () =>
      document.removeEventListener(
        AppConstants.FACTS.REQUEST_DOCUMENT_EXPAND_FROM_EXCEPTION_CENTER,
        handler
      );
  }, []);

  const maxEditorWidth = '100%';

  const handleOutputPanelSizeChange = (size: any) => {
    setOutputPanelWidth(size.width);
  };

  const memoizedOutputPanel = useMemo(() => {
    return (
      <OutputPanel
        ref={outputContainer}
        activeBlock={activeBlock}
        expandDocumentPreview={actions.expandDocumentPreview}
        isDebugMode={debugMode}
        onPanelSizeChange={handleOutputPanelSizeChange}
      />
    );
    // Faiyaz: Adding 'expandDocumentPreview' as dependency will cause unnecessary re-renders (KOG-5375)
  }, [activeBlock, activeOutputTab, debugMode]);

  return (
    <>
      <div
        className={classNames(
          styles.editor,
          'editor',
          styles[`editor-${source}`],
          {
            'editor--hide-tab-nav': hideTabNav
          }
        )}
        style={{
          width: maxEditorWidth,
          maxWidth: `calc(100% - ${outputPanelWidth}px)`
        }}
        data-cy="document-editor"
      >
        {editorStatus.showDocument && (
          <EditorPreview
            onClose={() => actions.expandDocumentPreview(false)}
            width={maxEditorWidth}
            outputPanelWidth={outputPanelWidth}
            name={worker.name}
          />
        )}
        {hide?.header ? null : (
          <EditorHeader
            workerId={workerId}
            isOutputPanelVisible={shouldRenderOutputPanel}
            isKonciergeVisible={shouldRenderChat}
          />
        )}
        <Tabs
          data-cy="editor-tabs"
          activeKey={subDocumentToken}
          defaultActiveKey={EditorTabsKey.MAIN}
          onChange={actions.onEditorTabChange}
          className={classNames(styles.editorTab, {
            [styles.hideTabNav]: hideTabNav
          })}
        >
          {editorTabs?.map((tabItem: any) => {
            return (
              <TabPane tab={tabItem.tab} key={tabItem.key}>
                <div
                  className={classNames(styles.editorContent, {
                    [styles.opacity]: editorStatus.readOnly
                  })}
                  ref={editorContainer}
                >
                  {!loading &&
                  editorBlocks.length &&
                  subDocumentToken === tabItem.key ? (
                    <RichTextEditor
                      workerId={workerId}
                      initialValue={editorBlocks}
                      setActiveBlock={handleActiveBlockChange}
                      showLineResult={!hide?.editorLineResult}
                      expandDocumentPreview={actions.expandDocumentPreview}
                      editorName={tabItem.name}
                      getEditorPlainText={getEditorPlainText}
                    />
                  ) : (
                    <Loader
                      message="Preparing playground..."
                      skeletonConfig={{ rows: 10 }}
                      type={ILoaderType.SKELETON}
                    />
                  )}
                </div>
              </TabPane>
            );
          })}
        </Tabs>
      </div>
      {shouldRenderChat ? <ChatPage /> : null}
      {shouldRenderOutputPanel ? memoizedOutputPanel : null}
    </>
  );
}
