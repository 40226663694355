import React from 'react';
import Icon from '@ant-design/icons';

function AccountSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <circle cx="9" cy="9" r="8.375" stroke="#DBDBE3" strokeWidth="1.25" />
      <path
        d="M5.47083 14.0538V12.8417C5.47083 12.1988 5.72624 11.5822 6.18087 11.1275C6.6355 10.6729 7.25212 10.4175 7.89507 10.4175H10.3193C10.9623 10.4175 11.5789 10.6729 12.0335 11.1275C12.4881 11.5822 12.7436 12.1988 12.7436 12.8417V14.0538"
        stroke="#DBDBE3"
        strokeWidth="1.17845"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="9.00619"
        cy="6.17492"
        r="2.12121"
        stroke="#DBDBE3"
        strokeWidth="1.17845"
      />
    </svg>
  );
}

export default function AccountIcon(props: { style?: React.CSSProperties }) {
  return <Icon component={AccountSvg} {...props} />;
}
