import React from 'react';
import Icon from '@ant-design/icons';

function WidgetUpdateConfirmSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9 1.125C4.65117 1.125 1.125 4.65117 1.125 9C1.125 13.3488 4.65117 16.875 9 16.875C13.3488 16.875 16.875 13.3488 16.875 9C16.875 4.65117 13.3488 1.125 9 1.125ZM11.9074 11.9918L10.7473 11.9865L9 9.90352L7.25449 11.9848L6.09258 11.99C6.01523 11.99 5.95195 11.9285 5.95195 11.8494C5.95195 11.816 5.96426 11.7844 5.98535 11.758L8.27227 9.0334L5.98535 6.31055C5.96411 6.28478 5.95233 6.25253 5.95195 6.21914C5.95195 6.1418 6.01523 6.07852 6.09258 6.07852L7.25449 6.08379L9 8.1668L10.7455 6.08555L11.9057 6.08027C11.983 6.08027 12.0463 6.1418 12.0463 6.2209C12.0463 6.2543 12.034 6.28594 12.0129 6.3123L9.72949 9.03516L12.0146 11.7598C12.0357 11.7861 12.048 11.8178 12.048 11.8512C12.048 11.9285 11.9848 11.9918 11.9074 11.9918Z"
        fill="#434343"
      />
    </svg>
  );
}

export default function WidgetUpdateConfirmIcon(props: {
  style?: React.CSSProperties;
}) {
  return <Icon component={WidgetUpdateConfirmSvg} {...props} />;
}
