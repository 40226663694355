import React from 'react';
import LayoutPage from '@pages/AppPage';
import * as Sentry from '@sentry/react';
import SentryErrorPage from './pages/SentryErrorPage';
import { RequireAuth } from './provider/auth';

export default function AuthApp() {
  return (
    <Sentry.ErrorBoundary
      fallback={SentryErrorPage}
      showDialog
      onError={(error) => {
        Sentry.captureException(error);
      }}
    >
      <RequireAuth>
        <LayoutPage />
      </RequireAuth>
    </Sentry.ErrorBoundary>
  );
}
