import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter, Routes, useLocation } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import * as Sentry from '@sentry/react';
import { store } from '@stores/AppStore';
import AuthApp from '@/AuthRoute';
import {
  Login,
  AdminPage,
  DepartmentsPage,
  ProcedureShare,
  InvalidRoute,
  ProceduresPage,
  DepartmentLayout,
  DepartmentBooks,
  DepartmentLearnings,
  DepartmentHistory,
  DepartmentCollaborators,
  AllRunsPage,
  RunViewLayout,
  ProcedurePage,
  RunView,
  SchedulesPage,
  VerifyLogin,
  DashboardPage,
  RequestPage,
  PlaygroundV3,
  ProcedureLearningsPage,
  PlaygroundsPage,
  HomePage,
  ExceptionsCentrePage,
  Signup,
  BooksPage,
  OAuthPage,
  NewPythonProcessPage
} from '@/pages';
import './AuthConfig';
import './App.less';
import { ConfigProvider } from 'antd';
import TestPage from '@/pages/TestPage';
import { RefreshProvider, useRefresh } from './provider/refresh';
import WithSuspense from './components/WithSuspense';
import Apollo from './provider/apollo';
import { RunProviderV2 } from './provider/runv2';
import { ExceptionsCentreProvider } from './pages/exceptions/ExceptionsCentreProvider';
import { AuthProvider } from './provider/auth';
import { appConfig } from './AppConfig';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// function LazyRoute({ path, component }: { path: string; component: string }) {
//   return <Route path={path} element={lazy(() => import(component))} />;
// }

function AppRoutes() {
  const location = useLocation();
  const lState = location.state as { background?: Location };
  return (
    <div>
      <SentryRoutes location={lState?.background || location}>
        {/* we could refactor like this for one-one mapping */}
        {/* <LazyRoute path="/signup" component="@pages/login" /> */}
        <Route path="/login" element={<WithSuspense element={<Login />} />} />
        <Route path="/signup" element={<WithSuspense element={<Signup />} />} />
        <Route
          path="/verify-user/:challenge"
          element={<WithSuspense element={<VerifyLogin />} />}
        />
        {/* Auth routes should be below, non-auth routes should be above */}
        <Route path="/" element={<AuthApp />}>
          {/* <Route index element={<ActionBar />} /> */}
          <Route index element={<HomePage />} />
          <Route path="/dashboard" element={<DashboardPage />} />

          <Route path="/processes" element={<ProceduresPage />} />
          <Route
            path="/process/new/python"
            element={<NewPythonProcessPage />}
          />

          <Route path="/departments" element={<DepartmentsPage />} />
          <Route path="/department" element={<DepartmentLayout />}>
            <Route index element={<DepartmentBooks />} />
            <Route path="learnings" element={<DepartmentLearnings />} />
            <Route path="history" element={<DepartmentHistory />} />
            <Route path="collaborators" element={<DepartmentCollaborators />} />
          </Route>

          <Route
            path="/processes/:departmentId/:procedureId"
            element={<RunViewLayout />}
          >
            <Route index element={<ProcedurePage />} />
            <Route
              path="run/:workerId"
              element={
                <RunProviderV2 contextId="1">
                  <RunView />
                </RunProviderV2>
              }
            />
            <Route path="learnings" element={<ProcedureLearningsPage />} />
            <Route path="schedules" element={<SchedulesPage />} />
            <Route path="share" element={<ProcedureShare />} />
            <Route path="runs" element={<AllRunsPage paneKey="1" />} />
            <Route path="exceptions" element={<AllRunsPage paneKey="2" />} />
            <Route path="outputs" element={<AllRunsPage paneKey="3" />} />
            <Route path="run/all" element={<AllRunsPage paneKey="1" />} />
          </Route>

          <Route path="/department/:departmentId/playground">
            <Route
              index
              element={
                <RunProviderV2 contextId="0">
                  <PlaygroundsPage />
                </RunProviderV2>
              }
            />
            <Route
              path=":workerId"
              element={
                <RunProviderV2 contextId="0">
                  <PlaygroundV3 />
                </RunProviderV2>
              }
            />
          </Route>

          <Route
            path="/request/:departmentId/:requestId"
            element={<RequestPage />}
          />

          <Route
            path="/exceptions"
            element={
              <ExceptionsCentreProvider>
                <ExceptionsCentrePage />
              </ExceptionsCentreProvider>
            }
          />

          <Route path="/books" element={<BooksPage />} />

          {/* TODO: Remove after 2 weeks post prod deployment. */}
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/internal/admin" element={<AdminPage />} />

          <Route path="/test" element={<TestPage />} />

          <Route path="/auth" element={<OAuthPage />} />

          <Route path="*" element={<InvalidRoute />} />
        </Route>

        <Route path="*" element={<InvalidRoute />} />
      </SentryRoutes>
    </div>
  );
}

function RefreshableAppRoutes() {
  const { refreshCount } = useRefresh();
  return (
    <React.Fragment key={refreshCount}>
      <AppRoutes />
    </React.Fragment>
  );
}

// Component implementation
function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 5,
          fontFamily: 'Nunito',
          colorPrimary: '#661eff',
          'purple-10': '#140e56'
        }
      }}
    >
      <Provider store={store}>
        <BrowserRouter basename="">
          <Apollo>
            <AuthProvider>
              <RefreshProvider>
                <RefreshableAppRoutes />
              </RefreshProvider>
            </AuthProvider>
          </Apollo>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
}

export default withLDProvider({
  clientSideID: appConfig.launchDarkly.clientSideId
})(App);
