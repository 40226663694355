import React from 'react';
import { Tooltip } from 'antd';
import { showPopup } from '@/stores/slices/appPopup';
import { GET_LOCAL_DOCUMENT_PREVIEW } from '@/graphql/queries/playground';
import { useApolloClient } from '@apollo/client';
import { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import { useAppDispatch } from '../stores/hooks';
import AppConstants from '../utils/AppConstants';
import './AnswerCSVViewer.less';
import TableIcon from './icons/TableIcon';
import styles from './AnswerS3FileViewer.module.less';
import CSVTable from './CSVTable';

interface IProps {
  title: string;
  data: string[];
  onEdit?: (data: string, idx?: number) => Promise<unknown>;
  showIcons?: boolean;
  onClick?: any;
  showInFactsTable?: boolean;
  showPreview?: boolean;
  expandDocumentPreview?: (open: boolean) => void;
}

export default function AnswerCSVViewer(props: IProps) {
  const {
    title,
    data,
    onEdit,
    showIcons,
    onClick,
    showInFactsTable,
    showPreview,
    expandDocumentPreview
  } = props;

  const dispatch = useAppDispatch();

  const client = useApolloClient();

  const show = () => {
    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.ANSWER_CSV,
        popupParams: {
          data,
          title,
          onEdit
        }
      })
    );
  };

  const expandTable = (downloadCSVData?: Record<string, any>[]) => {
    client.writeQuery({
      query: GET_LOCAL_DOCUMENT_PREVIEW,
      data: {
        getLocalDocumentPreview: {
          data: {
            type: FormattedAnswerTypeV2.TABLE,
            csvData: data[0],
            tableNumber: 0,
            downloadCSVData
          }
        }
      }
    });
    props.expandDocumentPreview?.(true);
  };

  if (showPreview) {
    return <CSVTable csvData={data[0]} expandTable={expandTable} />;
  }

  const handleClick = () => {
    if (expandDocumentPreview) expandTable();
    else if (onClick) onClick();
    else show();
  };

  return showInFactsTable ? (
    <div className={styles.tableFactTableContainer} onClick={handleClick}>
      <TableIcon style={{ width: 22, height: 24, marginRight: 2 }} />
      <span className={styles.fileButton}>{title}</span>
    </div>
  ) : (
    <div className="answer-csv-viewer">
      <span onClick={handleClick} className="view-link">
        {showIcons ? (
          <Tooltip
            destroyTooltipOnHide
            title={title}
            color="#fff"
            overlayInnerStyle={{
              color: 'black'
            }}
          >
            <TableIcon style={{ height: '18px', width: '18px' }} />
          </Tooltip>
        ) : (
          title
        )}
      </span>
    </div>
  );
}
