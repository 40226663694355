import React from 'react';
import Icon from '@ant-design/icons';

function ThreeDotMenuSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M6.98419 12.25C7.0991 12.25 7.21288 12.2274 7.31904 12.1834C7.4252 12.1394 7.52166 12.075 7.60291 11.9937C7.68416 11.9125 7.74861 11.816 7.79259 11.7098C7.83656 11.6037 7.85919 11.4899 7.85919 11.375C7.85919 11.2601 7.83656 11.1463 7.79259 11.0402C7.74861 10.934 7.68416 10.8375 7.60291 10.7563C7.52166 10.675 7.4252 10.6106 7.31904 10.5666C7.21288 10.5226 7.0991 10.5 6.98419 10.5C6.86929 10.5 6.7555 10.5226 6.64934 10.5666C6.54318 10.6106 6.44672 10.675 6.36547 10.7563C6.28422 10.8375 6.21977 10.934 6.1758 11.0402C6.13182 11.1463 6.10919 11.2601 6.10919 11.375C6.10919 11.4899 6.13182 11.6037 6.1758 11.7098C6.21977 11.816 6.28422 11.9125 6.36547 11.9937C6.44672 12.075 6.54318 12.1394 6.64934 12.1834C6.7555 12.2274 6.86929 12.25 6.98419 12.25ZM6.98419 7.875C7.21626 7.875 7.43882 7.78281 7.60291 7.61872C7.767 7.45462 7.85919 7.23206 7.85919 7C7.85919 6.76793 7.767 6.54538 7.60291 6.38128C7.43882 6.21719 7.21626 6.125 6.98419 6.125C6.75213 6.125 6.52957 6.21719 6.36547 6.38128C6.20138 6.54538 6.10919 6.76793 6.10919 7C6.10919 7.23206 6.20138 7.45462 6.36547 7.61872C6.52957 7.78281 6.75213 7.875 6.98419 7.875ZM6.98419 3.5C7.21626 3.5 7.43882 3.40781 7.60291 3.24372C7.767 3.07962 7.85919 2.85706 7.85919 2.625C7.85919 2.39293 7.767 2.17037 7.60291 2.00628C7.43882 1.84219 7.21626 1.75 6.98419 1.75C6.75213 1.75 6.52957 1.84219 6.36547 2.00628C6.20138 2.17037 6.10919 2.39293 6.10919 2.625C6.10919 2.85706 6.20138 3.07962 6.36547 3.24372C6.52957 3.40781 6.75213 3.5 6.98419 3.5Z"
        fill="black"
      />
    </svg>
  );
}

export default function ThreeDotMenuIcon(props: {
  style?: React.CSSProperties;
  className?: string;
}) {
  return <Icon component={ThreeDotMenuSvg} {...props} />;
}
