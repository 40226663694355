//
// Copyright (C) - Kognitos, Inc. All rights reserved
// ApolloGraphQL queries - Client side templates
//

import {
  getFact,
  listLearningsByProcedure,
  parseAlangText,
  getUploadToS3Url,
  getDownloadFromS3Url,
  listWorkerOutputsByProcedure,
  generateOpenAICompletions,
  listDepartmentCollaborators,
  getDepartmentHistory,
  getOwnerOrganization,
  getServiceCommand,
  autoCompleteCommand,
  getStepPathForRun,
  getFactsAtStepPath,
  listLearningsByDepartment,
  getLearnedAnswerByRequestAnswerId,
  listKChatMessagesByWorker,
  listSharedEntityByProcedure,
  exportCodeForContext,
  exportDepartment
} from '@/generated/graphql/queries';
import { gql } from '@apollo/client';
import { LIST_DEPARTMENT_LEARNED_ANSWERS } from './learning';
import {
  getRequest,
  listProcedureRequestsQuery,
  GET_REQUEST_ANSWER
} from './request';
import { GET_WORKER, getWorkerDependenciesV2 } from './worker';
import { listBooksByDepartment } from './book';
import {
  getDepartmentQuery,
  listAllDepartmentsQuery,
  LIST_DEPARTMENTS
} from './department';
import { GET_PROCEDURE } from './procedure';

export const COMMAND_FIELDS = gql`
  fragment CommandFields on Command {
    id
    owner
    readers
    editors
    name
    description
    workerId
    text
    subText
    subTextLanguage
    procedureId
    createdAt
    ast
    state
    output
    source
    sourceMetadata
    debug
    receivedAt
  }
`;

const Queries = {
  Command() {
    return gql`
      ${COMMAND_FIELDS}
      query GetCommand($id: ID!) {
        getCommand(id: $id) {
          ...CommandFields
        }
      }
    `;
  },

  IsCommandReceived() {
    return gql`
      query GetCommand($id: ID!) {
        getCommand(id: $id) {
          id
          receivedAt
        }
      }
    `;
  },

  CommandsByWorker() {
    return gql`
      ${COMMAND_FIELDS}
      query ListCommandsByWorker(
        $workerId: ID
        $limit: Int
        $nextToken: String
      ) {
        listCommandsByWorker(
          workerId: $workerId
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...CommandFields
          }
          nextToken
        }
      }
    `;
  },

  Fact() {
    return gql(getFact);
  },

  Facts() {
    return gql`
      query GetFacts($knowledgeId: ID!, $factIds: [ID!]!) {
        getFacts(knowledgeId: $knowledgeId, factIds: $factIds) {
          id
          knowledgeId
          knowledge {
            id
            owner
            readers
            editors
            name
            description
            departmentId
            workerId
            parentKnowledgeId
            state
          }
          names
          parentFact {
            id
            knowledgeId
            names
            parentFactId
            locations
            value
            owner
            readers
            editors
            referenceTo
            viewUrl
          }
          parentFactId
          locations
          relations {
            factId
            name
            direction
            fact {
              names
              value
              locations
              parentFact {
                id
                names
                value
              }
            }
          }
          value
          descriptions {
            name
            factId
          }
          owner
          readers
          editors
          referenceTo
          viewUrl
        }
      }
    `;
  },

  ExportCodeForContext() {
    return gql(exportCodeForContext);
  },

  ContextsForStep() {
    return gql`
      query GetContextsForStep($workerId: ID!, $contextId: ID, $stepId: ID) {
        getContextsForStep(
          workerId: $workerId
          contextId: $contextId
          stepId: $stepId
        ) {
          items {
            id
            createdAt
            depth
            stepList {
              __typename
              id
              number
              createdAt
              scratchedAt
              text
              answer
              childContextIdList
              contextId
              positionInContext
              workerId
              concepts
              questionIds
              commandId
              requestIds
              epoch
            }
            commands {
              __typename
              contextId
              positionInContext
              id
              state
              text
              createdAt
              forwardedAt
              invokedAt
              receivedAt
              processingAt
              processedAt
              failedAt
              workErrors
            }
            workerId
          }
        }
      }
    `;
  },

  Department() {
    return gql(getDepartmentQuery);
  },

  ListUserDepartments() {
    return LIST_DEPARTMENTS;
  },

  ListDepartmentLearnedAnswers() {
    return LIST_DEPARTMENT_LEARNED_ANSWERS;
  },

  ListAllDepartments() {
    return gql(listAllDepartmentsQuery);
  },

  ListDepartmentCollaborators() {
    return gql(listDepartmentCollaborators);
  },

  // Get department history for
  GetDepartmentHistory() {
    return gql(getDepartmentHistory);
  },

  // List departments w/ worker info
  Departments() {
    return gql`
      query ListDepartments($limit: Int, $nextToken: String) {
        listDepartments(limit: $limit, nextToken: $nextToken) {
          items {
            id
            owner
            readers
            editors
            name
            description
            publishedKnowledgeId
            workerId
            worker {
              id
              knowledgeId
            }
          }
          nextToken
        }
      }
    `;
  },

  // Organization
  OwnerOrganization() {
    return gql(getOwnerOrganization);
  },

  // Get procedure
  Procedure() {
    return GET_PROCEDURE;
  },

  ListSharedEntities() {
    return gql(listSharedEntityByProcedure);
  },

  getRequestAnswer() {
    return GET_REQUEST_ANSWER;
  },

  getLearnedAnswerByRequestAnswerId() {
    return gql(getLearnedAnswerByRequestAnswerId);
  },

  // Get worker
  Worker() {
    return gql(GET_WORKER);
  },

  WorkerDependenciesV2() {
    return gql(getWorkerDependenciesV2);
  },

  Request() {
    return gql(getRequest);
  },

  RequestsByProcedure() {
    return gql(listProcedureRequestsQuery);
  },

  // List workers by procedure
  ListBasicWorkersByProcedure() {
    return gql`
      query ListWorkersByProcedure(
        $procedureId: ID
        $limit: Int = 1000
        $nextToken: String
        $stateFilter: String
      ) {
        listWorkersByProcedure(
          procedureId: $procedureId
          limit: $limit
          nextToken: $nextToken
          stateFilter: $stateFilter
        ) {
          items {
            id
            name
            createdAt
            originalWorkerId
            state
          }
          nextToken
        }
      }
    `;
  },

  // List workers by procedure
  ListWorkersByProcedure() {
    return gql`
      query ListWorkersByProcedure(
        $procedureId: ID
        $limit: Int = 1000
        $nextToken: String
        $stateFilter: String
        $fromCreationDate: String
        $toCreationDate: String
      ) {
        listWorkersByProcedure(
          procedureId: $procedureId
          limit: $limit
          nextToken: $nextToken
          stateFilter: $stateFilter
          fromCreationDate: $fromCreationDate
          toCreationDate: $toCreationDate
        ) {
          items {
            id
            owner
            readers
            editors
            name
            description
            state
            version
            knowledgeId
            departmentId
            procedureId
            entryCommand
            entryCommandId
            createdAt
            originalWorkerId
            state
            stateLastUpdatedAt
            attachments
            requests {
              limit
              nextToken
              items {
                id
                state
                questions {
                  id
                }
              }
            }
          }
          nextToken
        }
      }
    `;
  },

  // List learnings by procedure
  LearningsByProcedure() {
    return gql(listLearningsByProcedure);
  },

  LearningsByDepartment() {
    return gql(listLearningsByDepartment);
  },

  // Get additional columns by gridId
  AdditionalColumns() {
    return gql`
      query getAdditionalColumns($gridId: ID!) {
        getAdditionalColumns(gridId: $gridId)
      }
    `;
  },

  ListBooksByDepartment() {
    return gql(listBooksByDepartment);
  },

  ParseAlangText() {
    return gql(parseAlangText);
  },

  S3UploadLink() {
    return gql(getUploadToS3Url);
  },

  S3DownloadLink() {
    return gql(getDownloadFromS3Url);
  },

  ListWorkerOutputsByProcedure() {
    return gql(listWorkerOutputsByProcedure);
  },

  GenerateOpenAICompletions() {
    return gql(generateOpenAICompletions);
  },

  GetAutoComplete() {
    return gql(autoCompleteCommand);
  },

  ServiceCommand() {
    return gql(getServiceCommand);
  },

  GetStepPathForRun() {
    return gql(getStepPathForRun);
  },

  GetFactsAtStepPath() {
    return gql(getFactsAtStepPath);
  },

  ListKChatMessagesByWorkerQuery() {
    return gql(listKChatMessagesByWorker);
  },

  ExportDepartment() {
    return gql(exportDepartment);
  }
};

export default Queries;
