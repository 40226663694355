import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import {
  GET_HISTORICAL_FACTS,
  GET_LOCAL_HISTORICAL_FACTS
} from '@/graphql/queries/fact';
import AppUtil from '@/utils/AppUtil';
import { useCustomCompareLayoutEffect } from 'use-custom-compare';
import { isEqual } from 'lodash/fp';
import { message } from 'antd';
import { Fact, GetHistoricalFactsQuery } from '../generated/API';

interface IProps {
  ids: string[];
  knowledgeId: string | null;
  epoch?: number;
  paginationState?: {
    pgNumber: number;
    pgSize: number;
  };
}

const prepareFactKey = (id: string, epoch?: number) => `${id}-${epoch || 0}`;

/**
 * @deprecated: This hook is used only for older brain verisons where we had to fetch all facts.
 * For newer implementation refer useLineFacts
 */
function useFact(props: IProps) {
  const client = useApolloClient();
  const facts =
    client.readQuery({ query: GET_LOCAL_HISTORICAL_FACTS })
      ?.getLocalHistoricalFacts?.data || {};
  const { ids, knowledgeId, paginationState, epoch } = props;
  const [fetchIds, setFetchIds] = useState<string[]>(ids);
  const [loading, setLoading] = useState(false);

  useCustomCompareLayoutEffect(
    () => {
      let currentIds = ids;
      if (paginationState) {
        const { pgNumber, pgSize } = paginationState;
        currentIds = AppUtil.getPaginatedList(ids, pgNumber, pgSize);
      }
      setFetchIds(currentIds);
    },
    [ids, paginationState],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  const [fetchFacts] = useLazyQuery<GetHistoricalFactsQuery>(
    GET_HISTORICAL_FACTS,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      onCompleted: (resp) => {
        if (resp?.getHistoricalFacts) {
          const responseFacts = (resp?.getHistoricalFacts! as Fact[]) || [];
          const factsMap = responseFacts.reduce<Record<string, Fact>>(
            (acc, cur) => {
              if (cur && cur.dereferencedId) {
                acc[prepareFactKey(cur.dereferencedId || cur.id, epoch)] = cur;
              }
              return acc;
            },
            {}
          );
          const facts = client.readQuery({ query: GET_LOCAL_HISTORICAL_FACTS });
          const oldFacts = facts?.getLocalHistoricalFacts?.data;
          client.writeQuery({
            query: GET_LOCAL_HISTORICAL_FACTS,
            data: {
              getLocalHistoricalFacts: {
                data: { ...oldFacts, ...factsMap }
              }
            }
          });
          const refIds = responseFacts
            .map((fact) => fact?.referenceTo?.[0]!)
            .filter(Boolean);
          if (refIds.length > 0) {
            setFetchIds(refIds);
          } else {
            setLoading(false);
          }
        }
      },
      onError() {
        message.error('Something went wrong, please refresh the page');
      }
    }
  );

  const refetchFacts = (fetchIds: string[]) => {
    if (fetchIds.length > 0) {
      setLoading(true);
      fetchFacts({
        variables: {
          factIds: fetchIds.map((id) => ({ id, epoch: epoch || 0 })),
          knowledgeId
        }
      });
    }
  };

  useCustomCompareLayoutEffect(
    () => {
      refetchFacts(fetchIds);
    },
    [fetchIds, epoch],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  const getFactById = (
    factId: string | null,
    epoch?: number
  ): Fact | undefined => {
    if (!factId) {
      return undefined;
    }

    let factToShow: Fact | undefined;

    const fact = facts[prepareFactKey(factId, epoch)];

    if (fact) {
      if (fact.referenceTo?.[0]) {
        factToShow = getFactById(fact.referenceTo[0], epoch);
      } else {
        factToShow = fact;
      }
    }

    return factToShow;
  };

  const getIds = () => {
    if (paginationState) {
      const { pgNumber, pgSize } = paginationState;
      return AppUtil.getPaginatedList(ids, pgNumber, pgSize);
    }
    return ids;
  };

  return { getFactById, loading, ids: getIds(), refetchFacts };
}

export default useFact;
