import React from 'react';
import Icon from '@ant-design/icons';

function PauseCircleSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM12 20.3036C7.41518 20.3036 3.69643 16.5848 3.69643 12C3.69643 7.41518 7.41518 3.69643 12 3.69643C16.5848 3.69643 20.3036 7.41518 20.3036 12C20.3036 16.5848 16.5848 20.3036 12 20.3036ZM10.0357 8.42857H8.96429C8.86607 8.42857 8.78571 8.50893 8.78571 8.60714V15.3929C8.78571 15.4911 8.86607 15.5714 8.96429 15.5714H10.0357C10.1339 15.5714 10.2143 15.4911 10.2143 15.3929V8.60714C10.2143 8.50893 10.1339 8.42857 10.0357 8.42857ZM15.0357 8.42857H13.9643C13.8661 8.42857 13.7857 8.50893 13.7857 8.60714V15.3929C13.7857 15.4911 13.8661 15.5714 13.9643 15.5714H15.0357C15.1339 15.5714 15.2143 15.4911 15.2143 15.3929V8.60714C15.2143 8.50893 15.1339 8.42857 15.0357 8.42857Z"
        fill="#7A8E9D"
      />
    </svg>
  );
}

export default function PlaygroundPauseIcon(props: {
  style?: React.CSSProperties;
}) {
  return <Icon component={PauseCircleSvg} {...props} />;
}
