import {
  split,
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  DefaultOptions,
  Operation,
  NormalizedCacheObject
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { onError } from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';
import apolloLogger from 'apollo-link-logger';
import { delayExponentialBackoff } from '@/utils/promise';
import { appConfig } from '@/AppConfig';
import { getJwtToken } from '@/provider/auth';
import { currentUserDetailsVar } from '@/graphql/cache/user';
import { currentLogLevel, logLevelDebug } from './AppUtil';

function getBackoffPromise(attempt: number): Promise<void> {
  return new Promise((res) => {
    const backoffInSec = delayExponentialBackoff(attempt);
    setTimeout(() => res(), backoffInSec);
  });
}

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    Sentry.withScope((scope) => {
      graphQLErrors.forEach((gError) => {
        const { message, locations, path, errorType } = gError as any;

        if (errorType === 'UnauthorizedException') {
          // This reload is acceptable since the end goal is to force
          // login.
          window.location.reload();
        }

        const locationz = JSON.stringify(locations);
        scope.setTags({
          operation: operation.operationName,
          path: `${path}`,
          message
        });
        scope.setExtra('graphqlError', {
          message,
          locations: locationz,
          path,
          errorType
        });
        Sentry.captureMessage(message);
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locationz}, Path: ${path}, ErrorType: ${errorType}`
        );
      });
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

function makeApolloLink() {
  const url = appConfig.backend.graphql_v2_endpoint
    ? appConfig.backend.graphql_v2_endpoint
    : appConfig.backend.aws_appsync_graphqlEndpoint; // fallback for old awsconfig in sandbox and prod

  const httpLink = createHttpLink({
    uri: ({ operationName }) => `${url}?op=${operationName}`
  });

  const authLink = setContext(async (_, { headers, ...rest }) => {
    const token = await getJwtToken();
    return {
      ...rest,
      headers: {
        ...Headers,
        authorization: token
      }
    };
  });

  const handlers = [
    new SentryLink(/* See options */),
    errorLink,
    authLink.concat(httpLink)
  ];

  if (currentLogLevel >= logLevelDebug) {
    handlers.unshift(apolloLogger);
  }

  return ApolloLink.from(handlers);
}

function makeLocalApolloLink(graphqlWsUrl: string) {
  const wsLink = new GraphQLWsLink(
    createClient({
      url: graphqlWsUrl,
      shouldRetry: () => true,
      retryAttempts: 5,
      retryWait: (attempt) => {
        return getBackoffPromise(attempt);
      },
      connectionParams: async () => {
        const token = await getJwtToken();
        return {
          type: 'connection_init',
          authorization: token
        };
      }
    })
  );

  (window as any).wsLink = wsLink;

  const isSubscription = ({ query }: Operation) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  };
  return split(isSubscription, wsLink, makeApolloLink());
}

// Returns a production apollo client linked to the backend
function makeApolloClient() {
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none'
    }
  };

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          currentUserDetails: {
            read() {
              return currentUserDetailsVar();
            }
          }
        }
      }
    }
  });

  const connectToDevTools = true;

  const graphqlWsUrl = appConfig.backend.graphql_subscription_agw_endpoint;
  if (graphqlWsUrl) {
    return new ApolloClient({
      link: makeLocalApolloLink(graphqlWsUrl),
      connectToDevTools,
      cache,
      defaultOptions
    });
  }
  return new ApolloClient({
    link: makeApolloLink(),
    connectToDevTools,
    cache,
    defaultOptions
  });
}

function makeGraphqlClient() {
  return makeApolloClient();
}

let instance: ApolloClient<NormalizedCacheObject> | null = null;

/**
 * Returns an ApolloClient instance for making GraphQL requests to the backend.
 * If an instance already exists, it returns the existing instance.
 * @returns {ApolloClient<NormalizedCacheObject>} The ApolloClient instance.
 */
function getGraphqlClient(): ApolloClient<NormalizedCacheObject> {
  if (!instance) {
    instance = makeGraphqlClient();
  }

  return instance;
}

export { getGraphqlClient };
