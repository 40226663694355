import React from 'react';
import Icon from '@ant-design/icons';

function OutputPanelOpenSvg() {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66938 20.3938C2.72517 20.3938 1.81962 20.0065 1.15196 19.317C0.4843 18.6275 0.109213 17.6924 0.109213 16.7173L0.109214 4.07027C0.109214 3.09521 0.484302 2.16008 1.15196 1.47061C1.81962 0.781137 2.72517 0.393796 3.66938 0.393796L19.3341 0.393798C20.2783 0.393798 21.1839 0.781139 21.8515 1.47061C22.5192 2.16008 22.8943 3.09521 22.8943 4.07027L22.8943 16.7173C22.8943 17.6924 22.5192 18.6275 21.8515 19.317C21.1839 20.0065 20.2783 20.3938 19.3341 20.3938L3.66938 20.3938ZM1.53328 16.7173C1.53328 17.3024 1.75833 17.8634 2.15893 18.2771C2.55953 18.6908 3.10285 18.9232 3.66938 18.9232L6.80233 18.9232L6.80233 1.86438L3.66938 1.86438C3.10285 1.86438 2.55953 2.09679 2.15893 2.51047C1.75833 2.92416 1.53328 3.48523 1.53328 4.07027L1.53328 16.7173ZM8.2264 1.86439L8.2264 18.9232L19.3341 18.9232C19.9006 18.9232 20.444 18.6908 20.8446 18.2771C21.2452 17.8634 21.4702 17.3024 21.4702 16.7173L21.4702 4.07027C21.4702 3.48523 21.2452 2.92416 20.8446 2.51047C20.444 2.09679 19.9006 1.86439 19.3341 1.86439L8.2264 1.86439Z"
        fill="#7A8E9D"
      />
      <g clipPath="url(#clip0_4_10097)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1184 7.77715C17.509 7.38663 17.5881 6.83261 17.2952 6.53971C17.0023 6.24682 16.4483 6.32597 16.0578 6.71649L13.2294 9.54492C12.986 9.78824 12.8636 10.095 12.8767 10.3626C12.8634 10.6303 12.9859 10.9372 13.2293 11.1807L16.0578 14.0091C16.4483 14.3997 17.0023 14.4788 17.2952 14.1859C17.5881 13.893 17.5089 13.339 17.1184 12.9485L14.5328 10.3628L17.1184 7.77715Z"
          fill="#7A8E9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_10097">
          <rect
            width="9.47368"
            height="5.26316"
            fill="white"
            transform="translate(17.6311 5.65696) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default function OutputPanelOpenIcon(props: {
  style?: React.CSSProperties;
}) {
  return <Icon component={OutputPanelOpenSvg} {...props} />;
}
