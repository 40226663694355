// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MiniPlaygroundPopup-module__miniPlaygroundPopup__rdcRh .ant-modal-content {
  padding: 0;
}
.MiniPlaygroundPopup-module__miniPlaygroundPopup__rdcRh .ant-modal-close {
  display: none;
}
.MiniPlaygroundPopup-module__miniPlaygroundPopup__rdcRh .editorErrorLineLast {
  background-color: inherit !important;
}
.MiniPlaygroundPopup-module__wrapper__tj1GG {
  background: #f7f7f7;
  padding: 16px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}
.MiniPlaygroundPopup-module__header__MWSAy {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 8px;
}
.MiniPlaygroundPopup-module__runInfo__uOG2e {
  font-size: 12px;
  color: #00000073;
}
.MiniPlaygroundPopup-module__sentenceInfo__IA23g {
  font-size: 14px;
  font-family: 'Source Code Pro';
}
.MiniPlaygroundPopup-module__closeIcon__UuFba svg {
  color: #00000040;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.MiniPlaygroundPopup-module__content__tSq5y {
  min-height: 400px;
  width: 100%;
  background: white;
}
.MiniPlaygroundPopup-module__footer__M6QFg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/popups/MiniPlaygroundPopup.module.less"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AALA;EAUI,oCAAA;AAFJ;AAMA;EACE,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAJF;AAOA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,uBAAA;EACA,mBAAA;AALF;AAWA;EACE,eAAA;EACA,gBAAA;AATF;AAYA;EACE,eAAA;EACA,8BAAA;AAVF;AAaA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAXF;AAcA;EACE,iBAAA;EACA,WAAA;EACA,iBAAA;AAZF;AAeA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;AAbF","sourcesContent":[".miniPlaygroundPopup {\n  & :global(.ant-modal-content) {\n    padding: 0;\n  }\n\n  & :global(.ant-modal-close) {\n    display: none;\n  }\n\n  :global(.editorErrorLineLast) {\n    background-color: inherit !important;\n  }\n}\n\n.wrapper {\n  background: #f7f7f7;\n  padding: 16px;\n  border-radius: 6px;\n  width: 100%;\n  height: 100%;\n}\n\n.header {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  padding-bottom: 8px;\n}\n\n.headerInfo {\n}\n\n.runInfo {\n  font-size: 12px;\n  color: #00000073;\n}\n\n.sentenceInfo {\n  font-size: 14px;\n  font-family: 'Source Code Pro';\n}\n\n.closeIcon svg {\n  color: #00000040;\n  width: 20px;\n  height: 20px;\n  cursor: pointer;\n}\n\n.content {\n  min-height: 400px;\n  width: 100%;\n  background: white;\n}\n\n.footer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  padding-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"miniPlaygroundPopup": `MiniPlaygroundPopup-module__miniPlaygroundPopup__rdcRh`,
	"wrapper": `MiniPlaygroundPopup-module__wrapper__tj1GG`,
	"header": `MiniPlaygroundPopup-module__header__MWSAy`,
	"runInfo": `MiniPlaygroundPopup-module__runInfo__uOG2e`,
	"sentenceInfo": `MiniPlaygroundPopup-module__sentenceInfo__IA23g`,
	"closeIcon": `MiniPlaygroundPopup-module__closeIcon__UuFba`,
	"content": `MiniPlaygroundPopup-module__content__tSq5y`,
	"footer": `MiniPlaygroundPopup-module__footer__M6QFg`
};
export default ___CSS_LOADER_EXPORT___;
