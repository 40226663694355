/* eslint-disable no-case-declarations */
import React from 'react';
import { BaseEditor, Transforms, Editor, Range, Node, Path } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import { isEmpty } from 'lodash';
import { message } from 'antd';
import shortUUID from 'short-uuid';
import {
  generateText,
  getDefaultNewLine,
  getEditorNodesFromSelection,
  getNodeByPath,
  parseText
} from './utils';
import {
  EditorBlock,
  ExecutionStatus,
  ISentence
} from '../Editor/editorInterface';
import { textDeFormatter } from '../EditorWidgets/decorators';

type EditorInstance = BaseEditor & ReactEditor & HistoryEditor;

const getSelectedBlocksWithPath = (editor: EditorInstance) => {
  const { selection } = editor;
  if (selection) {
    const reversed = Path.isAfter(
      selection?.anchor.path,
      selection?.focus.path
    );

    const selectedBlocks = Array.from(
      Node.elements(editor, {
        from: selection.anchor.path,
        to: selection.focus.path,
        reverse: reversed
      })
    );

    if (reversed) {
      selectedBlocks.reverse();
    }
    return selectedBlocks;
  }
  return null;
};

const adjustMultipleLineDelete = (editor: EditorInstance) => {
  const anchorNode: any = getNodeByPath(editor, editor.selection?.anchor.path);
  if (anchorNode.children.length > 1) {
    Editor.withoutNormalizing(editor, () => {
      for (let i = 1; i < anchorNode.children.length; i += 1) {
        Transforms.removeNodes(editor, {
          at: [editor.selection?.anchor.path[0]!, 1]
        });
      }

      // Update the text

      Transforms.insertText(
        editor,
        anchorNode.children.map((n: any) => n.text).join(''),
        { at: [editor.selection?.anchor.path[0]!, 0] }
      );

      // Set the selection to the end of the first children text
      Transforms.select(editor, {
        anchor: {
          path: [editor.selection?.anchor.path[0]!, 0],
          offset: anchorNode.children[0].text.length
        },
        focus: {
          path: [editor.selection?.anchor.path[0]!, 0],
          offset: anchorNode.children[0].text.length
        }
      });
    });
  }
};

export const indentBlockHandler = (editor: EditorInstance) => {
  const selectedBlocks = getSelectedBlocksWithPath(editor);

  if (selectedBlocks) {
    for (let i = 0; i < selectedBlocks.length; i += 1) {
      const path = selectedBlocks[i][1];
      if (!path) return;
      const [node] = Editor.node(editor, [path[0], 0]) as unknown as [
        ISentence
      ];
      const [pnode] = Editor.node(editor, [path[0] - 1, 0]);
      const prevNode = pnode as ISentence;

      // If the tabCount difference between the current line and previous line is more than 1 then don't allow indent
      // @ts-ignore
      if (node.tabCount + 1 - prevNode.tabCount > 1) return;

      if (prevNode) {
        Transforms.setNodes<ISentence>(
          editor,
          {
            ...node,
            tabCount: node.tabCount ? node.tabCount + 1 : 1
          },
          { at: [path[0], 0] }
        );
      }
    }
  }
};

export const outdentBlockHandler = (editor: EditorInstance) => {
  const selectedBlocks = getSelectedBlocksWithPath(editor);

  if (selectedBlocks) {
    for (let i = 0; i < selectedBlocks.length; i += 1) {
      const path = selectedBlocks[i][1];
      if (!path) return;
      const [node] = Editor.node(editor, [path[0], 0]);
      if (node) {
        Transforms.setNodes(
          editor,
          // @ts-ignore
          {
            ...node,
            // @ts-ignore
            tabCount: Math.max(node.tabCount - 1, 0)
          },
          { at: [path[0], 0] }
        );
      }
    }
  }
};

export const handleEnter = (
  e: React.KeyboardEvent,
  editor: EditorInstance,
  onPlay?: () => void
) => {
  if (e.ctrlKey || e.metaKey) {
    e.preventDefault();
    if (onPlay) {
      onPlay();
    }
    // setIsEditorBlured(true);
    return;
  }

  const { selection } = editor;
  if (selection) {
    e.preventDefault();
    const [start] = Range.edges(selection);
    const nodePath: any = getNodeByPath(editor);
    const path = editor.selection?.anchor.path;

    const { text } = nodePath.children[0];
    // TODO: in case of bullet point, make shift enter type of behaviour

    // Hanlde the case when the cursor is in between the text
    if (text.length === start.offset) {
      const newLine: EditorBlock = getDefaultNewLine();
      newLine.children[0].tabCount = nodePath.children[0].tabCount;
      Transforms.insertNodes(editor, newLine);
      // TODO: Check if I need to adjust childrens
    } else {
      if (!path) return;
      const [node] = Editor.node(editor, path);
      const currentode = node as ISentence;
      const preText = currentode.text.slice(0, start.offset);
      const postText = currentode.text.slice(start.offset);
      Transforms.splitNodes(editor, { always: true });
      Transforms.setNodes(
        editor,
        {
          ...currentode,
          text: preText,
          uiLineId: shortUUID.generate(),
          status: ExecutionStatus.NEW_LINE
        },
        { at: path }
      );

      // SetNodes with updated id, lineNumber and executionStatus
      Transforms.setNodes(
        editor,
        {
          text: postText,
          // @ts-ignore
          uiLineId: node.uiLineId,
          status: ExecutionStatus.NEW_LINE
        },
        { at: [path[0] + 1, path[1]] }
      );
    }
  }
};

export const handleFullDelete = (editor: EditorInstance) => {
  const editorNodes = Array.from(Node.elements(editor)).map((n) => {
    return { node: n[0].children[0], path: n[1] };
  });

  if (
    editorNodes.length === 1 &&
    editorNodes[0].node.text === '' &&
    editorNodes[0].node.tabCount &&
    editorNodes[0].node.tabCount > 0
  ) {
    Transforms.setNodes(
      editor,
      {
        ...editorNodes[0].node,
        tabcount: 0
      },
      { at: [editorNodes[0].path[0], 0] }
    );
  }
};

export const handleBackspace = (
  e: React.KeyboardEvent,
  editor: EditorInstance
) => {
  const { selection } = editor;
  const path = editor.selection?.anchor.path;
  if (selection) {
    const range = Range.edges(selection);
    const currentNode = getNodeByPath(editor, path);

    // If Anchor and Focus both are at same node then it is simple backspace
    // Let the default behaviour happen

    // If Anchor and Focus both are at different node then it is a delete
    if (range && range[0].path[0] !== range[1].path[0]) {
      e.preventDefault();
      Editor.deleteFragment(editor);
      handleFullDelete(editor);
      adjustMultipleLineDelete(editor);
    }

    // Backspace to handle outdent
    else if (
      range &&
      range[0].path[0] === range[1].path[0] &&
      range[0].offset === range[1].offset &&
      range[0].offset === 0 &&
      // @ts-ignore
      currentNode?.children?.[0].tabCount > 0
      // // @ts-ignore
      // currentNode?.children?.[0].text !== ''
    ) {
      e.preventDefault();
      outdentBlockHandler(editor);
    }

    // If Anchor and Focus both are at same node and at the same offset at 0th position then delete the node and
    // Take the text from the node and append it to the previous node
    else if (
      range &&
      range[0].path[0] === range[1].path[0] &&
      range[0].offset === range[1].offset &&
      range[0].offset === 0
    ) {
      e.preventDefault();
      if (!path) return;
      const [node] = Editor.node(editor, path);

      // @ts-ignore
      if (node.text.length === 0 && path[0] === 0) {
        Transforms.setNodes(
          editor,
          {
            text: '',
            answer: undefined,
            concepts: undefined,
            requests: undefined,
            status: ExecutionStatus.NEW_LINE
          },
          { at: path }
        );
      }

      if (path[0] - 1 >= 0) {
        // @ts-ignore
        const [prevNode] = Editor.node(editor, [path[0] - 1, path[1]]);
        if (prevNode) {
          // @ts-ignore
          Transforms.insertText(editor, prevNode.text + node.text, {
            at: [path[0] - 1, path[1]]
          });
          Transforms.select(editor, {
            path: [path[0] - 1, path[1]],
            // @ts-ignore
            offset: prevNode.text.length
          });
          Transforms.liftNodes(editor, { at: path, mode: 'highest' });
        }
      }
    }
  }
};

export const handleNewLineAdd = (editor: EditorInstance) => {
  const newLine: EditorBlock = getDefaultNewLine();
  ReactEditor.focus(editor);
  // move the cursor to the end of the file
  Transforms.select(editor, {
    anchor: Editor.end(editor, []),
    focus: Editor.end(editor, [])
  });

  Transforms.insertNodes(editor, newLine);
  ReactEditor.focus(editor);
};

export const handleCut = (
  e: React.ClipboardEvent,
  editor: EditorInstance,
  replacerObj?: Record<string, any>
) => {
  e.preventDefault();
  const selectedBlocks = getEditorNodesFromSelection(editor);
  const text = generateText(selectedBlocks).replace(/\n+$/, '');
  const deFormattedText = textDeFormatter(text, replacerObj);
  e.clipboardData.setData('text', deFormattedText);
  Editor.withoutNormalizing(editor, () => {
    Editor.deleteFragment(editor);
  });
  // Adjust the line if there are multiple children
  adjustMultipleLineDelete(editor);
  return e.clipboardData;
};

export const handleCopy = (
  e: React.ClipboardEvent,
  editor: EditorInstance,
  replacerObj?: Record<string, any>
) => {
  e.preventDefault();
  const selectedBlocks = getEditorNodesFromSelection(editor);
  const text = generateText(selectedBlocks).replace(/\n+$/, '');
  const deFormattedText = textDeFormatter(text, replacerObj);
  e.clipboardData.setData('text', deFormattedText);
  return e.clipboardData;
};

const handleSimplePaste = (text: string, editor: EditorInstance) => {
  const { selection } = editor;
  if (selection) {
    Editor.withoutNormalizing(editor, () => {
      Editor.deleteFragment(editor);
    });
    adjustMultipleLineDelete(editor);
    Editor.insertText(editor, text);
  }
};

export const handlePaste = (text: string, editor: EditorInstance) => {
  if (!text.includes('\n')) {
    handleSimplePaste(text, editor);
  } else {
    const parseOutput = parseText(text.replace(/\n+$/, ''));
    const { documentLines, errorMesaage } = parseOutput;

    if (editor.selection) {
      Editor.withoutNormalizing(editor, () => {
        Editor.deleteFragment(editor);
      });
      adjustMultipleLineDelete(editor);
      // After deltefragment check if the block has 2 children, make it one line
    }

    setTimeout(() => {
      Editor.withoutNormalizing(editor, () => {
        // Special case when there is unnecssary hanging line
        const editorlines = Array.from(Node.elements(editor)).map((n) => {
          return { node: n[0].children[0], path: n[1] };
        });

        if (
          editorlines?.length === 1 &&
          !isEmpty(editorlines[0].node.tabCount)
        ) {
          Transforms.setNodes(
            editor,
            {
              ...editorlines[0].node,
              tabCount: 0
            },
            { at: [0, 0] }
          );
        }

        // Get selected path
        const path = editor.selection?.anchor.path;
        if (path) {
          const [node] = Editor.node(editor, path);
          // @ts-ignore
          const { text } = node;
          const offset = editor.selection?.anchor.offset;
          const selectedNode = getNodeByPath(editor, path);

          // This is needed so that we can adjust the ID if ther are parent child relationship
          if (documentLines.length > 0) {
            Transforms.setNodes(
              editor,
              // @ts-ignore
              {
                ...selectedNode,
                uiLineId: documentLines[0].children[0].uiLineId
              },
              { at: path }
            );
          }

          // TODO: Can be optimized
          if (offset === text.length) {
            const updaedText = text.length
              ? `${text}${documentLines[0].children[0].text}`
              : documentLines[0].children[0].text;
            Transforms.insertText(editor, updaedText, { at: path });
            Transforms.insertNodes(editor, documentLines.slice(1));
            const newFocusLocation = {
              path,
              offset: updaedText.length
            };
            Transforms.select(editor, {
              anchor: newFocusLocation,
              focus: newFocusLocation
            });
          } else {
            if (offset && offset < text.length) {
              const preText = text.substring(0, offset);
              const postText = text.substring(offset);
              const updaedText = `${preText}${documentLines[0].children[0].text}`;
              Transforms.insertText(editor, updaedText, { at: path });
              const lastNode = documentLines[documentLines.length - 1];
              documentLines[documentLines.length - 1] = {
                ...lastNode,
                children: [
                  {
                    ...lastNode.children[0],
                    text: `${lastNode.children[0].text}${postText}`
                  }
                ]
              };
              Transforms.insertNodes(editor, documentLines.slice(1));
              const offsetLength = `${lastNode.children[0].text}`.length;
              const newFocusLocation = {
                path: [path[0] + documentLines.length - 1, path[1]],
                offset: offsetLength
              };
              Transforms.select(editor, {
                anchor: newFocusLocation,
                focus: newFocusLocation
              });
            }
          }
        }
      });
    }, 100);

    if (errorMesaage) {
      message.error(errorMesaage);
    }
  }
};

export const handleSpace = (e: React.KeyboardEvent, editor: EditorInstance) => {
  const { selection } = editor;
  if (
    selection?.focus?.offset === 0 &&
    selection?.focus === selection?.anchor
  ) {
    e.preventDefault();
    indentBlockHandler(editor);
  }
};

export const handleForwardSlash = (
  editor: EditorInstance,
  handleShowWidgetOptions?: (open: boolean) => void
) => {
  const { selection } = editor;
  if (selection) {
    const node = getNodeByPath(editor, selection?.anchor.path);
    if (
      selection.anchor.path[0] === selection.focus.path[0] &&
      selection.anchor.offset === selection.focus.offset
    ) {
      const num = selection.focus.offset;
      const text = (node as any).children[0].text;
      const lastWrittenChar = text?.split('')[num - 1];
      if (lastWrittenChar !== '/') {
        handleShowWidgetOptions?.(true);
      } else {
        handleShowWidgetOptions?.(false);
      }
    }
  }
};

interface IKeyBindings {
  e: any;
  editor: EditorInstance;
  onPlay?: () => void;
  onMdOpen?: (node: any, offset: number, path: number[]) => void;
  handleShowWidgetOptions?: (open: boolean) => void;
  isWidgetOptionsOpen?: boolean;
  updateWidgetOption?: (upPressed: boolean) => void;
  selectWidgetAtIndex?: () => void;
}

export const handleKeyDown = ({
  e,
  editor,
  onPlay,
  // onMdOpen,
  handleShowWidgetOptions,
  isWidgetOptionsOpen,
  updateWidgetOption,
  selectWidgetAtIndex
}: IKeyBindings) => {
  if (isWidgetOptionsOpen) {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        updateWidgetOption?.(true);
        break;
      case 'ArrowDown':
        e.preventDefault();
        updateWidgetOption?.(false);
        break;
      case 'Enter':
        selectWidgetAtIndex?.();
        e.preventDefault();
        break;
      default:
        handleShowWidgetOptions?.(false);
        break;
    }
  } else {
    switch (e.key) {
      case '/':
        handleForwardSlash(editor, handleShowWidgetOptions);
        break;
      case 'Enter':
        handleEnter(e, editor, onPlay);
        break;
      case 'Tab':
        e.preventDefault();
        if (e.shiftKey) {
          outdentBlockHandler(editor);
        } else {
          indentBlockHandler(editor);
        }
        break;
      case 'Backspace':
        handleBackspace(e, editor);
        break;
      case 'Escape':
        e.preventDefault();
        ReactEditor.blur(editor);
        break;
      case ' ':
        handleSpace(e, editor);
        break;
      default:
        break;
    }
  }
  // }
};

// TODO:
// handle enter, backspace and cursor left, right for widgets

// TODO: Future Improvement
// 1. Add marking if there are indentation issue in the code
// 2. Add marking if there are any line which is a children of empty line
// 3. Multiple line select and paste
// 4. Add support for bullet points
