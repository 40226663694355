import { Modal } from 'antd';
import React from 'react';
import MapView from '@/components/MapView/MapView';
import styles from './FullMapPopup.module.less';

interface IFullMapProps {
  open: boolean;
  onClose: () => void;
  data: any;
  labelMap: Record<string, string>;
  filteredList?: string[];
  onFilterChange: any;
  lagendProps?: any;
}

function FullMapPopup(props: IFullMapProps) {
  const {
    open,
    onClose,
    data,
    lagendProps,
    filteredList,
    onFilterChange,
    labelMap
  } = props;
  return (
    <Modal
      className="fact-relations-viewer-popup"
      title="Map"
      centered
      open={open}
      footer={null}
      onCancel={onClose}
      width="90vw"
    >
      <div className={styles.mapContainer}>
        <MapView
          data={data}
          showMapLegend
          lagendProps={lagendProps}
          labelMap={labelMap}
          filteredList={filteredList}
          onFilterChange={onFilterChange}
        />
      </div>
    </Modal>
  );
}

export default FullMapPopup;
