import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { calcEndPosition } from '../details/runs/items/RunContextUtil';
import {
  CreateCommandMutation,
  CreateCommandMutationVariables,
  CreateServiceCommandMutation,
  CreateServiceCommandMutationVariables,
  GetCommandQuery,
  GetServiceCommandQuery,
  Step
} from '../generated/API';
import Queries from '../graphql/Queries';
import { useRunCtxV2 } from '../provider/runv2';
import AppConstants from '../utils/AppConstants';

const STOP_SERVICE_COMMAND_POLLING_DURATION = 90 * 1000;

// Should be wrapped with run-provider-context-v2
function usePlaygroundServiceCommands() {
  const { worker, stepsList } = useRunCtxV2();

  const [createCommand] = useMutation<CreateCommandMutation>(
    AppConstants.APIS.COMMANDS.CREATE()
  );
  const [getCommand] = useLazyQuery<GetCommandQuery>(Queries.Command());

  const [createServiceCommand] = useMutation<CreateServiceCommandMutation>(
    AppConstants.APIS.SERVICE_COMMANDS.CREATE()
  );
  const [getServiceCommand] = useLazyQuery<GetServiceCommandQuery>(
    Queries.ServiceCommand()
  );

  const sendSteps = async (text: string, contextId?: string) => {
    const isMultilineText = text.trim().includes('\n');
    const commandContextId = contextId ? Number(contextId) : 0;

    const variables: CreateCommandMutationVariables = {
      input: {
        name: 'debug',
        description: 'debug',
        workerId: worker.id,
        text,
        subTextLanguage: 'english',
        contextId: commandContextId,
        sentenceId: calcEndPosition(
          stepsList.filter((step) => step.contextId === `${commandContextId}`)
        ),
        isMultilineText
      }
    };

    const response = await createCommand({
      variables
    });

    const commandId = response.data?.createCommand!.id;

    let timer: any = null;

    const data = await Promise.race([
      new Promise((resolve) => {
        timer = setInterval(async () => {
          const command = await getCommand({
            variables: {
              id: commandId
            }
          });

          const { receivedAt, failedAt } = command.data?.getCommand || {};
          if (failedAt && failedAt.length > 0) {
            resolve(command);
            clearInterval(timer);
            console.log('Rejected by brain', command.data?.getCommand?.id);
          }
          if (receivedAt && receivedAt.length > 0) {
            resolve(command);
            clearInterval(timer);
            console.log('Received by brain', command.data?.getCommand?.id);
          }
        }, 1000);
      }),
      new Promise((_, reject) => {
        setTimeout(() => {
          clearInterval(timer);
          reject(new Error('Command not yet received by brain'));
        }, STOP_SERVICE_COMMAND_POLLING_DURATION);
      })
    ]);

    return data;
  };

  // eslint-disable-next-line no-underscore-dangle
  const _handleServiceCommand = async (action: string, params: any) => {
    const variables: CreateServiceCommandMutationVariables = {
      input: {
        owner: worker.owner,
        workerId: worker.id,
        method: action
      }
    };

    if (params) {
      variables.input.params = JSON.stringify(params);
    }

    const data = await createServiceCommand({ variables });

    const id = data.data?.createServiceCommand!.id;

    let timer: any = null;

    await Promise.race([
      new Promise((resolve) => {
        timer = setInterval(async () => {
          const command = await getServiceCommand({
            variables: {
              id
            }
          });
          if (command.data?.getServiceCommand?.result) {
            clearInterval(timer);
            resolve(command);
          }
        }, 2000);
      }),
      new Promise((_, reject) => {
        setTimeout(() => {
          clearInterval(timer);
          reject(new Error('Command not yet received by brain'));
        }, STOP_SERVICE_COMMAND_POLLING_DURATION);
      })
    ]);
  };

  const scratchAllSteps = async () => {
    return _handleServiceCommand('scratch_all', null);
  };

  const scratchStep = async (step: Step) => {
    const params: any = {
      location_dict: {
        ctx_id: parseInt(step.contextId, 10),
        sentence_index: step.positionInContext
      },
      break_after_steps: 1
    };

    return _handleServiceCommand('scratch_steps', params);
  };

  return {
    sendSteps,
    scratchAllSteps,
    scratchStep
  };
}

export default usePlaygroundServiceCommands;
