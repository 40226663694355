import { gql } from '@apollo/client';

export const UPDATE_PLAYGROUND_DOCUMENT = gql`
  mutation updateWorkerDocument(
    $workerId: ID!
    $document: [SentenceInput!]!
    $shouldStartExecution: Boolean!
    $useAutopilot: Boolean
    $documentToken: String # $isLocalOnly: Boolean
  ) {
    updateWorkerDocument(
      input: {
        workerId: $workerId
        document: $document
        shouldStartExecution: $shouldStartExecution
        useAutopilot: $useAutopilot
        documentToken: $documentToken
        # isLocalOnly: $isLocalOnly
      }
    ) {
      sentences {
        lineId
        text
        parentId
        language
        metadata {
          lineId
          parentId
        }
        internalMetadata {
          key
          value
        }
      }
      updatedAt
    }
  }
`;

export const PAUSE_WORKER = gql`
  mutation pauseWorker($workerId: ID!) {
    pauseWorker(workerId: $workerId)
  }
`;

export const CREATE_WAKEUP_CALL = gql`
  mutation createWakeupCall($workerId: ID!) {
    createWakeupCall(input: { workerId: $workerId }) {
      id
    }
  }
`;

export const CREATE_MINI_PLAYGROUND = gql`
  mutation CreateMiniPlayground($input: CreateMiniPlaygroundInput!) {
    createMiniPlayground(input: $input) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      skippedAt
      processedAt
      failedAt
      workErrors
      createdBy
      lineId
      lineToken
    }
  }
`;
