import React from 'react';
import Icon from '@ant-design/icons';

function AutoPilotSVG({
  fill,
  width,
  height
}: {
  fill: string;
  width: number;
  height: number;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <path
        d="M3.13043 7.82614C7.43478 7.85223 9.86087 5.2957 10.5652 0.391357C10.4609 5.92179 13.5913 7.64353 18 7.82614C13.456 7.92941 10.5474 9.87243 10.5637 15.0966C10.5645 15.151 10.565 15.2057 10.5652 15.2609C10.5644 15.2058 10.5639 15.151 10.5637 15.0966C10.4757 8.75817 6.77744 8.34345 3.13043 7.82614Z"
        fill={fill}
      />
      <path
        d="M0 3.32609C1.92563 3.33776 3.01098 2.19405 3.32609 0C3.27941 2.47414 4.67986 3.24439 6.65217 3.32609C4.61934 3.37229 3.3181 4.24153 3.32543 6.57868C3.32577 6.60298 3.32599 6.62748 3.32609 6.65217C3.32573 6.62752 3.32551 6.60302 3.32543 6.57868C3.28605 3.74305 1.63155 3.55751 0 3.32609Z"
        fill={fill}
      />
      <path
        d="M0.782608 14.2826C2.93478 14.2956 4.14783 13.0174 4.5 10.5652C4.44783 13.3304 6.01304 14.1913 8.21739 14.2826C5.9454 14.3342 4.49107 15.3057 4.49927 17.9178C4.49964 17.945 4.49989 17.9724 4.5 18C4.4996 17.9724 4.49935 17.945 4.49927 17.9178C4.45526 14.7486 2.60611 14.5412 0.782608 14.2826Z"
        fill={fill}
      />
    </svg>
  );
}

export default function AutoPilotIcon(props: {
  style?: React.CSSProperties;
  width: number;
  height: number;
  fill: string;
}) {
  return (
    <Icon {...props}>
      <AutoPilotSVG
        fill={props.fill}
        width={props.width}
        height={props.height}
      />
    </Icon>
  );
}
