import React from 'react';
import Icon from '@ant-design/icons';

function NewTabSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_792_22342)">
        <path
          d="M0.966737 1.60658L0.966736 16.3923C0.966736 16.7479 1.25401 17.0352 1.60959 17.0352L16.3953 17.0352C16.7509 17.0352 17.0382 16.7479 17.0382 16.3923L17.0382 9.16015C17.0382 9.07176 16.9658 8.99944 16.8775 8.99944L15.7525 8.99944C15.6641 8.99944 15.5917 9.07176 15.5917 9.16015L15.5917 15.5887L2.41316 15.5887L2.41317 2.41015L8.84174 2.41015C8.93013 2.41015 9.00245 2.33783 9.00245 2.24944L9.00245 1.12444C9.00245 1.03604 8.93013 0.963723 8.84174 0.963723L1.60959 0.963722C1.25401 0.963722 0.966737 1.251 0.966737 1.60658Z"
          fill="#7A8E9D"
        />
        <path
          d="M15.6494 3.57968L16.6981 4.62834C16.7194 4.64958 16.7463 4.66441 16.7757 4.67113C16.805 4.67785 16.8357 4.6762 16.8641 4.66636C16.8926 4.65652 16.9177 4.6389 16.9367 4.61548C16.9556 4.59207 16.9676 4.56381 16.9713 4.53392L17.3932 0.9299C17.4052 0.827444 17.3188 0.739051 17.2144 0.751104L13.6103 1.17298C13.4778 1.18905 13.4215 1.35177 13.5159 1.44619L14.5686 2.49887L9.42173 7.64575C9.35945 7.70803 9.35945 7.81048 9.42173 7.87276L10.2735 8.72454C10.3358 8.78682 10.4382 8.78682 10.5005 8.72454L15.6494 3.57968Z"
          fill="#7A8E9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_792_22342">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 18) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default function NewTabIcon(props: { style?: React.CSSProperties }) {
  return <Icon component={NewTabSvg} {...props} />;
}
