// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IntegrateBookPopup-module__integrtationPopup__wKNHU {
  border-radius: 16px;
}
.IntegrateBookPopup-module__integrtationPopup__wKNHU .ant-modal-content {
  background: var(--grey-bg, #fff);
  padding: 0;
}
.IntegrateBookPopup-module__header___mPtP {
  color: var(--grey-9, #19242e);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 133.333% */
}
.IntegrateBookPopup-module__topContainer__RmXRC {
  padding: 24px;
  border-bottom: 1px solid var(--grey-1, #ebf2f4);
}
.IntegrateBookPopup-module__bottomContainer__Ni0I0 {
  padding: 24px;
  max-height: 32vh;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.IntegrateBookPopup-module__integrationContainer__OeUeN {
  display: flex;
  padding: 24px;
  flex-direction: column;
}
.IntegrateBookPopup-module__formContainer__Y_uRo {
  margin: 24px 0px 0px;
  min-height: 100px;
  position: relative;
}
.IntegrateBookPopup-module__submitContainer__KRmjr {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.IntegrateBookPopup-module__cancelBtn__JoKOI {
  margin-right: 16px;
}
.IntegrateBookPopup-module__noFormContainer__QhHqB {
  display: flex;
  flex-direction: column-reverse;
}
`, "",{"version":3,"sources":["webpack://./src/components/IntegrateBookPopup/IntegrateBookPopup.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AAFA;EAII,gCAAA;EACA,UAAA;AACJ;AAEA;EACE,6BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAAA,aAAa;AACf;AACA;EACE,aAAA;EACA,+CAAA;AACF;AACA;EACE,aAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,eAAA;EACA,SAAA;AACF;AAEA;EACE,aAAA;EACA,aAAA;EACA,sBAAA;AAAF;AAGA;EACE,oBAAA;EACA,iBAAA;EACA,kBAAA;AADF;AAGA;EACE,gBAAA;EACA,aAAA;EACA,yBAAA;AADF;AAIA;EACE,kBAAA;AAFF;AAKA;EACE,aAAA;EACA,8BAAA;AAHF","sourcesContent":[".integrtationPopup {\n  border-radius: 16px;\n\n  :global(.ant-modal-content) {\n    background: var(--grey-bg, #fff);\n    padding: 0;\n  }\n}\n.header {\n  color: var(--grey-9, #19242e);\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 32px; /* 133.333% */\n}\n.topContainer {\n  padding: 24px;\n  border-bottom: 1px solid var(--grey-1, #ebf2f4);\n}\n.bottomContainer {\n  padding: 24px;\n  max-height: 32vh;\n  overflow: auto;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 16px;\n}\n\n.integrationContainer {\n  display: flex;\n  padding: 24px;\n  flex-direction: column;\n}\n\n.formContainer {\n  margin: 24px 0px 0px;\n  min-height: 100px;\n  position: relative;\n}\n.submitContainer {\n  margin-top: 16px;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.cancelBtn {\n  margin-right: 16px;\n}\n\n.noFormContainer {\n  display: flex;\n  flex-direction: column-reverse;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"integrtationPopup": `IntegrateBookPopup-module__integrtationPopup__wKNHU`,
	"header": `IntegrateBookPopup-module__header___mPtP`,
	"topContainer": `IntegrateBookPopup-module__topContainer__RmXRC`,
	"bottomContainer": `IntegrateBookPopup-module__bottomContainer__Ni0I0`,
	"integrationContainer": `IntegrateBookPopup-module__integrationContainer__OeUeN`,
	"formContainer": `IntegrateBookPopup-module__formContainer__Y_uRo`,
	"submitContainer": `IntegrateBookPopup-module__submitContainer__KRmjr`,
	"cancelBtn": `IntegrateBookPopup-module__cancelBtn__JoKOI`,
	"noFormContainer": `IntegrateBookPopup-module__noFormContainer__QhHqB`
};
export default ___CSS_LOADER_EXPORT___;
