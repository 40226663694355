/* eslint-disable no-underscore-dangle */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  ListWorkerOutputsByProcedureQuery,
  ListWorkerOutputsByProcedureQueryVariables
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Queries from '@/graphql/Queries';
import { FetchResult } from '@apollo/client';
import AppUtil from '../../utils/AppUtil';

export interface GetProcedureOutputsParams
  extends ListWorkerOutputsByProcedureQueryVariables {
  page?: number;
}

export const getProcedureOutputs = createAsyncThunk<
  FetchResult<ListWorkerOutputsByProcedureQuery>,
  GetProcedureOutputsParams,
  AsyncThunkConfig
>('procedureOutputs/fetchAll', async ({ page, ...rest }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.ListWorkerOutputsByProcedure(),
    variables: {
      ...rest
    },
    fetchPolicy: 'network-only'
  })
);

const ProcedureOutputsAdapter = createEntityAdapter<any>({
  selectId: (output) => output._worker
});

interface IState {
  loading: boolean;
  error: any;
  nextToken: string | undefined;
  columns: string[];
  pageDataMap: Record<number, any[]>;
}

export const procedureOutputsSlice = createSlice({
  name: 'procedureOutputs',
  initialState: ProcedureOutputsAdapter.getInitialState<IState>({
    loading: false,
    error: undefined,
    nextToken: undefined,
    columns: [],
    pageDataMap: {}
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProcedureOutputs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getProcedureOutputs.fulfilled,
      (state, { payload, meta }) => {
        if (payload?.data?.listWorkerOutputsByProcedure) {
          const { columns, data, nextToken } =
            payload.data.listWorkerOutputsByProcedure;

          const outputs = AppUtil.safeParseJSON(data) as any[];

          state.columns = AppUtil.listFilterPrivateValue(columns || []);

          if (!meta.arg.nextToken) {
            ProcedureOutputsAdapter.setAll(state, outputs);
          } else {
            ProcedureOutputsAdapter.addMany(state, outputs);
          }

          if (meta.arg.page && outputs.length > 0) {
            state.pageDataMap[meta.arg.page] = outputs;
          }

          state.nextToken = nextToken || undefined;
        }
        state.loading = false;
      }
    );
    builder.addCase(getProcedureOutputs.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.loading = false;
    });
  }
});

export const procedureOutputsSelector =
  ProcedureOutputsAdapter.getSelectors<RootState>(
    (state) => state.procedureOutputs
  );

export const procedureOutputsQuerySelector = (state: RootState) => ({
  loading: state.procedureOutputs.loading,
  outputs: procedureOutputsSelector.selectAll(state),
  columns: state.procedureOutputs.columns,
  error: state.procedureOutputs.error,
  nextToken: state.procedureOutputs.nextToken,
  pageDataMap: state.procedureOutputs.pageDataMap
});

export default procedureOutputsSlice.reducer;
