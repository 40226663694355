import { RequestQuestion } from '../generated/API';
import {
  ExceptionStateType,
  IException,
  IExceptionStatusCounts
} from '../pages/exceptions/interface';

export const prepareException = (
  requestQuestion: RequestQuestion
): IException => {
  const questionState = requestQuestion.request?.state;

  let state = ExceptionStateType.PENDING;

  if (questionState === 'archived') {
    state = ExceptionStateType.ARCHIVED;
  } else if (questionState === 'handled') {
    state = ExceptionStateType.HANDLED;
  }

  return {
    id: requestQuestion.id,
    text: requestQuestion.text || '',
    summary: requestQuestion.summary || '',
    process: {
      id: requestQuestion.request?.procedureId || '',
      name: requestQuestion.request?.procedureName || ''
    },
    workerId: requestQuestion.request?.worker?.id || '',
    requestId: requestQuestion?.request?.id || '',
    request: null, // populated later when an exception is made active
    assignee: requestQuestion.request?.assignee || '',
    state,
    createdAt: requestQuestion.request?.createdAt || ''
  };
};

export const prepareExceptionStatusCounts = (
  valueCounts: any
): IExceptionStatusCounts => {
  const pendingExceptions = (valueCounts?.states || []).find(
    (d: any) => d?.value === ExceptionStateType.PENDING
  );
  const archivedExceptions = (valueCounts?.states || []).find(
    (d: any) => d?.value === ExceptionStateType.ARCHIVED
  );
  const handledExceptions = (valueCounts?.states || []).find(
    (d: any) => d?.value === ExceptionStateType.HANDLED
  );

  const pendingCount = pendingExceptions?.count || 0;
  const archivedCount = archivedExceptions?.count || 0;
  const handledCount = handledExceptions?.count || 0;

  return {
    pending: pendingCount,
    archived: archivedCount,
    handled: handledCount,
    total: pendingCount + archivedCount + handledCount
  };
};
