import React, { useEffect, useState } from 'react';
import { readEml } from 'eml-parse-js';
import { Alert } from 'antd';

interface IProps {
  fileUrl: string;
}

function EMLViewer(props: IProps) {
  const { fileUrl } = props;

  const [error, setError] = useState<string | null>(null);
  const [html, setHtml] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        const file = await fetch(fileUrl);
        const fileBlob = await file.blob();
        const fileText = await fileBlob.text();

        readEml(
          fileText,
          (err: any, emlJson: { html: React.SetStateAction<string> }) => {
            if (err) {
              setError('Something went wrong, please try again.');
            }
            setHtml(emlJson.html);
          }
        );
      } catch (err) {
        setError('Failed to load eml viewer');
      }
    })();
  }, [fileUrl]);

  if (error) {
    return <Alert message="Error" description={error} type="error" />;
  }

  return html ? (
    <iframe
      style={{ border: 'none' }}
      width="100%"
      height="100%"
      title="EML file"
      srcDoc={html}
    />
  ) : null;
}

export default EMLViewer;
