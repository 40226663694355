// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PortalContainer-module__portalContainer__PpMDy {
  position: fixed;
  z-index: 11;
  border-radius: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PortalContainer.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,kBAAA;AACF","sourcesContent":[".portalContainer {\n  position: fixed;\n  z-index: 11;\n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portalContainer": `PortalContainer-module__portalContainer__PpMDy`
};
export default ___CSS_LOADER_EXPORT___;
