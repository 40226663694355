import { gql } from '@apollo/client';

export const GET_LOCAL_HISTORICAL_FACTS = gql`
  query {
    getLocalHistoricalFacts @client {
      data
    }
  }
`;

export const GET_HISTORICAL_FACTS = gql`
  query GetHistoricalFacts($knowledgeId: ID!, $factIds: [HistoricalFactID!]!) {
    getHistoricalFacts(knowledgeId: $knowledgeId, factIds: $factIds) {
      id
      names
      locations
      dereferencedId
      type
      relations {
        factId
        name
        direction
      }
      value
      descriptions {
        name
        factId
      }
      referenceTo
      isDeleted
      parentFact {
        id
        knowledgeId
        names
        parentFactId
        locations
        value
        owner
        readers
        editors
        referenceTo
        viewUrl
        childProperties
        isDeleted
      }
      parentFactId
    }
  }
`;

export const GET_FACT_VALUE = gql`
  query GetFactValue($knowledgeId: ID!, $factId: ID!, $epoch: Int!) {
    getFact(knowledgeId: $knowledgeId, factId: $factId, epoch: $epoch) {
      id
      knowledgeId
      value
    }
  }
`;
