import { LoadingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAuth } from '@/provider/auth';
import { useAppDispatch } from '@/stores/hooks';
import { showPopup } from '@/stores/slices/appPopup';
import { selectDepartment } from '@/stores/slices/department';
import { userSelector } from '@/stores/slices/user';
import AppConstants from '@/utils/AppConstants';
import { ConfirmDialog } from '@/utils/AppDialogs';
import AppUtil from '@/utils/AppUtil';
import routes from '@/utils/routes';
import styles from './UserMenu.module.less';
import AccountIcon from '../icons/AccountIcon';

interface UserMenuProps {
  hide: () => void;
}

function UserMenu(props: UserMenuProps) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { username, organization } = useSelector(userSelector);

  const handleLogout = () => {
    props.hide();
    const onConfirm = () => {
      logout()
        .catch((err: any) => {
          AppUtil.logError('Error Logging out !', err);
          logout();
        })
        .finally(() => {
          // TODO: Remove this after 2 weeks post prod deployment.
          localStorage.removeItem('persist:root');
          navigate(routes.global.getHomePageUrl());
          dispatch(selectDepartment(null));
        });
    };

    ConfirmDialog(
      'Log out',
      'Are you sure?',
      AppConstants.ICONS.QUESTION,
      onConfirm,
      undefined,
      'Yes',
      'No',
      'modal-logout',
      {
        'data-cy': 'logout-yes'
      }
    );
  };

  const handleUserInvite = () => {
    props.hide();
    navigate(routes.department.getDepartmentCollaboratorsPageUrl());
  };
  const menu = (
    <Menu>
      {organization.data === null || organization.loading ? (
        <Menu.Item
          key="create"
          data-cy={
            organization.loading
              ? 'menu-organization-loading'
              : 'menu-organization-create'
          }
          disabled={organization.loading}
          onClick={() => {
            props.hide();
            dispatch(
              showPopup({
                popupId: AppConstants.POPUPS.CREATE_ORGANIZATION,
                popupParams: {}
              })
            );
          }}
        >
          {organization.loading ? (
            <>
              Loading Organization <LoadingOutlined />
            </>
          ) : (
            'Create Organization'
          )}
        </Menu.Item>
      ) : (
        <Menu.Item
          key={organization.data.id}
          data-cy="menu-organization"
          onClick={() => {
            props.hide();
            dispatch(
              showPopup({
                popupId: AppConstants.POPUPS.CREATE_ORGANIZATION,
                popupParams: {
                  readonly: true
                }
              })
            );
          }}
        >
          Organization Settings
        </Menu.Item>
      )}

      {AppUtil.isKognitosEmail(username) && (
        <Menu.Item key="admin" data-cy="menu-admin">
          <Link
            style={{ fontSize: '12px' }}
            to={routes.admin.getAdminPageUrl()}
            onClick={() => props.hide()}
          >
            Admin Settings
          </Link>
        </Menu.Item>
      )}

      <Menu.Item
        className={styles.menuItemContent}
        onClick={handleUserInvite}
        key="invite-users"
        data-cy="menu-user-invite"
      >
        Invite members
      </Menu.Item>

      <Menu.Item
        className={styles.logout}
        onClick={handleLogout}
        key="logout"
        data-cy="menu-logout"
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.accountMenu}>
      <div className={styles.header}>
        <AccountIcon />
        <div>
          <div className="orgName">{organization?.data?.name}</div>
          <div className="userEmail" data-cy="menu-username">
            {username}
          </div>
        </div>
      </div>
      {menu}
    </div>
  );
}

export default UserMenu;
