export function downloadCSV(title: string, data: string) {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: 'text/csv' });

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob);

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a');

  // Passing the blob downloading url
  a.setAttribute('href', url);

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute('download', `${title}.csv`);

  // Performing a download with click
  a.click();
}

/**
 * Returns a 2D array of strings from a CSV string
 * Hanldes quoted fields and commas in fields
 * @param input string in CSV format
 * @returns 2D array of strings
 */
export function parseCSV(input: string): string[][] {
  return input.split('\n').map((line) => {
    return (
      line
        .split('')
        .reduce<{ fields: string[]; inQuotes: boolean }>(
          (acc, char) => {
            if (char === '"') {
              acc.inQuotes = !acc.inQuotes;
            } else if (char === ',' && !acc.inQuotes) {
              acc.fields.push('');
            } else {
              acc.fields[acc.fields.length - 1] += char;
            }
            return acc;
          },
          { fields: [''], inQuotes: false }
        )
        // eslint-disable-next-line no-useless-escape
        .fields.map((field) => field.replace(/\"/g, '').trim())
    );
  });
}
