import { createWorker } from '@/stores/slices/worker';
import { useMemo, useState } from 'react';
import AppUtil from '@/utils/AppUtil';
import {
  CreateWorkerInput,
  CreateWorkerMutationVariables,
  Department
} from '../generated/API';
import { useAppDispatch, useAppSelector } from '../stores/hooks';
import { departmentQuerySelector } from '../stores/slices/department';

function usePlaygroundV2() {
  const { department } = useAppSelector(departmentQuerySelector);
  const isNewPlayground = AppUtil.isDocumentModelSupported(department);
  const dispatch = useAppDispatch();

  const [workerId, setWorkerId] = useState<string | null>(null);

  const spawnWorker = async (
    cmd: string,
    department: Department,
    name?: string
  ) => {
    const entryCommand = {
      text: cmd,
      subText: ''
    };

    const input: CreateWorkerInput = {
      name: name || 'untitled playground',
      owner: department.owner,
      knowledgeId: department.draftKnowledgeId,
      departmentId: department.id
    };

    if (!isNewPlayground) {
      input.entryCommand = JSON.stringify(entryCommand);
    }

    const variables: CreateWorkerMutationVariables = {
      input: {
        ...input
      }
    };

    const resp = await dispatch(createWorker(variables)).unwrap();
    const worker = resp?.data?.createWorker;
    if (worker) {
      setWorkerId(worker.id);
    }
    return worker;
  };

  const value = useMemo(
    () => ({
      workerId,
      spawnWorker
    }),
    [workerId, department]
  );

  return value;
}

export default usePlaygroundV2;
