// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.excel-table {
  padding: 0 16px;
}
.excel-table .ant-tabs-content-holder {
  margin-bottom: 20px;
}
.excel-table .ant-tabs-content {
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/ExcelTable/ExcelTable.less"],"names":[],"mappings":"AAEA;EACE,eAAA;AADF;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,cAAA;AAFJ","sourcesContent":["@import '@css/variables.less';\n\n.excel-table {\n  padding: 0 16px;\n\n  & .ant-tabs-content-holder {\n    margin-bottom: 20px;\n  }\n\n  & .ant-tabs-content {\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
