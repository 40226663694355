// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CSVTable-module__antCustomTable__n9Jl_ {
  border-radius: 0 !important;
  background-color: white;
}
.CSVTable-module__csvTable__KDpmL {
  overflow: hidden;
}
.CSVTable-module__cell__EPmdO {
  padding: 6px 12px;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  color: rgba(0, 0, 0, 0.85);
}
.CSVTable-module__csvCustomTableHeaderCell__fNPq2 {
  color: #000;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 166.667% */
  border-right: none;
}
.CSVTable-module__csvTableTitleContainer__aV_o3 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.CSVTable-module__csvTable__KDpmL {
  display: flex;
  flex-direction: column;
}
.CSVTable-module__csvCustomTableBodyCell__ITJH_:first-child {
  border-left: 1px solid #d9d9d9;
}
.CSVTable-module__customTableWrapper__ocmLl {
  overflow: auto;
}
.CSVTable-module__tableExpandIcon___D5hy {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CSVTable-module__actionButton__V7vy6 {
  width: 16px;
  height: 16px;
  margin-left: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CSVTable.module.less"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,uBAAA;AACF;AAEA;EACE,gBAAA;AAAF;AAGA;EACE,iBAAA;EACA,+BAAA;EACA,gCAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EADA,aAAa;EAEb,0BAAA;AAAF;AAGA;EACE,WAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EADA,aAAa;EAEb,kBAAA;AAAF;AAGA;EACE,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AADF;AAIA;EACE,aAAA;EACA,sBAAA;AAFF;AAME;EACE,8BAAA;AAJJ;AAQA;EACE,cAAA;AANF;AASA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAPF;AAUA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AARF","sourcesContent":[".antCustomTable {\n  border-radius: 0 !important;\n  background-color: white;\n}\n\n.csvTable {\n  overflow: hidden;\n}\n\n.cell {\n  padding: 6px 12px;\n  border-right: 1px solid #d9d9d9;\n  border-bottom: 1px solid #d9d9d9;\n  font-family: Nunito;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px; /* 157.143% */\n  color: rgba(0, 0, 0, 0.85);\n}\n\n.csvCustomTableHeaderCell {\n  color: #000;\n  font-family: Nunito;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 20px; /* 166.667% */\n  border-right: none;\n}\n\n.csvTableTitleContainer {\n  color: rgba(0, 0, 0, 0.85);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 22px;\n  margin-bottom: 12px;\n  display: flex;\n  align-items: center;\n}\n\n.csvTable {\n  display: flex;\n  flex-direction: column;\n}\n\n.csvCustomTableBodyCell {\n  &:first-child {\n    border-left: 1px solid #d9d9d9;\n  }\n}\n\n.customTableWrapper {\n  overflow: auto;\n}\n\n.tableExpandIcon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.actionButton {\n  width: 16px;\n  height: 16px;\n  margin-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"antCustomTable": `CSVTable-module__antCustomTable__n9Jl_`,
	"csvTable": `CSVTable-module__csvTable__KDpmL`,
	"cell": `CSVTable-module__cell__EPmdO`,
	"csvCustomTableHeaderCell": `CSVTable-module__csvCustomTableHeaderCell__fNPq2`,
	"csvTableTitleContainer": `CSVTable-module__csvTableTitleContainer__aV_o3`,
	"csvCustomTableBodyCell": `CSVTable-module__csvCustomTableBodyCell__ITJH_`,
	"customTableWrapper": `CSVTable-module__customTableWrapper__ocmLl`,
	"tableExpandIcon": `CSVTable-module__tableExpandIcon___D5hy`,
	"actionButton": `CSVTable-module__actionButton__V7vy6`
};
export default ___CSS_LOADER_EXPORT___;
