// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-viewer {
  position: relative;
}
.pdf-viewer .page-wrapper {
  position: relative;
}
.pdf-viewer .pdf-page {
  margin-bottom: 8px;
}
.pdf-viewer .pdf-pagination-info {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.pdf-viewer .pdf-pagination-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pdf-viewer .pdf-pagination-previous[disabled],
.pdf-viewer .pdf-pagination-next[disabled] {
  cursor: not-allowed;
}
.pdf-viewer .pdf-highlight {
  position: absolute;
  background-color: rgba(38, 187, 240, 0.7);
}
.pdf-viewer .pdf-annotations-canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/PDFViewer/PDFViewer.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAFA;EAII,kBAAA;AACJ;AALA;EAWI,kBAAA;AAHJ;AAOI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AALN;AAQI;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AANN;AAUE;;EAEE,mBAAA;AARJ;AAtBA;EAkCI,kBAAA;EACA,yCAAA;AATJ;AA1BA;EAwCI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;AAXJ","sourcesContent":[".pdf-viewer {\n  position: relative;\n\n  .page-wrapper {\n    position: relative;\n  }\n\n  .loader-cnt {\n  }\n\n  .pdf-page {\n    margin-bottom: 8px;\n  }\n\n  .pdf-pagination {\n    &-info {\n      display: flex;\n      justify-content: center;\n      margin-bottom: 10px;\n    }\n\n    &-actions {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n    }\n  }\n\n  & .pdf-pagination-previous[disabled],\n  & .pdf-pagination-next[disabled] {\n    cursor: not-allowed;\n  }\n\n  .pdf-highlight {\n    position: absolute;\n    background-color: rgb(38 187 240 / 70%);\n    // border: 1px solid #26bbf0;\n  }\n\n  .pdf-annotations-canvas {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
