import { Modal } from 'antd';
import React from 'react';
import { Department } from '../generated/API';
import DepartmentCollaboratorAddForm from '../components/department/DepartmentCollaboratorAddForm';

interface IProps {
  department: Department;
  onClose: () => void;
  onSuccess?: () => void;
}

function DepartmentCollaboratorsAddPopup(props: IProps) {
  const { department, onClose, onSuccess } = props;

  return (
    <Modal
      open
      title={`Add users to '${department.name}' department`}
      onCancel={onClose}
      width={600}
      keyboard={false}
      maskClosable={false}
      footer={null}
      className="department-collaborators-add-popup"
    >
      <DepartmentCollaboratorAddForm
        department={department}
        onSuccess={() => {
          onSuccess?.();
          onClose?.();
        }}
      />
    </Modal>
  );
}

export default DepartmentCollaboratorsAddPopup;
