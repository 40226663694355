/* eslint-disable jsx-a11y/anchor-has-content */
import classNames from 'classnames';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './MarkdownRenderer.less';
import { Button } from 'antd';
import { ThunderboltOutlined } from '@ant-design/icons';
import AppUtil from '@/utils/AppUtil';
import { useAppSelector } from '@/stores/hooks';
import { departmentQuerySelector } from '@/stores/slices/department';

interface IMarkdownRendererProps {
  children: any;
  disableLineWhiteSpace?: boolean;
}

function MarkdownRenderer(props: IMarkdownRendererProps) {
  const { children, disableLineWhiteSpace } = props;

  const { department } = useAppSelector(departmentQuerySelector);

  const renderLinkComponent = ({ node: _node, ...props }: any) => {
    // eslint-disable-next-line react/prop-types
    if (props?.href?.startsWith('mailto:')) {
      return <a {...props} />;
    }
    return (
      <a {...props} target="_blank" rel="noreferrer noopener">
        Link
      </a>
    );
  };

  const handleTryIt = (node: any) => {
    // node.children[0].children[0].value;
    try {
      const event = new CustomEvent('setProcedureFromChat', {
        detail: node.children[0].children[0].value
      });
      document.dispatchEvent(event);
    } catch (e: any) {
      console.error('Error while adding automation to playground', e);
    }
  };

  const renderPreComponent = ({ node, ...props }: any) => {
    return (
      <div className="KChatC">
        <pre {...props} />
        <Button
          size="small"
          onClick={() => handleTryIt(node)}
          icon={<ThunderboltOutlined />}
        >
          Try it
        </Button>
      </div>
    );
  };

  const component: any = {
    a: renderLinkComponent
  };
  if (AppUtil.isDocumentModelSupported(department)) {
    component.pre = renderPreComponent;
  }

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={classNames('markdown-renderer', {
        'disable-line-white-space': disableLineWhiteSpace
      })}
      components={component}
    >
      {children}
    </ReactMarkdown>
  );
}

export default MarkdownRenderer;
