// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Container.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;AACF;AACA;EACE;IACE,eAAA;EACF;AACF;AACA;EACE;IACE,iBAAA;EACF;AACF;AACA;EACE;IACE,iBAAA;EACF;AACF","sourcesContent":[".container {\n  width: 100%;\n  margin-left: auto;\n  margin-right: auto;\n}\n@media (min-width: 768px) {\n  .container {\n    max-width: 100%;\n  }\n}\n@media (min-width: 1024px) {\n  .container {\n    max-width: 1024px;\n  }\n}\n@media (min-width: 1280px) {\n  .container {\n    max-width: 1280px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
