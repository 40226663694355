import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { getWindowCache, setWindowCache } from '@/utils/windowCache';

import Loader, { ILoaderType } from '../Loader';
import AnnotationsCanvas, {
  IAnnotation
} from '../document-annotations/AnnotationsCanvas';

// Component CSS
import './PDFViewer.less';

const pdfWokerJS = getWindowCache('jsCache')('pdfWokerJS');
if (!pdfWokerJS) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  setWindowCache('jsCache')('pdfWokerJS', pdfjs.GlobalWorkerOptions.workerSrc);
}

export enum PDFViewType {
  ONE = 'ONE',
  ALL = 'ALL'
}

// TODO: Replace with IAnnotations[]
export interface IPDFViewerHighlightProps {
  width: number;
  height: number;
  x: number;
  y: number;
}

// TODO: namespace props
export interface IPDFViewerProps {
  pdfFileBlob: Blob;
  pageWidth?: number; // If height is provided, width will be ignored
  pageHeight?: number;
  pageScale?: number;
  pageNumbers?: number[];
  highlight?: IPDFViewerHighlightProps;
  allowViewTypeChange?: boolean;
  defaultViewType?: PDFViewType;
  allowMultipleAnnotations?: boolean;
  getAnnotationText?: (annotation: IAnnotation) => Promise<string>;
  onNewAnnotation?: (annotation: IAnnotation) => void;
  onPageRender?: (
    pageNumber: number,
    pageRectMap: Record<number, DOMRect>
  ) => void;
}

function PDFViewer(props: IPDFViewerProps) {
  const {
    pdfFileBlob,
    pageWidth,
    pageHeight,
    pageScale,
    pageNumbers: _pageNumbers,
    allowViewTypeChange = true,
    defaultViewType = PDFViewType.ALL,
    highlight,
    allowMultipleAnnotations,
    getAnnotationText,
    onNewAnnotation,
    onPageRender
  } = props;

  const [pageNumbers, setPageNumbers] = useState(_pageNumbers || []);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pdfString, setPdfString] = useState('');
  let base64String: string | ArrayBuffer | null = '';

  const reader = new FileReader();
  reader.readAsDataURL(pdfFileBlob);
  reader.onloadend = () => {
    base64String = reader.result! as string;
    if (base64String) {
      setPdfString(base64String.substr(base64String.indexOf(',') + 1));
    }
  };

  useEffect(() => {
    setPageNumbers(_pageNumbers || []);
  }, [_pageNumbers]);

  const [pageRectMap, setPageRectMap] = useState<Record<number, DOMRect>>({});
  const [viewType, setViewType] = useState<PDFViewType>(defaultViewType);

  const pageCanvasRef = useRef<HTMLCanvasElement>(null);
  const highlightElementRef = useRef<HTMLDivElement>(null);

  const noOfPages = pageNumbers.length;

  useEffect(() => {
    if (noOfPages > 10) {
      setViewType(PDFViewType.ONE);
    }
  }, [noOfPages]);

  const showPagination = viewType === PDFViewType.ONE;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const scrollToHighlighted = () => {
    if (highlightElementRef.current) {
      const offset = 150; // sticky document info header's height + some gap
      document.querySelector('.s3-file-viewer-popup.ant-modal')?.scrollTo({
        top: highlightElementRef.current!.getBoundingClientRect().top - offset
      });
    }
  };

  const onDocumentLoadSuccess = ({
    numPages: _numPages
  }: {
    numPages: number;
  }) => {
    if (noOfPages === 0) {
      setPageNumbers(Array.from(Array(_numPages + 1).keys()).slice(1));
    }
  };

  const changePage = (offset: number) => {
    setCurrentPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const handleNextClick = () => {
    if (currentPageNumber >= noOfPages) {
      return;
    }
    changePage(1);
  };

  const handlePreviousClick = () => {
    if (currentPageNumber <= 1) {
      return;
    }
    changePage(-1);
  };

  const updatePageRect = (pageNumber: number) => {
    if (pageCanvasRef.current) {
      const updatedPageRectMap = {
        ...pageRectMap,
        [pageNumber]: pageCanvasRef.current.getBoundingClientRect()
      };
      setPageRectMap(updatedPageRectMap);
      scrollToHighlighted();
      if (onPageRender) {
        onPageRender(pageNumber, updatedPageRectMap);
      }
    }
  };

  const isLocationFullPage = (location: IPDFViewerHighlightProps): boolean => {
    const fullWidth = location.width === Math.abs(100);
    const fullHeight = location.height === Math.abs(100);
    return fullHeight && fullWidth;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function renderHighlight(pageNumber: number) {
    if (!highlight) {
      return null;
    }

    if (isLocationFullPage(highlight)) {
      return null;
    }

    const pageRect = pageRectMap[pageNumber];

    if (!pageRect) {
      return null;
    }

    let width = 0;
    let height = 0;

    if (pageRect) {
      width = pageRect.width;
      height = pageRect.height;
    }

    const rect = {
      x: (highlight.x / 100.0) * width,
      y: (highlight.y / 100.0) * height,
      width: (highlight.width / 100.0) * width,
      height: (highlight.height / 100.0) * height
    };

    return (
      <div
        className="pdf-highlight"
        // Scroll to the first highlighted location
        ref={pageNumber === 1 ? highlightElementRef : null}
        style={{
          width: rect.width,
          height: rect.height,
          top: rect.y,
          left: rect.x
        }}
      />
    );
  }

  const renderLoader = () => (
    <div className="loader-cnt pdf-loader">
      <Loader
        type={ILoaderType.SKELETON}
        skeletonConfig={{
          rows: 10
        }}
      />
    </div>
  );

  const renderPage = (pageNumber: number) => {
    const pageRect = pageRectMap[pageNumber];

    return (
      <div className="page-wrapper" key={`page_${pageNumber}`}>
        <Page
          pageNumber={pageNumber}
          width={pageHeight ? undefined : pageWidth}
          height={pageHeight}
          className="pdf-page"
          canvasRef={pageCanvasRef}
          onRenderSuccess={() => {
            setTimeout(() => {
              updatePageRect(pageNumber);
            }, 500);
          }}
          loading={renderLoader()}
          scale={pageScale}
        />
        {renderHighlight(pageNumber)}
        {pageRect && (
          <div className="pdf-annotations-canvas">
            <AnnotationsCanvas
              width={pageRect.width}
              height={pageRect.height}
              allowMultiple={allowMultipleAnnotations || false}
              pageNumber={pageNumber}
              getAnnotationText={getAnnotationText}
              onNewAnnotation={onNewAnnotation}
            />
          </div>
        )}
      </div>
    );
  };

  const pageRenderDecider = () => {
    if (!showPagination) {
      return pageNumbers.map((pageNumber) => renderPage(pageNumber));
    }
    return renderPage(currentPageNumber);
  };

  return (
    <div className="pdf-viewer" data-cy="pdf-viewer">
      {noOfPages > 0 && (
        <Space className="pdf-pagination-info">
          {allowViewTypeChange && (
            <Select
              style={{
                width: 210
              }}
              value={viewType}
              onChange={setViewType}
              options={[
                {
                  value: PDFViewType.ALL,
                  label: 'Show all pages at once'
                },
                {
                  value: PDFViewType.ONE,
                  label: 'Show one page at a time'
                }
              ]}
            />
          )}

          {showPagination && (
            <>
              <LeftOutlined
                disabled={currentPageNumber <= 1}
                onClick={handlePreviousClick}
                className="pdf-pagination-previous"
              />
              <div>
                {currentPageNumber} / {noOfPages}
              </div>
              <RightOutlined
                disabled={currentPageNumber >= noOfPages}
                onClick={handleNextClick}
                className="pdf-pagination-next"
              />
            </>
          )}
        </Space>
      )}

      <Document
        className="pdf-viewer-document"
        file={`data:application/pdf;base64,${pdfString}`}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={renderLoader()}
        options={{
          standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
          // check for cmap: https://github.com/wojtekmaj/react-pdf/issues/197
          cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true
        }}
      >
        {pageRenderDecider()}
      </Document>
    </div>
  );
}

export default PDFViewer;
