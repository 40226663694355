import React from 'react';

export default function QuestionFilledIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#ee7600"
        d="M8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 Z M8,10.5 C7.58578644,10.5 7.25,10.8357864 7.25,11.25 C7.25,11.6642136 7.58578644,12 8,12 C8.41421356,12 8.75,11.6642136 8.75,11.25 C8.75,10.8357864 8.41421356,10.5 8,10.5 Z M8,4.5 C6.8954305,4.5 6,5.3954305 6,6.5 C6,6.77614237 6.22385763,7 6.5,7 C6.77614237,7 7,6.77614237 7,6.5 C7,5.94771525 7.44771525,5.5 8,5.5 C8.55228475,5.5 9,5.94771525 9,6.5 C9,6.87057776 8.91742566,7.07932265 8.63397873,7.39754919 L8.51803918,7.52254601 L8.25395141,7.79209256 C7.7117781,8.36030937 7.5,8.76946767 7.5,9.5 C7.5,9.77614237 7.72385763,10 8,10 C8.27614237,10 8.5,9.77614237 8.5,9.5 C8.5,9.12942224 8.58257434,8.92067735 8.86602127,8.60245081 L8.98196082,8.47745399 L9.24604859,8.20790744 C9.7882219,7.63969063 10,7.23053233 10,6.5 C10,5.3954305 9.1045695,4.5 8,4.5 Z"
      />
    </svg>
  );
}
