//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Main Entry point for the Kognitos UI
//
import React from 'react';
import ReactDOM from 'react-dom';
import { notification } from 'antd';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';
import { appConfig } from '@/AppConfig';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';

import App from './App';

// Application CSS
import '@css/index.less';
import SentryErrorPage from './pages/SentryErrorPage';
import AnalyticsTracker from './tracker';

// Global notification configuration
notification.config({
  placement: 'topRight',
  duration: 5,
  maxCount: 1
});

if (appConfig.sentry) {
  Sentry.init({
    environment: appConfig.sentry.environment,
    dsn: appConfig.sentry.dsn,

    integrations: [
      new BrowserTracing({
        tracingOrigins: [],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false
      })
    ],
    beforeSend(event: Sentry.Event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }

      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        if (!event.extra) event.extra = {};
        event.extra.LogRocket = logRocketSession;
        return event;
      }
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // // This sets the sample rate to be 10%. You may want this to be 100% while
    // // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // // If the entire session is not sampled, use the below sample rate to sample
    // // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0
  });
}

if (appConfig.logRocket) {
  LogRocket.init(appConfig.logRocket.appId);
}

if (appConfig.amplitude) {
  AnalyticsTracker.init();
}

// TODO: Faiyaz - check
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Sentry.ErrorBoundary
    showDialog
    fallback={SentryErrorPage}
    onError={(error) => {
      Sentry.captureException(error);
    }}
  >
    <App />
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
