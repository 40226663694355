import React, { useEffect, useState } from 'react';
import { Alert } from 'antd';
import { renderAsync } from 'docx-preview';
import Loader, { ILoaderType } from '../Loader';
import './DocxViewer.less';

interface IProps {
  flieBlob: Blob;
}

function DocxViewer(props: IProps) {
  const { flieBlob } = props;

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setError('');
        setLoading(true);
        const arrayBuffer = await flieBlob.arrayBuffer();

        await renderAsync(arrayBuffer, document.getElementById('docx-viewer')!);
      } catch (err) {
        setError('Failed to load docx viewer');
      } finally {
        setLoading(false);
      }
    })();
  }, [flieBlob]);

  return (
    <div>
      {loading && (
        <Loader
          type={ILoaderType.SKELETON}
          skeletonConfig={{
            rows: 6
          }}
        />
      )}
      <div className="docx-viewer" id="docx-viewer">
        {error && <Alert message="Error" description={error} type="error" />}
      </div>
    </div>
  );
}

export default DocxViewer;
