import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FetchResult } from '@apollo/client';
import {
  GetOwnerOrganizationQuery,
  GetOwnerOrganizationQueryVariables,
  Organization
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Queries from '../../graphql/Queries';
import {
  delayExponentialBackoff,
  retryPromiseWithDelay
} from '../../utils/promise';
import { shouldRetryQueryOnCommonErrors } from '../../utils/query';

export const getUserOrganization = createAsyncThunk<
  FetchResult<GetOwnerOrganizationQuery>,
  GetOwnerOrganizationQueryVariables,
  AsyncThunkConfig
>('user/organization/get', async ({ owner }, thunkAPI) =>
  retryPromiseWithDelay({
    promiseFn: () =>
      thunkAPI.extra.appSyncClient.query({
        query: Queries.OwnerOrganization(),
        variables: {
          owner
        }
      }),
    errorPredicate: shouldRetryQueryOnCommonErrors,
    retries: 5,
    delayTime: delayExponentialBackoff
  })
);

interface IState {
  username: string | null;
  loading: boolean;
  organization: {
    data: Organization | null;
    loading: boolean;
    error: any;
  };
}

const initialState: IState = {
  username: null,
  loading: true,
  organization: {
    data: null,
    loading: false,
    error: null
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUsername: (state, action: PayloadAction<string | null>) => {
      state.username = action.payload;
    },
    updateAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateOrganization: (state, action: PayloadAction<Organization>) => {
      state.organization.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserOrganization.pending, (state) => {
      state.organization.loading = true;
    });
    builder.addCase(getUserOrganization.fulfilled, (state, { payload }) => {
      if (payload.data?.getOwnerOrganization) {
        state.organization.data = payload.data.getOwnerOrganization;
      }
      state.organization.loading = false;
    });
    builder.addCase(getUserOrganization.rejected, (state, { payload }) => {
      state.organization.error = payload as any;
      state.organization.loading = false;
    });
  }
});

export const { updateUsername, updateAuthLoading, updateOrganization } =
  userSlice.actions;

export const userSelector = (state: RootState) => state.user;

export default userSlice.reducer;
