import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  CreateProcedureMutation,
  CreateProcedureMutationVariables,
  GetProcedureQuery,
  GetProcedureQueryVariables,
  ListProceduresByDepartmentQuery,
  ListProceduresByDepartmentQueryVariables,
  Procedure,
  UpdateProcedureScheduleMutation,
  UpdateProcedureScheduleMutationVariables
} from '@/generated/API';
import { RootState, AsyncThunkConfig } from '@/stores/AppStore';
import { FetchResult } from '@apollo/client';
import Mutations from '@/graphql/Mutations';
import {
  delayExponentialBackoff,
  retryPromiseWithDelay
} from '@/utils/promise';
import {
  GET_PROCEDURE,
  LIST_PROCEDURES_BY_DEPARTMENT
} from '../../graphql/procedure';

export interface GetProceduresByDepartmentParams
  extends ListProceduresByDepartmentQueryVariables {
  page?: number;
}

export const getProceduresByDepartment = createAsyncThunk<
  FetchResult<ListProceduresByDepartmentQuery>,
  GetProceduresByDepartmentParams,
  AsyncThunkConfig
>(
  'procedure/department/fetchAll',
  async ({ departmentId, limit, nextToken }, thunkAPI) =>
    thunkAPI.extra.appSyncClient.query({
      errorPolicy: 'none',
      query: LIST_PROCEDURES_BY_DEPARTMENT,
      variables: {
        departmentId,
        limit,
        nextToken
      }
    }),
  {
    serializeError: (x: any) => x
  }
);

export const getProcedure = createAsyncThunk<
  FetchResult<GetProcedureQuery>,
  GetProcedureQueryVariables,
  AsyncThunkConfig
>(
  'procedure/fetch',
  async (variables, thunkAPI) =>
    thunkAPI.extra.appSyncClient.query({
      query: GET_PROCEDURE,
      variables,
      fetchPolicy: 'network-only'
    }),
  {
    serializeError: (x: any) => x
  }
);

export const createProcedure = createAsyncThunk<
  FetchResult<CreateProcedureMutation>,
  CreateProcedureMutationVariables,
  AsyncThunkConfig
>('procedure/create', async (variables, thunkAPI) =>
  retryPromiseWithDelay({
    promiseFn: () =>
      thunkAPI.extra.appSyncClient.mutate({
        mutation: Mutations.CreateProcedure(),
        variables
      }),
    errorPredicate: (e: any) => e?.message === 'Execution timed out.',
    retries: 5,
    delayTime: delayExponentialBackoff
  })
);

export const updateProcedureSchedule = createAsyncThunk<
  FetchResult<UpdateProcedureScheduleMutation>,
  UpdateProcedureScheduleMutationVariables,
  AsyncThunkConfig
>('procedure/updateschedule', async (variables, thunkAPI) =>
  retryPromiseWithDelay({
    promiseFn: () =>
      thunkAPI.extra.appSyncClient.mutate({
        mutation: Mutations.UpdateProcedureSchedule(),
        variables
      }),
    errorPredicate: (e: any) => e?.message === 'Execution timed out.',
    retries: 5,
    delayTime: delayExponentialBackoff
  })
);

const ProcedureAdapter = createEntityAdapter<Procedure>();

interface IState {
  loading: boolean;
  error: any;
  nextToken: string | undefined;
  pageDataMap: Record<number, Procedure[]>;
}

export const procedureSlice = createSlice({
  name: 'procedure',
  initialState: ProcedureAdapter.getInitialState<IState>({
    loading: false,
    error: undefined,
    nextToken: undefined,
    pageDataMap: {}
  }),
  reducers: {
    addProcedure: ProcedureAdapter.setOne
  },
  extraReducers: (builder) => {
    builder.addCase(getProceduresByDepartment.pending, (state) => {
      state.error = null;
      state.loading = true;
    });

    builder.addCase(
      getProceduresByDepartment.fulfilled,
      (state, { payload, meta }) => {
        if (payload?.data) {
          const data: any = payload.data;
          const { items = [], nextToken } =
            data.listProceduresByKnowledge || data.listProceduresByDepartment;

          const procedures = items as Procedure[];

          if (!meta.arg.nextToken) {
            ProcedureAdapter.setAll(state, procedures);
          } else {
            ProcedureAdapter.addMany(state, procedures);
          }

          if (meta.arg.page) {
            state.pageDataMap[meta.arg.page] = procedures;
          }

          state.nextToken = nextToken || undefined;
        }
        state.loading = false;
      }
    );

    builder.addCase(getProceduresByDepartment.rejected, (state, { error }) => {
      state.error = error as any;
      state.loading = false;
    });

    builder.addCase(createProcedure.fulfilled, (state, action) => {
      if (action.payload.data?.createProcedure) {
        ProcedureAdapter.addOne(
          state,
          action.payload.data.createProcedure as Procedure
        );
      }
    });

    builder.addCase(getProcedure.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProcedure.fulfilled, (state, action) => {
      state.loading = false;
      ProcedureAdapter.setOne(
        state,
        action.payload.data?.getProcedure as Procedure
      );
    });
    builder.addCase(getProcedure.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as any;
    });
  }
});

export const procedureSelectors = ProcedureAdapter.getSelectors<RootState>(
  (state) => state.procedure
);
export const proceduresQuerySelector = (state: RootState) => ({
  loading: state.procedure.loading,
  procedures: procedureSelectors.selectAll(state),
  getProcedureById: (id: string) => procedureSelectors.selectById(state, id),
  error: state.procedure.error,
  nextToken: state.procedure.nextToken,
  pageDataMap: state.procedure.pageDataMap
});

export const { addProcedure } = procedureSlice.actions;

export default procedureSlice.reducer;
