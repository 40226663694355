//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// A central place to keep all SDK APIs that the UI uses.
//

import { Auth } from '@aws-amplify/auth';
import axios from 'axios';

import AppUtil from '@utils/AppUtil';
import { appConfig } from '@/AppConfig';

const SDKApis = {
  Auth: {
    async startSignUp(signupParam, callbackPathname) {
      const { email, fName, lName, company, role } = signupParam;

      if (appConfig.backend.kognitos_login_signup_url !== undefined) {
        // new signup flow calling

        const response = await axios.post(
          appConfig.backend.kognitos_login_signup_url,
          {
            username: email,
            firstName: fName,
            lastName: lName,
            company,
            role,
            clientMetadata: {
              origin: window.location.origin,
              pathname: callbackPathname
            }
          }
        );

        if (response.data !== null && response.data.success !== true) {
          throw Error(response.data.message);
        }
      }

      return null;
    },

    async startSignIn(email, _password, callbackPathname) {
      if (appConfig.backend.kognitos_login_url !== undefined) {
        // new login flow calling login endpoint

        const response = await axios.post(
          appConfig.backend.kognitos_login_url,
          {
            username: email,
            clientMetadata: {
              origin: window.location.origin,
              pathname: callbackPathname
            }
          }
        );

        if (response.data !== null && response.data.success !== true) {
          throw Error(response.data.message);
        }

        return null;
      }

      // old login flow using user pool sign-up

      const params = {
        username: email,
        password: AppUtil.getRandomString(30),
        attributes: {
          email
        },
        clientMetadata: {
          origin: window.location.origin,
          pathname: callbackPathname
        }
      };

      try {
        await Auth.signUp(params);
        return null;
      } catch (e) {
        if (e.code === 'UsernameExistsException') {
          return null;
        }
        throw e;
      }
    },

    async finishSignIn(email, code) {
      // sign out any user who's already signed in, otherwise a new login
      // might appear to succeed even if the challenge is wrong
      Auth.signOut();

      // verify the challenge
      const user = await Auth.signIn(email, null);
      await Auth.sendCustomChallengeAnswer(user, code);

      // the above call won't indicate if the challenge is correct or not,
      // due to how we leverage the custom auth flow, so this is needed to
      // return an error immediately if the challenge is wrong
      await Auth.currentAuthenticatedUser();
      return user;
    },

    async signOut() {
      return Auth.signOut();
    },

    async currentAuthenticatedUser() {
      try {
        const res = await Auth.currentAuthenticatedUser();
        return res;
      } catch (e) {
        console.error(e);
        return null;
      }
    },

    async currentSession() {
      try {
        const res = Auth.currentSession();
        return res;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
  }
};

export default SDKApis;
