import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  QuestionCircleFilled,
  WarningFilled
} from '@ant-design/icons';
import AutoPilotIcon from '@/components/icons/AutoPilotIcon';
import { Divider, Tooltip } from 'antd';
import { useAppSelector } from '@/stores/hooks';
import { departmentQuerySelector } from '@/stores/slices/department';
import AppUtil from '@/utils/AppUtil';
import SubProcedureIcon from '@/components/icons/SubProcedureIcon';
import JsonViewer from '@/components/JsonViewer';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { GET_LOG_MANAGER } from '@/graphql/queries/playground';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';
import styles from './Editor.module.less';
import EditorLineResult from '../EditorLineResult/EditorLineResult';
import {
  getCollapsibleBlock,
  isLineHidden,
  isLineinCollapseRegion,
  isRequestExists
} from './editorUtil';
import {
  EditorBlock,
  EditorSourceType,
  ExecutionStatus,
  SentenceExecutionData,
  SubDocument
} from './editorInterface';
import { TabKeys } from '../OutputPanel/OutputPanel';
import SubDocumentPopover from '../SubDocumentPopupover/SubDocumentPopover';
import WidgetManager from '../EditorWidgets/WidgetManager';
import { WIDGET_TYPES } from '../EditorWidgets/decorators';
import { useKogEditorContext } from './EditorProvider';
import EditorLineDropdown from './EditorLineDropdown';
import EditorLineRequestInfo from '../EditorLineRequestInfo/EditorLineRequestInfo';

function EditorLine(props: any) {
  const {
    workerId,
    leaf,
    blocks,
    children,
    activeBlock,
    autoPilotChecked,
    showLineResult = true,
    isDebugMode,
    handleWidgetEdit,
    replacerObj,
    activeDocumentToken
  } = props;

  const [isSubprocedurePopupVisible, setSubprocedurePopupVisible] =
    useState(false);
  const editor = useSlate();
  const { source, collapsedCodeBlocks, editorErrors, actions, widgets } =
    useKogEditorContext();
  const [showDebugData, setShowDebugData] = useState(false);
  const [collapsedBlockLeftPx, setCollapsedBlockLeftPx] = useState(0);

  const textRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isDebugMode) {
      setShowDebugData(false);
    }
  }, [isDebugMode]);

  const client = useApolloClient();
  const logs = client.readQuery({ query: GET_LOG_MANAGER })?.getLogManager
    ?.data;

  const getExecutionStatusStyle = (status: ExecutionStatus | undefined) => {
    switch (status) {
      case ExecutionStatus.SUCCESS:
        return styles.success;
      case ExecutionStatus.ERROR:
        return `${styles.error} editorErrorLine`;
      case ExecutionStatus.RUNNING:
        return styles.running;
      default:
        return '';
    }
  };

  const getEditorLeftLines = (depth: number) => {
    const items: JSX.Element[] = [];
    for (let i = 0; i <= depth; i += 1) {
      items.push(
        <div
          contentEditable={false}
          key={`editorLeftLine-${i}`}
          className={styles.editorRightLine}
          style={{ left: `-${35 * i}px`, userSelect: 'none' }}
        />
      );
    }
    return items;
  };

  const { uiLineId, status } = leaf;
  const lineDepth = leaf.tabCount;
  const lineNumber = blocks.findIndex(
    (b: EditorBlock) => b.children[0].uiLineId === uiLineId
  );

  const lineHasError = editorErrors?.[lineNumber];

  const isWidgetOpen = widgets.data?.lineId === leaf.uiLineId;

  const isMiniPlayground = source === EditorSourceType.MINI_PLAYGROUND;

  const handleLineClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isMiniPlayground && isRequestExists(leaf)) {
      props.setActiveBlock(leaf, TabKeys.Requests);
    }
  };

  useEffect(() => {
    if (!isMiniPlayground && isRequestExists(leaf)) {
      props.setActiveBlock(leaf, TabKeys.Requests);
    }
  }, [source, leaf.requests, leaf.editorRequests]);

  useEffect(() => {
    if (textRef.current) {
      const leafNode = textRef.current.querySelector('[data-slate-leaf]');
      if (leafNode) {
        const leafNodeRect = leafNode.getBoundingClientRect();
        const collapsedBlockLeftPx = leafNodeRect.width + 10;
        setCollapsedBlockLeftPx(collapsedBlockLeftPx);
      }
    }
  }, [textRef.current, leaf?.text]);

  const userWrittenSentence = leaf.originalText || '';

  const { department } = useAppSelector(departmentQuerySelector);
  const isAutoPilotSupported = AppUtil.isAutoPilotSupported(department);

  const isSentenceFixedByAutopilot = !!leaf.originalText;

  const handleProcedureTokenClick = (token: SubDocument) => {
    props.onSubprocedureClick(leaf, token);
  };

  const getExecutionData = (executionData: SentenceExecutionData) => {
    if (executionData?.startedAt && executionData?.completedAt) {
      const diff = moment(executionData.completedAt).diff(
        moment(executionData.startedAt)
      );
      return {
        ...executionData,
        timeToComplete: diff ? `${diff} ms` : undefined
      };
    }

    return executionData;
  };

  const logLink = AppUtil.getWorkerLogLink(
    workerId,
    getExecutionData(logs?.[`${leaf.uiLineId}-execution`])?.startedAt,
    getExecutionData(logs?.[`${leaf.uiLineId}-execution`])?.completedAt
  );

  const handleWidgetUpdate = (
    key: string,
    value: string,
    type: WIDGET_TYPES
  ) => {
    const count = replacerObj.KOG_WIDGET_COUNT[type] + 1;
    handleWidgetEdit({
      [key]: {
        value,
        type
      },
      // @ts-ignore
      KOG_WIDGET_COUNT: {
        ...(replacerObj?.KOG_WIDGET_COUNT as Object),
        [type]: count
      }
    });
  };

  const collapsibleBlock = getCollapsibleBlock(collapsedCodeBlocks, lineNumber);

  const handleCollapse = (e: React.MouseEvent) => {
    e.preventDefault();
    ReactEditor.focus(editor);
    const { selection } = editor;
    const currentSelectedLine = selection?.anchor?.path[0];
    const isInCollapsibleRegion = isLineinCollapseRegion(
      collapsedCodeBlocks,
      currentSelectedLine,
      lineNumber
    );
    const textLengthAtLine = blocks[lineNumber].children[0].text.length;
    if (isInCollapsibleRegion) {
      Transforms.select(editor, {
        anchor: {
          path: [lineNumber, 0],
          offset: textLengthAtLine
        },
        focus: {
          path: [lineNumber, 0],
          offset: textLengthAtLine
        }
      });
    }
    actions.toggleFold(lineNumber);
  };

  const getLineNumberPadding = () => {
    if (collapsibleBlock) {
      return 0;
    }
    return isMiniPlayground ? 10 : 20;
  };

  return (
    <div
      className={classNames(
        styles.editorLine,
        styles[`editorLine-${source}`],
        getExecutionStatusStyle(status),
        activeBlock?.uiLineId === uiLineId ? styles.active : '',
        lineHasError ? styles.lineError : '',
        activeBlock?.uiLineId === uiLineId &&
          source !== EditorSourceType.MINI_PLAYGROUND
          ? styles.active
          : ''
      )}
      style={{
        marginLeft: `${35 * lineDepth + (isMiniPlayground ? 40 : 70)}px`,
        display: isLineHidden(collapsedCodeBlocks, lineNumber)
          ? 'none'
          : 'block'
      }}
      onClick={(e) => handleLineClick(e)}
      key={`editorLine-${uiLineId}`}
    >
      {/* left spaces */}
      {getEditorLeftLines(lineDepth)}

      {/* line number */}
      <div
        className={styles.editorLineNumber}
        contentEditable={false}
        onContextMenu={(e) => {
          if (isDebugMode) {
            e.preventDefault();
            setShowDebugData((prev) => !prev);
          }
        }}
        onClick={handleCollapse}
        style={{
          left: `-${35 * lineDepth + 30}px`,
          userSelect: 'none',
          paddingRight: getLineNumberPadding()
        }}
      >
        {lineNumber + 1}

        {lineHasError && (
          <div className={styles.lineErrorIcon}>
            <Tooltip title={lineHasError} trigger="hover" placement="right">
              <WarningFilled />
            </Tooltip>
          </div>
        )}

        {collapsibleBlock && (
          <div className={styles.foldIcon}>
            {collapsibleBlock?.isCollapsed ? (
              <CaretRightOutlined />
            ) : (
              <CaretDownOutlined />
            )}
          </div>
        )}
      </div>

      {/* content */}
      <div
        className={classNames(
          styles.editorLineDetails,
          'justify-space-between'
          // {
          //   'justify-space-between': !leaf?.requests?.length
          // }
        )}
      >
        {!leaf.answer && leaf.requests?.length > 0 && !isMiniPlayground ? (
          <div
            className={styles.exceptionMarker}
            onClick={(e) => {
              e.stopPropagation();
              props.setActiveBlock(leaf, TabKeys.Requests);
            }}
          >
            <QuestionCircleFilled
              style={{ height: '18px', width: '18px', color: '#E9B825' }}
            />
          </div>
        ) : null}

        {/* middle text */}
        <div
          ref={textRef}
          data-lineid={uiLineId}
          data-linenumber={lineNumber}
          className={classNames(
            styles.editorLineInput,
            { shimmer: status === ExecutionStatus.RUNNING },
            { 'shimmer-finite': isSentenceFixedByAutopilot }
          )}
        >
          {/* {leaf.requests && (
            <div
              className={styles.exceptionMarker}
              onClick={(e) => {
                e.stopPropagation();
                props.setActiveBlock(leaf, TabKeys.Requests);
              }}
            >
              <WarningFilled
                style={{ height: '18px', width: '18px', color: '#cf2a38' }}
              />
            </div>
          )} */}
          {isAutoPilotSupported &&
          isSentenceFixedByAutopilot &&
          autoPilotChecked &&
          !isMiniPlayground ? (
            <div
              contentEditable={false}
              style={{ userSelect: 'none' }}
              className={styles.autoPilotContainer}
            >
              <span className={styles.userWrittenContent}>
                [&nbsp;&nbsp;{userWrittenSentence}
                &nbsp;&nbsp;]
              </span>
            </div>
          ) : null}
          {children}
          {collapsibleBlock?.isCollapsed ? (
            <div
              className={styles.collapsedBlock}
              style={{ left: `${collapsedBlockLeftPx}px` }}
              onClick={handleCollapse}
            />
          ) : null}

          {/* this is where we can show sentence execution data */}
          {showDebugData && (
            <div
              contentEditable={false}
              style={{ userSelect: 'none' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Divider />
              <a href={logLink} target="_blank" rel="noreferrer">
                click here to open logs
              </a>
              <Divider />
              <JsonViewer
                data={logs[`${leaf.uiLineId}-line`]}
                name="workerDocumentLine"
              />
              <Divider />
              <JsonViewer
                data={getExecutionData(logs[`${leaf.uiLineId}-execution`])}
                name="sentenceExecution"
              />
            </div>
          )}

          {isMiniPlayground ? <EditorLineRequestInfo sentence={leaf} /> : null}

          {isWidgetOpen && (
            <div
              contentEditable={false}
              style={{ userSelect: 'none' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {widgets.data && (
                <WidgetManager
                  widgetKey={widgets.data.widgetKey}
                  widgetValue={widgets.data.widgetValue}
                  widgetType={widgets.data.widgetType}
                  onUpdate={handleWidgetUpdate}
                />
              )}
            </div>
          )}
        </div>

        {/* right icons */}
        <div className={styles.editorLineActions} contentEditable={false}>
          {isAutoPilotSupported &&
          isSentenceFixedByAutopilot &&
          !isMiniPlayground ? (
            <Tooltip title="Statement fixed by Auto-pilot" color="#323232">
              <div
                className={classNames([
                  styles.autoPilotSVGContainer,
                  styles.autopilotScaleAnimation
                ])}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <AutoPilotIcon width={15} height={15} fill="#9747FF" />
              </div>
            </Tooltip>
          ) : null}
          {leaf.subDocuments && (
            <>
              <Tooltip title="View subprocess" color="#323232">
                <div
                  className={classNames([
                    styles.autoPilotSVGContainer,
                    styles.autopilotScaleAnimation
                  ])}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSubprocedurePopupVisible(!isSubprocedurePopupVisible);
                  }}
                  data-cy="subprocess-icon"
                >
                  <SubProcedureIcon width={16} height={16} fill="#9747FF" />
                </div>
              </Tooltip>
              <SubDocumentPopover
                documentTokens={
                  leaf.editorSubDocuments
                    ? leaf.editorSubDocuments[leaf.token!]
                    : leaf.subDocuments
                }
                visible={isSubprocedurePopupVisible}
                hidePopover={() => setSubprocedurePopupVisible(false)}
                onProcedureClick={handleProcedureTokenClick}
              />
            </>
          )}
          {showLineResult && (
            <EditorLineResult
              workerId={workerId}
              sentence={leaf}
              onAnswerClick={props.setActiveBlock}
              activeDocumentToken={activeDocumentToken}
              source={source}
            />
          )}
          {workerId && (
            <EditorLineDropdown
              leaf={leaf}
              activeDocumentToken={activeDocumentToken}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function RenderElement(props: any) {
  const {
    workerId,
    attributes,
    children,
    element,
    blocks,
    activeBlock,
    autoPilotChecked,
    setActiveBlock,
    showLineResult,
    onSubprocedureClick,
    isDebugMode,
    handleWidgetEdit,
    replacerObj,
    activeDocumentToken
  } = props;

  switch (element?.type) {
    case 'EditorLine':
    default:
      return (
        <div {...attributes} id={element.children[0].uiLineId}>
          <EditorLine
            workerId={workerId}
            leaf={element.children[0]}
            blocks={blocks}
            activeBlock={activeBlock}
            autoPilotChecked={autoPilotChecked}
            setActiveBlock={setActiveBlock}
            showLineResult={showLineResult}
            onSubprocedureClick={onSubprocedureClick}
            isDebugMode={isDebugMode}
            handleWidgetEdit={handleWidgetEdit}
            replacerObj={replacerObj}
            activeDocumentToken={activeDocumentToken}
          >
            {children}
          </EditorLine>
        </div>
      );
  }
}

export default RenderElement;
