import { GET_LOCAL_DOCUMENT_PREVIEW } from '@/graphql/queries/playground';
import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, CloseCircleFilled } from '@ant-design/icons';
import { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import S3DocumentViewer from '@/components/S3DocumentViewer';
import AppConstants from '@/utils/AppConstants';
import CSVTable from '@/components/CSVTable';
import AnswerConceptsTable from '@/components/playground2/AnswerConceptsTable';
import { Button, message } from 'antd';
import DownloadIcon from '@/components/icons/DownloadIcon';
import { downloadFile } from '@/utils/fileUtil';
import exportFromJSON from 'export-from-json';
import downloadCsv from 'download-csv';
import AnswerConceptsTableV2 from '@/components/playground2/AnswerConceptsTableV2';
import styles from './EditorPreview.module.less';

interface IEditorPreview {
  onClose: () => void;
  width: string;
  outputPanelWidth: number;
  name?: string | null;
}

function EditorPreview(props: IEditorPreview) {
  const { onClose, width, name, outputPanelWidth } = props;
  const client = useApolloClient();
  const [filePreview, setFilePreview] = useState<any>();

  const fetchData = () => {
    const data = client.readQuery({ query: GET_LOCAL_DOCUMENT_PREVIEW });
    setFilePreview(data);
  };

  useEffect(() => {
    document.addEventListener(
      AppConstants.FACTS.UPDATE_EXPANDED_DOCUMENT,
      fetchData
    );

    return () =>
      document.removeEventListener(
        AppConstants.FACTS.UPDATE_EXPANDED_DOCUMENT,
        fetchData
      );
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const renderComp = (fileData: any) => {
    const {
      getLocalDocumentPreview: { data }
    } = fileData;
    if (data.type === FormattedAnswerTypeV2.S3) {
      const { title, s3Object, pdfConfig } = data;
      return (
        <S3DocumentViewer
          key={`${title}:${s3Object.key}`}
          title={title}
          s3Object={s3Object}
          pdfConfig={{
            ...pdfConfig,
            allowViewTypeChange: false
          }}
          hideHeader
          width="100%"
          showLoader
        />
      );
    }
    if (data.type === FormattedAnswerTypeV2.TABLE) {
      return (
        <CSVTable
          csvData={data.csvData}
          hideHeader
          tableNumber={data.tableNumber}
        />
      );
    }
    if (data.type === FormattedAnswerTypeV2.TABLES_ROW) {
      if (data.newFactsModelData) {
        return (
          <AnswerConceptsTableV2
            newFactsModelData={data.newFactsModelData as any[]}
            hideHeader
            epoch={data.epoch}
          />
        );
      }
      return (
        <AnswerConceptsTable
          knowledgeId={data.knowledgeId!}
          answer={data.answer}
          tableRowCpt={data.tableRowCpt}
          hideHeader
          epoch={data.epoch}
        />
      );
    }
    return null;
  };

  const handleClose = () => {
    onClose?.();
    client.writeQuery({
      query: GET_LOCAL_DOCUMENT_PREVIEW,
      data: {
        getLocalDocumentPreview: { data: {} }
      }
    });
  };

  const renderTitle = () => {
    const { data } = filePreview.getLocalDocumentPreview;
    const { type, title } = data;
    let headerTitle = '';
    if (type === FormattedAnswerTypeV2.S3) {
      headerTitle = title;
    }

    if (
      type === FormattedAnswerTypeV2.TABLE ||
      type === FormattedAnswerTypeV2.TABLES_ROW
    ) {
      headerTitle = 'Table';
    }

    return (
      <span className={styles.titleContainer} data-cy={title || headerTitle}>
        <span className={styles.title}>
          <ArrowLeftOutlined
            onClick={handleClose}
            className={styles.backArrow}
          />
          {name ? `${name} / ${headerTitle}` : headerTitle}
          {(data.s3Object?.downloadUrl ||
            data?.downloadJsonData ||
            data?.downloadCSVData) && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                if (data.s3Object?.downloadUrl) {
                  downloadFile(
                    data.title,
                    data.s3Object.downloadUrl,
                    () => message.success('File download successfull'),
                    () => message.error('Could not download file')
                  );
                  return;
                }
                if (data.downloadJsonData) {
                  exportFromJSON(data.downloadJsonData);
                  return;
                }
                if (data?.downloadCSVData) {
                  downloadCsv(...data.downloadCSVData);
                }
              }}
            >
              <DownloadIcon
                style={{ color: '#8C8C8C' }}
                className={styles.downloadButton}
              />
            </span>
          )}
        </span>
        <Button
          data-cy="view-run"
          className={styles.viewRunButton}
          onClick={handleClose}
        >
          View Run
        </Button>
      </span>
    );
  };

  return filePreview ? (
    <div
      data-cy="editor-preview"
      className={styles.previewContainer}
      style={{ top: 0, left: 0, right: `${outputPanelWidth}px` }}
    >
      {renderTitle()}
      <div
        className={styles.documentContainer}
        style={{
          width
        }}
      >
        <span className={styles.documentViewer}>
          {renderComp(filePreview)}
          <CloseCircleFilled
            className={styles.closeIcon}
            onClick={handleClose}
          />
        </span>
      </div>
    </div>
  ) : (
    <div />
  );
}

export default EditorPreview;
