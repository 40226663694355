// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IntegrationForm-module__integrationForm__Rbxhn {
  background-color: #fff;
}
.IntegrationForm-module__integrationFormContainer__ecr27 {
  max-height: calc(35vh - 80px);
  overflow: auto;
}
.IntegrationForm-module__description__FYugK {
  margin: 8px 0px 0px;
  font-size: 11px;
  color: #8c8c8c;
}
.IntegrationForm-module__submitContainer__farlz {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.IntegrationForm-module__cancelBtn__YahX8 {
  margin-right: 20px;
}
.IntegrationForm-module__formField__mEGxX {
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.IntegrationForm-module__formField__mEGxX .ant-form-item-label label {
  font-size: 16px;
}
.IntegrationForm-module__formField__mEGxX .ant-form-item-explain-connected {
  font-size: 11px;
  font-style: italic;
}
.IntegrationForm-module__formField__mEGxX .ant-form-item-margin-offset {
  margin-bottom: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/IntegrationForm/IntegrationForm.module.less"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;AACA;EACE,6BAAA;EACA,cAAA;AACF;AAEA;EACE,mBAAA;EACA,eAAA;EACA,cAAA;AAAF;AAGA;EACE,gBAAA;EACA,aAAA;EACA,yBAAA;AADF;AAIA;EACE,kBAAA;AAFF;AAIA;EACE,oBAAA;EAEA,mBAAA;AAHF;AAAA;EAOM,eAAA;AAJN;AAHA;EAWI,eAAA;EACA,kBAAA;AALJ;AAPA;EAeI,6BAAA;AALJ","sourcesContent":[".integrationForm {\n  background-color: #fff;\n}\n.integrationFormContainer {\n  max-height: calc(35vh - 80px);\n  overflow: auto;\n}\n\n.description {\n  margin: 8px 0px 0px;\n  font-size: 11px;\n  color: #8c8c8c;\n}\n\n.submitContainer {\n  margin-top: 16px;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.cancelBtn {\n  margin-right: 20px;\n}\n.formField {\n  padding-bottom: 12px;\n  // border-bottom: 1px solid #ccc;\n  margin-bottom: 12px;\n\n  :global(.ant-form-item-label) {\n    label {\n      font-size: 16px;\n    }\n  }\n  :global(.ant-form-item-explain-connected) {\n    font-size: 11px;\n    font-style: italic;\n  }\n  :global(.ant-form-item-margin-offset) {\n    margin-bottom: 0px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"integrationForm": `IntegrationForm-module__integrationForm__Rbxhn`,
	"integrationFormContainer": `IntegrationForm-module__integrationFormContainer__ecr27`,
	"description": `IntegrationForm-module__description__FYugK`,
	"submitContainer": `IntegrationForm-module__submitContainer__farlz`,
	"cancelBtn": `IntegrationForm-module__cancelBtn__YahX8`,
	"formField": `IntegrationForm-module__formField__mEGxX`
};
export default ___CSS_LOADER_EXPORT___;
