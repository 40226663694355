import { gql } from '@apollo/client';

export const listProcedureRequestsQuery = /* GraphQL */ `
  query ListRequestsByProcedure(
    $procedureId: ID!
    $departmentId: ID!
    $limit: Int = 100
    $nextToken: String
    $state: RequestState
  ) {
    listRequestsByProcedure(
      procedureId: $procedureId
      departmentId: $departmentId
      limit: $limit
      nextToken: $nextToken
      state: $state
    ) {
      items {
        id
        stepPath
        contextId
        sentenceId
        createdAt
        handledAt
        state
        worker {
          id
          knowledgeId
        }
        uiConcepts
        questions {
          id
          text
          contextId
          sentenceId
          answerTypeHint
          ... on NativeCodeErrorQuestion {
            errorType
            errorMessage
            errorTraceback
          }
          ... on ReviewConceptQuestion {
            lexicalPath
            conceptId
          }
          ... on ValueNotKnownQuestion {
            lexicalPath
            choices
            helpText
            isPlural
          }
          ... on DomainNotIntegratedQuestion {
            domain
          }
          ... on MissingIntegrationCredentialQuestion {
            domain
          }
        }
        answers {
          id
          requestId
          questionId
          action {
            _
            ... on RetryStepAction {
              minimumDelay
              timeout
            }
            ... on UseConceptAction {
              conceptId
            }
            ... on UseLiteralValueAction {
              literalValue
            }
            ... on UseTechniqueAction {
              technique
            }
          }
          createdAt
          createdBy
        }
        contextPath {
          ctxId
          sentenceId
        }
      }
      limit
      nextToken
    }
  }
`;

export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      stepPath
      contextId
      sentenceId
      createdAt
      handledAt
      state
      procedureId
      worker {
        id
        knowledgeId
      }
      uiConcepts
      questions {
        id
        text
        contextId
        sentenceId
        answerTypeHint
        ... on NativeCodeErrorQuestion {
          errorType
          errorMessage
          errorTraceback
        }
        ... on ReviewConceptQuestion {
          lexicalPath
          conceptId
        }
        ... on ValueNotKnownQuestion {
          lexicalPath
          choices
          helpText
          isPlural
        }
        ... on DomainNotIntegratedQuestion {
          domain
        }
        ... on MissingIntegrationCredentialQuestion {
          domain
        }
      }
      answers {
        id
        requestId
        questionId
        action {
          _
          ... on RetryStepAction {
            minimumDelay
            timeout
          }
          ... on UseConceptAction {
            conceptId
          }
          ... on UseLiteralValueAction {
            literalValue
          }
          ... on UseTechniqueAction {
            technique
          }
        }
        createdAt
        createdBy
      }
      contextPath {
        ctxId
        sentenceId
      }
    }
  }
`;

export const CREATE_BULK_REQUEST_ANSWER = gql(`
  mutation CreateBulkRequestAnswer($inputs: [CreateRequestAnswerInput!]!) {
    createBulkRequestAnswer(inputs: $inputs) {
      id
      requestId
      questionId
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      createdAt
      createdBy
    }
  }
`);

export const GET_REQUEST_ANSWER = gql`
  query GetRequestAnswer($id: ID!) {
    getRequestAnswer(id: $id) {
      id
      requestId
      questionId
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseOauth2Action {
          accessToken
          refreshToken
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      sourceLearning {
        id
      }
      derivedLearning {
        id
      }
      createdAt
      createdBy
      summary
    }
  }
`;

export const CREATE_REQUEST_ANSWER = gql`
  mutation CreateRequestAnswer($input: CreateRequestAnswerInput!) {
    createRequestAnswer(input: $input) {
      id
      requestId
      questionId
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseOauth2Action {
          accessToken
          refreshToken
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      sourceLearning {
        id
        createdAt
        createdBy
        deletedAt
        deletedBy
        procedureId
        procedureName
        contextValue
        contextMetadata
      }
      createdAt
      createdBy
      summary
    }
  }
`;
