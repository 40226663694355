import React, { useEffect, useState } from 'react';
import FullMapPopup from '@/popups/FullMapPopup/FullMapPopup';
import dayjs from 'dayjs';
import AppUtil from '@/utils/AppUtil';
import { useS3 } from '@/hooks/useS3';
import MapView from '../MapView/MapView';
import styles from './MapPreview.module.less';
import ExpandIcon from '../icons/ExpandIcon';
import MapIcon from '../icons/MapIcon';
import { decodeText } from '../S3DocumentViewer';

interface DateRange {
  startDate: any | null;
  endDate: any | null;
}

interface MapPreviewProps {
  data: Record<string, any> | string;
}

/**
 *
 * This componnet is specially designed for IOHK, should be used only for IOHK
 * If any other place we need to render the map, should create a more generic component
 */
const MapPreview = ({ data }: MapPreviewProps) => {
  const s3 = useS3();
  const [cFilter, setCFilter] = useState<string[]>([]);
  const [labelMap, setLabelMap] = useState<Record<string, string>>({});
  const [mapData, setMapData] = useState<any>(null);
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null
  });

  const [fileData, setFileData] = useState<any>(null);

  const hanldeLabelMap = (dataObj: any) => {
    setCFilter(
      Object.keys(dataObj).map((key: string) => {
        if (key === 'intersect') {
          return 'All';
        }
        return key;
      })
    );
    const lMap: any = {};
    const labelCounts: any = {};

    Object.keys(dataObj).forEach((key) => {
      let label = key;

      // Special case for 'intersect' key
      if (label === 'intersect') {
        label = 'All';
      }

      // Transform the label into an abbreviation
      let transformedLabel = label
        .split(' ')
        .map((word) => word[0])
        .join('')
        .toLocaleUpperCase();

      // If the abbreviation has been used, append a count to it
      if (labelCounts[transformedLabel]) {
        labelCounts[transformedLabel] += 1; // Increment the count for this abbreviation
        transformedLabel += labelCounts[transformedLabel]; // Append the count to make the label unique
      } else {
        labelCounts[transformedLabel] = 1; // Initialize the count for this abbreviation
      }
      lMap[label] = transformedLabel;
    });

    setLabelMap(lMap);
  };

  const getS3Obj = async (s3Object: {
    bucket?: string;
    key: any;
    filename?: string;
  }) => {
    const updatedPresignedUrl = await s3.getS3PresignedUrl(s3Object.key);
    if (updatedPresignedUrl) {
      const urlObject = await s3.getSignedUrlObject(updatedPresignedUrl.url);
      if (urlObject) {
        decodeText(urlObject).then((r) => {
          const jsonObj = JSON.parse(r);
          setFileData(jsonObj);
          hanldeLabelMap(jsonObj);
        });
      }
    }
  };

  useEffect(() => {
    if (data) {
      if (typeof data === 'string' && data?.startsWith('File(')) {
        const file = data.split('File(')[1];
        const fileUrl = file.split(')')[0];
        const s3Data = AppUtil.getS3URIParts(fileUrl)!;
        getS3Obj(s3Data);
      } else if (typeof data === 'object' && data.file?.location) {
        const fileUrl = data.file.location;
        const s3Data = AppUtil.getS3URIParts(fileUrl)!;
        getS3Obj(s3Data);
      } else {
        hanldeLabelMap(data);
        setFileData(data);
      }
    }
  }, [data]);

  useEffect(() => {
    // Combine the featurecollection from all the keys in data
    // into one featurecollection, and add the label as a property
    // of each feature
    // update the code such that if the label is not present in the cFilter, then don't add it in the feature list
    // and if the label is present in the cFilter, then add it in the feature list

    if (fileData === null) return;

    let startDate = dayjs().add(100, 'year'); // Initialize with a far future date
    let endDate = dayjs().subtract(100, 'year'); // Initialize with a far past date

    const features = Object.keys(fileData).map((key: string) => {
      const results: any = [];
      const tempKey = key === 'intersect' ? 'All' : key;
      if (fileData[key]?.features?.length > 0) {
        const currentStartDate = dayjs(
          fileData[key].features[0].properties.ReceivedTime
        );
        const currentEndDate = dayjs(
          fileData[key].features[fileData[key].features.length - 1].properties
            .ReceivedTime
        );

        // Update startDate if currentStartDate is earlier
        if (currentStartDate.isBefore(startDate)) {
          startDate = currentStartDate;
        }

        // Update endDate if currentEndDate is later
        if (currentEndDate.isAfter(endDate)) {
          endDate = currentEndDate;
        }
      }

      if (cFilter.includes(tempKey)) {
        const temp = fileData[key]?.features?.map((f: any) => {
          return {
            type: 'Feature',
            properties: {
              ...f.properties,
              Label: tempKey
            },
            geometry: {
              ...f.geometry,
              coordinates: [
                f.geometry.coordinates[1],
                f.geometry.coordinates[0]
              ]
            }
          };
        });
        if (Array.isArray(temp)) {
          results.push(...temp);
        }
      }
      return results;
    });

    const geoJson = {
      type: 'FeatureCollection',
      features: features.flat()
    };

    setDateRange({
      startDate,
      endDate
    });

    setMapData(geoJson);
  }, [cFilter, fileData]);

  const handleFilterChange = (e: any) => {
    const item = e.target.value;
    if (cFilter.includes(item)) {
      setCFilter(cFilter.filter((i) => i !== item));
    } else {
      setCFilter([...cFilter, item]);
    }
  };

  return (
    <>
      <div className={styles.filePreviewContainer} data-cy="file-preview">
        <div className={styles.expandContainer}>
          <span className={styles.s3FactTableContainer}>
            <MapIcon style={{ width: 24, height: 24, marginRight: 8 }} />
            <span className={styles.filePreviewHeader}>Map</span>
          </span>
          <span className={styles.previewIcons}>
            <span
              onClick={() => setPopupVisible(true)}
              data-cy="expand-document"
            >
              <ExpandIcon style={{ width: 20, height: 20 }} />
            </span>
          </span>
        </div>

        <div className={styles.mapContainer}>
          {mapData && (
            <MapView
              data={mapData}
              lagendProps={{ dateRange }}
              filteredList={cFilter}
              labelMap={labelMap}
              onFilterChange={handleFilterChange}
            />
          )}
        </div>
      </div>
      <FullMapPopup
        open={popupVisible}
        data={mapData}
        lagendProps={{ dateRange }}
        filteredList={cFilter}
        labelMap={labelMap}
        onFilterChange={handleFilterChange}
        onClose={() => {
          setPopupVisible(false);
        }}
      />
    </>
  );
};

export default React.memo(MapPreview);
