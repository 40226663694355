import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction
} from '@reduxjs/toolkit';
import { FetchResult } from '@apollo/client';
import {
  CreateDepartmentMutation,
  CreateDepartmentMutationVariables,
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
  Department,
  GetDepartmentQuery,
  GetDepartmentQueryVariables,
  ListDepartmentsQuery,
  ListDepartmentsQueryVariables
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Mutations from '@/graphql/Mutations';
import Queries from '../../graphql/Queries';
import {
  removeSelectedDepartmentId,
  setSelectedDepartmentId
} from '../../utils/department';
import {
  delayExponentialBackoff,
  retryPromiseWithDelay
} from '../../utils/promise';
import { shouldRetryQueryOnCommonErrors } from '../../utils/query';

export const getDepartments = createAsyncThunk<
  FetchResult<ListDepartmentsQuery>,
  ListDepartmentsQueryVariables,
  AsyncThunkConfig
>('department/fetchAll', async ({ limit, nextToken }, thunkAPI) =>
  retryPromiseWithDelay({
    promiseFn: () =>
      thunkAPI.extra.appSyncClient.query({
        query: Queries.ListUserDepartments(),
        variables: {
          limit,
          nextToken
        }
      }),
    errorPredicate: shouldRetryQueryOnCommonErrors,
    retries: 5,
    delayTime: delayExponentialBackoff
  })
);

export const getDepartment = createAsyncThunk<
  FetchResult<GetDepartmentQuery>,
  GetDepartmentQueryVariables,
  AsyncThunkConfig
>('department/fetch', async ({ id }, thunkAPI) =>
  retryPromiseWithDelay({
    promiseFn: () =>
      thunkAPI.extra.appSyncClient.query({
        query: Queries.Department(),
        variables: {
          id
        }
      }),
    errorPredicate: shouldRetryQueryOnCommonErrors,
    retries: 5,
    delayTime: delayExponentialBackoff
  })
);

export const createDepartment = createAsyncThunk<
  FetchResult<CreateDepartmentMutation>,
  CreateDepartmentMutationVariables,
  AsyncThunkConfig
>('department/create', async (variables, thunkAPI) =>
  retryPromiseWithDelay({
    promiseFn: () =>
      thunkAPI.extra.appSyncClient.mutate({
        mutation: Mutations.CreateDepartment(),
        variables
      }),
    errorPredicate: (e: any) =>
      (e?.message || '').toLowerCase().includes('organisation not found') ||
      shouldRetryQueryOnCommonErrors(e),
    retries: 5,
    delayTime: delayExponentialBackoff
  })
);

export const createOrganization = createAsyncThunk<
  FetchResult<CreateOrganizationMutation>,
  CreateOrganizationMutationVariables,
  AsyncThunkConfig
>('department/create', async (variables, thunkAPI) =>
  retryPromiseWithDelay({
    promiseFn: () =>
      thunkAPI.extra.appSyncClient.mutate({
        mutation: Mutations.CreateOrganization(),
        variables
      }),
    errorPredicate: shouldRetryQueryOnCommonErrors,
    retries: 5,
    delayTime: delayExponentialBackoff
  })
);

const departmentsAdapter = createEntityAdapter<Department>();

interface IState {
  loading: boolean;
  selected: Department | null;
  error: any;
}

const initialState: IState = {
  loading: false,
  selected: null,
  error: undefined
};

export const departmentSlice = createSlice({
  name: 'department',
  initialState: departmentsAdapter.getInitialState(initialState),
  reducers: {
    updateDepartment: departmentsAdapter.setOne,
    addDepartment: departmentsAdapter.addOne,
    selectDepartment: (state, action: PayloadAction<Department | null>) => {
      state.selected = action.payload;
      if (action.payload === null) {
        removeSelectedDepartmentId();
      } else {
        setSelectedDepartmentId(action.payload.id);
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDepartments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDepartments.fulfilled, (state, { payload }) => {
      departmentsAdapter.setAll(
        state,
        payload?.data?.listDepartments?.items as Department[]
      );
      state.loading = false;
    });
    builder.addCase(createDepartment.fulfilled, (state, { payload }) => {
      if (payload?.data?.createDepartment) {
        departmentsAdapter.addOne(
          state,
          payload?.data?.createDepartment as Department
        );
      }
    });
    builder.addCase(getDepartments.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.loading = false;
    });
    builder.addCase(getDepartment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDepartment.fulfilled, (state, { payload }) => {
      if (payload?.data?.getDepartment) {
        departmentsAdapter.upsertOne(
          state,
          payload?.data?.getDepartment as Department
        );
      }
      state.loading = false;
    });
  }
});

export const { selectDepartment, updateDepartment, addDepartment } =
  departmentSlice.actions;

export const departmentsSelectors = departmentsAdapter.getSelectors<RootState>(
  (state) => state.department
);

export const departmentQuerySelector = (state: RootState) => {
  return {
    loading: state.department.loading,
    department: state.department.selected!,
    departments: departmentsSelectors.selectAll(state),
    error: state.department.error
  };
};

export default departmentSlice.reducer;
