import { gql } from '@apollo/client';

export const GET_REQUEST_QUESTION = gql(`
  query GetRequestQuestion($id: ID!) {
    getRequestQuestion(id: $id) {
      id
      text
      contextId
      sentenceId
      answerTypeHint
      failedAnswerId
      failedAnswerReason
      summary
      request {
        id
        createdAt
        state
        procedureId
        procedureName
        assignee
      }
      ... on NativeCodeErrorQuestion {
        errorType
        errorMessage
        errorTraceback
      }
      ... on ParseErrorQuestion {
        errorTraceback
      }
      ... on ReviewConceptQuestion {
        lexicalPath
        conceptId
      }
      ... on ValueNotKnownQuestion {
        lexicalPath
        choices
        helpText
        isPlural
      }
    }
  }
`);

export const LIST_QUESTIONS_BY_DEPARTMENT = gql(`
  query ListQuestionsByDepartment($input: ListQuestionsByDepartmentInput!) {
    listQuestionsByDepartment(input: $input) {
      items {
        id
        text
        contextId
        sentenceId
        answerTypeHint
        failedAnswerId
        failedAnswerReason
        summary
        request {
          id
          createdAt
          state
          procedureId
          procedureName
          assignee
          worker {
            id
          }
        }
        ... on NativeCodeErrorQuestion {
          errorType
          errorMessage
          errorTraceback
        }
        ... on ParseErrorQuestion {
          errorTraceback
        }
        ... on ReviewConceptQuestion {
          lexicalPath
          conceptId
        }
        ... on ValueNotKnownQuestion {
          lexicalPath
          choices
          helpText
          isPlural
        }
      }
      total
      valueCounts {
        states {
          value
          count
        }
        procedures {
          value
          count
        }
      }
    }
  }
`);

export const GET_EXCEPTIONS_STATUS_COUNTS = gql(`
  query getExceptionsStatusCounts($input: ListQuestionsByDepartmentInput!) {
    listQuestionsByDepartment(input: $input) {
      valueCounts {
        states {
          value
          count
        }
      }
    }
  }
`);

export const GET_ACTIVE_EXCEPTION_REQUEST = gql(`
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      stepPath
      contextId
      sentenceId
      createdAt
      handledAt
      state
      procedureId
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        attachments
        isArchived
        features
      }
      uiConcepts
      questions {
        id
        text
        summary
        contextId
        sentenceId
        answerTypeHint
        ... on NativeCodeErrorQuestion {
          errorType
          errorMessage
          errorTraceback
        }
        ... on ReviewConceptQuestion {
          lexicalPath
          conceptId
        }
        ... on ValueNotKnownQuestion {
          lexicalPath
          choices
          helpText
          isPlural
        }
      }
      answers {
        id
        requestId
        questionId
      }
      suggestedAnswers {
        id
        requestId
        questionId
        action {
          ... on ReplaceStepAction {
            _
            newSteps
          }
        }
      }
      contextPath {
        ctxId
        sentenceId
      }
    }
  }
`);

export const ASSIGN_REQUESTS = gql(`
  mutation AssignRequests($input: AssignRequestsInput!) {
    assignRequests(input: $input) {
      assignments {
        requestId
        assignment
        assignmentPolicyType
      }
    }
  }
`);

export const GET_DOCUMENT_BLOCKS = gql`
  query GetDocumentBlocks(
    $knowledgeId: ID!
    $documentFactId: ID!
    $boundingBoxes: GetDocumentBlocksInput
  ) {
    getDocumentBlocks(
      knowledgeId: $knowledgeId
      documentFactId: $documentFactId
      boundingBoxes: $boundingBoxes
    ) {
      blocksByPage {
        pageNum
      }
      rawText
    }
  }
`;
