//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Application wide code constants
//
import Queries from '@graphql/Queries';
import Mutations from '@graphql/Mutations';
import { AccessControlRole } from '../generated/API';

// Grid IDs (local - for reuse in AppConstants)
const gridIdsHash = {
  FLOWS_GRID: 'flows-grid',
  LEARNINGS_GRID: 'flow-learnings-grid',
  RUNS_GRID: 'flow-runs-grid',
  EXCEPTIONS_GRID: 'flow-exceptions-grid',
  BOOKS_GRID: 'books-grid'
};

const AppConstants = {
  // API Request States
  STATE_INIT: 0,
  STATE_IN_PROGRESS: 1,
  STATE_ERROR: 2,
  STATE_DONE: 3,

  // Date/time constants
  MILLIS_PER_SECOND: 1000,
  MILLIS_PER_MINUTE: 60 * 1000,
  MILLIS_PER_HOUR: 60 * 60 * 1000,
  MILLIS_PER_DAY: 24 * 60 * 60 * 1000,
  MILLIS_PER_WEEK: 7 * 24 * 60 * 60 * 1000,
  MILLIS_PER_MONTH: 30 * 24 * 60 * 60 * 1000,

  // UI indicator to show data not available
  NOT_AVAILABLE: '-',

  // Entity types this app deals with
  ENTITY_TYPES: {
    PROCEDURE: 'procedure',
    RUN: 'run',
    WORKER: 'worker'
  },

  // These are constants used by the code to refer to the
  // APIs that the UI needs. We have mock implementations in SDKApis whose
  // signature match the actual ones. When ready, we can point to the production ones
  APIS: {
    // The Details Pane uses this.
    // Get Query by entityType
    GET_ENTITY: {
      department: Queries.Department,
      fact: Queries.Fact,
      facts: Queries.Facts,
      worker: Queries.Worker,
      procedure: Queries.Procedure,
      command: Queries.Command
    },

    BOOKS: {
      LEARN: Mutations.LearnBooks
    },

    COMMANDS: {
      CREATE: Mutations.CreateCommand,
      LIST_BY_WORKER: Queries.CommandsByWorker
    },

    SERVICE_COMMANDS: {
      CREATE: Mutations.CreateServiceCommand,
      STOP_SERVICE_COMMAND_POLLING_DURATION: 90 * 1000
    },

    PROCEDURES: {
      CREATE: Mutations.CreateProcedure,
      SHARE: Mutations.ShareProcedure
    },

    DEPARTMENTS: {
      LIST: Queries.Departments,
      HISTORY: Queries.GetDepartmentHistory,
      ROLLBACK_VERSION: Mutations.RollbackDepartmentVersion
    },

    LEARNINGS: {
      DELETE: Mutations.DeleteLearning
    },

    // TODO: remove this and referred entries
    RUN_ITEMS: {
      CREATE: Mutations.AddRunItem
    },

    WORKERS: {
      LIST_BY_PROCEDURE: Queries.ListWorkersByProcedure,
      BASIC_LIST_BY_PROCEDURE: Queries.ListBasicWorkersByProcedure,
      STEP_CONTEXTS: Queries.ContextsForStep,
      CREATE: Mutations.CreateWorker,
      CREATE_REALTIME_CHANNEL: Mutations.CreateRealtimeChannel
    },

    ADDITIONAL_COLUMNS: {
      FETCH: Queries.AdditionalColumns,
      REGISTER: Mutations.RegisterAdditionalColumn,
      DEREGISTER: Mutations.DeregisterAdditionalColumn
    },

    PARSE: Queries.ParseAlangText,

    S3: {
      UPLOAD_LINK: Queries.S3UploadLink,
      DOWNLOAD_LINK: Queries.S3DownloadLink
    }
  },

  // Unique IDs for Grids
  GRID_IDS: gridIdsHash,

  // Prefix of an additional column's dataIndex.
  // The format is 'additionalColumn.<columnId>'
  GRID_ADDITIONAL_COLUMN_PREFIX: 'additionalColumn',

  // Page sizes for grids
  GRID_PAGE_SIZES: [10, 20, 30, 40, 60],

  // Types of users
  //----------
  USER_TYPES: {
    UNKNOWN: '-', // Not logged in
    END_USER: 'eu', // Consumer
    SME: 'sme' // Author
  },

  FACTS: {
    UPDATE_EXPANDED_DOCUMENT: 'UPDATE_EXPANDED_DOCUMENT',
    REQUEST_DOCUMENT_EXPAND_FROM_EXCEPTION_CENTER:
      'REQUEST_DOCUMENT_EXPAND_FROM_EXCEPTION_CENTER'
  },

  // The unknown user
  UNKNOWN_USER: {
    id: '-1',
    email: '?',
    firstName: '?',
    lastName: '?',
    active: false,
    type: '-'
  },

  // Top Level Page Ids
  PAGES: {
    LOGIN: 'login',
    FINISH_LOGIN: 'finish_login',
    SEARCH: 'search',

    // Per user pages have the format userType_page
    SME_HOME: 'sme_home',
    SME_PROCEDURES: 'sme_procedures',
    SME_KNOWLEDGE: 'sme_knowledge',
    SME_LEARNINGS: 'sme_learnings',

    EU_HOME: 'eu_home',
    EU_PROCEDURES: 'eu_procedures',
    Playground: 'playground'
  },

  // Sub Pages within a page.
  SUB_PAGES: {
    SUMMARY: 'summary'
  },

  POPUPS: {
    ANSWER_CSV: 'answerCSV',
    ANSWER_CONCEPTS_TABLE: 'answerConceptsTable',
    NEW_FLOW: 'newFlow',
    NEW_RUN: 'newRun',
    CREATE_ORGANIZATION: 'createOrganization',
    CREATE_DEPARTMENT: 'createDepartment',
    DEPARTMENT_COLLABORATORS: 'departmentCollaborators',
    DEPARTMENT_COLLABORATORS_ADD: 'departmentCollaboratorsAdd',
    REQUEST_EXCEPTIONS_BATCH_ANSWER: 'requestExceptionsBatchAnswer',
    VIEW_REQUEST: 'viewRequest',
    VIEW_OBJECT: 'viewObject',
    VIEW_RAW_FACT: 'viewRawFact',
    VIEW_S3_FILE: 'viewS3File',
    MARKDOWN_VIEWER: 'markdownViewer',
    MARKDOWN_EDITOR: 'markdownEditor',
    HTML_VIEWER: 'htmlViewer',
    EDIT_FACT: 'editFact',
    SCRATCH_STEP: 'scratchStep',
    DOCUMENT_EXCEPTION_EDITOR: 'documentExceptionEditor',
    CREDENTIAL_INTEGRATION: 'credentialIntegration',
    PROCEDURE_EXCEPTION_ROUTING: 'procedureExceptionRouting',
    FULL_MAP: 'fullMap',
    MINI_PLAYGROUND: 'miniPlayground'
  },

  DRAWERS: {
    ANSWER_FACTS: 'answerFacts',
    RUN_FACTS: 'runFacts'
  },

  INPUT_TYPES: {
    TEXT: 'text',
    STRING: 'string', // Same as text
    TEXT_AREA: 'textarea',
    PASSWORD: 'password',
    DATE: 'date',
    TIME: 'time',
    NUMBER: 'number',
    SINGLE_SELECT: 'singleSelect',
    MULTIPLE_SELECT: 'multipleSelect',
    BOOLEAN_CHECKBOX: 'booleanCheckbox',
    FILE_UPLOAD: 'fileUpload'
  },

  FILTER_OPERATORS: {
    EQUALS: '==',
    NOT_EQUALS: '!=',
    GREATER_THAN: '>',
    LESS_THEN: '<'
  },

  // Icons (Keep 'em sorted please)
  ICONS: {
    AUDIO: 'audio',
    BRAIN_LOGO: 'brainlogo',
    CANCEL: 'cancel',
    CLOSE: 'cs',
    DELETE: 'del',
    BAR_CHART: 'bc',
    BOOK: 'bk',
    DOWN: 'down',
    DOWN_ARROW: 'da',
    DOWN_CARET: 'dc',
    EDIT: 'ed',
    EMAIL: 'em',
    ENTER: 'en',
    FACT: 'fct',
    FORWARD: 'fw',
    FILTER: 'fl',
    HEART: 'ht',
    HISTORY: 'hs',
    IMAGE: 'img',
    LEARNING: 'ln',
    LEFT_ARROW: 'lo',
    LEFT_CARET: 'lc',
    LINK: 'ln',
    LOADING: 'ld',
    LOGO: 'logo',
    MENU: 'menu',
    NAME: 'name',
    NAME_WITH_LOGO: 'name-with-logo',
    NOTIFICATION: 'no',
    OK: 'ok',
    PLAY: 'pl',
    PLUS: 'plus',
    QUESTION: 'q',
    RESTART: 'rs',
    RIGHT_ARROW: 'ra',
    RIGHT_CARET: 'rc',
    RUN: 'rn',
    SEARCH: 'sr',
    SETTINGS: 'st',
    TABLE: 'ta',
    TEXT: 'tx',
    UP_ARROW: 'ua',
    VERTICAL_ELLIPSES: 've',
    VISUALIZATION: 'vz',
    WARNING_EXCLAMATION: 'we',
    WORKFLOW: 'wf',
    UPLOAD: 'up'
  },

  // Record<procedure.language, {type: procedure.language, label: string}>
  PROCEDURE_TYPE_MAP: {
    english: {
      type: 'english',
      label: 'English',
      codeMode: 'english'
    },
    // nodejs: {
    //   type: 'nodejs',
    //   label: 'Javascript',
    //   codeMode: 'javascript'
    // },
    python: {
      type: 'python',
      label: 'Python',
      codeMode: 'python'
    }
    // internal: {
    //   type: 'internal',
    //   label: 'Internal'
    // }
  },

  RUN_STATUS: {
    NOTSTARTED: 'notstarted',
    INPROGRESS: 'inprogress',
    COMPLETED: 'completed',
    ERROR: 'error',
    INPUT_NEEDED: 'inputneeded'
  },

  RUN_STATUS_COLOR: {
    created: 'blue',
    inprogress: 'blue',
    completed: 'green',
    error: 'red',
    inputneeded: 'yellow'
  },

  // UI Side Object for a new run item
  NEW_RUN_ITEM_ID: '-1',
  NEW_RUN_ITEM: {
    __typename: 'Step',
    contextId: '',
    workerId: '',
    id: '-1',
    lineText: '',
    parameters: [],
    status: '',
    messages: [],
    children: []
  },

  K2_CHAT: {
    KONCIERGE_DISPLAY_NAME: 'Koncierge'
  },

  RUN_ACTIONS: {
    INITIATE: 'initiate'
  },

  RUN_ITEM_ACTIONS: {
    INSERT_ABOVE: 'insertAbove',
    INSERT_BELOW: 'insertBelow',
    REWIND_TO_HERE: 'rewindToHere',
    RUN_TO_HERE: 'runToHere',
    STEP_INTO: 'stepInto',
    SKIP_PAST: 'skipPast',
    SCRATCH: 'scratch',
    EDIT_STEP: 'editStep',
    EDIT_ANSWER: 'editAnswer',
    SAVE: 'save', // Save a newly create run item
    CANCEL: 'cancel' // Cancel creation of a  new run item
  },

  // Knowledge Question Types
  QUESTION_TYPES: {
    STRING: 'string', // Simple textual answer
    NUMBER: 'number',
    DATE: 'date',
    SINGLE_SELECT: 'singleSelect',
    MULTIPLE_SELECT: 'multipleSelect'
  },

  // Question client side named validations
  VALIDATIONS: {
    VALID_REGION: 'validRegion', // options are {minWidth: minHeight: }

    STRING_LENGTH: 'stringLength', // Options { min, max }
    MATCHES_REGEX: 'matchesRegex', // Options { regex }

    VALID_DATE: 'validDate', // Options are { format }
    DATE_BEFORE: 'dateBefore', // Options are beforeMillis
    DATE_AFTER: 'dateAfter', // Options are afterMillis

    VALID_NUMBER: 'validNumber' // options are range {min: max: }
  },

  // Local storage keys
  STORAGE_KEYS: {
    PREFIX: 'Kognitos.UI',
    DEV: 'useDevAPIs', // Values are null/'true'/'false'
    GRID_CONFIG: 'gridConfig', // JSON for a grid's configuration
    DEPARTMENT: 'department',
    RECENT_SEARCHES: 'recentSearches',
    ENTITY_POPULARITIES: 'entityPopularities',
    KNOWLEDGE_TREE_COMPACT: 'ktreeCompact'
  },

  PATTERNS: {
    S3_URI: /"?s3:\/\/([^>"]+)"?/,
    S3_URI_G: /"?s3:\/\/([^>,"]+)"?/g,
    S3_URI_WIDGET: /"?s3:\/\/([^>"]+)"?/,
    HTTP_URI: /"?(https?:\/\/\S[^>,"]+)"?/,
    EMAIL_URI: /("?\S+@\S+\.\S+"?)/,
    MARKDOWN_TEXT: /"""(.*)"""/s,
    JSON: /"{(.*)}"/,
    EMAIL_HTML: /the email html is "(.*)"/,
    EMAIL_BODY: /the email body is "(.*)"/,
    SUPPORT_USER: /kognitos.com/,
    CLONE_SUFFIX: /(-\s*)(\d+\s*)?clone/,
    NUMBERS: /(\d+)/
  },

  // Tree View - Root node's ID
  TREE_ROOT_NODE_ID: 'root',

  // s3 file scopes
  S3_FILE_SCOPE: {
    WORKER: 'worker'
  },

  // notifications
  NOTIFICATIONS: {
    DISPLAY_DURATION: 6 * 1000
  },

  // JS Colors - maps to variables.less
  COLORS: {
    BLACK: '#343536',
    BLUE1: '#26bbf0',
    COLOR_INFO: '#1890ff',
    COLOR_SUCCESS: '#52c41a',
    COLOR_WARNING: '#faad14',
    COLOR_ERROR: '#ff4d4f',
    SELECTION_COLOR: '#26bbf0',
    SELECTION_COLOR_DIM: '#acd5ea'
  },

  SHARE: {
    SUPPORTED_STAGES: ['draft', 'published']
  },

  ACCESS_CONTROL_ROLE_CONFIG: {
    [AccessControlRole.admin]: {
      label: 'Admin',
      value: AccessControlRole.admin,
      disabled: false
    },
    [AccessControlRole.member]: {
      label: 'Member',
      value: AccessControlRole.member,
      disabled: false
    }
  },

  SCHEDULE_TYPES: {
    FEW_MINUTES: 'every_few_mins',
    HOURLY: 'hourly',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly'
  },

  EVENT_NAMES: {
    STEP_HIGHLIGH_ADD: 'step-highlight-add',
    STEP_HIGHLIGH_REMOVE: 'step-highlight-remove'
  },

  TABLE: {
    DEFAULT_PAGE_SIZE: 20
  },

  RUN_STATE_MAP: {
    ALL: {
      value: '',
      label: 'All runs'
    },
    PROCESSING: {
      value: 'processing',
      label: 'Processing'
    },
    CREATED: {
      value: 'created',
      label: 'Initiated'
    },
    WAITING_FOR_USER_INPUT: {
      value: 'waiting_for_user_input',
      label: 'Waiting for answer'
    },
    DONE: {
      value: 'done',
      label: 'Finished'
    },
    ERROR: {
      value: 'error',
      label: 'Failed'
    },
    ARCHIVED: {
      value: 'archived',
      label: 'Archived'
    }
  },

  // Record<email type, config>
  PROCEDURE_EMAIL_TYPE_MAP: {
    published: {
      label: 'Published'
    },
    draft: {
      label: 'Draft'
    }
  },

  EXCEPTIONS_ANSWERED_STATE_MAP: {
    PENDING: {
      value: false,
      label: 'Pending',
      key: 'pending'
    },
    ANSWERED: {
      value: true,
      label: 'Answered',
      key: 'answered'
    },
    ALL: {
      value: undefined,
      label: 'All',
      key: 'all'
    }
  },

  VALUE_ROW_KEYS: {
    NAME: ['name', 'Name', 'NAME'],
    ID: ['id', 'Id', 'ID']
  },

  MONITORING_EMAIL: 'monitoring@kognitos.com',

  FACT_TABLE_NAME: 'table',

  UNASSIGNED_EXCEPTION: 'Unassigned',

  SELF_SERVE: {
    TEMPLATE_ID: 'TEMPLATE_ID',
    TEMPLATES_USED: 'TEMPLATES_USED',
    TEMPLATE_ID_FOR_REQUEST: 'TEMPLATE_ID_FOR_REQUEST',
    FILE_UPLOAD_EVENT: 'FILE_UPLOAD_EVENT',
    TEMPLATE_WORKER_MAP: 'TEMPLATE_WORKER_MAP'
  },

  DOC_EXCEPTION: {
    ONBOARDING_MESSAGE_THRESHOLD: 1,
    DEFAULT_ANNOTATION_KEY: 'default'
  }
};

export default AppConstants;
