export const isWeb = typeof window !== 'undefined';
export const isFirefox =
  isWeb && navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

function getCaretIndex(element: Node) {
  let position = 0;
  const isSupported = typeof window.getSelection !== 'undefined';
  if (isSupported) {
    const selection = window.getSelection();
    // Check if there is a selection (i.e. cursor in place)
    if (selection && selection.rangeCount !== 0) {
      // Store the original range
      const range = window.getSelection()?.getRangeAt(0);
      if (range) {
        // Clone the range
        const preCaretRange = range.cloneRange();
        // Select all textual contents from the contenteditable element
        preCaretRange.selectNodeContents(element);
        // And set the range end to the original clicked position
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        // Return the text length from contenteditable start to the range end
        position = preCaretRange.toString().length;
      }
    }
  }
  return position;
}

const setCaretToEnd = (element: any) => {
  const range = document.createRange();
  const selection = window.getSelection();
  range.selectNodeContents(element);
  range.collapse(false);
  selection!.removeAllRanges();
  selection!.addRange(range);
  element.focus();
};

const setCarentToLocation = (element: any, position: number) => {
  if (element != null) {
    const range = document.createRange();
    const sel = window.getSelection();

    // FIXME: This is a hack
    // Need to check if the firstChild is of type textNode
    range.setStart(element.childNodes[0], position);
    range.collapse(true);
    sel?.removeAllRanges();
    sel?.addRange(range);
    element.focus();
  }
};

declare global {
  interface Document {
    caretPositionFromPoint(
      x: number,
      y: number
    ): {
      offsetNode: Node;
      offset: number;
    };
  }
}

function hasClass(element: HTMLElement, className: string): boolean {
  if (!element || !className) {
    return false;
  }

  const classNames = element?.className?.split(' ');
  return classNames.includes(className);
}

function isChild(obj: any, parentObj: any) {
  while (
    obj !== undefined &&
    obj !== null &&
    obj.tagName.toUpperCase() !== 'BODY'
  ) {
    if (obj === parentObj) {
      return true;
    }
    if (hasClass(obj, 'ant-pagination-item')) {
      // FIXME: This is hacky solution
      return true;
    }
    // eslint-disable-next-line no-param-reassign
    obj = obj.parentNode;
  }
  return false;
}

export const DOMUtil = {
  getCaretIndex,
  setCaretToEnd,
  setCarentToLocation,
  hasClass,
  isChild
};
