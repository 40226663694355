import { message } from 'antd';
import React from 'react';
import { nodeId } from '@/details/runs/items/RunContextUtil';
import { useNavigate } from 'react-router-dom';
import { QuestionAnswerMode } from '@/components/request/interface';
import Request from '../../../components/request/Request';
import {
  Request as RequestInterface
  // Step
} from '../../../generated/API';
import usePlaygroundServiceCommands from '../../../hooks/usePlaygroundServiceCommands';
import { useRunCtxV2 } from '../../../provider/runv2';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { departmentQuerySelector } from '../../../stores/slices/department';
import { getSteps } from '../../../stores/slices/steps';
import { isRequestHandled } from '../../../utils/request';
import routes from '../../../utils/routes';
import { EditorSourceType } from '../../../pages/PlaygroundV3/components/Editor/editorInterface';

interface IProps {
  step: any; // TODO: Need to be changed from any to actual type, changed to any so that it works with both playground v2 and v3
  stepRequests: RequestInterface[];
  editor?: {
    source: EditorSourceType;
    subDocumentToken: string;
  };
}

function RunStepInfo(props: IProps) {
  const { step, stepRequests, editor } = props;
  const { subDocumentToken } = editor || {};

  const { worker, updateExpandedNodes, expandedNodes, procedure } =
    useRunCtxV2();
  const stepNodeId = nodeId(step);
  const { department } = useAppSelector(departmentQuerySelector);
  const dispatch = useAppDispatch();
  const { scratchAllSteps, sendSteps } = usePlaygroundServiceCommands();
  const navigate = useNavigate();

  const pendingRequests = stepRequests.filter(
    (request) => !isRequestHandled(request)
  );
  const hasExceptions = pendingRequests.length > 0;

  const hide = !hasExceptions;

  if (hide) {
    return null;
  }

  const handleSuggestionClick = (suggestion: string[]) => {
    const hideMessage = message.loading('Applying suggestions...', 0);

    const runAsyncCommands = async () => {
      try {
        await scratchAllSteps();
        await sendSteps(suggestion.join('\n'));
        hideMessage();
      } catch (e: any) {
        console.error('Error while applying suggestions', e);
        message.error(e.message || 'Error while applying suggestions');
      }
    };

    runAsyncCommands();
  };

  const renderPendingRequests = () => {
    return pendingRequests.map((request) => (
      <Request
        key={request.id}
        request={request}
        worker={worker}
        department={department}
        procedure={procedure || undefined}
        sentence={step}
        activeDocumentToken={subDocumentToken}
        hideAnsweredQuestion
        questionConfig={{
          answerMode: QuestionAnswerMode.SINGLE,
          allowDocumentExceptionEditingInPopup: true,
          autoSelectDocumentException: false
        }}
        dispatchContextPath={false}
        onSuccess={() => {
          updateExpandedNodes(
            expandedNodes.filter((id) => id !== stepNodeId),
            true
          );
          dispatch(
            getSteps({
              workerId: worker.id,
              contextId: step.contextId
            })
          );
        }}
        suggestions={{
          handleClick: handleSuggestionClick
        }}
        archive={{
          onSuccess: () => {
            if (procedure?.id) {
              const navTo = routes.procedure.getProcedurePageUrl(
                procedure?.departmentId,
                procedure?.id
              );
              navigate(navTo);
            }
          }
        }}
      />
    ));
  };

  return <div className="run-step-info">{renderPendingRequests()}</div>;
}

export default RunStepInfo;
