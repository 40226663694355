import React from 'react';

import { Button, Result } from 'antd';

import AppUtil from '@/utils/AppUtil';
import BrainIcon from '../components/icons/BrainIcon';
import Container from '../components/Container';

export default function SentryErrorPage(props: any) {
  const { error, componentStack, resetError } = props;
  AppUtil.logError(error);

  return (
    <Container>
      <Result
        title={<div>Please try refreshing the page</div>}
        icon={
          <div
            style={{
              width: 100,
              margin: '0 auto'
            }}
          >
            <BrainIcon style={{ color: '#1a90ff', fontSize: '60px' }} />
          </div>
        }
        subTitle={error?.toString() || 'Error'}
        extra={
          <>
            <Button
              onClick={() => {
                resetError();
              }}
              type="primary"
            >
              Refresh
            </Button>
            <details style={{ whiteSpace: 'pre-wrap', marginTop: 12 }}>
              {error?.toString()}
              <br />
              {componentStack}
            </details>
          </>
        }
      />
    </Container>
  );
}
