import React, { SyntheticEvent, forwardRef, useState } from 'react';
import { Resizable, ResizableProps } from 'react-resizable';
import { throttle } from 'lodash';
import styles from './Resizable.module.less';

interface Size {
  width: number;
  height: number;
}

const HandleComponent = (_props: any) => {
  const { handleAxis, innerRef, ...props } = _props;
  return (
    <div
      ref={innerRef}
      className={`${styles.handle} handle-${handleAxis}`}
      {...props}
    />
  );
};
const ResizeHandle = forwardRef((props, ref) => (
  <HandleComponent innerRef={ref} {...props} />
));

const ResizableBox = ({
  children,
  initHeight,
  initWidth,
  minHeight = 500, // default height
  maxHeight,
  minWidth = 400, // default width
  maxWidth,
  resizeHandles = ['w'],
  onSizeChange
}: {
  children: JSX.Element;
  initHeight: number;
  initWidth: number;
  minHeight?: number;
  maxHeight?: number;
  minWidth?: number;
  maxWidth?: number;
  resizeHandles?: ('s' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne')[];
  onSizeChange?: (size: Size) => void;
}) => {
  const [size, setSize] = useState<Size>({
    width: initWidth,
    height: initHeight
  });

  const onResize: (
    event: SyntheticEvent<Element, Event>,
    params: { size: Size }
  ) => void = (_event, params: { size: Size }) => {
    const MAX_WIDTH = maxWidth && maxWidth > 850 ? 850 : maxWidth || 850;
    let width = params.size.width < minWidth ? minWidth : params.size.width;
    width = params.size.width > MAX_WIDTH ? MAX_WIDTH : params.size.width;
    setSize({
      ...size,
      width
    });

    if (onSizeChange) {
      onSizeChange({ ...size, width });
    }
  };

  const debouncedHandleResize = throttle(onResize, 100);

  let props: ResizableProps = {
    width: minWidth,
    height: minHeight,
    onResize: debouncedHandleResize,
    handle: <ResizeHandle />,
    resizeHandles
  };

  if (minWidth && minHeight) {
    props = {
      ...props,
      minConstraints: [minWidth, minHeight]
    };
  }

  if (maxWidth && maxHeight) {
    props = {
      ...props,
      maxConstraints: [maxWidth, maxHeight]
    };
  }

  const onResizeStart = () => {
    window.document.body.style.cursor = 'ew-resize';
  };

  const onResizeStop = () => {
    window.document.body.style.cursor = '';
  };

  return (
    <Resizable
      {...props}
      width={size.width}
      height={size.height}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      transformScale={1.8}
    >
      <div style={{ width: `${size.width}px`, height: `${size.height}px` }}>
        {children}
      </div>
    </Resizable>
  );
};

export default ResizableBox;
