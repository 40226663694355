import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import Editor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownRenderer from '@/components/MarkdownRenderer';
import {
  decodeMarkdownText,
  encodeMarkdownText
} from '@/components/editor/helper';
import AppUtil from '@/utils/AppUtil';
import WidgetUpdateConfirmIcon from '@/components/icons/WidgetUpdateConfirmIcon';
import WidgetUpdateDeleteIcon from '@/components/icons/WidgetUpdateDeleteIcon';
import classnames from 'classnames';
import { WIDGET_TYPES } from './decorators';
import styles from './WidgetManager.module.less';

interface IWidgetManagerProps {
  widgetKey: string;
  widgetValue: string;
  widgetType: WIDGET_TYPES;
  onUpdate: (key: string, value: string, type: WIDGET_TYPES) => void;
}

function WidgetManager({
  widgetKey,
  widgetValue,
  widgetType,
  onUpdate
}: IWidgetManagerProps) {
  const [editMode, setEditMode] = useState(!widgetValue);
  const widgetVal =
    widgetType === WIDGET_TYPES.MARKDOWN
      ? decodeMarkdownText(widgetValue.replaceAll('"""', ''))
      : widgetValue;
  const [value, setValue] = useState(widgetVal);
  const [initialValue, setInitialValue] = useState(widgetVal);

  useEffect(() => {
    const editWidgetEvent = (e: any) => {
      if (e.detail === widgetKey) {
        setEditMode(true);
      }
    };
    document.addEventListener('EditWidgetEvent', editWidgetEvent);

    return () =>
      document.removeEventListener('EditWidgetEvent', editWidgetEvent);
  }, []);

  useEffect(() => {
    setValue(value);
    setInitialValue(initialValue);
  }, [widgetKey]);

  const handleClose = () => {
    setEditMode(false);
  };

  const handleUpdate = () => {
    if (widgetType === WIDGET_TYPES.MARKDOWN) {
      const val = encodeMarkdownText(`"""${value}"""`);
      setInitialValue(val);
      onUpdate(widgetKey, val, widgetType);
    } else if (widgetType === WIDGET_TYPES.JSON) {
      setInitialValue(value);
      onUpdate(widgetKey, value, widgetType);
    } else {
      setInitialValue(value);
      onUpdate(widgetKey, value, widgetType);
    }
    handleClose();
  };

  const renderEditor = () => {
    switch (widgetType) {
      case WIDGET_TYPES.MARKDOWN:
        return (
          <div className={styles.widgetStyle}>
            <Editor
              autoFocus
              className={styles.markdownEditorContainer}
              value={value}
              renderHTML={(text) => (
                <MarkdownRenderer>{decodeMarkdownText(text)}</MarkdownRenderer>
              )}
              onChange={({ text }) => {
                setValue(text);
              }}
            />
          </div>
        );
      // case WIDGET_TYPES.JSON:
      //   return (
      //     <div style={styles.widgetStyle}>
      //       JSON:
      //       <ReactJson
      //         src={AppUtil.safeParseJSON(value)}
      //         name="json data"
      //         theme="bright:inverted"
      //         onEdit={(val) => {
      //           setValue(JSON.stringify(val.updated_src));
      //         }}
      //         onAdd={(val) => {
      //           setValue(JSON.stringify(val.updated_src));
      //         }}
      //         onDelete={(val) => {
      //           setValue(JSON.stringify(val.updated_src));
      //         }}
      //         style={{
      //           overflow: 'hidden',
      //           wordBreak: 'break-all',
      //           borderRadius: 4,
      //           height: '400px'
      //         }}
      //       />
      //     </div>
      //   );
      case WIDGET_TYPES.URL:
        return (
          <div
            className={classnames([
              styles.widgetStyle,
              styles.urlEditorContainer
            ])}
          >
            url:
            <input
              style={{
                background: 'transparent',
                border: 'none',
                outline: 'none',
                width: '100%'
              }}
              type="text"
              value={value.replaceAll('"', '')}
              onChange={(e) => setValue(`"${e.target.value}"`)}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const renderViewer = () => {
    switch (widgetType) {
      case WIDGET_TYPES.MARKDOWN:
        // Render Markdown
        return (
          <span className={styles.widgetStyle}>
            <MarkdownRenderer>{decodeMarkdownText(value)}</MarkdownRenderer>
          </span>
        );
      case WIDGET_TYPES.JSON:
        // Render JSON
        return (
          <div className={styles.widgetStyle}>
            JSON:
            {AppUtil.safeParseJSON(value, true)}
          </div>
        );
      case WIDGET_TYPES.URL: {
        // Render URL
        const url = value.replaceAll('"', '');
        return (
          <span className={styles.widgetStyle}>
            url:
            <a
              className={styles.linkRenderer}
              href={url}
              rel="noreferrer"
              target="_blank"
            >
              {url}
            </a>
          </span>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{
        marginTop: 8,
        display: 'flex',
        flexDirection: editMode ? 'column' : 'row'
      }}
    >
      {editMode ? renderEditor() : renderViewer()}
      <span
        className={styles.actionButtonsContainer}
        style={editMode ? { marginTop: 8 } : { marginLeft: 8 }}
      >
        {widgetType !== WIDGET_TYPES.JSON && editMode && (
          <>
            <Tooltip title="save">
              <Button
                className={styles.actionButton}
                size="small"
                onClick={handleUpdate}
                type="text"
              >
                <WidgetUpdateConfirmIcon />
              </Button>
            </Tooltip>
            <Tooltip title="close">
              <Button
                size="small"
                className={styles.actionButton}
                onClick={() => {
                  setEditMode(false);
                  setValue(initialValue);
                }}
                type="text"
              >
                <WidgetUpdateDeleteIcon />
              </Button>
            </Tooltip>
          </>
        )}
      </span>
    </div>
  );
}

export default WidgetManager;
