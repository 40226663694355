export type CognitoConfig = {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
};

export type SentryConfig = {
  environment: string;
  dsn: string;
  tracingOrigins: string[];
};

export type LogRocketConfig = {
  appId: string;
};

export type AmplitudeConfig = {
  apiKey: string;
  optOut?: boolean;
};

export interface GoogleMapApiConfig {
  apiKey: string;
}

export interface LaunchDarklyConfig {
  clientSideId: string;
}

export type BackendConfig = {
  api_v2_internal_graphql_endpoint: string;
  aws_appsync_authenticationType: string;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_project_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  environment: string;
  graphql_endpoint: string;
  graphql_subscription_appsync_endpoint: string;
  graphql_v2_endpoint: string;
  kognitos_login_url: string;
  graphql_subscription_agw_endpoint: string;
};

export type AppConfig = {
  cognito: CognitoConfig;
  sentry: SentryConfig | null;
  logRocket: LogRocketConfig | null;
  amplitude: AmplitudeConfig;
  googleMap: GoogleMapApiConfig;
  launchDarkly: LaunchDarklyConfig;
  backend: BackendConfig;
};

declare let APP_CONFIG: any;

// webpack DefinePlugin substitutes the *contents* of APP_CONFIG  directly into the packed code.
export const appConfig: AppConfig = Object.freeze(APP_CONFIG);
