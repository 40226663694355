import { Select } from 'antd';
import React, { CSSProperties } from 'react';
import { AccessControlRole } from '../generated/API';
import AppConstants from '../utils/AppConstants';

interface IProps {
  value: AccessControlRole;
  onChange: (role: AccessControlRole) => void;
  disabled?: boolean;
  style?: CSSProperties;
  allowOwnerTransfer?: boolean;
}

function AccessControlRoleSelector(props: IProps) {
  const { value, onChange, disabled, style, allowOwnerTransfer } = props;

  // @ts-ignore
  const selectedOption = AppConstants.ACCESS_CONTROL_ROLE_CONFIG[value];

  let options = Object.values(AppConstants.ACCESS_CONTROL_ROLE_CONFIG);
  if (!allowOwnerTransfer) {
    options = options.filter((config) => !config.disabled);
  }

  return (
    <Select
      disabled={disabled}
      value={selectedOption.value || undefined}
      onChange={onChange}
      style={style}
      data-cy="role-selector"
      placeholder="Select role"
    >
      {options.map((option) => (
        <Select.Option
          key={option.value}
          value={option.value}
          data-cy={`role-option-${option.label.toLowerCase()}`}
        >
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default AccessControlRoleSelector;
