import { gql } from '@apollo/client';

export const ON_WORKER_SENTENCE_UPDATE = gql`
  subscription onSentenceUpdate($workerId: ID!, $documentToken: String) {
    onSentenceUpdate(workerId: $workerId, documentToken: $documentToken) {
      sentences {
        answer
        concepts
        epoch
        iterationTokens
        lineId
        requests
        status
        token
        epoch
        subDocuments {
          documentToken
          processName
        }
        miniPlaygrounds {
          documentToken
          processName
        }
        startedAt
        completedAt
      }
    }
  }
`;

export const ON_SENTENCE_MINI_PLAYGROUNDS_UPDATE = gql`
  subscription onSentenceUpdate($workerId: ID!, $documentToken: String) {
    onSentenceUpdate(workerId: $workerId, documentToken: $documentToken) {
      sentences {
        lineId
        token
        miniPlaygrounds {
          documentToken
          processName
        }
      }
    }
  }
`;

export const ON_WORKER_STATE_UPDATE = gql`
  subscription OnNotifyWorkerUpdate($id: ID!) {
    onNotifyWorkerUpdate(id: $id) {
      id
      state
    }
  }
`;

export const ON_WORKER_DOCUMENT_UPDATE = gql`
  subscription OnWorkerDocumentUpdate($workerId: ID!) {
    onWorkerDocumentUpdate(workerId: $workerId) {
      sentences {
        lineId
        text
        parentId
        language
        metadata {
          lineId
          parentId
        }
        internalMetadata {
          key
          value
        }
      }
      updatedAt
    }
  }
`;
