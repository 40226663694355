import { Button, Modal, Spin, message } from 'antd';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CreateBulkRequestAnswerMutation,
  CreateBulkRequestAnswerMutationVariables,
  RequestAnswer as RequestAnswerInterface,
  RequestQuestion as RequestQuestionInterface,
  ValueNotKnownQuestion
} from '../generated/API';
import RequestQuestion from '../components/request/RequestQuestion';
import {
  IQuestionValues,
  QuestionAnswerMode,
  QuestionInputType
} from '../components/request/interface';
import { useAppSelector } from '../stores/hooks';
import { departmentQuerySelector } from '../stores/slices/department';
import AppUtil from '../utils/AppUtil';
import { IProcedureQuestion } from '../stores/slices/procedureRequests';
import FormattingUtil from '../utils/FormattingUtil';
import { prepareAnswerInput } from '../utils/request';
import { CREATE_BULK_REQUEST_ANSWER } from '../graphql/request';

interface IProps {
  onClose: () => void;
  onSuccess?: (data: RequestAnswerInterface[]) => void;
  procedureQuestions: IProcedureQuestion[];
}

function RequestExceptionsBatchAnswerPopup(props: IProps) {
  const { onSuccess, onClose, procedureQuestions } = props;

  const { department } = useAppSelector(departmentQuerySelector);

  const [questionValues, setQuestionValues] = useState<IQuestionValues | null>(
    null
  );

  const [bulkRequestAnswer, { loading }] =
    useMutation<CreateBulkRequestAnswerMutation>(CREATE_BULK_REQUEST_ANSWER);

  const onFinish = (values: IQuestionValues) => {
    const variables: CreateBulkRequestAnswerMutationVariables = {
      inputs: procedureQuestions.map((procedureQuestion) =>
        prepareAnswerInput(
          procedureQuestion.request,
          procedureQuestion.question,
          values
        )
      )
    };

    bulkRequestAnswer({
      variables
    })
      .then((resp) => {
        const answers = resp.data?.createBulkRequestAnswer;
        if (answers) {
          onSuccess?.(answers as RequestAnswerInterface[]);
          message.success(
            `Answered ${procedureQuestions.length} ${AppUtil.getPlural(
              'exception',
              procedureQuestions.length
            )} ✅`
          );
        }
        onClose();
      })
      .catch((e) => {
        console.error('bulkRequestAnswer error', e);
        message.error('Error answering exceptions');
      });
  };

  const onValuesChange = (values: Partial<IQuestionValues>) => {
    const allValues = values as IQuestionValues;
    if (
      allValues.inputType === QuestionInputType.VALUE_UPLOAD &&
      !!allValues.upload
    ) {
      onFinish(allValues);
    } else {
      setQuestionValues({
        ...questionValues,
        ...allValues
      });
    }
  };

  const disableSubmit =
    questionValues === null ||
    questionValues.inputType === QuestionInputType.VALUE_UPLOAD;

  let title = `Answer ${AppUtil.getPlural(
    'exception',
    procedureQuestions.length
  )} (${procedureQuestions.length})`;

  const sameQuestions =
    AppUtil.checkSameProcedureRequestQuestions(procedureQuestions);

  if (sameQuestions) {
    const firstProcedureQuestion = procedureQuestions[0];
    const processed = FormattingUtil.processQuestionV1(
      firstProcedureQuestion.question.text || '',
      (firstProcedureQuestion.question as any).lexicalPath || null
    );
    const questionText = processed?.text;
    if (questionText) {
      title = questionText;
    }
  }

  const valueNotKnownQuestion: ValueNotKnownQuestion = {
    __typename: 'ValueNotKnownQuestion',
    id: 'random',
    text: null,
    failedAnswers: null,
    contextId: null,
    sentenceId: null,
    answerTypeHint: null,
    failedAnswerId: null,
    failedAnswerReason: null,
    lexicalPath: null,
    choices: null,
    helpText: null
  };

  return (
    <Modal
      className="request-exceptions-batch-answer-modal"
      title={title}
      centered
      open
      footer={null}
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
    >
      <div className="run-requests">
        <div className="question-group">
          <Spin spinning={loading}>
            <RequestQuestion
              request={null}
              question={
                valueNotKnownQuestion as unknown as RequestQuestionInterface
              }
              worker={null}
              department={department}
              config={{
                answerMode: QuestionAnswerMode.BATCH
              }}
              onValuesChange={onValuesChange}
              questionValues={null}
            />
          </Spin>
          <Button
            loading={loading}
            size="middle"
            type="primary"
            data-cy="question-submit"
            className="question-submit"
            onClick={() => onFinish(questionValues!)}
            disabled={disableSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default RequestExceptionsBatchAnswerPopup;
