import { ReactEditor } from 'slate-react';
import { Editor, Element as SlateElement, Path, Node } from 'slate';
import shortUUID from 'short-uuid';
import {
  EditorBlock,
  ExecutionStatus,
  ISentence
} from '../Editor/editorInterface';

export const getNodePath = (editor: ReactEditor, node: any) => {
  // [TODO] - some when lost focus bug
  const path = ReactEditor.findPath(editor, node);
  const nodePath = path.length === 1 ? [path[0], 0] : path;
  return nodePath;
};

export const getNodeByPath = (
  editor: Editor,
  path?: Path,
  mode: 'all' | 'highest' | 'lowest' = 'lowest'
) => {
  const nodeEntry = Array.from(
    Editor.nodes(editor, {
      match: (node) => Editor.isEditor(editor) && SlateElement.isElement(node),
      at: path || editor.selection?.anchor.path,
      mode
    })
  )[0];

  if (nodeEntry) return nodeEntry[0];

  return editor.children[0];
};

export const getDefaultNewLine = () => {
  // Requires any here because of type mismatch default and custom element
  // const path: any = getNodeByPath(editor);
  const lineId = shortUUID.generate();
  return {
    type: 'EditorLine',
    children: [
      {
        uiLineId: lineId,
        text: '',
        status: ExecutionStatus.NEW_LINE,
        tabCount: 0
      }
    ]
  };
};

export const getNodeAndPath = (editor: Editor, id: string) => {
  const editorNodes = Array.from(Node.elements(editor)).map(
    (n) => n[0].children[0]
  );

  // @ts-ignore
  const index = editorNodes.findIndex((node) => node.uiLineId === id);

  return {
    path: [index, 0],
    // @ts-ignore
    node: editor.children[index].children[0]
  };
};

interface ParseTextOutput {
  documentLines: EditorBlock[];
  errorMesaage: string;
}

function findCommonSpacePerTab(lines: string[]): number {
  // Initialize an array to store the counts of spaces at the beginning of each line
  const spaceCounts: number[] = [];

  // Iterate through the lines and find the number of spaces at the beginning of each line
  for (const line of lines) {
    let spaceCount = 0;
    let i = 0;

    while (i < line.length && line[i] === ' ') {
      spaceCount += 1;
      i += 1;
    }

    if (spaceCount > 0) {
      spaceCounts.push(spaceCount);
    }
  }

  if (spaceCounts.length === 0) {
    return 0; // No spaces found
  }

  // Find the greatest common divisor (GCD) of spaceCounts
  function gcd(a: number, b: number): number {
    if (b === 0) {
      return a;
    }
    return gcd(b, a % b);
  }

  let commonSpacePerTab = spaceCounts[0];
  for (let i = 1; i < spaceCounts.length; i += 1) {
    commonSpacePerTab = gcd(commonSpacePerTab, spaceCounts[i]);
  }

  return commonSpacePerTab;
}

const countTabAtBeginning = (
  inputString: string,
  spacesPerTab: number
): number => {
  let spaceCount = 0;
  let index = 0;

  while (index < inputString.length && inputString[index] === ' ') {
    spaceCount += 1;
    index += 1;
  }

  // Calculate the equivalent tab count based on the number of spaces
  const tabCount = Math.floor(spaceCount / spacesPerTab);

  return tabCount;
};

export const parseText = (text: string): ParseTextOutput => {
  const trimmedText = text.trim();
  const lines = trimmedText.split('\n');
  const outputLines: EditorBlock[] = [];
  const errorMesaage: string = '';

  // TODO: SpacePerTab should be configurable
  let spacesPerTab = findCommonSpacePerTab(lines);
  if (spacesPerTab === 0) {
    spacesPerTab = 2;
  }

  // try {
  lines.forEach((line) => {
    // Count the number of tab at the beginning of the line
    const tabCount = countTabAtBeginning(line, spacesPerTab);

    // Create a new EditorBlock
    const newBlock: EditorBlock = {
      type: 'EditorLine',
      children: [
        {
          uiLineId: shortUUID.generate(),
          text: line.trim(),
          status: ExecutionStatus.NEW_LINE,
          tabCount
        }
      ]
    };

    outputLines.push(newBlock);
  });

  return {
    documentLines: outputLines,
    errorMesaage
  };
};

/**
 * Generate text from given EditorBlock[]
 * Based on tabCount of each EditorBlock, generateText will add indentation by two spaces
 * @param blocks
 * @returns {string}
 */
export const generateText = (blocks: EditorBlock[]): string => {
  let output = '';

  blocks?.forEach((block) => {
    output += `${
      '  '.repeat(block.children[0].tabCount || 0) + block.children[0].text
    }\n`;
  });

  return output.trim();
};

/**
 * Returns the editor nodes in the current selection
 * @param editor
 * @returns {EditorBlock[]}
 */
export const getEditorNodesFromSelection = (editor: Editor): EditorBlock[] => {
  let items: EditorBlock[] = [];
  const { selection } = editor;

  if (selection) {
    const reversed = Path.isAfter(
      selection?.anchor.path,
      selection?.focus.path
    );

    items = Array.from(
      Node.elements(editor, {
        from: selection.anchor.path,
        to: selection.focus.path,
        reverse: reversed
      })
    ).map((n) => {
      if (
        selection.anchor.path[0] ===
        selection.focus.path[0 && n[1][0] === selection.focus.path[0]]
      ) {
        const text = reversed
          ? // @ts-ignore
            n[0].children[0].text.substring(
              selection.focus.offset,
              selection.anchor.offset
            )
          : // @ts-ignore
            n[0].children[0].text.substring(
              selection.anchor.offset,
              selection.focus.offset
            );
        const children = {
          ...n[0].children[0],
          text
        };
        return {
          ...n[0],
          children: [children]
        };
      }
      if (n[1][0] === selection.focus.path[0]) {
        const text = reversed
          ? // @ts-ignore
            n[0].children[0].text.substring(selection.focus.offset)
          : // @ts-ignore
            n[0].children[0].text.substring(0, selection.focus.offset);
        const children = {
          ...n[0].children[0],
          text
        };
        return {
          ...n[0],
          children: [children]
        };
      }
      if (n[1][0] === selection.anchor.path[0]) {
        const text = reversed
          ? // @ts-ignore
            n[0].children[0].text.substring(0, selection.anchor.offset)
          : // @ts-ignore
            n[0].children[0].text.substring(selection.anchor.offset);
        const children = {
          ...n[0].children[0],
          text
        };
        return {
          ...n[0],
          children: [children]
        };
      }

      return n[0];
    }) as EditorBlock[];

    if (reversed) {
      items.reverse();
    }
  }
  return items;
};

export const getParentMap = (
  editorNodes: any[]
): Map<string, string | null> => {
  // create a mapping from child ids to parent ids using a Map
  const parentMap = new Map<string, string | null>();

  for (let i = 0; i < editorNodes.length; i += 1) {
    parentMap.set(editorNodes[i].node.uiLineId, editorNodes[i].node.uiParentId);
  }

  return parentMap;
};

export const getNodeIfPresent = (
  nodes: ISentence[],
  idToFind: string
): ISentence | null => {
  for (const node of nodes) {
    if (node.uiLineId === idToFind) {
      return node;
    }
  }
  return null;
};
