// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-link {
  cursor: pointer;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/editor/components/MarkdownLink.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,SAAA;AADF","sourcesContent":["@import '@css/variables.less';\n\n.markdown-link {\n  cursor: pointer;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
