/* eslint-disable no-nested-ternary */
import { Button, Tooltip, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import PlaygroundPlayIcon from '@/components/icons/PlaygroundPlayIcon';
import PlaygroundPauseIcon from '@/components/icons/PlaygroundPauseIcon';
import { useRunCtxV2 } from '@/provider/runv2';
import { EditOutlined, MessageOutlined } from '@ant-design/icons';
import { useReactiveVar, useSubscription } from '@apollo/client';
import { ON_WORKER_STATE_UPDATE } from '@/graphql/subscriptions/playground';
import NewProcedureFromRun from '@/components/NewProcedureFromRun/NewProcedureFromRun';
import RunDebugDetails from '@/components/RunDebugDetails';
import AutoPilotIcon from '@/components/icons/AutoPilotIcon';
import Switch from '@/components/Switch';
import { RetryRun } from '@/details/runs/FlowRunHeaderV2';
import OutputPanelCloseIcon from '@/components/icons/OutputPanelCloseIcon';
import OutputPanelOpenIcon from '@/components/icons/OutputPanelOpenIcon';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import {
  deleteCurrentTemplateId,
  deleteTemplateIdForRequest,
  getTemplateIdForWorker,
  removeTemplateIdForWorker,
  setUsedTemplates
} from '@/pages/Playgrounds/templateUtils';
import AccessControl from '@/components/AccessControl/AccessControl';
import { ProcessPermissionsEnum } from '@/utils/permissionConstants';
import { currentUserDetailsVar } from '@/graphql/cache/user';
import { useAppSelector } from '@/stores/hooks';
import { departmentQuerySelector } from '@/stores/slices/department';
import AppUtil from '@/utils/AppUtil';
import { useSelector } from 'react-redux';
import { trackEvent } from '@/tracker';
import { AnalyticsEvents } from '@/analytics/events';
import { useKogEditorContext } from '../Editor/EditorProvider';
import styles from './EditorHeader.module.less';
import { EditorSourceType } from '../Editor/editorInterface';
import { isMiniPlaygroundOpen } from '../../../../utils/playground';
import { popupSelector } from '../../../../stores/slices/appPopup';
import { textDeFormatter } from '../EditorWidgets/decorators';

interface EditorHeaderProps {
  workerId: string;
  isOutputPanelVisible: boolean;
  isKonciergeVisible: boolean;
}

enum WorkerState {
  CREATING = 'creating',
  CREATED = 'created',
  PROCESSING = 'processing',
  USER_STOPPED = 'user stopped',
  WAITING_FOR_USER_INPUT = 'waiting_for_user_input',
  DONE = 'done',
  DELETED = 'deleted',
  ERROR = 'error',
  STARTED = 'started',
  PREAPRING_KNOWLEDGE = 'preparing_knowledge',
  KNOWLEDGE_READY = 'ready'
}

function EditorHeader({
  workerId,
  isOutputPanelVisible,
  isKonciergeVisible
}: EditorHeaderProps) {
  const { worker, procedure } = useRunCtxV2();
  const {
    source,
    debugMode,
    editorContent,
    editorStatus,
    actions,
    replacerObj
  } = useKogEditorContext();
  const { department } = useAppSelector(departmentQuerySelector);
  const location = useLocation();
  const { currentActivePopups } = useSelector(popupSelector);

  const isMiniPlayground = source === EditorSourceType.MINI_PLAYGROUND;
  const isAutoPilotSupported = AppUtil.isAutoPilotSupported(department);

  const { email } = useReactiveVar(currentUserDetailsVar);

  const [status, setStatus] = useState<string | null>(null);
  const [statusText, setStatusText] = useState<string>('Processing');
  // const statusText = ['Idle', 'Processing', 'Paused', 'Completed', 'Failed'];
  const [progressWidth, setProgressWidth] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isRunPage, setIsRunPage] = useState(false);

  const progressRef = useRef<HTMLDivElement | null>(null);

  const mood: any = {
    Brain: '😐',
    'Execution started': '😐',
    'Preparing Worker': '🤓',
    'Worker ready': '🤓',
    Preparing: '🤓',
    Processing: '🤔',
    Paused: '😎',
    Done: '🤩',
    Failed: '😭'
  };

  useEffect(() => {
    switch (status) {
      case WorkerState.CREATED:
      case WorkerState.CREATING:
        setStatusText('Brain');
        break;
      case WorkerState.PREAPRING_KNOWLEDGE:
        setStatusText('Preparing Worker');
        break;
      case WorkerState.KNOWLEDGE_READY:
        setStatusText('Worker ready');
        break;
      case WorkerState.DONE:
        // This is done for self-service templates
        setUsedTemplates(getTemplateIdForWorker(worker.id, email!), email!);
        deleteCurrentTemplateId(email!);
        removeTemplateIdForWorker(worker.id, email!);
        deleteTemplateIdForRequest(email!);
        deleteCurrentTemplateId(email!);
        setStatusText('Done');
        break;
      case WorkerState.PROCESSING:
        setStatusText('Processing');
        break;
      case WorkerState.ERROR:
        setStatusText('Failed');
        break;
      case WorkerState.WAITING_FOR_USER_INPUT:
        setStatusText('Paused');
        break;
      case WorkerState.STARTED:
      default:
        setStatusText('Execution started');
    }
  }, [status]);

  useEffect(() => {
    if (worker?.state) {
      setStatus(worker.state);
    }
  }, [worker?.state]);

  useEffect(() => {
    if (editorStatus.isExecuting) {
      setStatus(WorkerState.STARTED);
    }
  }, [editorStatus.isExecuting]);

  useEffect(() => {
    if (progressRef.current) {
      const width = progressRef.current.getBoundingClientRect().width;
      setProgressWidth(width);
    }
  }, [progressRef.current]);

  const { error: subscriptionError } = useSubscription(ON_WORKER_STATE_UPDATE, {
    variables: { id: worker?.id },
    skip: !worker || isMiniPlaygroundOpen(source, currentActivePopups),
    onData: ({ data: subscriptionData }) => {
      if (subscriptionData?.data?.onNotifyWorkerUpdate?.state) {
        setStatus(subscriptionData.data.onNotifyWorkerUpdate.state);
      }
    }
  });

  useEffect(() => {
    if (subscriptionError) {
      console.log(subscriptionError);
    }
  }, [subscriptionError]);

  useEffect(() => {
    setShowTooltip(false);
  }, [isOutputPanelVisible]);

  useEffect(() => {
    if (location?.pathname.includes('run')) {
      setIsRunPage(true);
    }
  }, [location?.pathname]);

  const pauseHandler = () => {
    // TODO: Implement Pause Worker when Pause feature is enabled in the backend
    // if (editorStatus.readOnly) {
    //   return;
    // }
    // pauseWorker({
    //   variables: {
    //     workerId
    //   },
    //   onCompleted: () => {
    //     actions.updateEditorStatus({ isExecuting: false, isEditing: true });
    //   }
    // });
  };

  const onOutputPanelChange = (open: boolean) => {
    if (open) {
      actions.updateEditorStatus({ showOutputPanel: true });
    } else {
      actions.updateEditorStatus({ showOutputPanel: false });
      actions.updateActiveBlock(null);
    }
  };

  return (
    <div className={styles.editorHeader}>
      <div className={styles.editorProgress} ref={progressRef}>
        {worker?.id ? (
          <>
            <RunDebugDetails
              worker={worker}
              workerId={worker.id}
              showCreatedAt
              isDebugMode={debugMode}
              handleDebugMode={actions.toggleDebugMode}
            >
              <div
                style={{ fontSize: isMiniPlayground ? 24 : 32, lineHeight: 1 }}
              >
                {mood[statusText]}
              </div>
            </RunDebugDetails>
            <Typography.Text
              style={{
                fontSize: 12,
                lineHeight: '32px',
                marginLeft: 10,
                color: '#748f9e'
              }}
            >
              {statusText}
            </Typography.Text>
            {statusText === 'Processing' ||
            statusText === 'Preparing Worker' ? (
              <div
                className="loading-container"
                style={{ left: `${progressWidth + 40}px` }}
              >
                <div className={styles.loader} />
              </div>
            ) : null}
          </>
        ) : (
          <div />
        )}
      </div>
      <div className={styles.editorButtons}>
        {/* <Switch
          checkedChildren="Edit Mode"
          unCheckedChildren="Play Mode"
          onChange={onModeChange}
          checked={editMode}
        /> */}
        {!isMiniPlayground && (
          <Typography.Text type="secondary">
            {editorStatus.isExecuting
              ? 'Sent for execution'
              : editorStatus.isSaving
              ? 'Saving...'
              : 'Saved'}
          </Typography.Text>
        )}

        {!editorStatus.readOnly && !isMiniPlayground ? (
          <Tooltip title="Koncierge">
            <Button
              type="text"
              shape="default"
              className={classnames(
                'display-flex align-center justify-center',
                {
                  [styles.activeButton]: isKonciergeVisible
                }
              )}
              onClick={() => {
                trackEvent(
                  editorStatus.showChat
                    ? AnalyticsEvents.PLAYGROUND.PGKonciergeButtonClose
                    : AnalyticsEvents.PLAYGROUND.PGKonciergeButtonOpen,
                  {
                    playgroundId: workerId
                  }
                );
                actions.updateEditorStatus({
                  showChat: !editorStatus.showChat,
                  showOutputPanel: false
                });
              }}
              icon={
                <MessageOutlined style={{ height: '20px', width: '20px' }} />
              }
            />
          </Tooltip>
        ) : null}

        {!editorStatus.isExecuting ? (
          <Tooltip title="Run">
            <Button
              type="text"
              shape="default"
              className="display-flex align-center justify-center"
              onClick={() => actions.onPlay(workerId)}
              icon={<PlaygroundPlayIcon />}
              data-cy="document-run-button"
              size={isMiniPlayground ? 'small' : undefined}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Pause">
            <Button
              type="text"
              shape="default"
              className={classnames(
                'display-flex align-center justify-center',
                {
                  [styles.activeButton]: !editorStatus.readOnly
                }
              )}
              onClick={pauseHandler}
              icon={<PlaygroundPauseIcon />}
              size={isMiniPlayground ? 'small' : undefined}
            />
          </Tooltip>
        )}

        {!isMiniPlayground && isRunPage ? (
          <Tooltip title="Edit Run">
            <Button
              type="text"
              shape="default"
              data-cy="document-edit-button"
              className={classnames(
                'display-flex align-center justify-center',
                {
                  [styles.activeButton]: !editorStatus.readOnly
                }
              )}
              onClick={() => {
                actions.updateEditorStatus({
                  readOnly: !editorStatus.readOnly
                });
              }}
              icon={<EditOutlined style={{ color: '#748F9E' }} />}
            />
          </Tooltip>
        ) : null}

        {!isMiniPlayground ? (
          <AccessControl
            requiredPermissions={[
              ProcessPermissionsEnum.ProcessRead,
              ProcessPermissionsEnum.ProcessCreate,
              ProcessPermissionsEnum.ProcessUpdate
            ]}
            RestrictedPage={null}
          >
            <NewProcedureFromRun
              editorContent={textDeFormatter(editorContent, replacerObj)}
              workerId={worker?.id}
              procedure={procedure}
              isRunPage={isRunPage}
            />
          </AccessControl>
        ) : null}

        {worker?.id && !isMiniPlayground ? (
          <RetryRun workerId={worker.id} isPlayground={!procedure} />
        ) : null}

        {!isMiniPlayground && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {isOutputPanelVisible ? (
              <Tooltip
                title="Close output panel"
                destroyTooltipOnHide
                open={showTooltip}
              >
                <Button
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  type="text"
                  shape="default"
                  className={classnames(
                    'display-flex align-center justify-center',
                    {
                      [styles.activeButton]: isOutputPanelVisible
                    }
                  )}
                  onClick={() => onOutputPanelChange(false)}
                  icon={<OutputPanelCloseIcon />}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title="Open output panel"
                destroyTooltipOnHide
                open={showTooltip}
                placement="topRight"
              >
                <Button
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  type="text"
                  shape="default"
                  className="display-flex align-center justify-center"
                  onClick={() => onOutputPanelChange(true)}
                  icon={<OutputPanelOpenIcon />}
                />
              </Tooltip>
            )}
          </>
        )}

        {isAutoPilotSupported && !isMiniPlayground ? (
          <Switch
            checked={editorStatus.autoPilot}
            onChange={() =>
              actions.updateEditorStatus({ autoPilot: !editorStatus.autoPilot })
            }
            title="Auto-pilot"
            checkedChildren={
              <span className={styles.antSwitchInner}>
                <AutoPilotIcon
                  style={{ marginTop: 2 }}
                  width={18}
                  height={18}
                  fill="#ffffff"
                />
              </span>
            }
            unCheckedChildren={
              <span className={styles.antSwitchInner}>
                <AutoPilotIcon
                  style={{ marginTop: -1 }}
                  width={18}
                  height={18}
                  fill="#C6D1D7"
                />
              </span>
            }
          />
        ) : null}
      </div>
    </div>
  );
}

export default EditorHeader;
