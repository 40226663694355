// We shouldn't use error message for retrying, either use errorType or status code
export const shouldRetryQueryOnCommonErrors = (e: any) => {
  const message = (e?.message || '').toLowerCase();

  const conditions = [
    message.includes('network error'),
    message.includes('failed to fetch'),
    message.includes('status code 429'),
    message.includes('status code 503'),
    message.includes('status code 504'),
    message.includes('too many requests')
  ];

  return conditions.some(Boolean);
};
