// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentExceptionCanvas-module__documentCanvas__vYOt8 {
  position: relative;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/exception/document-exception/DocumentExceptionCanvas.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".documentCanvas {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentCanvas": `DocumentExceptionCanvas-module__documentCanvas__vYOt8`
};
export default ___CSS_LOADER_EXPORT___;
