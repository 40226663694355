import { Menu } from 'antd';
import React from 'react';
import styles from './SupportMenu.module.less';

interface UserMenuProps {
  hide: () => void;
}

function SupportMenu(props: UserMenuProps) {
  const menu = (
    <Menu className={styles.supportMenu}>
      <Menu.Item
        title=""
        className={styles.supportMenuItem}
        key="self-help"
        data-cy="menu-self-help"
        onClick={() => {
          props.hide();
          // eslint-disable-next-line no-underscore-dangle
          (window as any)?._wfx_widget_open?.();
        }}
      >
        <span className={styles.supportMenuItemContent}>Self Help</span>
      </Menu.Item>
      <Menu.Item
        title=""
        className={styles.supportMenuItem}
        key="share-feedback"
        data-cy="share-feedback"
        onClick={() => {
          props.hide();
          (window as any).showFeedBackSurvey = true;
          // eslint-disable-next-line no-underscore-dangle
          (window as any)?._wfx_refresh?.('survey');
          setTimeout(() => {
            (window as any).showFeedBackSurvey = true;
          }, 3000);
        }}
      >
        <span className={styles.supportMenuItemContent}>Share Feedback</span>
      </Menu.Item>
    </Menu>
  );
  return <div className={styles.accountMenu}>{menu}</div>;
}

export default SupportMenu;
