import { FastAverageColor } from 'fast-average-color';

const fac = new FastAverageColor();

export const getAvgColor = (img: HTMLImageElement): Promise<string> => {
  try {
    return fac
      .getColorAsync(img, {
        silent: true
      })
      .then((color) => {
        return color.rgba;
      })
      .catch(() => {
        return '';
      });
  } catch (e) {
    return Promise.resolve('');
  }
};

export const debouncedAction = (
  func: Function,
  wait: number
): ((...args: any[]) => any) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  return (...args: any[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
      timeout = null;
    }, wait);
  };
};
