/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import {
  IQuestionValues,
  QuestionInputType
} from '../components/request/interface';
import {
  AnswerAction,
  CreateAnswerActionInput,
  CreateRequestAnswerInput,
  LearnedAnswer,
  Procedure,
  Request,
  RequestAnswer,
  RequestQuestion,
  UseConceptAction,
  UseLiteralValueAction,
  UseTechniqueAction,
  ValueNotKnownQuestion,
  Worker
} from '../generated/API';
import FormattingUtil from './FormattingUtil';
import AppUtil from './AppUtil';

export function verifyQuestionValues(values: IQuestionValues) {
  let valid = false;

  switch (values.inputType) {
    case QuestionInputType.VALUE:
      valid = !!values.value;
      break;
    case QuestionInputType.VALUE_UPLOAD:
      valid = !!values.upload;
      break;
    case QuestionInputType.VALUE_CHOICE:
      valid = values.choice !== undefined;
      break;
    case QuestionInputType.TECHNIQUE:
      valid = !!values.technique;
      break;
    case QuestionInputType.VALUE_EMPTY:
    case QuestionInputType.NO_VALUE:
    case QuestionInputType.SKIP:
    case QuestionInputType.SKIP_RUN_END:
    case QuestionInputType.RETRY:
    case QuestionInputType.APPROVE_REVIEW:
      valid = true;
      break;
    case QuestionInputType.RETRY_AFTER_INTERVAL:
      if (values.retryDelayDateTime) {
        valid = true;
      }
      break;
    case QuestionInputType.DOCUMENT_FIELDS:
      if (values.documentFields?.length) {
        valid = true;
      }
      break;
    default:
      break;
  }

  return valid;
}

export function prepareAnswerActionInput(
  values: IQuestionValues
): CreateAnswerActionInput {
  const response: CreateAnswerActionInput = {};

  switch (values.inputType) {
    case QuestionInputType.VALUE: {
      response.useLiteralValueAction = {
        literalValue: FormattingUtil.encodeBrainValue(
          values.value || ''
        ) as string
      };
      break;
    }

    case QuestionInputType.VALUE_UPLOAD: {
      const upload = values.upload!;
      let value;

      if (upload.length > 1) {
        const s3urlList = upload.map((uploadResponse) => uploadResponse.s3Url);
        value = JSON.stringify(s3urlList);
      } else {
        value = FormattingUtil.encodeBrainValue(upload[0].s3Url) as string;
      }
      response.useLiteralValueAction = {
        literalValue: value as any
      };
      break;
    }

    case QuestionInputType.VALUE_EMPTY: {
      response.useLiteralValueAction = {
        literalValue: FormattingUtil.encodeBrainValue('-') as string
      };
      break;
    }

    case QuestionInputType.VALUE_CHOICE: {
      response.useLiteralValueAction = {
        literalValue: JSON.stringify(values.choice)
      };
      break;
    }

    case QuestionInputType.NO_VALUE: {
      response.useNoValueAction = {};
      break;
    }

    case QuestionInputType.TECHNIQUE: {
      response.useTechniqueAction = {
        technique: FormattingUtil.encodeBrainValue(values.technique) as string
      };
      break;
    }

    case QuestionInputType.SKIP: {
      response.skipStepAction = {};
      break;
    }

    case QuestionInputType.SKIP_RUN_END: {
      response.skipToEndOfRunAction = {};
      break;
    }

    case QuestionInputType.RETRY: {
      response.retryStepAction = {
        minimumDelay: 0
      };
      break;
    }

    case QuestionInputType.RETRY_AFTER_INTERVAL: {
      const secondsInAMin = 60;
      let minDelay = 0;
      let timeout;
      if (values.retryDelayDateTime) {
        const end = values.retryDelayDateTime;
        const start = new Date();
        const diff = end.diff(start);
        const duration = moment.duration(diff);
        minDelay = Math.ceil(duration.asSeconds());
      }
      if (values.retryDelayDateTime && values.retryTimeoutMinutes) {
        timeout = values.retryTimeoutMinutes * secondsInAMin;
      }
      response.retryStepAction = {
        minimumDelay: minDelay < 0 ? 0 : minDelay,
        timeout
      };
      break;
    }

    case QuestionInputType.APPROVE_REVIEW: {
      response.approveReviewAction = {};
      break;
    }

    case QuestionInputType.DOCUMENT_FIELDS: {
      const documentFields = values.documentFields!;
      let value;

      if (documentFields.length > 1) {
        const fieldsList = documentFields.map(
          (uploadResponse) => uploadResponse.text
        );
        value = JSON.stringify(fieldsList);
      } else {
        value = FormattingUtil.encodeBrainValue(
          documentFields[0].text
        ) as string;
      }
      response.useLiteralValueAction = {
        literalValue: value as any
      };
      break;
    }

    default: {
      break;
    }
  }

  return response;
}

export function prepareAnswerInput(
  request: Request,
  question: RequestQuestion,
  values: IQuestionValues
): CreateRequestAnswerInput {
  const response: CreateRequestAnswerInput = {
    requestId: request.id,
    questionId: question.id,
    action: prepareAnswerActionInput(values)
  };

  return response;
}

export function getQuestionInputTypeFromAnswerAction(
  action: AnswerAction
): QuestionInputType {
  const ACTION_QUESTION_INPUT_TYPE_MAP: Record<string, QuestionInputType> = {
    UseLiteralValueAction: QuestionInputType.VALUE,
    UseTechniqueAction: QuestionInputType.TECHNIQUE,
    ApproveReviewAction: QuestionInputType.APPROVE_REVIEW,
    RetryStepAction: QuestionInputType.RETRY,
    SkipStepAction: QuestionInputType.SKIP,
    SkipToEndOfRunAction: QuestionInputType.SKIP_RUN_END,
    UseConceptAction: QuestionInputType.CONCEPT,
    UseNoValueAction: QuestionInputType.NO_VALUE
  };

  return (
    ACTION_QUESTION_INPUT_TYPE_MAP[action?.__typename] || QuestionInputType.NONE
  );
}

export function getQuestionInputTypeFromAnswer(
  answer: RequestAnswer
): QuestionInputType {
  let inputType: QuestionInputType = QuestionInputType.NONE;

  if (answer?.action) {
    inputType = getQuestionInputTypeFromAnswerAction(answer.action);
  }

  return inputType;
}

export function getQuestionInputValueFromAnswer(
  answer: RequestAnswer | LearnedAnswer,
  placeholder: boolean = false
) {
  let value = '';

  if (answer) {
    const action = answer.action as any;
    const actionType = action?.__typename;

    switch (actionType) {
      case 'UseLiteralValueAction': {
        const literalValueAction = action as UseLiteralValueAction;

        if (literalValueAction.choices && literalValueAction.literalValue) {
          const { choices } = JSON.parse(literalValueAction.choices[0]) || {};
          return choices[literalValueAction.literalValue];
        }

        value = FormattingUtil.parseBrainValue(
          literalValueAction.literalValue || ''
        );
        break;
      }
      case 'UseTechniqueAction': {
        value = FormattingUtil.parseBrainValue(
          (action as UseTechniqueAction).technique || ''
        );
        break;
      }
      case 'UseConceptAction': {
        value = (action as UseConceptAction).conceptId || '';
        break;
      }
      case 'ApproveReviewAction': {
        if (placeholder) {
          value = '<approve>';
        }
        break;
      }
      case 'RetryStepAction': {
        if (placeholder) {
          value = '<retry>';
        }
        break;
      }
      case 'SkipStepAction': {
        if (placeholder) {
          value = '<skip>';
        }
        break;
      }
      case 'SkipToEndOfRunAction': {
        if (placeholder) {
          value = '<stop>';
        }
        break;
      }
      case 'UseNoValueAction': {
        if (placeholder) {
          value = '<empty>';
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  return value;
}

export function isQuestionAnswered(
  question: RequestQuestion,
  answers: RequestAnswer[]
) {
  return answers.some((answer) => answer.questionId === question.id);
}

export function getQuestionAnswer(
  question: RequestQuestion,
  answers: RequestAnswer[]
) {
  return answers.find((answer) => answer.questionId === question.id);
}

export function getPendingQuestions(requests: Request[]) {
  const questions = requests.reduce<RequestQuestion[]>(
    (acc, cur) => acc.concat(cur.questions!),
    []
  );
  const answers = requests.reduce<RequestAnswer[]>(
    (acc, cur) => acc.concat(cur.answers!),
    []
  );
  return questions.filter((question) => {
    return !isQuestionAnswered(question, answers);
  });
}

export function isRequestHandled(request: Request) {
  return request.state === 'handled';
}

export function areAllRequestsHandled(requests: Request[]) {
  return requests.every(isRequestHandled);
}

export function prepareDocumentExceptionTitle(
  worker: Worker,
  procedure?: Procedure
) {
  if (procedure) {
    const procedureRun = moment(worker.createdAt).format('lll');
    return `${AppUtil.removeToFromName(procedure?.name)} / ${procedureRun}`;
  }

  return worker.name || 'Untitled Playground';
}

export function isRequestPlural(request: Request): boolean {
  return (request.questions || []).some((question) => {
    const type = question.__typename as any;
    return (
      type === 'ValueNotKnownQuestion' &&
      (question as unknown as ValueNotKnownQuestion).isPlural
    );
  });
}
