import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  Book,
  ForgetBooksMutation,
  ForgetBooksMutationVariables,
  LearnBooksMutation,
  LearnBooksMutationVariables,
  ListBooksByDepartmentQuery,
  ListBooksByDepartmentQueryVariables
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Queries from '@/graphql/Queries';
import { FetchResult } from '@apollo/client';
import Mutations from '@/graphql/Mutations';

export interface GetBooksByDepartmentParams
  extends ListBooksByDepartmentQueryVariables {
  page?: number;
  background?: boolean;
}

export const getBooksByDepartment = createAsyncThunk<
  FetchResult<ListBooksByDepartmentQuery>,
  GetBooksByDepartmentParams,
  AsyncThunkConfig
>(
  'books/department/fetchAll',
  async ({ departmentId, version, limit, nextToken }, thunkAPI) =>
    thunkAPI.extra.appSyncClient.query({
      query: Queries.ListBooksByDepartment(),
      variables: {
        departmentId,
        version,
        limit,
        nextToken
      }
    }),
  {
    serializeError: (x: any) => x
  }
);

export const learnBooks = createAsyncThunk<
  FetchResult<LearnBooksMutation>,
  LearnBooksMutationVariables,
  AsyncThunkConfig
>('book/learn', async ({ ids, departmentId }, thunkAPI) => {
  const resp = await thunkAPI.extra.appSyncClient.mutate({
    mutation: Mutations.LearnBooks(),
    variables: {
      ids,
      departmentId
    }
  });

  return resp;
});

export const forgetBooks = createAsyncThunk<
  FetchResult<ForgetBooksMutation>,
  ForgetBooksMutationVariables,
  AsyncThunkConfig
>('book/forget', async ({ ids, departmentId }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.mutate({
    mutation: Mutations.ForgetBooks(),
    variables: {
      ids,
      departmentId
    }
  })
);

const BooksAdapter = createEntityAdapter<Book>({
  selectId: (book) => book.id!
});

interface IState {
  loading: boolean;
  error: any;
  nextToken: string | undefined;
  pageDataMap: Record<number, Book[]>;
}

export const booksSlice = createSlice({
  name: 'books',
  initialState: BooksAdapter.getInitialState<IState>({
    loading: false,
    error: undefined,
    nextToken: undefined,
    pageDataMap: {}
  }),
  reducers: {
    updateBook: BooksAdapter.updateOne
  },
  extraReducers: (builder) => {
    builder.addCase(getBooksByDepartment.pending, (state, { meta }) => {
      if (!meta.arg.background) {
        state.loading = true;
      }
    });

    builder.addCase(
      getBooksByDepartment.fulfilled,
      (state, { payload, meta }) => {
        if (payload?.data) {
          const data: any = payload.data;
          const { items = [], nextToken } = data.listBooksByDepartment;

          const books = items as Book[];

          if (!meta.arg.nextToken) {
            BooksAdapter.setAll(state, books);
          } else {
            BooksAdapter.addMany(state, books);
          }

          if (meta.arg.page && books.length > 0) {
            state.pageDataMap[meta.arg.page] = books;
          }

          state.nextToken = nextToken || undefined;
        }

        if (!meta.arg.background) {
          state.loading = false;
        }
      }
    );

    builder.addCase(
      getBooksByDepartment.rejected,
      (state, { payload, meta }) => {
        state.error = payload as any;
        if (!meta.arg.background) {
          state.loading = false;
        }
      }
    );

    // this block is not need because we are fetch all books after learning
    // builder.addCase(learnBook.fulfilled, (state, action) => {
    //   state.loading = false;
    //   const books = (action.payload.data?.learnBooks ?? []).map(
    //     ({ name, ...rest }) => ({ name, ...rest })
    //   );
    //   BooksAdapter.setMany(state, books);
    // });
  }
});

export const { updateBook } = booksSlice.actions;

export const booksSelectors = BooksAdapter.getSelectors<RootState>(
  (state) => state.books
);
export const booksQuerySelector = (state: RootState) => ({
  loading: state.books.loading,
  books: booksSelectors.selectAll(state),
  error: state.books.error,
  nextToken: state.books.nextToken,
  pageDataMap: state.books.pageDataMap
});

export default booksSlice.reducer;
