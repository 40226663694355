// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 24px;
}
.ant-layout-sider-collapsed .app-logo {
  color: #04a7fb;
  fill: #04a7fb;
  display: flex;
}
.ant-layout-sider-collapsed .app-logo .anticon svg {
  width: 50px !important;
  min-height: 50px !important;
}
.app-logo {
  color: #04a7fb;
  fill: #04a7fb;
  display: flex;
}
.app-logo .anticon svg {
  min-height: 50px !important;
}
.bottom-menu {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.bottom-menu .b-menu-item.ant-menu-item-selected {
  background-color: #252066 !important;
}
.bottom-menu-item {
  display: flex;
  justify-content: space-between;
}
.env-container {
  display: flex;
  justify-content: center;
}
.env-container .ant-tag {
  margin-right: 0;
  max-width: 90%;
  margin-bottom: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/views/AppLeftNav.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;AADF;AAIA;EACE,cAAA;EACA,aAAA;EACA,aAAA;AAFF;AADA;EAOM,sBAAA;EACA,2BAAA;AAHN;AAQA;EACE,cAAA;EACA,aAAA;EACA,aAAA;AANF;AAGA;EAQM,2BAAA;AARN;AAaA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;AAXF;AAQA;EAMI,oCAAA;AAXJ;AAeA;EACE,aAAA;EACA,8BAAA;AAbF;AAgBA;EACE,aAAA;EACA,uBAAA;AAdF;AAYA;EAII,eAAA;EACA,cAAA;EACA,mBAAA;AAbJ","sourcesContent":["// AppHeader View CSS\n@import '@css/variables.less';\n.logo-link {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 20px 0px 24px;\n}\n\n.ant-layout-sider-collapsed .app-logo {\n  color: @header-logo-color;\n  fill: @header-logo-color;\n  display: flex;\n\n  .anticon {\n    svg {\n      width: 50px !important;\n      min-height: 50px !important;\n    }\n  }\n}\n\n.app-logo {\n  color: @header-logo-color;\n  fill: @header-logo-color;\n  display: flex;\n\n  .anticon {\n    svg {\n      // width: 50px !important;\n      min-height: 50px !important;\n    }\n  }\n}\n\n.bottom-menu {\n  position: absolute;\n  bottom: 0px;\n  width: 100%;\n\n  .b-menu-item.ant-menu-item-selected {\n    background-color: #252066 !important;\n  }\n}\n\n.bottom-menu-item {\n  display: flex;\n  justify-content: space-between;\n}\n\n.env-container {\n  display: flex;\n  justify-content: center;\n  .ant-tag {\n    margin-right: 0;\n    max-width: 90%;\n    margin-bottom: 12px;\n  }\n}\n\n// .ant-layout-sider-children {\n//   .b-menu-item.ant-menu-item {\n//     .ant-icon {\n//       svg {\n//         path {\n//           fill: #bfbfbf;\n//         }\n//       }\n//     }\n//   }\n// }\n// .ant-layout-sider-children .b-menu-item.ant-menu-item-selected svg path {\n//   fill: #fff !important;\n// }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
