import { Button, Result } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRefresh } from '@/provider/refresh';
import { CreateDepartment } from '../popups/CreateDepartmentPopup';
import { userSelector } from '../stores/slices/user';
import AppUtil from '../utils/AppUtil';
import Container from './Container';

function DepartmentNotFound() {
  const { username, organization } = useSelector(userSelector);
  const canCreateDepartment = AppUtil.canCreateDepartment(
    username,
    organization.data
  );

  const { refresh } = useRefresh();

  if (canCreateDepartment) {
    return <CreateDepartment />;
  }

  return (
    <Container>
      <div data-cy="department-not-found">
        <Result
          status="403"
          title="Department Not Found"
          subTitle={`Looks like you're not part of any department. Please contact your admin.`}
          extra={
            <Button
              type="primary"
              onClick={() => {
                refresh();
              }}
            >
              Refresh
            </Button>
          }
        />
      </div>
    </Container>
  );
}

export default DepartmentNotFound;
