// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RichTextEditor-module__widgetOptionsContainer__C6Jfc {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: 1;
  padding: 3px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 650px;
}
.RichTextEditor-module__widgetOptionsList__Ib6Jy {
  padding: 0;
  margin: 0;
  list-style: none;
}
.RichTextEditor-module__widgetOptionsItem__dTUH7 {
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}
.RichTextEditor-module__widgetOptionsItem__dTUH7:hover {
  background-color: #00000010;
}
.RichTextEditor-module__widgetOptionsItemActive__NglnN {
  background-color: #00000010;
}
.RichTextEditor-module__newLine__QQUuJ {
  position: absolute;
  left: 76px;
  top: calc(100% + 3px);
  right: 0;
  height: 25px;
  display: flex;
  align-items: center;
  background-color: #f8fbfc;
}
.RichTextEditor-module__newLine__QQUuJ .RichTextEditor-module__iconContainer__aLVKI {
  position: absolute;
  left: -10px;
  color: #adc3cd;
  cursor: pointer;
}
.RichTextEditor-module__newLine__QQUuJ .RichTextEditor-module__newLineText__jbDmQ {
  font-family: 'Source Code Pro', monospace;
  padding-left: 17px;
  color: #adc3cd;
}
.RichTextEditor-module__newLine-MINI_PLAYGROUND__OQCE4 {
  left: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PlaygroundV3/components/RichTextEditor/RichTextEditor.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,wCAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;AACF;AAEA;EACE,UAAA;EACA,SAAA;EACA,gBAAA;AAAF;AAGA;EACE,aAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,iCAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;AADF;AAGE;EACE,2BAAA;AADJ;AAKA;EACE,2BAAA;AAHF;AAMA;EACE,kBAAA;EACA,UAAA;EACA,qBAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;AAJF;AAJA;EAUI,kBAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;AAHJ;AAVA;EAgBI,yCAAA;EACA,kBAAA;EACA,cAAA;AAHJ;AAOA;EACE,UAAA;AALF","sourcesContent":[".widgetOptionsContainer {\n  top: -9999px;\n  left: -9999px;\n  position: absolute;\n  z-index: 1;\n  padding: 3px;\n  background: white;\n  border-radius: 4px;\n  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);\n  display: flex;\n  flex-direction: column;\n  text-align: left;\n  max-width: 650px;\n}\n\n.widgetOptionsList {\n  padding: 0;\n  margin: 0;\n  list-style: none;\n}\n\n.widgetOptionsItem {\n  display: flex;\n  width: 100%;\n  overflow: hidden;\n  border-radius: 5px;\n  font-family: 'Nunito', sans-serif;\n  font-weight: 600;\n  font-size: 14px;\n  cursor: pointer;\n\n  &:hover {\n    background-color: #00000010;\n  }\n}\n\n.widgetOptionsItemActive {\n  background-color: #00000010;\n}\n\n.newLine {\n  position: absolute;\n  left: 76px;\n  top: calc(100% + 3px);\n  right: 0;\n  height: 25px;\n  display: flex;\n  align-items: center;\n  background-color: #f8fbfc;\n  .iconContainer {\n    position: absolute;\n    left: -10px;\n    color: #adc3cd;\n    cursor: pointer;\n  }\n  .newLineText {\n    font-family: 'Source Code Pro', monospace;\n    padding-left: 17px;\n    color: #adc3cd;\n  }\n}\n\n.newLine-MINI_PLAYGROUND {\n  left: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetOptionsContainer": `RichTextEditor-module__widgetOptionsContainer__C6Jfc`,
	"widgetOptionsList": `RichTextEditor-module__widgetOptionsList__Ib6Jy`,
	"widgetOptionsItem": `RichTextEditor-module__widgetOptionsItem__dTUH7`,
	"widgetOptionsItemActive": `RichTextEditor-module__widgetOptionsItemActive__NglnN`,
	"newLine": `RichTextEditor-module__newLine__QQUuJ`,
	"iconContainer": `RichTextEditor-module__iconContainer__aLVKI`,
	"newLineText": `RichTextEditor-module__newLineText__jbDmQ`,
	"newLine-MINI_PLAYGROUND": `RichTextEditor-module__newLine-MINI_PLAYGROUND__OQCE4`
};
export default ___CSS_LOADER_EXPORT___;
