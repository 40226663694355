import { gql } from '@apollo/client';

export const GET_WORKER_DOCUMENT = gql`
  query getWorkerDocument($workerId: ID!, $documentToken: String) {
    getWorkerDocument(workerId: $workerId, documentToken: $documentToken) {
      lineId
      text
      parentId
      metadata {
        lineId
        parentId
      }
      internalMetadata {
        key
        value
      }
    }
  }
`;

export const GET_SENTENCE_EXECUTION_DATA = gql`
  query getSentenceExecutionData(
    $workerId: ID!
    $token: String
    $documentToken: String
  ) {
    getSentenceExecutionData(
      workerId: $workerId
      iterationToken: $token
      documentToken: $documentToken
    ) {
      lineId
      token
      status
      answer
      concepts
      requests
      # subprocedureId
      iterationTokens
      epoch
      subDocuments {
        documentToken
        processName
      }
      miniPlaygrounds {
        documentToken
        processName
      }
      startedAt
      completedAt
    }
  }
`;

export const GET_LOOP_MANAGER = gql`
  query {
    getLoopManager @client {
      data
    }
  }
`;

export const GET_WORKER_MAP = gql`
  query {
    getWorkerMap @client {
      idMap
      reverseIdMap
    }
  }
`;

export const GET_DEPARTMENT_ID_FOR_WORKER = gql`
  query GetDepartmentForWorker($workerId: ID!) {
    getDepartmentForWorker(workerId: $workerId) {
      id
    }
  }
`;

export const GET_LOCAL_DOCUMENT_PREVIEW = gql`
  query {
    getLocalDocumentPreview @client {
      data
    }
  }
`;

export const GET_LOG_MANAGER = gql`
  query {
    getLogManager @client {
      data
    }
  }
`;
