import { gql } from '@apollo/client';

export const STORE_INTEGRATION_CREDENTIAL = gql`
  mutation StoreIntegrationCredential($input: CredentialValueInput!) {
    storeIntegrationCredential(input: $input) {
      integrationId
      departmentId
      lastChangedAt
      lastChangedBy
      lastValidatedAt
      lastValidationStatus
      lastValidationMessage
      isCredentialProvided
      redirectionFlowRequired
      isRedirectionFlowDone
    }
  }
`;

export const LEARN_BOOKS = gql`
  mutation LearnBooks($ids: [ID!]!, $departmentId: ID!) {
    learnBooks(ids: $ids, departmentId: $departmentId) {
      id
      name
    }
  }
`;

export const REMOVE_CREDENTIAL = gql`
  mutation RemoveIntegrationCredential(
    $integrationId: ID!
    $departmentId: ID!
  ) {
    removeIntegrationCredential(
      integrationId: $integrationId
      departmentId: $departmentId
    ) {
      integrationId
    }
  }
`;

export const FORGET_BOOKS = gql`
  mutation ForgetBooks($ids: [ID!]!, $departmentId: ID!) {
    forgetBooks(ids: $ids, departmentId: $departmentId) {
      id
    }
  }
`;

export const GENERATE_AUTHORIZATION_URL = gql`
  mutation GenerateIntegrationAuthorizationUrl(
    $integrationId: ID!
    $departmentId: ID!
    $workerId: ID
    $procedureId: ID
  ) {
    generateIntegrationAuthorizationUrl(
      integrationId: $integrationId
      departmentId: $departmentId
      workerId: $workerId
      procedureId: $procedureId
    ) {
      url
    }
  }
`;

export const STORE_INTEGRATION_REDIRECTION_FLOW_DATA = gql`
  mutation StoreIntegrationRedirectionFlowCredential(
    $departmentId: ID!
    $integrationId: ID!
    $redirectURIParams: String
  ) {
    storeIntegrationRedirectionFlowCredential(
      input: {
        departmentId: $departmentId
        integrationId: $integrationId
        redirectURIParams: $redirectURIParams
      }
    ) {
      integrationId
      departmentId
      lastChangedAt
      lastChangedBy
      lastValidatedAt
      lastValidationStatus
      lastValidationMessage
      isCredentialProvided
      redirectionFlowRequired
      isRedirectionFlowDone
    }
  }
`;
