const data = [
  {
    id: 1,
    name: 'Invoice processing',
    questions: [
      {
        stepPath: ['get the document'],
        sampleFile: '/self-serve-templates/invoice_processing_quickstart.pdf',
        sampleFileName: 'invoice_processing_quickstart.pdf',
        sampleFileType: 'application/pdf'
      }
    ],
    description:
      'This automation extracts invoice details, compiles them in an Excel sheet, and sends the data to an email.',
    text: `get the document

get the document as a file
find the file's filename

find the document's invoice id as the invoice id
find the document's invoice number as the invoice number
find the document's invoice date as the invoice date
find the document's vendor name as the vendor name
find the document's invoice total as the invoice total
find the document's total tax as the total tax
find the document's payment term as the payment term

the currency is "USD"

if the payment term is not found then
 ask koncierge for the payment term
  the task is "Find and print only the payment term information from the below invoice. Some examples are: Net 30 days, Due 45 days, Due on receipt, Due on MM/DD/YYYY __KOG_KONCIERGE_NEW_LINE__ {the document's lines}"

find the document's first table
get the above as the items table

convert the items table to an excel
send an email to user email
 the subject is "Extracted Document Data"
 the message is "Hello! Here are your extracted fields! __KOG_NEW_LINE__The invoice number is: {the invoice id} __KOG_NEW_LINE__The invoice date is: {the invoice date} __KOG_NEW_LINE____KOG_NEW_LINE__Attached you will see the extracted line items from your invoice! Thanks,Have a great day!"
 the attachment is the excel
 the attachment name is "Line_Items.xlsx"`
  },
  {
    id: 2,
    name: 'Contract template',
    questions: [
      {
        stepPath: ['get the document as the contract'],
        sampleFile: '/self-serve-templates/legal_contract.pdf',
        sampleFileName: 'legal_contract.pdf',
        sampleFileType: 'application/pdf'
      }
    ],
    description:
      'This automation extracts various fields from a legal contract, demonstrating how to integrate OpenAI into Kognitos.',
    exceptionAtLine: 'get the document as the contract',
    exceptionFile: '',
    text: `get the document as the contract
get the document's lines as the lines

search "financial risk of termination, penalty, amount" in the document
get the answer with citations
use those as the search results

get the contract's effective date as the effective date
ask koncierge for the reformatted date
 the openai model is "gpt-4"
 the task is "{the effective date} is a date. Can you reformat this date to look like MM.DD.YYYY? Only return the newly reformatted date. Nothing else"

ask koncierge for the payment timeline
 the openai model is "gpt-4"
 the task is "{the lines}__KOG_KONCIERGE_NEW_LINE__The above text is text from a sales contract. How many days does a client have to pay an invoice? just return the number of days, nothing else!"

ask koncierge for the termination risk
 the openai model is "gpt-4"
 the question is "Write a one sentence concise analysis on how much money the vendor will lose if the contract is terminated today. Providing citations in json format given below. __KOG_NEW_LINE__ {the search results}"

send an email to the user email
 the message is "{the termination risk}"
 the subject is "Contract Example"`
  },
  {
    id: 3,
    name: 'Excel automation',
    questions: [
      {
        stepPath: ['get the file'],
        sampleFile: '/self-serve-templates/complicated_sheet.xlsx',
        sampleFileName: 'complicated_sheet.xlsx',
        sampleFileType:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    ],
    description:
      'This automation reads data from a spreadsheet, extracts a table, filters rows based on a criteria and sends it to an email.',
    exceptionAtLine: 'get the file',
    exceptionFile: '',
    text: `get the file
open the sheet at the file

get the sheet's table whose first header is "Product Name" and whose last header is "Total Amount"
get the above table as the product table

get the sheet's table whose first header is "QA NUMBER" and whose last header is "COST CENTER"
get the above table as the cost table

remove the product table's rows whose Payer Number is ""

rename the product table's "Region" column to "Region Code"
the product table

get the cost table's first column's values
get the above as the qa numbers

convert the product table to an excel
send an email to user@email.com
 the message is "Hi! The Cost Centers we will be working with are: {the qa numbers}.  Attached you'll find an Excel with the product information!Thanks!Kognitos"
 the subject is "New Product Sheet"
 the attachment is the excel
 the attachment's name is "Products.xlsx"`
  }
  //   {
  //     id: 4,
  //     name: 'Excel automation',
  //     description:
  //       'this is some dummy descriptions text, lorem ipsum, dum dum dum dum',
  //     exceptionAtLine: 'get the file',
  //     exceptionFile: '',
  //     text: `get the file
  // open the sheet at the file

  // get the sheet's table whose first header is "Product Name" and whose last header is "Total Amount"
  // get the above table as the product table

  // get the sheet's table whose first header is "QA NUMBER" and whose last header is "COST CENTER"
  // get the above table as the cost table

  // remove the product table's rows whose Payer Number is ""

  // rename the product table's "Region" column to "Region Code"
  // the product table

  // get the cost table's first column's values
  // get the above as the qa numbers

  // convert the product table to an excel
  // send an email to user@email.com
  //   the message is """Hi! The Cost Centers we will be working with are: {the qa numbers}.  Attached you'll find an Excel with the product information!Thanks!Kognitos"""
  //   the subject is "New Product Sheet"
  //   the attachment is the excel
  //   the attachment's name is "Products.xlsx"`
  //   }
];

export default data;
