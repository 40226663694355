/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      skippedAt
      processedAt
      failedAt
      workStatus
      workErrors
      commandId
      questionId
      type
      owner
      readers
      editors
      name
      description
      text
      error
      remember
      secret
    }
  }
`;
export const listAnswersByCommand = /* GraphQL */ `
  query ListAnswersByCommand(
    $commandId: ID
    $limit: Int = 100
    $nextToken: String
  ) {
    listAnswersByCommand(
      commandId: $commandId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workerId
        createdAt
        forwardedAt
        invokedAt
        receivedAt
        processingAt
        skippedAt
        processedAt
        failedAt
        workStatus
        workErrors
        commandId
        questionId
        type
        owner
        readers
        editors
        name
        description
        text
        error
        remember
        secret
      }
      nextToken
    }
  }
`;
export const listBooksByKnowledge = /* GraphQL */ `
  query ListBooksByKnowledge(
    $knowledgeId: ID!
    $limit: Int = 100
    $nextToken: String
  ) {
    listBooksByKnowledge(
      knowledgeId: $knowledgeId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        name
        author
        description
        learnedAt
        commandId
        version
      }
      nextToken
    }
  }
`;
export const listBooksByDepartment = /* GraphQL */ `
  query ListBooksByDepartment(
    $departmentId: ID!
    $version: String
    $limit: Int = 100
    $nextToken: String
  ) {
    listBooksByDepartment(
      departmentId: $departmentId
      version: $version
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        name
        author
        description
        learnedAt
        commandId
        version
      }
      nextToken
    }
  }
`;
export const getCommand = /* GraphQL */ `
  query GetCommand($id: ID!) {
    getCommand(id: $id) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      skippedAt
      processedAt
      failedAt
      workStatus
      workErrors
      text
      owner
      readers
      editors
      name
      description
      subText
      subTextProperties
      subTextLanguage
      procedureId
      contextId
      sentenceId
      debug
      ast
      state
      output
      source
      sourceMetadata
      positionInContext
      isMultilineText
      useAutopilot
      questions {
        nextToken
      }
      answers {
        nextToken
      }
      notifications {
        nextToken
      }
    }
  }
`;
export const listCommandsByWorker = /* GraphQL */ `
  query ListCommandsByWorker(
    $workerId: ID
    $limit: Int = 100
    $nextToken: String
  ) {
    listCommandsByWorker(
      workerId: $workerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workerId
        createdAt
        forwardedAt
        invokedAt
        receivedAt
        processingAt
        skippedAt
        processedAt
        failedAt
        workStatus
        workErrors
        text
        owner
        readers
        editors
        name
        description
        subText
        subTextProperties
        subTextLanguage
        procedureId
        contextId
        sentenceId
        debug
        ast
        state
        output
        source
        sourceMetadata
        positionInContext
        isMultilineText
        useAutopilot
      }
      nextToken
    }
  }
`;
export const getContextsForStep = /* GraphQL */ `
  query GetContextsForStep($workerId: ID!, $contextId: ID, $stepId: ID) {
    getContextsForStep(
      workerId: $workerId
      contextId: $contextId
      stepId: $stepId
    ) {
      items {
        id
        workerId
        createdAt
        depth
        commandId
        concepts
      }
    }
  }
`;
export const exportCodeForContext = /* GraphQL */ `
  query ExportCodeForContext($workerId: ID!, $contextId: ID) {
    exportCodeForContext(workerId: $workerId, contextId: $contextId)
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      owner
      createdAt
      updatedAt
      billingStatus
    }
  }
`;
export const getOwnerOrganization = /* GraphQL */ `
  query GetOwnerOrganization($owner: String) {
    getOwnerOrganization(owner: $owner) {
      id
      name
      owner
      createdAt
      updatedAt
      billingStatus
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      owner
      organizationId
      readers
      editors
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      upgradeCommandId
      notifyProcedureInvocation
      features
      brainVersion
      status
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
        features
      }
      organization {
        id
        name
        owner
        createdAt
        updatedAt
        billingStatus
      }
      draftKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      publishedKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      requests {
        limit
        nextToken
      }
      learnedAnswers {
        nextToken
      }
    }
  }
`;
export const getDepartmentHistory = /* GraphQL */ `
  query GetDepartmentHistory(
    $departmentId: ID!
    $entityName: DepartmentBookEntity
    $entityIDs: [String!]
    $start: String
    $end: String
    $limit: Int = 10
    $nextToken: String
  ) {
    getDepartmentHistory(
      departmentId: $departmentId
      entityName: $entityName
      entityIDs: $entityIDs
      start: $start
      end: $end
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        entityId
        user
        operation
        entityName
        timestamp
        name
        rollbackVersion
      }
      nextToken
    }
  }
`;
export const listAllDepartments = /* GraphQL */ `
  query ListAllDepartments(
    $limit: Int = 1000
    $nextToken: String
    $deptFilter: ListDepartmentsFilter
  ) {
    listAllDepartments(
      limit: $limit
      nextToken: $nextToken
      deptFilter: $deptFilter
    ) {
      items {
        id
        owner
        organizationId
        readers
        editors
        name
        draftKnowledgeId
        publishedKnowledgeId
        workerId
        local
        state
        errors
        createdAt
        isDeleted
        upgradeCommandId
        notifyProcedureInvocation
        features
        brainVersion
        status
      }
      nextToken
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $limit: Int = 1000
    $nextToken: String
    $deptFilter: ListDepartmentsFilter
  ) {
    listDepartments(
      limit: $limit
      nextToken: $nextToken
      deptFilter: $deptFilter
    ) {
      items {
        id
        owner
        organizationId
        readers
        editors
        name
        draftKnowledgeId
        publishedKnowledgeId
        workerId
        local
        state
        errors
        createdAt
        isDeleted
        upgradeCommandId
        notifyProcedureInvocation
        features
        brainVersion
        status
      }
      nextToken
    }
  }
`;
export const listDepartmentCollaborators = /* GraphQL */ `
  query ListDepartmentCollaborators(
    $departmentId: String!
    $role: AccessControlRole
    $limit: Int = 1000
    $nextToken: String
  ) {
    listDepartmentCollaborators(
      departmentId: $departmentId
      role: $role
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        role
      }
      nextToken
    }
  }
`;
export const getFact = /* GraphQL */ `
  query GetFact($knowledgeId: ID!, $factId: ID!, $epoch: Int!) {
    getFact(knowledgeId: $knowledgeId, factId: $factId, epoch: $epoch) {
      id
      knowledgeId
      knowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      names
      parentFact {
        id
        knowledgeId
        names
        parentFactId
        locations
        value
        owner
        readers
        editors
        referenceTo
        dereferencedId
        type
        viewUrl
        childProperties
        isDeleted
      }
      parentFactId
      locations
      relations {
        factId
        name
        direction
      }
      value
      descriptions {
        name
        factId
      }
      owner
      readers
      editors
      referenceTo
      dereferencedId
      type
      viewUrl
      childProperties
      isDeleted
    }
  }
`;
export const getFacts = /* GraphQL */ `
  query GetFacts($knowledgeId: ID!, $factIds: [ID!]!) {
    getFacts(knowledgeId: $knowledgeId, factIds: $factIds) {
      id
      knowledgeId
      knowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      names
      parentFact {
        id
        knowledgeId
        names
        parentFactId
        locations
        value
        owner
        readers
        editors
        referenceTo
        dereferencedId
        type
        viewUrl
        childProperties
        isDeleted
      }
      parentFactId
      locations
      relations {
        factId
        name
        direction
      }
      value
      descriptions {
        name
        factId
      }
      owner
      readers
      editors
      referenceTo
      dereferencedId
      type
      viewUrl
      childProperties
      isDeleted
    }
  }
`;
export const getHistoricalFacts = /* GraphQL */ `
  query GetHistoricalFacts($knowledgeId: ID!, $factIds: [HistoricalFactID!]!) {
    getHistoricalFacts(knowledgeId: $knowledgeId, factIds: $factIds) {
      id
      knowledgeId
      knowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      names
      parentFact {
        id
        knowledgeId
        names
        parentFactId
        locations
        value
        owner
        readers
        editors
        referenceTo
        dereferencedId
        type
        viewUrl
        childProperties
        isDeleted
      }
      parentFactId
      locations
      relations {
        factId
        name
        direction
      }
      value
      descriptions {
        name
        factId
      }
      owner
      readers
      editors
      referenceTo
      dereferencedId
      type
      viewUrl
      childProperties
      isDeleted
    }
  }
`;
export const getKnowledge = /* GraphQL */ `
  query GetKnowledge($id: ID!) {
    getKnowledge(id: $id) {
      id
      owner
      readers
      editors
      name
      description
      departmentId
      workerId
      parentKnowledgeId
      state
      brainVersion
    }
  }
`;
export const getLearning = /* GraphQL */ `
  query GetLearning($id: ID!) {
    getLearning(id: $id) {
      id
      procedureId
      departmentId
      type
      inputParams
      questionType
      questionPath
      answerType
      answerText
      owner
      readers
      editors
      createdAt
      secret
    }
  }
`;
export const listLearningsByProcedure = /* GraphQL */ `
  query ListLearningsByProcedure(
    $procedureId: ID
    $limit: Int = 100
    $nextToken: String
  ) {
    listLearningsByProcedure(
      procedureId: $procedureId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        procedureId
        departmentId
        type
        inputParams
        questionType
        questionPath
        answerType
        answerText
        owner
        readers
        editors
        createdAt
        secret
      }
      nextToken
    }
  }
`;
export const listLearningsByDepartment = /* GraphQL */ `
  query ListLearningsByDepartment(
    $departmentId: ID
    $limit: Int = 100
    $nextToken: String
  ) {
    listLearningsByDepartment(
      departmentId: $departmentId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        procedureId
        departmentId
        type
        inputParams
        questionType
        questionPath
        answerType
        answerText
        owner
        readers
        editors
        createdAt
        secret
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      contextId
      commandId
      workerId
      sentenceId
      info
      owner
      readers
      editors
      name
      description
      createdAt
    }
  }
`;
export const listNotificationsByCommand = /* GraphQL */ `
  query ListNotificationsByCommand(
    $commandId: ID
    $limit: Int = 100
    $nextToken: String
  ) {
    listNotificationsByCommand(
      commandId: $commandId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contextId
        commandId
        workerId
        sentenceId
        info
        owner
        readers
        editors
        name
        description
        createdAt
      }
      nextToken
    }
  }
`;
export const getProcedure = /* GraphQL */ `
  query GetProcedure(
    $procedureId: ID!
    $knowledgeId: ID
    $departmentId: ID
    $version: String
  ) {
    getProcedure(
      procedureId: $procedureId
      knowledgeId: $knowledgeId
      departmentId: $departmentId
      version: $version
    ) {
      id
      knowledgeId
      departmentId
      text
      name
      owner
      language
      public
      bookReference
      email {
        stage
        email
      }
      title
      version
      assignmentPolicy {
        policyType
        collaborator
      }
      schedules {
        name
        expression
        enabled
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const getProcedureByName = /* GraphQL */ `
  query GetProcedureByName(
    $departmentId: ID!
    $procedureName: String!
    $departmentVersion: String!
  ) {
    getProcedureByName(
      departmentId: $departmentId
      procedureName: $procedureName
      departmentVersion: $departmentVersion
    ) {
      id
      knowledgeId
      departmentId
      text
      name
      owner
      language
      public
      bookReference
      email {
        stage
        email
      }
      title
      version
      assignmentPolicy {
        policyType
        collaborator
      }
      schedules {
        name
        expression
        enabled
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const listProceduresByKnowledge = /* GraphQL */ `
  query ListProceduresByKnowledge(
    $knowledgeId: ID!
    $limit: Int = 1000
    $nextToken: String
  ) {
    listProceduresByKnowledge(
      knowledgeId: $knowledgeId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        knowledgeId
        departmentId
        text
        name
        owner
        language
        public
        bookReference
        title
        version
      }
      nextToken
    }
  }
`;
export const listProceduresByDepartment = /* GraphQL */ `
  query ListProceduresByDepartment(
    $departmentId: ID!
    $limit: Int = 100
    $version: String
    $nextToken: String
    $procedureFilter: ListProceduresFilter
  ) {
    listProceduresByDepartment(
      departmentId: $departmentId
      limit: $limit
      version: $version
      nextToken: $nextToken
      procedureFilter: $procedureFilter
    ) {
      items {
        id
        knowledgeId
        departmentId
        text
        name
        owner
        language
        public
        bookReference
        title
        version
      }
      nextToken
    }
  }
`;
export const questionsMetricsByDepartment = /* GraphQL */ `
  query QuestionsMetricsByDepartment(
    $departmentId: ID!
    $language: String
    $duration: Int = 24
    $limit: Int = 1000
    $nextToken: String
    $requestsMetrics: Boolean
  ) {
    questionsMetricsByDepartment(
      departmentId: $departmentId
      language: $language
      duration: $duration
      limit: $limit
      nextToken: $nextToken
      requestsMetrics: $requestsMetrics
    ) {
      procedureId
      procedureName
      unhandled
      handled
      total
      totalRuns
      procedureTitle
      unhandledRequests
      handledRequests
      totalRequests
      knowledgeId
      departmentId
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      commandId
      workerId
      contextId
      sentenceId
      contextPath {
        ctxId
        sentenceId
      }
      owner
      readers
      editors
      name
      description
      type
      text
      path
      procedureId
      validationList
      answerId
      isAnswered
      delegationChain {
        method
        recipients
        parameters
      }
      createdAt
      rawException
      parentId
      secret
      stepPath
      answerTypeHint
      sourceMetadata
      oauth2Metadata
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
        features
      }
      children {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
        oauth2Metadata
      }
      answer {
        id
        workerId
        createdAt
        forwardedAt
        invokedAt
        receivedAt
        processingAt
        skippedAt
        processedAt
        failedAt
        workStatus
        workErrors
        commandId
        questionId
        type
        owner
        readers
        editors
        name
        description
        text
        error
        remember
        secret
      }
    }
  }
`;
export const listQuestionsByCommand = /* GraphQL */ `
  query ListQuestionsByCommand(
    $commandId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByCommand(
      commandId: $commandId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
        oauth2Metadata
      }
      nextToken
    }
  }
`;
export const listQuestionsByProcedure = /* GraphQL */ `
  query ListQuestionsByProcedure(
    $procedureId: ID!
    $limit: Int = 100
    $nextToken: String
    $isAnsweredFilter: Boolean
  ) {
    listQuestionsByProcedure(
      procedureId: $procedureId
      limit: $limit
      nextToken: $nextToken
      isAnsweredFilter: $isAnsweredFilter
    ) {
      items {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
        oauth2Metadata
      }
      nextToken
    }
  }
`;
export const listRequestsByProcedure = /* GraphQL */ `
  query ListRequestsByProcedure(
    $procedureId: ID!
    $departmentId: ID
    $limit: Int = 100
    $nextToken: String
    $state: RequestState
  ) {
    listRequestsByProcedure(
      procedureId: $procedureId
      departmentId: $departmentId
      limit: $limit
      nextToken: $nextToken
      state: $state
    ) {
      items {
        id
        text
        stepPath
        contextId
        sentenceId
        createdAt
        handledAt
        state
        assignee
        procedureId
        procedureName
        allQuestionsRequireAnswers
        uiConcepts
      }
      limit
      nextToken
    }
  }
`;
export const listQuestionsByDepartment = /* GraphQL */ `
  query ListQuestionsByDepartment($input: ListQuestionsByDepartmentInput!) {
    listQuestionsByDepartment(input: $input) {
      items {
        id
        text
        contextId
        sentenceId
        answerTypeHint
        failedAnswerId
        failedAnswerReason
        summary
        ... on DomainNotIntegratedQuestion {
          domain
        }
        ... on MissingIntegrationCredentialQuestion {
          domain
        }
        ... on NativeCodeErrorQuestion {
          errorType
          errorMessage
          errorTraceback
        }
        ... on OAuth2MissingQuestion {
          authPageURL
        }
        ... on ParseErrorQuestion {
          errorTraceback
        }
        ... on ReviewConceptQuestion {
          lexicalPath
          conceptId
        }
        ... on ValueNotKnownQuestion {
          lexicalPath
          choices
          helpText
          isPlural
        }
      }
      total
    }
  }
`;
export const questions = /* GraphQL */ `
  query Questions($filter: QuestionFilter!) {
    questions(filter: $filter) {
      items {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
        oauth2Metadata
      }
      nextToken
    }
  }
`;
export const getServiceCommand = /* GraphQL */ `
  query GetServiceCommand($id: ID!) {
    getServiceCommand(id: $id) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      skippedAt
      processedAt
      failedAt
      workStatus
      workErrors
      method
      owner
      readers
      editors
      params
      result
      error
    }
  }
`;
export const listServiceCommandsByWorker = /* GraphQL */ `
  query ListServiceCommandsByWorker(
    $workerId: ID
    $limit: Int = 100
    $nextToken: String
  ) {
    listServiceCommandsByWorker(
      workerId: $workerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workerId
        createdAt
        forwardedAt
        invokedAt
        receivedAt
        processingAt
        skippedAt
        processedAt
        failedAt
        workStatus
        workErrors
        method
        owner
        readers
        editors
        params
        result
        error
      }
      nextToken
    }
  }
`;
export const getWorkItem = /* GraphQL */ `
  query GetWorkItem($id: ID!) {
    getWorkItem(id: $id) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      skippedAt
      processedAt
      failedAt
      workStatus
      workErrors
      ... on Answer {
        commandId
        questionId
        type
        owner
        readers
        editors
        name
        description
        text
        error
        remember
        secret
      }
      ... on Command {
        text
        owner
        readers
        editors
        name
        description
        subText
        subTextProperties
        subTextLanguage
        procedureId
        contextId
        sentenceId
        debug
        ast
        state
        output
        source
        sourceMetadata
        positionInContext
        isMultilineText
        useAutopilot
        questions {
          nextToken
        }
        answers {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      ... on ServiceCommand {
        method
        owner
        readers
        editors
        params
        result
        error
      }
      ... on CreateMiniPlayground {
        createdBy
        lineId
        lineToken
      }
      ... on WakeupCall {
        scheduledTime
      }
      ... on HandledRequest {
        requestId
      }
      ... on UpdateDocument {
        createdBy
        document
        documentToken
        shouldStartExecution
        useAutopilot
      }
    }
  }
`;
export const getWorker = /* GraphQL */ `
  query GetWorker($id: ID!) {
    getWorker(id: $id) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      status
      statusLastUpdatedAt
      attachments
      isArchived
      features
      exceptions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
        oauth2Metadata
      }
      questions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
        oauth2Metadata
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const getDepartmentForWorker = /* GraphQL */ `
  query GetDepartmentForWorker($workerId: ID!) {
    getDepartmentForWorker(workerId: $workerId) {
      id
      owner
      organizationId
      readers
      editors
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      upgradeCommandId
      notifyProcedureInvocation
      features
      brainVersion
      status
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
        features
      }
      organization {
        id
        name
        owner
        createdAt
        updatedAt
        billingStatus
      }
      draftKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      publishedKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      requests {
        limit
        nextToken
      }
      learnedAnswers {
        nextToken
      }
    }
  }
`;
export const getWorkerOutputFacts = /* GraphQL */ `
  query GetWorkerOutputFacts($id: ID!) {
    getWorkerOutputFacts(id: $id) {
      name
      facts {
        id
        knowledgeId
        names
        parentFactId
        locations
        value
        owner
        readers
        editors
        referenceTo
        dereferencedId
        type
        viewUrl
        childProperties
        isDeleted
      }
    }
  }
`;
export const listDepartmentWorkerFacts = /* GraphQL */ `
  query ListDepartmentWorkerFacts(
    $knowledgeId: ID!
    $limit: Int = 100
    $nextToken: String
  ) {
    listDepartmentWorkerFacts(
      knowledgeId: $knowledgeId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        knowledgeId
        names
        parentFactId
        locations
        value
        owner
        readers
        editors
        referenceTo
        dereferencedId
        type
        viewUrl
        childProperties
        isDeleted
      }
      nextToken
    }
  }
`;
export const listWorkerOutputsByProcedure = /* GraphQL */ `
  query ListWorkerOutputsByProcedure(
    $procedureId: ID!
    $knowledgeId: ID
    $page: Int
    $pageSize: Int = 10
    $decodeConcepts: Boolean
    $departmentId: ID
    $fromDate: String
    $toDate: String
    $limit: Int = 10
    $nextToken: String
  ) {
    listWorkerOutputsByProcedure(
      procedureId: $procedureId
      knowledgeId: $knowledgeId
      page: $page
      pageSize: $pageSize
      decodeConcepts: $decodeConcepts
      departmentId: $departmentId
      fromDate: $fromDate
      toDate: $toDate
      limit: $limit
      nextToken: $nextToken
    ) {
      columns
      data
      nextToken
    }
  }
`;
export const listWorkersByProcedure = /* GraphQL */ `
  query ListWorkersByProcedure(
    $procedureId: ID
    $limit: Int = 1000
    $nextToken: String
    $stateFilter: String
    $fromCreationDate: String
    $toCreationDate: String
  ) {
    listWorkersByProcedure(
      procedureId: $procedureId
      limit: $limit
      nextToken: $nextToken
      stateFilter: $stateFilter
      fromCreationDate: $fromCreationDate
      toCreationDate: $toCreationDate
    ) {
      items {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
        features
      }
      nextToken
    }
  }
`;
export const listFactsByProcedure = /* GraphQL */ `
  query ListFactsByProcedure(
    $workerIds: [String!]!
    $contextId: ID!
    $stepId: ID!
  ) {
    listFactsByProcedure(
      workerIds: $workerIds
      contextId: $contextId
      stepId: $stepId
    ) {
      columns
      data
    }
  }
`;
export const getStepPathForRun = /* GraphQL */ `
  query GetStepPathForRun($workerId: ID!, $contextId: ID!, $stepId: ID!) {
    getStepPathForRun(
      workerId: $workerId
      contextId: $contextId
      stepId: $stepId
    )
  }
`;
export const getFactsAtStepPath = /* GraphQL */ `
  query GetFactsAtStepPath($workerId: ID!, $stepPath: [String!]!) {
    getFactsAtStepPath(workerId: $workerId, stepPath: $stepPath) {
      columns
      data
    }
  }
`;
export const listWorkersByUser = /* GraphQL */ `
  query ListWorkersByUser(
    $userId: String!
    $limit: Int = 1000
    $nextToken: String
    $fromCreationDate: String
    $toCreationDate: String
  ) {
    listWorkersByUser(
      userId: $userId
      limit: $limit
      nextToken: $nextToken
      fromCreationDate: $fromCreationDate
      toCreationDate: $toCreationDate
    ) {
      items {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
        features
      }
      nextToken
    }
  }
`;
export const parseAlangText = /* GraphQL */ `
  query ParseAlangText(
    $statement: String!
    $scopeCode: String!
    $language: String!
    $departmentId: ID!
    $version: String
  ) {
    parseAlangText(
      statement: $statement
      scopeCode: $scopeCode
      language: $language
      departmentId: $departmentId
      version: $version
    ) {
      ast
      text
      error
    }
  }
`;
export const getUploadToS3Url = /* GraphQL */ `
  query GetUploadToS3Url(
    $scope: S3FileScope!
    $scopeId: ID!
    $filename: String!
    $fields: AWSJSON
    $expiresIn: Int = 300
  ) {
    getUploadToS3Url(
      scope: $scope
      scopeId: $scopeId
      filename: $filename
      fields: $fields
      expiresIn: $expiresIn
    ) {
      url
      s3Url
      fields
    }
  }
`;
export const getDownloadFromS3Url = /* GraphQL */ `
  query GetDownloadFromS3Url(
    $objectKey: String!
    $expiresIn: Int = 300
    $objectType: S3ObjectType = BRAIN_FILES
    $onlyIfExists: Boolean
  ) {
    getDownloadFromS3Url(
      objectKey: $objectKey
      expiresIn: $expiresIn
      objectType: $objectType
      onlyIfExists: $onlyIfExists
    ) {
      url
      s3Url
    }
  }
`;
export const autoCompleteCommand = /* GraphQL */ `
  query AutoCompleteCommand($text: String!) {
    autoCompleteCommand(text: $text)
  }
`;
export const generateOpenAICompletions = /* GraphQL */ `
  query GenerateOpenAICompletions($prompt: String!) {
    generateOpenAICompletions(prompt: $prompt)
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      text
      stepPath
      contextId
      sentenceId
      createdAt
      handledAt
      state
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
        features
      }
      questions {
        id
        text
        contextId
        sentenceId
        answerTypeHint
        failedAnswerId
        failedAnswerReason
        summary
        ... on DomainNotIntegratedQuestion {
          domain
        }
        ... on MissingIntegrationCredentialQuestion {
          domain
        }
        ... on NativeCodeErrorQuestion {
          errorType
          errorMessage
          errorTraceback
        }
        ... on OAuth2MissingQuestion {
          authPageURL
        }
        ... on ParseErrorQuestion {
          errorTraceback
        }
        ... on ReviewConceptQuestion {
          lexicalPath
          conceptId
        }
        ... on ValueNotKnownQuestion {
          lexicalPath
          choices
          helpText
          isPlural
        }
      }
      suggestedAnswers {
        id
        requestId
        questionId
      }
      answers {
        id
        requestId
        questionId
        createdAt
        createdBy
        summary
      }
      contextPath {
        ctxId
        sentenceId
      }
      assignee
      procedureId
      procedureName
      allQuestionsRequireAnswers
      uiConcepts
    }
  }
`;
export const getRequestQuestion = /* GraphQL */ `
  query GetRequestQuestion($id: ID!) {
    getRequestQuestion(id: $id) {
      id
      text
      failedAnswers {
        message
      }
      contextId
      sentenceId
      answerTypeHint
      failedAnswerId
      failedAnswerReason
      request {
        id
        text
        stepPath
        contextId
        sentenceId
        createdAt
        handledAt
        state
        assignee
        procedureId
        procedureName
        allQuestionsRequireAnswers
        uiConcepts
      }
      answer {
        id
        requestId
        questionId
        createdAt
        createdBy
        summary
      }
      summary
      ... on DomainNotIntegratedQuestion {
        domain
      }
      ... on MissingIntegrationCredentialQuestion {
        domain
      }
      ... on NativeCodeErrorQuestion {
        errorType
        errorMessage
        errorTraceback
      }
      ... on OAuth2MissingQuestion {
        authPageURL
      }
      ... on ParseErrorQuestion {
        errorTraceback
      }
      ... on ReviewConceptQuestion {
        lexicalPath
        conceptId
      }
      ... on ValueNotKnownQuestion {
        lexicalPath
        choices
        helpText
        isPlural
      }
    }
  }
`;
export const getRequestAnswer = /* GraphQL */ `
  query GetRequestAnswer($id: ID!) {
    getRequestAnswer(id: $id) {
      id
      requestId
      questionId
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseOauth2Action {
          accessToken
          refreshToken
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      sourceLearning {
        id
        originalAnswerId
        createdAt
        createdBy
        deletedAt
        deletedBy
        procedureId
        procedureName
        contextValue
        contextMetadata
      }
      derivedLearning {
        id
        originalAnswerId
        createdAt
        createdBy
        deletedAt
        deletedBy
        procedureId
        procedureName
        contextValue
        contextMetadata
      }
      createdAt
      createdBy
      summary
    }
  }
`;
export const getLearnedAnswer = /* GraphQL */ `
  query GetLearnedAnswer($id: ID!) {
    getLearnedAnswer(id: $id) {
      id
      questionFilter {
        stepPath
        questionType
        lexicalPath
      }
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseOauth2Action {
          accessToken
          refreshToken
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      originalAnswerId
      createdAt
      createdBy
      deletedAt
      deletedBy
      procedureId
      procedureName
      procedure {
        id
        knowledgeId
        departmentId
        text
        name
        owner
        language
        public
        bookReference
        title
        version
      }
      contextValue
      contextMetadata
    }
  }
`;
export const getLearnedAnswerByRequestAnswerId = /* GraphQL */ `
  query GetLearnedAnswerByRequestAnswerId($requestAnswerId: ID!) {
    getLearnedAnswerByRequestAnswerId(requestAnswerId: $requestAnswerId) {
      id
      questionFilter {
        stepPath
        questionType
        lexicalPath
      }
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseOauth2Action {
          accessToken
          refreshToken
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      originalAnswerId
      createdAt
      createdBy
      deletedAt
      deletedBy
      procedureId
      procedureName
      procedure {
        id
        knowledgeId
        departmentId
        text
        name
        owner
        language
        public
        bookReference
        title
        version
      }
      contextValue
      contextMetadata
    }
  }
`;
export const getLearnedAnswersByDepartmentId = /* GraphQL */ `
  query GetLearnedAnswersByDepartmentId(
    $departmentId: ID!
    $limit: Int = 100
    $nextToken: String
  ) {
    getLearnedAnswersByDepartmentId(
      departmentId: $departmentId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        originalAnswerId
        createdAt
        createdBy
        deletedAt
        deletedBy
        procedureId
        procedureName
        contextValue
        contextMetadata
      }
      nextToken
    }
  }
`;
export const getContextualLearningInfoByLearning = /* GraphQL */ `
  query GetContextualLearningInfoByLearning($learningId: ID!) {
    getContextualLearningInfoByLearning(learningId: $learningId) {
      items {
        id
        createdAt
        createdBy
        procedureId
        learningId
        requestId
        procedureName
        contextValue
        contextVector
        contextMetadata
      }
    }
  }
`;
export const getContextualLearningInfoByRequest = /* GraphQL */ `
  query GetContextualLearningInfoByRequest($requestId: ID!) {
    getContextualLearningInfoByRequest(requestId: $requestId) {
      items {
        id
        createdAt
        createdBy
        procedureId
        learningId
        requestId
        procedureName
        contextValue
        contextVector
        contextMetadata
      }
    }
  }
`;
export const getLearnedAnswerSuggestions = /* GraphQL */ `
  query GetLearnedAnswerSuggestions($input: LearnedAnswerSuggestionsInput!) {
    getLearnedAnswerSuggestions(input: $input) {
      items {
        id
        originalAnswerId
        createdAt
        createdBy
        deletedAt
        deletedBy
        procedureId
        procedureName
        contextValue
        contextMetadata
      }
      nextToken
    }
  }
`;
export const listDepartmentFacts = /* GraphQL */ `
  query ListDepartmentFacts(
    $departmentId: ID!
    $version: String
    $limit: Int = 100
    $nextToken: String
  ) {
    listDepartmentFacts(
      departmentId: $departmentId
      version: $version
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentId
        name
        values
        version
      }
      nextToken
    }
  }
`;
export const getDepartmentFact = /* GraphQL */ `
  query GetDepartmentFact($id: ID!, $departmentId: ID!, $version: String) {
    getDepartmentFact(id: $id, departmentId: $departmentId, version: $version) {
      id
      departmentId
      name
      values
      version
    }
  }
`;
export const getValueDownloadUrl = /* GraphQL */ `
  query GetValueDownloadUrl(
    $value: String!
    $departmentId: ID!
    $workerId: ID
    $secret: Boolean!
    $disposition: String
  ) {
    getValueDownloadUrl(
      value: $value
      departmentId: $departmentId
      workerId: $workerId
      secret: $secret
      disposition: $disposition
    )
  }
`;
export const revealSecretValue = /* GraphQL */ `
  query RevealSecretValue($value: String!, $departmentId: ID!, $workerId: ID) {
    revealSecretValue(
      value: $value
      departmentId: $departmentId
      workerId: $workerId
    )
  }
`;
export const listSharedEntityByProcedure = /* GraphQL */ `
  query ListSharedEntityByProcedure(
    $departmentId: ID!
    $procedureId: ID!
    $version: String
    $limit: Int = 50
    $nextToken: String
  ) {
    listSharedEntityByProcedure(
      departmentId: $departmentId
      procedureId: $procedureId
      version: $version
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityId
        departmentId
        shareName
        fullShareId
        departmentVersion
      }
      nextToken
    }
  }
`;
export const getSharedEntity = /* GraphQL */ `
  query GetSharedEntity($id: ID!) {
    getSharedEntity(id: $id) {
      id
      entityId
      departmentId
      shareName
      fullShareId
      departmentVersion
    }
  }
`;
export const exportDepartment = /* GraphQL */ `
  query ExportDepartment($departmentId: ID!, $version: String) {
    exportDepartment(departmentId: $departmentId, version: $version) {
      formatVersion
      departmentID
      departmentVersion
      checksum
      features
      procedures {
        id
        name
        text
        language
      }
      facts {
        name
        values
      }
      learnedBooks {
        ids
      }
      learnings {
        questionType
        stepPath
        lexicalPath
        procedureId
        action
        literalValue
        choices
        conceptId
        technique
        retryTimeout
        retryMinimumDelay
        newSteps
        oauth2Metadata
      }
    }
  }
`;
export const listKChatMessagesByWorker = /* GraphQL */ `
  query ListKChatMessagesByWorker(
    $workerId: ID!
    $limit: Int = 50
    $nextToken: String
  ) {
    listKChatMessagesByWorker(
      workerId: $workerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentId
        workerId
        message
        timestamp
        displayName
        role
      }
      nextToken
    }
  }
`;
export const listWorkersByDepartment = /* GraphQL */ `
  query ListWorkersByDepartment(
    $departmentId: ID!
    $limit: Int = 10
    $nextToken: String
    $fromCreationDate: String
    $toCreationDate: String
    $procedureId: ID
  ) {
    listWorkersByDepartment(
      departmentId: $departmentId
      limit: $limit
      nextToken: $nextToken
      fromCreationDate: $fromCreationDate
      toCreationDate: $toCreationDate
      procedureId: $procedureId
    ) {
      items {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
        features
      }
      nextToken
    }
  }
`;
export const getSentenceExecutionData = /* GraphQL */ `
  query GetSentenceExecutionData(
    $workerId: ID!
    $iterationToken: String
    $documentToken: String
  ) {
    getSentenceExecutionData(
      workerId: $workerId
      iterationToken: $iterationToken
      documentToken: $documentToken
    ) {
      lineId
      token
      status
      answer
      concepts
      requests
      epoch
      iterationTokens
      internalMetadata {
        key
        value
      }
      subDocuments {
        documentToken
        processName
      }
      miniPlaygrounds {
        documentToken
        processName
      }
      documentToken
      startedAt
      completedAt
    }
  }
`;
export const getWorkerDocument = /* GraphQL */ `
  query GetWorkerDocument($workerId: ID!, $documentToken: String) {
    getWorkerDocument(workerId: $workerId, documentToken: $documentToken) {
      lineId
      text
      parentId
      language
      metadata {
        lineId
        parentId
      }
      internalMetadata {
        key
        value
      }
      documentToken
    }
  }
`;
export const listIntegrationsByDepartment = /* GraphQL */ `
  query ListIntegrationsByDepartment(
    $departmentId: ID!
    $version: String
    $limit: Int = 100
    $nextToken: String
  ) {
    listIntegrationsByDepartment(
      departmentId: $departmentId
      version: $version
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        name
        author
        description
        integratedAt
        version
      }
      nextToken
    }
  }
`;
export const getIntegrationCredentialForm = /* GraphQL */ `
  query GetIntegrationCredentialForm($integrationId: ID!, $departmentId: ID!) {
    getIntegrationCredentialForm(
      integrationId: $integrationId
      departmentId: $departmentId
    ) {
      integrationId
      fields {
        label
        key
        type
        description
        example
      }
    }
  }
`;
export const validateIntegrationCredential = /* GraphQL */ `
  query ValidateIntegrationCredential($integrationId: ID!, $departmentId: ID!) {
    validateIntegrationCredential(
      integrationId: $integrationId
      departmentId: $departmentId
    ) {
      integrationId
      departmentId
      lastChangedAt
      lastChangedBy
      lastValidatedAt
      lastValidationStatus
      lastValidationMessage
      isCredentialProvided
      redirectionFlowRequired
      isRedirectionFlowDone
    }
  }
`;
export const revealIntegrationCredential = /* GraphQL */ `
  query RevealIntegrationCredential($integrationId: ID!, $departmentId: ID!) {
    revealIntegrationCredential(
      integrationId: $integrationId
      departmentId: $departmentId
    ) {
      integrationId
      departmentId
      data {
        label
        key
        value
      }
    }
  }
`;
export const resolveIntegrationCredentialValue = /* GraphQL */ `
  query ResolveIntegrationCredentialValue(
    $departmentId: ID!
    $integrationId: ID!
  ) {
    resolveIntegrationCredentialValue(
      departmentId: $departmentId
      integrationId: $integrationId
    ) {
      integrationId
      departmentId
      data {
        label
        key
        value
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($departmentId: ID!) {
    getUser(departmentId: $departmentId) {
      username
      firstName
      lastName
      company
      role
    }
  }
`;
export const getDocumentBlocks = /* GraphQL */ `
  query GetDocumentBlocks(
    $knowledgeId: ID!
    $documentFactId: ID!
    $boundingBoxes: GetDocumentBlocksInput
  ) {
    getDocumentBlocks(
      knowledgeId: $knowledgeId
      documentFactId: $documentFactId
      boundingBoxes: $boundingBoxes
    ) {
      blocksByPage {
        pageNum
      }
      rawText
    }
  }
`;
