// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestInfo-module__requestInfo__uS7N5 {
  background-color: #f5f5f5;
  padding: 12px;
  font-size: 14px;
  font-family: Nunito;
}
.RequestInfo-module__summary__WbHgW {
  margin-top: 16px;
}
.RequestInfo-module__summaryTitle__dl73N {
  font-weight: bold;
}
.RequestInfo-module__summaryIcon__Pxw7G {
  font-size: 12px;
  margin-left: 4px;
}
.RequestInfo-module__summaryInfo__jU1iG {
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/request/RequestInfo/RequestInfo.module.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;AACF;AAEA;EACE,gBAAA;AAAF;AAGA;EACE,iBAAA;AADF;AAIA;EACE,eAAA;EACA,gBAAA;AAFF;AAKA;EACE,eAAA;AAHF","sourcesContent":[".requestInfo {\n  background-color: #f5f5f5;\n  padding: 12px;\n  font-size: 14px;\n  font-family: Nunito;\n}\n\n.summary {\n  margin-top: 16px;\n}\n\n.summaryTitle {\n  font-weight: bold;\n}\n\n.summaryIcon {\n  font-size: 12px;\n  margin-left: 4px;\n}\n\n.summaryInfo {\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requestInfo": `RequestInfo-module__requestInfo__uS7N5`,
	"summary": `RequestInfo-module__summary__WbHgW`,
	"summaryTitle": `RequestInfo-module__summaryTitle__dl73N`,
	"summaryIcon": `RequestInfo-module__summaryIcon__Pxw7G`,
	"summaryInfo": `RequestInfo-module__summaryInfo__jU1iG`
};
export default ___CSS_LOADER_EXPORT___;
