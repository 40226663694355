import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import SDKApis from '@utils/SDKApis';
import { CognitoUser } from '@aws-amplify/auth';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { useAppDispatch } from '@/stores/hooks';
import { useSelector } from 'react-redux';
import { setAnalyticsUserId } from '@/tracker';
import AuthLoading from '../components/AuthLoading';
import routes from '../utils/routes';
import {
  getUserOrganization,
  updateAuthLoading,
  updateUsername,
  userSelector
} from '../stores/slices/user';

export async function getJwtToken(): Promise<string> {
  try {
    const session = await SDKApis.Auth.currentSession();
    const token = session!.getIdToken().getJwtToken();
    return token;
  } catch (e) {
    console.error('No token found.');
    // TODO: This seems odd, but it's what the old code did.
    return '';
  }
}

type IUser = CognitoUser | null;

// to hold additional data signin may require
interface IAuthSignInMeta {
  callbackPathname: string;
}

export interface SignupParams {
  fName: string;
  lName: string;
  email: string;
  company: string;
  role?: string;
  checked: boolean;
}
interface IAuth {
  signup: (signupParams: SignupParams, meta: IAuthSignInMeta) => Promise<IUser>;
  signin: (email: string, meta: IAuthSignInMeta) => Promise<IUser>;
  logout: () => Promise<void>;
  checkLogin: () => Promise<IUser>;
  getJwtToken: () => Promise<string>;
  refreshTokens: () => Promise<any>;
  verifyLogin: (email: string, code: string) => Promise<IUser>;
}

const AuthContext = React.createContext<IAuth>(null!);

interface IAuthProps {
  children: React.ReactNode;
}

export function AuthProvider(props: IAuthProps) {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<CognitoUser | null>(null);

  const signInHandler = (userData: CognitoUser) => {
    if (userData) {
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
      const username = userData.getUsername();
      LogRocket.identify(username);
      Sentry.setUser({ email: username });
      setAnalyticsUserId(username);
      dispatch(updateUsername(username));
      setUser(userData);
      return userData;
    }
    return null;
  };

  const finallyHandler = () => {
    dispatch(updateAuthLoading(false));
  };

  const refreshTokens = async () => {
    try {
      const refreshToken = await SDKApis.Auth.currentSession().then((s) =>
        s!.getRefreshToken()
      );
      user?.refreshSession(refreshToken, () => {});
    } catch (e) {
      console.error(e);
    }
  };

  // amplify source -> https://bit.ly/3rsNkVi
  const checkLogin = () =>
    SDKApis.Auth.currentAuthenticatedUser()
      .then(signInHandler)
      .finally(finallyHandler);

  const signup = async (signupParams: SignupParams, meta: IAuthSignInMeta) => {
    const { email } = signupParams;
    LogRocket.identify(email);
    Sentry.setUser({ email });
    setAnalyticsUserId(email);
    return SDKApis.Auth.startSignUp(signupParams, meta.callbackPathname);
  };

  const signin = (email: string, meta: IAuthSignInMeta) => {
    LogRocket.identify(email);
    Sentry.setUser({ email });
    setAnalyticsUserId(email);
    return SDKApis.Auth.startSignIn(email, '***', meta.callbackPathname);
  };

  const verifyLogin = (email: string, code: string) =>
    SDKApis.Auth.finishSignIn(email, code)
      .then(signInHandler)
      .finally(finallyHandler);

  const logout = () =>
    SDKApis.Auth.signOut().then(() => {
      dispatch(updateUsername(null));
      setUser(null);
    });

  useEffect(() => {
    checkLogin();
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    signup,
    signin,
    logout,
    checkLogin,
    verifyLogin,
    getJwtToken,
    refreshTokens
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext<IAuth>(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { username, loading } = useSelector(userSelector);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (username) {
      dispatch(
        getUserOrganization({
          owner: username
        })
      );
    }
  }, [username]);

  if (loading) {
    return (
      <div data-cy="auth-loading">
        <AuthLoading />
      </div>
    );
  }

  if (!username) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    console.log('Redirecting to Login');
    return (
      <Navigate
        to={routes.auth.getLoginPageUrl()}
        state={{ from: location }}
        replace
      />
    );
    // return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  return <div data-cy="auth-success">{children}</div>;
}
