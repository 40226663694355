import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  TaskAbortError,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  CreateServiceCommandInput,
  CreateServiceCommandMutation,
  CreateWorkerMutation,
  CreateWorkerMutationVariables,
  GetCommandQuery,
  GetCommandQueryVariables,
  GetServiceCommandQuery,
  GetServiceCommandQueryVariables,
  GetWorkerQuery,
  GetWorkerQueryVariables,
  ListWorkersByProcedureQuery,
  ListWorkersByProcedureQueryVariables,
  Worker
} from '@/generated/API';
import { RootState, AsyncThunkConfig } from '@/stores/AppStore';
import { FetchResult, gql } from '@apollo/client';
import Mutations from '@/graphql/Mutations';
import { getServiceCommand } from '@/generated/graphql/queries';
import { startAppListening } from '@/stores/listenerMiddleware';
import AppConstants from '@/utils/AppConstants';
import Queries from '@/graphql/Queries';

export const createWorker = createAsyncThunk<
  FetchResult<CreateWorkerMutation>,
  CreateWorkerMutationVariables,
  AsyncThunkConfig
>('worker/create', async (variables, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: AppConstants.APIS.WORKERS.CREATE(),
    variables
  })
);

export const getWorker = createAsyncThunk<
  FetchResult<GetWorkerQuery>,
  GetWorkerQueryVariables,
  AsyncThunkConfig
>(
  'worker/fetch',
  async ({ id }, thunkAPI) =>
    thunkAPI.extra.appSyncClient.query({
      query: AppConstants.APIS.GET_ENTITY.worker(),
      variables: {
        id
      }
    }),
  {
    serializeError: (x: any) => x
  }
);

export interface IGetWorkerDependenciesV2Variables {
  workerId: string;
  contextId: string;
  requestExceptionSupported: boolean;
}

export interface IGetWorkerDependenciesV2Data {
  getWorker?: GetWorkerQuery['getWorker'];
}

export const getWorkerDependenciesV2 = createAsyncThunk<
  FetchResult<IGetWorkerDependenciesV2Data>,
  IGetWorkerDependenciesV2Variables,
  AsyncThunkConfig
>('worker/dependencies/v2/fetch', async ({ workerId, contextId }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.WorkerDependenciesV2(),
    variables: {
      workerId,
      contextId
    }
  })
);

export const getServiceCmd = createAsyncThunk<
  FetchResult<GetServiceCommandQuery>,
  GetServiceCommandQueryVariables,
  AsyncThunkConfig
>('worker/getServiceCmd', async ({ id }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: gql(getServiceCommand),
    variables: {
      id
    }
  })
);

export const getCmd = createAsyncThunk<
  FetchResult<GetCommandQuery>,
  GetCommandQueryVariables,
  AsyncThunkConfig
>('worker/getCmd', async ({ id }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.Command(),
    variables: {
      id
    }
  })
);

export const listWorkerByProcedureId = createAsyncThunk<
  FetchResult<ListWorkersByProcedureQuery>,
  ListWorkersByProcedureQueryVariables,
  AsyncThunkConfig
>('worker/listByProcedureId', async (variables, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.ListWorkersByProcedure(),
    variables
  })
);

export const sendServiceCmd = createAsyncThunk<
  FetchResult<CreateServiceCommandMutation>,
  CreateServiceCommandInput,
  AsyncThunkConfig
>('worker/sendServiceCmd', async ({ workerId, method }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.mutate({
    mutation: Mutations.CreateServiceCommand(),
    variables: {
      input: {
        workerId,
        method
      }
    }
  })
);

type WorkerDebugStatus = 'init' | 'pausing' | 'paused' | 'running' | 'ran';
type WorkerLocalState = { debugStatus?: WorkerDebugStatus };
const WorkerAdapter = createEntityAdapter<Worker & WorkerLocalState>();

const updateWorkerDebugStatus = (
  state: any,
  workerId: string,
  status: WorkerDebugStatus
) => {
  state.entities[workerId] = {
    ...(state.entities[workerId] || {}),
    debugStatus: status
  };
};

export const workerSlice = createSlice({
  name: 'worker',
  initialState: WorkerAdapter.getInitialState({
    loading: false,
    error: undefined
  }),
  reducers: {
    updateWorker: WorkerAdapter.updateOne,
    removeWorker: WorkerAdapter.removeOne,
    addWorker: (state, action: PayloadAction<Worker>) => {
      const worker = action.payload;
      const workerId = worker?.id;

      if (worker) {
        // @ts-ignore
        state.entities[workerId] = {
          ...worker,
          debugStatus: 'init'
        };

        if (state.ids.indexOf(worker.id) === -1) {
          state.ids.push(workerId);
        }
      }
      state.loading = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createWorker.fulfilled, (state, action) => {
      const worker = action?.payload?.data?.createWorker as Worker;
      if (worker) {
        WorkerAdapter.addOne(state, worker);
      }
    });
    builder.addCase(sendServiceCmd.pending, (state, action) => {
      const { workerId } = action.meta.arg;
      const { method } = action.meta.arg;
      switch (method) {
        case 'pause':
          updateWorkerDebugStatus(state, workerId, 'pausing');
          break;
        case 'play':
          updateWorkerDebugStatus(state, workerId, 'running');
          break;
        default:
          break;
      }

      state.loading = true;
    });
    builder.addCase(sendServiceCmd.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getServiceCmd.fulfilled, (state, action) => {
      const workerId = action.payload.data?.getServiceCommand?.workerId;
      const result =
        JSON.parse(action.payload.data?.getServiceCommand?.result!) || {};

      if (result.status === 'pause') {
        updateWorkerDebugStatus(state, workerId!, 'paused');
      }

      if (result.status === 'run') {
        updateWorkerDebugStatus(state, workerId!, 'ran');
      }
    });
    builder.addCase(sendServiceCmd.rejected, (state, action) => {
      state.error = action.error as any;
      state.loading = false;
    });
    builder.addCase(getWorker.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as any;
    });
    builder.addCase(getWorker.fulfilled, (state, { payload, meta }) => {
      const workerId = meta.arg.id;
      const worker = payload?.data?.getWorker!;

      if (worker) {
        // @ts-ignore
        state.entities[workerId] = {
          ...worker,
          debugStatus: 'init'
        };

        if (state.ids.indexOf(worker.id) === -1) {
          state.ids.push(workerId);
        }
      }

      if (payload.errors) {
        state.error = payload?.errors as any;
      }

      state.loading = false;
    });
  }
});

export const workerQuerySelector = (state: RootState) => ({
  loading: state.worker.loading,
  error: state.worker.error
});

export default workerSlice.reducer;

startAppListening({
  actionCreator: sendServiceCmd.fulfilled,
  effect: async (action, listenerApi) => {
    // Only allow one instance of this listener to run at a time
    listenerApi.unsubscribe();
    // eslint-disable-next-line no-unsafe-optional-chaining
    let cmd = action?.payload?.data?.createServiceCommand;

    const pollingTask = listenerApi.fork(async () => {
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          // console.log('Polling service command');
          const resp = await listenerApi
            .dispatch(getServiceCmd({ id: cmd?.id! }))
            .unwrap();
          cmd = resp.data?.getServiceCommand;
          await listenerApi.delay(500);
        }
      } catch (err) {
        if (err instanceof TaskAbortError) {
          // could do something here to track that the task was cancelled
          // console.log('task aborted');
        }
      }
    });

    const cond = await listenerApi.condition(
      () => cmd?.result !== null || cmd?.error !== null,
      300000
    ); // timeout after 10s
    if (!cond) {
      // console.log('timeout');
    }
    // console.log(cmd?.result, cmd?.error);
    // console.log('cancelling the polling;');
    pollingTask.cancel();
    listenerApi.subscribe();
  }
});

export const debugStateSelector = (workerId: string) => (state: RootState) =>
  state?.worker?.entities[workerId]?.debugStatus ?? 'init';

export const { removeWorker, updateWorker, addWorker } = workerSlice.actions;

export const workerSelector = WorkerAdapter.getSelectors(
  (state: RootState) => state.worker
);
