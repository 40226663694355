import { useState } from 'react';
import AppConstants from '../utils/AppConstants';

interface IProps {
  totalCount: number;
  pageSize?: number;
}

function usePaginationState(props: IProps) {
  const { pageSize = AppConstants.TABLE.DEFAULT_PAGE_SIZE, totalCount } = props;

  const [pageNumber, setPageNumber] = useState(1);

  return {
    pageNumber,
    updatePageNumber: (page: number) => {
      if (page) {
        setPageNumber(page);
      }
    },
    pageSize,
    total: totalCount
  };
}

export default usePaginationState;
