// type VisitorType = 'sme' | 'eu';

import { Procedure } from '../generated/API';

// Auth
const getLoginPageUrl = () => '/login';

const getVerifyLoginPageUrl = (challenge: string) => `verify-user/${challenge}`;

// Global
const getHomePageUrl = () => '/';

const getPlaygroundPageUrl = (departmentId: string, workerId?: string) =>
  `/department/${departmentId}/playground${workerId ? `/${workerId}` : ''}`;

// Procedure
const getProcedureHomePageUrl = () => `/processes`;

const getProcedurePageUrl = (
  departmentId: Procedure['departmentId'],
  procedureId: string
) => {
  return `/processes/${departmentId}/${procedureId}`;
};

const getProcedureRunAllPageUrl = (
  departmentId: Procedure['departmentId'],
  procedureId: string
) => `${getProcedurePageUrl(departmentId, procedureId)}/run/all`;

const getProcedureRunPageUrl = (
  departmentId: Procedure['departmentId'],
  procedureId: string,
  workerId: string
) => `${getProcedurePageUrl(departmentId, procedureId)}/run/${workerId}`;

const getProcedureLearningsPageUrl = (
  departmentId: Procedure['departmentId'],
  procedureId: string
) => `${getProcedurePageUrl(departmentId, procedureId)}/learnings`;

const getProcedureSchedulesPageUrl = (
  departmentId: Procedure['departmentId'],
  procedureId: string
) => `${getProcedurePageUrl(departmentId, procedureId)}/schedules`;

const getProcedureSharePageUrl = (
  departmentId: Procedure['departmentId'],
  procedureId: string
) => `${getProcedurePageUrl(departmentId, procedureId)}/share`;

const getNewPythonProcessPageUrl = () => '/process/new/python';

// Department
const getDepartmentsPageUrl = () => `/departments`;
const getDepartmentPageUrl = () => `/department`;
const getDepartmentLearningsPageUrl = () => `/department/learnings`;
const getDepartmentHistoryPageUrl = () => `/department/history`;
const getDepartmentCollaboratorsPageUrl = () => `/department/collaborators`;

// Admin
const getAdminPageUrl = () => `/internal/admin`;

// Dashboard
const getDashboardPageUrl = () => '/dashboard';

// Exceptions
const getExceptionsPageUrl = () => '/exceptions';

const getExceptionPageUrl = (exceptionId: string) =>
  `${getExceptionsPageUrl()}?activeExceptionId=${exceptionId}`;

const getRequestPageUrl = (departmentId: string, requestId: string) =>
  `/request/${departmentId}/${requestId}`;

// Books
const getBooksPageUrl = () => '/books';

const authRoutes = {
  getLoginPageUrl,
  getVerifyLoginPageUrl
};

const globalRoutes = {
  getHomePageUrl
};

const playgroundRoutes = {
  getPlaygroundPageUrl
};

const procedureRoutes = {
  getProcedureHomePageUrl,
  getProcedurePageUrl,
  getProcedureRunAllPageUrl,
  getProcedureRunPageUrl,
  getProcedureLearningsPageUrl,
  getProcedureSchedulesPageUrl,
  getProcedureSharePageUrl,
  getNewPythonProcessPageUrl
};

const departmentRoutes = {
  getDepartmentsPageUrl,
  getDepartmentPageUrl,
  getDepartmentLearningsPageUrl,
  getDepartmentHistoryPageUrl,
  getDepartmentCollaboratorsPageUrl
};

const booksRoutes = {
  getBooksPageUrl
};

const adminRoutes = {
  getAdminPageUrl
};

const dashboardRoutes = {
  getDashboardPageUrl
};

const exceptions = {
  getExceptionsPageUrl,
  getExceptionPageUrl,
  getRequestPageUrl
};

const routes = {
  auth: authRoutes,
  global: globalRoutes,
  playground: playgroundRoutes,
  procedure: procedureRoutes,
  department: departmentRoutes,
  admin: adminRoutes,
  dashboard: dashboardRoutes,
  exceptions,
  books: booksRoutes
};

export default routes;
