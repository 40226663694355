// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.object-viewer-popup .object-viewer .CodeMirror {
  height: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/popups/ObjectViewerPopup.less"],"names":[],"mappings":"AAEA;EAGM,aAAA;AAHN","sourcesContent":["@import '@css/variables.less';\n\n.object-viewer-popup {\n  .object-viewer {\n    .CodeMirror {\n      height: 500px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
