import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  DepartmentHistoryEvent,
  GetDepartmentHistoryQuery,
  GetDepartmentHistoryQueryVariables
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Queries from '@/graphql/Queries';
import { FetchResult } from '@apollo/client';

export interface GetDepartmentHistoryParams
  extends GetDepartmentHistoryQueryVariables {
  page?: number;
}

export const getDepartmentHistory = createAsyncThunk<
  FetchResult<GetDepartmentHistoryQuery>,
  GetDepartmentHistoryParams,
  AsyncThunkConfig
>('departmentHistory/fetch', async ({ page, ...rest }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.GetDepartmentHistory(),
    variables: {
      ...rest
    }
  })
);

const DepartmentHistoryAdapter = createEntityAdapter<DepartmentHistoryEvent>({
  selectId: (history) =>
    `${history.entityName}-${history.entityId}-${history.operation}`
});

interface IState {
  loading: boolean;
  error: any;
  nextToken: string | undefined;
  pageDataMap: Record<number, DepartmentHistoryEvent[]>;
}

export const departmentHistorySlice = createSlice({
  name: 'departmentHistory',
  initialState: DepartmentHistoryAdapter.getInitialState<IState>({
    loading: false,
    error: undefined,
    nextToken: undefined,
    pageDataMap: {}
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDepartmentHistory.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      getDepartmentHistory.fulfilled,
      (state, { payload, meta }) => {
        if (payload?.data) {
          const { items = [], nextToken } =
            payload.data.getDepartmentHistory ?? {};

          const history = items as DepartmentHistoryEvent[];

          DepartmentHistoryAdapter.addMany(state, history);

          if (meta.arg.page && history.length > 0) {
            state.pageDataMap[meta.arg.page] = history;
          }

          state.nextToken = nextToken || undefined;
        }

        state.loading = false;
      }
    );

    builder.addCase(getDepartmentHistory.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.loading = false;
    });
  }
});

export const departmentHistorySelectors =
  DepartmentHistoryAdapter.getSelectors<RootState>(
    (state) => state.departmentHistory
  );

export const departmentHistoryQuerySelector = (state: RootState) => ({
  loading: state.departmentHistory.loading,
  history: departmentHistorySelectors.selectAll(state),
  error: state.departmentHistory.error,
  nextToken: state.departmentHistory.nextToken,
  pageDataMap: state.departmentHistory.pageDataMap
});

export default departmentHistorySlice.reducer;
