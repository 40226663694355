//
// Copyright (C) - Kognotos, Inc. All rights reserved
//
// Shows debug details for a run
//

// 3rd party libraries
import React from 'react';
import { Popover, Switch } from 'antd';

// Local imports
import { Worker } from '@/generated/API';
import moment from 'moment';
import { userSelector } from '@/stores/slices/user';
import { useAppSelector } from '@/stores/hooks';
import AppUtil from '../utils/AppUtil';

interface IPropsRunDebugDetails {
  /**
   * The run (worker).
   */
  worker: Worker;
  workerId: string;

  /**
   * Embedded child elements to show inside popover.
   */
  children: any;
  showCreatedAt?: boolean;
  isDebugMode?: boolean;
  handleDebugMode?: (start: boolean) => void;
}

function RunDebugDetails({
  worker,
  workerId,
  children,
  showCreatedAt,
  isDebugMode,
  handleDebugMode
}: IPropsRunDebugDetails) {
  const logLink = AppUtil.getWorkerLogLink(workerId);

  const mmt = moment(
    Number(AppUtil.millisFromDateString(worker?.createdAt ?? Date().toString()))
  );

  const { username } = useAppSelector(userSelector);

  const supportUser = AppUtil.isSupportUser(username);

  return (
    <Popover
      key={`details-${worker?.id}`}
      placement="right"
      className="popover-trigger"
      title={worker?.name || 'Playground'}
      trigger="hover"
      content={
        <span>
          <strong>Id:</strong> {worker?.id} (
          <a href={logLink} target="_blank" rel="noreferrer">
            logs
          </a>
          )
          <p />
          <strong>KnowledgeId:</strong> {worker?.knowledgeId}
          <p />
          {showCreatedAt ? (
            <span>
              <strong>Created At:</strong> {mmt.toLocaleString()}
            </span>
          ) : null}
          <p />
          {supportUser && handleDebugMode && (
            <span>
              <strong>Debug mode:</strong>{' '}
              <Switch defaultChecked={isDebugMode} onChange={handleDebugMode} />
            </span>
          )}
        </span>
      }
    >
      {children}
    </Popover>
  );
}

export default RunDebugDetails;
