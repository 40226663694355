import React from 'react';
import Icon from '@ant-design/icons';

function SubProcedureSVG({
  fill,
  width,
  height
}: {
  fill: string;
  width: number;
  height: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.6786 22.012C35.8554 22.012 36 21.8674 36 21.6906V19.4406C36 19.2638 35.8554 19.1192 35.6786 19.1192H9.40179C9.11401 18.4066 8.61979 17.7964 7.9826 17.3668C7.34541 16.9373 6.59436 16.708 5.82589 16.7085C3.69643 16.7085 1.96875 18.4361 1.96875 20.5656C1.96875 22.6951 3.69643 24.4228 5.82589 24.4228C7.44509 24.4228 8.83125 23.4263 9.40179 22.012H15.4406V28.1192C15.4406 30.337 17.2406 32.137 19.4585 32.137H35.6786C35.8554 32.137 36 31.9924 36 31.8156V29.5656C36 29.3888 35.8554 29.2442 35.6786 29.2442H19.4585C18.8357 29.2442 18.3335 28.7419 18.3335 28.1192V22.012H35.6786ZM15.75 11.2442H26.3571V15.1897C26.3571 15.5152 26.7388 15.704 26.992 15.4991L33.9228 10.0268C34.1237 9.87007 34.1237 9.56873 33.9228 9.41204L26.992 3.9357C26.7348 3.73079 26.3571 3.91561 26.3571 4.24507V8.19061H15.75C15.5732 8.19061 15.4286 8.33525 15.4286 8.51204V10.9228C15.4286 11.0995 15.5732 11.2442 15.75 11.2442Z"
        fill={fill}
      />
    </svg>
  );
}

export default function SubProcedureIcon(props: {
  style?: React.CSSProperties;
  width: number;
  height: number;
  fill: string;
}) {
  return (
    <Icon {...props}>
      <SubProcedureSVG
        fill={props.fill}
        width={props.width}
        height={props.height}
      />
    </Icon>
  );
}
