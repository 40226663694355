//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Functions for low level application dialogs (Confirm, propmt, etc...)
//

// 3rd party includes
import React from 'react';
import { Modal, Input, ModalFuncProps } from 'antd';

// Local includes
import i18n from '@utils/i18n';
import ComponentFactory from '@utils/ComponentFactory';

// Component CSS
import './AppDialogs.less';

// Component i18n
const i18nt = (s: string, d: string) => i18n.t('AppDialogs', s, d);

export const ConfirmDialog = (
  confirmationTitle: string, // The question usually
  confirmationText: string, // Additional info
  iconId: string, // icon to show next to title
  onConfirm: () => void, // confirm callback
  onCancel?: () => void, // Cancel callback
  okText: string = '', // OK Text
  cancelText: string = '', // Cancel Text
  className: string = '',
  okButtonProps: any = {}
) => {
  const icon = ComponentFactory.getIcon(iconId);
  const options: ModalFuncProps = {
    title: confirmationTitle,
    icon,
    content: confirmationText,
    onOk: onConfirm,
    onCancel,
    okText: okText || i18nt('ok', 'OK'),
    cancelText: cancelText || i18nt('cancel', 'Cancel'),
    className,
    okButtonProps
  };

  Modal.confirm(options);
};

export function PromptDialog(
  prompt: string, // The question to ask
  defaultValue: string, // Default answer
  iconId: string, // icon to show next to title
  helpText: string, // Optional help text to show
  okCallback: (answer: string) => void, // OK callback
  cancelCallback?: () => void, // Cancel callback
  okText: string = '', // OK Text (Optional
  cancelText: string = '' // Cancel Text
) {
  const icon = ComponentFactory.getIcon(iconId);
  const inputElRef = React.createRef<HTMLInputElement>();

  const inputElement = (
    // @ts-ignore
    <Input ref={inputElRef} defaultValue={defaultValue} type="text" />
  );

  const title = (
    <div className="prompt-container">
      <div className="prompt">{prompt}</div>
      {inputElement}
      {helpText && <div className="help-text">{helpText}</div>}
    </div>
  );

  // Confirm Modal options
  const options = {
    className: 'app-prompt',
    title,
    icon,
    okText: okText || i18nt('ok', 'OK'),
    cancelText: cancelText || i18nt('cancel', 'Cancel'),
    onOk: () => {
      if (inputElRef.current) {
        // @ts-ignore
        okCallback(inputElRef.current.state.value.trim());
      }
    },
    onCancel: cancelCallback
  };
  Modal.confirm(options);
}
