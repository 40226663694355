import { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useRunCtxV2 } from '@/provider/runv2';
import AppConstants from '@/utils/AppConstants';
import styles from './EditableS3Fact.module.less';
import S3FileUpload from '../Upload';
import AnswerS3FileViewer from '../AnswerS3FileViewer';

interface IEditableS3FactProps {
  children: React.ReactNode;
  factType: {
    type: FormattedAnswerTypeV2;
    answer: any;
  };
  onEdit?: (data: string) => Promise<unknown>;
  onDelete?: () => Promise<unknown>;
}

function EditableS3Fact(props: IEditableS3FactProps) {
  const { children, factType, onEdit, onDelete } = props;
  const runCtx = useRunCtxV2();

  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [editValue, setEditValue] = useState(factType.answer);

  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    setDeleteLoading(false);
    setIsDeleteClicked(false);
  }, [factType.answer]);

  // In case the worker is not loaded, or we don't want edit functionality
  // useful for just rendering children outside of playground.
  if (!runCtx || !onEdit || !onDelete) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  const { worker } = runCtx;
  const isInReadMode = !isEditClicked && !isDeleteClicked;

  const fact = (
    <div className={styles['editable-container']}>
      {isEditClicked ? (
        <>
          <span className={styles['file-edit-container']}>
            {!editLoading && <AnswerS3FileViewer answer={editValue} />}
            <S3FileUpload
              scope={AppConstants.S3_FILE_SCOPE.WORKER}
              scopeId={worker!.id}
              minimal
              multiple={false}
              onChange={(data) => setEditValue(data[0].s3Url)}
            />
          </span>
          <span className={styles['edit-actions-container']}>
            {editLoading ? (
              <LoadingOutlined />
            ) : (
              <>
                {editValue && (
                  <Tooltip title="confirm">
                    <Button
                      danger
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<CheckOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onEdit) {
                          setEditLoading(true);
                          onEdit(editValue).finally(() => {
                            setEditLoading(false);
                            setIsEditClicked(false);
                          });
                        }
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip title="cancel">
                  <Button
                    size="small"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditClicked(false);
                    }}
                  />
                </Tooltip>
              </>
            )}
          </span>
        </>
      ) : (
        children
      )}
      {isInReadMode && (
        <span className={styles['actions-container']}>
          <span
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setIsEditClicked(true);
              setEditValue(factType.answer);
            }}
          >
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </span>
          <span
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setIsDeleteClicked(true);
            }}
          >
            <Tooltip title="Delete">
              <DeleteOutlined />
            </Tooltip>
          </span>
        </span>
      )}
    </div>
  );

  return (
    <span>
      {isDeleteClicked ? (
        <div className={{ ...styles.dim, ...styles['delete-container'] }}>
          <span className={styles['delete-fact-container']}>{fact}</span>
          <span className={styles['delete-actions-container']}>
            Delete?
            {deleteLoading ? (
              <LoadingOutlined />
            ) : (
              <span className={styles['delete-buttons-container']}>
                <Tooltip title="confirm">
                  <Button
                    danger
                    type="primary"
                    shape="circle"
                    size="small"
                    icon={<CheckOutlined />}
                    onClick={() => {
                      if (onDelete) {
                        setDeleteLoading(true);
                        onDelete().finally(() => {
                          setIsDeleteClicked(false);
                          setDeleteLoading(false);
                        });
                      }
                    }}
                  />
                </Tooltip>
                <Tooltip title="cancel">
                  <Button
                    size="small"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => setIsDeleteClicked(false)}
                  />
                </Tooltip>
              </span>
            )}
          </span>
        </div>
      ) : (
        fact
      )}
    </span>
  );
}

export default EditableS3Fact;
