// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-description .ant-alert-action {
  position: absolute;
  right: 14px;
}
.error-description .error-raw {
  margin-top: 12px;
}
.error-description .error-raw .CodeMirror {
  height: 250px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorDescription.less"],"names":[],"mappings":"AAIE;EACE,kBAAA;EACA,WAAA;AAHJ;AAME;EACE,gBAAA;AAJJ;AAMI;EACE,aAAA;AAJN","sourcesContent":["// DepartmentSelector CSS\n@import '@css/variables.less';\n\n.error-description {\n  & .ant-alert-action {\n    position: absolute;\n    right: 14px;\n  }\n\n  & .error-raw {\n    margin-top: 12px;\n\n    & .CodeMirror {\n      height: 250px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
