import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { EditorView, ViewUpdate } from '@codemirror/view';
import { Extension } from '@codemirror/state';
import { indentationMarkers } from '@replit/codemirror-indentation-markers';
import { python } from '@codemirror/lang-python';
import debug from 'debug';
import { fileLink } from './extensions/fileLink';
import { markdownLink } from './extensions/markdownLink';
// import { autoSuggestPlugin } from './extensions/autoSuggest';
// import { autoSuggestLink } from './extensions/autoSuggestLink';
import './editor.less';
import { addKogLineBreak, updateMarkdownValue } from './helper';

const log = debug('Editor');

const baseTheme = EditorView.baseTheme({
  '.cm-underline': { textDecoration: 'underline 3px red' },
  '.cm-editor.cm-focused': {
    outline: 'none'
  },
  '.cm-mdb-expression': {
    color: 'gray',
    fontStyle: 'italic'
  }
});

interface EditorProps {
  value: string;
  onChange: (value: string, viewUpdate: ViewUpdate) => void;
  extensions?: Extension[];
  className?: string;
  language?: string;
  markdown?: {
    onSave: (updatedValue: string) => void;
  };
  readOnly?: boolean;
}

export interface EditorRef {
  getSelection: () => string | undefined;
}

const englishExtensions: Extension[] = [
  // syntaxHighlighting(kogHighlightStyle),
  fileLink
  // TODO: enable this
  // autoSuggestPlugin,
  // autoSuggestLink
];

const pythonExtensions = [python()];
const commonExtensions = [
  baseTheme,
  EditorView.contentAttributes.of({
    'data-cy': 'editor',
    spellcheck: 'true'
  }),
  indentationMarkers()
];

const basicSetup = {
  highlightActiveLine: false,
  highlightActiveLineGutter: false,
  foldGutter: false,
  lineNumbers: false,
  history: true,
  indentOnInput: true
};

const Editor = forwardRef<EditorRef, EditorProps>((props, ref) => {
  const {
    value: defaultValue,
    onChange,
    className,
    extensions,
    language,
    markdown,
    readOnly
  } = props;

  const value = addKogLineBreak(defaultValue);

  const inputRef = useRef<ReactCodeMirrorRef>(null);
  const [ext, setExt] = useState<any>([]);

  useEffect(() => {
    (window as any).handleEditorMarkdownValueChange = (
      oldValue: string,
      newValue: string
    ) => {
      const updatedValue = updateMarkdownValue(value, oldValue, newValue);
      if (markdown?.onSave) {
        markdown.onSave(updatedValue);
      }
    };
  }, [value, markdown?.onSave]);

  useEffect(() => {
    if (language === 'python') {
      log('Language => python, reconfiguring the extension');
      setExt([...commonExtensions, ...pythonExtensions, ...(extensions || [])]);
    }

    if (language === 'english') {
      log('Language => english, reconfiguring the extension');
      const updatedExtensions = [
        ...commonExtensions,
        ...englishExtensions,
        ...(extensions || [])
      ];

      if (markdown) {
        setExt([...updatedExtensions, markdownLink]);
      } else {
        setExt(updatedExtensions);
      }
    }
  }, [language, markdown]);

  useImperativeHandle(
    ref,
    () => ({
      getSelection() {
        const state = inputRef.current?.view?.state;
        const selection = state?.sliceDoc(
          state?.selection.main.from,
          state?.selection.main.to
        );
        return selection;
      }
    }),
    []
  );

  return (
    <CodeMirror
      ref={inputRef}
      placeholder="Type something.."
      extensions={ext}
      value={value}
      basicSetup={basicSetup}
      className={`editor ${className}`}
      indentWithTab
      onChange={onChange}
      readOnly={readOnly}
    />
  );
});

Editor.defaultProps = {
  extensions: [],
  className: '',
  language: 'english',
  markdown: undefined,
  readOnly: false
};

Editor.displayName = 'Editor';

export default memo(Editor);
