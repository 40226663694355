import React from 'react';

export default function NameIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1562 484"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            fill="#333333"
            d="M88.98,322.1V88.54h16.78v141.78L241.95,88.54h19.41l-97.04,102.3L266.29,322.1h-19.41l-93.75-120.73
			l-47.37,48.69v72.04H88.98z"
          />
          <path
            fill="#333333"
            d="M355.11,325.39c-12.06,0-23.19-2.3-33.39-6.88c-10.2-4.58-19.03-10.98-26.48-19.17
			c-7.46-8.19-13.27-17.64-17.43-28.35c-4.17-10.71-6.25-21.96-6.25-33.76c0-12.02,2.08-23.32,6.25-33.92
			c4.16-10.6,10.03-19.99,17.6-28.19c7.57-8.19,16.45-14.64,26.65-19.34s21.22-7.05,33.06-7.05c11.84,0,22.81,2.35,32.9,7.05
			c10.08,4.7,18.97,11.14,26.65,19.34c7.67,8.19,13.59,17.59,17.76,28.19c4.16,10.6,6.25,21.9,6.25,33.92
			c0,11.8-2.09,23.05-6.25,33.76c-4.17,10.71-10.03,20.16-17.6,28.35c-7.57,8.19-16.45,14.59-26.65,19.17
			C377.97,323.09,366.95,325.39,355.11,325.39z M288,237.88c0,13.33,3.01,25.5,9.05,36.52c6.03,11.03,14.09,19.81,24.18,26.36
			c10.09,6.55,21.27,9.82,33.55,9.82c12.28,0,23.52-3.33,33.72-9.99c10.2-6.66,18.31-15.61,24.34-26.86
			c6.03-11.24,9.04-23.52,9.04-36.84c0-13.31-3.01-25.54-9.04-36.67c-6.04-11.13-14.14-20.03-24.34-26.69
			c-10.2-6.66-21.33-9.99-33.39-9.99c-12.28,0-23.47,3.39-33.55,10.15c-10.09,6.77-18.2,15.72-24.34,26.86
			C291.07,211.68,288,224.13,288,237.88z"
          />
          <path
            fill="#333333"
            d="M547.22,325.39c-11.63,0-22.43-2.41-32.4-7.24c-9.98-4.82-18.54-11.46-25.66-19.9
			c-7.13-8.44-12.72-17.87-16.78-28.29c-4.06-10.41-6.09-21.22-6.09-32.4c0-11.62,1.92-22.81,5.76-33.55
			c3.83-10.74,9.32-20.23,16.45-28.45c7.12-8.22,15.51-14.75,25.17-19.57c9.65-4.82,20.4-7.24,32.24-7.24
			c14.91,0,27.9,3.84,38.98,11.51c11.07,7.68,20.45,17.1,28.12,28.29v-37.17h15.13v174.11c0,15.33-3.73,28.25-11.18,38.76
			c-7.46,10.51-17.33,18.34-29.6,23.49c-12.28,5.14-25.55,7.72-39.8,7.72c-19.96,0-35.8-3.62-47.53-10.86
			c-11.74-7.24-21.22-16.78-28.45-28.62l11.18-7.57c6.8,11.4,16.01,19.85,27.63,25.33c11.62,5.48,24.01,8.22,37.17,8.22
			c11.62,0,22.31-2.12,32.07-6.34c9.76-4.23,17.54-10.52,23.36-18.86c5.81-8.35,8.72-18.59,8.72-30.73v-37.5
			c-6.8,11.18-16.07,20.12-27.8,26.81C572.16,322.05,559.94,325.39,547.22,325.39z M551.17,311.25c7.24,0,14.25-1.32,21.05-3.95
			c6.79-2.63,13.05-6.19,18.75-10.69c5.7-4.49,10.41-9.48,14.14-14.97c3.73-5.48,5.92-10.97,6.58-16.45v-56.25
			c-3.29-8.77-8.28-16.61-14.97-23.52c-6.69-6.91-14.2-12.28-22.53-16.12c-8.34-3.84-16.89-5.76-25.66-5.76
			c-10.31,0-19.52,2.2-27.63,6.58c-8.12,4.39-14.97,10.15-20.56,17.27c-5.59,7.13-9.87,15.13-12.83,24.01
			c-2.96,8.88-4.44,17.71-4.44,26.48c0,9.87,1.75,19.19,5.27,27.96c3.5,8.78,8.39,16.61,14.64,23.52
			c6.25,6.91,13.49,12.28,21.71,16.12C532.91,309.33,541.73,311.25,551.17,311.25z"
          />
          <path
            fill="#333333"
            d="M819.27,322.1h-16.45v-95c0-21.84-3.12-37.84-9.38-47.99c-6.25-10.16-16.17-15.23-29.77-15.23
			c-9.65,0-19.14,2.29-28.45,6.88c-9.32,4.58-17.44,10.85-24.34,18.82c-6.91,7.97-11.9,16.97-14.97,27V322.1h-16.45V151.37h15.46
			v40.95c4.61-8.74,10.74-16.33,18.42-22.77c7.67-6.44,16.23-11.52,25.66-15.24c9.43-3.71,19.19-5.57,29.28-5.57
			c9.43,0,17.38,1.7,23.85,5.08c6.47,3.39,11.73,8.25,15.79,14.58c4.05,6.34,6.96,14.2,8.72,23.6c1.75,9.4,2.63,20.1,2.63,32.12
			V322.1z"
          />
          <path fill="#333333" d="M868.94,322.1V151.04h16.45V322.1H868.94z" />
          <path
            fill="#333333"
            d="M1013.34,315.19c-1.1,0.44-3.29,1.54-6.58,3.29c-3.29,1.76-7.46,3.35-12.5,4.77
			c-5.05,1.43-10.64,2.14-16.78,2.14c-5.92,0-11.46-1.16-16.61-3.47c-5.16-2.31-9.27-5.79-12.34-10.42
			c-3.07-4.63-4.6-10.27-4.6-16.89V164.86h-24.01v-13.49h24.01V92.82h16.45v58.56h39.8v13.49h-39.8v125.86
			c0.44,6.4,2.74,11.21,6.91,14.41c4.16,3.2,8.99,4.8,14.47,4.8c6.79,0,12.72-1.15,17.76-3.45c5.04-2.3,8-3.79,8.88-4.44
			L1013.34,315.19z"
          />
          <path
            fill="#333333"
            d="M1114.99,325.39c-12.94,0-24.67-2.34-35.2-7.02c-10.53-4.68-19.63-11.1-27.3-19.27
			c-7.68-8.16-13.6-17.57-17.77-28.24c-4.17-10.66-6.25-21.88-6.25-33.63c0-11.97,2.08-23.29,6.25-33.96
			c4.17-10.66,10.09-20.08,17.77-28.24c7.67-8.16,16.83-14.58,27.47-19.26c10.63-4.68,22.42-7.02,35.36-7.02
			c12.72,0,24.34,2.34,34.87,7.02c10.53,4.68,19.68,11.1,27.47,19.26c7.78,8.16,13.76,17.58,17.93,28.24
			c4.16,10.67,6.25,21.99,6.25,33.96c0,11.75-2.09,22.97-6.25,33.63c-4.17,10.67-10.09,20.08-17.77,28.24
			c-7.68,8.16-16.83,14.59-27.47,19.27C1139.71,323.05,1127.93,325.39,1114.99,325.39z M1058.08,237.55
			c0,11.72,2.58,22.36,7.73,31.91c5.15,9.55,12.01,17.1,20.56,22.63c8.55,5.54,18.09,8.3,28.62,8.3s20.12-2.82,28.78-8.47
			c8.66-5.64,15.57-13.29,20.73-22.95c5.15-9.66,7.73-20.35,7.73-32.07c0-11.72-2.58-22.35-7.73-31.9
			c-5.16-9.55-12.06-17.15-20.73-22.79s-18.26-8.47-28.78-8.47s-20.07,2.88-28.62,8.63c-8.55,5.75-15.41,13.4-20.56,22.95
			C1060.65,214.87,1058.08,225.62,1058.08,237.55z"
          />
          <path
            fill="#333333"
            d="M1295.92,325.39c-14.26,0-27.63-2.36-40.14-7.07s-23.25-11.79-32.24-21.22l11.18-20.4
			c9.87,9.21,19.74,15.79,29.6,19.74c9.87,3.95,19.95,5.92,30.27,5.92c11.84,0,21.49-2.21,28.95-6.65
			c7.45-4.43,11.18-10.97,11.18-19.63c0-6.31-1.92-10.99-5.76-14.04c-3.84-3.05-9.27-5.61-16.28-7.67c-7.02-2.06-15.35-4.41-25-7.02
			c-11.84-3.48-21.82-7.18-29.93-11.1c-8.12-3.92-14.26-8.81-18.42-14.69c-4.17-5.88-6.25-13.28-6.25-22.21
			c0-10.88,2.85-20.08,8.55-27.59c5.7-7.51,13.54-13.23,23.52-17.14c9.98-3.92,21.33-5.88,34.05-5.88c12.72,0,24.23,1.97,34.54,5.92
			c10.3,3.95,19.19,9.76,26.65,17.44l-13.16,20.06c-6.8-7.02-14.26-12.22-22.37-15.62c-8.12-3.4-17.22-5.1-27.3-5.1
			c-5.92,0-11.74,0.71-17.44,2.12c-5.7,1.41-10.42,3.91-14.14,7.5c-3.73,3.59-5.59,8.65-5.59,15.17c0,5.44,1.48,9.73,4.44,12.88
			c2.96,3.16,7.34,5.82,13.16,7.99c5.81,2.18,12.88,4.57,21.22,7.18c12.94,3.7,24.18,7.39,33.72,11.09
			c9.54,3.7,16.94,8.48,22.21,14.35c5.26,5.87,7.89,14.03,7.89,24.46c0,16.09-6.14,28.65-18.42,37.67
			C1332.32,320.88,1316.09,325.39,1295.92,325.39z"
          />
          <rect
            x="868.94"
            y="92.82"
            fill="#333333"
            width="16.45"
            height="30.27"
          />
        </g>
        <g>
          <path
            fill="#333333"
            d="M1403.88,92.33h-21.45v54.76h-4.2V92.33h-21.45v-3.79h47.1V92.33z"
          />
          <path
            fill="#333333"
            d="M1465.98,147.1V96.7l-23.01,39.42h-2.81l-23.01-39.42v50.39h-4.21V88.54h4.21l24.41,41.89l24.41-41.89h4.21
			v58.56H1465.98z"
          />
        </g>
      </g>
    </svg>
  );
}
