// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-request-popup .question-group {
  border-radius: 8px;
  padding-bottom: 16px;
}
.view-request-popup .ant-layout.question {
  background: none;
}
.view-request-popup .header {
  display: flex;
}
.view-request-popup .header svg {
  width: 22px;
  height: 22px;
  margin-left: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/popups/ViewRequestPopup.less"],"names":[],"mappings":"AAEA;EAEI,kBAAA;EACA,oBAAA;AAFJ;AADA;EAOI,gBAAA;AAHJ;AAME;EACE,aAAA;AAJJ;AAMI;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AAJN","sourcesContent":["@import '@css/variables.less';\n\n.view-request-popup {\n  .question-group {\n    border-radius: 8px;\n    padding-bottom: 16px;\n  }\n\n  .ant-layout.question {\n    background: none;\n  }\n\n  & .header {\n    display: flex;\n\n    & svg {\n      width: 22px;\n      height: 22px;\n      margin-left: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
