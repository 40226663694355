// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditableS3Fact-module__editable-container__GQDUt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.EditableS3Fact-module__editable-container__GQDUt .EditableS3Fact-module__file-edit-container__ubj6q {
  margin-left: 8px;
  display: flex;
  height: 24px;
  align-items: center;
}
.EditableS3Fact-module__editable-container__GQDUt .EditableS3Fact-module__edit-actions-container__E33w9 {
  display: grid;
  grid-template-columns: 14px 14px;
  grid-gap: 16px;
  margin-right: 12px;
}
.EditableS3Fact-module__actions-container__x2UWD {
  display: grid;
  grid-template-columns: 14px 14px;
  grid-gap: 8px;
}
.EditableS3Fact-module__delete-container__JMSd5 {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.EditableS3Fact-module__delete-fact-container__B2KpA .EditableS3Fact-module__editable-container__GQDUt {
  background: rgba(255, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 4px;
}
.EditableS3Fact-module__delete-actions-container__AWFl1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 8px;
  margin-right: 12px;
}
.EditableS3Fact-module__delete-actions-container__AWFl1 .EditableS3Fact-module__delete-buttons-container__sHwfI {
  cursor: pointer;
  display: grid;
  grid-template-columns: 14px 14px;
  grid-gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/playground2/EditableS3Fact.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EACE,gBAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;AADJ;AAIE;EACE,aAAA;EACA,gCAAA;EACA,cAAA;EACA,kBAAA;AAFJ;AAME;EACA,aAAA;EACA,gCAAA;EACA,aAAA;AAJF;AAOE;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AALF;AASE;EACE,gCAAA;EACA,kBAAA;EACA,kBAAA;AAPJ;AAWA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,kBAAA;AATF;AAWE;EACE,eAAA;EACA,aAAA;EACA,gCAAA;EACA,cAAA;AATJ","sourcesContent":["@import '@css/variables.less';\n\n.editable-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  & .file-edit-container {\n    margin-left: 8px;\n    display: flex;\n    height: 24px;\n    align-items: center;\n  }\n\n  & .edit-actions-container {\n    display: grid;\n    grid-template-columns: 14px 14px;\n    grid-gap: 16px;\n    margin-right: 12px;\n  }\n}\n\n& .actions-container {\n  display: grid;\n  grid-template-columns: 14px 14px;\n  grid-gap: 8px;\n}\n\n& .delete-container {\n  display: flex;\n  flex-direction: column;\n  border-radius: 5px;\n}\n\n.delete-fact-container {\n  & .editable-container {\n    background: rgba(255, 0, 0, 0.1);\n    border-radius: 5px;\n    margin-bottom: 4px;\n  }\n}\n\n.delete-actions-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-left: 8px;\n  margin-right: 12px;\n\n  & .delete-buttons-container {\n    cursor: pointer;\n    display: grid;\n    grid-template-columns: 14px 14px;\n    grid-gap: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editable-container": `EditableS3Fact-module__editable-container__GQDUt`,
	"file-edit-container": `EditableS3Fact-module__file-edit-container__ubj6q`,
	"edit-actions-container": `EditableS3Fact-module__edit-actions-container__E33w9`,
	"actions-container": `EditableS3Fact-module__actions-container__x2UWD`,
	"delete-container": `EditableS3Fact-module__delete-container__JMSd5`,
	"delete-fact-container": `EditableS3Fact-module__delete-fact-container__B2KpA`,
	"delete-actions-container": `EditableS3Fact-module__delete-actions-container__AWFl1`,
	"delete-buttons-container": `EditableS3Fact-module__delete-buttons-container__sHwfI`
};
export default ___CSS_LOADER_EXPORT___;
