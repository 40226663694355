// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SupportMenu-module__accountMenu__tqoJu {
  border-radius: 2px;
  background: #e5e5eb;
}
.SupportMenu-module__supportMenu__ewsAB {
  border-radius: 2px;
  background: #e5e5eb;
  padding: 2px 0;
  width: 180px !important;
}
.SupportMenu-module__supportMenuItem__XjJHr {
  background: #e5e5eb;
  margin: 0 !important;
  display: flex;
  height: 28px !important;
  width: 180px !important;
  padding: 0px 7px 0px 12px !important;
  align-items: center;
  justify-content: flex-start;
  transition: none !important;
}
.SupportMenu-module__supportMenuItem__XjJHr:hover {
  background: #dbdbe3 !important;
  border-radius: 0 !important;
}
.SupportMenu-module__supportMenuItemContent__t98I8 {
  margin: 0 !important;
  display: flex;
  height: 28px;
  width: 180px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/SupportMenu/SupportMenu.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;AACF;AAEA;EACE,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,uBAAA;AAAF;AAGA;EACE,mBAAA;EACA,oBAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,oCAAA;EACA,mBAAA;EACA,2BAAA;EACA,2BAAA;AADF;AAIA;EACE,8BAAA;EACA,2BAAA;AAFF;AAKA;EACE,oBAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,2BAAA;EACA,gBAAA;AAHF","sourcesContent":[".accountMenu {\n  border-radius: 2px;\n  background: #e5e5eb;\n}\n\n.supportMenu {\n  border-radius: 2px;\n  background: #e5e5eb;\n  padding: 2px 0;\n  width: 180px !important;\n}\n\n.supportMenuItem {\n  background: #e5e5eb;\n  margin: 0 !important;\n  display: flex;\n  height: 28px !important;\n  width: 180px !important;\n  padding: 0px 7px 0px 12px !important;\n  align-items: center;\n  justify-content: flex-start;\n  transition: none !important;\n}\n\n.supportMenuItem:hover {\n  background: #dbdbe3 !important;\n  border-radius: 0 !important;\n}\n\n.supportMenuItemContent {\n  margin: 0 !important;\n  display: flex;\n  height: 28px;\n  width: 180px;\n  align-items: center;\n  justify-content: flex-start;\n  border-radius: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountMenu": `SupportMenu-module__accountMenu__tqoJu`,
	"supportMenu": `SupportMenu-module__supportMenu__ewsAB`,
	"supportMenuItem": `SupportMenu-module__supportMenuItem__XjJHr`,
	"supportMenuItemContent": `SupportMenu-module__supportMenuItemContent__t98I8`
};
export default ___CSS_LOADER_EXPORT___;
