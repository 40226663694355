import React from 'react';
import classNames from 'classnames';
import { CopyOutlined } from '@ant-design/icons';
import styles from './LearningMethod.module.less';
import MarkdownRenderer from '../../MarkdownRenderer';

interface ILearningMethodProps {
  header?: string;
  body: string;
  onClick?: () => void;
  onTryNowClick?: () => void;
}

function LearningMethod(props: ILearningMethodProps) {
  const { header, body, onClick, onTryNowClick } = props;

  return (
    <div className={styles.learningMethod} onClick={onClick}>
      {header && (
        <div className={styles.learningMethodHeader}>
          <div>{header}</div>
          <CopyOutlined
            className={classNames(
              styles.tryNowButton,
              'learning-method-try-now'
            )}
            onClick={(e) => {
              e.stopPropagation();
              onTryNowClick?.();
            }}
          />
        </div>
      )}
      <div className={styles.learningMethodBody}>
        <MarkdownRenderer>{body}</MarkdownRenderer>
      </div>
    </div>
  );
}

export default LearningMethod;
