import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  Department,
  ListAllDepartmentsQuery,
  ListAllDepartmentsQueryVariables
} from '@/generated/API';
import { RootState, AsyncThunkConfig } from '@/stores/AppStore';
import Queries from '@/graphql/Queries';
import { FetchResult } from '@apollo/client';
import { orderBy } from 'lodash/fp';

export interface GetSupportDepartmentsParams
  extends ListAllDepartmentsQueryVariables {
  page?: number;
}

export const getAllSupportDepartments = createAsyncThunk<
  FetchResult<ListAllDepartmentsQuery>,
  GetSupportDepartmentsParams,
  AsyncThunkConfig
>('supportDepartment/fetchAll', async ({ limit, nextToken }, thunkAPI) => {
  const {
    extra: { appSyncClient }
  } = thunkAPI as any;
  return appSyncClient.query({
    query: Queries.ListAllDepartments(),
    variables: {
      limit,
      nextToken
    }
  });
});

const SupportDepartmentAdapter = createEntityAdapter<Department>();

interface IState {
  loading: boolean;
  error: any;
  nextToken: string | undefined;
  pageDataMap: Record<number, Department[]>;
}

export const supportDepartmentSlice = createSlice({
  name: 'supportDepartment',
  initialState: SupportDepartmentAdapter.getInitialState<IState>({
    loading: false,
    error: undefined,
    nextToken: undefined,
    pageDataMap: {}
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllSupportDepartments.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllSupportDepartments.fulfilled,
      (state, { payload, meta }) => {
        if (payload?.data?.listAllDepartments) {
          const { items = [], nextToken } = payload.data.listAllDepartments;
          const departments = items as Department[];

          if (!meta.arg.nextToken) {
            SupportDepartmentAdapter.setAll(state, departments);
          } else {
            SupportDepartmentAdapter.addMany(state, departments);
          }

          if (meta.arg.page) {
            state.pageDataMap[meta.arg.page] = departments;
          }

          state.nextToken = nextToken || undefined;
        }
        state.loading = false;
      }
    );
    builder.addCase(getAllSupportDepartments.rejected, (state, { error }) => {
      state.error = error as any;
      state.loading = false;
    });
  }
});

export const supportDepartmentsSelectors =
  SupportDepartmentAdapter.getSelectors<RootState>(
    (state) => state.supportDepartment
  );

export const supportDepartmentQuerySelector = (state: RootState) => {
  const allDeps = orderBy(
    (dep) => new Date(dep.createdAt!),
    ['desc'],
    supportDepartmentsSelectors.selectAll(state)
  );

  return {
    loading: state.supportDepartment.loading,
    supportDepartments: allDeps,
    error: state.supportDepartment.error,
    nextToken: state.supportDepartment.nextToken,
    pageDataMap: state.supportDepartment.pageDataMap
  };
};

export default supportDepartmentSlice.reducer;
