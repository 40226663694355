/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import './InlineInfiniteLoader.less';

interface IProps {
  children: React.ReactNode;
  active: boolean;
}

function InlineInfiniteLoader(props: IProps) {
  const { children, active } = props;

  if (!active) {
    return <>{children}</>;
  }

  return (
    <div className="inline-infinite-loader">
      {children}
      <div className="loader" />
    </div>
  );
}

export default InlineInfiniteLoader;
