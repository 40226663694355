import { BaseEditor, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

type EditorInstance = BaseEditor & ReactEditor & HistoryEditor;

// Added to fix KOG-5576
export const withCustomNormalization = (editor: EditorInstance) => {
  const { normalizeNode } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // Check if the node is an element and has no children
    // @ts-ignore
    if (node.children && node.children.length === 0) {
      console.log('No children node', node);
      // remove the node or add a default child, we can also add a default node instead of removing
      Transforms.removeNodes(editor, { at: path });
    } else {
      // Normalize the rest of the document
      normalizeNode(entry);
    }
  };

  return editor;
};
