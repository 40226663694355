//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// AppLeftNav is the application header component
//

// 3rd party libraries
import React, { useState } from 'react';
import { Menu, Tag, Tooltip, Typography, theme } from 'antd';

// Local imports
import i18n from '@utils/i18n';
import { useLocation, NavLink } from 'react-router-dom';
import './AppLeftNav.less';
import { appConfig } from '@/AppConfig';
import { useSelector } from 'react-redux';
import { departmentQuerySelector } from '@/stores/slices/department';
import { useAppSelector } from '@/stores/hooks';
import Sider from 'antd/es/layout/Sider';
import { useQuery, useReactiveVar } from '@apollo/client';
import PlaygroundIcon from '@/components/icons/PlaygroundIcon';
import ProcessIcon from '@/components/icons/ProcessIcon';
import ExceptionIcon from '@/components/icons/ExceptionIcon';
import DepartmentIcon from '@/components/icons/DepartmentIcon';
import HomeIcon from '@/components/icons/HomeIcon';
import BooksIcon from '@/components/icons/BooksIcon';
import { currentUserDetailsVar } from '@/graphql/cache/user';
import SupportIcon from '@/components/icons/SupportIcon';
import classnames from 'classnames';
import SupportMenu from '@/components/SupportMenu/SupportMenu';
import PortalContainer from '@/components/PortalContainer';
import routes from '../utils/routes';
import AppUtil from '../utils/AppUtil';
import { userSelector } from '../stores/slices/user';
import { GET_EXCEPTIONS_STATUS_COUNTS } from '../graphql/exception';
import {
  ListQuestionsByDepartmentQuery,
  RequestQuestionValueCounts
} from '../generated/API';
import { ExceptionStateType } from '../pages/exceptions/interface';
import { prepareExceptionStatusCounts } from '../transformers/exception';
import styles from './AppLeftNav.module.less';

// Component i18n
const i18nt = (s: string, d?: string) => i18n.t('AppLeftNav', s, d);

export const COLLAPSED_WIDTH = 72;

enum ActionComponents {
  SUPPORT = 'SUPPORT'
}

interface ILeftNavProps {
  top: number;
}

// Component implementation
function AppLeftNav(props: ILeftNavProps) {
  const { top } = props;
  const { isDemoUser } = useReactiveVar(currentUserDetailsVar);
  const { token } = theme.useToken();
  const { username } = useSelector(userSelector);
  const { department, loading } = useAppSelector(departmentQuerySelector);

  const location = useLocation();
  const { environment } = appConfig.backend;

  const [actionComponent, setActionComponent] = useState<string | undefined>();

  const handleBottomMenuItemClicked = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    setActionComponent((prev) => {
      if (prev !== id) {
        return id;
      }
      return undefined;
    });
  };

  const { data: statusCountsData, refetch: refetchStatusCounts } =
    useQuery<ListQuestionsByDepartmentQuery>(GET_EXCEPTIONS_STATUS_COUNTS, {
      variables: {
        input: {
          departmentId: department?.id,
          filters: {
            state: [ExceptionStateType.PENDING],
            requestAssignee: [username]
          }
        }
      },
      skip: !department?.id || !username
    });

  const valueCounts = (statusCountsData?.listQuestionsByDepartment as any)
    ?.valueCounts as RequestQuestionValueCounts | undefined;

  const statusCounts = prepareExceptionStatusCounts(valueCounts);

  const shouldShowEnvMessage = () => {
    const hide = AppUtil.getFromLocalStorage('HIDE_ENV_MESSAGE');
    if (hide === 'true') {
      return false;
    }
    return !AppUtil.isProdEnv();
  };

  if (!username) {
    return null;
  }

  const departmentLoading = loading && !department;

  const iconStyle = { fontSize: '28px', width: '28px', height: '28px' };

  const pages = [
    {
      id: 'playground',
      label: i18nt(`page_playground`),
      url: routes.playground.getPlaygroundPageUrl(department?.id),
      isExternal: false,
      disabled: departmentLoading,
      icon: <PlaygroundIcon style={iconStyle} />
    },
    {
      id: 'processes',
      label: i18nt(`page_sme_procedures`),
      url: routes.procedure.getProcedureHomePageUrl(),
      isExternal: false,
      disabled: false,
      icon: <ProcessIcon style={iconStyle} />
    },

    {
      id: 'exceptions',
      label: i18nt(`page_sme_exceptions`),
      url: routes.exceptions.getExceptionsPageUrl(),
      isExternal: false,
      disabled: false,
      icon: <ExceptionIcon style={iconStyle} />,
      badge: {
        count: statusCounts.pending,
        title: `${statusCounts.pending} pending exceptions`
      }
    },
    {
      id: 'books',
      label: i18nt(`page_sme_books`) || 'Books',
      url: routes.books.getBooksPageUrl(),
      isExternal: false,
      disabled: false,
      icon: <BooksIcon style={iconStyle} />
    },
    {
      id: 'department',
      label: i18nt(`page_sme_department`),
      url: routes.department.getDepartmentPageUrl(),
      isExternal: false,
      disabled: false,
      icon: <DepartmentIcon style={iconStyle} />
    }
  ].filter(Boolean);

  if (!isDemoUser) {
    pages.unshift({
      id: 'dashboard',
      label: i18nt(`page_sme_dashboard`),
      url: routes.dashboard.getDashboardPageUrl(),
      isExternal: false,
      disabled: false,
      icon: <HomeIcon style={iconStyle} />
    });
  }

  const currentPage = pages.find((page) => {
    if (location.pathname.includes('/playground')) {
      return page.id === 'playground';
    }

    const key = location.pathname.split('/')[1];
    return key === page.id;
  });
  const { id } = currentPage || { id: '' };

  const handleCustomTrigger = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Sider
      collapsed
      trigger={null}
      collapsedWidth={COLLAPSED_WIDTH}
      onClick={handleCustomTrigger}
      style={{
        background: AppUtil.isTestEnv() ? token.blue : '#DBDBE3',
        overflow: 'auto',
        height: `calc(100vh - ${top}px)`,
        position: 'fixed',
        width: '72px !important',
        left: 0,
        top,
        bottom: 0
      }}
    >
      {/* <NewPlayground isCollapsed /> */}
      <Menu
        key={id}
        mode="inline"
        className={styles.mainMenu}
        defaultSelectedKeys={[id]}
        onClick={() => {
          refetchStatusCounts();
        }}
        items={pages.map((page) => {
          const label = (
            <NavLink
              to={page.url}
              onClick={(e) => e.stopPropagation()}
              className={styles.menuItemLink}
            >
              <span
                data-cy={`nav-${page.id}`}
                className={classnames([
                  styles.menuLinkContent,
                  {
                    [styles.menuLinkContentActive]: id === page.id
                  }
                ])}
              >
                {page.icon}
                {page.label}
              </span>
            </NavLink>
          );

          return {
            label,
            // this is to not show the tooltip
            title: '',
            key: page.id,
            className: styles.menuItem,
            disabled: page.disabled
          };
        })}
      />
      <div className={styles.bottomMenu}>
        {shouldShowEnvMessage() && (
          <div className={styles.envContainer}>
            <Tag
              style={{
                width: '60px',
                margin: '0 6px',
                borderRadius: '1px',
                background: '#D1D1DB',
                textAlign: 'center'
              }}
            >
              <Tooltip
                title={`This app is running in the ${environment} test environment.`}
                placement="right"
              >
                <Typography.Text
                  ellipsis
                  style={{
                    maxWidth: COLLAPSED_WIDTH,
                    color: '#595973'
                  }}
                >
                  {environment}
                </Typography.Text>
              </Tooltip>
            </Tag>
          </div>
        )}
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleBottomMenuItemClicked(e, ActionComponents.SUPPORT);
          }}
          className={styles.menuItemLink}
        >
          <span
            className={classnames([
              styles.menuLinkContent,
              {
                [styles.menuLinkContentActive]:
                  actionComponent === ActionComponents.SUPPORT
              }
            ])}
          >
            <SupportIcon style={iconStyle} />
            Support
          </span>
        </div>
      </div>

      {actionComponent === ActionComponents.SUPPORT ? (
        <PortalContainer
          style={{
            left: COLLAPSED_WIDTH,
            bottom: 16
          }}
          hide={() => setActionComponent(undefined)}
        >
          <SupportMenu hide={() => setActionComponent(undefined)} />
        </PortalContainer>
      ) : null}
    </Sider>
  );
}

export default AppLeftNav;
