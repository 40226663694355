import React, { useState, useEffect } from 'react';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { Button, Input, Space, Typography, List } from 'antd';
import {
  PingInput,
  OnPingSubscription,
  OnPingSubscriptionVariables,
  PingMutation
} from '../generated/API';

const { Title } = Typography;

const PING_MUTATION = gql`
  mutation Ping($channel: String!, $data: String) {
    ping(input: { channel: $channel, data: $data }) {
      channel
      data
    }
  }
`;

const ON_PING_SUBSCRIPTION = gql`
  subscription OnPing($channel: String!) {
    onPing(channel: $channel) {
      channel
      data
    }
  }
`;

function MessageList({ channel }: { channel: string }) {
  const { data: subscriptionData } = useSubscription<
    OnPingSubscription,
    OnPingSubscriptionVariables
  >(ON_PING_SUBSCRIPTION, {
    variables: { channel }
  });
  const [messages, setMessages] = useState<{ channel: string; data: string }[]>(
    []
  );

  useEffect(() => {
    if (subscriptionData?.onPing) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          channel: `${subscriptionData.onPing?.channel}`,
          data: `${subscriptionData.onPing?.data}`
        }
      ]);
    }
  }, [subscriptionData]);

  return (
    <div>
      <p>Messages:</p>
      <List
        dataSource={messages}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta title={item.channel} description={item.data} />
          </List.Item>
        )}
      />
    </div>
  );
}

function TestPage() {
  const [channel, setChannel] = useState('1');
  const [data, setData] = useState('hello world');
  const [ping, { data: responseData }] = useMutation<PingMutation, PingInput>(
    PING_MUTATION
  );
  const [subscriptions, setSubscriptions] = useState<string[]>([]);

  const handleSubscribe = () => {
    setSubscriptions((prevSubscriptions) => [...prevSubscriptions, channel]);
  };

  const handlePing = () => {
    ping({ variables: { channel, data } });
  };

  return (
    <div>
      <Title level={2}>Test Page</Title>
      <Space direction="vertical">
        <Input
          placeholder="Channel"
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
        />
        <Input
          placeholder="Data"
          value={data}
          onChange={(e) => setData(e.target.value)}
        />
        <Button type="primary" onClick={handlePing}>
          Ping
        </Button>
        {responseData && (
          <div>
            <p>Response:</p>
            <p>Channel: {responseData.ping.channel}</p>
            <p>Data: {responseData.ping.data}</p>
          </div>
        )}
      </Space>
      <Title level={2}>Subscribe</Title>
      <Input
        placeholder="Channel"
        value={channel}
        onChange={(e) => setChannel(e.target.value)}
      />
      <Button type="primary" onClick={handleSubscribe}>
        subscribe
      </Button>
      {subscriptions.map((channel) => (
        <MessageList key={channel} channel={channel} />
      ))}
    </div>
  );
}

export default TestPage;
