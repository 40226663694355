import React from 'react';
import Icon from '@ant-design/icons';

function SidebarSvg() {
  return (
    <svg
      stroke="#748F9E"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
      <line x1="9" y1="3" x2="9" y2="21" />
    </svg>
  );
}

export default function SidebarIcon(props: { style?: React.CSSProperties }) {
  return <Icon component={SidebarSvg} {...props} />;
}
