//
// Copyright (C) - Kognitos, Inc. All rights reserved
// This is the Drawer state reducer for the application.
//
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../AppStore';

export interface IDrawer {
  currentDrawerId: any; // TODO: should be an enum
  currentDrawerParams: any;
}

const initialState: IDrawer = {
  currentDrawerId: null,
  currentDrawerParams: null
};

export const appDrawerSlice = createSlice({
  name: 'appDrawer',
  initialState,
  reducers: {
    showDrawer: (
      state,
      action: PayloadAction<{ drawerId: string; drawerParams: any }>
    ) => {
      state.currentDrawerId = action.payload.drawerId;
      state.currentDrawerParams = action.payload.drawerParams;
    },
    hideDrawer: (state) => {
      state.currentDrawerId = null;
      state.currentDrawerParams = null;
    }
  }
});

export const { showDrawer, hideDrawer } = appDrawerSlice.actions;

export const drawerSelector = (state: RootState) => state.drawers;

export default appDrawerSlice.reducer;
