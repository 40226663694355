import React from 'react';
import { Typography } from 'antd';
import './FactSummaryV2.less';
import useFactV2 from '@/hooks/useFactV2';
import FormattingUtil, {
  FormattedAnswerTypeV2
} from '../../utils/FormattingUtil';
import { useAppDispatch } from '../../stores/hooks';
import AppConstants from '../../utils/AppConstants';
import { showPopup } from '../../stores/slices/appPopup';
import AnswerObjectViewer from '../AnswerObjectViewer';
import AnswerS3FileViewer from '../AnswerS3FileViewer';

interface IProps {
  factId: string;
  knowledgeId: string;
  ellipsis?: boolean;
}

function FactSummaryV2(props: IProps) {
  const { factId, knowledgeId, ellipsis = true } = props;

  const { getFactById, loading } = useFactV2({
    ids: [factId],
    knowledgeId
  });
  const dispatch = useAppDispatch();

  const fact = getFactById(factId);

  const parsedValue = FormattingUtil.parseBrainValue(fact?.value || '');
  const firstName = fact?.names?.[0];
  const value = parsedValue ?? firstName;

  if (loading) {
    return <>...</>;
  }

  const factAnswer = FormattingUtil.getFormattedAnswerV2({
    answer: value
  });

  const renderFact = () => {
    if (factAnswer.type === FormattedAnswerTypeV2.TABLES_ROW) {
      return <AnswerObjectViewer object={value} />;
    }
    if (factAnswer.type === FormattedAnswerTypeV2.S3) {
      return <AnswerS3FileViewer answer={factAnswer.answer} />;
    }
    return (
      <Typography.Text ellipsis={ellipsis} className="fact-summary-text">
        {factAnswer.answer}
      </Typography.Text>
    );
  };

  return (
    // eslint-disable-next-line react/no-unknown-property
    <span className="fact" kog-dbg-fact-id={factId}>
      <div
        role="link"
        tabIndex={0}
        onDoubleClick={(e) => {
          e.stopPropagation();
          dispatch(
            showPopup({
              popupId: AppConstants.POPUPS.VIEW_RAW_FACT,
              popupParams: {
                factId,
                knowledgeId
              }
            })
          );
        }}
      >
        {renderFact()}
      </div>
    </span>
  );
}

export default FactSummaryV2;
