import React from 'react';
import Icon from '@ant-design/icons';

function DepartmentSvg(props: any) {
  const { fill } = props;
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2 6.35H6.8C6.55147 6.35 6.35 6.55147 6.35 6.8V21.2C6.35 21.4485 6.55147 21.65 6.8 21.65H21.2C21.4485 21.65 21.65 21.4485 21.65 21.2V6.8C21.65 6.55147 21.4485 6.35 21.2 6.35ZM6.8 5C5.80589 5 5 5.80589 5 6.8V21.2C5 22.1941 5.80589 23 6.8 23H21.2C22.1941 23 23 22.1941 23 21.2V6.8C23 5.80589 22.1941 5 21.2 5H6.8Z"
        fill={fill || '#303048'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3003 23L11.3003 5L12.6503 5L12.6503 23L11.3003 23Z"
        fill={fill || '#303048'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 14.0001L23 14.0001L23 15.3501L5 15.3501L5 14.0001Z"
        fill={fill || '#303048'}
      />
    </svg>
  );
}

export default function DepartmentIcon(props: {
  style?: React.CSSProperties;
  fill?: string;
  height?: number;
  width?: number;
}) {
  return <Icon component={() => DepartmentSvg(props)} {...props} />;
}
