//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// File content specific utility functions
//

// 3rd party libraries
import React from 'react';
import {
  ApartmentOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileUnknownOutlined
} from '@ant-design/icons';

const ContentUtil = {
  iconForExtension(extension: string) {
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return <FileImageOutlined />;
      case 'pdf':
        return <FilePdfOutlined />;
      case 'txt':
        return <FileTextOutlined />;
      case 'csv':
        return <FileExcelOutlined />;
      case 'yml':
      case 'yaml':
      case 'json':
        return <ApartmentOutlined />;
      default:
        return <FileUnknownOutlined />;
    }
  },

  iconForContentType(contentType: string) {
    switch (contentType) {
      case 'image/jpeg':
      case 'image/png':
        return <FileImageOutlined />;
      case 'application/pdf':
        return <FilePdfOutlined />;
      case 'text/plain':
        return <FileTextOutlined />;
      default:
        return <FileUnknownOutlined />;
    }
  }
};

export default ContentUtil;
