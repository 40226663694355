import throttle from 'lodash/throttle';
import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Rect, KonvaNodeEvents, Transformer } from 'react-konva';
import { useSelector } from 'react-redux';
import { last } from 'lodash';
import { useAppDispatch } from '../../stores/hooks';
import {
  annotationSelector,
  selectAnnotation,
  updateAnnotation,
  updateAnnotations as updateGlobalAnnotations
} from '../../stores/slices/annotation';

const HIGHLIGHT_FILL_COLOUR = '#722ED14D';

export interface IAnnotation {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  pageNumber: number;
  textContent: string;
  borderColor?: string;
  fillColor?: string;
  allowDelete?: boolean;
  loading?: boolean;
}

interface IAnnotationRectangleProps {
  annotation: IAnnotation;
  isSelected: boolean;
  onSelect: () => void;
  onTransform?: () => void;
  onChange: (newAttrs: IAnnotation) => void;
}

function AnnotationRectangle(props: IAnnotationRectangleProps) {
  const { annotation, isSelected, onSelect, onTransform, onChange } = props;

  const shapeRef = useRef<any>(null);
  const trRef = useRef<any>(null);

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      if (trRef.current) {
        trRef.current.nodes([shapeRef.current]);
      }
      if (shapeRef.current) {
        shapeRef.current.getLayer().batchDraw();
        shapeRef.current.fill(HIGHLIGHT_FILL_COLOUR);
      }
    } else {
      if (shapeRef.current) {
        if (!annotation.fillColor) {
          shapeRef.current.fill(undefined);
        }
      }
    }
  }, [isSelected]);

  const handleTransformEnd: KonvaNodeEvents['onTransformEnd'] = () => {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const node = shapeRef.current as any;
    if (node) {
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      // we will reset it back
      node.scaleX(1);
      node.scaleY(1);
      onChange({
        ...annotation,
        x: node.x(),
        y: node.y(),
        // set minimal value
        width: Math.max(5, node.width() * scaleX),
        height: Math.max(node.height() * scaleY)
      });
    }
  };

  const handleSelect: KonvaNodeEvents['onClick'] = () => {
    onSelect();
  };

  return (
    <>
      <Rect
        ref={shapeRef}
        x={annotation.x}
        y={annotation.y}
        width={annotation.width}
        height={annotation.height}
        fill={annotation.fillColor}
        stroke={annotation.borderColor}
        onClick={handleSelect}
        draggable
        onDragStart={() => {
          onChange({
            ...annotation,
            fillColor: HIGHLIGHT_FILL_COLOUR
          });
        }}
        onDragEnd={(e) => {
          onChange({
            ...annotation,
            x: e.target.x(),
            y: e.target.y(),
            fillColor: undefined
          });
        }}
        onTransformEnd={handleTransformEnd}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
          onTransform={onTransform}
        />
      )}
    </>
  );
}

interface IAnnotationsCanvasProps {
  width: number;
  height: number;
  pageNumber: number;
  allowMultiple: boolean;
  getAnnotationText?: (annotation: IAnnotation) => Promise<string>;
  onNewAnnotation?: (annotation: IAnnotation) => void;
}

function AnnotationsCanvas(props: IAnnotationsCanvasProps) {
  const {
    width,
    height,
    pageNumber,
    allowMultiple,
    getAnnotationText,
    onNewAnnotation
  } = props;

  const { annotations: annotationsState, selectedAnnotationId } =
    useSelector(annotationSelector);
  const dispatch = useAppDispatch();

  const annotations = annotationsState.filter(
    (annotation) => annotation.pageNumber === pageNumber
  );

  const [newAnnotation, setNewAnnotation] = useState<IAnnotation | null>(null);

  const updateAnnotations = (ans: IAnnotation[]) => {
    const validAnnotations = ans.filter((an) => an.width > 0 && an.height > 0);

    const lastAnnotation =
      validAnnotations.length === 0 ? [] : [last(validAnnotations)!];

    dispatch(
      updateGlobalAnnotations(allowMultiple ? validAnnotations : lastAnnotation)
    );
  };

  const updateSingleAnnotation = (annotation: IAnnotation) => {
    dispatch(
      updateAnnotation({
        id: annotation.id,
        changes: annotation
      })
    );
  };

  const generateRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor}`;
  };

  const checkDeselect = (e: any) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      dispatch(selectAnnotation(null));
    }
  };

  const handleMouseDown: KonvaNodeEvents['onMouseDown'] = (event) => {
    checkDeselect(event);

    if (!newAnnotation) {
      const position = event.target.getStage()?.getPointerPosition();
      if (position) {
        const { x, y } = position!;
        setNewAnnotation({
          id: new Date().getTime().toString(),
          x,
          y,
          width: 0,
          height: 0,
          pageNumber,
          fillColor: HIGHLIGHT_FILL_COLOUR,
          textContent: ''
        });
      }
    }
  };

  const handleAnnotationTextLifecycle = (annotation: IAnnotation) => {
    if (getAnnotationText && annotation.width > 0 && annotation.height > 0) {
      updateSingleAnnotation({
        ...annotation,
        loading: true
      });

      getAnnotationText(annotation)
        .then((text) => {
          updateSingleAnnotation({
            ...annotation,
            textContent: text,
            loading: false
          });
        })
        .catch(() => {
          updateSingleAnnotation({
            ...annotation,
            loading: false
          });
        });
    }
  };

  const handleMouseUp: KonvaNodeEvents['onMouseUp'] = (event) => {
    if (newAnnotation) {
      const sx = newAnnotation.x;
      const sy = newAnnotation.y;
      const position = event.target.getStage()?.getPointerPosition();
      if (position) {
        const { x, y } = position;
        const width = x - sx;
        const height = y - sy;

        const updatedAnnotation: IAnnotation = {
          ...newAnnotation,
          width,
          height,
          fillColor: undefined,
          borderColor: generateRandomColor(),
          textContent: '',
          allowDelete: true
        };

        updateAnnotations([...annotations, updatedAnnotation]);
        setNewAnnotation(null);

        if (onNewAnnotation) {
          onNewAnnotation(updatedAnnotation);
        }

        handleAnnotationTextLifecycle(updatedAnnotation);
      }
    }
  };

  const handleMouseMove: KonvaNodeEvents['onMouseMove'] = (event) => {
    if (newAnnotation) {
      const sx = newAnnotation.x;
      const sy = newAnnotation.y;
      const position = event.target.getStage()?.getPointerPosition();
      if (position) {
        const { x, y } = position;
        setNewAnnotation({
          ...newAnnotation,
          width: x - sx,
          height: y - sy
        });
      }
    }
  };

  const throttledMouseMove = throttle(handleMouseMove, 500);

  return (
    <Stage
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={throttledMouseMove}
      width={width}
      height={height}
      onDragStart={() => setNewAnnotation(null)}
    >
      <Layer>
        {[...annotations, newAnnotation].filter(Boolean).map((annotation) => {
          const rect = annotation as IAnnotation;
          return (
            <AnnotationRectangle
              key={rect.id}
              annotation={rect}
              isSelected={rect.id === selectedAnnotationId}
              onSelect={() => {
                dispatch(selectAnnotation(rect.id));
              }}
              onChange={(updatedAnnotation) => {
                const newAnnotations = annotations.map((a) => {
                  if (a.id === updatedAnnotation.id) {
                    return updatedAnnotation;
                  }
                  return a;
                });
                updateAnnotations(newAnnotations);
                handleAnnotationTextLifecycle(updatedAnnotation);
              }}
              onTransform={() => {
                setNewAnnotation(null);
              }}
            />
          );
        })}
      </Layer>
    </Stage>
  );
}

export default AnnotationsCanvas;
