//
// Copyright (C) - Kognitos, Inc. All rights reserved
// GraphQL mutations. Client Side templates
//
import { gql } from '@apollo/client';
import {
  createKChatMessage,
  createLearnedAnswer,
  createOrganization,
  createProcedure,
  createServiceCommand,
  createSharedEntity,
  deleteDepartment,
  deleteLearning,
  forgetBooks,
  importDepartment,
  learnBooks,
  removeUserFromDepartment,
  rollbackDepartmentVersion,
  shareProcedure,
  updateDepartment,
  updateLearnedAnswer,
  updateProcedureSchedule,
  upsertUserToDepartment
} from '../generated/graphql/mutations';
import {
  createDepartment,
  rollbackDepartment,
  upgradeDepartment
} from './department';
import { CREATE_WORKER } from './worker';
import { CREATE_COMMAND } from './command';
import { CREATE_REQUEST_ANSWER } from './request';

const Mutations = {
  // Create a worker
  CreateWorker() {
    return gql(CREATE_WORKER);
  },

  CreateRequestAnswer() {
    return CREATE_REQUEST_ANSWER;
  },

  CreateLearnedAnswer() {
    return gql(createLearnedAnswer);
  },

  UpdateLearnedAnswer() {
    return gql(updateLearnedAnswer);
  },

  // Create a procedure
  CreateProcedure() {
    return gql(createProcedure);
  },

  CreateSharedEntity() {
    return gql(createSharedEntity);
  },

  // Share a procedure
  ShareProcedure() {
    return gql(shareProcedure);
  },

  // Create a new command
  CreateCommand() {
    return gql(CREATE_COMMAND);
  },

  // Create a service command
  CreateServiceCommand() {
    return gql(createServiceCommand);
  },

  // Delete a learning
  DeleteLearning() {
    return gql(deleteLearning);
  },

  // Create organization
  CreateOrganization() {
    return gql(createOrganization);
  },

  // Create a new department
  CreateDepartment() {
    return gql(createDepartment);
  },

  UpgradeDepartment() {
    return gql(upgradeDepartment);
  },

  RollbackDepartment() {
    return gql(rollbackDepartment);
  },

  // Delete department
  DeleteDepartment() {
    return gql(deleteDepartment);
  },

  // Update department
  UpdateDepartment() {
    return gql(updateDepartment);
  },

  // Rollback Dept to a previous version
  RollbackDepartmentVersion() {
    return gql(rollbackDepartmentVersion);
  },

  // Add a user to department as a collaborator
  AddDepartmentCollaborator() {
    return gql(upsertUserToDepartment);
  },

  // Remove a user from department as a collaborator
  RemoveDepartmentCollaborator() {
    return gql(removeUserFromDepartment);
  },

  // Add a new Run Item.
  // params.runId - ID of run to add to
  // params.parentId - ID of parent under which to add to
  // params.atIndex - Index at which to insert
  // params.lineText - LineText of the new run item
  // @return - newly created run item (currently on id, linetext)
  AddRunItem() {
    return gql`
      mutation AddRunItem(
        $runId: String!
        $parentId: String!
        $atIndex: Int!
        $lineText: String!
      ) {
        AddRunItem(
          runId: $runId
          parentId: $parentId
          atIndex: $atIndex
          lineText: $lineText
        ) {
          id
          lineText
        }
      }
    `;
  },

  CreateRealtimeChannel() {
    return gql`
      mutation createRealtimeChannel($workerId: ID!, $connectionId: ID!) {
        createRealtimeChannel(
          workerId: $workerId
          connectionId: $connectionId
        ) {
          workerId
          connectionId
        }
      }
    `;
  },

  // Register an additional column
  // params - gridId, columnId, columnName
  RegisterAdditionalColumn() {
    return gql`
      mutation registerAdditionalColumn(
        $gridId: String!
        $columnId: String!
        $columnName: String!
      ) {
        registerAdditionalColumn(
          gridId: $gridId
          columnId: $columnId
          columnName: $columnName
        )
      }
    `;
  },

  // Deregister an additional column
  // params - gridId, columnId
  DeregisterAdditionalColumn() {
    return gql`
      mutation deregisterAdditionalColumn(
        $gridId: String!
        $columnId: String!
      ) {
        deregisterAdditionalColumn(gridId: $gridId, columnId: $columnId)
      }
    `;
  },

  LearnBooks() {
    return gql(learnBooks);
  },

  ForgetBooks() {
    return gql(forgetBooks);
  },

  UpdateProcedureSchedule() {
    return gql(updateProcedureSchedule);
  },

  CreateKChatMessage() {
    return gql(createKChatMessage);
  },

  ImportDepartment() {
    return gql(importDepartment);
  }
};

export default Mutations;
