import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, message } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { last } from 'lodash';
import { EditorSourceType, ISentence } from './editorInterface';
import { useAppDispatch } from '../../../../stores/hooks';
import { showPopup } from '../../../../stores/slices/appPopup';
import AppConstants from '../../../../utils/AppConstants';
import { useRunCtxV2 } from '../../../../provider/runv2';
import { useKogEditorContext } from './EditorProvider';
import {
  GetRequestQuery,
  GetRequestQueryVariables
} from '../../../../generated/API';
import Queries from '../../../../graphql/Queries';
import { MiniPlaygroundModeType } from '../../../../popups/MiniPlaygroundPopup';

interface IEditorLineDropdownProps {
  leaf: ISentence;
  activeDocumentToken: string;
}

function EditorLineDropdown(props: IEditorLineDropdownProps) {
  const { leaf, activeDocumentToken } = props;

  const dispatch = useAppDispatch();
  const { worker, procedure } = useRunCtxV2();
  const { source } = useKogEditorContext();

  const { rolloutContextBasedLearning } = useFlags();

  const [getRequest, { loading }] = useLazyQuery<
    GetRequestQuery,
    GetRequestQueryVariables
  >(Queries.Request());

  const openMiniPlayground = () => {
    const lastRequestId: string | null = last(leaf.requests) || null;

    const popupParams = {
      popupId: AppConstants.POPUPS.MINI_PLAYGROUND,
      popupParams: {
        mode: MiniPlaygroundModeType.DEFAULT,
        worker,
        sentence: leaf,
        activeDocumentToken
      }
    };

    if (lastRequestId && procedure && rolloutContextBasedLearning) {
      const hide = message.loading('Opening Mini playground...', 0);
      getRequest({
        variables: {
          id: lastRequestId
        }
      }).then((resp) => {
        const request = resp.data?.getRequest;
        if (request) {
          hide();
          dispatch(
            showPopup({
              ...popupParams,
              popupParams: {
                ...popupParams.popupParams,
                mode: MiniPlaygroundModeType.CONTEXTUAL_LEARNING,
                request,
                procedure,
                resetDocument: true
              }
            })
          );
        }
      });
    } else {
      dispatch(showPopup(popupParams));
    }
  };

  const items: MenuProps['items'] = [];

  if (
    [
      EditorSourceType.PLAYGROUND,
      EditorSourceType.PROCESS_RUN,
      EditorSourceType.EXCEPTION_CENTRE_RUN
    ].includes(source)
  ) {
    items.push({
      key: 'mini-playground',
      label: 'Open Mini playground',
      onClick: (e) => {
        e.domEvent.stopPropagation();
        openMiniPlayground();
      },
      disabled: loading
    });
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items
      }}
    >
      <MoreOutlined
        className="menuIcon"
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Dropdown>
  );
}

export default EditorLineDropdown;
