import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { message } from 'antd';
import { last, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ExceptionStateType,
  IException,
  IExceptionFilterOptions,
  IExceptionSelectedFilters,
  IExceptionStatusCounts,
  IExceptionsCentreContext,
  IExceptionsCentreContextMethods
} from './interface';
import {
  AssignRequestsMutation,
  AssignRequestsMutationVariables,
  AssignmentPolicyType,
  DepartmentUser,
  GetRequestQuery,
  GetRequestQuestionQuery,
  ListDepartmentCollaboratorsQuery,
  ListQuestionsByDepartmentQuery,
  ListQuestionsByDepartmentQueryVariables,
  Request,
  RequestQuestion,
  RequestQuestionValueCounts,
  SortOrder
} from '../../generated/API';
import {
  ASSIGN_REQUESTS,
  GET_ACTIVE_EXCEPTION_REQUEST,
  GET_EXCEPTIONS_STATUS_COUNTS,
  GET_REQUEST_QUESTION,
  LIST_QUESTIONS_BY_DEPARTMENT
} from '../../graphql/exception';
import { useAppSelector } from '../../stores/hooks';
import { departmentQuerySelector } from '../../stores/slices/department';
import { LIST_DEPARTMENT_COLLABORATORS } from '../../graphql/departmentCollaborators';
import {
  prepareException,
  prepareExceptionStatusCounts
} from '../../transformers/exception';
import { userSelector } from '../../stores/slices/user';
import {
  getActiveExceptionIdFromUrl,
  getExceptionCentreLocalFilters,
  saveExceptionCentreFilters
} from '../../utils/exception';
import routes from '../../utils/routes';

const ExceptionsCentreContext = React.createContext<IExceptionsCentreContext>(
  null!
);

interface IExceptionsCentreProviderProps {
  children: React.ReactNode;
}

export function ExceptionsCentreProvider(
  props: IExceptionsCentreProviderProps
) {
  const { children } = props;

  const { username } = useSelector(userSelector);
  const { department } = useAppSelector(departmentQuerySelector);
  const navigate = useNavigate();

  const defaultSelectedFilters: IExceptionSelectedFilters = useMemo(() => {
    const fallback: IExceptionSelectedFilters = {
      process: [],
      state: [ExceptionStateType.PENDING],
      dateRange: [], // [before, after],
      assignee: username ? [username] : [],
      typename: [],
      searchText: []
    };

    const localStorageFilters = getExceptionCentreLocalFilters();
    if (localStorageFilters !== null) {
      return localStorageFilters;
    }

    return fallback;
  }, []);

  // exceptions data (paginated)
  const [exceptions, setExceptions] = useState<IException[]>([]);

  // exceptions count
  const [exceptionsCount, setExceptionsCount] = useState<number>(0);

  // exceptions filter Options
  const [filterOptions, setFilterOptions] = useState<IExceptionFilterOptions>({
    process: []
  });

  // selected filters
  const [selectedFilters, setSelectedFilters] =
    useState<IExceptionSelectedFilters>(defaultSelectedFilters);

  // selected exceptions (for bulk actions)
  const [selectedExceptions, setSelectedExceptions] = useState<IException[]>(
    []
  );

  // active exception (for single actions)
  const [activeException, setActiveException] = useState<IException | null>(
    null
  );

  // pagination page number
  const [page, setPage] = useState<number>(1);

  // urlActiveExceptionId
  // This is a state because when we clear, we do router.navigate instead of window.navigate
  const [urlActiveExceptionId, setUrlActiveExceptionId] = useState<
    string | null
  >(getActiveExceptionIdFromUrl() || null);

  // status counts
  const [statusCounts, setStatusCounts] = useState<IExceptionStatusCounts>({
    pending: 0,
    archived: 0,
    handled: 0,
    total: 0
  });

  // exceptions list query
  const [fetchDepartmentQuestions, fetchDepartmentQuestionsParams] =
    useLazyQuery<ListQuestionsByDepartmentQuery>(LIST_QUESTIONS_BY_DEPARTMENT, {
      onCompleted(data) {
        setExceptionsCount(data.listQuestionsByDepartment?.total || 0);

        const valueCounts = (data?.listQuestionsByDepartment as any)
          ?.valueCounts;

        const options: IExceptionFilterOptions = {
          process: []
        };

        options.process = valueCounts?.procedures || [];
        setFilterOptions(options);

        setStatusCounts(prepareExceptionStatusCounts(valueCounts));
      }
    });

  // used to fetch active exception if present in url search params
  const [fetchUrlActiveException] = useLazyQuery<GetRequestQuestionQuery>(
    GET_REQUEST_QUESTION,
    {
      onCompleted(data) {
        const question = data.getRequestQuestion as RequestQuestion | null;
        if (question) {
          const exception = prepareException(question) as any;
          const uniqueExceptions = uniqBy([exception, ...exceptions], 'id');
          setExceptions(uniqueExceptions);
          setActiveException(exception);
        }
      }
    }
  );

  // department collaborators query
  const { data: departmentCollaboratorsData } =
    useQuery<ListDepartmentCollaboratorsQuery>(LIST_DEPARTMENT_COLLABORATORS, {
      variables: {
        departmentId: department.id
      },
      skip: !department?.id
    });

  // active exception request query
  const [fetchActiveExceptionRequest] = useLazyQuery<GetRequestQuery>(
    GET_ACTIVE_EXCEPTION_REQUEST,
    {
      onCompleted(data) {
        const request = data.getRequest as Request | null;
        if (request) {
          setActiveException((prevException) => ({
            ...prevException!,
            request
          }));
        }
      }
    }
  );

  // assign requests mutation
  const [assignRequestsMutation] =
    useMutation<AssignRequestsMutation>(ASSIGN_REQUESTS);

  // status counts query (used to refresh once an exception is handled)
  const [fetchExceptionsStatusCounts] = useLazyQuery(
    GET_EXCEPTIONS_STATUS_COUNTS,
    {
      onCompleted(data) {
        const valueCounts = (data?.listQuestionsByDepartment as any)
          ?.valueCounts as RequestQuestionValueCounts | undefined;
        setStatusCounts(prepareExceptionStatusCounts(valueCounts));
      }
    }
  );

  const departmentUsers = (departmentCollaboratorsData
    ?.listDepartmentCollaborators?.items || []) as DepartmentUser[];

  const DEFAULT_PAGE_SIZE = 15;

  // acts as a wrapper around questions query and its params; gives UI full control
  const fetchExceptions: IExceptionsCentreContextMethods['fetchExceptions'] = (
    params
  ) => {
    const variables: ListQuestionsByDepartmentQueryVariables['input'] = {
      departmentId: department.id,
      sortField: ['request', 'createdAt'],
      sortOrder: SortOrder.DESCEND,
      filters: {
        state:
          selectedFilters.state.length === 0
            ? undefined
            : selectedFilters.state,
        procedureName:
          selectedFilters.process.length === 0
            ? undefined
            : selectedFilters.process,
        requestCreatedAfter: selectedFilters.dateRange?.[0] || undefined,
        requestCreatedBefore: selectedFilters.dateRange?.[1] || undefined,
        containsWords:
          selectedFilters.searchText.length === 0
            ? undefined
            : selectedFilters.searchText.join(','),
        requestAssignee:
          selectedFilters.assignee.length === 0
            ? undefined
            : selectedFilters.assignee,
        typename:
          selectedFilters.typename.length === 0
            ? undefined
            : selectedFilters.typename
      },

      currentPage: params.page,
      pageSize: DEFAULT_PAGE_SIZE
    };
    return fetchDepartmentQuestions({
      variables: {
        input: variables
      }
    });
  };

  const refreshStatusCounts = () => {
    fetchExceptionsStatusCounts({
      variables: {
        input: {
          departmentId: department.id,
          filters: {
            state: [ExceptionStateType.PENDING],
            requestAssignee: [username]
          }
        }
      }
    });
  };

  const activateException: IExceptionsCentreContextMethods['activateException'] =
    (exception) => {
      if (exception === null) {
        setActiveException(null);
        return;
      }

      setActiveException(exception);
      if (activeException?.id === exception.id) {
        fetchActiveExceptionRequest({
          variables: {
            id: exception.requestId
          }
        });
      }
    };

  const scrollExceptionIntoView = (exception: IException) => {
    const exceptionElement = document.getElementById(
      `exception-${exception.id}`
    );
    if (exceptionElement) {
      exceptionElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const fetchInitialExceptions = () => {
    setPage(1);

    message.destroy();

    const hide = message.loading('Loading exceptions...', 0);
    return fetchExceptions({
      page: 1
    })
      .then(({ data }) => {
        const fetchedQuestions = data.listQuestionsByDepartment
          ?.items as unknown as RequestQuestion[];

        if (fetchedQuestions) {
          const preparedExceptions = fetchedQuestions.map(prepareException);
          setExceptions(preparedExceptions);
          if (preparedExceptions.length > 0) {
            activateException(preparedExceptions[0]);
            scrollExceptionIntoView(preparedExceptions[0]);
          } else {
            activateException(null);
          }
        }

        return fetchedQuestions;
      })
      .finally(() => {
        hide();
      });
  };

  useEffect(() => {
    saveExceptionCentreFilters(selectedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    if (department?.id) {
      if (urlActiveExceptionId) {
        fetchExceptions({
          page: 1
        });
        fetchUrlActiveException({
          variables: {
            id: urlActiveExceptionId
          }
        });
      } else {
        fetchInitialExceptions();
      }
    }

    return () => {
      message.destroy();
    };
  }, [urlActiveExceptionId, department?.id, selectedFilters]);

  useEffect(() => {
    if (activeException?.id) {
      fetchActiveExceptionRequest({
        variables: {
          id: activeException.requestId
        }
      });
    }
  }, [activeException?.id]);

  const fetchNextPageExceptions: IExceptionsCentreContextMethods['fetchNextPageExceptions'] =
    () => {
      const nextPage = page + 1;
      return fetchExceptions({
        page: nextPage
      }).then((resp) => {
        const { data } = resp;

        const fetchedQuestions = data.listQuestionsByDepartment
          ?.items as unknown as RequestQuestion[];

        if (fetchedQuestions) {
          const uniqueExceptions = uniqBy(
            [...exceptions, ...fetchedQuestions.map(prepareException)],
            'id'
          );
          setExceptions(uniqueExceptions);
        }

        setPage(nextPage);
        return resp;
      });
    };

  // Always make sure to load 2x of initial exceptions on first load
  useEffect(() => {
    const conditions = [
      page === 1, // only on the first page load
      exceptions.length > 0, //  there should be some data
      exceptions.length < exceptionsCount, // if exceptions length is less than total count
      exceptions.length < DEFAULT_PAGE_SIZE * 2 // if exceptions length is less than 2x of default page size
    ];

    if (conditions.every(Boolean)) {
      fetchNextPageExceptions();
    }
  }, [page, exceptions]);

  const clearUrlActiveExceptionId: IExceptionsCentreContextMethods['clearUrlActiveExceptionId'] =
    () => {
      setUrlActiveExceptionId(null);
      navigate(routes.exceptions.getExceptionsPageUrl());
    };

  const selectException: IExceptionsCentreContextMethods['selectException'] = (
    exception
  ) => {
    const isSelected = selectedExceptions.find(
      (selectedException) => selectedException.id === exception.id
    );

    if (isSelected) {
      setSelectedExceptions(
        selectedExceptions.filter(
          (selectedException) => selectedException.id !== exception.id
        )
      );
    } else {
      setSelectedExceptions([...selectedExceptions, exception]);
    }
  };

  const updateSelectedFilter: IExceptionsCentreContextMethods['updateSelectedFilter'] =
    (key, value, reset) => {
      if (reset) {
        setSelectedFilters({
          ...selectedFilters,
          [key]: value
        });
        return;
      }

      const currentFilters = selectedFilters[key];
      const isSelected = currentFilters.find((filter) => filter === value);
      if (isSelected) {
        setSelectedFilters({
          ...selectedFilters,
          [key]: currentFilters.filter((filter) => filter !== value)
        });
      } else {
        setSelectedFilters({
          ...selectedFilters,
          [key]: [...currentFilters, value]
        });
      }
    };

  const isFilterApplied = Object.values(selectedFilters).some(
    (filter) => filter.length > 0
  );

  const gotoNextException: IExceptionsCentreContextMethods['gotoNextException'] =
    (currentException, refetchIfNotFound) => {
      const activeExceptionIndex = exceptions.findIndex(
        (exception) => exception.id === currentException.id
      );
      const nextException = exceptions[activeExceptionIndex + 1];
      if (nextException) {
        activateException(nextException);
        scrollExceptionIntoView(nextException);
      } else {
        if (refetchIfNotFound) {
          // if user has reached the end of pending exceptions, then refetch
          fetchInitialExceptions();
        } else {
          activateException(null);
          message.info('No more exceptions to show');
        }
      }
    };

  const gotoPreviousException: IExceptionsCentreContextMethods['gotoPreviousException'] =
    (currentException) => {
      const activeExceptionIndex = exceptions.findIndex(
        (exception) => exception.id === currentException.id
      );
      const previousException = exceptions[activeExceptionIndex - 1];
      if (previousException) {
        activateException(previousException);
        scrollExceptionIntoView(previousException);
      }
    };

  const assignExceptions: IExceptionsCentreContextMethods['assignExceptions'] =
    (exceptionsToAssign, collaborator) => {
      const variables: AssignRequestsMutationVariables = {
        input: {
          requestIds: exceptionsToAssign.map(
            (exception) => exception.requestId
          ),
          assignmentPolicy: {
            policyType: AssignmentPolicyType.COLLABORATOR,
            collaborator
          }
        }
      };

      return assignRequestsMutation({
        variables
      }).then((resp) => {
        message.success('Assigned successfully');

        setSelectedExceptions([]);

        const updatedExceptions = exceptions.map((exception) => {
          if (exceptionsToAssign.some((e) => e.id === exception.id)) {
            return {
              ...exception,
              assignee: collaborator
            };
          }
          return exception;
        });
        setExceptions(updatedExceptions);
        refreshStatusCounts();

        const lastException = last(exceptionsToAssign);
        if (lastException) {
          gotoNextException(lastException, true);
        }

        return resp;
      });
    };

  const markExceptionsAsHandled: IExceptionsCentreContextMethods['markExceptionsAsHandled'] =
    (exceptionsToMarkAsHandled, actionType) => {
      const updatedExceptions = exceptions.map((exception) => {
        if (exceptionsToMarkAsHandled.some((e) => e.id === exception.id)) {
          return {
            ...exception,
            state: actionType
          };
        }
        return exception;
      });

      setExceptions(updatedExceptions);
      refreshStatusCounts();

      const lastException = last(exceptionsToMarkAsHandled);
      if (lastException) {
        gotoNextException(lastException, true);
      }
    };

  const value: IExceptionsCentreContext = useMemo(
    () => ({
      loading: fetchDepartmentQuestionsParams.loading,
      error: fetchDepartmentQuestionsParams.error,
      exceptions,
      totalExceptionsCount: exceptionsCount,
      exceptionsFilterOptions: filterOptions,
      selectedFilters,
      selectedExceptions,
      activeException,
      departmentUsers,
      isFilterApplied,
      statusCounts,
      urlActiveExceptionId,

      methods: {
        fetchExceptions,
        fetchNextPageExceptions,
        selectException,
        clearSelectedExceptions: () => {
          setSelectedExceptions([]);
        },
        activateException,
        updateSelectedFilter,
        clearSelectedFilters: () => {
          setSelectedFilters({
            process: [],
            state: [],
            dateRange: [],
            assignee: [],
            typename: [],
            searchText: []
          });
        },
        gotoNextException,
        gotoPreviousException,
        assignExceptions,
        markExceptionsAsHandled,
        clearUrlActiveExceptionId
      }
    }),
    [
      exceptions,
      exceptionsCount,
      filterOptions,
      selectedFilters,
      fetchExceptions,
      fetchNextPageExceptions,
      selectedExceptions,
      activeException,
      departmentUsers,
      updateSelectedFilter
    ]
  );

  return (
    <ExceptionsCentreContext.Provider value={value}>
      {children}
    </ExceptionsCentreContext.Provider>
  );
}

export function useExceptionsCentreContext() {
  return React.useContext(ExceptionsCentreContext);
}
