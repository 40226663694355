import { ApolloError } from '@apollo/client';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useState, useEffect, useCallback, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './AppStore';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Async Thunk Query
 * @param AsyncThunk
 * @param options
 * @returns
 */
export function useATQ(
  // todo: Fix the types
  action: AsyncThunk<any, any, any>,
  {
    variables,
    callback,
    wait,
    polling
  }: {
    variables?: any;
    callback?: (data: any) => void;
    wait?: boolean;
    polling?: number;
  }
) {
  const dispatch = useAppDispatch();
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApolloError | null>(null);
  const [data, setData] = useState<any>(null);
  const callbackRef = useRef(callback);
  const stringifyVariables = JSON.stringify(variables);
  const intervalRef = useRef<number>();
  const countRef = useRef<number>(0);
  const { isPollingEnabled } = useAppSelector((state) => state.dev);

  useEffect(() => {
    if (callbackRef.current !== callback) {
      callbackRef.current = callback;
    }
  }, [callback]);

  const main = useCallback(() => {
    countRef.current += 1;

    if (!polling) {
      setIsLoading(true);
    }

    dispatch(action(variables))
      .unwrap()
      .then((val) => {
        setData(val);
        setError(null);
        callbackRef.current?.(val);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [variables]);

  useEffect(() => {
    if (wait) {
      return;
    }

    // setIsLoading(true);
    main();

    if (polling && isPollingEnabled) {
      intervalRef.current = setInterval(main, polling) as unknown as number;
      return () => clearInterval(intervalRef.current);
    }
    return () => {};
  }, [wait, stringifyVariables, isPollingEnabled]);

  const value = {
    loading,
    error,
    data,
    refetch: () => {
      main();
    }
  };

  return value;
}
