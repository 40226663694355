import React from 'react';
import dayjs from 'dayjs';
import { Checkbox } from 'antd';
import styles from './MapPreview.module.less';

interface IProps {
  dateRange?: any;
  label?: any;
  filteredList?: any;
  onFilterChange: (checkedValues: any) => void;
}
const MapLegend = ({
  dateRange,
  label,
  filteredList,
  onFilterChange
}: IProps) => {
  const handleFilterChange = (e: any) => {
    onFilterChange(e);
  };
  return (
    <div className={styles.legend}>
      {dateRange?.startDate ? (
        <div className={styles.timeRange}>
          {/* <div className={styles.timeRangeLabel}>Time Range</div> */}
          <div className={styles.timeRangeValue}>
            {dayjs(dateRange?.startDate).format('lll')} -{' '}
            {dayjs(dateRange?.endDate).format('lll')}
          </div>
        </div>
      ) : null}

      <div className={styles.divider} />

      <div className={styles.legendItem}>
        <div
          className={styles.legendColor}
          style={{ backgroundColor: 'green' }}
        />
        <div className={styles.legendText}>{`<3`}</div>
      </div>
      <div className={styles.legendItem}>
        <div
          className={styles.legendColor}
          style={{ backgroundColor: 'yellow' }}
        />
        <div className={styles.legendText}>3-5</div>
      </div>
      <div className={styles.legendItem}>
        <div
          className={styles.legendColor}
          style={{ backgroundColor: 'red' }}
        />
        <div className={styles.legendText}>{`>5`}</div>
      </div>

      <div className={styles.divider} />
      <div className={styles.legendItem}>
        <div className={styles.legendText}>Vehicles</div>
      </div>
      {Object.keys(label).map((l) => (
        <div className={styles.legendItem}>
          <Checkbox
            checked={filteredList?.includes(l)}
            onChange={handleFilterChange}
            value={l}
          >
            <span
              className={styles.legendTextCheck}
            >{`${l}(${label[l]})`}</span>
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

export default MapLegend;
