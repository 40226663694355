import { QueryResult, SingleExecutionResult } from '@apollo/client';
import { ApolloError } from '@apollo/react-hooks';
import { DepartmentUser, Request } from '../../generated/API';

export enum ExceptionStateType {
  PENDING = 'pending',
  ARCHIVED = 'archived',
  HANDLED = 'handled'
}

export interface IExceptionStatusCounts {
  pending: number;
  archived: number;
  handled: number;
  total: number;
}

export interface IException {
  id: string;
  text: string;
  summary: string;
  process: {
    id: string;
    name: string;
  };
  workerId: string;
  requestId: string;
  request: Request | null;
  assignee: string;
  state: ExceptionStateType;
  createdAt: string;
}

export interface IExceptionFilterOption {
  value: string;
  count: number;
}

export interface IExceptionFilterOptions {
  process: IExceptionFilterOption[];
}

export interface IExceptionSelectedFilters {
  process: string[];
  state: ExceptionStateType[];
  dateRange: string[];
  assignee: string[];
  typename: string[];
  searchText: string[];
}

export interface IFetchExceptionsParams {
  page: number;
}

export interface IExceptionsCentreContextMethods {
  fetchExceptions: (params: IFetchExceptionsParams) => Promise<QueryResult>;
  fetchNextPageExceptions: () => Promise<QueryResult>;
  selectException: (exception: IException) => void;
  clearSelectedExceptions: () => void;
  activateException: (exception: IException | null) => void;
  updateSelectedFilter: (
    key: keyof IExceptionSelectedFilters,
    value: string | string[],
    reset?: boolean
  ) => void;
  clearSelectedFilters: () => void;
  gotoNextException: (
    currentException: IException,
    refetchIfNotFound?: boolean
  ) => void;
  gotoPreviousException: (currentException: IException) => void;
  assignExceptions: (
    exceptions: IException[],
    collaborator: string
  ) => Promise<SingleExecutionResult>;
  markExceptionsAsHandled: (
    exceptions: IException[],
    actionType: ExceptionStateType
  ) => void;
  clearUrlActiveExceptionId: () => void;
}

export interface IExceptionsCentreContext {
  loading: boolean;
  error?: ApolloError;
  exceptions: IException[];
  totalExceptionsCount: number;
  exceptionsFilterOptions: IExceptionFilterOptions;
  selectedFilters: IExceptionSelectedFilters;
  selectedExceptions: IException[];
  activeException: IException | null;
  departmentUsers: DepartmentUser[];
  isFilterApplied: boolean;
  statusCounts: IExceptionStatusCounts;
  urlActiveExceptionId: string | null;

  // methods
  methods: IExceptionsCentreContextMethods;
}
