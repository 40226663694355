// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.run-item-details {
  padding-left: 10px;
}
.run-item-details hr {
  color: #d9d9d9;
  opacity: 0.5;
}
.run-item-details h4:not(:first-child) {
  margin-top: 20px;
}
.run-item-details .parameters-container .bubble {
  display: inline-block;
  width: auto;
  padding: 4px 10px;
  border-radius: 4px;
  background-color: #f4f4f6;
  margin-right: 10px;
  cursor: pointer;
  opacity: 0.6;
  font-size: 12px;
}
.run-item-details .messages-container {
  margin-top: 20px;
}
.run-item-details > .run-item {
  padding-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/details/runs/items/RunItemDetailsV2.less"],"names":[],"mappings":"AAGA;EAIE,kBAAA;AALF;AACA;EAOI,cAAA;EACA,YAAA;AALJ;AAHA;EAYI,gBAAA;AANJ;AANA;EAiBM,qBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,eAAA;AARN;AAjBA;EA8BI,gBAAA;AAVJ;AApBA;EAkCI,kBAAA;AAXJ","sourcesContent":["// RunItemDetails CSS\n@import '@css/variables.less';\n\n.run-item-details {\n  // background-color: @details-bg-color;\n  // padding: 10px;\n\n  padding-left: @tabspace;\n\n  hr {\n    color: @border-inner-color;\n    opacity: 0.5;\n  }\n\n  h4:not(:first-child) {\n    margin-top: 20px;\n  }\n\n  .parameters-container {\n    .bubble {\n      display: inline-block;\n      width: auto;\n      padding: 4px 10px;\n      border-radius: @border-radius-default;\n      background-color: @page-bg-color;\n      margin-right: 10px;\n      cursor: pointer;\n      opacity: 0.6;\n      font-size: @fontSizeSmall;\n    }\n  }\n\n  .messages-container {\n    margin-top: 20px;\n  }\n\n  > .run-item {\n    padding-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
