// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-area {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/ImageArea.less"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAFF","sourcesContent":["// ImageArea  CSS\n@import '@css/variables.less';\n\n.image-area {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  overflow: hidden;\n  // border: 1px dashed @selection-color;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
