//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// ObjectViewer is a component for displaying objects as JSON or YAML
//

// 3rd party libraries
import React from 'react';
import { dump } from 'js-yaml';
import Editor from '@/components/editor';

import './ObjectViewer.less';

// eslint-disable-next-line no-shadow
enum DisplayMode {
  YAML = 'text/x-yaml',
  JSON = 'javascript'
}

interface IObjectViewerProps {
  /**
   * The object to display
   */
  object: object;

  mode: DisplayMode;
}

// Component implementation
function ObjectViewer(props: IObjectViewerProps) {
  const { object, mode } = props;

  // const cmOptions = {
  //   mode: {
  //     name: mode,
  //     json: true
  //   },
  //   lineNumbers: false,
  //   lineWrapping: true,
  //   foldGutter: true,
  //   gutters: ['CodeMirror-foldgutter']
  // };

  let value = null;
  if (mode === DisplayMode.JSON) {
    value = JSON.stringify(object, null, 2);
  } else {
    value = dump(object);
  }

  return (
    <div className="object-viewer">
      <Editor language="english" value={value} onChange={() => {}} />
    </div>
  );
}

export { DisplayMode, ObjectViewer };
