// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inline-facts {
  font-family: 'Nunito', sans-serif;
  display: inline-flex;
  flex-wrap: wrap;
}
.inline-facts .summary-link,
.inline-facts .summary-link .ant-typography {
  cursor: pointer;
  color: #07a6f3;
}
.inline-facts .fact {
  display: inline;
  margin-left: 4px;
  margin-right: 4px;
}
.inline-facts .fact .ant-typography {
  padding-right: 0;
  text-decoration: underline;
}
.fact-highlight {
  text-decoration: underline;
  text-decoration-color: #add8e6;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  cursor: pointer;
}
.fact-highlight:hover {
  background: rgba(173, 216, 230, 0.6);
}
`, "",{"version":3,"sources":["webpack://./src/components/playground2/FactSummaryV2.less"],"names":[],"mappings":"AAEA;EACE,iCAAA;EAOA,oBAAA;EACA,eAAA;AAPF;AAAE;;EAEE,eAAA;EACA,cAAA;AAEJ;AAIE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAFJ;AAII;EACE,gBAAA;EACA,0BAAA;AAFN;AAOA;EACE,0BAAA;EACA,8BAAA;EACA,0BAAA;EACA,8BAAA;EACA,eAAA;AALF;AAOE;EACE,oCAAA;AALJ","sourcesContent":["@import '@css/variables.less';\n\n.inline-facts {\n  font-family: @baseFont;\n  & .summary-link,\n  & .summary-link .ant-typography {\n    cursor: pointer;\n    color: @color-link-blue;\n  }\n\n  display: inline-flex;\n  flex-wrap: wrap;\n\n  & .fact {\n    display: inline;\n    margin-left: 4px;\n    margin-right: 4px;\n\n    & .ant-typography {\n      padding-right: 0;\n      text-decoration: underline;\n    }\n  }\n}\n\n.fact-highlight {\n  text-decoration: underline;\n  text-decoration-color: rgba(173, 216, 230, 1);\n  text-underline-offset: 4px;\n  text-decoration-thickness: 2px;\n  cursor: pointer;\n\n  &:hover {\n    background: rgba(173, 216, 230, 0.6);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
