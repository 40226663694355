import AppConstants from '@/utils/AppConstants';
import AppUtil from '@/utils/AppUtil';

const hashString = (str: string) => {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
};

const getKey = (key: string, userEmail: string) => {
  return `${key}-${hashString(userEmail)}`;
};

export const getCurrentTemplateId = (userEmail: string) => {
  return AppUtil.getFromLocalStorage(
    getKey(AppConstants.SELF_SERVE.TEMPLATE_ID, userEmail)
  );
};

export const setTemplateIdForRequest = (id: string, userEmail: string) => {
  AppUtil.setInLocalStorage(
    getKey(AppConstants.SELF_SERVE.TEMPLATE_ID_FOR_REQUEST, userEmail),
    id
  );
};

export const setCurrentTemplateId = (id: string, userEmail: string) => {
  AppUtil.setInLocalStorage(
    getKey(AppConstants.SELF_SERVE.TEMPLATE_ID, userEmail),
    id
  );
  setTemplateIdForRequest(id, userEmail);
};

export const deleteCurrentTemplateId = (userEmail: string) => {
  AppUtil.removeFromLocalStorage(
    getKey(AppConstants.SELF_SERVE.TEMPLATE_ID, userEmail)
  );
};

export const getTemplateIdForRequest = (userEmail: string) => {
  return AppUtil.getFromLocalStorage(
    getKey(AppConstants.SELF_SERVE.TEMPLATE_ID_FOR_REQUEST, userEmail)
  );
};

export const deleteTemplateIdForRequest = (userEmail: string) => {
  AppUtil.removeFromLocalStorage(
    getKey(AppConstants.SELF_SERVE.TEMPLATE_ID_FOR_REQUEST, userEmail)
  );
};

const getTemplateWorkerMap = (userEmail: string) => {
  return AppUtil.safeParseJSON(
    AppUtil.getFromLocalStorage(
      getKey(AppConstants.SELF_SERVE.TEMPLATE_WORKER_MAP, userEmail)
    ),
    true
  );
};

export const setTemplateWorkerMap = (
  templateId: string,
  workerId: string,
  userEmail: string
) => {
  const templateWorkerMap = getTemplateWorkerMap(userEmail);
  if (templateId) {
    if (!templateWorkerMap) {
      AppUtil.setInLocalStorage(
        getKey(AppConstants.SELF_SERVE.TEMPLATE_WORKER_MAP, userEmail),
        JSON.stringify({ [templateId]: workerId })
      );
    } else {
      AppUtil.setInLocalStorage(
        getKey(AppConstants.SELF_SERVE.TEMPLATE_WORKER_MAP, userEmail),
        JSON.stringify({ ...templateWorkerMap, [templateId]: workerId })
      );
    }
  }
};

export const isTemplateHasWorker = (templateId: string, userEmail: string) => {
  const templateWorkerMap = getTemplateWorkerMap(userEmail);
  return templateWorkerMap && templateWorkerMap[templateId];
};

const removeTemplateWorkerMap = (templateId: string, userEmail: string) => {
  const templateWorkerMap = getTemplateWorkerMap(userEmail);
  if (templateWorkerMap) {
    delete templateWorkerMap[templateId];
    AppUtil.setInLocalStorage(
      getKey(AppConstants.SELF_SERVE.TEMPLATE_WORKER_MAP, userEmail),
      JSON.stringify(templateWorkerMap)
    );
  }
};

export const getTemplateIdForWorker = (workerId: string, userEmail: string) => {
  const templateWorkerMap = getTemplateWorkerMap(userEmail);
  if (templateWorkerMap) {
    return Object.keys(templateWorkerMap).find(
      (key) => templateWorkerMap[key] === workerId
    );
  }
  return null;
};

export const removeTemplateIdForWorker = (
  workerId: string,
  userEmail: string
) => {
  const templateId = getTemplateIdForWorker(workerId, userEmail);
  if (templateId) {
    removeTemplateWorkerMap(templateId, userEmail);
  }
};

export const getUsedTemplates = (userEmail: string) => {
  return AppUtil.safeParseJSON(
    AppUtil.getFromLocalStorage(
      getKey(AppConstants.SELF_SERVE.TEMPLATES_USED, userEmail)
    ),
    true
  );
};

export const setUsedTemplates = (
  templateId: string | null | undefined,
  userEmail: string
) => {
  const templatesUsed = getUsedTemplates(userEmail);
  if (templateId) {
    if (!templatesUsed) {
      AppUtil.setInLocalStorage(
        getKey(AppConstants.SELF_SERVE.TEMPLATES_USED, userEmail),
        JSON.stringify([templateId])
      );
    } else {
      AppUtil.setInLocalStorage(
        getKey(AppConstants.SELF_SERVE.TEMPLATES_USED, userEmail),
        JSON.stringify([...templatesUsed, templateId])
      );
    }
    // When template is used(Done state/fully ran), remove it from templaeteIdWokerMap
    removeTemplateWorkerMap(templateId, userEmail);
  }
};
