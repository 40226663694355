//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// DocumentDisplay is a component for displaying image & PDF files from S3
//

// 3rd party libraries
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Space, Tooltip } from 'antd';
import {
  CloseOutlined,
  DownloadOutlined,
  ExpandAltOutlined,
  LoadingOutlined,
  ShrinkOutlined
} from '@ant-design/icons';
import Editor from '@/components/editor';
import { load } from 'js-yaml';
import { ApolloError } from '@apollo/client';
import { AxiosResponse } from 'axios';
import AppConstants from '@utils/AppConstants';
import ContentUtil from '@/utils/ContentUtil';
import PDFViewer, { IPDFViewerProps } from '@components/PDFViewer/PDFViewer';
import { ObjectViewer, DisplayMode } from '@/components/ObjectViewer';
import { showPopup } from '@/stores/slices/appPopup';

import CSVTable from './CSVTable';
import './S3DocumentViewer.less';
import { useAppDispatch } from '../stores/hooks';
import { useS3 } from '../hooks/useS3';
import ExcelTable from './ExcelTable/ExcelTable';
import DocxViewer from './DocxViewer/DocxViewer';
import { S3PresignedUrl } from '../generated/API';
import HTMLViewer from './HTMLViewer';
import EMLViewer from './EMLViewer';
import Loader, { ILoaderType } from './Loader';

const getArrayBuffer = async (o: AxiosResponse<any>) => {
  const buffer = await new Response(o.data).arrayBuffer();
  return buffer;
};

export const decodeText = async (o: AxiosResponse<any>) => {
  const decoder = new TextDecoder();
  const buffer = await getArrayBuffer(o);
  return decoder.decode(buffer);
};

export interface IPDFConfig {
  pageNumbers?: IPDFViewerProps['pageNumbers'];
  highlight?: IPDFViewerProps['highlight'];
  allowViewTypeChange?: IPDFViewerProps['allowViewTypeChange'];
  pageWidth?: IPDFViewerProps['pageWidth'];
  pageHeight?: IPDFViewerProps['pageHeight'];
  defaultViewType?: IPDFViewerProps['defaultViewType'];
}

function RenderImage({
  imageObject,
  expandImage
}: {
  imageObject: AxiosResponse<any>;
  expandImage?: boolean;
}) {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    setUrl(URL.createObjectURL(imageObject.data));

    return () => {
      URL.revokeObjectURL(imageObject.data);
    };
  }, [imageObject]);
  const element = document.querySelector('.s3-file-viewer-popup');
  const elementHeight = (element as HTMLElement)?.offsetHeight;

  return (
    <img
      ref={imgRef}
      src={url}
      alt="Preview"
      data-cy="image-viewer"
      style={
        expandImage
          ? {
              maxWidth: '100%'
            }
          : {
              objectFit: 'cover',
              maxHeight: elementHeight ? elementHeight - 60 : 520
            }
      }
    />
  );
}

function RenderPlainText({ text }: { text: string }) {
  // const cmOptions = {
  //   mode: {
  //     name: 'text/plain'
  //   },
  //   lineNumbers: true,
  //   lineWrapping: true
  // };
  return (
    <div className="txt-viewer">
      <Editor language="english" value={text} onChange={() => {}} />
    </div>
  );
}

interface IRenderObjectProps {
  object: AxiosResponse<any>;
  presignedUrl: S3PresignedUrl | null;
  parentDimension: DOMRect | null;
  pdfConfig?: IPDFConfig;
  expandImage?: boolean;
}

function RenderObject(props: IRenderObjectProps) {
  const { object, presignedUrl, parentDimension, pdfConfig, expandImage } =
    props;

  const parentWidth = parentDimension ? parentDimension.width - 30 : 0;

  const cType = object.headers['content-type'];

  const [decodedData, setDecodedData] = useState<any>(null);

  const SUPPORTED_FILE_TYPES = {
    IMG: cType?.startsWith('image/'),
    CSV: cType === 'text/csv',
    TXT: cType === 'text/plain',
    JSON: cType.includes('application/json'),
    YML:
      (cType?.startsWith('application') || cType?.startsWith('text')) &&
      (cType.endsWith('yml') || cType.endsWith('yaml')),
    PDF:
      cType === 'application/pdf' ||
      (presignedUrl?.s3Url && presignedUrl?.s3Url.endsWith('.pdf')),
    EXCEL: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel'
    ].includes(cType),
    DOCX: [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ].includes(cType),
    HTML: cType === 'text/html',
    EML: cType === 'message/rfc822'
  };
  // https://www.npmjs.com/package/eml-parser
  // https://github.com/wojtekmaj/react-pdf/blob/v5.x/README.md

  useEffect(() => {
    const shouldDecode = [
      SUPPORTED_FILE_TYPES.CSV,
      SUPPORTED_FILE_TYPES.JSON,
      SUPPORTED_FILE_TYPES.YML,
      SUPPORTED_FILE_TYPES.TXT,
      SUPPORTED_FILE_TYPES.HTML
    ].some(Boolean);

    if (shouldDecode) {
      decodeText(object).then((r) => {
        setDecodedData(r);
      });
    }
  }, [cType, object]);

  if (SUPPORTED_FILE_TYPES.IMG) {
    return <RenderImage imageObject={object} expandImage={expandImage} />;
  }
  if (SUPPORTED_FILE_TYPES.TXT && decodedData) {
    return <RenderPlainText text={decodedData} />;
  }
  if (SUPPORTED_FILE_TYPES.JSON && decodedData) {
    try {
      const jsonObj = JSON.parse(decodedData);
      return (
        <div className="json-viewer">
          <ObjectViewer mode={DisplayMode.JSON} object={jsonObj} />
        </div>
      );
    } catch (error) {
      /* ignore */
    }
  }
  if (SUPPORTED_FILE_TYPES.YML && decodedData) {
    try {
      const ymlObj = load(decodedData);
      return (
        <div className="yml-viewer">
          <ObjectViewer mode={DisplayMode.YAML} object={ymlObj as object} />
        </div>
      );
    } catch (error) {
      /* ignore */
    }
  }
  if (SUPPORTED_FILE_TYPES.CSV && decodedData) {
    return <CSVTable csvData={decodedData} className="csv-viewer" />;
  }

  if (!object.data) return <Loader type={ILoaderType.SKELETON} />;

  if (SUPPORTED_FILE_TYPES.PDF) {
    return (
      <PDFViewer
        pdfFileBlob={object.data!}
        pageWidth={pdfConfig?.pageWidth || parentWidth}
        pageHeight={pdfConfig?.pageHeight}
        pageNumbers={pdfConfig?.pageNumbers}
        highlight={pdfConfig?.highlight}
        allowViewTypeChange={pdfConfig?.allowViewTypeChange}
        defaultViewType={pdfConfig?.defaultViewType}
      />
    );
  }

  if (SUPPORTED_FILE_TYPES.EXCEL) {
    return <ExcelTable fileBlob={object.data!} />;
  }

  if (SUPPORTED_FILE_TYPES.DOCX) {
    return <DocxViewer flieBlob={object.data!} />;
  }

  if (SUPPORTED_FILE_TYPES.HTML) {
    return <HTMLViewer html={decodedData} />;
  }

  if (SUPPORTED_FILE_TYPES.EML) {
    return <EMLViewer fileUrl={object.config.url!} />;
  }

  return (
    <Alert
      message="😳 Unsupported but coming soon:"
      description={cType}
      type="error"
    />
  );
}

interface IProps {
  s3Object: { bucket: string; key: string };
  title?: string;
  onClose?: () => void;
  pdfConfig?: IPDFConfig;
  allowFullscreen?: boolean;
  hideHeader?: boolean;
  width?: string;
  showLoader?: boolean;
}

// Component implementation
function S3DocumentViewer(props: IProps) {
  const {
    title,
    s3Object,
    onClose,
    pdfConfig,
    allowFullscreen = false,
    hideHeader,
    width,
    showLoader
  } = props;

  const [error, setError] = useState<ApolloError | null>(null);
  const [object, setObject] = useState<AxiosResponse<any> | null>(null);
  const [presignedUrl, setPresignedUrl] = useState<S3PresignedUrl | null>(null);
  const [isImage, setIsImage] = useState(false);
  const [imageHeightExpanded, setImageHeightExpanded] = useState(true);
  const dispatch = useAppDispatch();
  const s3 = useS3();

  const parentRef = useRef<HTMLDivElement | null>(null);

  const getParentDimension = () => {
    if (parentRef.current) {
      return parentRef.current.getBoundingClientRect();
    }
    return null;
  };

  const getObject = async () => {
    const updatedPresignedUrl = await s3.getS3PresignedUrl(s3Object.key);
    if (updatedPresignedUrl) {
      setPresignedUrl(updatedPresignedUrl);
      const urlObject = await s3.getSignedUrlObject(updatedPresignedUrl.url);
      if (urlObject) {
        setObject(urlObject);
        const cType = urlObject.headers['content-type'];
        setIsImage(cType?.startsWith('image/'));
      }
    }
  };

  useEffect(() => {
    getObject();

    return () => {
      setObject(null);
      setError(null);
    };
  }, [s3Object?.key, !!pdfConfig]);

  const openDocumentPopup = () => {
    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.VIEW_S3_FILE,
        popupParams: {
          title,
          s3Object,
          pdfConfig: {
            highlight: pdfConfig?.highlight,
            pageNumbers: pdfConfig?.pageNumbers,
            allowViewTypeChange: pdfConfig?.allowViewTypeChange
          }
        }
      })
    );
  };

  let cardBody = showLoader ? <Loader type={ILoaderType.SKELETON} /> : null;

  if (error) {
    cardBody = (
      <Alert message="Error" description={error.message} type="error" />
    );
  } else if (object !== null) {
    cardBody = (
      <RenderObject
        object={object}
        presignedUrl={presignedUrl}
        pdfConfig={pdfConfig}
        parentDimension={getParentDimension()}
        expandImage={imageHeightExpanded}
      />
    );
  }

  let titleIcon = <LoadingOutlined data-cy="document-loading-icon" />;
  if (object !== null && object.headers['content-type']) {
    titleIcon = ContentUtil.iconForContentType(object.headers['content-type']);
  }

  return (
    <div
      className="document-viewer"
      style={width ? { width } : {}}
      ref={parentRef}
    >
      <Card
        size="small"
        title={
          !hideHeader && (
            <Space>
              {titleIcon}
              {title}
            </Space>
          )
        }
        bordered={false}
        extra={
          !hideHeader && (
            <Space>
              {allowFullscreen && (
                <Tooltip title="Expand">
                  <Button
                    type="link"
                    size="small"
                    icon={<ExpandAltOutlined />}
                    onClick={openDocumentPopup}
                  />
                </Tooltip>
              )}
              {isImage &&
                (imageHeightExpanded ? (
                  <Tooltip title="Shrink">
                    <Button
                      type="link"
                      size="small"
                      icon={<ShrinkOutlined />}
                      onClick={() => setImageHeightExpanded(false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Expand">
                    <Button
                      type="link"
                      size="small"
                      icon={<ExpandAltOutlined />}
                      onClick={() => setImageHeightExpanded(true)}
                    />
                  </Tooltip>
                ))}
              {object && (
                <Tooltip title="Download">
                  <Button
                    type="link"
                    size="small"
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      window.open(object.config.url, '_blank');
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip title="Close">
                <span data-cy="document-modal-close">
                  <Button
                    type="link"
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={onClose}
                  />
                </span>
              </Tooltip>
            </Space>
          )
        }
      >
        {cardBody}
      </Card>
    </div>
  );
}

export default S3DocumentViewer;
