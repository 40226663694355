import React, { forwardRef } from 'react';

// TODO: Move to public dir and load dynamically - tackle while working on tech debt
import airtable from '../../assets/book-icons/airtable.svg';
import adobe from '../../assets/book-icons/adobe.svg';
import awsS3 from '../../assets/book-icons/aws_s3.svg';
import cloudStorage from '../../assets/book-icons/cloud_storage.svg';
import email from '../../assets/book-icons/email.svg';
import epicor from '../../assets/book-icons/epicor.jpeg';
import excel from '../../assets/book-icons/excel.svg';
import github from '../../assets/book-icons/github.svg';
import sheets from '../../assets/book-icons/google-sheets.svg';
import html from '../../assets/book-icons/html.svg';
import hubspot from '../../assets/book-icons/hubspot.svg';
import receipt from '../../assets/book-icons/invoice-receipt.svg';
import linear from '../../assets/book-icons/linear.svg';
import oneDrive from '../../assets/book-icons/one_drive.png';
import oracleFusion from '../../assets/book-icons/oracle_fusion.png';
import outlook from '../../assets/book-icons/outlook.svg';
import pii from '../../assets/book-icons/pii.svg';
import powerbi from '../../assets/book-icons/powerbi.svg';
import salesforce from '../../assets/book-icons/salesforce.svg';
import sharepoint from '../../assets/book-icons/sharepoint.svg';
import slack from '../../assets/book-icons/slack.svg';
import zendesk from '../../assets/book-icons/zendesk.svg';
import documentProcessingGoogle from '../../assets/book-icons/document_processing_google.png';
import documentProcessingAzure from '../../assets/book-icons/document_processing_azure.jpeg';
import servicenow from '../../assets/book-icons/servicenow.svg';
import paycom from '../../assets/book-icons/paycom.jpg';
import translator from '../../assets/book-icons/document_translator.jpg';
import sftp from '../../assets/book-icons/sftp.png';
import azureMicrosoft from '../../assets/book-icons/azure_microsoft.png';
import stripe from '../../assets/book-icons/stripe.svg';
import database from '../../assets/book-icons/database.png';
import ocr from '../../assets/book-icons/ocr.png';

interface IProps {
  id: string;
}

const BookIcon = forwardRef((props: IProps, ref: any) => {
  const NAME_ICON_MAP: Record<string, any> = {
    airtable,
    adobe_sign: adobe,
    aws_s3: awsS3,
    google_cloud: cloudStorage,
    email,
    epicor,
    excel,
    github,
    gsheet: sheets,
    html,
    hubspot,
    document_processing: receipt,
    linear,
    onedrive: oneDrive,
    oracle_fusion: oracleFusion,
    outlook,
    pii_processing: pii,
    powerbi,
    salesforce,
    sharepoint,
    slack,
    zendesk,
    document_processing_google: documentProcessingGoogle,
    document_processing_azure: documentProcessingAzure,
    servicenow,
    hr_module_paycom: paycom,
    translation: translator,
    azure_storage: azureMicrosoft,
    azure_service_bus: azureMicrosoft,
    stripe,
    db_api: database,
    sftp,
    ocr
  };

  return (
    <img
      ref={ref}
      src={NAME_ICON_MAP[props.id]}
      alt="book-icon"
      height={20}
      width={20}
    />
  );
});

export default BookIcon;
