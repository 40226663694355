import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Editor, Transforms, createEditor, Node } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import shortUUID from 'short-uuid';
import ReactDOM from 'react-dom';
import { useApolloClient, useSubscription } from '@apollo/client';
import { Button, message } from 'antd';
import { ON_WORKER_SENTENCE_UPDATE } from '@/graphql/subscriptions/playground';
import {
  GET_LOG_MANAGER,
  GET_LOOP_MANAGER,
  GET_WORKER_MAP
} from '@/graphql/queries/playground';
import { isEqual, last } from 'lodash';
import { useCustomCompareLayoutEffect } from 'use-custom-compare';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import RenderLeaf from '../Editor/RenderLeaf';
import { generateText, getNodeByPath, parseText } from './utils';
import {
  EditorBlock,
  EditorSourceType,
  ExecutionStatus,
  ISentence
} from '../Editor/editorInterface';
import styles from './RichTextEditor.module.less';
import {
  handleCopy,
  handleCut,
  handleKeyDown,
  handleNewLineAdd,
  handlePaste
} from './keyBindings';
import {
  isRequestExists,
  replaceEditorText,
  scrollElementIntoView
} from '../Editor/editorUtil';
import {
  WIDGET_TYPES,
  editableWidgets,
  textFormatter,
  widgetDecorator
} from '../EditorWidgets/decorators';
import { TabKeys } from '../OutputPanel/OutputPanel';
import { withCustomNormalization } from './withCustomNormalizer';
import { useKogEditorContext } from '../Editor/EditorProvider';
import RenderElement from '../Editor/RenderElement';

interface RichTextEditorProps {
  workerId: string | null;
  initialValue: any;
  setActiveBlock: any;
  readOnly?: boolean;
  getEditorPlainText?: (editorText: string) => void;
  showLineResult?: boolean;
  isOutputPanelVisible?: boolean;
  hideOutputPanel?: () => void;
  expandDocumentPreview?: (open: boolean) => void;
  isDebugMode?: boolean;
  editorName?: string;
}

export function Portal({ children }: { children: React.ReactNode }) {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
}

function RichTextEditor({
  workerId,
  initialValue,
  setActiveBlock,
  getEditorPlainText,
  showLineResult,
  isOutputPanelVisible,
  expandDocumentPreview,
  editorName,
  readOnly = false
}: RichTextEditorProps) {
  const client = useApolloClient();
  const location = useLocation();
  const {
    source,
    editorStatus,
    debugMode,
    activeBlock,
    subDocumentToken,
    replacerObj,
    editorValue,
    actions,
    widgets
  } = useKogEditorContext();
  const editor = useMemo(
    () => withCustomNormalization(withHistory(withReact(createEditor()))),
    []
  );
  const editorRef = useRef<HTMLDivElement>(null);
  const [key, setKey] = useState('');
  const [showWidgetOptions, setShowWidgetOptions] = useState(false);
  const [selectedWidgetOptionIndex, setSelectedWidgetOptionIndex] = useState(0);
  const [targetEl, setTargetEl] = useState<any>();

  const widgetOptionRef = useRef(null);

  useEffect(() => {
    if (initialValue) {
      setKey(shortUUID.generate());
    }
  }, [initialValue]);

  useEffect(() => {
    if (
      location.pathname.includes('run') &&
      source !== EditorSourceType.MINI_PLAYGROUND
    ) {
      actions.updateEditorStatus({ readOnly: true });
    } else {
      actions.updateEditorStatus({ readOnly });
    }
  }, [readOnly, location.pathname]);

  // TODO: Don't add this subscription if it is Procedure page, Only subscribe for playground & process run page
  const { error: subscriptionError } = useSubscription(
    ON_WORKER_SENTENCE_UPDATE,
    {
      variables: { workerId, documentToken: subDocumentToken },
      onData: ({ data: subscriptionData }) => {
        if (!subscriptionData.data) return;
        const { sentences } = subscriptionData.data.onSentenceUpdate;

        const workerMap = client.readQuery({
          query: GET_WORKER_MAP
        });

        const editorNodes = Array.from(Node.elements(editor)).map((n) => {
          return { node: n[0].children[0], path: n[1] };
        });

        const keepActiveblockFlag = sentences.some(
          (s: any) => s.status === ExecutionStatus.ERROR
        );

        Editor.withoutNormalizing(editor, () => {
          for (let i = 0; i < editorNodes.length; i += 1) {
            const editorNode = editorNodes[i].node as ISentence;
            const sentence = sentences.find(
              (s: any) =>
                s.lineId === workerMap?.getWorkerMap?.idMap[editorNode.uiLineId]
            );
            if (sentence) {
              const updateItems: any = {};
              updateItems.status = sentence.status;
              updateItems.token = sentence.token;
              updateItems.epoch = sentence.epoch;

              const oldLogData = client.readQuery({ query: GET_LOG_MANAGER });
              client.writeQuery({
                query: GET_LOG_MANAGER,
                data: {
                  getLogManager: {
                    data: {
                      ...oldLogData?.getLogManager?.data,
                      [`${editorNode.uiLineId}-execution`]: sentence
                    }
                  }
                }
              });

              // Need to fix it for iteration line maybe
              if (sentence.subDocuments) {
                updateItems.subDocuments = sentence.subDocuments;
              }

              if (sentence.miniPlaygrounds) {
                updateItems.miniPlaygrounds = sentence.miniPlaygrounds;
              }

              if (sentence.iterationTokens.length > 0) {
                // updateItems.
                // TODO: Hanlde loop results here

                updateItems.loopResultCount = sentence?.iterationTokens?.length;
                const d = {
                  [editorNode.uiLineId]: {
                    currentLoopIndex: sentence.iterationTokens.length - 1,
                    currentLoopToken:
                      sentence.iterationTokens[
                        sentence.iterationTokens.length - 1
                      ],
                    totalLoopCount: sentence.iterationTokens.length - 1,
                    loopResults: sentence.iterationTokens
                  }
                };
                const oldLoopManager = client.readQuery({
                  query: GET_LOOP_MANAGER
                });
                client.writeQuery({
                  query: GET_LOOP_MANAGER,
                  data: {
                    getLoopManager: {
                      data: {
                        ...oldLoopManager?.getLoopManager?.data,
                        ...d
                      }
                    }
                  }
                });
              } else {
                if (sentence?.token === '/0') {
                  updateItems.answer = sentence.answer;
                  updateItems.concepts = sentence.concepts;
                  updateItems.requests = sentence.requests;
                } else {
                  // TODO: Need to check for loop result token
                  updateItems.requests = sentence.requests;
                  updateItems.editorAnswers = {
                    ...updateItems.editorAnswers,
                    [sentence.token]: sentence.answer
                  };
                  updateItems.editorConcepts = {
                    ...updateItems.editorConcepts,
                    [sentence.token]: sentence.concepts
                  };
                  if (sentence.requests.length > 0) {
                    updateItems.editorRequests = {
                      ...updateItems.editorRequests,
                      [sentence.token]: sentence.requests
                    };
                  } else {
                    updateItems.editorRequests = {};
                  }
                  if (sentence.subDocuments) {
                    updateItems.editorSubDocuments = {
                      ...updateItems.editorSubDocuments,
                      [sentence.token]: sentence.subDocuments
                    };
                  }
                }
              }
              Transforms.setNodes(
                editor,
                { ...editorNode, ...updateItems },
                { at: [editorNodes[i].path[0], 0] }
              );
            }
          }
        });
        if (!keepActiveblockFlag) {
          setActiveBlock(null);
        }
      },
      skip: !workerId
    }
  );

  useCustomCompareLayoutEffect(
    () => {
      const activeBlockUILineId = activeBlock?.uiLineId;
      const docLineIds = editorValue?.map(
        // @ts-ignore
        (line: EditorBlock) => line?.children[0]?.uiLineId
      );
      const isActiveSentenceBlockDeleted = !docLineIds?.includes(
        activeBlockUILineId ?? ''
      );
      if (activeBlock && isActiveSentenceBlockDeleted) {
        setActiveBlock(null);
        actions.updateEditorStatus({ showOutputPanel: false });
      }

      if (
        editorValue &&
        editorValue.length > 0 &&
        !activeBlock &&
        isOutputPanelVisible
      ) {
        const lastBlockWhereRequestExists = [...editorValue]
          .reverse()
          .find((bl) => isRequestExists((bl as any).children[0] as ISentence));

        const blockToShow =
          lastBlockWhereRequestExists || (editorValue[0] as any);
        const tabToShow = lastBlockWhereRequestExists
          ? TabKeys.Requests
          : TabKeys.Results;

        setActiveBlock(blockToShow.children[0] as ISentence, tabToShow, true);
      }
    },
    [isOutputPanelVisible, activeBlock, editorValue],
    (prev, cur) => isEqual(prev, cur)
  );

  useEffect(() => {
    let observer: any;
    let element: any;
    if (activeBlock && isOutputPanelVisible) {
      element = document.getElementById(
        // @ts-ignore
        activeBlock.uiLineId
      );
      // TODO: make this generic
      observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            return;
          }
          scrollElementIntoView(element!);
          observer.unobserve(element!);
        },
        {
          root: null,
          threshold: 0.1 // set offset 0.1 means trigger if atleast 10% of element in viewport
        }
      );

      if (element !== null) {
        observer.observe(element!);
      }
    }
    return () => observer && element && observer.unobserve(element);
  }, [isOutputPanelVisible, activeBlock]);

  const setProcedureFromChatHandler = (res: any) => {
    const codeBlock = res.detail as string;
    const parsedOutput = parseText(codeBlock.replace(/\n+$/, ''));
    if (parsedOutput.errorMesaage.length === 0) {
      ReactEditor.focus(editor);
      // TODO: May need to store the previous cursor point, currently it is set to the end of the document
      Transforms.select(editor, {
        anchor: Editor.end(editor, []),
        focus: Editor.end(editor, [])
      });

      const selection = editor.selection;

      if (
        selection?.focus.offset === 0 &&
        selection.focus.path[0] === 0 &&
        selection.focus.path[1] === 0
      ) {
        const updatedText = parsedOutput.documentLines[0].children[0].text;
        Transforms.insertText(editor, updatedText, {
          at: selection.focus.path
        });
        if (parsedOutput.documentLines.length > 1) {
          Transforms.insertNodes(editor, parsedOutput.documentLines.slice(1));
        }
      } else {
        Transforms.insertNodes(editor, parsedOutput.documentLines);
      }
    } else {
      // TODO: Fix the messge later
      message.error('Unable to parse, Please copy and paste it manually');
      console.log('Unable to parse text from koncierge');
    }
  };

  useEffect(() => {
    document.addEventListener(
      'setProcedureFromChat',
      setProcedureFromChatHandler
    );

    return () => {
      document.removeEventListener(
        'setProcedureFromChat',
        setProcedureFromChatHandler
      );
    };
  }, []);

  useEffect(() => {
    if (subscriptionError) {
      console.log('subscriptionError', subscriptionError);
    }
  }, [subscriptionError]);

  useEffect(() => {
    actions.onEditorValueChange(initialValue, workerId);
  }, [initialValue]);

  useEffect(() => {
    if (getEditorPlainText) {
      const items = Array.from(Node.elements(editor)).map(
        (n) => n[0]
      ) as EditorBlock[];
      const text = generateText(items);
      getEditorPlainText(text);
    }
  }, [editorValue]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        // setTarget(undefined);
        // setSearch('');
        // setSearchResults([]);
      }
    };
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [editorRef.current]);

  useEffect(() => {
    const errorlineCb = () => {
      if (editorRef.current) {
        const elements = Array.from(
          document.getElementsByClassName('editorErrorLine') || []
        );
        const lastErrorLine = last(elements);
        if (lastErrorLine) {
          // TODO: Sakti - remove
          lastErrorLine.classList.add('editorErrorLineLast');
          lastErrorLine.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
            // inline: 'start'
          });
        }
      }
    };

    if (source !== EditorSourceType.MINI_PLAYGROUND) {
      if (editorStatus.readOnly) {
        errorlineCb();
      } else {
        setTimeout(errorlineCb, 500);
      }
    }
  }, [editorRef.current]);

  useEffect(() => {
    if (showWidgetOptions) {
      const el: any = widgetOptionRef.current;
      if (el && targetEl) {
        const domRange = ReactEditor.toDOMRange(editor, targetEl);
        const rect = domRange.getBoundingClientRect();
        el.style.top = `${rect.top + window.pageYOffset + 24}px`;
        el.style.left = `${rect.left + window.pageXOffset}px`;
      }
    }
  }, [showWidgetOptions]);

  const handleWidgetEdit = (data: Record<string, string>) => {
    actions.updateReplacerObj?.((prev) => ({ ...prev, ...data }));
  };

  const onWidgetFileUpload = (filesList: Record<'s3Url', string>[]) => {
    const fileStr = filesList.map((i) => `"${i.s3Url}"`).join(', ');
    const { selection } = editor;
    if (selection) {
      const node = getNodeByPath(editor, selection.anchor.path);
      // @ts-ignore
      const oldText = node.children[0].text;
      const newText = oldText.replace('upload file', fileStr);
      const formattedData = textFormatter(newText, replacerObj);
      handleWidgetEdit(formattedData.replacerObj);
      replaceEditorText(
        editor,
        formattedData.text,
        selection.anchor,
        selection.focus
      );
    }
  };

  const handleWidgetDelete = (widgetKey: string, uiLineId: string) => {
    const path = [
      // @ts-ignore
      editorValue.findIndex((bl) => bl.children[0].uiLineId === uiLineId),
      0
    ];
    const node = getNodeByPath(editor, path);
    // @ts-ignore
    const text = node.children[0].text;
    const updatedText = text.replace(widgetKey, '');
    replaceEditorText(editor, updatedText, { path }, { path }, text);
    actions.updateReplacerObj((prev) => {
      const newObj = { ...prev };
      delete newObj[widgetKey];
      return newObj;
    });
  };

  const renderLeaf = useCallback(
    (props: any) => {
      return (
        <RenderLeaf
          {...props}
          blocks={editorValue}
          activeBlock={activeBlock}
          setActiveBlock={setActiveBlock}
          autoPilotChecked={editorStatus.autoPilot}
          replacerObj={replacerObj}
          handleWidgetEdit={handleWidgetEdit}
          workerId={workerId}
          onWidgetFileUpload={onWidgetFileUpload}
          expandDocumentPreview={expandDocumentPreview}
          handleWidgetDelete={handleWidgetDelete}
        />
      );
    },
    [editorValue, activeBlock, setActiveBlock]
  );

  const renderElement = useCallback(
    (props: any) => {
      return (
        <RenderElement
          {...props}
          workerId={workerId}
          blocks={editorValue}
          activeBlock={activeBlock}
          setActiveBlock={setActiveBlock}
          autoPilotChecked={editorStatus.autoPilot}
          showLineResult={showLineResult}
          onSubprocedureClick={actions.onSubprocedureClick}
          isDebugMode={debugMode}
          handleWidgetEdit={handleWidgetEdit}
          replacerObj={replacerObj}
          activeDocumentToken={subDocumentToken}
        />
      );
    },
    [
      workerId,
      editorValue,
      activeBlock,
      setActiveBlock,
      editorStatus.autoPilot,
      showLineResult,
      actions.onSubprocedureClick,
      debugMode,
      handleWidgetEdit,
      replacerObj,
      subDocumentToken
    ]
  );

  const isNewLineBtnRequired = () => {
    if (editorStatus.readOnly) {
      return false;
    }

    if (editorValue?.length > 1) {
      return true;
    }
    if (
      editorValue.length === 1 &&
      // @ts-ignore
      editorValue[0].children[0].text.length > 0
    ) {
      return true;
    }
    return false;
  };

  const handleShowWidgetOptions = (open: boolean) => {
    setShowWidgetOptions(open);
    if (!open) {
      setSelectedWidgetOptionIndex(0);
    }
  };

  const updateWidgetOption = (upPressed: boolean) => {
    if (upPressed) {
      if (selectedWidgetOptionIndex === 0) {
        setSelectedWidgetOptionIndex(editableWidgets.length - 1);
      } else {
        setSelectedWidgetOptionIndex((prev) => prev - 1);
      }
    } else {
      if (selectedWidgetOptionIndex === editableWidgets.length - 1) {
        setSelectedWidgetOptionIndex(0);
      } else {
        setSelectedWidgetOptionIndex((prev) => prev + 1);
      }
    }
  };

  const createWidget = (type: WIDGET_TYPES, baseKey: string) => {
    const { anchor, focus } = editor.selection as any;

    const node = getNodeByPath(editor, anchor.path);
    // @ts-ignore
    const text = node.children[0].text;
    const count =
      // @ts-ignore
      // eslint-disable-next-line no-unsafe-optional-chaining
      replacerObj?.KOG_WIDGET_COUNT[type] + 1;
    const key = `[${baseKey}${count}]`;
    const updatedText = `${text.slice(0, anchor.offset - 1)}${key}${text.slice(
      anchor.offset
    )}`;
    replaceEditorText(editor, updatedText, anchor, focus);
    let value = '';
    if (type === WIDGET_TYPES.MARKDOWN) {
      value = '""""""';
    }
    // @ts-ignore
    handleWidgetEdit({
      [key]: {
        value,
        type
      },
      // @ts-ignore
      KOG_WIDGET_COUNT: {
        ...(replacerObj?.KOG_WIDGET_COUNT as Object),
        [type]: count
      }
    });
    widgets.setWidgetData({
      // @ts-ignore
      lineId: node.children[0].uiLineId,
      widgetType: type,
      widgetKey: key,
      widgetValue: value
    });
  };

  const selectWidgetAtIndex = () => {
    if (!editor.selection) return;
    const { anchor, focus } = editor.selection as any;
    if (anchor.path[0] !== focus.path[0] || anchor.offset !== focus.offset)
      return;
    switch (editableWidgets[selectedWidgetOptionIndex].type) {
      case WIDGET_TYPES.FILE: {
        const node = getNodeByPath(editor, anchor.path);
        // @ts-ignore
        const text = node.children[0].text;
        const key = `upload file`;
        const updatedText = `${text.slice(
          0,
          anchor.offset - 1
        )}${key}${text.slice(anchor.offset)}`;
        replaceEditorText(editor, updatedText, anchor, focus);
        handleWidgetEdit({
          // @ts-ignore
          [key]: {
            value: '',
            type: WIDGET_TYPES.FILE
          }
        });
        break;
      }

      case WIDGET_TYPES.MARKDOWN: {
        createWidget(WIDGET_TYPES.MARKDOWN, 'md');
        break;
      }

      case WIDGET_TYPES.JSON: {
        createWidget(WIDGET_TYPES.JSON, 'json');
        break;
      }

      case WIDGET_TYPES.URL: {
        createWidget(WIDGET_TYPES.URL, 'url');
        break;
      }

      default:
        break;
    }
    handleShowWidgetOptions(false);
  };

  const onPlay = () => {
    if (workerId) {
      actions.onPlay(workerId);
    }
  };

  if (key.length) {
    return (
      <div ref={editorRef} style={{ position: 'relative' }}>
        <Slate
          key={key}
          editor={editor}
          initialValue={editorValue}
          onChange={(v) => {
            const { selection } = editor;
            if (selection) {
              setTargetEl(selection);
            }
            actions.onEditorValueChange(v, workerId);
          }}
        >
          <Editable
            data-cy={`slate-editor-${editorName}`}
            id="slate-editor"
            renderLeaf={renderLeaf}
            renderElement={renderElement}
            placeholder={editorStatus.readOnly ? '...' : 'Type a command...'}
            spellCheck={false}
            autoFocus
            readOnly={editorStatus.readOnly || !editorStatus.isEditing}
            onKeyDown={(e) =>
              handleKeyDown({
                e,
                editor,
                onPlay,
                handleShowWidgetOptions,
                isWidgetOptionsOpen: showWidgetOptions,
                updateWidgetOption,
                selectWidgetAtIndex
              })
            }
            onPaste={(e) => {
              e.preventDefault();
              const text = e.clipboardData.getData('text/plain');
              const formattedData = textFormatter(text, replacerObj);
              if (actions.updateReplacerObj) {
                actions.updateReplacerObj((prev) => ({
                  ...prev,
                  ...formattedData.replacerObj
                }));
              }
              handlePaste(formattedData.text, editor);
            }}
            onCopy={(e) => handleCopy(e, editor, replacerObj)}
            onCut={(e) => {
              handleCut(e, editor, replacerObj);
            }}
            decorate={(data) => widgetDecorator(data, replacerObj)}
          />
        </Slate>
        {isNewLineBtnRequired() && (
          <div
            className={classNames(styles.newLine, styles[`newLine-${source}`])}
            onClick={() => handleNewLineAdd(editor)}
          >
            <div className={styles.iconContainer}>+</div>
            <div className={styles.newLineText}>Type a command...</div>
          </div>
        )}
        {showWidgetOptions && (
          <Portal>
            <div
              ref={widgetOptionRef as any}
              className={styles.widgetOptionsContainer}
            >
              <ul className={styles.widgetOptionsList}>
                {editableWidgets.map((widget, index) => (
                  <Button
                    type="text"
                    onClick={selectWidgetAtIndex}
                    onMouseEnter={() => setSelectedWidgetOptionIndex(index)}
                    className={classNames([
                      styles.widgetOptionsItem,
                      {
                        [styles.widgetOptionsItemActive]:
                          index === selectedWidgetOptionIndex
                      }
                    ])}
                    key={widget.type}
                  >
                    {widget.label}
                  </Button>
                ))}
              </ul>
            </div>
          </Portal>
        )}
      </div>
    );
  }
  return null;
}

export default RichTextEditor;
