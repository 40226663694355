import React, { createContext, useContext, useState } from 'react';

// Context and Provider for facilitating a "virtual" refresh/reload of the app
// without calling window.location.reload().

interface RefreshContextData {
  refreshCount: number;
  refresh: () => void;
}

const RefreshContext = createContext<RefreshContextData>({
  refreshCount: 0,
  refresh: () => {}
});

const useRefresh = () => {
  const context = useContext(RefreshContext);
  if (!context) {
    throw new Error('useRefresh must be used within a RefreshProvider.');
  }
  return context;
};

interface IProps {
  children: React.ReactNode;
}

function RefreshProvider(props: IProps) {
  const [counter, setCounter] = useState(0);

  const { children } = props;

  const increment = () => {
    setCounter((prevCounter) => prevCounter + 1);
  };

  return (
    <RefreshContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ refreshCount: counter, refresh: increment }}
    >
      {children}
    </RefreshContext.Provider>
  );
}

export { RefreshProvider, useRefresh };
