import { createSlice } from '@reduxjs/toolkit';

export const devSlice = createSlice({
  name: 'dev',
  initialState: {
    isPollingEnabled: true
  },
  reducers: {
    disablePolling: (state) => {
      state.isPollingEnabled = false;
    },
    enablePolling: (state) => {
      state.isPollingEnabled = true;
    }
  }
});

export default devSlice.reducer;
