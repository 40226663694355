import { isEmpty } from 'lodash';
import { Editor, Transforms } from 'slate';
import templates from '@/pages/Playgrounds/templates';
import { GET_LOG_MANAGER } from '@/graphql/queries/playground';
import { message } from 'antd';
import {
  UISentence,
  EditorBlock,
  ISentence,
  Sentence,
  SentenceExecutionData,
  CodeBlock,
  EditorErrorRecord
} from './editorInterface';
import { parseText } from '../RichTextEditor/utils';
import { textFormatter } from '../EditorWidgets/decorators';

const SCROLL_INTO_VIEW_DELAY = 400;

export const getDepthOfLine = (
  sentence: UISentence,
  sentences: EditorBlock[]
) => {
  let depth = 0;
  let currentSentence = sentence;
  while (sentences.length > 1 && currentSentence.uiParentId) {
    depth += 1;
    const find = sentences.find(
      // eslint-disable-next-line no-loop-func
      (s) => s.children[0].uiLineId === currentSentence.uiParentId
    );
    if (find) {
      currentSentence = find.children[0];
    }
    if (find === undefined) {
      break;
    }
  }
  return depth;
};

export const getClosestParent = (nodes: UISentence[], node: UISentence) => {
  const parent = nodes.find(
    (n: { uiLineId: string }) => n.uiLineId === node?.uiParentId
  );
  return parent;
};

export const isRequest = (sentence: ISentence) => {
  if (sentence?.requests && sentence?.requests.length > 0) {
    return true;
  }
  return false;
};

export const isEmptyEditorRequests = (sentence: ISentence) => {
  const req = sentence?.editorRequests;
  if (isEmpty(req)) {
    return true;
  }

  if (req) {
    const keys = Object.keys(req);
    for (let i = 0; i < keys.length; i += 1) {
      if (req[keys[i]].length > 0) {
        return false;
      }
    }
  }

  return true;
};

export const isEmptyAnswer = (answer: any) => {
  if (answer === 'null') {
    return true;
  }
  if (isEmpty(answer)) {
    return true;
  }

  return false;
};

export const isEmptyEditorAnswers = (sentence: ISentence) => {
  const answer = sentence?.editorAnswers;
  if (isEmpty(answer)) {
    return true;
  }

  if (answer) {
    const keys = Object.keys(answer);
    for (let i = 0; i < keys.length; i += 1) {
      if (!isEmptyAnswer(answer[keys[i]])) {
        return false;
      }
    }
  }

  return true;
};

export const isRequestExists = (leaf: ISentence) => {
  if (isEmptyAnswer(leaf?.answer) && !isEmpty(leaf.requests)) {
    return true;
  }
  if (isEmptyEditorAnswers(leaf) && !isEmptyEditorRequests(leaf)) {
    return true;
  }
  return false;
};

export const scrollElementIntoView = (targetElement: HTMLElement) => {
  setTimeout(() => {
    targetElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, SCROLL_INTO_VIEW_DELAY);
};

export const getSentenceTabCount = (
  sentence: Sentence,
  sentences: Sentence[]
) => {
  let tabCount = 0;
  let currentSentence = sentence;

  const findParent = (sentences: Sentence[], parentId: string) => {
    return sentences.find((s) => s.lineId === parentId) as Sentence;
  };

  while (sentences.length > 1 && currentSentence.parentId) {
    tabCount += 1;
    const find = findParent(sentences, currentSentence.parentId);
    if (find) {
      currentSentence = find;
    }
    if (find === undefined) {
      break;
    }
  }
  return tabCount;
};

export const replaceEditorText = (
  editor: Editor,
  text: string,
  anchor: { path: any },
  focus: { path: any },
  oldText?: string
) => {
  if (oldText) {
    Transforms.delete(editor, {
      at: {
        anchor: { path: anchor.path, offset: 0 },
        focus: { path: focus.path, offset: oldText.length }
      }
    });
  }
  Transforms.insertText(editor, text, {
    at: {
      anchor: { path: anchor.path, offset: 0 },
      focus: { path: focus.path, offset: text.length }
    }
  });
};

export const getTemplateBlocksFromId = (
  id: string | null,
  keysObj?: Record<any, any>
) => {
  if (!id) return {};
  let blocks: any = {};
  let newKeysObj = { ...keysObj };
  const template = templates.find((i) => `${i.id}` === id);
  if (template) {
    const parsedOutput = parseText(template.text);
    if (parsedOutput.errorMesaage.length === 0) {
      const updatedOutput = parsedOutput.documentLines.map((el) => {
        const child = el.children[0];
        const formattedSentence = textFormatter(child.text, newKeysObj);
        newKeysObj = { ...newKeysObj, ...formattedSentence.replacerObj };
        const updatedText = formattedSentence.text
          .replace('__KOG_KONCIERGE_NEW_LINE__', '\\n--------------------\\n')
          .replaceAll('__KOG_NEW_LINE__', '\\n');
        return {
          ...el,
          children: [
            {
              ...child,
              text: updatedText
            }
          ]
        };
      });
      blocks = updatedOutput;
    }
  }
  return { blocks, replacerObj: newKeysObj };
};

export const constructIdMap = (sentences: any[]) => {
  let obj = {};
  for (const i of sentences) {
    obj = Object.assign(obj, { [i.metadata.lineId]: i.lineId });
  }
  return obj;
};

export const constructSentence = (sentences: any) => {
  return sentences?.map((i: any) => {
    const metadata: { lineId: string; parentId?: string } = {
      lineId: i.metadata.lineId
    };
    if (i.metadata.parentId) {
      metadata.parentId = i.metadata.parentId;
    }
    return {
      text: i.text,
      metadata
    };
  });
};

export const handleSaveError = (error: any, docToken: string) => {
  // FIXME: not the best way to check for Error, logically we should not make an API call if there is no change in subprocess or else
  // Backend should return metaData if request is sent to backend
  if (
    error.message.includes(
      `Cannot read properties of null (reading 'lineId')`
    ) &&
    docToken.length === 0
  ) {
    message.error({ content: error.message, duration: 2 });
  }
};

export const constructDocumentLineLogs = async (
  client: any,
  sentences: any[]
) => {
  if (!sentences) return;
  const updatedData = sentences?.reduce(
    (
      acc: { [x: string]: any },
      curr: { metadata: { lineId: string | number } }
    ) => {
      if (!curr.metadata) return acc;
      acc[`${curr.metadata.lineId}-line`] = curr;
      return acc;
    },
    {}
  );
  const oldData = client.readQuery({ query: GET_LOG_MANAGER });
  client.writeQuery({
    query: GET_LOG_MANAGER,
    data: {
      getLogManager: {
        data: { ...oldData?.getLogManager?.data, ...updatedData }
      }
    }
  });
};

export const constructDocumentExecLogs = async (
  client: any,
  data: Record<string, SentenceExecutionData>[],
  debugMode: boolean
) => {
  if (!data) return;
  if (debugMode) {
    const oldData = client.readQuery({ query: GET_LOG_MANAGER })?.getLogManager
      ?.data;

    const updatedData = data.reduce((acc, curr) => {
      const key = Object.keys(curr)?.[0];
      // eslint-disable-next-line no-param-reassign
      acc = { ...acc, [`${key}-execution`]: curr[key] };
      return acc;
    }, oldData);
    client.writeQuery({
      query: GET_LOG_MANAGER,
      data: {
        getLogManager: {
          data: updatedData
        }
      }
    });
  }
};

export const getCollapsedCodeBlocks = (lines: EditorBlock[]): CodeBlock[] => {
  const blocks: CodeBlock[] = [];
  const blockStack: CodeBlock[] = [];

  lines.forEach((line, index) => {
    // End the current block if needed
    while (
      blockStack.length > 0 &&
      (line.children[0]?.tabCount || 0) <=
        blockStack[blockStack.length - 1].tabCount
    ) {
      const finishedBlock = blockStack.pop();
      if (finishedBlock) {
        finishedBlock.endLine = index - 1;
        blocks.push(finishedBlock);
      }
    }

    // Start a new block if the next line has a greater tabCount
    if (
      index < lines.length - 1 &&
      (lines[index + 1].children[0]?.tabCount || 0) >
        (line.children[0]?.tabCount || 0)
    ) {
      blockStack.push({
        startLine: index,
        endLine: -1, // To be determined
        tabCount: line.children[0]?.tabCount || 0,
        isCollapsed: false
      });
    }
  });

  // Close any remaining open blocks
  while (blockStack.length > 0) {
    const remainingBlock = blockStack.pop();
    if (remainingBlock) {
      remainingBlock.endLine = lines.length - 1;
      blocks.push(remainingBlock);
    }
  }

  return blocks;
};

export const getCollapsibleBlock = (block: CodeBlock[], index: number) => {
  const currentBlock = block.find((b) => b.startLine === index);
  return currentBlock;
};

export const isLineHidden = (codeBlocks: CodeBlock[], lineIndex: number) => {
  return codeBlocks.some((block) => {
    return (
      block.isCollapsed &&
      block.startLine < lineIndex &&
      lineIndex <= block.endLine
    );
  });
};

export const isLineinCollapseRegion = (
  blocks: CodeBlock[],
  selectionLineIndex: number | undefined,
  collapsibleLineIndex: number
) => {
  if (selectionLineIndex === undefined) return false;

  const targetBlock = blocks.find(
    (block) => block.startLine === collapsibleLineIndex
  );

  if (!targetBlock) return false;

  return (
    targetBlock.startLine <= selectionLineIndex &&
    selectionLineIndex <= targetBlock.endLine
  );
};

/**
 *  This function will check for the following errors:
 * 1. Empty line followed by an indented line
 * 2. Incorrect indentation
 * Errors are based on 0-indexed line numbers
 * @param lines
 * @returns
 */
export const indentValidator = (lines: EditorBlock[]) => {
  const errors: EditorErrorRecord[] = [];
  let expectedTabCount = 0; // This will keep track of the expected maximum tabCount for the current line

  for (let i = 0; i < lines.length; i += 1) {
    const currentLine = lines[i];
    const currentTabCount = currentLine.children[0]?.tabCount || 0;

    // Check for Error Type 1: Empty line followed by an indented line
    if (
      currentLine.children[0].text === '' &&
      lines[i + 1] &&
      (lines[i + 1].children[0]?.tabCount || 0) > currentTabCount
    ) {
      errors.push({
        lineNumber: i,
        errorMessage: 'Empty line followed by indented line'
      });
    }

    // Check for Error Type 2: Incorrect indentation
    if (currentTabCount > expectedTabCount) {
      errors.push({ lineNumber: i, errorMessage: 'Incorrect indentation' });
    }

    // Update the expectedTabCount for the next iteration
    // If the current line is not empty, set expectedTabCount to currentTabCount + 1
    // Otherwise, keep the expectedTabCount the same
    if (currentLine.children[0].text !== '') {
      expectedTabCount = currentTabCount + 1;
    }
  }

  return errors;
};
