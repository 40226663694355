import { DocumentLineInput } from './editorInterface';

class TreeNode {
  uiLineId: string;

  tabCount: number;

  text: string;

  // eslint-disable-next-line no-use-before-define
  parent: TreeNode | null;

  // eslint-disable-next-line no-use-before-define
  children: TreeNode[];

  constructor(
    uiLineId: string,
    tabCount: number,
    text: string,
    parent: TreeNode | null,
    children: TreeNode[] = []
  ) {
    this.uiLineId = uiLineId;
    this.tabCount = tabCount;
    this.text = text;
    this.parent = parent;
    this.children = children;
  }

  addChild(child: TreeNode) {
    this.children.push(child);
  }
}

export function createTree(
  lines: { uiLineId: string; tabCount: number; text: string }[]
): TreeNode {
  const stack: TreeNode[] = [];
  const root = new TreeNode('-1', -1, '', null);

  stack.push(root);

  for (const { tabCount, text, uiLineId } of lines) {
    let topNode = stack[stack.length - 1];
    let topNodeParent = topNode.parent;

    let currentNode;

    if (topNode.tabCount === tabCount) {
      currentNode = new TreeNode(uiLineId, tabCount, text, topNodeParent);
      if (topNodeParent) {
        topNodeParent.addChild(currentNode);
      }
    } else if (tabCount === topNode.tabCount + 1) {
      currentNode = new TreeNode(uiLineId, tabCount, text, topNode);
      if (topNode) {
        topNode.addChild(currentNode);
      }
    } else if (tabCount > topNode.tabCount + 1) {
      throw new Error('Invalid tabCount');
      // console.log(`invalid tabCount${tabCount}`, topNode);
    } else if (tabCount < topNode.tabCount) {
      while (true) {
        stack.pop();
        if (stack.length === 0) {
          break;
        }
        topNode = stack[stack.length - 1];
        topNodeParent = topNode.parent;
        if (topNode.tabCount === tabCount) {
          break;
        }
      }

      topNode = stack[stack.length - 1];
      topNodeParent = topNode.parent;
      currentNode = new TreeNode(uiLineId, tabCount, text, topNodeParent);
      if (topNodeParent) {
        topNodeParent.addChild(currentNode);
      }
    }

    if (currentNode) {
      stack.push(currentNode);
    }
  }

  return root;
}
export const dfs = (root: TreeNode, callback: (node: TreeNode) => void) => {
  const stack: TreeNode[] = [];
  stack.push(root);

  while (stack.length > 0) {
    const node = stack.pop();
    if (node) {
      callback(node);
      for (let i = node.children.length - 1; i >= 0; i -= 1) {
        stack.push(node.children[i]);
      }
    }
  }
};

export function transformInputToOutput(input: any[]): DocumentLineInput[] {
  const output: DocumentLineInput[] = [];
  const parentStack: { tabCount: number; lineId: string }[] = [];

  for (const item of input) {
    const { text, uiLineId, tabCount } = item;

    // Find the closest parent with tabCount less than the current item
    let parentId: string | undefined;
    for (let i = parentStack.length - 1; i >= 0; i--) {
      if (parentStack[i].tabCount < tabCount) {
        parentId = parentStack[i].lineId;
        break;
      }
    }

    const outputItem: DocumentLineInput = {
      text,
      metadata: {
        lineId: uiLineId
      }
    };

    if (parentId) {
      outputItem.metadata!.parentId = parentId;
    }

    output.push(outputItem);

    // Add the current item to the parent stack
    parentStack.push({ tabCount, lineId: uiLineId });
  }

  return output;
}
