import {
  GetFactQuery,
  GetFactQueryVariables,
  Fact,
  GetFactsQuery,
  GetFactsQueryVariables
} from '@/generated/API';
import Queries from '@/graphql/Queries';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import { FetchResult } from '@apollo/client';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';

export const getFact = createAsyncThunk<
  FetchResult<GetFactQuery>,
  GetFactQueryVariables,
  AsyncThunkConfig
>('fact/fetch', async (variables, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.Fact(),
    variables
  })
);

export const getFacts = createAsyncThunk<
  FetchResult<GetFactsQuery>,
  GetFactsQueryVariables,
  AsyncThunkConfig
>('facts/fetch', async (variables, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.Facts(),
    variables
  })
);

export const FactAdapter = createEntityAdapter<Fact>();

export const factSlice = createSlice({
  name: 'fact',
  initialState: FactAdapter.getInitialState({
    loading: false,
    error: undefined,
    nextToken: undefined
  }),
  reducers: {
    updateFact: FactAdapter.updateOne
  },
  extraReducers: (builder) => {
    builder.addCase(getFact.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(getFact.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      FactAdapter.upsertOne(state, action.payload.data?.getFact as Fact);
    });
    builder.addCase(getFact.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as any;
    });
    builder.addCase(getFacts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFacts.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getFacts.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      FactAdapter.upsertMany(state, action.payload.data?.getFacts as Fact[]);
    });
  }
});

export const { updateFact } = factSlice.actions;
export const factSelectors = FactAdapter.getSelectors<RootState>(
  (state) => state.fact
);
export default factSlice.reducer;
