import { EditOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../../stores/hooks';
import { showPopup } from '../../../stores/slices/appPopup';
import AppConstants from '../../../utils/AppConstants';
import { decodeMarkdownText } from '../helper';
import './MarkdownLink.less';

interface IProps {
  text: string;
  onSave: (text: string) => void;
}

function MarkdownLink(props: IProps) {
  const { text, onSave } = props;

  const dispatch = useAppDispatch();

  return (
    <Tag
      icon={<EditOutlined />}
      color="default"
      className="markdown-link"
      onClick={() => {
        dispatch(
          showPopup({
            popupId: AppConstants.POPUPS.MARKDOWN_EDITOR,
            popupParams: {
              text: decodeMarkdownText(text),
              onSave
            }
          })
        );
      }}
      // eslint-disable-next-line react/no-children-prop
      children="<#>"
    />
  );
}

export default MarkdownLink;
