import React from 'react';

interface IProps {
  html: string;
}
// TODO: Need to add DOMPurify to sanitize the HTML
function HTMLViewer(props: IProps) {
  const { html } = props;

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: html
      }}
    />
  );
}

export default HTMLViewer;
