// import { Department } from '@/generated/API';
// import routes from '@/utils/routes';
import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import TopHeaderLogoIcon from '@/components/icons/TopHeaderLogoIcon';
import AccountIcon from '@/components/icons/AccountIcon';
import PortalContainer from '@/components/PortalContainer';
import UserMenu from '@/components/UserMenu/UserMenu';
import DepartmentSelectorMenu from '@/components/department/DepartmentSelectorMenu';
import { DownOutlined } from '@ant-design/icons';
import { departmentQuerySelector } from '@/stores/slices/department';
import { useAppSelector } from '@/stores/hooks';
import classnames from 'classnames';
import { Department } from '@/generated/API';
import styles from './AppTopHeader.module.less';

enum ActionComponents {
  ACCOUNT = 'ACCOUNT',
  DEPARTMENT_SELECTOR = 'DEPARTMENT_SELECTOR'
}

export const HEADER_HEIGHT = 42;

function AppTopHeader() {
  const [actionComponent, setActionComponent] = useState<string | undefined>();

  const [departments, setDepartments] = useState<Department[]>([]);

  const { department } = useAppSelector(departmentQuerySelector);
  let depName = department?.name || '';
  if (depName.length > 10) {
    depName = `${depName.substring(0, 10)}...`;
  }

  const handleMenuItemClicked = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    setActionComponent((prev) => {
      if (prev !== id) {
        return id;
      }
      return undefined;
    });
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.pageName}>
          <TopHeaderLogoIcon />
        </div>
        <div className={styles.rightContainer}>
          <span
            data-cy="nav-ACCOUNT"
            className={styles.iconContainer}
            onClick={(e) => handleMenuItemClicked(e, ActionComponents.ACCOUNT)}
          >
            <AccountIcon />
          </span>
          <div className={styles.divider} />
          <button
            data-cy="top-nav-dept-selector"
            type="button"
            className={classnames([
              styles.DepartmentSelectorButton,
              {
                [styles.DepartmentSelectorButtonActive]:
                  actionComponent === ActionComponents.DEPARTMENT_SELECTOR
              }
            ])}
            onClick={(e) =>
              handleMenuItemClicked(e, ActionComponents.DEPARTMENT_SELECTOR)
            }
          >
            <span>Department{depName && `: ${depName}`}</span>
            <DownOutlined />
          </button>
        </div>
      </div>
      {actionComponent === ActionComponents.ACCOUNT ? (
        <PortalContainer
          style={{
            top: HEADER_HEIGHT + 2,
            right: '180px'
          }}
          hide={() => setActionComponent(undefined)}
        >
          <UserMenu hide={() => setActionComponent(undefined)} />
        </PortalContainer>
      ) : null}

      {actionComponent === ActionComponents.DEPARTMENT_SELECTOR ? (
        <PortalContainer
          style={{
            top: HEADER_HEIGHT + 2,
            right: 16
          }}
          hide={() => setActionComponent(undefined)}
        >
          <DepartmentSelectorMenu
            departments={departments}
            setDepartments={setDepartments}
            hide={() => setActionComponent(undefined)}
          />
        </PortalContainer>
      ) : null}
    </div>
  );
}

export default AppTopHeader;
