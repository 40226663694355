/* eslint-disable react/function-component-definition */

declare let window: any;

export const getWindowCache = (cacheName: string) => (key: string) => {
  const windowObj = window[cacheName];
  if (!windowObj) {
    return null;
  }
  return windowObj[key];
};

export const setWindowCache =
  (cacheName: string) => (key: string, data: any) => {
    const windowObj = window[cacheName];
    window[cacheName] = {
      ...(windowObj || {}),
      [key]: data
    };
  };
