import { Moment } from 'moment';

export enum QuestionInputType {
  VALUE = 'value',
  VALUE_UPLOAD = 'value-upload',
  VALUE_CHOICE = 'value-choice',
  VALUE_EMPTY = 'value-empty',
  CONCEPT = 'concept',
  TECHNIQUE = 'technique',
  NO_VALUE = 'no-value',
  SKIP = 'skip',
  SKIP_RUN_END = 'skip-run-end',
  RETRY = 'retry',
  APPROVE_REVIEW = 'approve-review',
  NONE = '',
  RETRY_AFTER_INTERVAL = 'retry-after-interval',
  DOCUMENT_FIELDS = 'document-fields'
}

export interface IQuestionValueDocumentField {
  text: string;
  // location?: string;
}

export interface IQuestionValues {
  inputType: QuestionInputType;
  value?: string;
  upload?: { s3Url: string }[];
  choice?: string;
  technique?: string;
  remember?: boolean;
  secret?: boolean;
  retryDelayDateTime?: Moment;
  retryTimeoutMinutes?: number;
  documentFields?: IQuestionValueDocumentField[];
}

export enum QuestionAnswerMode {
  SINGLE = 'single',
  BATCH = 'batch'
}

export interface IQuestionConfig {
  answerMode: QuestionAnswerMode;
  useSummaryQuestionText?: boolean;
  allowDocumentExceptionEditingInPopup?: boolean;
  autoSelectDocumentException?: boolean;
}
