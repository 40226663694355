import { gql } from '@apollo/client';

export const GET_PROCEDURE = gql(`
  query GetProcedure(
    $procedureId: ID!
    $knowledgeId: ID
    $departmentId: ID
    $version: String
  ) {
    getProcedure(
      procedureId: $procedureId
      knowledgeId: $knowledgeId
      departmentId: $departmentId
      version: $version
    ) {
      id
      knowledgeId
      departmentId
      text
      name
      owner
      language
      bookReference
      schedules {
        name
        expression
        enabled
      }
      email {
        stage
        email
      }
      title
      version
      assignmentPolicy {
        policyType
        collaborator
      }
    }
  }
`);

export const INVOKE_PROCEDURE = gql(`
  mutation InvokeProcedure2(
    $departmentId: ID!
    $procedureId: ID!
    $knowledgeId: ID
    $owner: String
    $name: String
    $description: String
    $debug: Boolean
    $local: Boolean
    $commandParamsInput: CommandParamsInput
    $version: String
  ) {
    invokeProcedure2(
      departmentId: $departmentId
      procedureId: $procedureId
      knowledgeId: $knowledgeId
      owner: $owner
      name: $name
      description: $description
      debug: $debug
      local: $local
      commandParamsInput: $commandParamsInput
      version: $version
    ) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      attachments
      isArchived
    }
  }
`);

export const UPDATE_PROCEDURE = gql(`
  mutation UpdateProcedure($input: UpdateProcedureInput!) {
    updateProcedure(input: $input) {
      id
      knowledgeId
      departmentId
      text
      name
      owner
      language
      bookReference
      schedules {
        name
        expression
        enabled
      }
      email {
        stage
        email
      }
      title
      version
      assignmentPolicy {
        policyType
        collaborator
      }
    }
  }
`);

export const GENERATE_PROCESS_VISUALIZATION = gql`
  mutation GenerateProcessVisualization($procedureId: ID!, $departmentId: ID!) {
    generateProcessVisualization(
      procedureId: $procedureId
      departmentId: $departmentId
    ) {
      status
      message
    }
  }
`;

export const LIST_PROCEDURES_BY_DEPARTMENT = gql`
  query ListProceduresByDepartment(
    $departmentId: ID!
    $limit: Int = 100
    $version: String
    $nextToken: String
    $procedureFilter: ListProceduresFilter
  ) {
    listProceduresByDepartment(
      departmentId: $departmentId
      limit: $limit
      version: $version
      nextToken: $nextToken
      procedureFilter: $procedureFilter
    ) {
      items {
        id
        knowledgeId
        departmentId
        name
        owner
        language
        public
        bookReference
        title
        version
      }
      nextToken
    }
  }
`;
