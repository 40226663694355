// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentExceptionExtractions-module__extractionsWrapper__kL3fd {
  position: relative;
}
.DocumentExceptionExtractions-module__annotationInput__L_nKl {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8px;
}
.DocumentExceptionExtractions-module__textareaWrapper__dIJud {
  width: 100%;
  position: relative;
}
.DocumentExceptionExtractions-module__textarea__sc4LM {
  margin-right: 4px;
}
.DocumentExceptionExtractions-module__textareaExtraPadding__faAOq {
  padding-right: 26px;
}
.DocumentExceptionExtractions-module__openInWindowIcon__uHSIc {
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
}
.DocumentExceptionExtractions-module__onboardingContent__hJeOG {
  margin-top: 50px;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.DocumentExceptionExtractions-module__onboardingImage__TAUih {
  width: 100%;
  max-width: 280px;
  border: 1px solid #f0f0f0;
}
.DocumentExceptionExtractions-module__onboardingText__uRArH {
  font-size: 14px;
  text-align: center;
  margin-top: 12px;
  max-width: 280px;
}
`, "",{"version":3,"sources":["webpack://./src/components/exception/document-exception/DocumentExceptionExtractions.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAEA;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;EACA,eAAA;AAAF;AAGA;EACE,WAAA;EACA,kBAAA;AADF;AAIA;EACE,iBAAA;AAFF;AAKA;EACE,mBAAA;AAHF;AAMA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,eAAA;AAJF;AAOA;EACE,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AALF;AAQA;EACE,WAAA;EACA,gBAAA;EACA,yBAAA;AANF;AASA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AAPF","sourcesContent":[".extractionsWrapper {\n  position: relative;\n}\n\n.annotationInput {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  margin-top: 8px;\n}\n\n.textareaWrapper {\n  width: 100%;\n  position: relative;\n}\n\n.textarea {\n  margin-right: 4px;\n}\n\n.textareaExtraPadding {\n  padding-right: 26px;\n}\n\n.openInWindowIcon {\n  position: absolute;\n  top: 6px;\n  right: 6px;\n  cursor: pointer;\n}\n\n.onboardingContent {\n  margin-top: 50px;\n  margin-bottom: 12px;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.onboardingImage {\n  width: 100%;\n  max-width: 280px;\n  border: 1px solid #f0f0f0;\n}\n\n.onboardingText {\n  font-size: 14px;\n  text-align: center;\n  margin-top: 12px;\n  max-width: 280px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extractionsWrapper": `DocumentExceptionExtractions-module__extractionsWrapper__kL3fd`,
	"annotationInput": `DocumentExceptionExtractions-module__annotationInput__L_nKl`,
	"textareaWrapper": `DocumentExceptionExtractions-module__textareaWrapper__dIJud`,
	"textarea": `DocumentExceptionExtractions-module__textarea__sc4LM`,
	"textareaExtraPadding": `DocumentExceptionExtractions-module__textareaExtraPadding__faAOq`,
	"openInWindowIcon": `DocumentExceptionExtractions-module__openInWindowIcon__uHSIc`,
	"onboardingContent": `DocumentExceptionExtractions-module__onboardingContent__hJeOG`,
	"onboardingImage": `DocumentExceptionExtractions-module__onboardingImage__TAUih`,
	"onboardingText": `DocumentExceptionExtractions-module__onboardingText__uRArH`
};
export default ___CSS_LOADER_EXPORT___;
