import reactStringReplace from 'react-string-replace';
import AppConstants from '../../utils/AppConstants';
import FormattingUtil from '../../utils/FormattingUtil';

/*
  Codemirror's widget doesn't allow multiline matching, so as a hack we replace actual line breaks with fake line breaks while rendering the widget. Also we revert the replacing while sending any request to the backend.
*/
export const addKogLineBreak = (value: string): string =>
  reactStringReplace(
    value,
    AppConstants.PATTERNS.MARKDOWN_TEXT,
    (match) => `"""${match.replace(/\n/gs, '/KOG-N')}"""`
  ).join('');

export const removeKogLineBreak = (value: string): string =>
  value.replace(/\/KOG-N/gs, '\n');

export const updateMarkdownValue = (
  value: string,
  markdownOldValue: string,
  markdownNewValue: string
) =>
  value
    .replace(`"""${markdownOldValue}"""`, `"""${markdownNewValue}"""`)
    .replace(/""""""/gm, '')
    .trim();

export const encodeMarkdownText = (value: string): string =>
  reactStringReplace(
    value,
    AppConstants.PATTERNS.MARKDOWN_TEXT,
    (match) => `""${FormattingUtil.encodeBrainValue(match)}""`
  ).join('');

// Because we encode the value within markdown strings, backend sends linebreaks with extra backslash.
export const decodeMarkdownText = (value: string): string =>
  value.replace(/\\n/g, '\n');
