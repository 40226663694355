import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  ListWorkersByProcedureQuery,
  ListWorkersByProcedureQueryVariables,
  Worker
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Queries from '@/graphql/Queries';
import { FetchResult } from '@apollo/client';

export interface GetProcedureRunsParams
  extends ListWorkersByProcedureQueryVariables {
  page?: number;
}

export const getProcedureRuns = createAsyncThunk<
  FetchResult<ListWorkersByProcedureQuery>,
  GetProcedureRunsParams,
  AsyncThunkConfig
>(
  'procedureRuns/fetchAll',
  async (
    {
      limit,
      nextToken,
      procedureId,
      stateFilter,
      fromCreationDate,
      toCreationDate
    },
    thunkAPI
  ) =>
    thunkAPI.extra.appSyncClient.query({
      query: Queries.ListWorkersByProcedure(),
      variables: {
        procedureId,
        limit,
        nextToken,
        stateFilter,
        fromCreationDate,
        toCreationDate
      },
      fetchPolicy: 'network-only'
    })
);

const ProcedureRunsAdapter = createEntityAdapter<Worker>({
  selectId: (worker) => worker.id!
});

interface IState {
  loading: boolean;
  error: any;
  nextToken: string | undefined;
  pageDataMap: Record<number, Worker[]>;
}

export const procedureRunsSlice = createSlice({
  name: 'procedureRuns',
  initialState: ProcedureRunsAdapter.getInitialState<IState>({
    loading: false,
    error: undefined,
    nextToken: undefined,
    pageDataMap: {}
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProcedureRuns.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProcedureRuns.fulfilled, (state, { payload, meta }) => {
      if (payload?.data?.listWorkersByProcedure) {
        const { items = [], nextToken } = payload.data.listWorkersByProcedure;

        const workers = items as Worker[];

        if (!meta.arg.nextToken) {
          ProcedureRunsAdapter.setAll(state, workers);
        } else {
          ProcedureRunsAdapter.addMany(state, workers);
        }

        if (meta.arg.page && workers.length > 0) {
          state.pageDataMap[meta.arg.page] = workers;
        }

        state.nextToken = nextToken || undefined;
      }
      state.loading = false;
    });
    builder.addCase(getProcedureRuns.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.loading = false;
    });
  }
});

export const procedureRunsSelector =
  ProcedureRunsAdapter.getSelectors<RootState>((state) => state.procedureRuns);

export const procedureRunsQuerySelector = (state: RootState) => ({
  loading: state.procedureRuns.loading,
  runs: procedureRunsSelector.selectAll(state),
  error: state.procedureRuns.error,
  nextToken: state.procedureRuns.nextToken,
  pageDataMap: state.procedureRuns.pageDataMap
});

export default procedureRunsSlice.reducer;
