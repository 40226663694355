/* eslint-disable no-underscore-dangle */
import {
  BulbTwoTone,
  InfoCircleTwoTone,
  QuestionCircleFilled,
  FileOutlined,
  BookOutlined,
  CaretRightOutlined
} from '@ant-design/icons';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Select,
  Space,
  Spin,
  Tooltip,
  message
} from 'antd';
import classNames from 'classnames';
import last from 'lodash/last';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { userSelector } from '@/stores/slices/user';
import { useSelector } from 'react-redux';
import templates from '@/pages/Playgrounds/templates';
import { getTemplateIdForRequest } from '@/pages/Playgrounds/templateUtils';
import { IUploadedResponse, useS3 } from '@/hooks/useS3';
import { currentUserDetailsVar } from '@/graphql/cache/user';
import {
  Department,
  GetFactQuery,
  GetFactQueryVariables,
  GetLearnedAnswerSuggestionsQuery,
  GetLearnedAnswerSuggestionsQueryVariables,
  GetRequestAnswerQuery,
  LearnedAnswer,
  NativeCodeErrorQuestion,
  Procedure,
  Request,
  RequestAnswer,
  RequestQuestion as RequestQuestionInterface,
  ReviewConceptQuestion,
  ValueNotKnownQuestion,
  Worker
} from '../../generated/API';
import Queries from '../../graphql/Queries';
import useRequestQuestionChoices from '../../hooks/useRequestQuestionChoices';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { showPopup } from '../../stores/slices/appPopup';
import AppUtil from '../../utils/AppUtil';
import FormattingUtil from '../../utils/FormattingUtil';
import {
  getQuestionInputTypeFromAnswer,
  getQuestionInputValueFromAnswer,
  prepareDocumentExceptionTitle
} from '../../utils/request';
import AutoSizeInput from '../AutoSizeInput';
import ErrorDescription from '../ErrorDescription';
import InlineFacts from '../facts/InlineFacts';
import MarkdownRenderer from '../MarkdownRenderer';
import S3FileUpload from '../Upload';
import {
  IQuestionConfig,
  IQuestionValues,
  QuestionAnswerMode,
  QuestionInputType
} from './interface';
import './Request.less';
import DocumentExceptionExtractions from '../exception/document-exception/DocumentExceptionExtractions';
import { annotationSelector } from '../../stores/slices/annotation';
import RequestIntegration from './RequestIntegration/RequestIntegration';
import AppConstants from '../../utils/AppConstants';
import { getDocumentExceptionFile } from '../../utils/exception';
import { GET_FACT_VALUE } from '../../graphql/queries/fact';
import LearningMethod from './LearningMethod/LearningMethod';
import { ISentence } from '../../pages/PlaygroundV3/components/Editor/editorInterface';
import { LIST_LEARNED_ANSWER_SUGGESTIONS } from '../../graphql/learning';
import Loader, { ILoaderType } from '../Loader';
import { MiniPlaygroundModeType } from '../../popups/MiniPlaygroundPopup';

/**
 * This is component used for batch answering as well, and in that case, providing the following props as null
 * - request
 * - worker
 *
 * Instead of making the above props as optional, we're making them required along with *null* type to maintain strictness in other applications/usages.
 */
interface IRequestQuestionProps {
  request: Request | null;
  question: RequestQuestionInterface;
  worker: Worker | null;
  department?: Department;
  procedure?: Procedure;
  sentence?: ISentence;
  activeDocumentToken?: string;
  config: IQuestionConfig;
  answerId?: string;
  questionValues: IQuestionValues | null;
  onValuesChange: (values: Partial<IQuestionValues>) => void;
}

function RequestQuestion(props: IRequestQuestionProps) {
  const {
    request,
    question,
    worker,
    department,
    procedure,
    sentence,
    activeDocumentToken,
    config,
    answerId,
    questionValues,
    onValuesChange
  } = props;

  const {
    answerMode,
    useSummaryQuestionText,
    allowDocumentExceptionEditingInPopup, // once answered, do we want to allow editing in a popup
    autoSelectDocumentException // if true, allow marking fields will be auto selected
  } = config;
  const { email } = useReactiveVar(currentUserDetailsVar);

  const templateId = getTemplateIdForRequest(email!);
  const currentTemplateSampleFile = templates
    .find((i) => `${i.id}` === templateId)
    ?.questions?.find(
      (i) => JSON.stringify(i?.stepPath) === JSON.stringify(request?.stepPath)
    );
  const isTemplateModifiedRequest = !!currentTemplateSampleFile?.sampleFile;

  const { prepareMetaFiles, uploadFiles } = useS3();

  const [sampleFileUploadLoading, setSampleFileUploadLoading] = useState(false);

  const { rolloutContextBasedLearning } = useFlags();

  const handleSelServeFileClick = async (template: {
    sampleFile: URL;
    sampleFileName: string;
    sampleFileType: string;
  }) => {
    setSampleFileUploadLoading(true);
    const blob = await fetch(template.sampleFile as any).then((data) =>
      data.blob()
    );
    const fileBlob = new File([blob], template.sampleFileName, {
      type: template.sampleFileType
    });
    const metaFiles = await prepareMetaFiles(
      [fileBlob],
      AppConstants.S3_FILE_SCOPE.WORKER,
      request!.worker!.id!
    );
    const uploadedFilePostFields = await uploadFiles(metaFiles);
    const event = new CustomEvent(AppConstants.SELF_SERVE.FILE_UPLOAD_EVENT, {
      detail: { question, uploadResult: uploadedFilePostFields }
    });
    document.dispatchEvent(event);
    setSampleFileUploadLoading(false);
  };

  const type = question.__typename as any;

  const questionType = {
    // TODO: Change this string to type from GraphQl, hardcode string can create problems in future
    value: type === 'ValueNotKnownQuestion',
    conceptReview: type === 'ReviewConceptQuestion',
    nativeCodeError: type === 'NativeCodeErrorQuestion',
    parseError: type === 'ParseErrorQuestion',
    ensureFailed: type === 'EnsureFailedQuestion',
    domainNotIntegrated: type === 'DomainNotIntegratedQuestion',
    missingIntegrationCredentials:
      type === 'MissingIntegrationCredentialQuestion'
  };

  const valueNotKnownQuestion = question as unknown as ValueNotKnownQuestion;
  const conceptReviewQuestion = question as unknown as ReviewConceptQuestion;

  const { text, summary, answerTypeHint } =
    question as unknown as RequestQuestionInterface;

  const lexicalPath = (valueNotKnownQuestion || conceptReviewQuestion)
    .lexicalPath;

  const processed = FormattingUtil.processQuestionV1(
    text || '',
    lexicalPath || null
  );

  const { data: answerData, loading: answerLoading } =
    useQuery<GetRequestAnswerQuery>(Queries.getRequestAnswer(), {
      variables: {
        id: answerId
      },
      skip: !answerId
    });
  const answer = answerData?.getRequestAnswer;

  const wasLearningMethodUsed = !!answer?.sourceLearning;
  const wasAnswerLearned =
    !!answer?.sourceLearning || answer?.derivedLearning || false;

  const disabled = !!answerId;

  const {
    data: learnedAnswerSuggestions,
    loading: learnedAnswerSuggestionsLoading
  } = useQuery<
    GetLearnedAnswerSuggestionsQuery,
    GetLearnedAnswerSuggestionsQueryVariables
  >(LIST_LEARNED_ANSWER_SUGGESTIONS, {
    variables: {
      input: {
        departmentId: department?.id!,
        procedureId: procedure?.id!,
        lexicalPath,
        stepPath: request?.stepPath
      }
    },
    skip: !!answerId || !procedure || !rolloutContextBasedLearning
  });

  const learningSuggestions = (learnedAnswerSuggestions
    ?.getLearnedAnswerSuggestions?.items || []) as LearnedAnswer[];

  const isPlural = valueNotKnownQuestion && valueNotKnownQuestion.isPlural;

  const documentExceptionFactId = request?.uiConcepts
    ? getDocumentExceptionFile(request.uiConcepts)
    : null;

  const { data: documentExceptionFactData } = useQuery<
    GetFactQuery,
    GetFactQueryVariables
  >(GET_FACT_VALUE, {
    variables: {
      knowledgeId: worker?.knowledgeId!,
      factId: documentExceptionFactId!,
      epoch: 0
    },
    skip: !worker?.knowledgeId || !documentExceptionFactId
  });

  const factValue = documentExceptionFactData?.getFact?.value;
  const documentExceptionS3File: string | null = factValue
    ? JSON.parse(factValue)
    : null;

  const questionChoices = useRequestQuestionChoices({
    question,
    knowledgeId: worker ? worker.knowledgeId! : null
  });

  const choices = questionType.value ? valueNotKnownQuestion.choices || [] : [];

  let defaultValue = getQuestionInputValueFromAnswer(answer as RequestAnswer);
  let defaultInputType = getQuestionInputTypeFromAnswer(
    answer as RequestAnswer
  );

  const allowMarkFields = !!documentExceptionS3File;

  const lastLexicalPath =
    (lexicalPath || []).length > 0
      ? AppUtil.safeParseJSON(last(lexicalPath), true)
      : '';

  const exceptionLabel = lexicalPath
    ? lastLexicalPath
    : `field${isPlural ? 's' : ''}`;

  if (!answer && allowMarkFields && autoSelectDocumentException) {
    defaultInputType = QuestionInputType.DOCUMENT_FIELDS;
  }
  if (questionType.conceptReview) {
    defaultInputType = QuestionInputType.APPROVE_REVIEW;
  }
  if (choices.length > 0) {
    defaultInputType = QuestionInputType.VALUE_CHOICE;
  }

  if (answer) {
    if (
      defaultInputType === QuestionInputType.VALUE_CHOICE &&
      defaultValue !== undefined
    ) {
      defaultValue = questionChoices.choices[Number(defaultValue)];
    }
  }

  const [inputType, setInputType] =
    useState<QuestionInputType>(defaultInputType);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setInputType(defaultInputType);
  }, [defaultInputType]);

  // technique is controlled manually instead of using form
  useEffect(() => {
    if (defaultInputType === QuestionInputType.TECHNIQUE && defaultValue) {
      onValuesChange({
        technique: defaultValue
      });
    }
  }, [defaultInputType, defaultValue]);

  useEffect(() => {
    onValuesChange({
      inputType
    });
  }, []);

  const { annotations } = useSelector(annotationSelector);

  const validAnnotations = useMemo(
    () => annotations.filter((annotation) => !!annotation.textContent),
    [annotations]
  );

  useEffect(() => {
    if (validAnnotations) {
      onValuesChange({
        inputType: QuestionInputType.DOCUMENT_FIELDS,
        documentFields: validAnnotations.map((annotation) => ({
          text: annotation.textContent || ''
        }))
      });
    }
  }, [validAnnotations]);

  const openMiniPlayground = (options: { reset: boolean }) => {
    let mode = MiniPlaygroundModeType.CONTEXTUAL_LEARNING;
    if (!procedure || !rolloutContextBasedLearning) {
      mode = MiniPlaygroundModeType.TECHNIQUE;
    }

    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.MINI_PLAYGROUND,
        popupParams: {
          mode,
          worker,
          procedure,
          sentence,
          request,
          activeDocumentToken,
          resetDocument: options.reset,
          onMethodUse: (editorText: string) => {
            setInputType(QuestionInputType.TECHNIQUE);
            onValuesChange({
              inputType: QuestionInputType.TECHNIQUE,
              technique: editorText
            });
          }
        }
      })
    );
  };

  const openDocumentExceptionEditor = () => {
    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.DOCUMENT_EXCEPTION_EDITOR,
        popupParams: {
          document: {
            id: documentExceptionFactId!,
            s3File: documentExceptionS3File!
          },
          knowledgeId: worker!.knowledgeId!,
          title: prepareDocumentExceptionTitle(worker!, procedure),
          fieldName: exceptionLabel,
          allowMultipleAnnotations: isPlural
        }
      })
    );
  };

  useEffect(() => {
    if (
      inputType === QuestionInputType.DOCUMENT_FIELDS &&
      allowDocumentExceptionEditingInPopup
    ) {
      openDocumentExceptionEditor();
    }
  }, [inputType, allowDocumentExceptionEditingInPopup]);

  const renderQuestionText = () => {
    if (answerMode !== QuestionAnswerMode.SINGLE) {
      return null;
    }

    let title = null;

    if (useSummaryQuestionText && summary) {
      title = (
        <Layout.Header className="question-header">{summary}</Layout.Header>
      );
    }

    title = (
      <Layout.Header className="question-header">
        <InlineFacts
          text={processed?.text!}
          knowledgeId={worker!.knowledgeId!}
        />
      </Layout.Header>
    );

    return (
      <div className="question-header-wrapper">
        <QuestionCircleFilled />
        {title}
      </div>
    );
  };

  const renderNativeCodeError = () => {
    const nativeCodeErrorQuestion =
      question as unknown as NativeCodeErrorQuestion;

    return (
      <ErrorDescription
        errorMessage={nativeCodeErrorQuestion.errorMessage || null}
        rawException={nativeCodeErrorQuestion.errorTraceback || null}
        className="error-description"
      />
    );
  };

  const renderInput = () => {
    if (inputType === QuestionInputType.VALUE) {
      return (
        <Form.Item
          name="value"
          valuePropName="value"
          initialValue={defaultValue}
        >
          <AutoSizeInput
            value=""
            onChange={() => null}
            placeholder={processed?.englishPath || 'Answer'}
            disabled={disabled}
            className={classNames('question-input')}
            size={{
              maxRows: 10
            }}
            autoFocus
          />
        </Form.Item>
      );
    }

    // TODO: Remove "source" condition once KOG-779 is resolved
    if (
      inputType === QuestionInputType.VALUE_UPLOAD &&
      answerMode === QuestionAnswerMode.SINGLE &&
      request
    ) {
      const normFile = (uploadedResponses: IUploadedResponse[]) =>
        uploadedResponses;

      return (
        <Form.Item
          name="upload"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="question-upload"
        >
          <S3FileUpload
            scope={AppConstants.S3_FILE_SCOPE.WORKER}
            scopeId={request.worker!.id}
            minimal={false}
            multiple
          />
        </Form.Item>
      );
    }

    if (inputType === QuestionInputType.VALUE_EMPTY) {
      return null;
    }

    if (inputType === QuestionInputType.VALUE_CHOICE) {
      return (
        <Form.Item
          name="choice"
          valuePropName="value"
          initialValue={defaultValue || undefined}
          className="question-choice"
        >
          <Select
            disabled={disabled || questionChoices.loading}
            size="middle"
            placeholder={processed?.englishPath || 'Select'}
            showSearch
            optionFilterProp="children"
            loading={questionChoices.loading}
            filterOption={(input, option) => {
              if (!option?.children?.[0]) {
                return false;
              }
              return option.children[0]
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            optionLabelProp="label"
            autoFocus
            onClick={(e) => {
              e.stopPropagation();
            }}
            // eslint-disable-next-line react/no-unstable-nested-components
            dropdownRender={(menu) => (
              <div className="question-choice-select-menu">{menu}</div>
            )}
          >
            {questionChoices.choices.map((text: any, choiceIndex: number) => (
              <Select.Option
                value={choiceIndex}
                // eslint-disable-next-line react/no-array-index-key
                key={`${choiceIndex}+${text}`}
                label={text}
              >
                {text.toString()}
                {questionChoices.choicesRawValuesMap[choiceIndex] && (
                  <>
                    {' '}
                    <Tooltip title="View details">
                      <InfoCircleTwoTone
                        className="question-choice-view-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(
                            showPopup({
                              popupId: AppConstants.POPUPS.VIEW_OBJECT,
                              popupParams: {
                                data: questionChoices.choicesRawValuesMap[
                                  choiceIndex
                                ],
                                title: text
                              }
                            })
                          );
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    // TODO: Phase 2
    if (inputType === QuestionInputType.CONCEPT) {
      return null;
    }

    if (inputType === QuestionInputType.TECHNIQUE) {
      return (
        <div>
          {wasLearningMethodUsed && (
            <div className="method-used-title">
              <BookOutlined />
              <span>Method used</span>
            </div>
          )}
          <div
            className={classNames('technique-input-wrapper', {
              'extra-padding': !answerId
            })}
          >
            <Form.Item>
              <Input.TextArea
                disabled={disabled}
                autoSize={{ minRows: 3, maxRows: 6 }}
                spellCheck={false}
                autoComplete="off"
                placeholder="Technique"
                data-cy="technique-input"
                autoFocus
                value={questionValues?.technique}
                onChange={(e) => {
                  onValuesChange({
                    technique: e.target.value
                  });
                }}
              />
            </Form.Item>
            {!answerId && (
              <span
                className="new-window-icon"
                onClick={() =>
                  openMiniPlayground({
                    reset: false
                  })
                }
              >
                <Tooltip title="Open Mini playground">
                  <BookOutlined />
                </Tooltip>
              </span>
            )}
          </div>
        </div>
      );
    }

    if (inputType === QuestionInputType.NO_VALUE) {
      return null;
    }

    if (inputType === QuestionInputType.SKIP) {
      return null;
    }

    if (inputType === QuestionInputType.SKIP_RUN_END) {
      return null;
    }

    if (inputType === QuestionInputType.RETRY) {
      return null;
    }

    if (inputType === QuestionInputType.RETRY_AFTER_INTERVAL) {
      return (
        <Space direction="vertical">
          <Space direction="horizontal">
            Retry After
            <Form.Item
              name="retryDelayDateTime"
              valuePropName="value"
              getValueFromEvent={(onchange) => onchange}
            >
              <DatePicker
                disabledDate={(date) => date.isBefore(dayjs().add(-1, 'hour'))}
                showTime={{
                  showSecond: false,
                  use12Hours: true,
                  minuteStep: 5
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
          </Space>

          <span>
            <Divider style={{ margin: '12px 0' }} />
            <Space direction="horizontal">
              Stop retrying after
              <Form.Item name="retryTimeoutMinutes" valuePropName="value">
                <Select
                  // [1,2,3,4,...,15]
                  options={Array.from({ length: 15 }, (_, i) => i + 1).map(
                    (i) => ({
                      label: i,
                      value: i
                    })
                  )}
                />
              </Form.Item>
              minutes
            </Space>
          </span>
        </Space>
      );
    }

    // TODO: Update inputType
    if (inputType === QuestionInputType.APPROVE_REVIEW) {
      return null;
    }

    if (inputType === QuestionInputType.DOCUMENT_FIELDS) {
      return (
        <div className="question-extractions">
          <DocumentExceptionExtractions
            fieldName={exceptionLabel}
            showDefaultExtraction
            showDelete
            disabled={disabled}
            onOpenInWindowClick={
              allowDocumentExceptionEditingInPopup
                ? openDocumentExceptionEditor
                : undefined
            }
            enableOnboarding={false}
          />
        </div>
      );
    }

    return null;
  };

  const renderAvailableLearnings = () => {
    if (learnedAnswerSuggestionsLoading) {
      return <Loader type={ILoaderType.SKELETON} />;
    }

    if (learningSuggestions.length === 0) {
      return null;
    }

    return (
      <div className="available-learnings">
        <Collapse
          bordered={false}
          ghost
          expandIconPosition="end"
          // eslint-disable-next-line react/no-unstable-nested-components
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel
            header={
              <div>
                <div className="-header">
                  <div className="-title">
                    <BookOutlined />
                    <span>
                      Available learnings: {learningSuggestions.length}
                    </span>
                  </div>
                </div>

                <div className="learning-suggestions-helptext">
                  Paste one in mini playground to check if it works
                </div>
              </div>
            }
            key="1"
          >
            <>
              {learningSuggestions!.map((learningSuggestion, index) => {
                const learntMethodText = AppUtil.safeParseJSON(
                  (learningSuggestion.action as any)?.technique,
                  true
                );

                return (
                  <LearningMethod
                    key={learningSuggestion.id}
                    header={`Learning ${index + 1}`}
                    body={learntMethodText}
                    onTryNowClick={() => {
                      navigator.clipboard
                        .writeText(learntMethodText)
                        .then(() => {
                          message.info(
                            'Copied to clipboard, paste in mini-playground',
                            5,
                            () => {}
                          );
                          openMiniPlayground({
                            reset: true
                          });
                        });
                    }}
                  />
                );
              })}
            </>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  };

  const { username } = useAppSelector(userSelector);
  const isSupportUser = AppUtil.isSupportUser(username);

  const showAnswerTypes = defaultInputType !== QuestionInputType.VALUE_CHOICE;

  const SHOW_ANSWER_TYPES: Record<QuestionInputType, boolean> = {
    [QuestionInputType.APPROVE_REVIEW]:
      answerMode === QuestionAnswerMode.BATCH ||
      (answerMode === QuestionAnswerMode.SINGLE && questionType.conceptReview),
    [QuestionInputType.VALUE]: questionType.value || isSupportUser,
    [QuestionInputType.VALUE_UPLOAD]:
      questionType.value && answerMode === QuestionAnswerMode.SINGLE,
    [QuestionInputType.VALUE_EMPTY]: questionType.value || isSupportUser,
    [QuestionInputType.CONCEPT]: false, // not supported yet
    [QuestionInputType.TECHNIQUE]: questionType.value || isSupportUser,
    [QuestionInputType.SKIP]: true,
    [QuestionInputType.SKIP_RUN_END]: false, // not supported yet
    [QuestionInputType.RETRY]:
      questionType.value || questionType.nativeCodeError,
    [QuestionInputType.RETRY_AFTER_INTERVAL]:
      questionType.value || questionType.nativeCodeError,
    [QuestionInputType.NO_VALUE]: false,
    [QuestionInputType.VALUE_CHOICE]: choices.length > 0,
    [QuestionInputType.NONE]: false,
    [QuestionInputType.DOCUMENT_FIELDS]: allowMarkFields
  };

  if (
    questionType.missingIntegrationCredentials ||
    questionType.domainNotIntegrated
  ) {
    return (
      <RequestIntegration
        question={question}
        missingCredential={questionType.missingIntegrationCredentials}
      />
    );
  }

  return (
    <Spin spinning={answerLoading}>
      <Form
        autoComplete="off"
        onValuesChange={(_, allValues) =>
          onValuesChange({
            ...allValues,
            inputType:
              defaultInputType === QuestionInputType.VALUE_CHOICE
                ? QuestionInputType.VALUE_CHOICE
                : inputType
          })
        }
        initialValues={{
          remember: wasAnswerLearned
        }}
        className="question-form"
        key={`${defaultInputType}-${defaultValue}-${wasAnswerLearned}-${isSupportUser}`}
      >
        <Layout className="question">
          {valueNotKnownQuestion && (
            <div className="question-description">
              <div className="markdown">
                <MarkdownRenderer>
                  {valueNotKnownQuestion.helpText}
                </MarkdownRenderer>
              </div>
            </div>
          )}

          {renderQuestionText()}

          {questionType.nativeCodeError && isSupportUser
            ? renderNativeCodeError()
            : null}

          {answerTypeHint && (
            <Collapse ghost>
              <Collapse.Panel
                header={
                  <Space>
                    Click to view answer hint
                    <BulbTwoTone />
                  </Space>
                }
                key="1"
                className="answer-hint-panel"
              >
                <div className="markdown answer-hint-markdown">
                  <MarkdownRenderer>{answerTypeHint}</MarkdownRenderer>
                </div>
              </Collapse.Panel>
            </Collapse>
          )}

          {!isTemplateModifiedRequest && (
            <span>
              {(isSupportUser || !questionType.parseError) && (
                <Layout>
                  {showAnswerTypes && (
                    <Form.Item name="answer-type-dropdown">
                      <Select
                        disabled={disabled}
                        size="middle"
                        placeholder="Select a method"
                        bordered
                        onSelect={(value: QuestionInputType) => {
                          setInputType(value);
                          onValuesChange({
                            inputType: value
                          });
                          if (value === QuestionInputType.TECHNIQUE) {
                            openMiniPlayground({
                              reset: !questionValues?.technique
                            });
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        value={inputType || undefined}
                      >
                        {SHOW_ANSWER_TYPES[QuestionInputType.APPROVE_REVIEW] ? (
                          <Select.Option
                            value={QuestionInputType.APPROVE_REVIEW}
                          >
                            Approve
                          </Select.Option>
                        ) : null}
                        {SHOW_ANSWER_TYPES[QuestionInputType.VALUE] && (
                          <Select.Option value={QuestionInputType.VALUE}>
                            Write in answer
                          </Select.Option>
                        )}
                        {/* TODO: Remove "source" condition once KOG-779 is resolved */}
                        {SHOW_ANSWER_TYPES[QuestionInputType.VALUE_UPLOAD] && (
                          <Select.Option value={QuestionInputType.VALUE_UPLOAD}>
                            Upload files
                          </Select.Option>
                        )}
                        {SHOW_ANSWER_TYPES[
                          QuestionInputType.DOCUMENT_FIELDS
                        ] && (
                          <Select.Option
                            value={QuestionInputType.DOCUMENT_FIELDS}
                          >
                            Select from document
                          </Select.Option>
                        )}
                        {SHOW_ANSWER_TYPES[QuestionInputType.VALUE_EMPTY] && (
                          <Select.Option value={QuestionInputType.VALUE_EMPTY}>
                            No Value
                          </Select.Option>
                        )}
                        {SHOW_ANSWER_TYPES[QuestionInputType.SKIP] && (
                          <Select.Option value={QuestionInputType.SKIP}>
                            Skip this step
                          </Select.Option>
                        )}
                        {/* TODO: Phase 2 */}
                        {/* {questionType.value && (
                          <Select.Option value={QuestionInputType.CONCEPT}>
                            Concept
                          </Select.Option>
                        )} */}
                        {SHOW_ANSWER_TYPES[QuestionInputType.TECHNIQUE] && (
                          <Select.Option value={QuestionInputType.TECHNIQUE}>
                            Compute an answer
                          </Select.Option>
                        )}
                        {/* <Select.Option value={QuestionInputType.SKIP}>
                          Skip
                        </Select.Option>
                        <Select.Option value={QuestionInputType.SKIP_RUN_END}>
                          Skip to end of run
                        </Select.Option> */}

                        {SHOW_ANSWER_TYPES[QuestionInputType.RETRY] && (
                          <Select.Option value={QuestionInputType.RETRY}>
                            Retry
                          </Select.Option>
                        )}
                        {SHOW_ANSWER_TYPES[
                          QuestionInputType.RETRY_AFTER_INTERVAL
                        ] && (
                          <Select.Option
                            value={QuestionInputType.RETRY_AFTER_INTERVAL}
                          >
                            Retry after an interval
                          </Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  )}

                  <Layout.Content
                    style={{
                      backgroundColor:
                        inputType === QuestionInputType.RETRY_AFTER_INTERVAL
                          ? '#fafaf9'
                          : '#fff',
                      padding:
                        inputType === QuestionInputType.RETRY_AFTER_INTERVAL
                          ? 8
                          : 0,
                      borderRadius: 5
                    }}
                    className="question-inputs"
                  >
                    {renderInput()}
                  </Layout.Content>
                </Layout>
              )}
            </span>
          )}

          {!isTemplateModifiedRequest && (
            <Layout.Footer>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox disabled={disabled} data-cy="remember-checkbox">
                  {procedure
                    ? 'Always use this answer for this process?'
                    : 'Always use this answer for this department?'}
                </Checkbox>
              </Form.Item>
            </Layout.Footer>
          )}

          {renderAvailableLearnings()}

          {isTemplateModifiedRequest && (
            <div className="self-serve-hint-container">
              <FileOutlined className="file-icon" />
              <div className="self-serve-text-container">
                <p className="self-serve-text">
                  We have pre-loaded a sample file for you
                </p>
                <Button
                  className="self-serve-file-upload-btn"
                  loading={sampleFileUploadLoading}
                  disabled={sampleFileUploadLoading}
                  onClick={() =>
                    handleSelServeFileClick(currentTemplateSampleFile as any)
                  }
                >
                  Submit sample file
                </Button>
              </div>
            </div>
          )}
        </Layout>
      </Form>
    </Spin>
  );
}

export default RequestQuestion;
