import React from 'react';
import {
  Request,
  RequestQuestion,
  ReviewConceptQuestion,
  ValueNotKnownQuestion
} from '../../../generated/API';
import styles from './RequestInfo.module.less';
import FormattingUtil from '../../../utils/FormattingUtil';
import InlineFacts from '../../facts/InlineFacts';

interface IRequestInfo {
  request: Request;
}

function RequestInfo(props: IRequestInfo) {
  const { request } = props;

  const { questions = [] } = request;

  const renderQuestionInfo = (question: RequestQuestion) => {
    const { text } = question;

    const lexicalPath = (
      question as unknown as ValueNotKnownQuestion | ReviewConceptQuestion
    ).lexicalPath;

    const processed = FormattingUtil.processQuestionV1(
      text || '',
      lexicalPath || null
    );

    return (
      <div className={styles.requestInfo}>
        <div className={styles.title}>
          <InlineFacts
            text={processed?.text!}
            knowledgeId={request.worker!.knowledgeId!}
          />
        </div>

        {/* <div className={styles.summary}>
          <div className={styles.summaryTitle}>
            Summary
            <RightOutlined className={styles.summaryIcon} />
          </div>

          <div className={styles.summaryInfo}>
            This is a summary of the exception that the user can read. it is a
            brief description. This is a summary of the exception that the user
            can read. it is a brief description.
          </div>
        </div> */}
      </div>
    );
  };

  if (questions!.length === 0) {
    return null;
  }

  return (
    <>
      {questions!.map((question) => (
        <React.Fragment key={question.id}>
          {renderQuestionInfo(question)}
        </React.Fragment>
      ))}
    </>
  );
}

export default RequestInfo;
