import React, { useEffect, useState } from 'react';

import { Tooltip } from 'antd';
import { useSlateStatic, ReactEditor } from 'slate-react';
import AppConstants from '@/utils/AppConstants';
// import { useAppDispatch } from '@/stores/hooks';
// import {
//   decodeMarkdownText,
//   encodeMarkdownText
// } from '@/components/editor/helper';
import S3FileUpload from '@/components/Upload';
import { useS3 } from '@/hooks/useS3';
import AppUtil from '@/utils/AppUtil';
import { useApolloClient } from '@apollo/client';
import { GET_LOCAL_DOCUMENT_PREVIEW } from '@/graphql/queries/playground';
import { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import { TabKeys } from '../OutputPanel/OutputPanel';
import { EditorBlock, ISentence } from './editorInterface';
import { WIDGET_TYPES } from '../EditorWidgets/decorators';
import { useKogEditorContext } from './EditorProvider';

interface RenderLeafProps {
  attributes: any;
  children: any;
  leaf: ISentence & {
    widgetKey: string;
    widgetData: {
      value: string;
      type: WIDGET_TYPES;
    };
  };
  blocks: EditorBlock[];
  activeBlock: ISentence | null;
  setActiveBlock: (block: ISentence | null, answerType: TabKeys) => void;
  handleWidgetEdit?: (data: Record<string, any>) => void;
  handleWidgetDelete?: (widgetKey: string, uiLineId: string) => void;
  workerId: string;
  autoPilotChecked?: boolean;
  // onSubprocedureClick: (
  //   block: ISentence,
  //   subprocedureToken: SubDocument
  // ) => void;
  replacerObj?: Record<string, any>;
  onWidgetFileUpload?: (filesList: Record<'s3Url', string>[]) => void;
  expandDocumentPreview?: (open: boolean) => void;
}

export default function RenderLeaf(props: RenderLeafProps) {
  const { attributes, leaf, workerId, onWidgetFileUpload } = props;
  const { children } = props;
  const { widgets } = useKogEditorContext();
  const editor = useSlateStatic();

  // const dispatch = useAppDispatch();
  const client = useApolloClient();
  const s3 = useS3();

  const [s3Parts, setS3Parts] = useState<any>();
  const [s3Object, setS3Object] = useState<any>();

  const [showEditButton, setShowEditButton] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const [isDeleteActive, setDeleteActive] = useState(false);

  const isWidgetOpen =
    widgets.data?.lineId === leaf.uiLineId &&
    widgets.data?.widgetKey === leaf.widgetKey;

  const getS3Obj = async (s3Object: {
    bucket?: string;
    key: any;
    filename?: string;
  }) => {
    const updatedPresignedUrl = await s3.getS3PresignedUrl(s3Object.key);
    if (updatedPresignedUrl) {
      const urlObject = await s3.getSignedUrlObject(updatedPresignedUrl.url);
      if (urlObject) {
        setS3Object(urlObject);
      }
    }
  };

  useEffect(() => {
    if (leaf.widgetData?.type === WIDGET_TYPES.FILE && leaf.widgetData?.value) {
      const data = AppUtil.getS3URIParts(leaf.widgetData.value)!;
      setS3Parts(data);
      getS3Obj(data);
    }
  }, [leaf.widgetData?.type, leaf.widgetData?.value]);

  const expandPreview = () => {
    client.writeQuery({
      query: GET_LOCAL_DOCUMENT_PREVIEW,
      data: {
        getLocalDocumentPreview: {
          data: {
            type: FormattedAnswerTypeV2.S3,
            title: s3Parts.filename,
            s3Object: {
              bucket: s3Parts.bucket,
              key: s3Parts.key,
              downloadUrl: s3Object?.config?.url
            }
          }
        }
      }
    });
    props.expandDocumentPreview?.(true);
  };

  if (leaf.decoration === 'widget') {
    const text = leaf.widgetData.value;
    const isEditable =
      leaf.widgetData.type === WIDGET_TYPES.MARKDOWN ||
      leaf.widgetData.type === WIDGET_TYPES.URL;
    const title = text.length > 200 ? `${text.slice(0, 200)}...` : text;

    const getWidgetComp = () => {
      const style = {
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '12px',
        fontWeight: 500
      };
      if (
        leaf.widgetData.type === WIDGET_TYPES.FILE &&
        leaf.widgetData.value === ''
      ) {
        return (
          <S3FileUpload
            wrapper
            scope={AppConstants.S3_FILE_SCOPE.WORKER}
            scopeId={workerId}
            multiple
            onChange={(response) => {
              onWidgetFileUpload?.(response);
            }}
          >
            <span {...attributes} style={style}>
              {children}
            </span>
          </S3FileUpload>
        );
      }

      return (
        <span
          {...attributes}
          style={{ ...style }}
          onMouseEnter={() => {
            if (
              [WIDGET_TYPES.URL, WIDGET_TYPES.MARKDOWN].includes(
                leaf.widgetData?.type
              )
            ) {
              setShowEditButton(true);
              setShowDeleteButton(true);
            } else if (leaf.widgetData?.type === WIDGET_TYPES.JSON) {
              setShowDeleteButton(true);
            }
          }}
          onMouseLeave={() => {
            setShowEditButton(false);
            setShowDeleteButton(false);
            setDeleteActive(false);
          }}
        >
          <Tooltip title={title}>
            <span>{children}</span>
          </Tooltip>
          {showEditButton ? (
            <Tooltip title="edit">
              <span
                contentEditable={false}
                style={{ userSelect: 'none', marginLeft: 10 }}
                onClick={(e) => {
                  ReactEditor.blur(editor as any);
                  if (isWidgetOpen) {
                    e.stopPropagation();
                  }
                  const timeout = setTimeout(() => {
                    const event = new CustomEvent('EditWidgetEvent', {
                      detail: leaf.widgetKey
                    });
                    document.dispatchEvent(event);
                    clearTimeout(timeout);
                  }, 1);
                }}
              >
                <EditOutlined />
              </span>
            </Tooltip>
          ) : null}
          {showDeleteButton ? (
            <Tooltip title="delete">
              <span
                contentEditable={false}
                style={{ userSelect: 'none', marginLeft: 10 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!isDeleteActive) {
                    setDeleteActive(true);
                  } else {
                    props.handleWidgetDelete?.(leaf.widgetKey, leaf.uiLineId);
                  }
                }}
              >
                <DeleteOutlined
                  style={{ color: isDeleteActive ? 'red' : 'rgba(0,0,0,0.85)' }}
                />
              </span>
            </Tooltip>
          ) : null}

          {isWidgetOpen && (
            <Tooltip title="close">
              <span
                onClick={() => {
                  ReactEditor.blur(editor as any);
                }}
                contentEditable={false}
                style={{ userSelect: 'none', marginLeft: 10 }}
              >
                <CloseCircleOutlined />
              </span>
            </Tooltip>
          )}
        </span>
      );
    };

    return (
      <span
        style={{
          padding: '4px 6px',
          margin: 0,
          lineHeight: '16px',
          cursor: 'pointer',
          maxHeight: '22px',
          borderRadius: '4px',
          background: '#F0F5FF'
        }}
        onClick={(e) => {
          e.stopPropagation();
          // ReactEditor.blur(editor as any);
          if (leaf.widgetData.type === WIDGET_TYPES.FILE && s3Object) {
            expandPreview();
          }
          if (isEditable) {
            if (isWidgetOpen) {
              widgets.setWidgetData(null);
            } else {
              widgets.setWidgetData({
                lineId: leaf.uiLineId,
                widgetType: leaf.widgetData.type,
                widgetKey: leaf.widgetKey,
                widgetValue: leaf.widgetData.value
              });
            }
          }
        }}
      >
        {getWidgetComp()}
      </span>
    );
  }

  return <span {...attributes}>{children}</span>;
}
