import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useLazyQuery } from '@apollo/client';
import PDFViewer, { PDFViewType } from '@components/PDFViewer/PDFViewer';

import styles from './DocumentExceptionCanvas.module.less';
import AppUtil from '../../../utils/AppUtil';
import { useS3 } from '../../../hooks/useS3';
import {
  GetDocumentBlocksQuery,
  GetDocumentBlocksQueryVariables
  // S3PresignedUrl
} from '../../../generated/API';
import Loader, { ILoaderType } from '../../Loader';
import { IAnnotation } from '../../document-annotations/AnnotationsCanvas';
import { GET_DOCUMENT_BLOCKS } from '../../../graphql/exception';
import { incrementDocExceptionUsageCount } from '../../../utils/doc-exception';

interface IDocumentExceptionCanvasProps {
  document: {
    id: string;
    s3File: string;
  };
  knowledgeId: string;
  allowMultipleAnnotations: boolean;
}

function DocumentExceptionCanvas(props: IDocumentExceptionCanvasProps) {
  const { document, knowledgeId, allowMultipleAnnotations } = props;

  // const [presignedUrl, setPresignedUrl] = useState<S3PresignedUrl | null>(null);
  const [s3Data, setS3Data] = useState<AxiosResponse<any> | null>(null);
  const [parentCanvasRect, setParentCanvasRect] = useState<DOMRect | null>(
    null
  );
  const [pageRectMap, setPageRectMap] = useState<Record<number, DOMRect>>({});

  const [getDocumentBlocks] = useLazyQuery<
    GetDocumentBlocksQuery,
    GetDocumentBlocksQueryVariables
  >(GET_DOCUMENT_BLOCKS);

  const s3 = useS3();
  const canvasParentRef = useRef<HTMLDivElement | null>(null);

  const s3Object = AppUtil.getS3URIParts(document.s3File)!;

  const padding = 6;
  const pageWidth = parentCanvasRect ? parentCanvasRect.width - padding : null;

  const getObject = async () => {
    const updatedPresignedUrl = await s3.getS3PresignedUrl(s3Object.key);
    if (updatedPresignedUrl) {
      // setPresignedUrl(updatedPresignedUrl);
      const object = await s3.getSignedUrlObject(updatedPresignedUrl.url);
      setS3Data(object);
    }
  };

  const getParentCanvasRect = () => {
    if (canvasParentRef.current) {
      setParentCanvasRect(canvasParentRef.current.getBoundingClientRect());
    }
  };

  const fetchAnnotationRawText = async (annotation: IAnnotation) => {
    const pageRect = pageRectMap[annotation.pageNumber];

    const pageWidth = pageRect.width;
    const pageHeight = pageRect.height;

    const x = annotation.x;
    const y = annotation.y;

    const boundingBoxPercentage = {
      left: x / pageWidth,
      top: y / pageHeight,
      width: annotation.width / pageWidth,
      height: annotation.height / pageHeight
    };

    const blocks = await getDocumentBlocks({
      variables: {
        knowledgeId,
        documentFactId: document.id,
        boundingBoxes: {
          boundingBoxWithPage: [
            {
              pageNum: annotation.pageNumber - 1,
              boundingBox: boundingBoxPercentage
            }
          ]
        }
      }
    });

    const rawText = blocks?.data?.getDocumentBlocks?.[0]?.rawText;
    return rawText || '';
  };

  const onNewAnnotation = (_annotation: IAnnotation) => {
    incrementDocExceptionUsageCount();
  };

  useEffect(() => {
    getObject();
    getParentCanvasRect();
  }, [s3Object?.key]);

  return (
    <div className={styles.documentCanvas} ref={canvasParentRef}>
      {s3Data ? (
        <PDFViewer
          pdfFileBlob={s3Data?.data!}
          pageWidth={pageWidth!}
          allowViewTypeChange={false}
          defaultViewType={PDFViewType.ONE}
          pageScale={1}
          allowMultipleAnnotations={allowMultipleAnnotations}
          getAnnotationText={fetchAnnotationRawText}
          onNewAnnotation={onNewAnnotation}
          onPageRender={(_, pageRectMap) => {
            setPageRectMap(pageRectMap);
          }}
        />
      ) : (
        <Loader
          type={ILoaderType.SKELETON}
          skeletonConfig={{
            rows: 10
          }}
        />
      )}
    </div>
  );
}

export default DocumentExceptionCanvas;
