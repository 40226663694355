import {
  GetRequestQuery,
  GetRequestQueryVariables,
  Request as RequestInterface
} from '@/generated/API';
import Queries from '@/graphql/Queries';
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { last } from 'lodash';
import { ISentence } from '../Editor/editorInterface';
import { isRequestHandled } from '../../../../utils/request';
import RequestInfo from '../../../../components/request/RequestInfo/RequestInfo';

interface EditorLineRequestInfoProps {
  sentence: ISentence;
}

function EditorLineRequestInfo(props: EditorLineRequestInfoProps) {
  const { sentence } = props;

  const [requests, setRequests] = useState<RequestInterface[]>([]);

  const [getRequest] = useLazyQuery<GetRequestQuery, GetRequestQueryVariables>(
    Queries.Request(),
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    const token = sentence.token!;

    let requestIds = [];
    if (sentence?.editorRequests) {
      requestIds = sentence.editorRequests[token] || [];
    } else if (sentence.requests?.length > 0) {
      requestIds = sentence.requests;
    }

    const lastRequest: string | null = last(requestIds) || null;

    if (lastRequest) {
      getRequest({
        variables: {
          id: lastRequest
        }
      }).then((resp) => {
        if (resp.data?.getRequest) {
          setRequests([resp.data?.getRequest as RequestInterface]);
        }
      });
    } else {
      setRequests([]);
    }
  }, [sentence.requests]);

  const pendingRequests = requests.filter(
    (request) => !isRequestHandled(request)
  );
  const hasExceptions = pendingRequests.length > 0;

  if (!hasExceptions) {
    return null;
  }

  const renderPendingRequests = () => {
    return pendingRequests.map((request) => (
      <RequestInfo key={request.id} request={request} />
    ));
  };

  return (
    <div
      contentEditable={false}
      style={{ userSelect: 'none', marginTop: 10 }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {renderPendingRequests()}
    </div>
  );
}

export default EditorLineRequestInfo;
