import {
  GetProcedureQueryVariables,
  Procedure,
  UpdateProcedureMutation
} from '@/generated/API';
import Queries from '@/graphql/Queries';
import { useAppSelector, useAppDispatch } from '@/stores/hooks';
import { showPopup } from '@/stores/slices/appPopup';
import { departmentQuerySelector } from '@/stores/slices/department';
import AppConstants from '@/utils/AppConstants';
import routes from '@/utils/routes';
import { useLazyQuery, useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';
import { message, Tooltip, Button, Dropdown, MenuProps } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppUtil from '@/utils/AppUtil';
import { getProcedure } from '@/stores/slices/procedure';
import PlaygroundScriptIcon from '../icons/PlaygroundScriptIcon';
import { UPDATE_PROCEDURE } from '../../graphql/procedure';

interface INewProcedureFromRunProps {
  workerId: string;
  procedure?: Procedure | null;
  editorContent?: string;
  isRunPage?: boolean;
}

function NewProcedureFromRun(props: INewProcedureFromRunProps) {
  const { workerId, procedure, editorContent, isRunPage } = props;

  const { department } = useAppSelector(departmentQuerySelector);
  const isNewPlayground = AppUtil.isDocumentModelSupported(department);

  const [exportCodeForContext, { loading }] = useLazyQuery(
    Queries.ExportCodeForContext(),
    {
      variables: {
        workerId,
        contextId: procedure ? '1' : '0'
      }
    }
  );

  const [updateProcedure] =
    useMutation<UpdateProcedureMutation>(UPDATE_PROCEDURE);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onClickCreateProcedure = () => {
    exportCodeForContext().then(({ data, error }) => {
      if (error) {
        message.error(error.message);
      } else if (data.exportCodeForContext?.length > 0) {
        dispatch(
          showPopup({
            popupId: AppConstants.POPUPS.NEW_FLOW,
            popupParams: {
              onSuccess: (result: any) => {
                const p = result.createProcedure;
                message.success('New process created');
                navigate(
                  routes.procedure.getProcedurePageUrl(p.departmentId, p.id)
                );
              },
              department,
              procedureBody: data.exportCodeForContext.join('\n'),
              suffix: ''
            }
          })
        );
      } else {
        message.error('No commands found!');
      }
    });
  };

  const onPlaygroundProcessCreate = () => {
    if (editorContent) {
      dispatch(
        showPopup({
          popupId: AppConstants.POPUPS.NEW_FLOW,
          popupParams: {
            onSuccess: (result: any) => {
              const p = result.createProcedure;
              message.success('New process created');
              navigate(
                routes.procedure.getProcedurePageUrl(p.departmentId, p.id)
              );
            },
            department,
            procedureBody: editorContent,
            suffix: ''
          }
        })
      );
    }
  };

  const updateCurrentProcess = () => {
    if (editorContent) {
      updateProcedure({
        variables: {
          input: {
            id: procedure?.id,
            departmentId: department.id,
            text: editorContent,
            name: procedure?.name,
            language: procedure?.language
          }
        },
        onCompleted: () => {
          message.success('Process updated');
          const variables: GetProcedureQueryVariables = {
            procedureId: procedure?.id!
          };

          if (department.id && department.id !== 'null') {
            variables.departmentId = department.id;
          }

          return dispatch(getProcedure(variables)).unwrap();
        },
        onError: (e) => {
          console.log('update procedure error', e);
          message.error('Failed to update process. Please try');
        }
      });
    }
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'save-edits-to-current-process',
      label: (
        <span data-cy="save-edits-to-current-process">
          Save edits to current process
        </span>
      ),
      onClick: updateCurrentProcess
    },
    {
      key: 'create-new-process-from-run',
      label: (
        <span data-cy="create-new-process-from-run">
          Create new process from run
        </span>
      ),
      onClick: onPlaygroundProcessCreate
    }
  ];

  if (isNewPlayground && isRunPage) {
    return (
      <Tooltip title="Save Process">
        <Dropdown menu={{ items: menuItems }} placement="bottomRight" arrow>
          <Button
            type="text"
            disabled={isEmpty(editorContent)}
            data-cy="save-process-button"
            className="display-flex align-center justify-center"
            icon={<PlaygroundScriptIcon />}
          />
        </Dropdown>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Create Process from Run">
      {isNewPlayground ? (
        <Button
          type="text"
          disabled={isEmpty(editorContent)}
          data-cy="new-procedure-button"
          className="display-flex align-center justify-center"
          onClick={onPlaygroundProcessCreate}
          icon={<PlaygroundScriptIcon />}
        />
      ) : (
        <Button
          size="small"
          type="link"
          icon={<PlaygroundScriptIcon />}
          loading={loading}
          disabled={loading}
          onClick={onClickCreateProcedure}
          data-cy="new-procedure-button"
          className="new-procedure-button"
        />
      )}
    </Tooltip>
  );
}

export default NewProcedureFromRun;
