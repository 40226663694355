import { gql } from '@apollo/client';

export const LIST_INTEGRATION_BY_DEPARTMENT = gql`
  query ListIntegrationsByDepartment(
    $departmentId: ID!
    $version: String
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationsByDepartment(
      departmentId: $departmentId
      version: $version
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        name
        author
        description
        integratedAt
        version
        credentialMetadata {
          isCredentialProvided
          redirectionFlowRequired
          isRedirectionFlowDone
        }
      }
      nextToken
    }
  }
`;

export const GET_INTEGRATION_CREDENTIAL_FORM = gql`
  query GetIntegrationCredentialForm($integrationId: ID!, $departmentId: ID!) {
    getIntegrationCredentialForm(
      integrationId: $integrationId
      departmentId: $departmentId
    ) {
      integrationId
      fields {
        label
        key
        type
        description
        example
      }
    }
  }
`;

export const GET_INTEGRATION_CREDENTIALS = gql`
  query RevealIntegrationCredential($integrationId: ID!, $departmentId: ID!) {
    revealIntegrationCredential(
      integrationId: $integrationId
      departmentId: $departmentId
    ) {
      integrationId
      departmentId
      data {
        label
        key
        value
      }
    }
  }
`;
