import { Fact } from '@/generated/API';
import useLineFact, {
  FactAnswer,
  FACT_STATUS,
  prepareFactKey
} from '@/hooks/useLineFact';
import { useAppDispatch } from '@/stores/hooks';
import { showPopup } from '@/stores/slices/appPopup';
import AppConstants from '@/utils/AppConstants';
import { ApartmentOutlined, LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { DisplayMode } from '../ObjectViewer';

interface IProps {
  fact: Fact;
  knowledgeId: string;
  epoch: number;
}

function FactRelationsViewer(props: IProps) {
  const { fact, knowledgeId, epoch } = props;

  const dispatch = useAppDispatch();

  const relations = fact.relations || [];

  const cptList: FactAnswer[] = relations
    .filter((relation) => relation?.factId)
    .map((relation) => ({
      id: relation?.factId!,
      status: FACT_STATUS.INIT,
      __typename: 'Fact'
    }));

  const { facts, factIds } = useLineFact({
    cptList,
    knowledgeId,
    epoch
  });

  const factValues = factIds.map((id) => facts[prepareFactKey(id, epoch)]);
  const loadingIndex = factValues.findIndex(
    (fact) => fact?.status === FACT_STATUS.LOADING
  );

  return loadingIndex > -1 ? (
    <LoadingOutlined />
  ) : (
    <Tooltip title="View relations">
      <ApartmentOutlined
        style={{
          marginLeft: 6
        }}
        onClick={() => {
          dispatch(
            showPopup({
              popupId: AppConstants.POPUPS.VIEW_OBJECT,
              popupParams: {
                data: factValues.map((fv) => fv.value),
                displayMode: DisplayMode.JSON
              }
            })
          );
        }}
      />
    </Tooltip>
  );
}

export default FactRelationsViewer;
