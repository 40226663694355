import {
  GetDownloadFromS3UrlQuery,
  S3PresignedPost,
  S3PresignedUrl
} from '@/generated/API';
import AppConstants from '@/utils/AppConstants';
import AppUtil from '@/utils/AppUtil';
import { useApolloClient } from '@apollo/client';
import { message } from 'antd';
import React from 'react';
import axios from 'axios';
import { getWindowCache, setWindowCache } from '@/utils/windowCache';
import {
  delayExponentialBackoff,
  retryPromiseWithDelay
} from '@/utils/promise';

const hasUrlExpired = (url: any) =>
  url &&
  typeof url === 'string' &&
  Number(url.split('&').slice(-1)[0].split('=')[1]) <
    Math.floor(Date.now() / 1000);

interface IMetaFile {
  file: File;
  postFields: S3PresignedPost;
}

export interface IUploadedResponse {
  s3Url: string;
}

export function useS3() {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = React.useState(false);

  const getS3PresignedUrl = async (
    objectKey: string
  ): Promise<S3PresignedUrl | null | undefined> => {
    // We show a document inline and then once you click on it, we render a detailed view of the same (in a popup). Ideally we don't want to re-fetch the same document for the second time.
    const cacheData = getWindowCache('signedUrlCache')(objectKey);
    if (cacheData && !hasUrlExpired(cacheData.url)) {
      return cacheData;
    }

    try {
      setIsLoading(true);
      const result = await client.query<GetDownloadFromS3UrlQuery>({
        query: AppConstants.APIS.S3.DOWNLOAD_LINK(),
        variables: {
          objectKey
        }
      });
      const r = result?.data?.getDownloadFromS3Url;
      setWindowCache('signedUrlCache')(objectKey, r);
      return r;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
    return null;
  };

  const getSignedUrlObject = async (signedUrl: string) => {
    const cacheData = await getWindowCache('signedUrlObjectCache')(signedUrl);
    if (cacheData && !hasUrlExpired(cacheData?.request.responseURL)) {
      return cacheData;
    }

    try {
      const result = axios.get(signedUrl, {
        responseType: 'blob'
      });
      setWindowCache('signedUrlObjectCache')(signedUrl, result);
      return result;
    } catch (e) {
      console.error(e);
    }
    return null;
  };

  const download = async (url: string) => {
    const { key } = AppUtil.getS3URIParts(url)!;
    const presignedUrl = await getS3PresignedUrl(key);
    if (!presignedUrl) {
      message.error('Failed to get download link');
      return;
    }
    const link = document.createElement('a');
    link.href = presignedUrl.url;
    const { filename } = AppUtil.getS3URIParts(url)!;
    link.setAttribute('download', filename);
    link.click();
  };

  const openInNewTab = async (url: string) => {
    const { key } = AppUtil.getS3URIParts(url)!;
    const presignedUrl = await getS3PresignedUrl(key);
    if (presignedUrl) {
      window.open(presignedUrl.url, '_blank');
    }
  };

  const prepareMetaFiles = async (
    fileList: File[],
    scope: string,
    scopeId: string
  ): Promise<IMetaFile[]> => {
    const fileSignedPostFieldsPromises = fileList.map((file) =>
      client.query({
        query: AppConstants.APIS.S3.UPLOAD_LINK(),
        variables: {
          scope,
          scopeId,
          filename: file.name,
          fields: JSON.stringify({ 'Content-Type': file.type })
        }
      })
    );

    const signedPostFieldsResult = await Promise.allSettled(
      fileSignedPostFieldsPromises
    );

    const metaFiles = signedPostFieldsResult
      .map((r) => {
        if (r.status === 'fulfilled' && !!r.value.data) {
          const postFields: S3PresignedPost = r.value.data?.getUploadToS3Url;
          const s3Parts = AppUtil.getS3URIParts(postFields.s3Url);
          const file = fileList.find((f) => f.name === s3Parts?.filename)!;
          return {
            postFields,
            file
          };
        }
        return null;
      })
      .filter(Boolean) as IMetaFile[];
    return metaFiles;
  };

  const uploadFiles = async (metaFiles: IMetaFile[]) => {
    const uploadPromises = metaFiles.map((metaFile) => {
      const { file, postFields } = metaFile;

      const formData = new FormData();

      const fields =
        typeof postFields.fields === 'string'
          ? JSON.parse(postFields.fields)
          : postFields.fields;

      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });

      formData.append('file', file);

      return retryPromiseWithDelay({
        promiseFn: () =>
          fetch(postFields.url, {
            method: 'POST',
            body: formData
          }),
        errorPredicate: (e: any) => !!e,
        retries: 5,
        delayTime: delayExponentialBackoff
      });
    });

    const uploadResult = await Promise.allSettled(uploadPromises);
    const uploadedIndexes = uploadResult
      .map((result, index) => {
        if (result.status === 'fulfilled' && result.value.ok) {
          return index;
        }
        return undefined;
      })
      .filter((index) => index !== undefined);

    const uploadedResponses: IUploadedResponse[] = uploadedIndexes.map(
      (index) => {
        const metaFile = metaFiles.find(
          (_f, fileIndex) => fileIndex === index
        )!;
        return {
          s3Url: metaFile.postFields.s3Url
        };
      }
    );

    return uploadedResponses;
  };

  return {
    isLoading,
    getS3PresignedUrl,
    getSignedUrlObject,
    openInNewTab,
    download,
    prepareMetaFiles,
    uploadFiles
  };
}
