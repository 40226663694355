// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditableStringFact-module__editable-container__LJx01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.EditableStringFact-module__editable-container__LJx01 .EditableStringFact-module__edit-actions-container__LJk7i {
  display: grid;
  grid-template-columns: 14px 14px;
  grid-gap: 16px;
  margin-right: 12px;
}
.EditableStringFact-module__actions-container__S1YIG {
  display: grid;
  grid-template-columns: 14px 14px;
  grid-gap: 8px;
}
.EditableStringFact-module__delete-container__gAPPX {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.EditableStringFact-module__delete-fact-container__JroGb .EditableStringFact-module__editable-container__LJx01 {
  background: rgba(255, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 4px;
}
.EditableStringFact-module__delete-actions-container__lIRO1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 8px;
  margin-right: 12px;
}
.EditableStringFact-module__delete-actions-container__lIRO1 .EditableStringFact-module__delete-buttons-container__SRRwu {
  cursor: pointer;
  display: grid;
  grid-template-columns: 14px 14px;
  grid-gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/playground2/EditableStringFact.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EACE,aAAA;EACA,gCAAA;EACA,cAAA;EACA,kBAAA;AADJ;AAKE;EACA,aAAA;EACA,gCAAA;EACA,aAAA;AAHF;AAME;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AAJF;AAQE;EACE,gCAAA;EACA,kBAAA;EACA,kBAAA;AANJ;AAUA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,gBAAA;EACA,kBAAA;AARF;AAUE;EACE,eAAA;EACA,aAAA;EACA,gCAAA;EACA,cAAA;AARJ","sourcesContent":["@import '@css/variables.less';\n\n.editable-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  & .edit-actions-container {\n    display: grid;\n    grid-template-columns: 14px 14px;\n    grid-gap: 16px;\n    margin-right: 12px;\n  }\n}\n\n& .actions-container {\n  display: grid;\n  grid-template-columns: 14px 14px;\n  grid-gap: 8px;\n}\n\n& .delete-container {\n  display: flex;\n  flex-direction: column;\n  border-radius: 5px;\n}\n\n.delete-fact-container {\n  & .editable-container {\n    background: rgba(255, 0, 0, 0.1);\n    border-radius: 5px;\n    margin-bottom: 4px;\n  }\n}\n\n.delete-actions-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-left: 8px;\n  margin-right: 12px;\n\n  & .delete-buttons-container {\n    cursor: pointer;\n    display: grid;\n    grid-template-columns: 14px 14px;\n    grid-gap: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editable-container": `EditableStringFact-module__editable-container__LJx01`,
	"edit-actions-container": `EditableStringFact-module__edit-actions-container__LJk7i`,
	"actions-container": `EditableStringFact-module__actions-container__S1YIG`,
	"delete-container": `EditableStringFact-module__delete-container__gAPPX`,
	"delete-fact-container": `EditableStringFact-module__delete-fact-container__JroGb`,
	"delete-actions-container": `EditableStringFact-module__delete-actions-container__lIRO1`,
	"delete-buttons-container": `EditableStringFact-module__delete-buttons-container__SRRwu`
};
export default ___CSS_LOADER_EXPORT___;
