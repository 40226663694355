/* eslint-disable no-underscore-dangle */
//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// AppPage is a component that is responsible for rendering the current page
//

// 3rd party libraries
import React, { useCallback, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout, message } from 'antd';
import * as Sentry from '@sentry/react';

// Local Includes
import AppLeftNav, { COLLAPSED_WIDTH } from '@/views/AppLeftNav';
import CurrentPopup from '@popups/CurrentPopup';
import { userSelector } from '@/stores/slices/user';

import { isEmpty } from 'lodash';
import { permissionUtil } from '@/utils/permissionUtil';
import { currentUserDetailsVar } from '@/graphql/cache/user';
import { GET_LOGGEDIN_USER } from '@/graphql/queries/user';
import { useLazyQuery } from '@apollo/client';
import AnalyticsTracker, { AnalyticsGlobalProperties } from '@/tracker';
import AppTopHeader, { HEADER_HEIGHT } from '@/views/AppTopHeader';
import styles from './AppPage.module.less';
import WithSuspense from '../components/WithSuspense';
import { useAppDispatch, useAppSelector } from '../stores/hooks';
import {
  departmentQuerySelector,
  getDepartment,
  getDepartments,
  selectDepartment
} from '../stores/slices/department';
import Loader, { ILoaderType } from '../components/Loader';
import routes from '../utils/routes';
import DepartmentNotFound from '../components/DepartmentNotFound';
import CurrentDrawer from '../popups/CurrentDrawer';
import { getSelectedDepartmentId } from '../utils/department';
import {
  AccessControlRole,
  Department,
  Organization,
  OrganizationBillingStatus,
  User
} from '../generated/API';
import { useRefresh } from '../provider/refresh';
import SentryErrorPage from './SentryErrorPage';

const { Content } = Layout;

/**
 * This is layout page. `<Outlet/>` is the placeholder for dynamic components
 * @returns
 */
function AppPage() {
  // TODO: each page should control its layout

  const { department, loading } = useAppSelector(departmentQuerySelector);
  const { username, organization } = useAppSelector(userSelector);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { refresh } = useRefresh();

  const [getLoggedInUser, { loading: userLoading }] =
    useLazyQuery(GET_LOGGEDIN_USER);

  const addUserDataToCache = (
    latestDepartment: Department,
    user: User,
    org: Organization
  ) => {
    currentUserDetailsVar({
      department: latestDepartment,
      permissions: permissionUtil.getOrgPermissions(org),
      email: username,
      name: user?.firstName || username?.split('@')[0],
      lastName: user?.lastName,
      id: username, // TODO: Add a proper id
      role: user?.role as AccessControlRole,
      organization: org,
      isDemoUser:
        org && org?.billingStatus === OrganizationBillingStatus.UNPAID.valueOf()
    });

    // Add user data to window object for Whatfix to use
    if (isEmpty((window as any)._kog_settings)) {
      (window as any)._kog_settings = {};
    }
    (window as any)._kog_settings.user = username;
    (window as any)._kog_settings.role = user.role;

    const analyticsData: AnalyticsGlobalProperties = {
      department: latestDepartment.name,
      role: user.role?.valueOf(),
      isDemoUser:
        org &&
        org?.billingStatus === OrganizationBillingStatus.UNPAID.valueOf(),
      accountType:
        org?.billingStatus === OrganizationBillingStatus.UNPAID.valueOf()
          ? 'Unpaid'
          : 'Paid'
    };
    if (org && org?.name) {
      analyticsData.organization = org?.name;
    }
    AnalyticsTracker.setGlobalProperties(analyticsData);
  };

  // TODO: Remove this after 2 weeks post prod deployment.
  useEffect(() => {
    localStorage.removeItem('persist:root');
  }, []);

  const selectedDepartmentId = getSelectedDepartmentId();

  const fetchSelectedDepartment = useCallback(() => {
    dispatch(
      getDepartment({
        id: selectedDepartmentId!
      })
    )
      .unwrap()
      .then((res) => {
        const fetchedDepartment = res.data?.getDepartment as Department | null;

        // If there is no such department by the stored id, then clear the id and use the latest department
        if (fetchedDepartment === null) {
          dispatch(selectDepartment(null));
          refresh();
          return;
        }

        if (fetchedDepartment) {
          dispatch(selectDepartment(fetchedDepartment));
          getLoggedInUser({
            variables: {
              departmentId: fetchedDepartment.id
            },
            onCompleted: (data) => {
              const user = data?.getUser;
              if (user) {
                addUserDataToCache(fetchedDepartment, user, organization.data!);
              }
            }
          });
        }
      })
      .catch((err) => {
        console.log('getDepartment:error', err);
        message.error(err.message || 'Failed to fetch department');
      });
  }, [organization, selectedDepartmentId]);

  const fetchLatestDepartment = useCallback(() => {
    dispatch(
      getDepartments({
        limit: 1
      })
    )
      .unwrap()
      .then((response) => {
        const latestDepartment = response.data?.listDepartments?.items?.[0];
        if (latestDepartment) {
          dispatch(selectDepartment(latestDepartment));
          getLoggedInUser({
            variables: {
              departmentId: latestDepartment.id
            },
            onCompleted: (data) => {
              const user = data?.getUser;
              if (user) {
                addUserDataToCache(latestDepartment, user, organization.data!);
              }
            }
          });
        }
      })
      .catch((err) => {
        console.log('listDepartments:error', err);
        message.error(err.message || 'Failed to fetch department');
      });
  }, [organization, selectedDepartmentId]);

  useEffect(() => {
    if (selectedDepartmentId) {
      fetchSelectedDepartment();
    } else {
      fetchLatestDepartment();
    }
  }, [organization, selectedDepartmentId]);

  const renderContent = () => {
    if (organization.loading || userLoading) {
      return <Loader type={ILoaderType.FULL_PAGE} />;
    }

    if (location.pathname.includes(routes.department.getDepartmentsPageUrl())) {
      return <Outlet />;
    }
    if (loading && !department) {
      return <Loader type={ILoaderType.FULL_PAGE} />;
    }
    if (location.pathname.includes('/automation')) {
      return <Outlet />;
    }
    if (!loading && !department) {
      return <DepartmentNotFound />;
    }
    return <Outlet />;
  };

  return (
    <div className={styles.appPage}>
      <Layout>
        <AppTopHeader />
        <AppLeftNav top={HEADER_HEIGHT} />
        <Content
          // style={{ marginLeft: COLLAPSED_WIDTH, marginTop: HEADER_HEIGHT }}
          style={{ marginLeft: COLLAPSED_WIDTH }}
        >
          <Layout>
            <div className={styles.pageContent}>
              <Sentry.ErrorBoundary
                fallback={SentryErrorPage}
                showDialog
                onError={(error) => {
                  Sentry.captureException(error);
                }}
              >
                <WithSuspense element={<>{renderContent()}</>} />
              </Sentry.ErrorBoundary>
            </div>
          </Layout>
        </Content>
        <CurrentPopup />
        <CurrentDrawer />
      </Layout>
    </div>
  );
}

export default AppPage;
