import RunFactRendererV2 from '@/components/playground2/RunFactRendererV2';
import useFactV2 from '@/hooks/useFactV2';
import { useRunCtxV2 } from '@/provider/runv2';
import FormattingUtil, { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import React from 'react';
import './LongAnswer.less';
import { Pagination } from 'antd';
import { getFactType } from '@/utils/facts';
import usePaginationState from '@/hooks/usePaginationState';
import { LoadingOutlined } from '@ant-design/icons';
import classnames from 'classnames';

interface IProps {
  step: any;
  isPgV3?: boolean;
}

const PAGE_SIZE = 10;

function LongAnswerWrapper(props: IProps) {
  const { step, isPgV3 } = props;

  const answer = step.answer;

  const { worker } = useRunCtxV2();

  // Answer can be null or an object
  const parsedAnswer = answer ? FormattingUtil.parseBrainValue(answer) : {};
  // const editableValue = getTerminalValue(parsedAnswer);

  let cptIds: string[] = [];
  if (parsedAnswer?.concept) {
    cptIds = [parsedAnswer?.concept?.id];
  }
  if (parsedAnswer?.concepts) {
    parsedAnswer?.concepts?.items?.forEach((cpt: any) => {
      const ans = cpt ? FormattingUtil.decodeBrainValue(cpt) : {};
      cptIds.push(ans?.concept?.id);
    });
  }

  const { pageNumber, updatePageNumber, pageSize, total } = usePaginationState({
    totalCount: cptIds.length,
    pageSize: PAGE_SIZE
  });

  const {
    getFactById,
    loading,
    ids: currentCpts
  } = useFactV2({
    ids: cptIds,
    knowledgeId: worker!.knowledgeId!,
    epoch: step.epoch,
    paginationState: {
      pgNumber: pageNumber,
      pgSize: pageSize
    }
  });

  const fact = getFactById(currentCpts[0], step.epoch);
  const factType = getFactType(fact);
  if (
    parsedAnswer?.concept &&
    factType.type !== FormattedAnswerTypeV2.TABLES_ROW
  ) {
    if (loading) {
      return (
        <div
          className={classnames([
            'long-answer',
            {
              'long-answer-container': !isPgV3
            }
          ])}
        >
          <LoadingOutlined />
        </div>
      );
    }
    const fact = getFactById(cptIds[0], step.epoch);
    const factType = getFactType(fact);
    return fact ? (
      <div
        className={classnames([
          'long-answer',
          {
            'long-answer-container': !isPgV3
          }
        ])}
      >
        <RunFactRendererV2
          fact={fact}
          noTextMassage={factType.type === FormattedAnswerTypeV2.RESULT}
          factType={factType}
          knowledgeId={worker!.knowledgeId || ''}
        />
      </div>
    ) : null;
  }

  if (fact && factType.type === FormattedAnswerTypeV2.TABLES_ROW) {
    return (
      <div
        className={classnames([
          'long-answer',
          {
            'long-answer-container': !isPgV3
          }
        ])}
      >
        <RunFactRendererV2
          fact={fact}
          factType={factType}
          knowledgeId={worker?.knowledgeId!}
          stepAnswer={answer!}
        />
      </div>
    );
  }

  return (
    <div
      data-cy="long-answer"
      className={classnames([
        'long-answer',
        {
          'long-answer-container': !isPgV3
        }
      ])}
    >
      {total > pageSize && (
        <div className="pagination-container">
          <Pagination
            pageSize={pageSize}
            showSizeChanger={false}
            size="small"
            defaultCurrent={pageNumber}
            total={total}
            onChange={(pgNumber) => updatePageNumber(pgNumber)}
          />
        </div>
      )}
      {loading ? (
        <div>
          <LoadingOutlined />
        </div>
      ) : (
        <ul className="list-style">
          {currentCpts.map((cpt) => {
            const fact = getFactById(cpt, step.epoch);
            const factType = getFactType(fact);
            return (
              <li>
                {fact ? (
                  <RunFactRendererV2
                    fact={fact}
                    noTextMassage={
                      factType.type === FormattedAnswerTypeV2.RESULT
                    }
                    factType={{
                      ...factType,
                      answer:
                        factType.type === FormattedAnswerTypeV2.TABLE &&
                        typeof factType.answer === 'string'
                          ? [factType.answer]
                          : factType.answer
                    }}
                    knowledgeId={worker!.knowledgeId || ''}
                  />
                ) : null}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

const LongAnswer = (props: IProps) => {
  const { step } = props;
  const { worker } = useRunCtxV2();
  if (!step) {
    return null;
  }

  const answer = step.answer;
  if (!answer) {
    return null;
  }

  // Answer can be null or an object
  const parsedAnswer = answer ? FormattingUtil.parseBrainValue(answer) : {};
  // const editableValue = getTerminalValue(parsedAnswer);

  if (!parsedAnswer) {
    return null;
  }

  if (!worker) {
    return null;
  }

  return <LongAnswerWrapper {...props} />;
};

export default LongAnswer;
