// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fact-document-summary-list {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 8px;
  flex-wrap: wrap;
}
.document-summary {
  cursor: pointer;
  margin-left: 8px;
}
.document-summary:first-of-type {
  margin-left: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/facts/FactDocumentSummary.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AACF;AAEA;EACE,eAAA;EACA,gBAAA;AAAF;AACE;EACE,cAAA;AACJ","sourcesContent":[".fact-document-summary-list {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  margin-left: 8px;\n  flex-wrap: wrap;\n}\n\n.document-summary {\n  cursor: pointer;\n  margin-left: 8px;\n  &:first-of-type {\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
