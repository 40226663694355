//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Authentication configuration and init for Amplify
//

import { Auth } from '@aws-amplify/auth';
// import MemoryStorage from 'memorystorage';
// import { v4 as uuidv4 } from 'uuid';
import { appConfig } from '@/AppConfig';

if (appConfig.cognito) {
  Auth.configure({
    Auth: {
      region: appConfig.cognito.region,
      userPoolId: appConfig.cognito.userPoolId,
      userPoolWebClientId: appConfig.cognito.userPoolWebClientId,
      authenticationFlowType: 'CUSTOM_AUTH'
      // This can be removed once we have cookie-based auth; until then we may enable this again at some point for security reasons.
      // storage: new MemoryStorage(uuidv4()) // used instead of the default (local storage) for improved resistance against XSS vectors
    }
  });
}
