import React, { useState } from 'react';
import { Button, DatePicker, Dropdown, Input, Badge, Menu } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { DownOutlined } from '@ant-design/icons';

interface IActions {
  id: string;
  onClick?: (selectedRows: any) => void;
  isDisabled?: (selectedRows: any) => boolean;
  label: string;
}

const renderActionMenu = ({
  selectedRows,
  actions
}: {
  selectedRows: any;
  actions: IActions[];
}) => (
  <Menu disabled={selectedRows.length === 0}>
    {actions &&
      actions.map((action: IActions) => (
        <Menu.Item
          key={action.id}
          onClick={() => action.onClick?.(selectedRows)}
          disabled={action.isDisabled?.(selectedRows)}
          data-cy={action.id}
        >
          {action.label}
        </Menu.Item>
      ))}
  </Menu>
);

const TableFilters = ({
  filter,
  setFilteredInfo,
  actions,
  selectedRows
}: {
  filter?: any;
  setFilteredInfo: any;
  actions?: IActions[];
  selectedRows?: any[];
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const triggerExternalFilterChange = (
    searchValue: string,
    dateValue: Dayjs | null,
    endDateValue?: Dayjs | null
  ) => {
    if (filter.onExternalFilterChange) {
      filter.onExternalFilterChange(searchValue, dateValue, endDateValue);
    }
  };

  return (
    <>
      {filter?.allowSearchFilter && (
        <Input.Search
          placeholder={filter?.searchPlaceholder || 'Search...'}
          onSearch={(v) => {
            setSearchValue(v);
            triggerExternalFilterChange(v, selectedDate, selectedEndDate);
          }}
          allowClear
          className="table-search table-action-left"
          data-cy="table-search"
        />
      )}
      {filter?.allowDateFilter && (
        <Badge dot={!!selectedDate} className="table-action-left">
          <DatePicker
            value={selectedDate}
            onChange={(date) => {
              setFilteredInfo({});
              setSelectedDate(date || null);
              if (!date) {
                setSelectedEndDate(null);
              }
              triggerExternalFilterChange(
                searchValue,
                date || null,
                selectedEndDate
              );
            }}
            disabledDate={(current) => dayjs(current).isAfter(dayjs())}
            className="table-date-picker"
          />
        </Badge>
      )}
      {filter?.endDateFilter && (
        <Badge dot={!!selectedDate} className="table-action-left">
          <DatePicker
            value={selectedEndDate}
            disabled={!selectedDate}
            onChange={(date) => {
              setFilteredInfo({});
              setSelectedEndDate(date || null);
              triggerExternalFilterChange(
                searchValue,
                selectedDate,
                date || null
              );
            }}
            disabledDate={(current) =>
              dayjs(current).isAfter(dayjs()) ||
              dayjs(current).isBefore(dayjs(selectedDate))
            }
            className="table-date-picker"
            placeholder="End Date"
          />
        </Badge>
      )}
      {actions && (
        <Dropdown
          overlay={renderActionMenu({ selectedRows, actions })}
          trigger={['hover']}
          className="table-button-actions table-action-left"
          disabled={selectedRows && selectedRows.length === 0}
        >
          <Button>
            Actions
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </>
  );
};

export default TableFilters;
