import React, {
  ChangeEvent,
  MutableRefObject,
  MouseEvent,
  useState,
  useCallback
} from 'react';
import { Input, InputRef } from 'antd';
import { debouncedAction } from '@/utils/miscUtil';

interface IDebouncedInput {
  value: string;
  onChange: (e: string) => void;
  waitTime?: number;
  ref?: MutableRefObject<InputRef | null>;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  className?: string;
  readOnly?: boolean;
}

const DebouncedInput = ({
  value,
  onChange,
  waitTime = 500,
  ref,
  className,
  isDisabled,
  onBlur,
  onClick,
  readOnly
}: IDebouncedInput) => {
  const [val, setVal] = useState<string>(value);

  /* eslint-disable */
  // Linter is disabled since its not able to
  // figure out dependencies of the function returned by debouncedAction
  const onChangeHandler = useCallback(
    debouncedAction((txt: string) => {
      onChange(txt);
    }, waitTime),
    [onChange]
  );
  /* eslint-enable */

  return (
    <Input
      ref={ref}
      className={className}
      style={{ width: value.length * 12 + 20 }}
      readOnly={readOnly}
      value={val}
      disabled={isDisabled}
      spellCheck={false}
      onChange={(e) => {
        setVal(e.target.value);
        onChangeHandler(e.target.value);
      }}
      onBlur={onBlur}
      onClick={onClick}
    />
  );
};

export default DebouncedInput;
