import { ApartmentOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import Loader from '../components/Loader';
import { DisplayMode, ObjectViewer } from '../components/ObjectViewer';
import AppUtil from '../utils/AppUtil';
import './ObjectViewerPopup.less';

interface IProps {
  data: any;
  title?: React.ReactNode;
  loading?: boolean;
  className?: string;
  displayMode?: DisplayMode;
  onClose: () => void;
}

function ObjectViewerPopup(props: IProps) {
  const { data, title, loading, className, onClose, displayMode } = props;

  const name = AppUtil.getObjectName(data);
  const id = AppUtil.getObjectId(data);

  let modalTitle: React.ReactNode = 'Details';

  if (name) {
    const nameWithTooltip = (
      <Tooltip title={id}>
        <span>
          <ApartmentOutlined /> {name}
        </span>
      </Tooltip>
    );
    modalTitle = nameWithTooltip;
  }

  return (
    <Modal
      className={classNames('object-viewer-popup', className)}
      title={title || modalTitle}
      centered
      open
      footer={null}
      onCancel={onClose}
    >
      {loading ? (
        <Loader />
      ) : (
        <ObjectViewer mode={displayMode || DisplayMode.YAML} object={data} />
      )}
    </Modal>
  );
}

export default ObjectViewerPopup;
