import React from 'react';
import { useAppSelector, useATQ } from '../stores/hooks';
import { factSelectors, getFact } from '../stores/slices/fact';
import ObjectViewerPopup from './ObjectViewerPopup';

interface IProps {
  factId: string;
  knowledgeId: string;
  onClose: () => void;
}

function RawFactViewerPopup(props: IProps) {
  const { factId, knowledgeId, onClose } = props;

  // We intentionally want to fetch latest data here instead of accessing from the store
  const { loading } = useATQ(getFact, {
    variables: {
      factId,
      knowledgeId
    }
  });

  const fact = useAppSelector(
    (state) => factSelectors.selectById(state, factId)!
  );

  return (
    <ObjectViewerPopup
      data={fact || {}}
      title="Fact"
      className="fact-viewer-popup"
      onClose={onClose}
      loading={loading}
    />
  );
}

export default RawFactViewerPopup;
