// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-renderer {
  white-space: pre-line;
}
.markdown-renderer.disable-line-white-space {
  white-space: nowrap;
}
.markdown-renderer p,
.markdown-renderer pre,
.markdown-renderer blockquote {
  margin: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/MarkdownRenderer.less"],"names":[],"mappings":"AAEA;EACE,qBAAA;AADF;AAGE;EACE,mBAAA;AADJ;AAQE;;;EAGE,oBAAA;AANJ","sourcesContent":["@import '@css/variables.less';\n\n.markdown-renderer {\n  white-space: pre-line;\n\n  &.disable-line-white-space {\n    white-space: nowrap;\n  }\n\n  & > {\n    // word-break: break-all;\n  }\n\n  & p,\n  & pre,\n  & blockquote {\n    margin: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
