/* eslint-disable max-classes-per-file */

import {
  EditorView,
  Decoration,
  ViewPlugin,
  WidgetType,
  MatchDecorator
} from '@codemirror/view';
import { Extension } from '@codemirror/state';

class PlaceholderWidget extends WidgetType {
  label: any;

  constructor(label: any) {
    super();
    this.label = label;
  }

  eq(other: any) {
    return other.label === this.label;
  }

  toDOM() {
    const filename = this.label.split('/').splice(-1)[0];
    const wrap = document.createElement('span');
    wrap.innerHTML = filename;
    wrap.className = 'cm-s3-file';
    return wrap;
  }
}

const placeholderMatcher = new MatchDecorator({
  regexp: /s3:\/\/([^>,"]+)/g,
  decoration: (match) =>
    Decoration.widget({
      widget: new PlaceholderWidget(match[0])
    })
});

export const fileLinkExtension = () =>
  ViewPlugin.fromClass(
    class {
      placeholders: any;

      constructor(view: any) {
        this.placeholders = placeholderMatcher.createDeco(view);
      }

      update(update: any) {
        this.placeholders = placeholderMatcher.updateDeco(
          update,
          this.placeholders
        );
      }
    },
    {
      decorations: (v) => v.placeholders,
      provide: (plugin) =>
        EditorView.atomicRanges.of(
          (view) => view.plugin(plugin)?.placeholders || Decoration.none
        )
    }
  );

export const fileLinkStyle = EditorView.baseTheme({
  '.cm-s3-file': {
    textDecorationLine: 'underline',
    textDecorationStyle: 'dashed',
    textDecorationColor: '#eee'
  }
});

export const fileLink: Extension = [fileLinkExtension(), fileLinkStyle];
