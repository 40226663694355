import AppConstants from '@/utils/AppConstants';
import AppUtil from '@/utils/AppUtil';
import reactStringReplace from 'react-string-replace';
import { DocumentLineInput } from '../Editor/editorInterface';

export enum WIDGET_TYPES {
  FILE = 'file',
  URL = 'url',
  JSON = 'json',
  MARKDOWN = 'markdown',
  // DATE = 'date',
  EMAIL_HTML = 'email_html',
  EMAIL_SUBJECT = 'email_subject',
  EMAIL_BODY = 'email_body'
  // SEND_EMAIL = 'send_email',
  // ASK_KONCIERGE = 'ask_koncierge'
}

export interface IWidgetData {
  lineId: string;
  widgetType: WIDGET_TYPES;
  widgetKey: string;
  widgetValue: string;
}

const editableWidgetTypes = [
  WIDGET_TYPES.FILE,
  WIDGET_TYPES.MARKDOWN,
  // WIDGET_TYPES.JSON,
  WIDGET_TYPES.URL
  // WIDGET_TYPES.DATE
  // WIDGET_TYPES.SEND_EMAIL,
  // WIDGET_TYPES.ASK_KONCIERGE
];

export const editableWidgets = editableWidgetTypes.map((i) => ({
  type: i,
  label: i.trim().toLowerCase().split('_').join(' ')
}));

export const WIDGET_COUNT_INIT_STATE = {
  KOG_WIDGET_COUNT: {
    [WIDGET_TYPES.MARKDOWN]: 0,
    [WIDGET_TYPES.JSON]: 0,
    [WIDGET_TYPES.URL]: 0
  }
};

function findAllOccurrencesOfSubstr(str: string, sub: string) {
  const occurrences: { index: number; substring: string }[] = [];

  if (sub.length === 0) return occurrences;

  for (let i = 0; i < str.length; i += 1) {
    if (str.substring(i, i + sub.length) === sub) {
      occurrences.push({ index: i, substring: sub });
      i += sub.length - 1; // Skip the substring length to avoid overlapping matches
    }
  }

  return occurrences;
}

const findWidgetsInText = (text: string, keyArr: string[]) => {
  if (keyArr.length === 0) {
    return [];
  }
  const matches = keyArr.filter((key) => text.includes(key));

  if (matches) {
    const widgetsConfig: (string | number)[][] = [];
    matches.forEach((match) => {
      findAllOccurrencesOfSubstr(text, match.trim()).forEach((oc) => {
        widgetsConfig.push([oc.substring.trim(), oc.index, match]);
      });
    });
    return widgetsConfig;
  }
  return [];
};

export const widgetDecorator = (
  [node, path]: any,
  replacerObj: any | undefined
) => {
  const nodeText = node.text;

  if (!nodeText || !replacerObj) return [];

  const urls = findWidgetsInText(nodeText, Object.keys(replacerObj));

  return urls.map(([url, index, widgetKey]: any) => {
    return {
      anchor: {
        path,
        offset: index
      },
      focus: {
        path,
        offset: index + url.length
      },
      decoration: 'widget',
      widgetKey,
      widgetData: replacerObj[widgetKey]
    };
  });
};

/**
 * {
 * [md1]: {val: 'asdasd', type: 'md'},
 * [md2]: {val: 'asdasd', type: 'md'},,
 * KOG_WIDGET_COUNT: {
 *  md: 2, json: 3
 * }
 * }
 */

export const textFormatter = (text: string, oldObj?: any) => {
  let replacerObj = { ...WIDGET_COUNT_INIT_STATE, ...oldObj };

  const inlineEmailHtml = reactStringReplace(
    text,
    AppConstants.PATTERNS.EMAIL_HTML,
    (match) => {
      replacerObj = {
        ...replacerObj,
        'email-html': { value: `"${match}"`, type: WIDGET_TYPES.EMAIL_HTML }
      };
      return 'the email html is email-html';
    }
  );

  const inlineEmailBody = reactStringReplace(
    inlineEmailHtml,
    AppConstants.PATTERNS.EMAIL_BODY,
    (match) => {
      const key = 'email-body';
      replacerObj = {
        ...replacerObj,
        [key]: { value: `"${match}"`, type: WIDGET_TYPES.EMAIL_BODY }
      };
      return 'the email body is email-body';
    }
  );

  const inlineMD = reactStringReplace(
    inlineEmailBody,
    AppConstants.PATTERNS.MARKDOWN_TEXT,
    (match) => {
      const widgetNumber =
        replacerObj.KOG_WIDGET_COUNT[WIDGET_TYPES.MARKDOWN] + 1;
      const widgetKey = `[Md${widgetNumber}]`;
      const newWidgetCount = {
        ...replacerObj.KOG_WIDGET_COUNT,
        [WIDGET_TYPES.MARKDOWN]: widgetNumber
      };
      replacerObj = {
        ...replacerObj,
        [widgetKey]: { value: `"""${match}"""`, type: WIDGET_TYPES.MARKDOWN },
        KOG_WIDGET_COUNT: newWidgetCount
      };
      return widgetKey;
    }
  );

  const inlineFiles = reactStringReplace(
    inlineMD,
    AppConstants.PATTERNS.S3_URI_WIDGET,
    (match) => {
      const uri = `s3://${match}`;
      const s3Parts = AppUtil.getS3URIParts(uri);
      if (s3Parts && s3Parts.filename) {
        replacerObj = {
          ...replacerObj,
          [s3Parts?.filename]: { value: `"${uri}"`, type: WIDGET_TYPES.FILE }
        };
      }

      return s3Parts?.filename ? `${s3Parts?.filename}` : uri;
    }
  );

  const inlineURL = reactStringReplace(
    inlineFiles,
    AppConstants.PATTERNS.HTTP_URI,
    (match) => {
      const widgetNumber = replacerObj.KOG_WIDGET_COUNT[WIDGET_TYPES.URL] + 1;
      const widgetKey = `[url${widgetNumber}]`;
      const newWidgetCount = {
        ...replacerObj.KOG_WIDGET_COUNT,
        [WIDGET_TYPES.URL]: widgetNumber
      };
      replacerObj = {
        ...replacerObj,
        [widgetKey]: { value: `"${match}"`, type: WIDGET_TYPES.URL },
        KOG_WIDGET_COUNT: newWidgetCount
      };
      return widgetKey;
    }
  );

  const inlineJSON = reactStringReplace(
    inlineURL,
    AppConstants.PATTERNS.JSON,
    (match) => {
      if (match && match.includes(':')) {
        const widgetNumber =
          replacerObj.KOG_WIDGET_COUNT[WIDGET_TYPES.JSON] + 1;
        const widgetKey = `[json${widgetNumber}]`;
        const newWidgetCount = {
          ...replacerObj.KOG_WIDGET_COUNT,
          [WIDGET_TYPES.URL]: widgetNumber
        };
        replacerObj = {
          ...replacerObj,
          [widgetKey]: {
            value: `"{${match}}"`,
            type: WIDGET_TYPES.JSON
          },
          KOG_WIDGET_COUNT: newWidgetCount
        };
        return widgetKey;
      }
      return `"{${match}}"`;
    }
  );

  return { text: inlineJSON.join(''), replacerObj };
};

const DeFormatPriority = [
  WIDGET_TYPES.FILE,
  WIDGET_TYPES.JSON,
  WIDGET_TYPES.MARKDOWN,
  WIDGET_TYPES.URL,
  WIDGET_TYPES.EMAIL_SUBJECT,
  WIDGET_TYPES.EMAIL_BODY,
  WIDGET_TYPES.EMAIL_HTML
];

export const textDeFormatterForDocumentLine = (
  document: DocumentLineInput[],
  replacerObj?: Record<string, any>
) => {
  // If replacerObj is not provided, return the original document
  if (!replacerObj) {
    return document;
  }

  // Initialize a map to associate each type with its corresponding keys
  const typeToKeysMap = new Map();
  // Populate the map with each type in DeFormatPriority
  for (const type of DeFormatPriority) {
    typeToKeysMap.set(type, []);
  }

  // Loop through keys in replacerObj
  for (const key of Object.keys(replacerObj)) {
    // Retrieve the type of the current key
    const type = replacerObj[key].type;
    // If the type is in the map, add the key to the corresponding array
    if (typeToKeysMap.has(type)) {
      typeToKeysMap.get(type).push(key);
    }
  }

  // Iterate over the document, modifying each line as needed
  return document.map((line) => {
    // Skip modification if the line doesn't contain text
    if (!line.text) {
      return line;
    }

    // Start with the original text of the line
    let newtext = line.text;
    // Iterate over each type in DeFormatPriority
    for (const type of DeFormatPriority) {
      // Get the keys associated with the current type
      const keys = typeToKeysMap.get(type);
      // Iterate over these keys
      for (const key of keys) {
        // If the current key is found in the text, replace it with its value
        if (newtext.includes(key)) {
          newtext = newtext.replaceAll(key, replacerObj[key].value as string);
        }
      }
    }

    // Return the modified line with updated text
    return { ...line, text: newtext };
  });
};

export const textDeFormatter = (
  text: string,
  replacerObj?: Record<string, any>
) => {
  if (!replacerObj) {
    return text;
  }
  const organizedKeys = DeFormatPriority.reduce((acc, type) => {
    Object.keys(replacerObj).forEach((key) => {
      if (replacerObj[key].type === type) {
        acc.push(key);
      }
    });
    return acc;
  }, [] as string[]);

  // Apply replacements in the order of priority
  let newText = text;
  organizedKeys.forEach((key) => {
    newText = newText.replaceAll(key, replacerObj[key].value as string);
  });

  return newText;
};
