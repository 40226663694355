//
//  Copyright (C) - Kognitos. All rights reserved
//

import { createLogger } from 'redux-logger';
import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import books from '@/stores/slices/books';
import { listenerMiddleware } from '@/stores/listenerMiddleware';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { amplitudeMiddleware } from '@/tracker';
import { getGraphqlClient } from '@/utils/GraphqlClient';
import popups from './slices/appPopup';
import drawers from './slices/appDrawer';
import settings from './slices/settings';
import run from './slices/run';
import department from './slices/department';
import supportDepartment from './slices/supportDepartment';
import worker from './slices/worker';
import steps from './slices/steps';
import procedure from './slices/procedure';
import procedureRuns from './slices/procedureRuns';
import procedureRequests from './slices/procedureRequests';
import procedureOutputs from './slices/procedureOutputs';
import user from './slices/user';
import request from './slices/request';
import fact from './slices/fact';
import departmentLearnedAnswers from './slices/departmentLearnedAnswers';
import departmentHistory from './slices/departmentHistory';
import dev, { devSlice } from './slices/dev';
import annotation from './slices/annotation';

const reducers = combineReducers({
  popups,
  drawers,
  settings,
  run,
  department,
  supportDepartment,
  books,
  worker,
  steps,
  procedure,
  procedureRuns,
  procedureRequests,
  procedureOutputs,
  user,
  request,
  fact,
  dev,
  departmentLearnedAnswers,
  departmentHistory,
  annotation
});

// TODO: Turn this off in prod
const debugStore = false;

export const client = getGraphqlClient();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

// TODO: JSX tree shouldn't re-render with updates unrelated to it, for eg. the entire tree and state re-renders on the run page
export type RootState = ReturnType<typeof reducers>;
export function setupStore(
  preloadedState?: PreloadedState<RootState>,
  { skipAmplitude = false } = {}
) {
  return configureStore({
    reducer: reducers,
    preloadedState,
    // https://github.com/reduxjs/redux-devtools/blob/main/extension/docs/API/Arguments.md
    devTools: {
      // trace: true,
      // traceLimit: 25,
      maxAge: 100,
      actionCreators: devSlice.actions
    },
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        thunk: {
          extraArgument: {
            appSyncClient: client
          }
        },
        serializableCheck: false
      }).prepend(listenerMiddleware.middleware);

      if (debugStore) {
        middleware.push(createLogger({ collapsed: true }) as any);
      }

      middleware.push(LogRocket.reduxMiddleware());

      if (!skipAmplitude) {
        middleware.push(amplitudeMiddleware);
      }

      return middleware;
    },
    enhancers: [sentryReduxEnhancer]
  });
}

export const store = setupStore();

export type StoreType = ReturnType<typeof setupStore>;
export type AppDispatch = StoreType['dispatch'];
export type AsyncThunkConfig = {
  state: RootState;
  dispatch: AppDispatch;
  extra: {
    appSyncClient: ApolloClient<NormalizedCacheObject>;
  };
};
