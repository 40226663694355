import { Button, Space, Tabs } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import DocumentExceptionExtractions, {
  IDocumentExceptionExtractionsProps
} from './DocumentExceptionExtractions';
import styles from './DocumentExceptionStagingArea.module.less';
import { annotationSelector } from '../../../stores/slices/annotation';

interface IDocumentExceptionStagingAreaProps {
  extractionProps: IDocumentExceptionExtractionsProps;
  onCancel: () => void;
  onSubmit?: () => void;
}

function DocumentExceptionStagingArea(
  props: IDocumentExceptionStagingAreaProps
) {
  const { extractionProps, onCancel, onSubmit } = props;

  const { annotations } = useSelector(annotationSelector);

  const items = [
    {
      key: '1',
      label: 'Text',
      children: <DocumentExceptionExtractions {...extractionProps} />
    }
  ];

  const foundValidText = annotations.find(
    (annotation) => !!annotation?.textContent
  );

  const isDisabled = !foundValidText;

  return (
    <div className={styles.documentStaging}>
      <div className={styles.content}>
        <Tabs defaultActiveKey="1" items={items} />
      </div>

      <div className={styles.footer}>
        <Space>
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            disabled={isDisabled}
            onClick={() => {
              onSubmit?.();
              onCancel();
            }}
          >
            Submit
          </Button>
        </Space>
      </div>
    </div>
  );
}

export default DocumentExceptionStagingArea;
