import { useRunCtxV2 } from '@/provider/runv2';
import FormattingUtil, { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import { Empty } from 'antd';
import React from 'react';
import { omit } from 'lodash';
import useLineFact, {
  FactAnswer,
  FACT_STATUS,
  prepareFactKey
} from '@/hooks/useLineFact';
import RunFactRendererV2 from '@/components/playground2/RunFactRendererV2';
import styles from '@/components/playground2/RunFactRendererV2.module.less';
import Loader, { ILoaderType } from '@/components/Loader';

const FactLoader = () => (
  <span className={styles.stringFactContainer}>
    <Loader
      className={styles.noPadding}
      message="Getting data"
      skeletonConfig={{ rows: 0 }}
      type={ILoaderType.SKELETON}
    />
  </span>
);

interface IProps {
  step: any;
  expandDocumentPreview?: (open: boolean) => void;
  isDebugMode?: boolean;
}

function LineResultRenderer({
  answer,
  knowledgeId,
  epoch,
  expandDocumentPreview,
  isDebugMode
}: {
  answer: any;
  knowledgeId: string;
  epoch: number;
  expandDocumentPreview?: (open: boolean) => void;
  isDebugMode?: boolean;
}) {
  const cptList: FactAnswer[] = [];
  if (answer?.concept) {
    cptList.push(omit(answer.concept, 'locations') as FactAnswer);
  } else if (answer?.concepts) {
    cptList.push(
      ...answer.concepts.items.map((i: any) => {
        const data = FormattingUtil.decodeBrainValue(i)?.concept;
        return omit(data, 'locations') as FactAnswer;
      })
    );
  }

  const { facts, factIds } = useLineFact({ cptList, knowledgeId, epoch });
  const firstFactKey = prepareFactKey(factIds[0], epoch);

  if (cptList.length === 0) {
    return <Empty />;
  }

  const firstFact = facts[firstFactKey];
  if (!facts || !firstFact) {
    return <FactLoader />;
  }

  // If Table's row / JSON && any value has a _large_value_, then fetch and render cptsTable
  // If Table's row / JSON && no value has a _large_value_, then render cptsTable

  // call useLineFact with all cpts -> If any has _large_value_ fetch and show loading || If any has parentFact, fetch and show loading
  // map on list of cpts
  // S3 -> show doc if 1 cpt, else show list
  if (
    factIds.length === 1 &&
    firstFact?.fact_type === FormattedAnswerTypeV2.S3
  ) {
    return firstFact && firstFact.status === FACT_STATUS.LOADING ? (
      <FactLoader />
    ) : (
      <RunFactRendererV2
        debug={isDebugMode}
        showPreview
        fact={firstFact}
        factType={{
          type: FormattedAnswerTypeV2.S3,
          answer: firstFact.value || firstFact.display_value
        }}
        knowledgeId={knowledgeId}
        expandDocumentPreview={expandDocumentPreview}
      />
    );
  }
  // Table -> show table if 1 cpt, else show list
  if (
    factIds.length === 1 &&
    facts[firstFactKey].fact_type === FormattedAnswerTypeV2.TABLE
  ) {
    return firstFact && firstFact.status === FACT_STATUS.LOADING ? (
      <FactLoader />
    ) : (
      <RunFactRendererV2
        debug={isDebugMode}
        showPreview
        fact={firstFact}
        factType={{
          type: FormattedAnswerTypeV2.TABLE,
          answer: [firstFact.value || firstFact.display_value]
        }}
        knowledgeId={knowledgeId}
        expandDocumentPreview={expandDocumentPreview}
      />
    );
  }

  if (
    firstFact.fact_type === FormattedAnswerTypeV2.TABLES_ROW ||
    // A string is a valid json, hence we check for object type
    (firstFact.fact_type === FormattedAnswerTypeV2.JSON &&
      typeof firstFact.value === 'object')
  ) {
    const data = factIds.map((id) => facts[prepareFactKey(id, epoch)]);
    return (
      <RunFactRendererV2
        fact={firstFact}
        factType={{ type: FormattedAnswerTypeV2.TABLES_ROW, answer: '' }}
        knowledgeId={knowledgeId}
        showInFactsTable
        showPreview
        expandDocumentPreview={expandDocumentPreview}
        epoch={epoch}
        debug={isDebugMode}
        tablesRowDataNewFactsModel={data}
      />
    );
  }

  let updatedFactIds = factIds;
  // This shows a loader for all the results after a LOADING status is found
  const loadingIndex = factIds.findIndex(
    (item) => facts[prepareFactKey(item, epoch)]?.status === FACT_STATUS.LOADING
  );

  if (loadingIndex >= 0) {
    updatedFactIds = factIds.slice(0, loadingIndex);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {updatedFactIds.map((id) => {
        const fact = facts[prepareFactKey(id, epoch)];

        let factType = fact.fact_type;
        if (!factType) {
          factType = FormattedAnswerTypeV2.STRING;
        } else if (
          factType === FormattedAnswerTypeV2.JSON &&
          typeof fact.value !== 'object'
        ) {
          factType = FormattedAnswerTypeV2.STRING;
        }

        let factTypeAnswer: any = fact.value;
        if (fact.fact_type === FormattedAnswerTypeV2.TABLE) {
          factTypeAnswer = [fact.value];
        } else if (!fact.fact_type) {
          factTypeAnswer = fact.display_value;
        }

        return fact?.status === FACT_STATUS.LOADING ? (
          <FactLoader />
        ) : (
          <RunFactRendererV2
            showRelations
            debug={isDebugMode}
            key={id}
            fact={fact}
            factType={{
              type: factType,
              answer: factTypeAnswer || '-'
            }}
            knowledgeId={knowledgeId}
            showInFactsTable
            noTextMassage
            expandDocumentPreview={expandDocumentPreview}
          />
        );
      })}
      {loadingIndex >= 0 && (
        <Loader
          className={styles.noPadding}
          message="Getting data"
          skeletonConfig={{ rows: 5 }}
          type={ILoaderType.SKELETON}
        />
      )}
    </div>
  );
}

function LineResult(props: IProps) {
  const { step, expandDocumentPreview, isDebugMode } = props;
  const { worker } = useRunCtxV2();
  if (!step || !step.answer || !worker) {
    return <Empty />;
  }

  const answer = step.answer;
  const parsedAnswer = FormattingUtil.parseBrainValue(answer);

  return (
    <LineResultRenderer
      answer={parsedAnswer}
      knowledgeId={worker.knowledgeId!}
      epoch={step.epoch}
      expandDocumentPreview={expandDocumentPreview}
      isDebugMode={isDebugMode}
    />
  );
}

export default LineResult;
