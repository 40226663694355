// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-viewer .ant-card {
  box-shadow: none;
}
.document-viewer .ant-card-body {
  padding: 0;
}
.document-viewer .ant-card-head {
  margin-bottom: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/S3DocumentViewer.less"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGE;EACE,UAAA;AADJ;AAIE;EACE,kBAAA;AAFJ","sourcesContent":[".document-viewer {\n  & .ant-card {\n    box-shadow: none;\n  }\n\n  & .ant-card-body {\n    padding: 0;\n  }\n\n  & .ant-card-head {\n    margin-bottom: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
