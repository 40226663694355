import React from 'react';
import { Drawer } from 'antd';
import useFactV2 from '@/hooks/useFactV2';
import { getFactType } from '@/utils/facts';
import RunFactRendererV2 from '@/components/playground2/RunFactRendererV2';
import AnswerObjectViewer from '@/components/AnswerObjectViewer';
import Table from '../components/table/Table';
import '../components/playground2/FactsTableV3.less';
import usePaginationState from '../hooks/usePaginationState';
import { FormattedAnswerTypeV2 } from '../utils/FormattingUtil';

interface IProps {
  title: string;
  ids: string[];
  knowledgeId: string;
  onClose: () => void;
}

export default function RunFactsDrawer(props: IProps) {
  const { title, ids, knowledgeId, onClose } = props;

  const { pageNumber, updatePageNumber, pageSize } = usePaginationState({
    totalCount: ids.length,
    pageSize: 10
  });

  const {
    getFactById,
    loading,
    ids: currentCpts
  } = useFactV2({
    ids,
    knowledgeId: knowledgeId!,
    paginationState: {
      pgNumber: pageNumber,
      pgSize: pageSize
    }
  });

  const fact = getFactById(currentCpts[0]);

  const columns = [
    {
      id: title,
      title,
      renderColumn: (_: any, record: any) => {
        const factType = getFactType(record);
        if (factType.type === FormattedAnswerTypeV2.TABLES_ROW) {
          console.log(factType);
          return <AnswerObjectViewer object={factType.answer} />;
        }
        return fact ? (
          <RunFactRendererV2
            fact={record}
            factType={factType}
            knowledgeId={knowledgeId}
          />
        ) : (
          '...'
        );
      }
    }
  ];

  return (
    <Drawer title={title} placement="right" onClose={onClose} width="50%" open>
      <span data-cy="run-facts-drawer">
        <Table
          uniqueRowId="id"
          data={currentCpts.map((cpt) => getFactById(cpt))}
          columns={columns}
          loading={loading}
          className="facts-table"
          pagination={{
            total: ids.length,
            pageNumber,
            pageSize,
            onChange: (page) => {
              updatePageNumber(page);
            }
          }}
        />
      </span>
    </Drawer>
  );
}
