// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RequestPanel-module__requestPanel__J25CL {
  display: flex;
  flex-direction: column;
  padding: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PlaygroundV3/components/RequestPanel/RequestPanel.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AACF","sourcesContent":[".requestPanel {\n  display: flex;\n  flex-direction: column;\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requestPanel": `RequestPanel-module__requestPanel__J25CL`
};
export default ___CSS_LOADER_EXPORT___;
