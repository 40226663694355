import React from 'react';
import { BaseEditor, Descendant } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import { TabKeys } from '../OutputPanel/OutputPanel';
import { IWidgetData } from '../EditorWidgets/decorators';
import { Worker } from '../../../../generated/API';

export interface Answer {
  id: string;
  type: string;
  value?: any;
}

export interface AnswersToken {
  [key: string]: any;
}

export enum ExecutionStatus {
  NEW_LINE = 'NEW_LINE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  RUNNING = 'RUNNING',
  PENDING = 'PENDING'
}
export interface SubDocument {
  documentToken: string;
  processName: string;
}

export interface SentenceMetadata {
  lineId: string;
  parentId?: string | null;
}

export interface SentenceInternalMetadata {
  key: string;
  value: string;
}

export enum SentenceInternalMetadataKeys {
  ORIGINAL_TEXT = 'original_text'
}

export interface Sentence {
  lineId: string;
  text: string;
  parentId?: string | null;
  metadata?: SentenceMetadata;
  internalMetadata?: SentenceInternalMetadata[];
  language?: string;
}

export interface SentenceExecutionData {
  lineId: string;
  token: string;
  status: ExecutionStatus;
  answer?: any;
  concepts?: any;
  requests?: any;
  iterationTokens?: string[];
  subDocuments?: SubDocument[];
  miniPlaygrounds?: SubDocument[];
  epoch?: number;
  startedAt?: Date;
  completedAt?: Date;
}

export interface ISentence {
  uiLineId: string;
  text: string;
  status?: ExecutionStatus;
  uiParentId?: string | null;
  loopResultCount?: number;
  currentLoppIndex?: number;
  concepts?: any;
  requests?: any;
  answer?: any;
  editorAnswers?: AnswersToken;
  editorConcepts?: AnswersToken;
  editorRequests?: AnswersToken;
  editorSubDocuments?: AnswersToken;
  editorMiniPlaygrounds?: AnswersToken;
  token?: string;
  originalText?: string;
  epoch?: number;
  decoration?: string;
  subDocuments?: SubDocument[];
  miniPlaygrounds?: SubDocument[];
  tabCount?: number;
  documentLine?: Sentence;
  executionData?: SentenceExecutionData;
}

export interface UISentence {
  uiLineId: string;
  text: string;
  uiParentId?: string | null;
}

export interface DocumentLineInput {
  text: string;
  metadata?: SentenceMetadata;
  language?: string;
}

export interface EditorBlock {
  type: string;
  children: ISentence[];
}

export enum EditorTabsKey {
  MAIN = ''
}

export interface IEditorTabs {
  key: string;
  tab: string | React.ReactNode;
  itemKey: string;
  name: string;
}

export interface IEditorStatus {
  readOnly: boolean;
  isEditing: boolean;
  isSaving: boolean;
  autoPilot: boolean;
  showDocument: boolean;
  isExecuting: boolean;
  showChat: boolean;
  showOutputPanel: boolean;
}

export interface CodeBlock {
  startLine: number;
  endLine: number;
  tabCount: number;
  isCollapsed: boolean;
}

export enum EditorSourceType {
  PLAYGROUND = 'PLAYGROUND',
  PROCESS_RUN = 'PROCESS_RUN',
  PROCESS = 'PROCESS',
  EXCEPTION_CENTRE_RUN = 'EXCEPTION_CENTRE_RUN',
  MINI_PLAYGROUND = 'MINI_PLAYGROUND'
}

export interface EditorErrorRecord {
  lineNumber: number;
  errorMessage: string;
}

export interface IEditorContextActions {
  toggleDebugMode: (mode: boolean) => void;
  onEditorTabClose: (tab: string) => void;
  updateEditorTabs: (tabName: string) => void;
  onSubprocedureClick: (block: ISentence, subDocument: SubDocument) => void;
  fetchWorkerData: (workerId: string) => void;
  onPlay: (workerId: string) => void;
  updateReplacerObj: React.Dispatch<
    React.SetStateAction<Record<string, any> | undefined>
  >;
  updateEditorStatus: (newState: Partial<IEditorStatus>) => void;
  onAskKoncierge: (text: string) => void;
  onEditorTabChange: (tab: string) => void;
  updateActiveBlock: React.Dispatch<React.SetStateAction<any>>;
  onEditorValueChange: (v: any, workerId: string | null) => void;
  updateActiveOutputTab: React.Dispatch<React.SetStateAction<TabKeys>>;
  toggleFold: (index: number) => void;
  expandDocumentPreview: (open: boolean) => void;
}

export interface IEditorContext {
  source: EditorSourceType;
  loading: boolean;
  editorStatus: IEditorStatus;
  debugMode: boolean;
  editorTabs: IEditorTabs[];
  editorContent: string;
  editorBlocks: Descendant[];
  sentences: DocumentLineInput[];
  subDocumentToken: string;
  replacerObj: Record<string, any> | undefined;
  activeBlock: ISentence | null;
  activeOutputTab: TabKeys;
  editorValue: Descendant[];
  collapsedCodeBlocks: CodeBlock[];
  editorErrors: Record<string, string>;
  actions: IEditorContextActions;
  widgets: {
    data: IWidgetData | null;
    setWidgetData: React.Dispatch<React.SetStateAction<IWidgetData | null>>;
  };
}

export interface EditorProviderProps {
  children: React.ReactNode;
  source: EditorSourceType;
  worker: Worker | null;
  defaultDocumentToken?: string;
}

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: EditorBlock;
    Text: ISentence;
  }
}
