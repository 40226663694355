import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { FetchResult } from '@apollo/client';
import {
  GetRequestQuery,
  GetRequestQueryVariables,
  Request
} from '../../generated/API';
import { AsyncThunkConfig, RootState } from '../AppStore';
import Queries from '../../graphql/Queries';
import { getWorkerDependenciesV2 } from './worker';

export const getRequest = createAsyncThunk<
  FetchResult<GetRequestQuery>,
  GetRequestQueryVariables,
  AsyncThunkConfig
>('request/fetch', async ({ id }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.Request(),
    variables: {
      id
    }
  })
);

export const RequestAdapter = createEntityAdapter<Request>();

export const RequestSlice = createSlice({
  name: 'request',
  initialState: RequestAdapter.getInitialState({
    loading: false,
    error: undefined,
    nextToken: undefined
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWorkerDependenciesV2.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getWorkerDependenciesV2.fulfilled, (state, { payload }) => {
      if (payload.data?.getWorker?.requests) {
        const { requests } = payload.data.getWorker;
        RequestAdapter.upsertMany(state, (requests as any).items || []);
        state.loading = false;
      }
    });

    builder.addCase(getWorkerDependenciesV2.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.loading = false;
    });

    builder.addCase(getRequest.fulfilled, (state, { payload }) => {
      if (payload.data?.getRequest) {
        state.loading = false;
        RequestAdapter.setOne(state, payload.data.getRequest as Request);
      }
    });
  }
});

export const requestSelectors = RequestAdapter.getSelectors<RootState>(
  (state) => state.request
);

export const requestQuerySelector = (state: RootState) => {
  return {
    requests: requestSelectors.selectAll(state)
  };
};

export default RequestSlice.reducer;
