// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.playground-header {
  margin-top: 42px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f0f2f5;
  padding: 10px;
  position: sticky;
}
.playground-header .header-actions {
  color: #6f787e;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.playground-header .header-actions .department-selector {
  margin-bottom: 0;
}
.playground-header .department-selector-v3 {
  margin-bottom: 0px;
}
.playground-header .playground-name-text {
  padding: 1px 4px;
  text-align: center;
  border: none;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  max-width: 100%;
}
.playground-header .playground-name-text:hover {
  border: 1px solid #ccc;
}
.playground-header .playground-name-text:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.playground-header .playground-name-text-editable {
  border: 1px solid #444 !important;
}
.playground-header-message {
  display: inline-flex;
}
.playground-header-message .message-body {
  display: flex;
  padding: 2px;
}
.playground-header-message .message-body.message-content {
  align-self: flex-start;
}
.playground-header-message .message-body .close-button {
  align-self: flex-end;
  padding-left: 8px;
}
.playground-header-message .message-body .close-button .anticon-close {
  color: rgba(0, 0, 0, 0.88);
}
`, "",{"version":3,"sources":["webpack://./src/details/runs/FlowRunHeaderV2.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,gCAAA;EACA,aAAA;EACA,gBAAA;AADF;AAGE;EACE,cAAA;EACA,eAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAKI;EACE,gBAAA;AAHN;AAfA;EAuBI,kBAAA;AALJ;AAlBA;EA2BI,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,oBAAA;EACA,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;AANJ;AAOI;EACE,sBAAA;AALN;AAOI;EACE,YAAA;EACA,aAAA;EACA,gBAAA;AALN;AArCA;EA8CI,iCAAA;AANJ;AAUA;EACE,oBAAA;AARF;AAUE;EACE,aAAA;EACA,YAAA;AARJ;AAUI;EACE,sBAAA;AARN;AAWI;EACE,oBAAA;EACA,iBAAA;AATN;AAWM;EACE,0BAAA;AATR","sourcesContent":["@import '@css/variables.less';\n\n.playground-header {\n  margin-top: 42px;\n  display: flex;\n  align-items: center;\n  border-bottom: 2px solid #f0f2f5;\n  padding: 10px;\n  position: sticky;\n\n  & .header-actions {\n    color: #6f787e;\n    font-size: 12px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  & .header-actions {\n    & .department-selector {\n      margin-bottom: 0;\n    }\n  }\n\n  .department-selector-v3 {\n    margin-bottom: 0px;\n  }\n\n  .playground-name-text {\n    padding: 1px 4px;\n    text-align: center;\n    border: none;\n    margin-bottom: 0.5em;\n    color: rgba(0, 0, 0, 0.88);\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 1.4;\n    max-width: 100%;\n    &:hover {\n      border: 1px solid #ccc;\n    }\n    &:focus {\n      border: none;\n      outline: none;\n      box-shadow: none;\n    }\n  }\n  .playground-name-text-editable {\n    border: 1px solid #444 !important;\n  }\n}\n\n.playground-header-message {\n  display: inline-flex;\n\n  & .message-body {\n    display: flex;\n    padding: 2px;\n\n    &.message-content {\n      align-self: flex-start;\n    }\n\n    & .close-button {\n      align-self: flex-end;\n      padding-left: 8px;\n\n      & .anticon-close {\n        color: rgba(0, 0, 0, 0.88);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
