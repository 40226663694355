import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useRef } from 'react';
import './ImageArea.less';

interface IImageAreaProps {
  imgURL: string;
  boundingBox: {
    width: number; // Expressed as a floating point percentage from 0-100.
    height: number; // Expressed as a floating point percentage from 0-100.
    x: number; // Expressed as a floating point percentage from 0-100.
    y: number; // Expressed as a floating point percentage from 0-100.
  };
  // Optional - Fit the image into this destination size.
  destination?: {
    width?: number;
    height?: number;
  };
  onClick?: (e: any) => void;
  loading?: boolean;
}

function ImageArea(props: IImageAreaProps) {
  const { imgURL, boundingBox, destination, onClick, loading } = props;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const imageObj = new Image();

    imageObj.onload = function anonymous() {
      const canvas = canvasRef.current!;

      const sourceX = (boundingBox.x / 100.0) * imageObj.width;
      const sourceY = (boundingBox.y / 100.0) * imageObj.height;
      const sourceWidth = (boundingBox.width / 100.0) * imageObj.width;
      const sourceHeight = (boundingBox.height / 100.0) * imageObj.height;

      // console.log('>>> Image Size', imageObj.width, imageObj.height);
      // console.log(
      //   '>>> Source Rect',
      //   sourceX,
      //   sourceY,
      //   sourceWidth,
      //   sourceHeight
      // );

      const destX = 0;
      const destY = 0;
      const destWidth = destination?.width || sourceWidth;
      const destHeight = destination?.height || sourceHeight;

      // This needs to be done first, and has to be a whole number
      canvas.width = destWidth;
      canvas.height = destHeight;

      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      context.drawImage(
        imageObj,
        sourceX,
        sourceY,
        sourceWidth,
        sourceHeight,
        destX,
        destY,
        destWidth,
        destHeight
      );
    };

    imageObj.src = imgURL;

    imageObj.style.objectFit = 'contain';
  }, [imgURL, boundingBox]);

  // Render the UI
  return (
    <div
      className="image-area"
      onClick={onClick}
      style={{
        maxWidth: destination?.width || 'none',
        maxHeight: destination?.height || 'none'
      }}
    >
      {loading ? <LoadingOutlined /> : <canvas ref={canvasRef} />}
    </div>
  );
}

export default ImageArea;
