import { Fact } from '@/generated/API';
import isEmpty from 'lodash/isEmpty';
import FormattingUtil from './FormattingUtil';

export const getFactType = (fact?: Fact) => {
  const parsedValue = FormattingUtil.parseBrainValue(fact?.value || '');
  const firstName = fact?.names?.[0];
  const value = parsedValue ?? firstName;
  return FormattingUtil.getFormattedAnswerV2({
    answer: value,
    firstName
  });
};

export const isConceptsEmpty = (concepts: any) => {
  if (concepts === '[]' || concepts === 'null') {
    return true;
  }
  if (isEmpty(concepts)) {
    return true;
  }
  return false;
};
