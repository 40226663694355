import { gql } from '@apollo/client';

export const LIST_DEPARTMENT_COLLABORATORS = gql(`
  query ListDepartmentCollaborators(
    $departmentId: String!
    $role: AccessControlRole
    $limit: Int = 1000
    $nextToken: String
  ) {
    listDepartmentCollaborators(
      departmentId: $departmentId
      role: $role
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        role
      }
      nextToken
    }
  }
`);
