import { useMutation } from '@apollo/client';
import {
  IQuestionValues,
  QuestionInputType
} from '../components/request/interface';
import {
  CreateLearnedAnswerMutation,
  CreateLearnedAnswerMutationVariables,
  CreateRequestAnswerMutation,
  CreateRequestAnswerMutationVariables,
  Request,
  RequestQuestion,
  ValueNotKnownQuestion
} from '../generated/API';
import { prepareAnswerActionInput, prepareAnswerInput } from '../utils/request';
import { CREATE_REQUEST_ANSWER } from '../graphql/request';
import { CREATE_LEARNED_ANSWER } from '../graphql/learning';
import { useAppSelector } from '../stores/hooks';
import { departmentQuerySelector } from '../stores/slices/department';

function useRequestMethods(request: Request) {
  const { department } = useAppSelector(departmentQuerySelector);

  const [createRequestAnswer, createRequestAnswerProps] =
    useMutation<CreateRequestAnswerMutation>(CREATE_REQUEST_ANSWER);
  const [createLearnedAnswer, createLearnedAnswerProps] =
    useMutation<CreateLearnedAnswerMutation>(CREATE_LEARNED_ANSWER);

  const answerQuestion = async (
    question: RequestQuestion,
    values: IQuestionValues,
    learn: boolean
  ) => {
    if (!request) {
      return Promise.reject(new Error('Request is not defined'));
    }

    const variables: CreateRequestAnswerMutationVariables = {
      input: prepareAnswerInput(request, question, values)
    };

    return createRequestAnswer({
      variables
    }).then((answerResponse) => {
      const createdAnswer = answerResponse.data?.createRequestAnswer;
      if (createdAnswer) {
        if (learn && createdAnswer.id && department) {
          const action = prepareAnswerActionInput(values);

          // KOG-2227
          if (values.inputType === QuestionInputType.VALUE_CHOICE) {
            const valueNotKnownQuestion =
              question as unknown as ValueNotKnownQuestion;
            action.useLiteralValueAction!.choices =
              valueNotKnownQuestion.choices;
          }

          const variables: CreateLearnedAnswerMutationVariables = {
            input: {
              departmentId: department.id,
              originalAnswerId: createdAnswer.id,
              procedureId: request.procedureId || null,
              action,
              filter: {
                questionType: question.__typename,
                lexicalPath: (question as any).lexicalPath || null,
                stepPath: request.stepPath
              }
            }
          };
          createLearnedAnswer({
            variables
          });
        }
      }
    });
  };

  return {
    answerQuestion,
    answerLoading:
      createRequestAnswerProps.loading || createLearnedAnswerProps.loading
  };
}

export default useRequestMethods;
