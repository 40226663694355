import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { getGraphqlClient } from '@/utils/GraphqlClient';

interface IProps {
  children: JSX.Element;
}

function Apollo(props: IProps) {
  const { children } = props;

  useEffect(() => {
    (window as any).handleSessionExpiry = () => {
      Modal.warning({
        title: 'Please sign in again',
        icon: <ExclamationCircleFilled />,
        content:
          'Your session has expired or you signed out in another window. Please sign in again.',
        okText: 'Sign In',
        onOk() {
          // This reload is acceptable since the end goal is to force
          // login.
          window.location.reload();
        },
        cancelText: null,
        closable: false,
        autoFocusButton: 'ok'
      });
    };
  }, []);

  const client = getGraphqlClient();

  return <ApolloProvider client={client as any}>{children}</ApolloProvider>;
}

export default Apollo;
