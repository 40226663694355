// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContextualLearningPreview-module__generatedHeader__byD9y {
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #f0f0f0;
  color: #595959;
  padding: 12px;
}
.ContextualLearningPreview-module__generatedContent__ugOwU {
  padding: 12px;
}
.ContextualLearningPreview-module__generatedContextHeader__Yh8TK {
  display: flex;
  align-items: center;
  color: #00000073;
}
.ContextualLearningPreview-module__generatedContextHeader__Yh8TK .first-child {
  font-weight: 600;
  margin-right: 8px;
}
.ContextualLearningPreview-module__generatedContextHeader__Yh8TK .second-child {
  margin-right: 8px;
  font-size: 12px;
}
.ContextualLearningPreview-module__generatedContextFilname__lKQ5o {
  color: #661eff;
}
.ContextualLearningPreview-module__generatedMethod__eviN9 {
  margin-top: 20px;
}
.ContextualLearningPreview-module__generatedMethodHeader__fFHoq {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #00000073;
}
.ContextualLearningPreview-module__generatedMethodHeader__fFHoq .first-child {
  font-size: 14px;
  font-weight: 600;
}
.ContextualLearningPreview-module__generatedMethodHeader__fFHoq .anticon {
  cursor: pointer;
}
.ContextualLearningPreview-module__generatedMethodText__pSLZL {
  margin-top: 4px;
  max-height: 150px;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/request/LearningMethod/ContextualLearningPreview.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,gCAAA;EACA,cAAA;EACA,aAAA;AACF;AAEA;EACE,aAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAFA;EAMI,gBAAA;EACA,iBAAA;AADJ;AANA;EAWI,iBAAA;EACA,eAAA;AAFJ;AAcA;EACE,cAAA;AAZF;AAeA;EACE,gBAAA;AAbF;AAgBA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AAdF;AAUA;EAOI,eAAA;EACA,gBAAA;AAdJ;AAMA;EAYI,eAAA;AAfJ;AAmBA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AAjBF","sourcesContent":[".generatedHeader {\n  font-size: 14px;\n  font-weight: 600;\n  border-bottom: 1px solid #f0f0f0;\n  color: #595959;\n  padding: 12px;\n}\n\n.generatedContent {\n  padding: 12px;\n}\n\n.generatedContextHeader {\n  display: flex;\n  align-items: center;\n  color: #00000073;\n\n  :global(.first-child) {\n    font-weight: 600;\n    margin-right: 8px;\n  }\n\n  :global(.second-child) {\n    margin-right: 8px;\n    font-size: 12px;\n  }\n}\n\n// TODO: V2\n// .generatedContextImage {\n//   width: 82px;\n//   height: 108px;\n//   background: #d9d9d9;\n//   margin-top: 4px;\n// }\n\n.generatedContextFilname {\n  color: #661eff;\n}\n\n.generatedMethod {\n  margin-top: 20px;\n}\n\n.generatedMethodHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: #00000073;\n\n  :global(.first-child) {\n    font-size: 14px;\n    font-weight: 600;\n  }\n\n  :global(.anticon) {\n    cursor: pointer;\n  }\n}\n\n.generatedMethodText {\n  margin-top: 4px;\n  max-height: 150px;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generatedHeader": `ContextualLearningPreview-module__generatedHeader__byD9y`,
	"generatedContent": `ContextualLearningPreview-module__generatedContent__ugOwU`,
	"generatedContextHeader": `ContextualLearningPreview-module__generatedContextHeader__Yh8TK`,
	"generatedContextFilname": `ContextualLearningPreview-module__generatedContextFilname__lKQ5o`,
	"generatedMethod": `ContextualLearningPreview-module__generatedMethod__eviN9`,
	"generatedMethodHeader": `ContextualLearningPreview-module__generatedMethodHeader__fFHoq`,
	"generatedMethodText": `ContextualLearningPreview-module__generatedMethodText__pSLZL`
};
export default ___CSS_LOADER_EXPORT___;
