/* eslint-disable no-nested-ternary */
import {
  GetIntegrationCredentialFormQuery,
  Integration,
  LearnBooksMutation
} from '@/generated/API';
import { Button, Divider, Modal, Spin, Typography, message } from 'antd';
import Search from 'antd/es/input/Search';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  GET_INTEGRATION_CREDENTIAL_FORM,
  LIST_INTEGRATION_BY_DEPARTMENT
} from '@/graphql/queries/books';
import { useAppSelector } from '@/stores/hooks';
import { departmentQuerySelector } from '@/stores/slices/department';
import { useLazyQuery, useMutation } from '@apollo/client';
import { LEARN_BOOKS } from '@/graphql/mutations/books';
import classnames from 'classnames';
import { immutableSort } from '@/utils/fp-utils';
import AppUtil from '@/utils/AppUtil';
import IconBookCard from '../IconBookCard/IconBookCard';
import styles from './IntegrateBookPopup.module.less';
import IntegrationForm from '../IntegrationForm/IntegrationForm';

interface IntegrateBookPopupProps {
  books: Integration[];
  onClose: () => void;
  onIntegration: (book: Integration) => void;
  credentialData?: any;
  currentBook?: Integration;
  workerId?: string;
  procedureId?: string;
}

function IntegrateBookPopup(props: IntegrateBookPopupProps) {
  const {
    books,
    onClose,
    currentBook,
    onIntegration,
    credentialData,
    workerId,
    procedureId
  } = props;

  // This will be required in future when we will bring search and filter functionality within the IntegrateBookPopup
  const [filteredBooks, setFilteredBooks] = useState<Integration[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [activeBookId, setActiveBookId] = useState<string>('');
  const { department } = useAppSelector(departmentQuerySelector);
  const [formData, setFormData] = useState<Record<string, any> | undefined>(
    undefined
  );
  const [getIntegrationFrom, { loading, data }] =
    useLazyQuery<GetIntegrationCredentialFormQuery>(
      GET_INTEGRATION_CREDENTIAL_FORM
    );
  const [learnBook, { loading: learnBookLoading }] =
    useMutation<LearnBooksMutation>(LEARN_BOOKS);

  useEffect(() => {
    if (searchText !== '') {
      const searchedBooks = AppUtil.listFuzzySearch(
        books,
        ['name'],
        searchText
      );
      setFilteredBooks(searchedBooks);
    } else {
      setFilteredBooks(books);
    }
  }, [books, searchText]);

  const handleBookClick = (key: string) => {
    setActiveBookId(key);
  };

  useEffect(() => {
    if (currentBook) {
      setActiveBookId(currentBook.id);
    }
  }, [currentBook]);

  useEffect(() => {
    if (!isEmpty(activeBookId)) {
      const foundBook = filteredBooks.find((item) => item.id === activeBookId);
      if (!isEmpty(foundBook?.credentialMetadata)) {
        getIntegrationFrom({
          variables: {
            integrationId: activeBookId,
            departmentId: department.id
          }
        });
      }
    }
  }, [activeBookId]);

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  const noop = () => {};

  const handleFormClose = () => {
    setActiveBookId('');
    setFormData(undefined);
    if (!isEmpty(credentialData) || !isEmpty(currentBook)) {
      onClose();
    }
  };

  const handleClose = () => {
    setActiveBookId('');
    setFormData(undefined);
    onClose();
  };

  const handleIntegration = (newBook: string) => {
    setActiveBookId('');
    onIntegration(books.find((item) => item.id === newBook)!);
  };

  const handleLearnBookClick = (integrationId: string) => {
    learnBook({
      variables: {
        ids: [integrationId],
        departmentId: department.id
      },
      onCompleted: () => {
        message.success('Book learned successfully');
        handleIntegration(integrationId);
      },
      refetchQueries: [
        {
          query: LIST_INTEGRATION_BY_DEPARTMENT,
          variables: {
            departmentId: department.id
          }
        }
      ],
      onError: (error) => {
        message.error(
          `Unable to learn book,
          ${error.message}`
        );
      }
    });
  };

  const sortedFilteredBooks = immutableSort(
    filteredBooks,
    (a: any, b: any) =>
      Number(a.url.endsWith('empty')) - Number(b.url.endsWith('empty'))
  );

  const onSearch = (searchText: string) => {
    setSearchText(searchText);
  };

  return (
    <Modal
      open
      onCancel={handleClose}
      destroyOnClose
      mask
      footer={null}
      centered
      className={styles.integrtationPopup}
    >
      <div className={styles.topContainer}>
        <Typography.Text className={styles.header}>
          {isEmpty(credentialData) ? `Add New Book` : `Update Book`}
        </Typography.Text>

        {isEmpty(credentialData) && isEmpty(activeBookId) ? (
          <>
            <Divider />
            <Search
              placeholder="Search"
              onSearch={onSearch}
              defaultValue={searchText}
              className={styles.searchBox}
              allowClear
            />
          </>
        ) : null}
      </div>

      {isEmpty(activeBookId) && (
        <div className={styles.bottomContainer}>
          {sortedFilteredBooks?.map((item: Integration) => {
            return (
              <IconBookCard
                key={item.id}
                book={item}
                onClick={handleBookClick}
              />
            );
          })}
        </div>
      )}

      {!isEmpty(activeBookId) && filteredBooks.length > 0 && (
        <div className={styles.integrationContainer}>
          <div className={styles.iconContainer}>
            <IconBookCard
              book={filteredBooks.find((item) => item.id === activeBookId)!}
              onClick={noop}
            />
          </div>

          <div
            className={classnames(styles.formContainer, {
              [styles.noFormContainer]: !loading
            })}
          >
            <Spin spinning={loading}>
              {formData && formData.getIntegrationCredentialForm ? (
                <IntegrationForm
                  fields={formData.getIntegrationCredentialForm.fields!}
                  onclose={handleFormClose}
                  integrationId={activeBookId}
                  onIntegration={handleIntegration}
                  credentialData={credentialData}
                  workerId={workerId}
                  procedureId={procedureId}
                />
              ) : !loading ? (
                <div className={styles.submitContainer}>
                  <Button
                    type="default"
                    onClick={() => {
                      setActiveBookId('');
                      setFormData(undefined);
                    }}
                    className={styles.cancelBtn}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleLearnBookClick(activeBookId)}
                    loading={learnBookLoading}
                    data-cy="learn-book-submit"
                  >
                    Learn
                  </Button>
                </div>
              ) : null}
            </Spin>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default IntegrateBookPopup;
