import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/stores/AppStore';
import { IAnnotation } from '../../components/document-annotations/AnnotationsCanvas';
import AppConstants from '../../utils/AppConstants';

export interface ISettings {
  annotations: IAnnotation[];
  selectedAnnotationId: string | null;
}

const defaultAnnotation: IAnnotation = {
  id: AppConstants.DOC_EXCEPTION.DEFAULT_ANNOTATION_KEY,
  height: 0,
  width: 0,
  x: 0,
  y: 0,
  pageNumber: 1,
  textContent: '',
  allowDelete: false,
  loading: false
};

const initialState: ISettings = {
  annotations: [defaultAnnotation],
  selectedAnnotationId: null
};

export const annotationsSlice = createSlice({
  name: 'annotations',
  initialState,
  reducers: {
    updateAnnotations: (state, action: PayloadAction<IAnnotation[]>) => {
      state.annotations = [...action.payload];
      if (state.annotations.length === 0) {
        state.annotations = [defaultAnnotation];
      }
    },
    deleteAnnotation: (state, action: PayloadAction<string>) => {
      state.annotations = state.annotations.filter(
        (annotation) => annotation.id !== action.payload
      );

      if (state.annotations.length === 0) {
        state.annotations = [defaultAnnotation];
      }
    },
    updateAnnotation: (
      state,
      action: PayloadAction<{ id: string; changes: Partial<IAnnotation> }>
    ) => {
      state.annotations = state.annotations.map((annotation) =>
        annotation.id === action.payload.id
          ? { ...annotation, ...action.payload.changes }
          : annotation
      );
    },
    selectAnnotation: (state, action: PayloadAction<string | null>) => {
      state.selectedAnnotationId = action.payload;
    },
    resetAnnotations: (state) => {
      state.annotations = [defaultAnnotation];
    }
  }
});

export const {
  updateAnnotations,
  deleteAnnotation,
  updateAnnotation,
  selectAnnotation,
  resetAnnotations
} = annotationsSlice.actions;

export const annotationSelector = (state: RootState) => state.annotation;

export default annotationsSlice.reducer;
