import React, { forwardRef } from 'react';
import { Empty, Tabs, Typography } from 'antd';
import classNames from 'classnames';
import { QuestionCircleFilled } from '@ant-design/icons';
import ResultIcon from '@/components/icons/ResultIcon';
import FactsIcon from '@/components/icons/FactsIcon';
import LineResult from '@/details/runs/items/LineResult';
import Resizable from '@/components/Resizable/Resizable';
import LineFacts from '@/details/runs/items/LineFacts';
import styles from './OutputPanel.module.less';
import { ISentence } from '../Editor/editorInterface';
import RequestPanel from '../RequestPanel/RequestPanel';
import { isConceptsEmpty } from '../../../../utils/facts';
import { useKogEditorContext } from '../Editor/EditorProvider';

const { TabPane } = Tabs;

export enum TabKeys {
  Upload = '1',
  Results = '2',
  Facts = '3',
  Requests = '4'
}

interface OutputPanelProps {
  activeBlock: ISentence | null | any;
  expandDocumentPreview: (open: boolean) => void;
  isDebugMode: boolean;
  onPanelSizeChange: (size: any) => void;
}

const OutputPanel = forwardRef((props: OutputPanelProps, ref: any) => {
  const { activeBlock, expandDocumentPreview, isDebugMode, onPanelSizeChange } =
    props;
  const { activeOutputTab, actions } = useKogEditorContext();

  const handleTabChange = (key: any) => {
    actions.updateActiveOutputTab(key);
  };

  const maxWidth = Math.floor(window.visualViewport?.width || 0);
  const height = Math.floor((window.visualViewport?.height || 0) - 80);
  return (
    <Resizable
      initWidth={400}
      initHeight={height}
      minWidth={400}
      minHeight={height}
      maxWidth={maxWidth}
      maxHeight={height}
      onSizeChange={onPanelSizeChange}
    >
      <div className={styles.outputHeader} ref={ref} data-cy="output-panel">
        <Tabs
          activeKey={activeOutputTab}
          defaultActiveKey={TabKeys.Results}
          onChange={handleTabChange}
        >
          <TabPane
            tab={
              <div
                className={classNames('display-flex', 'align-center', {
                  'low-opacity':
                    !activeBlock?.answer && !activeBlock?.editorAnswers
                })}
              >
                <ResultIcon style={{ width: 16, height: 18 }} />
                <Typography.Text>Results</Typography.Text>
              </div>
            }
            key={TabKeys.Results}
            disabled={!activeBlock?.answer && !activeBlock?.editorAnswers}
          >
            <div className={classNames([styles.outputContent, styles.results])}>
              <LineResult
                step={{
                  ...activeBlock,
                  answer: activeBlock?.editorAnswers
                    ? activeBlock.editorAnswers?.[activeBlock?.token]
                    : activeBlock?.answer
                }}
                expandDocumentPreview={expandDocumentPreview}
                isDebugMode={isDebugMode}
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <div
                data-cy="outputpanel-facts"
                className={classNames('display-flex', 'align-center', {
                  'low-opacity': isConceptsEmpty(
                    activeBlock?.editorConcepts
                      ? activeBlock.editorConcepts?.[activeBlock?.token]
                      : activeBlock?.concepts
                  )
                })}
              >
                <FactsIcon style={{ width: 16, height: 18 }} />
                <Typography.Text>Facts</Typography.Text>
              </div>
            }
            disabled={isConceptsEmpty(
              activeBlock?.editorConcepts
                ? activeBlock.editorConcepts?.[activeBlock?.token]
                : activeBlock?.concepts
            )}
            key={TabKeys.Facts}
          >
            <div className={styles.outputContent}>
              {!isConceptsEmpty(
                activeBlock?.editorConcepts
                  ? activeBlock.editorConcepts?.[activeBlock?.token]
                  : activeBlock?.concepts
              ) ? (
                <LineFacts
                  step={{
                    ...activeBlock,
                    concepts: activeBlock?.editorConcepts
                      ? activeBlock.editorConcepts?.[activeBlock?.token]
                      : activeBlock?.concepts
                  }}
                  expandDocumentPreview={expandDocumentPreview}
                  isDebugMode={isDebugMode}
                />
              ) : null}
            </div>
          </TabPane>
          <TabPane
            tab={
              <div
                className={classNames('display-flex', 'align-center', {
                  'low-opacity': activeBlock?.requests?.length <= 0
                })}
              >
                <QuestionCircleFilled
                  style={{
                    height: '16px',
                    width: '16px',
                    color: ` ${
                      activeBlock?.requests?.length > 0 ? '#E9B825' : '#748F9E'
                    }`
                  }}
                />
                <Typography.Text>Questions</Typography.Text>
              </div>
            }
            key={TabKeys.Requests}
            disabled={activeBlock?.requests?.length <= 0}
          >
            <div className={styles.outputContent}>
              {activeBlock?.requests?.length > 0 ||
              activeBlock?.editorRequests ? (
                <RequestPanel sentence={activeBlock} />
              ) : (
                <Empty />
              )}
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Resizable>
  );
});

export default OutputPanel;
