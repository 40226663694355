/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-await-in-loop */
import {
  CloseOutlined,
  CloudDownloadOutlined,
  CopyOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { ApolloError, useMutation } from '@apollo/client';
import i18n from '@utils/i18n';
import { Button, InputRef, Spin, Tooltip, message, notification } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ampTrackEvent } from '@/analytics';
import NewProcedureFromRun from '@/components/NewProcedureFromRun/NewProcedureFromRun';
import WorkerStatus from '@/components/WorkerStatus';
import PlaygroundReloadIcon from '@/components/icons/PlaygroundReloadIcon';
import SidebarIcon from '@/components/icons/SidebarIcon';
import { DUPLICATE_WORKER, UPDATE_WORKER } from '@/graphql/worker';
import { useS3 } from '@/hooks/useS3';
import { useAppDispatch, useAppSelector } from '@/stores/hooks';
import AppUtil from '@/utils/AppUtil';
import routes from '@/utils/routes';
import { AnalyticsEvents } from '@/analytics/events';
import { trackEvent } from '@/tracker';

import DebouncedInput from '@components/DebouncedInput/DebouncedInput';
import {
  DuplicateWorkerMutation,
  UpdateWorkerInput,
  Worker
} from '../../generated/API';
import usePlaygroundV2 from '../../hooks/usePlaygroundV2';
import { useRunCtxV2 } from '../../provider/runv2';
import { departmentQuerySelector } from '../../stores/slices/department';
import { setWorkerId } from '../../stores/slices/run';
import { getWorker } from '../../stores/slices/worker';

import './FlowRunHeaderV2.less';

const i18nt = (s: string, d?: string) => i18n.t('FlowRunDetails', s, d);

interface IRetryRunProps {
  workerId: string;
  isPlayground: boolean;
}

const SuccessMessage = () => (
  <div className="playground-header-message">
    <div className="message-body">
      <span className="message-content">Playground renamed successfully</span>
      &nbsp;
      <span className="close-button">
        <CloseOutlined />
      </span>
    </div>
  </div>
);

const FailureMessage = () => (
  <div className="playground-header-message">
    <div className="message-body">
      <span className="message-content">Unable to rename playground</span>
      &nbsp;
      <span className="close-button">
        <CloseOutlined />
      </span>
    </div>
  </div>
);

export function RetryRun({ workerId, isPlayground }: IRetryRunProps) {
  const [state, setState] = React.useState(false);
  const { department } = useAppSelector(departmentQuerySelector);
  const navigate = useNavigate();

  const onMutationError = (error: ApolloError) => {
    setState(false);
    notification.error({
      message: 'Error',
      description: error.message
    });
  };

  const [rerun, rerunResults] = useMutation<DuplicateWorkerMutation>(
    DUPLICATE_WORKER,
    {
      onError: onMutationError
    }
  );

  const duplicateWorker = rerunResults.data?.duplicateWorker;

  const getProcedureRunUrl = (worker: Worker) =>
    routes.procedure.getProcedureRunPageUrl(
      worker.departmentId,
      worker.procedureId!,
      worker.id
    );

  const initiateRun = () => {
    ampTrackEvent('ClickRunAgainButton');
    setState(true);
    rerun({
      variables: {
        workerId,
        departmentId: department.id,
        runWorker: !isPlayground
      }
    }).then(() => {
      setState(false);
    });
  };

  useEffect(() => {
    if (duplicateWorker) {
      if (isPlayground) {
        navigate(
          routes.playground.getPlaygroundPageUrl(
            department.id,
            duplicateWorker.id
          ),
          {
            replace: true
          }
        );
      } else {
        navigate(getProcedureRunUrl(duplicateWorker as Worker), {
          replace: true
        });
      }
    }
  }, [duplicateWorker]);

  const tooltipText = isPlayground
    ? 'Duplicate Playground'
    : 'Run Again (with same inputs)';

  return (
    <Tooltip title={tooltipText} placement="topRight">
      <Button
        type="text"
        data-cy="retry-run"
        shape="default"
        className="display-flex align-center justify-center"
        icon={
          isPlayground ? (
            <CopyOutlined
              style={{ fontSize: '20px', color: '#7A8E9D' }}
              spin={state}
              className="reload"
            />
          ) : (
            <PlaygroundReloadIcon spin={state} className="reload" />
          )
        }
        onClick={() => initiateRun()}
      />
    </Tooltip>
  );
}

interface IFlowRunHeaderProps {
  className?: string;
  historyPanelOpen?: boolean;
  setHistoryPanelOpen?: (open: boolean) => void;
}

function FlowRunHeaderV2(props: IFlowRunHeaderProps) {
  const { className, historyPanelOpen, setHistoryPanelOpen } = props;
  const { department } = useAppSelector(departmentQuerySelector);
  const { worker, procedure, error } = useRunCtxV2();
  const dispatch = useAppDispatch();
  const s3 = useS3();
  const navigate = useNavigate();
  const { spawnWorker } = usePlaygroundV2();
  const isDocumentModelEnabled = AppUtil.isDocumentModel(worker);

  const [newPlaygroundLoading, toggleNewPlaygroundLoading] = useState(false);
  const [name, setName] = useState<string>(worker?.name || '');
  const [nameEditable, setNameEditable] = useState(false);

  const inputRef = useRef<InputRef | null>(null);

  const [updateWorkerName, { loading: updateNameLoading }] = useMutation<
    Worker,
    UpdateWorkerInput
  >(UPDATE_WORKER);

  useEffect(() => {
    if (worker?.name) {
      setName(worker.name);
    }
  }, [worker?.name]);

  const handleHistoryButtonClick = () => {
    if (setHistoryPanelOpen) {
      setHistoryPanelOpen(!historyPanelOpen);
      trackEvent(AnalyticsEvents.PLAYGROUND.PGHistoryButtonClose, {
        playgroundId: worker?.id
      });
    } else {
      trackEvent(AnalyticsEvents.PLAYGROUND.PGHistoryButtonOpen, {
        playgroundId: worker?.id
      });
    }
  };

  const handleCreateNewWorker = () => {
    trackEvent(AnalyticsEvents.PLAYGROUND.NewPGHeader);
    toggleNewPlaygroundLoading(true);
    spawnWorker('', department)
      .then((newWorker) => {
        if (newWorker) {
          dispatch(setWorkerId(newWorker.id));
          const url = routes.playground.getPlaygroundPageUrl(
            department.id,
            newWorker.id
          );
          navigate(url, {
            replace: true
          });
        }
      })
      .catch((e) => {
        console.error(e);
        message.error('Failed to create new playground');
      })
      .finally(() => {
        toggleNewPlaygroundLoading(false);
      });
  };

  const handler = useCallback(
    (name: string, workerId: string) => {
      updateWorkerName({
        variables: {
          id: workerId,
          name
        },
        onError: (_err) => {
          message.error({
            content: <FailureMessage />,
            onClick: () => message.destroy()
          });
        },
        update: (_cache) => {
          message.success({
            content: <SuccessMessage />,
            onClick: () => message.destroy()
          });
          dispatch(
            getWorker({
              id: workerId
            })
          ).finally(() => {
            setNameEditable(false);
          });
        }
      });
    },
    [dispatch, updateWorkerName]
  );

  const handleNameClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setNameEditable(true);
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  return (
    <div className={classNames('playground-header', className)}>
      <div className="display-flex align-center" style={{ flexBasis: '30%' }}>
        {!procedure?.id && (
          <Tooltip
            title={
              historyPanelOpen
                ? i18nt('hidePlaygroundHistory', ' Hide Playground history')
                : i18nt('showPlaygroundHistory', 'Show Playground history')
            }
          >
            <Button
              type="link"
              data-cy="playground-history-btn"
              className="playground-history display-flex align-center"
              style={{ marginRight: '20px' }}
              icon={<SidebarIcon style={{ width: '18px', height: '18px' }} />}
              onClick={handleHistoryButtonClick}
            />
          </Tooltip>
        )}

        {worker?.id && !isDocumentModelEnabled ? (
          <WorkerStatus worker={worker} />
        ) : (
          <div />
        )}
      </div>

      <div
        className="display-flex align-center justify-center"
        style={{
          flexBasis: '40%'
        }}
      >
        <Spin spinning={updateNameLoading}>
          <DebouncedInput
            ref={inputRef}
            waitTime={1000}
            className={classNames('playground-name-text', {
              'playground-name-text-editable': nameEditable
            })}
            readOnly={!nameEditable}
            value={name}
            isDisabled={updateNameLoading}
            onChange={(name: string) => handler(name, worker?.id)}
            onBlur={() => setNameEditable(false)}
            onClick={handleNameClick}
          />
        </Spin>
      </div>

      <div
        className="header-actions"
        style={{ flexBasis: '30%', justifyContent: 'flex-end' }}
      >
        {worker?.attachments?.length && (
          <Tooltip title="Download all attachments">
            <Button
              type="link"
              size="small"
              data-cy="download-attachments"
              className="download-attachments"
              icon={<CloudDownloadOutlined className="kog-icon" />}
              onClick={() => {
                worker?.attachments?.forEach((attachment: string) => {
                  s3.openInNewTab(attachment);
                });
              }}
            />
          </Tooltip>
        )}

        {!procedure && !AppUtil.hasPermissionError(error) ? (
          <Tooltip title={i18nt('create_procedure', 'Start new playground')}>
            <Button
              type="primary"
              data-cy="playground-new-run"
              className="playground-new-run"
              icon={<PlusOutlined style={{ width: '18px', height: '18px' }} />}
              onClick={handleCreateNewWorker}
              loading={newPlaygroundLoading}
            >
              New Playground
            </Button>
          </Tooltip>
        ) : null}

        {worker?.id && !isDocumentModelEnabled ? (
          <NewProcedureFromRun workerId={worker.id} procedure={procedure} />
        ) : null}

        {procedure && worker?.id && (
          <RetryRun workerId={worker.id} isPlayground={false} />
        )}
      </div>
    </div>
  );
}

export default FlowRunHeaderV2;
