//
// Copyright (C) - Kognitos, Inc. All rights reserved
// This is the Popup state reducer for the application.
//
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../AppStore';

export interface IPopup {
  currentActivePopups: Record<string, object>;
  currentDetailsInfo: { entityType: any; vars: any } | null;
}

const initialState: IPopup = {
  currentActivePopups: {},
  currentDetailsInfo: null
};

export const appPopupSlice = createSlice({
  name: 'appPopup',
  initialState,
  reducers: {
    showPopup: (
      state,
      action: PayloadAction<{ popupId: string; popupParams: any }>
    ) => {
      state.currentActivePopups[action.payload.popupId] =
        action.payload.popupParams;
    },
    hidePopup: (state, action: PayloadAction<{ popupId: string }>) => {
      delete state.currentActivePopups[action.payload.popupId];
    },
    showDetails: (
      state,
      action: PayloadAction<{ entityType: any; vars: any }>
    ) => {
      state.currentDetailsInfo = action.payload;
    },
    hideDetails: (state) => {
      state.currentDetailsInfo = null;
    }
  }
});

export const { showPopup, hidePopup, showDetails, hideDetails } =
  appPopupSlice.actions;

export const popupSelector = (state: RootState) => state.popups;

export default appPopupSlice.reducer;
