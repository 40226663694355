// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-loading {
  background: #140e56;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.auth-loading > .loader.default {
  color: white;
}
.auth-loading > .loader.default .icon,
.auth-loading > .loader.default .msg {
  font-size: 20px;
}
.auth-loading .loader.default {
  background-color: transparent;
}
.auth-loading.full {
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/components/AuthLoading.less"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AADF;AAIE;EACE,YAAA;AAFJ;AAII;;EAEE,eAAA;AAFN;AAOI;EACE,6BAAA;AALN;AAQE;EACE,6BAAA;AANJ","sourcesContent":["@import '@css/variables.less';\n\n.auth-loading {\n  background: @dark-bg-color;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n\n  // overriding loader CSS\n  & > .loader.default {\n    color: white;\n\n    & .icon,\n    & .msg {\n      font-size: 20px;\n    }\n  }\n\n  .loader {\n    &.default {\n      background-color: transparent;\n    }\n  }\n  &.full {\n    background-color: transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
