// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LearningMethod-module__learningMethod__pTcnL {
  border-radius: 6px;
  color: #484848;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
  cursor: pointer;
}
.LearningMethod-module__learningMethod__pTcnL:hover .learning-method-try-now {
  opacity: 1;
}
.LearningMethod-module__learningMethodHeader__M6hyb {
  border-bottom: 1px solid #d9d9d9;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}
.LearningMethod-module__tryNowButton__pI7as {
  opacity: 0;
}
.LearningMethod-module__learningMethodBody__lcOT6 {
  padding: 12px 0;
  font-family: 'Source Code Pro', monospace;
  font-size: 12px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/request/LearningMethod/LearningMethod.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;AACF;AACE;EAEI,UAAA;AAAN;AAKA;EACE,gCAAA;EACA,cAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AAHF;AAMA;EACE,UAAA;AAJF;AAOA;EACE,eAAA;EACA,yCAAA;EACA,eAAA;EACA,gBAAA;AALF","sourcesContent":[".learningMethod {\n  border-radius: 6px;\n  color: #484848;\n  font-size: 13px;\n  font-weight: 500;\n  line-height: 16px;\n  margin-bottom: 8px;\n  cursor: pointer;\n\n  &:hover {\n    :global(.learning-method-try-now) {\n      opacity: 1;\n    }\n  }\n}\n\n.learningMethodHeader {\n  border-bottom: 1px solid #d9d9d9;\n  padding: 8px 0;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-weight: 700;\n}\n\n.tryNowButton {\n  opacity: 0;\n}\n\n.learningMethodBody {\n  padding: 12px 0;\n  font-family: 'Source Code Pro', monospace;\n  font-size: 12px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"learningMethod": `LearningMethod-module__learningMethod__pTcnL`,
	"learningMethodHeader": `LearningMethod-module__learningMethodHeader__M6hyb`,
	"tryNowButton": `LearningMethod-module__tryNowButton__pI7as`,
	"learningMethodBody": `LearningMethod-module__learningMethodBody__lcOT6`
};
export default ___CSS_LOADER_EXPORT___;
