// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sensitive-text {
  display: flex;
  align-items: center;
}
.sensitive-text .toggle-icon {
  cursor: pointer;
  margin-left: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SensitiveText.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;AADF;AAOE;EACE,eAAA;EACA,gBAAA;AALJ","sourcesContent":["@import '@css/variables.less';\n\n.sensitive-text {\n  display: flex;\n  align-items: center;\n\n  &.active {\n    // color: @color-link-blue;\n  }\n\n  & .toggle-icon {\n    cursor: pointer;\n    margin-left: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
