export const CREATE_COMMAND = /* GraphQL */ `
  mutation CreateCommand($input: CreateCommandInput!, $locked: Boolean) {
    createCommand(input: $input, locked: $locked) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      skippedAt
      processedAt
      failedAt
      workErrors
      text
      owner
      readers
      editors
      name
      description
      subText
      subTextProperties
      subTextLanguage
      procedureId
      contextId
      sentenceId
      debug
      ast
      state
      output
      source
      sourceMetadata
      positionInContext
      isMultilineText
      useAutopilot
    }
  }
`;
