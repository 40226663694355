import React, { ComponentType, ReactNode } from 'react';
import { useReactiveVar } from '@apollo/client';
import { PermissionEnum } from '@/utils/permissionConstants';
import { currentUserDetailsVar } from '@/graphql/cache/user';

interface AccessControlProps {
  requiredPermissions: PermissionEnum[];
  children: ReactNode;
  RestrictedPage?: ComponentType | ReactNode | null;
}

const AccessControl: React.FC<AccessControlProps> = ({
  requiredPermissions,
  RestrictedPage,
  children
}) => {
  const currentUserDetails = useReactiveVar(currentUserDetailsVar);

  // Assume userData is correctly typed and getUserDetails is defined
  const userPermissions = currentUserDetails?.permissions || [];

  // Check if the required permissions are present in the user's permissions
  const hasPermission = requiredPermissions.every((permission) =>
    userPermissions.includes(permission)
  );

  // Determine the type of RestrictedPage and render accordingly
  const RenderRestrictedPage =
    // @ts-ignore
    typeof RestrictedPage === 'function' ? <RestrictedPage /> : RestrictedPage;

  // Render children if the user has required permissions, else render RestrictedPage
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return hasPermission ? <>{children}</> : <>{RenderRestrictedPage}</>;
};

export default React.memo(AccessControl);
