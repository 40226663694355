import { Modal } from 'antd';
import React from 'react';
import DocumentExceptionCanvas from '../components/exception/document-exception/DocumentExceptionCanvas';
import styles from './DocumentExceptionEditorPopup.module.less';
import DocumentExceptionStagingArea from '../components/exception/document-exception/DocumentExceptionStagingArea';

interface IProps {
  document: {
    id: string;
    s3File: string;
  };
  knowledgeId: string;
  title: string;
  fieldName: string;
  allowMultipleAnnotations: boolean;
  onClose: () => void;
  onSubmit?: () => void;
}

function DocumentExceptionEditorPopup(props: IProps) {
  const {
    document,
    knowledgeId,
    title,
    fieldName,
    allowMultipleAnnotations,
    onClose,
    onSubmit
  } = props;

  return (
    <Modal
      open
      title={title}
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
      footer={null}
      className={styles.documentExceptionPopup}
    >
      <div className={styles.wrapper}>
        <div className={styles.canvasWrapper}>
          <DocumentExceptionCanvas
            document={document}
            allowMultipleAnnotations={allowMultipleAnnotations}
            knowledgeId={knowledgeId}
          />
        </div>
        <div className={styles.stagingWrapper}>
          <DocumentExceptionStagingArea
            onCancel={onClose}
            extractionProps={{
              fieldName,
              showDefaultExtraction: true,
              showDelete: true,
              enableOnboarding: true
            }}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
}

export default DocumentExceptionEditorPopup;
