import React from 'react';
import { Button, Modal } from 'antd';
import HTMLViewer from '../components/HTMLViewer';

interface IProps {
  html: string;
  title?: string;
  onClose: () => void;
}

function HTMLViewerPopup(props: IProps) {
  const { html, title = 'HTML Viewer', onClose } = props;

  return (
    <Modal
      title={title}
      open
      onCancel={onClose}
      // width={900}
      className="markdown-editor"
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>
      ]}
    >
      <HTMLViewer
        html={
          html
            .replace(/\\u00a0/gi, ' ') // &nbsp;
            .replace(/\\r|\\n/gi, '&nbsp;') // \r\n
            .replace(/\\"/gi, '') // unnecessary \"
        }
      />
    </Modal>
  );
}

export default HTMLViewerPopup;
