/* eslint-disable react/require-default-props */
import { Input } from 'antd';
import classNames from 'classnames';
import React from 'react';
import './AutoSizeInput.less';

export interface IAutoSizeInputProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  suffix?: React.ReactNode;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
  size?: {
    minRows?: number;
    maxRows?: number;
  };
  bordered?: boolean;
}

const AutoSizeInput = React.forwardRef<any, IAutoSizeInputProps>(
  (props, ref) => {
    const {
      value,
      onChange,
      disabled,
      onKeyDown,
      suffix,
      placeholder,
      autoFocus,
      className,
      size,
      bordered
    } = props;

    return (
      <div className="autosize-input">
        <Input.TextArea
          ref={ref}
          value={value}
          onChange={(e) => onChange?.(e.target.value)}
          className={classNames('textarea', className)}
          disabled={disabled}
          autoSize={size}
          onKeyDown={onKeyDown}
          spellCheck={false}
          autoComplete="off"
          placeholder={placeholder}
          autoFocus={autoFocus}
          bordered={bordered}
        />
        <div className="suffix">{suffix}</div>
      </div>
    );
  }
);

export default AutoSizeInput;
