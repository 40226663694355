// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autosize-input {
  position: relative;
}
.autosize-input > .suffix {
  position: absolute;
  right: 3px;
  top: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AutoSizeInput.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,kBAAA;EACA,UAAA;EACA,QAAA;AACJ","sourcesContent":[".autosize-input {\n  position: relative;\n\n  & > .suffix {\n    position: absolute;\n    right: 3px;\n    top: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
