/* eslint-disable no-underscore-dangle */
//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Application wide utility functions
//

import _cloneDeep from 'lodash/fp/cloneDeep';
import _get from 'lodash/fp/get';
import moment from 'moment';
import emailProviders from 'email-providers/all.json';
import tldExtract from 'tld-extract';
import Fuse from 'fuse.js';
import AppConstants from '@utils/AppConstants';
import { appConfig } from '@/AppConfig';
import dayjs from 'dayjs';
import {
  encodeMarkdownText,
  removeKogLineBreak
} from '@/components/editor/helper';
import { DepartmentFeature } from '@/generated/API';

// TODO: use logging library.
export const logLevelError = 0;
export const logLevelDebug = 1;
export const currentLogLevel = logLevelError;

// clean, and make it tree-sharakeable
const AppUtil = {
  logDebug(...msg) {
    if (currentLogLevel >= logLevelDebug) {
      // eslint-disable-next-line no-console
      console.log(...msg);
    }
  },

  logError(...args) {
    if (currentLogLevel >= logLevelError) {
      // eslint-disable-next-line no-console
      console.error(...args);
    }
  },

  // Validate an email string
  validateEmail(email) {
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  isPersonalEmail(email) {
    const broken = email.split('@');
    const address = `http://${broken[broken.length - 1]}`;
    const { domain } = tldExtract(address);
    return emailProviders.includes(domain);
  },

  // Return a random string of length size_bytes
  getRandomString(sizeBytes) {
    const randomValues = new Uint8Array(sizeBytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues)
      .map((nr) => nr.toString(16).padStart(2, '0'))
      .join('');
  },

  // Returns the type of a user
  getUserType(_user) {
    // Return hard-coded user type for now; eventually, get this based on groups.
    return AppConstants.USER_TYPES.SME;
  },

  // Application wide Code Mirror options
  getCodeMirrorOptions(procedureType) {
    return {
      theme: 'eclipse', // if you change this, update index.js as well
      lineNumbers: true,
      lineWrapping: true,
      gutters: ['CodeMirror-linenumbers'],
      mode: AppConstants.PROCEDURE_TYPE_MAP[procedureType].codeMode
    };
  },

  getFromLocalStorage(key) {
    const prefixedKey = `${AppConstants.STORAGE_KEYS.PREFIX}.${key}`;
    return localStorage.getItem(prefixedKey);
  },

  setInLocalStorage(key, value) {
    const prefixedKey = `${AppConstants.STORAGE_KEYS.PREFIX}.${key}`;
    return localStorage.setItem(prefixedKey, value);
  },

  removeFromLocalStorage(key) {
    const prefixedKey = `${AppConstants.STORAGE_KEYS.PREFIX}.${key}`;
    return localStorage.removeItem(prefixedKey);
  },

  // Safe parse a json string
  // (Do not call JSON.parse directly - hard to debug failures)
  safeParseJSON(jsonString, returnOriginal = false) {
    if (jsonString) {
      try {
        return JSON.parse(jsonString);
      } catch (ex) {
        // AppUtil.logError(`Invalid JSON: ${jsonString}`);
      }
    }
    return returnOriginal ? jsonString : {};
  },

  hasPermissionError(error) {
    if (!error?.graphQLErrors?.length) {
      return false;
    }
    return error.graphQLErrors.some(
      (gErr) =>
        gErr?.errorType === 'PermissionError' ||
        gErr?.message === 'cannot access the department'
    );
  },

  // Basic clone of an object
  simpleClone(jsObject) {
    return AppUtil.safeParseJSON(JSON.stringify(jsObject));
  },

  // CSV converter
  convert2CSV(entities, displayColumns) {
    const ret = entities.map((entity) => {
      const csvLine = [];
      displayColumns.forEach((col) => {
        let csvValue = _get(col.dataIndex, entity);
        // Column level exporter.
        if (col.export) {
          csvValue = col.export(csvValue);
        }

        csvLine.push(`"${csvValue || '-'}"`);
      });
      return csvLine.join(',');
    });
    return ret.join('\n');
  },

  // Key handler - checks for enter key
  onEnter(event, func) {
    const code = event.keyCode ? event.keyCode : event.which;
    if (code === 13) {
      func();
    }
    return false;
  },

  // Update a questions' answer
  updateAnswer(question, answer) {
    if (question.questionType === AppConstants.QUESTION_TYPES.MULTIPLE_SELECT) {
      // eslint-disable-next-line no-param-reassign
      question.answers = answer;
    } else {
      // eslint-disable-next-line no-param-reassign
      question.answers = [answer];
    }
  },

  // Get tree node path
  treeNodePath(treeNode) {
    if (treeNode) {
      const pNodes = treeNode.getPath().splice(1); // remove root node
      return pNodes.map((np) => np.model.id).join('/');
    }
    return '';
  },

  // Is given tree node a leaf
  isLeafTreeNode(treeNode) {
    return treeNode?.children?.length === 0;
  },

  // Tree Model - Get node by id under a portion of the tree
  findNodeById(fromNode, nodeId) {
    let ret;
    AppUtil.TreeWalk(fromNode, (n) => {
      if (n.id === nodeId) {
        ret = n;
      }
    });
    return ret;
  },

  // Render a tree node's value
  renderTreeNodeValue(nodeData) {
    if (nodeData && nodeData.value) {
      if (nodeData.value.join) {
        return nodeData.value.join(', ');
      }
      if (nodeData.type === 'date') {
        return new Date(Number(nodeData.value)).toLocaleDateString();
      }
      return nodeData.value;
    }
    return null;
  },

  // Simple Tree walker
  TreeWalk(node, visitFunc) {
    visitFunc(node);
    if (node.children) {
      node.children.forEach((c) => AppUtil.TreeWalk(c, visitFunc));
    }
  },

  // Insert a tree node after the given treeNode
  insertTreeNode(treeData, treeNode, newNode) {
    // eslint-disable-next-line no-param-reassign
    newNode.parentId = treeNode.parentId;
    const parentTreeNode = AppUtil.findNodeById(treeData, treeNode.parentId);
    const newChildren = [];
    parentTreeNode.children.forEach((c) => {
      newChildren.push(c);
      if (c.id === treeNode.id) {
        newChildren.push(newNode);
      }
    });
    parentTreeNode.children = newChildren;
  },

  // Update a tree node (type/name)
  // if type changes, validations are removed
  updateTreeNode(treeNode, newName, newType) {
    // Successful - so update local one
    const hasTypeChanged = treeNode.type !== newType;
    // eslint-disable-next-line no-param-reassign
    treeNode.name = newName;
    // eslint-disable-next-line no-param-reassign
    treeNode.type = newType;
    if (hasTypeChanged) {
      // eslint-disable-next-line no-param-reassign
      treeNode.validationList = [];
    }
    treeNode.data.forEach((d) => {
      d.value = '';
    });
  },

  // Insert a tree node after the given treeNode
  deleteTreeNode(treeData, treeNode) {
    const parentTreeNode = AppUtil.findNodeById(treeData, treeNode.parentId);
    const newChildren = [];
    parentTreeNode.children.forEach((c) => {
      if (c.id !== treeNode.id) {
        newChildren.push(c);
      }
    });
    parentTreeNode.children = newChildren;
  },

  // Get contextInfo by ID
  // @return { data, mimetype, url }
  contextInfo(contexts, contextId) {
    const context = contexts.find((c) => c.id === contextId);
    if (context) {
      const ret = {
        data: context.data,
        mimeType: context.mimeType,
        url: ''
      };
      if (context.mimeType.startsWith('image/') && context.data) {
        ret.url = `data:${context.mimeType};base64,${context.data}`;
      } else if (context.url) {
        ret.url = context.url;
      }
      return ret;
    }

    return null;
  },

  millisFromDateString(dateString) {
    return new Date(Date.parse(dateString)).getTime();
  },

  getS3URIParts(value) {
    const matches = `${value}`.match(AppConstants.PATTERNS.S3_URI);
    if (matches) {
      const parts = matches[1].split('/');
      const bucket = parts[0];
      const key = parts.slice(1).join('/');
      const [filename] = parts.slice(-1);

      return {
        bucket,
        key,
        filename
      };
    }

    return null;
  },

  // Get Poll interval for queries in millis
  getQueryPollInterval() {
    return 2000;
  },

  // Helper to programmatically update the current user's columns
  updateUserColumns(gridId, defaultColumns, columnId, bAdd) {
    const localStorageKey = `${AppConstants.STORAGE_KEYS.GRID_CONFIG}.${gridId}`;
    let userConfig = AppUtil.safeParseJSON(
      AppUtil.getFromLocalStorage(localStorageKey)
    );
    if (!userConfig.columns) {
      userConfig = {
        columns: defaultColumns,
        pageSize: AppConstants.GRID_PAGE_SIZES[0]
      };
    }
    if (bAdd) {
      // Add the new column to the end
      userConfig.columns.push(columnId);
      AppUtil.setInLocalStorage(localStorageKey, JSON.stringify(userConfig));
    } else {
      // Remove only if user had non-default
      const idx = userConfig.columns.indexOf(columnId);
      if (idx >= 0) {
        userConfig.columns.splice(idx, 1);
        AppUtil.setInLocalStorage(localStorageKey, JSON.stringify(userConfig));
      }
    }
  },

  // Helper to disable grid actions
  updateGridActions(actions, selectionInfo, disabledKeys) {
    const actionsCopy = _cloneDeep(actions);
    actionsCopy.forEach((a) => {
      switch (a.scope) {
        case 'single':
          // eslint-disable-next-line no-param-reassign
          a.disabled = selectionInfo?.keys.length !== 1 ?? true;
          break;
        case 'batch':
          // eslint-disable-next-line no-param-reassign
          a.disabled = selectionInfo?.keys.length === 0 ?? true;
          break;
        default:
          break;
      }

      if (disabledKeys.includes(a.id)) {
        // eslint-disable-next-line no-param-reassign
        a.disabled = true;
      }
    });
    return actionsCopy;
  },

  generateUniqueID() {
    const four = () => Math.round(Math.random() * 10000);
    return Number(`${four()}${four()}`);
  },

  /* *** HACK for demo: remove *** */
  removeToFromName(name, search = '') {
    if (!name) {
      return '';
    }
    if (
      name.toLowerCase().startsWith('to ') &&
      !search.toLowerCase().startsWith('to')
    ) {
      return name.slice(3);
    }
    return name;
  },

  isDepartmentValid(department) {
    return [
      department?.draftKnowledgeId,
      department?.publishedKnowledgeId
    ].some((id) => id !== null);
  },

  filterPrivateValue(item) {
    return !item?.startsWith('_');
  },

  listFilterPrivateValue(list) {
    return list.filter(AppUtil.filterPrivateValue);
  },

  isOrganizationAdmin(userId, organization) {
    return organization !== null && organization?.owner === userId;
  },

  canCreateDepartment(userId, organization) {
    const isOrgAdmin = AppUtil.isOrganizationAdmin(userId, organization);
    if (!organization) {
      return true;
    }
    if (organization && isOrgAdmin) {
      return true;
    }
    return false;
  },

  getSelectionText() {
    let text = '';
    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== 'Control') {
      text = document.selection.createRange().text;
    }
    return text;
  },

  isProdEnv() {
    return ['production', 'production-eu', 'eu-production'].includes(
      appConfig.backend.environment
    );
  },

  isSandboxEnv() {
    return ['sandbox', 'sandbox-eu', 'eu-sandbox'].includes(
      appConfig.backend.environment
    );
  },

  isDevEnv() {
    return !(AppUtil.isProdEnv() || AppUtil.isSandboxEnv());
  },

  isTestEnv() {
    return !(
      this.isProdEnv() ||
      this.isSandboxEnv() ||
      appConfig.backend.environment === 'main'
    );
  },

  isProcedurePromptEnabled() {
    const localValue = AppUtil.getFromLocalStorage('procedure-prompt-enabled');
    if (localValue) {
      return JSON.parse(localValue) === true;
    }
    return false;
  },

  allowDepLocalUpdate(username) {
    // We deliberately want to disable showing/updating a department's local state on prod and sandbox. https://linear.app/kognitos/issue/KOG-1384/prevent-set-local-on-production-customer-departments
    // And should be shown only to support users
    return AppUtil.isDevEnv() && AppUtil.isSupportUser(username);
  },

  // TODO: remove this after document model is default for all customers
  /**
   * @deprecated Since we have the ability to check features from workers instead of department. This was done to migrate customers from command model to doc model
   */
  isDocumentModelSupported(department) {
    if (!department?.features) {
      return false;
    }
    return department.features.includes(DepartmentFeature.DOCUMENT_MODEL);
  },

  isAutoPilotSupported(department) {
    if (!department?.features) {
      return false;
    }
    return department.features.includes(DepartmentFeature.AUTOPILOT);
  },

  getPlural(word, count) {
    if (count === 1) {
      return word;
    }
    return `${word}s`;
  },

  getTimeSpanDelta(span) {
    const deltas = {
      day: AppConstants.MILLIS_PER_DAY,
      week: AppConstants.MILLIS_PER_WEEK,
      month: AppConstants.MILLIS_PER_MONTH
    };
    return deltas[span];
  },

  // Generate a time range covering a span
  // Days start at 12:00AM and end at 11:59PM
  generateTimeRange(span, endAt) {
    const m = moment(endAt);
    m.hours(0);
    m.minutes(0);
    m.seconds(0);

    const delta = AppUtil.getTimeSpanDelta(span);
    const start = m.valueOf() - delta;
    const end = m.add(1, 'd').subtract(1, 'm').valueOf();

    return {
      span,
      outerStart: start,
      outerEnd: end,
      innerStart: start,
      innerEnd: end
    };
  },

  // checks if the user had filled up a schedule
  hasFilledSchedule(schedule) {
    let ret;
    const hasEveryFewMins = !!(
      schedule.byEveryFewMinutes && schedule.byEveryFewMinutes.length > 0
    );
    const hasMinutes = !!(schedule.byminute && schedule.byminute.length > 0);
    const hasHours = !!(schedule.byhour && schedule.byhour.length > 0);
    const hasWeekday = !!(schedule.byweekday && schedule.byweekday.length > 0);
    const hasMonthDay = !!(
      schedule.bymonthday && schedule.bymonthday.length > 0
    );

    switch (schedule.freq) {
      case AppConstants.SCHEDULE_TYPES.FEW_MINUTES:
        ret = hasEveryFewMins;
        break;
      case AppConstants.SCHEDULE_TYPES.HOURLY:
        ret = hasMinutes;
        break;
      case AppConstants.SCHEDULE_TYPES.DAILY:
        ret = hasMinutes && hasHours;
        break;
      case AppConstants.SCHEDULE_TYPES.WEEKLY:
        ret = hasMinutes && hasHours && hasWeekday;
        break;
      case AppConstants.SCHEDULE_TYPES.MONTHLY:
        ret = hasMinutes && hasHours && hasMonthDay;
        break;
      default:
        ret = false;
        break;
    }
    return ret;
  },

  // Convert a schedule to/from UTF and the current timezone
  convertSchedule(schedule, bToUTC) {
    const covertedRule = { ...schedule };

    // Set inital timezone
    const mmt = moment();
    if (bToUTC) {
      mmt.local();
    } else {
      mmt.utc();
    }

    // set the target timezone
    const setTargetTz = () => {
      if (bToUTC) {
        mmt.utc();
      } else {
        mmt.local();
      }
    };

    switch (schedule.freq) {
      case AppConstants.SCHEDULE_TYPES.HOURLY:
        mmt.minutes(schedule.byminute ? schedule.byminute[0] : 0);
        setTargetTz();
        covertedRule.byminute = [mmt.minutes()];
        break;
      case AppConstants.SCHEDULE_TYPES.DAILY:
        mmt.hours(schedule.byhour ? schedule.byhour[0] : 0);
        mmt.minutes(schedule.byminute ? schedule.byminute[0] : 0);
        setTargetTz();
        covertedRule.byhour = [mmt.hours()];
        covertedRule.byminute = [mmt.minutes()];
        break;
      case AppConstants.SCHEDULE_TYPES.WEEKLY:
        mmt.days(schedule.byweekday ? schedule.byweekday[0] : 1);
        mmt.hours(schedule.byhour ? schedule.byhour[0] : 0);
        mmt.minutes(schedule.byminute ? schedule.byminute[0] : 0);
        setTargetTz();
        covertedRule.byweekday = [mmt.day()];
        covertedRule.byhour = [mmt.hours()];
        covertedRule.byminute = [mmt.minutes()];
        break;
      case AppConstants.SCHEDULE_TYPES.MONTHLY:
        mmt.date(schedule.bymonthday ? schedule.bymonthday[0] : 1);
        mmt.hours(schedule.byhour ? schedule.byhour[0] : 0);
        mmt.minutes(schedule.byminute ? schedule.byminute[0] : 0);
        setTargetTz();
        covertedRule.bymonthday = [mmt.date()];
        covertedRule.byhour = [mmt.hours()];
        covertedRule.byminute = [mmt.minutes()];
        break;
      default:
        break;
    }

    return covertedRule;
  },

  // procedureQuestions: IProcedureQuestion[]
  checkSameProcedureRequestQuestions(procedureQuestions) {
    if (procedureQuestions.length === 0) {
      return false;
    }

    if (procedureQuestions.length === 1) {
      return true;
    }

    const firstProcedureQuestion = procedureQuestions[0];
    return procedureQuestions.every(
      (procedureQuestion) =>
        procedureQuestion.question.__typename ===
          firstProcedureQuestion.question.__typename &&
        procedureQuestion.question.text ===
          firstProcedureQuestion.question.text &&
        JSON.stringify(procedureQuestion.question.lexicalPath) ===
          JSON.stringify(firstProcedureQuestion.question.lexicalPath) &&
        JSON.stringify(procedureQuestion.request.stepPath) ===
          JSON.stringify(firstProcedureQuestion.request.stepPath)
    );
  },

  isKognitosEmail(email) {
    return email?.endsWith('@kognitos.com');
  },

  allowProcedureEdit(procedure, department, _userEmail) {
    return procedure.knowledgeId === department?.draftKnowledgeId;
  },

  shouldShowQuestionChoices(type) {
    return type && ['which', 'review', 'review relations'].includes(type);
  },

  getObjectName(object) {
    return AppConstants.VALUE_ROW_KEYS.NAME.map((key) => object[key]).filter(
      Boolean
    )[0];
  },

  getObjectId(object) {
    return AppConstants.VALUE_ROW_KEYS.ID.map((key) => object[key]).filter(
      Boolean
    )[0];
  },

  getFirstKeyFromObject(object) {
    return object[Object.keys(object)[0]];
  },

  getStepNodeFromQuestion(contextPath, contextId) {
    let stepToHighlight = null;

    if (contextPath?.length) {
      if (contextId) {
        const lastContextPath = contextPath.find(
          (path) => path.ctxId === contextId
        );
        if (lastContextPath) {
          stepToHighlight = `${lastContextPath.ctxId}:${lastContextPath.sentenceId}`;
        }
      } else {
        const maxContextId = Math.max(...contextPath.map((o) => o.ctxId));
        const listWithMaxContextId = contextPath.filter(
          (path) => path.ctxId === maxContextId
        );
        if (listWithMaxContextId.length > 1) {
          const maxSentenceId = Math.max(
            ...listWithMaxContextId.map((o) => o.sentenceId)
          );
          const objectWithMaxSentenceId = listWithMaxContextId.find(
            (path) => path.sentenceId === maxSentenceId
          );
          stepToHighlight = `${objectWithMaxSentenceId.ctxId}:${objectWithMaxSentenceId.sentenceId}`;
        } else {
          stepToHighlight = `${listWithMaxContextId[0].ctxId}:${listWithMaxContextId[0].sentenceId}`;
        }
      }
    }

    return stepToHighlight;
  },

  preparePageTitle(title) {
    return `${title} | Kognitos`;
  },

  listFuzzySearch(list, keys, searchValue) {
    const result = new Fuse(list, {
      keys: Array.isArray(keys) ? keys : [keys],
      includeScore: true,
      minMatchCharLength: searchValue.length
    }).search(searchValue);

    return result
      .filter((resultItem) => resultItem.score <= 0.5)
      .map((resultItem) => resultItem.item);
  },

  listDateSearch(list, key, date) {
    return list.filter((item) => dayjs(item[key]).isSame(date, 'day'));
  },

  // TODO: Ideally API should support these filters, Table should just replace the incoming data from API
  handleTableExternalFilterChange({
    list,
    key: { search: searchKeys, date: dateKey },
    searchValue,
    dateValue
  }) {
    let updatedData = list;

    // filter for data
    if (dateValue) {
      updatedData = AppUtil.listDateSearch(updatedData, dateKey, dateValue);
    }

    // filter for search
    if (searchValue.trim().length) {
      updatedData = AppUtil.listFuzzySearch(
        updatedData,
        searchKeys,
        searchValue
      );
    }

    return updatedData;
  },

  shouldFetchPageData(pageDataMap, page) {
    if (page === 0) {
      return false;
    }

    const pageData = pageDataMap[page];
    if (pageData === undefined) {
      return true;
    }
    return false;
  },

  // are we on a mobile UI ?
  isMobileUI() {
    const query = '(max-device-width: 1024px)';
    const ret = window.matchMedia(query);
    return ret.matches;
  },

  prepareFilesUploadCommand(uploadedResponse, oldValue = '') {
    const filesString = uploadedResponse.map((r) => `"${r.s3Url}"`).join(', ');

    const defaultCommand =
      uploadedResponse.length > 1
        ? `the files are ${filesString}`
        : `the file is ${filesString}`;

    let command = defaultCommand;

    if (oldValue) {
      if (oldValue.includes('the file is')) {
        const replacedOldValue = oldValue
          .trim()
          .replace('the file is', 'the files are');
        command = `${replacedOldValue}, ${filesString}`;
      } else if (!oldValue.match(AppConstants.PATTERNS.S3_URI)) {
        command = `${oldValue.trim()}\n${command}`;
      } else {
        command = `${oldValue.trim()}, ${filesString}`;
      }
    }

    return command;
  },

  formatProcedureText(text) {
    const procedureText = removeKogLineBreak(text || '');
    return encodeMarkdownText(procedureText).trim();
    // return procedureText;
  },

  hasMarkdownText(text) {
    if (!text) {
      return false;
    }
    // return AppConstants.PATTERNS.MARKDOWN_TEXT.test(text);
    return `${text}`.startsWith('"""');
  },

  getPaginatedList(list, pageNumber, pageSize) {
    return [...list].slice(pageSize * (pageNumber - 1), pageSize * pageNumber);
  },

  isSupportUser(username) {
    return username?.match(AppConstants.PATTERNS.SUPPORT_USER);
  },

  // Util to format time
  formatTime(timeStamp) {
    return moment(timeStamp).format('MMM DD, YYYY h:mm a');
  },

  upperFirstChar(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  isPlaygroundV3(pathname) {
    return pathname.includes('playground2');
  },

  getWorkerLogLink(workerId, fromTs, toTs) {
    // return `https://app.logz.io/#/dashboard/kibana/discover?_a=(columns:!(message),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'logzioCustomerIndex*',key:worker,negate:!f,params:(query:'${workerId}'),type:phrase),query:(match_phrase:(worker:'${workerId}')))),index:'logzioCustomerIndex*',interval:auto,query:(language:lucene,query:''),sort:!(!('@timestamp',desc)))&_g=(filters:!())`

    if (fromTs && toTs) {
      // adding 100ms to toTs and removing 100ms from fromTs to ensure no data is lost
      const from = moment(fromTs).utc() - 100;
      const to = moment(fromTs).utc() + 100;
      return `https://app.datadoghq.com/logs?query=%40worker_id%3A${workerId}&from_ts=${from}&to_ts=${to}&live=false`;
    }

    if (fromTs) {
      const from = moment(fromTs).utc() - 100;
      return `https://app.datadoghq.com/logs?query=%40worker_id%3A${workerId}&from_ts=${from}&live=false`;
    }

    return `https://app.datadoghq.com/logs?query=%40worker_id%3A${workerId}`;
  },

  convertCSVStringToArray(csv) {
    if (typeof csv === 'string') {
      return [csv];
    }
    return csv;
  },

  isResultTypeDone(result) {
    let lowercase = '';
    if (typeof result === 'string') {
      lowercase = result.toLowerCase();
    } else if (typeof result === 'object') {
      lowercase = result.result.toLowerCase();
    }
    return lowercase === 'done' || lowercase === 'ok';
  },

  isDocumentModel(worker) {
    if (!worker?.features) {
      return false;
    }
    return worker.features.includes('document_model');
  },

  parseWithoutNaN(value) {
    try {
      if (typeof value === 'string') {
        const newVal = value.replace(/\bNaN\b/g, '"__NaN__"');
        const val = JSON.parse(newVal, (k, v) => {
          if (typeof v === 'number' && Number.isNaN(v)) {
            return '';
          }
          if (v === '__NaN__') {
            return '';
          }
          if (v === null) {
            return '';
          }
          return v;
        });
        return val;
      }
      return value;
    } catch (e) {
      console.log(e);
    }
  }
};

export default AppUtil;
