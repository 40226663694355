import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, Typography } from 'antd';
import { Integration } from '@/generated/API';
import { getAvgColor } from '@/utils/miscUtil';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './IconBookCard.module.less';
import BookIcon from '../book/BookIcon';

interface IconBookCardProps {
  book: Integration;
  onClick: (key: string) => void;
  className?: string;
}

function IconBookCard(props: IconBookCardProps) {
  const { book, onClick, className } = props;
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [bgColor, setBgColor] = useState<string | undefined>();
  const isDisabled = book?.url?.endsWith('empty');

  useEffect(() => {
    let isMounted = true;
    if (!isEmpty(book) && imgRef.current) {
      getAvgColor(imgRef.current).then((color) => {
        if (isMounted) setBgColor(color);
      });
    }
    return () => {
      isMounted = false;
    };
  }, [imgRef.current, book]);

  const handleClick = () => {
    if (!isDisabled) {
      onClick(book.id);
    } else {
      // TODO: Coming soon mesage need to be shown
      // Currently, we are not showing any message, no design was ready as of now
    }
  };

  if (isEmpty(book)) {
    return null;
  }

  return (
    <div
      className={classnames(styles.iconBookCard, {
        [styles.disabled]: isDisabled,
        [className as string]: className
      })}
      onClick={handleClick}
      data-cy={`book-${book.name?.toLowerCase()}`}
    >
      <div className={styles.left} style={{ backgroundColor: bgColor }}>
        <div className={styles.icon}>
          <BookIcon ref={imgRef} id={book.id} />
        </div>
      </div>
      <Typography.Text>{book.name}</Typography.Text>
      <Typography.Text type="secondary">
        {book?.credentialMetadata?.redirectionFlowRequired ? (
          <Tooltip title="This book requires a redirection flow">
            <InfoCircleOutlined color="#8C8C8C" />
          </Tooltip>
        ) : null}
      </Typography.Text>
    </div>
  );
}

export default IconBookCard;
