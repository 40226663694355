import { gql } from '@apollo/client';

export const LIST_DEPARTMENT_LEARNED_ANSWERS = gql`
  query listDepartmentLearnedAnswers($id: ID!) {
    getDepartment(id: $id) {
      learnedAnswers {
        nextToken
        items {
          id
          questionFilter {
            stepPath
            questionType
            lexicalPath
          }
          action {
            _
            ... on RetryStepAction {
              minimumDelay
              timeout
            }
            ... on UseConceptAction {
              conceptId
            }
            ... on UseLiteralValueAction {
              literalValue
              choices
            }
            ... on UseTechniqueAction {
              technique
            }
          }
          createdAt
          createdBy
          deletedAt
          deletedBy
          procedureId
          procedureName
          procedure {
            id
            name
            knowledgeId
          }
          contextValue
          contextMetadata
        }
      }
    }
  }
`;

export const CREATE_LEARNED_ANSWER = gql`
  mutation CreateLearnedAnswer($input: CreateLearnedAnswerInput!) {
    createLearnedAnswer(input: $input) {
      id
      questionFilter {
        stepPath
        questionType
        lexicalPath
      }
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseOauth2Action {
          accessToken
          refreshToken
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      createdAt
      createdBy
      deletedAt
      deletedBy
      procedureId
      procedureName
      procedure {
        id
      }
      contextValue
      contextMetadata
    }
  }
`;

export const LIST_LEARNED_ANSWER_SUGGESTIONS = gql`
  query GetLearnedAnswerSuggestions($input: LearnedAnswerSuggestionsInput!) {
    getLearnedAnswerSuggestions(input: $input) {
      items {
        id
        createdAt
        createdBy
        deletedAt
        deletedBy
        procedureId
        procedureName
        contextValue
        contextMetadata
        questionFilter {
          stepPath
          questionType
          lexicalPath
        }
        action {
          _
          ... on RetryStepAction {
            minimumDelay
            timeout
          }
          ... on UseConceptAction {
            conceptId
          }
          ... on UseLiteralValueAction {
            literalValue
            choices
          }
          ... on UseTechniqueAction {
            technique
          }
        }
      }
      nextToken
    }
  }
`;

export const GET_CONTEXTUAL_LEARNING_INFO_BY_REQUEST = gql`
  query GetContextualLearningInfoByRequest($requestId: ID!) {
    getContextualLearningInfoByRequest(requestId: $requestId) {
      items {
        id
        createdAt
        createdBy
        procedureId
        learningId
        requestId
        procedureName
        contextMetadata
      }
    }
  }
`;

export const DELETE_LEARNED_ANSWER = gql`
  mutation DeleteLearnedAnswer($input: DeleteLearnedAnswerInput!) {
    deleteLearnedAnswer(input: $input) {
      id
    }
  }
`;
