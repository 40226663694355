// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.worker-status .content {
  display: inline-flex;
  flex-wrap: wrap;
}
.worker-status .ant-comment-inner {
  padding: 0;
}
.worker-status .ant-comment-content-author {
  margin: 0;
  transition: all 0.1s ease-in-out;
}
.worker-status .operation {
  margin-right: 8px;
}
.worker-status .ant-tag {
  white-space: initial;
  max-width: 650px;
}
.worker-status .default-msg {
  font-size: 24px;
}
.worker-status .ant-avatar-string {
  font-size: 50px;
}
.worker-status.author-center .ant-comment-content-author {
  position: relative;
  top: 10px;
}
.worker-status.author-margin .ant-comment-content-author {
  top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/WorkerStatus.less"],"names":[],"mappings":"AACE;EACE,oBAAA;EACA,eAAA;AAAJ;AAGE;EACE,UAAA;AADJ;AAIE;EACE,SAAA;EACA,gCAAA;AAFJ;AAKE;EACE,iBAAA;AAHJ;AAME;EACE,oBAAA;EACA,gBAAA;AAJJ;AAOE;EACE,eAAA;AALJ;AApBA;EA6BI,eAAA;AANJ;AAUI;EACE,kBAAA;EACA,SAAA;AARN;AAaI;EACE,QAAA;AAXN","sourcesContent":[".worker-status {\n  & .content {\n    display: inline-flex;\n    flex-wrap: wrap;\n  }\n\n  & .ant-comment-inner {\n    padding: 0;\n  }\n\n  & .ant-comment-content-author {\n    margin: 0;\n    transition: all 0.1s ease-in-out;\n  }\n\n  & .operation {\n    margin-right: 8px;\n  }\n\n  & .ant-tag {\n    white-space: initial;\n    max-width: 650px;\n  }\n\n  & .default-msg {\n    font-size: 24px;\n  }\n\n  .ant-avatar-string {\n    font-size: 50px;\n  }\n\n  &.author-center {\n    & .ant-comment-content-author {\n      position: relative;\n      top: 10px;\n    }\n  }\n\n  &.author-margin {\n    & .ant-comment-content-author {\n      top: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
