import { Dropdown, Tooltip, Typography, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { showPopup } from '@/stores/slices/appPopup';
import DocumentFactIcon from '@/components/icons/DocumentFactIcon';
import { useApolloClient } from '@apollo/client';
import { GET_LOCAL_DOCUMENT_PREVIEW } from '@/graphql/queries/playground';
import { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import { useS3 } from '@/hooks/useS3';
import { downloadFile } from '@/utils/fileUtil';
import { useAppDispatch } from '../stores/hooks';
import AppUtil from '../utils/AppUtil';
import AppConstants from '../utils/AppConstants';
import styles from './AnswerS3FileViewer.module.less';
import ExpandIcon from './icons/ExpandIcon';
import S3DocumentViewer from './S3DocumentViewer';
import ThreeDotMenuIcon from './icons/ThreeDotMenuIcon';
import DownloadIcon from './icons/DownloadIcon';

interface IProps {
  answer: string;
  showFileIcon?: boolean;
  showInFactsTable?: boolean;
  onClick?: any;
  showPreview?: boolean;
  expandDocumentPreview?: (open: boolean) => void;
}

function AnswerS3FileViewer(props: IProps) {
  const {
    answer,
    showFileIcon,
    showInFactsTable,
    onClick,
    showPreview,
    expandDocumentPreview
  } = props;

  const client = useApolloClient();

  const s3 = useS3();

  const s3Parts = useMemo(() => AppUtil.getS3URIParts(answer)!, [answer]);

  const [s3Object, setS3Object] = useState<any>();

  const getS3Obj = async (s3Object: {
    bucket?: string;
    key: any;
    filename?: string;
  }) => {
    const updatedPresignedUrl = await s3.getS3PresignedUrl(s3Object.key);
    if (updatedPresignedUrl) {
      const urlObject = await s3.getSignedUrlObject(updatedPresignedUrl.url);
      if (urlObject) {
        setS3Object(urlObject);
      }
    }
  };

  useEffect(() => {
    if (s3Parts) {
      getS3Obj(s3Parts);
    }
  }, [s3Parts]);

  const dispatch = useAppDispatch();

  const handleViewDetails = () => {
    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.VIEW_S3_FILE,
        popupParams: {
          title: s3Parts.filename,
          s3Object: {
            bucket: s3Parts.bucket,
            key: s3Parts.key
          }
        }
      })
    );
  };

  const expandPreview = () => {
    client.writeQuery({
      query: GET_LOCAL_DOCUMENT_PREVIEW,
      data: {
        getLocalDocumentPreview: {
          data: {
            type: FormattedAnswerTypeV2.S3,
            title: s3Parts.filename,
            s3Object: {
              bucket: s3Parts.bucket,
              key: s3Parts.key,
              downloadUrl: s3Object?.config?.url
            }
          }
        }
      }
    });
    expandDocumentPreview?.(true);
  };

  // TODO: Add a test case to download file
  const onFileDownload = () => {
    downloadFile(
      s3Parts.filename,
      s3Object.config.url,
      () => message.success('File download successfull'),
      () => message.error('Could not download file')
    );
  };

  if (showPreview) {
    return (
      <span className={styles.filePreviewContainer} data-cy="file-preview">
        <div className={styles.expandContainer}>
          <span className={styles.s3FactTableContainer}>
            <DocumentFactIcon
              style={{ width: 24, height: 24, marginRight: 8 }}
            />
            <span className={styles.filePreviewHeader}>{s3Parts.filename}</span>
          </span>
          <span className={styles.previewIcons}>
            {s3Object && (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  onFileDownload();
                }}
              >
                <DownloadIcon className={styles.downloadButton} />
              </span>
            )}
            <span onClick={expandPreview} data-cy="expand-document">
              <ExpandIcon style={{ width: 20, height: 20 }} />
            </span>
          </span>
        </div>
        <S3DocumentViewer
          key={`${s3Parts.filename}:${s3Parts.key}`}
          title={s3Parts.filename}
          s3Object={s3Parts}
          hideHeader
        />
      </span>
    );
  }

  const renderFactsTableS3 = () =>
    showInFactsTable ? (
      <div className={styles.s3FactTableButtonContainer}>
        <span className={styles.title}>
          <DocumentFactIcon style={{ width: 24, height: 24 }} />
          <span className={styles.fileButton}>
            {s3Parts.filename.length > 20
              ? `${s3Parts.filename.slice(0, 20)}...`
              : s3Parts.filename}
          </span>
        </span>
        <span onClick={(e) => e.stopPropagation()}>
          <Dropdown
            menu={{
              items: [
                {
                  key: 'download',
                  label: 'Download',
                  onClick: onFileDownload
                }
              ]
            }}
          >
            <ThreeDotMenuIcon />
          </Dropdown>
        </span>
      </div>
    ) : (
      <Typography.Text ellipsis className={styles.answerS3FileViewerText}>
        {s3Parts.filename}
      </Typography.Text>
    );

  return (
    <div
      className={styles.answerS3FileViewer}
      data-cy="answer-s3-file-viewer"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) {
          onClick();
        } else if (expandDocumentPreview) {
          expandPreview();
        } else {
          handleViewDetails();
        }
      }}
    >
      {showFileIcon ? (
        <Tooltip
          title={s3Parts?.filename}
          color="#fff"
          overlayInnerStyle={{
            color: 'black'
          }}
          destroyTooltipOnHide
        >
          <DocumentFactIcon style={{ width: 24, height: 24 }} />
        </Tooltip>
      ) : (
        renderFactsTableS3()
      )}
    </div>
  );
}

export default AnswerS3FileViewer;
