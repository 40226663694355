export const delay = (ms: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve) => setTimeout(resolve, ms));

export const delayExponentialBackoff = (attempt: number) => 2 ** attempt * 1000;

interface IOptions {
  promiseFn: () => Promise<any>;
  errorPredicate: (error: any) => boolean;
  delayTime: ((nthTry: number) => number) | number;
  retries: number;
  /**
   * Used to share attempt state. This is a private option.
   * @internal
   */
  _retryIndex?: number;
}

export async function retryPromiseWithDelay(options: IOptions): Promise<any> {
  const { promiseFn, errorPredicate, delayTime, retries, _retryIndex } =
    options;

  try {
    const res = await promiseFn();
    return res;
  } catch (e) {
    const attempt = _retryIndex ? retries - _retryIndex : 0;

    if (_retryIndex! < 1 || !errorPredicate(e)) {
      return Promise.reject<any>(e);
    }

    const finalDelayTime =
      typeof delayTime === 'function' ? delayTime(attempt) : delayTime;

    await delay(finalDelayTime);

    return retryPromiseWithDelay({
      ...options,
      _retryIndex: (_retryIndex || retries) - 1
    });
  }
}

/*
 * funcs is a list of function that returns a promise
 * const funcs = urls.map(url => () => $.ajax(url))
 * serialPromises(funcs).then(console.log)
 */
export const serialPromises = (funcs: Array<() => Promise<any>>) =>
  funcs.reduce(
    (promise: Promise<any>, func) =>
      promise.then((result) =>
        func().then(Array.prototype.concat.bind(result))
      ),
    Promise.resolve([])
  );
