import { AccessControlRole, Department, Organization } from '@/generated/API';
import { ReactiveVar, makeVar } from '@apollo/client';

interface CurrentUserDetails {
  department: Department | null;
  permissions: string[];
  email: string | null;
  name: string | undefined | null;
  lastName?: string | undefined | null;
  id: string | null;
  role: AccessControlRole;
  organization: Organization | null;
  isDemoUser: boolean;
}

export const currentUserDetailsVar: ReactiveVar<CurrentUserDetails> =
  makeVar<CurrentUserDetails>({
    department: null,
    permissions: [],
    email: '',
    name: '',
    lastName: '',
    id: '',
    role: AccessControlRole.admin,
    organization: null,
    isDemoUser: false
  });
