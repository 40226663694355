import React from 'react';
import { useAppDispatch } from '../stores/hooks';
import { showPopup } from '../stores/slices/appPopup';
import AppConstants from '../utils/AppConstants';
import './AnswerMarkdownViewer.less';
import { decodeMarkdownText } from './editor/helper';

interface IProps {
  answer: string;
}

function AnswerMarkdownViewer(props: IProps) {
  const { answer } = props;

  const dispatch = useAppDispatch();

  const handleViewDetails = () => {
    const match = AppConstants.PATTERNS.MARKDOWN_TEXT.exec(answer);
    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.MARKDOWN_VIEWER,
        popupParams: {
          text: match ? decodeMarkdownText(match[1]) : ''
        }
      })
    );
  };

  return (
    <div
      className="answer-markdown-viewer"
      data-cy="answer-markdown-viewer"
      onClick={handleViewDetails}
      // eslint-disable-next-line react/no-children-prop
      children="<#>"
    />
  );
}

export default AnswerMarkdownViewer;
