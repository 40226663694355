// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppPage-module__appPage__llzhd {
  background: #f4f4f6;
  overflow: hidden;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AppPage.module.less"],"names":[],"mappings":"AAGA;EACE,mBAAA;EACA,gBAAA;EACA,kBAAA;AAFF","sourcesContent":["// Application Page Styles (Applies to all pages)\n@import '@css/variables.less';\n\n.appPage {\n  background: @page-bg-color;\n  overflow: hidden;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appPage": `AppPage-module__appPage__llzhd`
};
export default ___CSS_LOADER_EXPORT___;
