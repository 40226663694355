import React from 'react';
import ReactJson from 'react-json-view';

interface IJsonViewerProps {
  data: Record<any, any>;
  name: string;
}

function JsonViewer(props: IJsonViewerProps) {
  const { data, name } = props;
  return (
    <div>
      <ReactJson
        name={name}
        src={data}
        theme="brewer"
        style={{
          overflow: 'hidden',
          wordBreak: 'break-all'
        }}
      />
    </div>
  );
}

export default JsonViewer;
