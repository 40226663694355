import React from 'react';
import Icon from '@ant-design/icons';

function WidgetUpdateConfirmSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9 1.125C4.65117 1.125 1.125 4.65117 1.125 9C1.125 13.3488 4.65117 16.875 9 16.875C13.3488 16.875 16.875 13.3488 16.875 9C16.875 4.65117 13.3488 1.125 9 1.125ZM12.4014 6.42832L8.69941 11.5611C8.64767 11.6334 8.57946 11.6922 8.50044 11.7328C8.42142 11.7734 8.33386 11.7946 8.24502 11.7946C8.15618 11.7946 8.06862 11.7734 7.9896 11.7328C7.91057 11.6922 7.84237 11.6334 7.79063 11.5611L5.59863 8.52363C5.53184 8.43047 5.59863 8.30039 5.71289 8.30039H6.5373C6.7166 8.30039 6.88711 8.38652 6.99258 8.53418L8.24414 10.2709L11.0074 6.43887C11.1129 6.29297 11.2816 6.20508 11.4627 6.20508H12.2871C12.4014 6.20508 12.4682 6.33516 12.4014 6.42832Z"
        fill="#52C41A"
      />
    </svg>
  );
}

export default function WidgetUpdateConfirmIcon(props: {
  style?: React.CSSProperties;
}) {
  return <Icon component={WidgetUpdateConfirmSvg} {...props} />;
}
