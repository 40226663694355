import React from 'react';
import Icon from '@ant-design/icons';

function OutputPanelCloseSvg() {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.42097 20.3938C3.47675 20.3938 2.57121 20.0065 1.90355 19.317C1.23589 18.6275 0.8608 17.6924 0.8608 16.7173L0.860801 4.07027C0.860801 3.09521 1.23589 2.16008 1.90355 1.47061C2.57121 0.781137 3.47675 0.393796 4.42097 0.393796L20.0857 0.393798C21.0299 0.393798 21.9355 0.781139 22.6031 1.47061C23.2708 2.16008 23.6459 3.09521 23.6459 4.07027L23.6459 16.7173C23.6459 17.6924 23.2708 18.6275 22.6031 19.317C21.9355 20.0065 21.0299 20.3938 20.0857 20.3938L4.42097 20.3938ZM2.28487 16.7173C2.28487 17.3024 2.50992 17.8634 2.91052 18.2771C3.31111 18.6908 3.85444 18.9232 4.42097 18.9232L7.55392 18.9232L7.55392 1.86438L4.42097 1.86438C3.85444 1.86438 3.31111 2.09679 2.91052 2.51047C2.50992 2.92416 2.28487 3.48523 2.28487 4.07027L2.28487 16.7173ZM8.97798 1.86439L8.97798 18.9232L20.0857 18.9232C20.6522 18.9232 21.1956 18.6908 21.5962 18.2771C21.9968 17.8634 22.2218 17.3024 22.2218 16.7173L22.2218 4.07027C22.2218 3.48523 21.9968 2.92416 21.5962 2.51047C21.1956 2.09679 20.6522 1.86439 20.0857 1.86439L8.97798 1.86439Z"
        fill="#7A8E9D"
      />
      <g clipPath="url(#clip0_4_10098)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6322 13.0104C13.2416 13.401 13.1625 13.955 13.4554 14.2479C13.7483 14.5408 14.3023 14.4616 14.6928 14.0711L17.5213 11.2427C17.7646 10.9994 17.887 10.6926 17.8739 10.425C17.8872 10.1573 17.7647 9.85035 17.5213 9.6069L14.6928 6.77847C14.3023 6.38795 13.7483 6.3088 13.4554 6.60169C13.1625 6.89459 13.2417 7.44861 13.6322 7.83913L16.2178 10.4248L13.6322 13.0104Z"
          fill="#7A8E9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_10098">
          <rect
            width="9.47368"
            height="5.26316"
            fill="white"
            transform="translate(13.1195 15.1306) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default function OutputPanelCloseIcon(props: {
  style?: React.CSSProperties;
}) {
  return <Icon component={OutputPanelCloseSvg} {...props} />;
}
