import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Step, Worker } from '@/generated/API';
import { RootState } from '@/stores/AppStore';
import { IPDFConfig } from '../../components/S3DocumentViewer';
import { getWorker } from './worker';

export interface IS3ViewDetails {
  title: string;
  s3Object: { bucket: string; key: string };
  pdfConfig: IPDFConfig;
}

interface IStepFactsEdit {
  step: Step;
  worker: Worker;
}

export interface Irun {
  workerId: string | null;
  selectedStep: Step | null;
  expandedStep: Step | null;
  stepFactsEdit: IStepFactsEdit | null;
  selectedStepKeyToEdit: string | null;
  s3PreviewDetails: IS3ViewDetails | null;
}

const initialState: Irun = {
  workerId: null,
  selectedStep: null,
  expandedStep: null,
  stepFactsEdit: null,
  selectedStepKeyToEdit: null,
  s3PreviewDetails: null
};

export const runSlice = createSlice({
  name: 'run',
  initialState,
  reducers: {
    setWorkerId: (state, action: PayloadAction<string | null>) => {
      state.workerId = action.payload;
    },
    setSelectedStep: (state, action: PayloadAction<Step | null>) => {
      state.selectedStep = action.payload;
    },
    setExpandedStep: (state, action: PayloadAction<Step | null>) => {
      state.expandedStep = action.payload;
    },
    setStepFactsEdit: (state, action: PayloadAction<IStepFactsEdit | null>) => {
      state.stepFactsEdit = action.payload;
    },
    setSelectedStepKeyToEdit: (state, action: PayloadAction<string | null>) => {
      state.selectedStepKeyToEdit = action.payload;
    },
    setS3PreviewDetails: (
      state,
      action: PayloadAction<IS3ViewDetails | null>
    ) => {
      state.s3PreviewDetails = action.payload;
    },
    resetRun: (state) => {
      state.expandedStep = null;
      state.selectedStep = null;
      state.s3PreviewDetails = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getWorker.fulfilled, (state) => {
      state.expandedStep = null;
      state.selectedStep = null;
      state.s3PreviewDetails = null;
    });
  }
});

export const {
  setWorkerId,
  setSelectedStep,
  setExpandedStep,
  setS3PreviewDetails,
  setSelectedStepKeyToEdit,
  setStepFactsEdit
} = runSlice.actions;

export const runSelector = (state: RootState) => state.run;
export default runSlice.reducer;
