import React from 'react';
import Icon from '@ant-design/icons';

function PlaygroundReloadSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_494_5259)">
        <path
          d="M18.8638 3.2433L17.6049 4.22768C15.8883 2.03348 13.2187 0.625 10.2209 0.625C5.04458 0.625 0.854849 4.81027 0.848153 9.98884C0.841456 15.1719 5.04012 19.375 10.2209 19.375C14.2678 19.375 17.7165 16.808 19.029 13.2121C19.0624 13.1183 19.0133 13.0134 18.9196 12.9821L17.654 12.5469C17.6098 12.5317 17.5615 12.5345 17.5194 12.5545C17.4773 12.5745 17.4447 12.6103 17.4285 12.654C17.3883 12.7656 17.3437 12.8772 17.2968 12.9866C16.9107 13.9018 16.3571 14.7232 15.6517 15.4286C14.9521 16.1296 14.1237 16.6889 13.212 17.0759C12.2678 17.4754 11.2611 17.6786 10.2254 17.6786C9.18744 17.6786 8.18297 17.4754 7.23878 17.0759C6.32622 16.6905 5.49753 16.131 4.79905 15.4286C4.09748 14.729 3.53874 13.8996 3.15396 12.9866C2.7544 12.0402 2.55128 11.0357 2.55128 9.99777C2.55128 8.95982 2.7544 7.95536 3.15396 7.00893C3.54012 6.09375 4.09369 5.27232 4.79905 4.56696C5.5044 3.86161 6.32583 3.30804 7.23878 2.91964C8.18297 2.52009 9.18967 2.31696 10.2254 2.31696C11.2633 2.31696 12.2678 2.52009 13.212 2.91964C14.1245 3.30501 14.9532 3.86455 15.6517 4.56696C15.8727 4.78795 16.0803 5.02232 16.2723 5.26786L14.9285 6.31697C14.9019 6.33753 14.8817 6.36519 14.8701 6.39675C14.8585 6.42831 14.8561 6.46249 14.8631 6.49537C14.8701 6.52825 14.8862 6.55848 14.9097 6.5826C14.9331 6.60672 14.9628 6.62373 14.9955 6.6317L18.9151 7.59152C19.0267 7.6183 19.1361 7.53348 19.1361 7.41964L19.154 3.3817C19.1517 3.23438 18.9799 3.15179 18.8638 3.2433Z"
          fill="#7A8E9D"
        />
      </g>
      <defs>
        <clipPath id="clip0_494_5259">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default function PlaygroundReloadIcon(props: {
  style?: React.CSSProperties;
  spin?: boolean;
  className?: string;
}) {
  return <Icon component={PlaygroundReloadSvg} {...props} />;
}
