import React from 'react';
import { Modal } from 'antd';
import MarkdownRenderer from '../components/MarkdownRenderer';

interface IProps {
  text: string;
  title?: string;
  onClose: () => void;
}

function MarkdownViewerPopup(props: IProps) {
  const { text, title = 'Markdown Viewer', onClose } = props;
  return (
    <Modal
      title={title}
      open
      onCancel={onClose}
      width={900}
      className="markdown-viewer"
      footer={null}
    >
      <MarkdownRenderer>
        {text.replace(/\\n|\\r/gi, '\n').replace(/\\t/gi, '\t&nbsp;')}
      </MarkdownRenderer>
    </Modal>
  );
}

export default MarkdownViewerPopup;
