import { UpdateDocumentInput } from '@/generated/API';
import { UPDATE_PLAYGROUND_DOCUMENT } from '@/graphql/mutations/playground';
import {
  GET_WORKER_DOCUMENT,
  GET_SENTENCE_EXECUTION_DATA,
  GET_LOOP_MANAGER,
  GET_WORKER_MAP
} from '@/graphql/queries/playground';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import shortUUID from 'short-uuid';
import { debounce, invert, isEmpty, isEqual } from 'lodash';
import { Typography, message } from 'antd';
import AppUtil from '@/utils/AppUtil';
import { CloseOutlined } from '@ant-design/icons';
import SubProcedureIcon from '@/components/icons/SubProcedureIcon';
import { useApolloClient } from '@apollo/react-hooks';
import useMergeState from '@/utils/reactUtils';
import {
  deleteCurrentTemplateId,
  getCurrentTemplateId
} from '@/pages/Playgrounds/templateUtils';
import { currentUserDetailsVar } from '@/graphql/cache/user';
import { useAppSelector } from '@/stores/hooks';
import { departmentQuerySelector } from '@/stores/slices/department';
import { useCustomCompareLayoutEffect } from 'use-custom-compare';
import { Descendant } from 'slate';
import { userSelector } from '@/stores/slices/user';
import { useSelector } from 'react-redux';
import AppConstants from '@/utils/AppConstants';
import {
  constructIdMap,
  getSentenceTabCount,
  getTemplateBlocksFromId,
  constructDocumentExecLogs,
  constructDocumentLineLogs,
  handleSaveError,
  getCollapsedCodeBlocks,
  indentValidator
} from './editorUtil';
import {
  DocumentLineInput,
  EditorBlock,
  EditorTabsKey,
  IEditorStatus,
  IEditorTabs,
  IEditorContext,
  ISentence,
  EditorProviderProps,
  Sentence,
  SentenceExecutionData,
  SentenceInternalMetadataKeys,
  SubDocument,
  CodeBlock,
  EditorErrorRecord,
  EditorSourceType
} from './editorInterface';
import {
  IWidgetData,
  WIDGET_COUNT_INIT_STATE,
  textDeFormatterForDocumentLine,
  textFormatter
} from '../EditorWidgets/decorators';
import { generateText } from '../RichTextEditor/utils';
import styles from './Editor.module.less';
import { TabKeys } from '../OutputPanel/OutputPanel';
import { transformInputToOutput } from './editorTree';
import { FactsProvider } from '../FactsContext/FactsProvider';
import {
  delayExponentialBackoff,
  retryPromiseWithDelay
} from '../../../../utils/promise';
import { shouldRetryQueryOnCommonErrors } from '../../../../utils/query';

const KogEditorContext = React.createContext<IEditorContext>(null!);

export const EditorProvider = (props: EditorProviderProps) => {
  const { children, worker, source, defaultDocumentToken = '' } = props;

  const { username } = useSelector(userSelector);
  const client = useApolloClient();
  const { email } = useReactiveVar(currentUserDetailsVar);
  const { department } = useAppSelector(departmentQuerySelector);
  const isAutoPilotSupported = AppUtil.isAutoPilotSupported(department);

  const [oldSentences, setOldSentences] = useState<DocumentLineInput[]>([]);
  const [sentences, setSentence] = useState<DocumentLineInput[]>([]);
  const [editorBlocks, setEditorBlocks] = useState<Descendant[]>([]);
  const [editorValue, setEditorValue] = useState<Descendant[]>([]);
  const [collapsedCodeBlocks, setCollapsedCodeBlocks] = useState<CodeBlock[]>(
    []
  );
  const [activeBlock, setActiveBlock] = useState<ISentence | null>(null);
  const [activeOutputTab, setActiveOutputTab] = useState<TabKeys>(
    TabKeys.Upload
  );

  const [editorTabs, setEditorTabs] = useState<IEditorTabs[]>([]);
  const [debugMode, setDebugMode] = useState(false);
  const [subDocumentToken, setSubDocumentToken] =
    useState<string>(defaultDocumentToken);
  const [idMap, setIdMap] = useMergeState<Record<string, string>>({});
  const [editorErrors, setEditorErrors] = useState<Record<string, string>>({});
  const [editorStatus, updateEditorStatus] = useMergeState<IEditorStatus>({
    readOnly: true,
    isEditing: true,
    isSaving: false,
    isExecuting: false,
    autoPilot: false,
    showDocument: false,
    showChat: false,
    showOutputPanel: false
  });
  /**
   * {
   * [md1]: {value: 'asdasd', type: 'md'},
   * [md2]: {value: 'asdasd', type: 'md'},,
   * KOG_WIDGET_COUNT: {
   *  md: 2, json: 3
   * }
   * }
   */
  const [replacerObj, setReplacerObj] = useState<Record<string, any>>();

  // This refs are needed to get the current value inside setInterval,
  const sentenceRef = useRef(sentences);
  sentenceRef.current = sentences;

  // Query
  const [
    getWorkerDocument,
    { data: documentdata, loading: documentLoading, error: documentLoadError }
  ] = useLazyQuery<any>(GET_WORKER_DOCUMENT);

  const [
    getSentenceExecutionData,
    {
      data: sentenceExecutionData,
      loading: sentenceExecutionLoading,
      error: sentenceExecutionError
    }
  ] = useLazyQuery<any>(GET_SENTENCE_EXECUTION_DATA);

  // Mutation
  const [updateWorkerDocument] = useMutation<UpdateDocumentInput>(
    UPDATE_PLAYGROUND_DOCUMENT
  );

  // For self-service demo users
  const templateId = getCurrentTemplateId(email!);

  const templateBlocksData = useMemo(() => {
    return getTemplateBlocksFromId(templateId, replacerObj);
  }, [templateId]);

  useEffect(() => {
    // Remove all existing tabs if the worker is changed

    return () => {
      setEditorTabs([]);
    };
  }, [worker?.id]);

  useEffect(() => {
    if (
      documentdata?.getWorkerDocument &&
      sentenceExecutionData?.getSentenceExecutionData
    ) {
      let newIdMap = {};
      const document = documentdata?.getWorkerDocument;
      const executionDocument: SentenceExecutionData[] =
        sentenceExecutionData?.getSentenceExecutionData || [];
      const ubID: any = {};

      let lineLogSentences: Sentence[] = [];
      let execLogs: Record<string, SentenceExecutionData>[] = [];

      let keysObj: any = replacerObj;
      const editorBlocks = document.map((sentence: Sentence) => {
        const clientMetaData: any = { ...sentence.metadata } || {};

        if (isEmpty(clientMetaData)) {
          clientMetaData.lineId = shortUUID.generate();
          lineLogSentences = [
            ...lineLogSentences,
            { ...sentence, metadata: { lineId: clientMetaData.lineId } }
          ];
        } else {
          lineLogSentences = [...lineLogSentences, sentence];
        }

        clientMetaData.tabCount = getSentenceTabCount(sentence, document);

        // This is needed as in case of procedure metadata doesn't come from backend initially.
        // So we have store the data in local variable even before idMap state gets initiated
        // To know the proper ui generated parentID to create parent child relationship
        ubID[clientMetaData.lineId] = sentence.lineId;

        newIdMap = Object.assign(newIdMap, {
          [clientMetaData.lineId]: sentence.lineId
        });

        // Find the sentence in the sentenceExecutionData
        const sentenceExecution = executionDocument.find(
          (s: any) => s.lineId === sentence.lineId
        );

        if (clientMetaData.lineId) {
          execLogs = [
            ...execLogs,
            { [clientMetaData.lineId]: sentenceExecution! }
          ];
        }

        const formattedData = textFormatter(sentence.text, keysObj);
        keysObj = { ...keysObj, ...formattedData.replacerObj };

        const editorBlockChildren: ISentence = {
          uiLineId: clientMetaData.lineId,
          text: formattedData.text,
          tabCount: clientMetaData.tabCount
        };

        const { internalMetadata } = sentence;
        if (internalMetadata) {
          const originalText = internalMetadata.find(
            (imd) => imd.key === SentenceInternalMetadataKeys.ORIGINAL_TEXT
          );
          if (originalText) {
            editorBlockChildren.originalText = originalText.value;
          }
        }

        if (sentenceExecution) {
          editorBlockChildren.status = sentenceExecution.status;
          editorBlockChildren.requests = sentenceExecution.requests;
          editorBlockChildren.concepts = sentenceExecution.concepts;
          editorBlockChildren.token = sentenceExecution.token;
          editorBlockChildren.answer = sentenceExecution.answer;
          editorBlockChildren.epoch = sentenceExecution.epoch;
          editorBlockChildren.subDocuments = sentenceExecution.subDocuments;
          editorBlockChildren.miniPlaygrounds =
            sentenceExecution.miniPlaygrounds;

          if (
            sentenceExecution?.iterationTokens &&
            sentenceExecution?.iterationTokens?.length > 0
          ) {
            editorBlockChildren.loopResultCount =
              sentenceExecution?.iterationTokens?.length;
            const d = {
              [clientMetaData.lineId]: {
                currentLoopIndex: sentenceExecution.iterationTokens.length - 1,
                currentLoopToken:
                  sentenceExecution.iterationTokens[
                    sentenceExecution.iterationTokens.length - 1
                  ],
                totalLoopCount: sentenceExecution.iterationTokens.length - 1,
                loopResults: sentenceExecution.iterationTokens
              }
            };
            const oldLoopManager = client.readQuery({
              query: GET_LOOP_MANAGER
            });
            client.writeQuery({
              query: GET_LOOP_MANAGER,
              data: {
                getLoopManager: {
                  data: {
                    ...oldLoopManager?.getLoopManager?.data,
                    ...d
                  }
                }
              }
            });
          } else {
            if (sentenceExecution?.token === '/0') {
              editorBlockChildren.answer = sentenceExecution?.answer;
              editorBlockChildren.concepts = sentenceExecution?.concepts;
              editorBlockChildren.requests = sentenceExecution?.requests;
            } else {
              // TODO: Need to check for loop result token
              editorBlockChildren.editorAnswers = {
                ...editorBlockChildren.editorAnswers,
                [sentenceExecution.token]: sentenceExecution.answer
              };
              editorBlockChildren.editorConcepts = {
                ...editorBlockChildren.editorConcepts,
                [sentenceExecution.token]: sentenceExecution.concepts
              };
              editorBlockChildren.editorRequests = {
                ...editorBlockChildren.editorRequests,
                [sentenceExecution.token]: sentenceExecution.requests
              };
              if (sentenceExecution.subDocuments) {
                editorBlockChildren.editorSubDocuments = {
                  ...editorBlockChildren.editorSubDocuments,
                  [sentenceExecution.token]: sentenceExecution.subDocuments
                };
              }
            }
          }
        }

        return {
          type: 'EditorLine',
          children: [
            {
              ...editorBlockChildren
            }
          ]
        };
      });

      if (editorBlocks.length > 0) {
        setEditorBlocks(editorBlocks);
      } else if (templateId && templateBlocksData.blocks) {
        setEditorBlocks(templateBlocksData.blocks);
        setReplacerObj(templateBlocksData.replacerObj);
      } else {
        const firstLine = {
          type: 'EditorLine',
          children: [
            {
              uiLineId: shortUUID.generate(),
              text: '',
              tabCount: 0
            }
          ]
        };
        setEditorBlocks([firstLine]);
      }
      if (isEmpty(keysObj)) {
        // Added to initiate updateDocumentWorker, else replacerObj remains empty for simple documentline
        setReplacerObj({ ...WIDGET_COUNT_INIT_STATE });
      } else {
        setReplacerObj((prev) => ({ ...prev, ...keysObj }));
      }
      setIdMap(newIdMap);
      constructDocumentLineLogs(client, lineLogSentences);
      constructDocumentExecLogs(client, execLogs, debugMode);
    }
  }, [documentdata, sentenceExecutionData]);

  const validateIndentation = () => {
    // Show errror only for support user

    if (!AppUtil.isSupportUser(username)) return true;

    if (!editorValue || editorValue.length === 0) {
      return false;
    }

    const errorRecords: EditorErrorRecord[] = indentValidator(
      editorValue as EditorBlock[]
    );

    if (errorRecords.length > 0) {
      const errors: Record<string, string> = {};
      errorRecords.forEach((error) => {
        errors[error.lineNumber] = error.errorMessage;
      });
      setEditorErrors(errors);
      return false;
    }

    setEditorErrors({});
    return true;
  };

  useEffect(() => {
    if (editorValue.length > 0) {
      setCollapsedCodeBlocks(
        getCollapsedCodeBlocks(editorValue as unknown as EditorBlock[])
      );

      validateIndentation();
    }
  }, [editorValue]);

  useEffect(() => {
    const workerMap = client.readQuery({
      query: GET_WORKER_MAP
    });
    const map = workerMap?.getWorkerMap || {};
    const newIdMap = { ...map.idMap, ...idMap };
    const newReverseIdMap = { ...map.reverseIdMap, ...invert(idMap) };

    client.writeQuery({
      query: GET_WORKER_MAP,
      data: {
        getWorkerMap: {
          idMap: newIdMap,
          reverseIdMap: newReverseIdMap
        }
      }
    });
  }, [idMap]);

  useEffect(() => {
    if (activeBlock) {
      // check if activeBlock is present and editor content is updated for the specific block
      // No need to update the error state as it will automatically be updated in sometime, via subscription
      const findItem = editorValue?.find((i) => {
        return (
          // @ts-ignore
          i.children[0].uiLineId === activeBlock?.uiLineId &&
          // @ts-ignore
          i.children[0].text === activeBlock?.text
        );
      });
      if (findItem && source !== EditorSourceType.EXCEPTION_CENTRE_RUN) {
        updateEditorStatus({ showOutputPanel: true });
      } else {
        updateEditorStatus({ showOutputPanel: false });
      }
    } else {
      updateEditorStatus({ showOutputPanel: false });
    }
  }, [activeBlock, editorValue]);

  useEffect(() => {
    constructDocumentLineLogs(client, documentdata?.getWorkerDocument);
    const data = Object.keys(idMap).map((id) => {
      const data = sentenceExecutionData?.getSentenceExecutionData?.filter(
        (i: { lineId: any }) => i.lineId === idMap[id]
      );
      return { [id]: data?.[0] };
    });
    constructDocumentExecLogs(client, data, debugMode);
  }, [documentdata, sentenceExecutionData, debugMode]);

  useEffect(() => {
    // If activeTab or subDocumentToken is not present in editorTabs, then change the activeTab to main
    const findIndex = editorTabs.findIndex(
      (i: any) => i.key === subDocumentToken
    );
    if (findIndex < 0) {
      setSubDocumentToken(defaultDocumentToken);
    }
  }, [editorTabs]);

  // Handler Errror
  useEffect(() => {
    if (documentLoadError) {
      message.error({ content: documentLoadError.message, duration: 2 });
    }
    if (sentenceExecutionError) {
      message.error({ content: sentenceExecutionError.message, duration: 2 });
    }
  }, [documentLoadError, sentenceExecutionError]);

  useEffect(() => {
    const intermidiateLine = editorValue?.map((st) => {
      const line = st as EditorBlock;
      const s = line.children[0];
      return {
        text: s.text,
        uiLineId: s.uiLineId,
        tabCount: s.tabCount
      };
    });
    if (!intermidiateLine) return;
    const sentenceToUpdate: DocumentLineInput[] =
      transformInputToOutput(intermidiateLine);
    setSentence(sentenceToUpdate);
  }, [editorValue]);

  const toggleDebugMode = (mode: boolean) => {
    setDebugMode(mode);
  };

  const handleTabClose = useCallback(
    (tab: string) => {
      setEditorTabs((prevEditorTabs: any) => {
        const newEditorTabs = prevEditorTabs.filter((i: any) => i.key !== tab);
        return newEditorTabs;
      });
    },
    [setEditorTabs]
  );

  const updateEditorTabs = useCallback(
    (tabName: string) => {
      setEditorTabs((prevTabs: IEditorTabs[]) => {
        if (prevTabs.length === 0) {
          return [
            {
              key: defaultDocumentToken || EditorTabsKey.MAIN,
              tab: (
                <div>
                  <Typography.Text>{tabName}</Typography.Text>
                </div>
              ),
              itemKey: '',
              name: tabName
            }
          ];
        }
        const newTabs = [...prevTabs];
        const findIndex = newTabs.findIndex(
          (i: any) => i.key === EditorTabsKey.MAIN
        );
        if (findIndex >= 0) {
          newTabs[findIndex].tab = (
            <div>
              <Typography.Text>{tabName}</Typography.Text>
            </div>
          );
        }
        return newTabs;
      });
    },
    [setEditorTabs, worker]
  );

  const handleSubProcessClick = (
    _block: ISentence,
    subDocument: SubDocument
  ) => {
    // 1. Add New Tab item if the token doesn't exists in the editorTabs
    const findIndex = editorTabs.findIndex(
      (i: any) => i.key === subDocument.documentToken
    );
    if (findIndex < 0) {
      const newEditorTabs = [
        ...editorTabs,
        {
          key: subDocument.documentToken,
          tab: (
            <div className={styles.tab}>
              <SubProcedureIcon
                width={16}
                height={16}
                fill="#9747FF"
                style={{ marginRight: 10 }}
              />
              <Typography.Text>
                {AppUtil.removeToFromName(subDocument.processName)}
              </Typography.Text>
              <CloseOutlined
                className={styles.tabClose}
                onClick={() => handleTabClose(subDocument.documentToken)}
              />
            </div>
          ),
          itemKey: '',
          name: AppUtil.removeToFromName(subDocument.processName)
        }
      ];
      setEditorTabs(newEditorTabs);
    }

    // 2. Wait for sometime & Change the default tab to the newly created tab
    setTimeout(() => {
      setSubDocumentToken(subDocument.documentToken); // TODO: can be removed as both are same as of now
    }, 100);
    // 3. This in turn make an API call with token for getWorkerDocument
  };

  const fetchWorkerData = (workerId: string) => {
    retryPromiseWithDelay({
      promiseFn: () =>
        getWorkerDocument({
          variables: {
            workerId,
            documentToken: subDocumentToken
          }
        }),
      errorPredicate: shouldRetryQueryOnCommonErrors,
      retries: 5,
      delayTime: delayExponentialBackoff
    });

    retryPromiseWithDelay({
      promiseFn: () =>
        getSentenceExecutionData({
          variables: {
            workerId,
            documentToken: subDocumentToken
          }
        }),
      errorPredicate: shouldRetryQueryOnCommonErrors,
      retries: 5,
      delayTime: delayExponentialBackoff
    });
  };

  const handleEditorTabChange = (tab: string) => {
    setSubDocumentToken(tab);
  };

  const handleAskKoncierge = (text: string) => {
    updateEditorStatus({ showChat: true, showOutputPanel: false });
    setTimeout(() => {
      const event = new CustomEvent('AskKonciergeEvent', {
        detail: text
      });
      document.dispatchEvent(event);
    }, 0);
  };

  const postUpdateTask = (
    updatedSentences: DocumentLineInput[],
    isExecuting: boolean
  ) => {
    updateEditorStatus({ isSaving: false, isExecuting });
    setOldSentences(sentences);

    // TODO: Can be moved to onWorkerUpdate event
    constructDocumentLineLogs(client, updatedSentences);
    setIdMap(constructIdMap(updatedSentences));
  };

  const playHandler = (workerId: string) => {
    if (editorStatus.readOnly) {
      return;
    }

    // Before execution, check for indentation
    if (validateIndentation()) {
      updateEditorStatus({ isSaving: true });
      const document = textDeFormatterForDocumentLine(sentences, replacerObj);
      updateWorkerDocument({
        variables: isAutoPilotSupported
          ? {
              workerId,
              document,
              shouldStartExecution: true,
              documentToken: subDocumentToken,
              useAutopilot: !!editorStatus.autoPilot
            }
          : {
              workerId,
              document,
              shouldStartExecution: true,
              documentToken: subDocumentToken
            },
        onError: (error) => {
          updateEditorStatus({ isSaving: false, isExecuting: false });
          handleSaveError(error, subDocumentToken);
        },
        onCompleted: (updatedData: any) => {
          postUpdateTask(updatedData.updateWorkerDocument.sentences, true);
          setTimeout(() => {
            updateEditorStatus({ isExecuting: false });
          }, 2000);
        }
      });
    }
  };

  const apiHandler = (
    workerId: string | null,
    lines: EditorBlock[],
    parentDocumentToken: string,
    keysObj: any,
    editorMode: boolean,
    widgetUpdated?: boolean
  ) => {
    if (editorMode) {
      return;
    }

    const hasProcedureupdated =
      !isEqual(oldSentences, sentenceRef.current) || !!widgetUpdated;

    if (
      worker &&
      AppUtil.isDocumentModel(worker) &&
      lines.length > 0 && // NOT really needed but just to be safe
      sentenceRef.current.length > 0 &&
      hasProcedureupdated
    ) {
      updateEditorStatus({ isSaving: true });
      const document = textDeFormatterForDocumentLine(
        sentenceRef.current,
        keysObj
      );
      updateWorkerDocument({
        variables: {
          workerId,
          document,
          documentToken: parentDocumentToken,
          shouldStartExecution: false
        },
        onError: (error) => {
          updateEditorStatus({ isSaving: false });
          handleSaveError(error, parentDocumentToken);
        },
        onCompleted: (updatedData: any) => {
          postUpdateTask(updatedData.updateWorkerDocument.sentences, false);
          deleteCurrentTemplateId(email!);
        }
      });
    }
  };

  const delayedCallback = React.useRef(
    debounce(
      (
        workerId: string | null,
        lines: EditorBlock[],
        parentDocumentToken: string,
        keysObj: Record<string, string>,
        editorReadOnly: boolean,
        widgetUpdated: boolean
      ) =>
        apiHandler(
          workerId,
          lines,
          parentDocumentToken,
          keysObj,
          editorReadOnly,
          widgetUpdated
        ),
      1000
    )
  ).current;

  useEffect(() => {
    delayedCallback.cancel();
  }, [subDocumentToken]);

  const handleEditorChange = (
    v: any,
    workerId: string | null,
    sdt?: any,
    ro?: any,
    wu?: boolean
  ) => {
    setEditorValue(v);
    if (workerId) {
      delayedCallback(
        workerId,
        v,
        sdt || subDocumentToken,
        ro || replacerObj,
        editorStatus.readOnly,
        !!wu
      );
    }
  };

  const toggleFold = (lineNumber: number) => {
    const newBlocks = collapsedCodeBlocks.map((block) => {
      if (block.startLine === lineNumber) {
        return { ...block, isCollapsed: !block.isCollapsed };
      }
      return block;
    });

    setCollapsedCodeBlocks(newBlocks);
  };

  useCustomCompareLayoutEffect(
    () => {
      handleEditorChange(editorValue, subDocumentToken, replacerObj, true);
    },
    [replacerObj],
    (prev, next) => {
      return isEqual(prev, next);
    }
  );

  const [widgetData, setWidgetData] = useState<IWidgetData | null>(null);

  const expandDocumentPreview = useCallback(
    (open: boolean) => {
      updateEditorStatus({ showDocument: open });
      const event = new CustomEvent(
        AppConstants.FACTS.UPDATE_EXPANDED_DOCUMENT
      );
      document.dispatchEvent(event);
    },
    [updateEditorStatus]
  );

  const value: IEditorContext = useMemo(
    () => ({
      source,
      loading: documentLoading || sentenceExecutionLoading,
      debugMode,
      editorTabs,
      editorContent: generateText(editorValue as EditorBlock[]),
      subDocumentToken,
      editorBlocks,
      replacerObj,
      editorStatus,
      activeBlock,
      activeOutputTab,
      sentences,
      editorValue,
      collapsedCodeBlocks,
      editorErrors,
      actions: {
        toggleDebugMode,
        onEditorTabClose: handleTabClose,
        updateEditorTabs,
        onSubprocedureClick: handleSubProcessClick,
        fetchWorkerData,
        updateReplacerObj: setReplacerObj,
        onPlay: playHandler,
        onEditorTabChange: handleEditorTabChange,
        onAskKoncierge: handleAskKoncierge,
        updateEditorStatus,
        updateActiveBlock: setActiveBlock,
        onEditorValueChange: handleEditorChange,
        updateActiveOutputTab: setActiveOutputTab,
        toggleFold,
        expandDocumentPreview
      },
      widgets: {
        data: widgetData,
        setWidgetData
      }
    }),
    [
      source,
      sentences,
      editorStatus,
      debugMode,
      editorValue,
      subDocumentToken,
      documentLoading,
      sentenceExecutionLoading,
      editorBlocks,
      replacerObj,
      activeBlock,
      activeOutputTab,
      collapsedCodeBlocks,
      editorErrors,
      widgetData
    ]
  );

  return (
    <KogEditorContext.Provider value={value}>
      <FactsProvider>{children}</FactsProvider>
    </KogEditorContext.Provider>
  );
};

export function useKogEditorContext() {
  return React.useContext(KogEditorContext);
}
