import { gql } from '@apollo/client';

export const GET_LOGGEDIN_USER = gql`
  query getUser($departmentId: ID!) {
    getUser(departmentId: $departmentId) {
      firstName
      lastName
      username
      company
      role
    }
  }
`;
