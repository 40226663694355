import {
  CreateKChatMessageMutation,
  CreateKChatMessageMutationVariables,
  ListKChatMessagesByWorkerQuery
} from '@/generated/API';
import Mutations from '@/graphql/Mutations';
import Queries from '@/graphql/Queries';
import { useRunCtxV2 } from '@/provider/runv2';
import { PlayCircleTwoTone, ThunderboltOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import TextArea from 'antd/es/input/TextArea';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import './ChatPage.less';
import FormattingUtil from '@/utils/FormattingUtil';
import { Button, message, Tag, Space } from 'antd';
import { client } from '@/stores/AppStore';
import Subscriptions from '@/graphql/Subscriptions';
import AppUtil from '@/utils/AppUtil';
import { useAppSelector } from '@/stores/hooks';
import { departmentQuerySelector } from '@/stores/slices/department';
import MarkdownRenderer from '../MarkdownRenderer';

export enum EStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface IHistoryItem {
  id: string;
  departmentId: string;
  workerId: string;
  message: string;
  timestamp: string;
  displayName?: string | null | undefined;
}

const KONCIERGE = 'Koncierge';

function ChatPage() {
  const [questionText, setQuestionText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [history, setHistory] = useState<IHistoryItem[]>([]);
  const [isHistoryLoaded, setIsHistoryLoaded] = useState<boolean>(false);

  const { worker } = useRunCtxV2();
  const { department } = useAppSelector(departmentQuerySelector);
  const isNewPlayground = AppUtil.isDocumentModelSupported(department);

  const [getMessageHistory] = useLazyQuery<ListKChatMessagesByWorkerQuery>(
    Queries.ListKChatMessagesByWorkerQuery()
  );

  const [submitQuestion] = useMutation<CreateKChatMessageMutation>(
    Mutations.CreateKChatMessage()
  );

  const getHistory = (error?: boolean) =>
    getMessageHistory({ variables: { workerId: worker.id } })
      .then((messageHistory) => {
        if (messageHistory.data?.listKChatMessagesByWorker?.items) {
          const items = messageHistory.data?.listKChatMessagesByWorker?.items;
          setIsHistoryLoaded(true);
          if (error) {
            setHistory([
              ...items,
              {
                id: '',
                departmentId: '',
                workerId: '',
                message: `you can reload the page anytime, I won’t forget where I am.`,
                timestamp: '',
                displayName: KONCIERGE
              }
            ]);
          } else {
            setHistory(items);
          }
        }
      })
      .finally(() => setLoading(false));

  useEffect(() => {
    if (worker?.id) {
      getHistory();
    }
  }, [worker?.id]);

  const handleCodeClick = (codeBlock: string) => {
    setLoading(true);
    const hideMessage = message.loading('Adding automation to playground', 0);
    const runAsyncCommands = async () => {
      try {
        const event = new CustomEvent('setProcedureFromChat', {
          detail: codeBlock
        });
        document.dispatchEvent(event);
        hideMessage();
        message.success('Automation added to playground');
      } catch (e: any) {
        console.error('Error while adding automation to playground', e);
        message.error(
          e.message || 'Error while adding automation to playground'
        );
      } finally {
        setLoading(false);
      }
    };

    runAsyncCommands();
  };

  useEffect(() => {
    const chatMessages: Element = document.querySelector(
      '.chat-messages'
    ) as Element;
    chatMessages.scrollTop = chatMessages.scrollHeight;
    let subscription: any = null;
    if (isHistoryLoaded && worker?.id && worker.departmentId) {
      const observer = client.subscribe({
        query: Subscriptions.OnNotifyKChatMessageUpdate(),
        variables: {
          workerId: worker.id,
          departmentId: worker.departmentId
        },
        fetchPolicy: 'network-only'
      });
      subscription = observer.subscribe({
        next(data) {
          let found = false;
          const updatedHistory = history.map((item) => {
            if (item.id === data.data.onNotifyKChatMessageUpdate.id) {
              found = true;
              return data.data.onNotifyKChatMessageUpdate;
            }
            return item;
          });
          if (!found) {
            updatedHistory.push(data.data.onNotifyKChatMessageUpdate);
          }
          setHistory(updatedHistory);
        },
        error(data) {
          console.warn('OnNotifyKChatMessageUpdate error: ', data);
        }
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [isHistoryLoaded, worker?.id, worker?.departmentId, history]);

  const onSubmitQuestion = () => {
    const variables: CreateKChatMessageMutationVariables = {
      input: {
        departmentId: worker.departmentId!,
        workerId: worker.id!,
        message: questionText
      }
    };
    setLoading(true);
    submitQuestion({ variables })
      .then((res) => {
        const items = res.data?.createKChatMessage.items;
        if (items) {
          setHistory([...history, ...items]);
        }
      })
      .catch(() => {
        getHistory(true);
      })
      .finally(() => {
        setLoading(false);
        setQuestionText('');
        setQuestionText('');
      });
  };

  const handleAskKonciergeEvent = (e: any) => {
    setQuestionText(e.detail);
  };

  useEffect(() => {
    document.addEventListener('AskKonciergeEvent', handleAskKonciergeEvent);

    return () => {
      document.removeEventListener(
        'AskKonciergeEvent',
        handleAskKonciergeEvent
      );
    };
  }, []);

  return (
    <div className="koncierge-chat">
      <div className="chat-header">
        <Space>
          Koncierge
          <Tag>BETA</Tag>
        </Space>
      </div>
      <div className="chat-messages">
        {history.map((item) => {
          // TODO: the follow is a hack. We need a way to identify these messages from the API.
          let massagedMessage = item.message;
          if (item.message.includes('?? ')) {
            massagedMessage = '✅ &nbsp; shuffling through my notes...';
          }
          massagedMessage = massagedMessage
            .replace('KOG program', 'automation')
            .replace('KOG', '');

          const codeBlock = FormattingUtil.extractCodeFromMarkdown(item.message)
            .replaceAll('kog\n', '')
            .replaceAll('KOG\n', '');
          return (
            <div key={item.id} className="message-code-blocks">
              <div
                className={classnames([
                  'message',
                  { 'chat-reply': item.displayName === KONCIERGE }
                ])}
              >
                <MarkdownRenderer>{massagedMessage}</MarkdownRenderer>
              </div>
              {codeBlock && !isNewPlayground && (
                <Button
                  disabled={loading}
                  size="small"
                  onClick={() => handleCodeClick(codeBlock)}
                  icon={<ThunderboltOutlined />}
                >
                  Try it
                </Button>
              )}
            </div>
          );
        })}
        {loading && <div className="dot-typing" />}
      </div>
      <div className="bottom-input">
        <div className="input-container">
          <TextArea
            value={questionText}
            onChange={(e) => {
              e.preventDefault();
              setQuestionText(e.currentTarget.value);
            }}
            disabled={loading}
            className="ele-input"
            autoFocus
            color="danger"
            size="large"
            onPressEnter={(e) => {
              e.preventDefault();
              if (questionText) onSubmitQuestion();
            }}
            placeholder="Describe what you would like to automate or ask me a question"
            autoSize={{
              minRows: 1,
              maxRows: 2
            }}
          />
          <div
            onClick={(e) => {
              if (loading) return;
              e.preventDefault();
              if (questionText) onSubmitQuestion();
            }}
          >
            <PlayCircleTwoTone
              disabled={loading}
              twoToneColor={loading ? 'gray' : '#661EFF'}
              className="send-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
