// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-organization-popup .ant-modal-body {
  padding-bottom: 1px;
}
.create-organization-popup .actions {
  margin-bottom: 12px;
}
.create-organization-popup .actions .ant-space {
  display: flex;
  justify-content: end;
}
.create-organization-popup .ant-divider-horizontal {
  margin: 12px 0;
}
`, "",{"version":3,"sources":["webpack://./src/popups/CreateOrganizationPopup.less"],"names":[],"mappings":"AAAA;EAEI,mBAAA;AAAJ;AAFA;EAMI,mBAAA;AADJ;AAGI;EACE,aAAA;EACA,oBAAA;AADN;AATA;EAeI,cAAA;AAHJ","sourcesContent":[".create-organization-popup {\n  .ant-modal-body {\n    padding-bottom: 1px;\n  }\n\n  .actions {\n    margin-bottom: 12px;\n\n    & .ant-space {\n      display: flex;\n      justify-content: end;\n    }\n  }\n\n  .ant-divider-horizontal {\n    margin: 12px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
