import { GetRequestQuery, Request as RequestInterface } from '@/generated/API';
import Queries from '@/graphql/Queries';
import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import RunStepInfo from '@/details/runs/items/RunStepInfo';
import HandledRequests from '@/details/runs/items/HandledRequests';
import { ISentence } from '../Editor/editorInterface';
import styles from './RequestPanel.module.less';
import { useKogEditorContext } from '../Editor/EditorProvider';

interface RequestPanelProps {
  sentence: ISentence;
}

export default function RequestPanel({ sentence }: RequestPanelProps) {
  const [requests, setRequests] = React.useState<
    RequestInterface[] | undefined
  >();

  const { source, subDocumentToken } = useKogEditorContext();

  const [getRequest, { data }] = useLazyQuery<GetRequestQuery>(
    Queries.Request()
  );

  useEffect(() => {
    if (sentence?.editorRequests) {
      const token = sentence.token!;
      const req = sentence.editorRequests[token];
      if (req && req.length > 0) {
        getRequest({
          variables: {
            id: req[req.length - 1]
          }
        });
      }
    } else if (sentence.requests?.length > 0) {
      getRequest({
        variables: {
          id: sentence.requests[sentence.requests.length - 1]
        }
      });
    }
  }, [sentence.requests]);

  useEffect(() => {
    if (data?.getRequest) {
      setRequests([data?.getRequest as RequestInterface]);
    }
  }, [data]);

  if (requests === undefined) {
    return null;
  }

  return (
    <div className={styles.requestPanel}>
      <RunStepInfo
        step={sentence}
        stepRequests={requests}
        editor={{
          source,
          subDocumentToken
        }}
      />
      <HandledRequests
        step={sentence}
        editor={{
          source,
          subDocumentToken
        }}
        stepRequests={requests}
      />
    </div>
  );
}
