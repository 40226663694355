//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Kognitos Brain Logo - Bsic version. Stripped out of advanced svg like gradients
//
import React from 'react';
import Icon from '@ant-design/icons';

function BrainLogoSvg() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 525 525"
      xmlSpace="preserve"
      width="1em"
      height="1em"
    >
      <g>
        <path
          d="M427.37,202.69c-3.65-10.11-8.27-19.89-13.73-29.19c-5.46-9.3-11.9-18.04-19.02-26.18
		c-0.86-1.05-1.79-2.02-2.72-3.01l-2.77-2.97l-1.39-1.48l-1.45-1.42l-2.91-2.83l-1.46-1.41c-0.49-0.47-1.01-0.9-1.52-1.35
		l-3.06-2.68c-2-1.83-4.16-3.47-6.28-5.16c-8.53-6.69-17.67-12.62-27.28-17.67c-9.62-5.05-19.74-9.15-30.13-12.35
		c-2.62-0.74-5.22-1.52-7.85-2.21l-7.95-1.85l-8.03-1.46l-1-0.18l-1.01-0.13l-2.02-0.26l-4.05-0.52
		c-21.63-2.3-43.68-0.86-64.72,4.64c-21.03,5.5-41.01,14.92-58.5,27.67c-8.75,6.36-16.96,13.47-24.38,21.31
		c-7.44,7.82-14.09,16.35-19.94,25.38c-11.62,18.09-19.9,38.26-23.96,59.18c0.02-0.08,0.04-0.17,0.07-0.25
		c6.98-26.01,24.13-48.29,47.62-61.58c16.12-9.12,34.75-14.32,54.61-14.32c32.3,0,62,18.43,82.86,44.8
		c4.77,6.04,8.93,12.53,12.65,19.26c43.72,78.94,88.81,89.1,103.82,90.18c3.01,0.36,6.37,0.41,10.12,0.12
		c19.44-1.52,35.31-18.21,35.32-40.66c0.02,0.54,0.06,1.08,0.08,1.62C438.07,244.43,434.63,222.92,427.37,202.69z M402.85,251.63
		c-0.28,0-0.55,0.02-0.82,0.02c-29.02,0-73.23-75.73-101.15-87.72c25.03,8.38,46.95,23.52,63.57,43.24
		c-29.95-45.66-81.7-75.84-140.52-75.84c-29.04,0-51.58,4.86-75.4,17.8c28.52-28.65,68.06-46.39,111.75-46.39
		c17.66,0,34.64,2.9,50.49,8.24c54.43,18.35,93.86,65.73,103.21,122.23C415.2,240.64,414.37,251.63,402.85,251.63z"
        />
        <path d="M434.6,299.82c0.04-0.13,0.07-0.26,0.11-0.39l-0.32,1.1C434.46,300.29,434.53,300.06,434.6,299.82z" />
        <path
          d="M371.28,358.85c-20.68,4.12-42.44,2.79-62.97-4.23l-8.44-2.88c-21.7-7.42-39.11-22.36-55.13-38.73
		c-28.18-28.82-53.72-67.15-71.64-89.58c-8.63-10.8-21.47-17.62-35.32-18.05c-0.52-0.02-1.04-0.02-1.57-0.02
		c-8.38,0-16.27,2.11-23.16,5.82c-13.33,7.18-22.2,20.44-24.37,35.33l-0.6,4.13c-1.32,11.85-1.33,23.83,0.28,35.64
		c11.64,85.5,85.18,151.4,174.17,151.4c78.21,0,144.48-50.9,167.3-121.27l4.56-15.87C425.95,330.13,401.59,352.81,371.28,358.85z
		 M327.16,401.45c-1.04,0.39-2.08,0.78-3.11,1.15c-6.2,2.24-12.24,4.15-18.12,5.73c-0.98,0.26-1.96,0.52-2.93,0.77
		c-10.69,2.71-20.85,4.37-30.51,5.14c-0.88,0.07-1.75,0.13-2.62,0.19c-2.61,0.16-5.18,0.26-7.71,0.3c-1.69,0.02-3.36,0.02-5.01-0.01
		c-5.79-0.11-11.38-0.54-16.76-1.26c-1.54-0.21-3.06-0.44-4.57-0.69c-1.51-0.25-3-0.53-4.47-0.82c-5.16-1.04-10.12-2.34-14.88-3.88
		c-4.08-1.31-8.02-2.79-11.82-4.41c-0.63-0.27-1.26-0.54-1.89-0.82c-1.25-0.56-2.49-1.13-3.7-1.71c-3.05-1.46-6-3.01-8.85-4.64
		c-1.14-0.65-2.27-1.31-3.38-1.98c-0.56-0.34-1.11-0.68-1.66-1.02c-1.1-0.68-2.18-1.38-3.24-2.08c-1.6-1.06-3.16-2.13-4.69-3.23
		c-1.02-0.73-2.03-1.47-3.02-2.21c-0.5-0.37-0.99-0.75-1.47-1.12c-0.98-0.75-1.94-1.51-2.88-2.28c-6.15-4.98-11.66-10.2-16.58-15.41
		c-3.78-4.01-7.2-8.02-10.28-11.9c-0.31-0.39-0.61-0.78-0.91-1.16c-1.2-1.54-2.35-3.07-3.44-4.56c-0.55-0.75-1.08-1.49-1.6-2.22
		c-2.33-3.29-4.39-6.42-6.18-9.3c-0.2-0.32-0.39-0.64-0.59-0.95c-2.31-3.76-4.14-7.07-5.51-9.71c-0.48-0.92-0.96-1.88-1.44-2.85
		c-0.24-0.49-0.48-0.98-0.72-1.48c-1.18-2.5-2.31-5.14-3.24-7.85c-0.74-2.17-1.36-4.39-1.79-6.62c-0.21-1.12-0.37-2.24-0.48-3.36
		c-0.1-1.12-0.15-2.24-0.13-3.35c0.11-5.8,1.48-11.02,3.77-15.53c0.42-0.82,0.86-1.62,1.34-2.39c0.48-0.77,0.98-1.53,1.51-2.25
		c0.53-0.73,1.09-1.43,1.67-2.11c0.29-0.34,0.59-0.67,0.89-1c1.51-1.63,3.18-3.11,4.95-4.41c0.32-0.24,0.65-0.47,0.98-0.69
		c13.62-9.32,32.15-6.79,43.22,5.43c12.88,14.21,27.97,34.17,60.34,59.33c1.02,0.79,2.1,1.48,3.15,2.19
		c5.26,3.58,10.52,6.8,15.73,9.7c1.04,0.58,2.08,1.14,3.12,1.7c11.42,6.08,22.54,10.62,32.81,14.01c1.87,0.62,3.7,1.19,5.51,1.74
		c1.81,0.54,3.58,1.05,5.32,1.52c1.74,0.47,3.44,0.91,5.11,1.32c22.48,5.52,37.91,5.39,37.91,5.38c-8.83,3.05-17.6,5.08-26.25,6.26
		c-2.16,0.29-4.32,0.54-6.47,0.73s-4.29,0.33-6.41,0.42c-12.77,0.55-25.2-0.62-37.05-2.98c-5.93-1.18-11.71-2.65-17.32-4.35
		c-1.25-0.38-2.48-0.77-3.71-1.17c-4.3-1.4-8.5-2.92-12.57-4.54c-0.58-0.23-1.16-0.46-1.74-0.7c-3.46-1.41-6.83-2.89-10.1-4.41
		c-0.54-0.25-1.09-0.51-1.63-0.77c-5.93-2.82-11.53-5.78-16.74-8.76c-0.47-0.27-0.94-0.54-1.41-0.81
		c-25.21-14.63-40.9-29.4-40.9-29.4c9.83,11.13,20.09,20.54,30.51,28.49c0.65,0.5,1.3,0.99,1.96,1.47c1.96,1.46,3.92,2.86,5.89,4.22
		c0.66,0.45,1.31,0.9,1.97,1.34c4.59,3.09,9.2,5.91,13.8,8.49s9.2,4.92,13.76,7.03c1.3,0.6,2.61,1.19,3.91,1.76
		c13.65,5.97,26.99,9.99,39.45,12.65c1.19,0.25,2.36,0.49,3.53,0.72s2.33,0.45,3.48,0.65c1.15,0.21,2.29,0.4,3.43,0.58
		c0.57,0.09,1.13,0.18,1.69,0.27c2.25,0.35,4.45,0.65,6.62,0.91c0.54,0.07,1.08,0.13,1.61,0.19c5.35,0.61,10.43,0.99,15.16,1.2
		c0.47,0.02,0.94,0.04,1.41,0.06c3.27,0.12,6.36,0.16,9.26,0.15c0.41,0,0.83-0.01,1.23-0.01c15.86-0.2,25.67-2.15,25.68-2.15
		C339.82,396.39,333.41,399.1,327.16,401.45z"
        />
      </g>
    </svg>
  );
}

function BrainLogo(props: { style?: React.CSSProperties }) {
  return <Icon component={BrainLogoSvg} {...props} />;
}

export default BrainLogo;
