// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer-concepts-table-viewer .view-link {
  cursor: pointer;
  color: #07a6f3;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.answer-concepts-table-viewer .view-link .anticon {
  margin-right: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AnswerConceptsTableViewer.less"],"names":[],"mappings":"AAGE;EACE,eAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AAFJ;AAII;EACE,iBAAA;AAFN","sourcesContent":["@import '@css/variables.less';\n\n.answer-concepts-table-viewer {\n  & .view-link {\n    cursor: pointer;\n    color: @color-link-blue;\n    display: flex;\n    align-items: center;\n    white-space: nowrap;\n\n    & .anticon {\n      margin-right: 2px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
