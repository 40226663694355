// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor > .cm-editor.cm-focused {
  outline: none;
}
.cm-editor {
  width: 100%;
}
.editor .cm-line::selection {
  background-color: #1890ff !important;
}
.cm-tooltip.cm-tooltip-autocomplete {
  background-color: white;
  border: 1px dashed #eee;
  border-radius: 4px;
}
.cm-tooltip.cm-tooltip-autocomplete ul li {
  white-space: pre-wrap;
}
.cm-tooltip.cm-tooltip-autocomplete ul li[aria-selected] {
  background-color: rgba(179, 173, 214, 0.205);
  color: black;
}
.cm-tooltip.cm-tooltip-autocomplete .cm-completionMatchedText {
  color: #5468ff;
  text-decoration: none;
  font-weight: bold;
}
.cm-tooltip.cm-tooltip-autocomplete .kognitos-autocomplete-option {
  background-color: white;
  font-size: 19px;
  border-bottom: 1px dashed #eee;
  font-family: 'Open Sans', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/editor/editor.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;EACE,WAAA;AAAF;AAGA;EACE,oCAAA;AADF;AAIA;EACE,uBAAA;EACA,uBAAA;EACA,kBAAA;AAFF;AADA;EAMI,qBAAA;AAFJ;AAJA;EAUI,4CAAA;EACA,YAAA;AAHJ;AARA;EAcI,cAAA;EACA,qBAAA;EACA,iBAAA;AAHJ;AAbA;EAmBI,uBAAA;EACA,eAAA;EACA,8BAAA;EACA,oCAAA;AAHJ","sourcesContent":[".editor > .cm-editor.cm-focused {\n  outline: none;\n}\n\n.cm-editor {\n  width: 100%;\n}\n\n.editor .cm-line::selection {\n  background-color: #1890ff !important;\n}\n\n.cm-tooltip.cm-tooltip-autocomplete {\n  background-color: white;\n  border: 1px dashed #eee;\n  border-radius: 4px;\n\n  ul li {\n    white-space: pre-wrap;\n  }\n\n  ul li[aria-selected] {\n    background-color: rgba(179, 173, 214, 0.205);\n    color: black;\n  }\n  .cm-completionMatchedText {\n    color: #5468ff;\n    text-decoration: none;\n    font-weight: bold;\n  }\n  .kognitos-autocomplete-option {\n    background-color: white;\n    font-size: 19px;\n    border-bottom: 1px dashed #eee;\n    font-family: 'Open Sans', sans-serif;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
