// Ref: https://github.com/fregante/indent-textarea/blob/main/index.ts
import { insert } from 'text-field-edit';

/*
# Global notes
Indent and unindent affect characters outside the selection, so the selection has to be expanded (`newSelection`) before applying the replacement regex.
The unindent selection expansion logic is a bit convoluted and I wish a genius would rewrite it more efficiently.
*/

const useEditorTabKey = () => {
  function indentSelection(element: HTMLTextAreaElement): void {
    const { selectionStart, selectionEnd, value } = element;
    const selectedText = value.slice(selectionStart, selectionEnd);
    // The first line should be indented, even if it starts with `\n`
    // The last line should only be indented if includes any character after `\n`
    const lineBreakCount = /\n/g.exec(selectedText)?.length;

    if (lineBreakCount! > 0) {
      // Select full first line to replace everything at once
      const firstLineStart = value.lastIndexOf('\n', selectionStart - 1) + 1;

      const newSelection = element.value.slice(
        firstLineStart,
        selectionEnd - 1
      );
      const indentedText = newSelection.replace(
        /^|\n/g, // Match all line starts
        '$&\u0020\u0020'
      );
      const replacementsCount = indentedText.length - newSelection.length;

      // Replace newSelection with indentedText
      element.setSelectionRange(firstLineStart, selectionEnd - 1);
      insert(element, indentedText);

      // Restore selection position, including the indentation
      element.setSelectionRange(
        selectionStart + 1,
        selectionEnd + replacementsCount
      );
    } else {
      insert(element, '\u0020\u0020');
    }
  }

  function findLineEnd(value: string, currentEnd: number): number {
    // Go to the beginning of the last line
    const lastLineStart = value.lastIndexOf('\n', currentEnd - 1) + 1;

    // There's nothing to unindent after the last cursor, so leave it as is
    if (value.charAt(lastLineStart) !== '\u0020\u0020') {
      return currentEnd;
    }

    return lastLineStart + 1; // Include the first character, which will be a tab
  }

  // The first line should always be unindented
  // The last line should only be unindented if the selection includes any characters after `\n`
  function unindentSelection(element: HTMLTextAreaElement): void {
    const { selectionStart, selectionEnd, value } = element;

    // Select the whole first line because it might contain \t
    const firstLineStart = value.lastIndexOf('\n', selectionStart - 1) + 1;
    const minimumSelectionEnd = findLineEnd(value, selectionEnd);

    const newSelection = element.value.slice(
      firstLineStart,
      minimumSelectionEnd
    );
    const indentedText = newSelection.replace(
      /(^|\n)(\u0020\u0020| {1,2})/g,
      '$1'
    );
    const replacementsCount = newSelection.length - indentedText.length;

    // Replace newSelection with indentedText
    element.setSelectionRange(firstLineStart, minimumSelectionEnd);
    insert(element, indentedText);

    // Restore selection position, including the indentation
    const firstLineIndentation = /\u0020\u0020| {1,2}/.exec(
      value.slice(firstLineStart, selectionStart)
    );

    const difference = firstLineIndentation
      ? firstLineIndentation[0]!.length
      : 0;

    const newSelectionStart = selectionStart - difference;
    element.setSelectionRange(
      selectionStart - difference,
      Math.max(newSelectionStart, selectionEnd - replacementsCount)
    );
  }

  function tabToIndentListener(event: KeyboardEvent): void {
    if (
      event.defaultPrevented ||
      event.metaKey ||
      event.altKey ||
      event.ctrlKey
    ) {
      return;
    }

    const textarea = event.target as HTMLTextAreaElement;

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        unindentSelection(textarea);
      } else {
        indentSelection(textarea);
      }

      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  type WatchableElements =
    | string
    | HTMLTextAreaElement
    | Iterable<HTMLTextAreaElement>;

  function enableTabToIndent(
    elements: WatchableElements,
    signal?: AbortSignal
  ): void {
    if (typeof elements === 'string') {
      // eslint-disable-next-line no-param-reassign
      elements = document.querySelectorAll(elements);
    } else if (elements instanceof HTMLTextAreaElement) {
      // eslint-disable-next-line no-param-reassign
      elements = [elements];
    }

    for (const element of elements) {
      element.addEventListener('keydown', tabToIndentListener, { signal });
    }
  }

  return { enableTabToIndent };
};

export default useEditorTabKey;
