import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './PortalContainer.module.less';

function PortalContainer(props: any) {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        props.hide();
      }
    };
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [ref.current]);

  const portalRoot = document.getElementById('portal-root');
  if (!portalRoot) return null;

  return ReactDOM.createPortal(
    // The first argument is what you want to render
    <div ref={ref} className={styles.portalContainer} style={props?.style}>
      {props.children}
    </div>,
    // The second argument is where you want to render it
    portalRoot
  );
}

export default PortalContainer;
