import React, { useEffect, useState } from 'react';
import { Upload, Button, Tooltip, Spin, message } from 'antd';
import {
  InboxOutlined,
  LoadingOutlined,
  UploadOutlined
} from '@ant-design/icons';
import i18n from '@utils/i18n';
import slugify from 'slugify';
import { IUploadedResponse, useS3 } from '@/hooks/useS3';

const i18nt = (s: string, d?: string) => i18n.t('Upload', s, d);

const ACCEPTED_FILE_FORMATS = [
  // PDF
  '.pdf',

  // IMAGE
  '.jpeg',
  '.jpg',
  '.png',

  // Data
  '.txt',
  '.json',
  '.yml',
  '.yaml',
  '.csv',

  // Excel sheets
  '.xlsx',
  '.xls',

  // Docx
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  // HTML
  '.html',
  '.htm',

  // Email
  '.eml',

  // ERP Document
  '.edi',

  // audio
  '.mp3',
  '.wav'
].join(',');

interface IS3FileUploadProps {
  scope: string;
  scopeId: string;
  multiple: boolean;
  wrapper?: boolean;
  children?: React.ReactNode;
  minimal?: boolean;
  onChange?: (uploadedResponses: IUploadedResponse[]) => void;
  disabled?: boolean;
  showUploadList?: boolean;
}

function S3FileUpload(props: IS3FileUploadProps) {
  const {
    scope,
    scopeId,
    minimal,
    onChange,
    multiple,
    disabled = false,
    showUploadList = false,
    wrapper = false,
    children
  } = props;

  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const { prepareMetaFiles, uploadFiles } = useS3();

  const beforeUpload = async (_file: File, fileList: File[]) => {
    setFiles(
      fileList.map(
        (file) =>
          new File([file], slugify(file.name), {
            type: file.type
          })
      )
    );
    return false;
  };

  useEffect(() => {
    (async () => {
      if (files.length > 0) {
        try {
          setLoading(true);
          const metaFiles = await prepareMetaFiles(files, scope, scopeId);
          const uploadedFilePostFields = await uploadFiles(metaFiles);

          if (onChange) {
            onChange(uploadedFilePostFields);
          }

          setLoading(false);
        } catch (e: any) {
          console.log(e);
          message.error(e?.message || 'Failed to upload file');
          setLoading(false);
        }
      }
    })();
  }, [files]);

  if (wrapper) {
    return (
      <Upload
        accept={ACCEPTED_FILE_FORMATS}
        beforeUpload={beforeUpload}
        showUploadList={false}
        disabled={loading || disabled}
        multiple={multiple}
      >
        <span>
          {children}
          <span style={{ marginLeft: 4 }}>
            {loading ? <LoadingOutlined /> : <UploadOutlined />}
          </span>
        </span>
      </Upload>
    );
  }

  if (minimal) {
    return (
      <Upload
        accept={ACCEPTED_FILE_FORMATS}
        beforeUpload={beforeUpload}
        showUploadList={false}
        disabled={loading || disabled}
        multiple={multiple}
      >
        <Tooltip title={i18nt('upload', 'Upload File')}>
          <Button
            type="link"
            icon={
              <span>{loading ? <LoadingOutlined /> : <UploadOutlined />}</span>
            }
          />
        </Tooltip>
      </Upload>
    );
  }

  return (
    <Upload.Dragger
      accept={ACCEPTED_FILE_FORMATS}
      beforeUpload={beforeUpload}
      showUploadList={showUploadList}
      disabled={loading || disabled}
      multiple={multiple}
    >
      <Spin spinning={loading}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag files to this area to upload
        </p>
      </Spin>
    </Upload.Dragger>
  );
}

export default S3FileUpload;
