// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentExceptionEditorPopup-module__documentExceptionPopup__k393l {
  top: 0;
  padding: 0;
  width: 95vw !important;
  max-width: 1200px !important;
  height: 100vh;
}
.DocumentExceptionEditorPopup-module__documentExceptionPopup__k393l .ant-modal-content {
  height: 100%;
  border-radius: 8px;
}
.DocumentExceptionEditorPopup-module__documentExceptionPopup__k393l .ant-modal-footer {
  position: absolute;
  right: 22px;
  bottom: 10px;
}
.DocumentExceptionEditorPopup-module__wrapper__d9FyX {
  display: flex;
}
.DocumentExceptionEditorPopup-module__canvasWrapper__svEUC {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
  overflow: auto;
}
.DocumentExceptionEditorPopup-module__stagingWrapper__Zt06t {
  width: 400px;
  border-left: 1px solid #f0f0f0;
  padding-left: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/popups/DocumentExceptionEditorPopup.module.less"],"names":[],"mappings":"AAAA;EACE,MAAA;EACA,UAAA;EACA,sBAAA;EACA,4BAAA;EACA,aAAA;AACF;AACE;EACE,YAAA;EACA,kBAAA;AACJ;AAGE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AADJ;AAKA;EACE,aAAA;AAHF;AAMA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,0BAAA;EACA,cAAA;AAJF;AAOA;EACE,YAAA;EAEA,8BAAA;EACA,kBAAA;AANF","sourcesContent":[".documentExceptionPopup {\n  top: 0;\n  padding: 0;\n  width: 95vw !important;\n  max-width: 1200px !important;\n  height: 100vh;\n\n  & :global(.ant-modal-content) {\n    height: 100%;\n    border-radius: 8px;\n    // background: var(--neutral-2, #fafafa);\n  }\n\n  & :global(.ant-modal-footer) {\n    position: absolute;\n    right: 22px;\n    bottom: 10px;\n  }\n}\n\n.wrapper {\n  display: flex;\n}\n\n.canvasWrapper {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  height: calc(100vh - 65px);\n  overflow: auto;\n}\n\n.stagingWrapper {\n  width: 400px;\n  // background-color: white;\n  border-left: 1px solid #f0f0f0;\n  padding-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentExceptionPopup": `DocumentExceptionEditorPopup-module__documentExceptionPopup__k393l`,
	"wrapper": `DocumentExceptionEditorPopup-module__wrapper__d9FyX`,
	"canvasWrapper": `DocumentExceptionEditorPopup-module__canvasWrapper__svEUC`,
	"stagingWrapper": `DocumentExceptionEditorPopup-module__stagingWrapper__Zt06t`
};
export default ___CSS_LOADER_EXPORT___;
