/* eslint-disable react/no-unstable-nested-components */
//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// InlineFacts is a component that replaces source concepts in text with FactSummaries
//

// 3rd party libraries
import React, { useMemo } from 'react';
import { Popover, Tag, Tooltip } from 'antd';
import FormattingUtil from '@utils/FormattingUtil';
import AppConstants from '@/utils/AppConstants';
import reactStringReplace from 'react-string-replace';
import { showPopup } from '@/stores/slices/appPopup';
import AppUtil from '@/utils/AppUtil';
import { IStepConcept } from '@/details/runs/items/LineFacts';
import MarkdownRenderer from '../MarkdownRenderer';
import './InlineFacts.less';
import { useAppDispatch } from '../../stores/hooks';
import { decodeMarkdownText } from '../editor/helper';
import FactSummaryV2 from '../playground2/FactSummaryV2';

type ConceptInfo = IStepConcept[] | null | undefined;

interface IProps {
  text: string;
  knowledgeId: string | null; // if knowledgeid is null, we'll fallkback to showing concept's name instead of fetching from api
  concepts?: ConceptInfo;
}

const generateTooltip = (
  text: string,
  concepts: ConceptInfo,
  knowledgeId: string
) => {
  if (!concepts) return text;
  const subStrToIdsMap: Record<string, string[]> = {};
  concepts?.forEach((concept) => {
    const lastPath =
      concept.lexical_path &&
      concept.lexical_path[concept.lexical_path.length - 1];

    if (lastPath && lastPath.location) {
      const subStr = text.slice(
        lastPath.location.start.position,
        lastPath.location.end.position
      );
      if (subStr.length > 0) {
        subStrToIdsMap[subStr] = concept.ids;
      }
    }
  });

  let finalStr: any = text;

  Object.keys(subStrToIdsMap).forEach((key) => {
    finalStr = reactStringReplace(finalStr, key, (match) => {
      if (subStrToIdsMap[match]) {
        return (
          <Tooltip
            color="#fffff0"
            placement="right"
            title={
              <FactSummaryV2
                knowledgeId={knowledgeId}
                factId={subStrToIdsMap[match][0]}
              />
            }
          >
            <span className="fact-highlight">{match}</span>
          </Tooltip>
        );
      }
      return match;
    });
  });

  return finalStr;
};

// Component implementation
function InlineFacts(props: IProps) {
  const { text, knowledgeId, concepts } = props;

  const dispatch = useAppDispatch();

  const textMemo = useMemo(() => {
    const regexifiedOutput = reactStringReplace(
      knowledgeId === null
        ? text
        : generateTooltip(text, concepts, knowledgeId),
      FormattingUtil.srcRegex,
      (match) => {
        const concept = AppUtil.safeParseJSON(`{${match}}`);
        if (Object.keys(concept).length === 0) {
          return match;
        }
        const { id, uuid, name } = concept;

        if (knowledgeId === null) {
          return name;
        }

        const factId = id ?? `concept/${uuid}`;

        if (!factId) {
          return match;
        }

        return (
          <FactSummaryV2 key={id} knowledgeId={knowledgeId} factId={factId} />
        );
      }
    );

    const replacedEmailHTML = reactStringReplace(
      regexifiedOutput,
      AppConstants.PATTERNS.EMAIL_HTML,
      (match) => (
        <span>
          the email html is{' '}
          <Tag
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                showPopup({
                  popupId: AppConstants.POPUPS.HTML_VIEWER,
                  popupParams: {
                    html: match,
                    title: 'Email HTML'
                  }
                })
              );
            }}
          >
            HTML
          </Tag>
        </span>
      )
    );

    const replacedEmailBody = reactStringReplace(
      replacedEmailHTML,
      AppConstants.PATTERNS.EMAIL_BODY,
      (match) => (
        <span>
          the email body is{' '}
          <Tag
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                showPopup({
                  popupId: AppConstants.POPUPS.MARKDOWN_VIEWER,
                  popupParams: {
                    text: match,
                    title: 'Email Body'
                  }
                })
              );
            }}
          >
            BODY
          </Tag>
        </span>
      )
    );

    const replacedMarkdown = reactStringReplace(
      replacedEmailBody,
      AppConstants.PATTERNS.MARKDOWN_TEXT,
      (match) => (
        <Tag
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              showPopup({
                popupId: AppConstants.POPUPS.MARKDOWN_VIEWER,
                popupParams: {
                  text: decodeMarkdownText(match)
                }
              })
            );
          }}
          // eslint-disable-next-line react/no-children-prop
          children="<#>"
        />
      )
    );

    const replacedJSON = reactStringReplace(
      replacedMarkdown,
      AppConstants.PATTERNS.JSON,
      (match) => (
        <Tag
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              showPopup({
                popupId: AppConstants.POPUPS.VIEW_OBJECT,
                popupParams: {
                  data: JSON.parse(`"{${match}}"`)
                }
              })
            );
          }}
        >
          JSON
        </Tag>
      )
    );

    const replacedS3File = reactStringReplace(
      replacedJSON,
      AppConstants.PATTERNS.S3_URI,
      (match) => {
        const s3Parts = AppUtil.getS3URIParts(`s3://${match}`)!;
        return (
          <Popover content={match}>
            <Tag
              data-cy={`tag-file-${s3Parts?.filename}`}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  showPopup({
                    popupId: AppConstants.POPUPS.VIEW_S3_FILE,
                    popupParams: {
                      title: s3Parts.filename,
                      s3Object: {
                        bucket: s3Parts.bucket,
                        key: s3Parts.key
                      }
                    }
                  })
                );
              }}
            >
              {s3Parts?.filename}
            </Tag>
          </Popover>
        );
      }
    );

    const replacedURL = reactStringReplace(
      replacedS3File,
      AppConstants.PATTERNS.HTTP_URI,
      (match) => (
        <Popover
          content={
            <div className="inline-facts-markdown">
              <MarkdownRenderer disableLineWhiteSpace>{match}</MarkdownRenderer>
            </div>
          }
        >
          <Tag data-cy={`tag-url-${match}`}>URL</Tag>
        </Popover>
      )
    );

    const replacedEmail = reactStringReplace(
      replacedURL,
      AppConstants.PATTERNS.EMAIL_URI,
      (match) => (
        <Popover content={match}>
          <Tag data-cy={`tag-email-${match}`}>{match}</Tag>
        </Popover>
      )
    );

    return replacedEmail;
  }, [text]);

  return <span className="inline-facts">{textMemo}</span>;
}

export default InlineFacts;
