// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.object-viewer .CodeMirror {
  height: 300px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ObjectViewer.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,WAAA;AAAJ","sourcesContent":[".object-viewer {\n  .CodeMirror {\n    height: 300px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
