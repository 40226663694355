import { Fact } from '@/generated/API';
import useFactV2 from '@/hooks/useFactV2';
import AppUtil from '@/utils/AppUtil';
import FormattingUtil from '@/utils/FormattingUtil';
import React from 'react';
import usePaginationState from '@/hooks/usePaginationState';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import exportFromJSON from 'export-from-json';
import { Empty } from 'antd';
import styles from './AnswerConceptsTable.module.less';
import Table from '../table/Table';
import AnswerObjectViewer from '../AnswerObjectViewer';
import ExpandIcon from '../icons/ExpandIcon';
import JsonViewer from '../JsonViewer';
import DownloadIcon from '../icons/DownloadIcon';

interface IProps {
  knowledgeId: string;
  answer?: string;
  tableRowCpt?: any;
  hideHeader?: boolean;
  expandTable?: (downloadJsonData?: { data: any; exportType: string }) => void;
  epoch?: number;
  showDebugDetails?: boolean;
  hideTable?: boolean;
}

const getFactValue = (fact: Fact) => AppUtil.parseWithoutNaN(fact.value);

function AnswerConceptsTable(props: IProps) {
  const {
    knowledgeId,
    answer,
    tableRowCpt,
    hideHeader,
    expandTable,
    epoch,
    showDebugDetails,
    hideTable
  } = props;

  let cptIds: string[] = [];
  if (answer) {
    const parsedAnswer = FormattingUtil.parseBrainValue(answer!);
    if (!parsedAnswer?.concepts && parsedAnswer?.concept) {
      const ans = parsedAnswer.concept;
      cptIds = [ans?.id];
    } else {
      parsedAnswer?.concepts.items.forEach((cpt: any) => {
        const ans = cpt ? FormattingUtil.decodeBrainValue(cpt) : {};
        cptIds.push(ans?.concept?.id);
      });
    }
  } else if (tableRowCpt) {
    cptIds = tableRowCpt.ids;
  }

  const paginationState = usePaginationState({
    totalCount: cptIds.length,
    pageSize: 10
  });

  const {
    getFactById,
    ids: currentCpts,
    loading
  } = useFactV2({
    ids: cptIds,
    knowledgeId,
    epoch,
    paginationState: {
      pgNumber: paginationState.pageNumber,
      pgSize: paginationState.pageSize
    }
  });

  const firstFact = getFactById(cptIds[0], epoch);
  // TODO: hack for concept info KOG-3728
  let firstVal;
  if (firstFact) {
    if (firstFact.value?.includes('concept info')) {
      firstVal = AppUtil.safeParseJSON(
        AppUtil.safeParseJSON(firstFact!.value)['concept info'].data
      );
    } else {
      firstVal = getFactValue(firstFact);
    }
  }
  const cols = firstVal ? Object.keys(firstVal) : [];

  const getData = (ids: string[]) =>
    ids.map((id) => {
      const fact = getFactById(id, epoch);
      if (fact) {
        const data = FormattingUtil.parseBrainValue(fact.value || '');
        if (data['concept info']) {
          const obj = AppUtil.safeParseJSON(data['concept info'].data);
          return obj;
        }
        return data;
      }
      return [];
    });

  const getColumns = () => {
    return cols.map((key: any, idx: number) => ({
      id: `${key}-object-column-${idx}`,
      dataKey: key,
      title: key,
      renderColumn: (value: any) => {
        if (!value) {
          return '-';
        }
        if (typeof value === 'object') {
          return <AnswerObjectViewer object={value} />;
        }
        return value;
      }
    }));
  };

  const tableData = getData(currentCpts);
  const tableCols = getColumns();

  const downloadData = {
    data: tableData,
    exportType: exportFromJSON.types.json
  };

  const getTable = () => (
    <div
      className={classNames([styles.answerConceptsTable])}
      data-cy="concepts-table"
    >
      {!hideHeader && (
        <span className={styles.cptsTableTitleContainer}>
          Table
          <span
            className={styles.tableExpandIcon}
            onClick={() => {
              exportFromJSON(downloadData);
            }}
          >
            <DownloadIcon className={styles.actionButton} />
          </span>
          <span
            data-cy="expand-concepts-table"
            className={styles.tableExpandIcon}
            onClick={() => {
              expandTable?.(downloadData);
            }}
          >
            <ExpandIcon className={styles.actionButton} />
          </span>
        </span>
      )}
      {cols.length > 0 ? (
        <Table
          bordered={false}
          loading={loading}
          uniqueRowId="id"
          data={tableData}
          columns={tableCols}
          pagination={
            cptIds.length > paginationState.pageSize
              ? {
                  hideTotalString: true,
                  total: cptIds.length,
                  pageSize: paginationState.pageSize,
                  onChange: (page) => {
                    paginationState.updatePageNumber(page);
                  },
                  pageNumber: paginationState.pageNumber
                }
              : undefined
          }
          components={{
            table: ({ children }: any) => {
              return (
                <table className={styles.antCustomTable}>{children}</table>
              ) as any;
            },
            header: {
              wrapper: ({ children }: any) =>
                (
                  <thead className="ant-custom-table-header-wrapper">
                    {children}
                  </thead>
                ) as any,
              row: ({ children }: any) =>
                (
                  <tr className="ant-custom-table-header-row">{children}</tr>
                ) as any,
              cell: (data: any) => {
                return (
                  <td
                    className={classNames([
                      styles.cell,
                      styles.cptsCustomTableHeaderCell
                    ])}
                  >
                    {data.children}
                  </td>
                ) as any;
              }
            },
            body: {
              wrapper: ({ children }: any) =>
                (
                  <tbody className={styles.antCustomTableBodyWrapper}>
                    {children}
                  </tbody>
                ) as any,
              row: ({ children }: any) =>
                (
                  <tr className={styles.antCustomTableBodyRow}>{children}</tr>
                ) as any,
              cell: (data: any) => {
                return (
                  <td
                    className={classNames([
                      styles.cell,
                      styles.cptsCustomTableBodyCell
                    ])}
                  >
                    {data.children}
                  </td>
                ) as any;
              }
            }
          }}
        />
      ) : (
        <Empty />
      )}
    </div>
  );

  const getJsonViewer = () => {
    if (loading) {
      return <LoadingOutlined />;
    }
    return (
      <JsonViewer
        data={{ epoch, rows: currentCpts.map((id) => getFactById(id)) }}
        name="rows"
      />
    );
  };

  return (
    <span className={styles.tableContainer}>
      {!hideTable && getTable()}
      {showDebugDetails && getJsonViewer()}
    </span>
  );
}

export default AnswerConceptsTable;
