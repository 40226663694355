import { gql } from '@apollo/client';

export const GET_WORKER = /* GraphQL */ `
  query GetWorker($id: ID!) {
    getWorker(id: $id) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      attachments
      isArchived
      features
    }
  }
`;

export const CREATE_WORKER = /* GraphQL */ `
  mutation CreateWorker($input: CreateWorkerInput!, $local: Boolean) {
    createWorker(input: $input, local: $local) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      attachments
      isArchived
      features
    }
  }
`;

export const getWorkerDependenciesV2 = /* GraphQL */ `
  query getWorkerDependencies($workerId: ID!) {
    getWorker(id: $workerId) {
      requests {
        limit
        nextToken
        items {
          id
          stepPath
          contextId
          sentenceId
          createdAt
          handledAt
          state
          worker {
            id
          }
          uiConcepts
          questions {
            id
            text
            contextId
            sentenceId
            answerTypeHint
            ... on NativeCodeErrorQuestion {
              errorType
              errorMessage
              errorTraceback
            }
            ... on ReviewConceptQuestion {
              lexicalPath
              conceptId
            }
            ... on ValueNotKnownQuestion {
              lexicalPath
              choices
              helpText
              isPlural
            }
          }
          answers {
            id
            requestId
            questionId
          }
          suggestedAnswers {
            id
            requestId
            questionId
            action {
              ... on ReplaceStepAction {
                _
                newSteps
              }
            }
          }
          contextPath {
            ctxId
            sentenceId
          }
        }
      }
    }
  }
`;

// Naming convention for Graphql queries is as per Apollo documention articles: 'SCREAMIGN_SNAKE_CASE'
export const LIST_WORKERS_BY_DEPARTMENT = /* GraphQL */ `
  query listWorkersByDepartment(
    $departmentId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listWorkersByDepartment(
      departmentId: $departmentId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        createdAt
        isArchived
        features
      }
      nextToken
    }
  }
`;

export const DUPLICATE_WORKER = gql(`
  mutation DuplicateWorker(
    $departmentId: ID!, 
    $workerId: ID!, 
    $owner: String, 
    $runWorker: Boolean = true
  ) {
    duplicateWorker(
      departmentId: $departmentId
      workerId: $workerId
      owner: $owner
      runWorker: $runWorker
    ) {
      id
      owner
      name
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      originalWorkerId
      createdAt
      state
      isArchived
      features
    }
  }
`);

export const DUPLICATE_WORKERS = gql(`
  mutation DuplicateWorkers(
    $departmentId: ID!, 
    $workerIds: [ID!]!, 
    $owner: String
    $runWorker: Boolean = true
  ) {
    duplicateWorkers(
      departmentId: $departmentId
      workerIds: $workerIds
      owner: $owner
      runWorker: $runWorker
    ) {
      id
      owner
      name
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      originalWorkerId
      createdAt
      state
      isArchived
      features
    }
  }
`);

export const UPDATE_WORKER = /* GraphQL */ gql`
  mutation updateWorker($id: ID!, $name: String) {
    updateWorker(input: { id: $id, name: $name }) {
      id
      name
    }
  }
`;

export const ARCHIVE_WORKER = /* GraphQL */ gql`
  mutation setWorkerArchived($input: ArchiveWorkerInput!) {
    setWorkerArchived(input: $input) {
      id
      name
    }
  }
`;
