import { Modal, ModalFuncProps, Spin, Typography, message } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/stores/hooks';
import { departmentQuerySelector } from '@/stores/slices/department';
import { useQuery } from '@apollo/react-hooks';
import { LIST_INTEGRATION_BY_DEPARTMENT } from '@/graphql/queries/books';
import { useRunCtxV2 } from '@/provider/runv2';
import {
  Integration,
  ListIntegrationsByDepartmentQuery,
  RequestQuestion
} from '@/generated/API';
import IconBookCard from '@/components/IconBookCard/IconBookCard';
import QuestionFilledIcon from '@/components/icons/QuestionFilledIcon';
import { hidePopup, showPopup } from '@/stores/slices/appPopup';
import AppConstants from '@/utils/AppConstants';
import { ExclamationCircleFilled } from '@ant-design/icons';
import styles from './RequestIntegration.module.less';

const { confirm } = Modal;

export interface ConfirmModal {
  destroy: any;
  update?: (
    configUpdate:
      | ModalFuncProps
      | ((prevConfig: ModalFuncProps) => ModalFuncProps)
  ) => void;
}

export const confirmPopup = (workerText: string, onOk?: any): ConfirmModal => {
  const noOp = () => {};
  return confirm({
    title: `Learning a new book requires a new to ${workerText} be created`,
    icon: <ExclamationCircleFilled />,
    okText: 'Ok',
    content: `Please create a new ${workerText}`,
    cancelButtonProps: { style: { display: 'none' } },
    onOk: onOk || noOp
  });
};

interface RequestIntegrationProps {
  question: RequestQuestion;
  missingCredential?: boolean;
}

export enum IntegrationCreateNewPopupKeys {
  createNewWorker = 'createNewWorker'
}

function RequestIntegration(props: RequestIntegrationProps) {
  const dispatch = useAppDispatch();
  const { question, missingCredential } = props;
  const { worker, procedure } = useRunCtxV2();

  const { department } = useAppSelector(departmentQuerySelector);

  const [books, setBooks] = useState<Integration[]>([]);
  const [selectedBooks, setSelectedBooks] = useState<Integration[]>([]);

  const { loading, data } = useQuery<ListIntegrationsByDepartmentQuery>(
    LIST_INTEGRATION_BY_DEPARTMENT,
    {
      variables: {
        departmentId: department?.id
      },
      onError: () => {
        message.error({
          content: 'Unable to fetch integrations'
        });
      }
    }
  );

  useEffect(() => {
    if (!loading && data?.listIntegrationsByDepartment?.items) {
      setBooks(data?.listIntegrationsByDepartment?.items);
    }
  }, [data]);

  useEffect(() => {
    // @ts-ignore
    setSelectedBooks(books.filter((i) => question.domain === i.id));
  }, [question, books]);

  const handleIntegration = () => {
    dispatch(
      hidePopup({ popupId: AppConstants.POPUPS.CREDENTIAL_INTEGRATION })
    );
    const workerText = procedure ? 'run' : 'playground';
    confirmPopup(workerText);
  };

  const handleShowIntegrateBookPopup = (currentBookId: string) => {
    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.CREDENTIAL_INTEGRATION,
        popupParams: {
          books: data?.listIntegrationsByDepartment?.items || [],
          onClose: dispatch(
            hidePopup({
              popupId: AppConstants.POPUPS.CREDENTIAL_INTEGRATION
            })
          ),
          onIntegration: handleIntegration,
          currentBook: books.find((i) => i.id === currentBookId),
          workerId: worker?.id,
          procedureId: procedure?.id
        }
      })
    );
  };

  return (
    <Spin spinning={selectedBooks.length <= 0}>
      <div className={styles.requestIntegration}>
        <div className={styles.textContainer}>
          <Icon component={QuestionFilledIcon} />
          <Typography.Text className={styles.text}>
            {missingCredential
              ? `Credential missng for ${selectedBooks?.[0]?.name} book. Do you want to add the credentials now?`
              : `Looks like You haven't learned the ${selectedBooks?.[0]?.name} book
            yet. Do you want to learn it now?`}
          </Typography.Text>
        </div>
        <div className={styles.integrationBtnContainer}>
          {selectedBooks.map((book) => (
            <IconBookCard
              key={book.id}
              book={book}
              onClick={() => handleShowIntegrateBookPopup(book.id)}
              className={styles.bookCard}
            />
          ))}
        </div>
      </div>
    </Spin>
  );
}

export default RequestIntegration;
