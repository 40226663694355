import { useState } from 'react';

/**
 * @method useMergeState - This function is used to merge multiple states of useState hooks.
 *
 * @template T - The type of the state.
 * @param initialState - Initial state of the component of type T.
 * @returns A tuple of the current state and a function to update the state.
 */
const useMergeState = <T extends {}>(
  initialState: T
): [T, (newState: Partial<T>) => void] => {
  const [state, setState] = useState<T>(initialState);
  const setMergedState = (newState: Partial<T>) =>
    setState((prevState: T) => ({ ...prevState, ...newState }));
  return [state, setMergedState];
};

export default useMergeState;
