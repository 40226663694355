import React, { useMemo } from 'react';
import { Modal } from 'antd';
import AnswerConceptsTable from '@/components/playground2/AnswerConceptsTable';
import './AnswerConceptsTablePopup.less';

interface IProps {
  title: string;
  knowledgeId: string;
  onClose: () => void;
  answer?: string;
  tableRowCpt?: any;
}

export default function AnswerConceptsTablePopup(props: IProps) {
  const { title, knowledgeId, onClose, answer, tableRowCpt } = props;

  const memoConceptsTable = useMemo(
    () => (
      <AnswerConceptsTable
        knowledgeId={knowledgeId}
        answer={answer}
        tableRowCpt={tableRowCpt}
      />
    ),
    [answer, tableRowCpt]
  );

  return (
    <Modal
      className="answer-concepts-table-modal"
      title={title}
      centered
      open
      closable
      onCancel={onClose}
      footer={null}
      maskClosable={false}
    >
      {memoConceptsTable}
    </Modal>
  );
}
