import React from 'react';
import Icon from '@ant-design/icons';

function ExpandSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M16.7734 2.03378L12.7614 2.53211C12.6214 2.54907 12.5641 2.71871 12.6617 2.81838L13.8216 3.97831L10.5666 7.23333C10.535 7.26522 10.5173 7.30827 10.5173 7.35314C10.5173 7.39801 10.535 7.44107 10.5666 7.47295L11.523 8.42932C11.5887 8.49505 11.6969 8.49505 11.7626 8.42932L15.0197 5.17217L16.1797 6.33211C16.202 6.35447 16.2303 6.37008 16.2611 6.37714C16.292 6.3842 16.3242 6.38243 16.354 6.37203C16.3839 6.36163 16.4103 6.34301 16.4301 6.31832C16.4499 6.29364 16.4623 6.26387 16.4659 6.23244L16.9621 2.22251C16.9657 2.1969 16.9632 2.17083 16.9549 2.14633C16.9467 2.12183 16.9329 2.09957 16.9146 2.08129C16.8964 2.06302 16.8741 2.04922 16.8496 2.04097C16.8251 2.03273 16.799 2.03027 16.7734 2.03378ZM7.47698 10.5626C7.4451 10.531 7.40204 10.5133 7.35717 10.5133C7.3123 10.5133 7.26925 10.531 7.23736 10.5626L3.98234 13.8197L2.82241 12.6598C2.80003 12.6374 2.7718 12.6218 2.74096 12.6147C2.71011 12.6077 2.67791 12.6095 2.64802 12.6199C2.61814 12.6303 2.5918 12.6489 2.57201 12.6736C2.55222 12.6983 2.53979 12.728 2.53614 12.7595L2.03781 16.7694C2.02509 16.8797 2.11627 16.9708 2.22654 16.9581L6.23859 16.4598C6.37855 16.4428 6.4358 16.2732 6.33826 16.1735L5.17832 15.0136L8.43547 11.7564C8.5012 11.6907 8.5012 11.5826 8.43547 11.5168L7.47698 10.5626Z"
        fill="#8C8C8C"
      />
    </svg>
  );
}

export default function ExpandIcon(props: {
  style?: React.CSSProperties;
  className?: string;
}) {
  return <Icon component={ExpandSvg} {...props} />;
}
