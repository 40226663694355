import { ISentence } from '@/pages/PlaygroundV3/components/Editor/editorInterface';
import React from 'react';
import FormattingUtil, { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import RunFactRendererV2 from '@/components/playground2/RunFactRendererV2';
import { TabKeys } from '@/pages/PlaygroundV3/components/OutputPanel/OutputPanel';
import { useRunCtxV2 } from '@/provider/runv2';
import { Badge, Tooltip } from 'antd';
import { BulbFilled, LoadingOutlined } from '@ant-design/icons';
import { omit } from 'lodash';
import AppUtil from '@/utils/AppUtil';
import useLineFact, {
  FactAnswer,
  FACT_STATUS,
  prepareFactKey
} from '@/hooks/useLineFact';
import { isEmptyAnswer } from '@/pages/PlaygroundV3/components/Editor/editorUtil';

interface ILineAnswerProps {
  step: ISentence;
  onClick?: any;
}

function LineAnswer(props: ILineAnswerProps) {
  const { step, onClick } = props;
  const { worker } = useRunCtxV2();

  const parsedAnswer = step?.answer
    ? FormattingUtil.parseBrainValue(step.answer)
    : {};

  const cpt = omit(parsedAnswer?.concept, 'locations') as FactAnswer;

  const cpts = parsedAnswer?.concepts?.items?.map((i: any) =>
    // eslint-disable-next-line no-underscore-dangle
    omit(i.__concept__, 'locations')
  );

  let cptList: FactAnswer[] = [];

  if (parsedAnswer?.concept) {
    cptList.push(cpt);
  } else if (parsedAnswer?.concepts?.items?.length > 0) {
    cptList = cpts;
  }

  const hasFactType = step?.answer?.includes('fact_type');
  const hasDisplayValue = step?.answer?.includes('display_value');

  const dontFetchFacts =
    (hasFactType && hasDisplayValue) || parsedAnswer?.concepts;

  const { facts, factIds } = useLineFact({
    cptList,
    knowledgeId: worker?.knowledgeId!,
    epoch: step?.epoch,
    dontFetch: dontFetchFacts
  });

  if (!step) {
    return null;
  }

  const answer = step.answer;
  if (isEmptyAnswer(answer)) {
    return null;
  }

  if (!worker) {
    return null;
  }

  if (cptList.length === 0) {
    return (
      <Tooltip
        destroyTooltipOnHide
        title="view answer"
        color="#fff"
        overlayInnerStyle={{
          color: 'black'
        }}
      >
        <span
          onClick={(e) => {
            e.stopPropagation();
            onClick(step, TabKeys.Results, true);
          }}
        >
          <BulbFilled
            style={{
              color: '#748f9e',
              width: '16px',
              height: '16px',
              cursor: 'pointer'
            }}
          />
        </span>
      </Tooltip>
    );
  }

  const loadingIndex = factIds.findIndex(
    (id) =>
      facts?.[prepareFactKey(id, step.epoch)]?.status === FACT_STATUS.LOADING
  );
  if (loadingIndex >= 0 && !dontFetchFacts && parsedAnswer?.concept) {
    return <LoadingOutlined />;
  }

  if (parsedAnswer?.concept) {
    const fact = facts[prepareFactKey(cpt.id, step.epoch)];

    switch (fact?.fact_type) {
      case FormattedAnswerTypeV2.S3: {
        return (
          <RunFactRendererV2
            fact={fact}
            factType={{
              type: FormattedAnswerTypeV2.S3,
              answer: fact?.value || fact?.display_value
            }}
            knowledgeId={worker?.knowledgeId!}
            showIcons
            onClick={(e: any) => {
              e?.preventDefault();
              e?.stopPropagation();
              onClick(step, TabKeys.Results, true);
            }}
          />
        );
      }

      case FormattedAnswerTypeV2.TABLE: {
        return (
          <RunFactRendererV2
            fact={fact}
            factType={{
              type: FormattedAnswerTypeV2.TABLE,
              answer: fact?.display_value
            }}
            knowledgeId={worker?.knowledgeId!}
            showIcons
            onClick={() => onClick(step, TabKeys.Results, true)}
          />
        );
      }

      case FormattedAnswerTypeV2.RESULT: {
        return (
          <RunFactRendererV2
            fact={fact}
            factType={{
              type: FormattedAnswerTypeV2.RESULT,
              answer: fact?.value
            }}
            knowledgeId={worker?.knowledgeId!}
            showIcons
            onClick={() => onClick(step, TabKeys.Results, true)}
          />
        );
      }

      case FormattedAnswerTypeV2.JSON:
      case FormattedAnswerTypeV2.TABLES_ROW: {
        const text =
          fact?.fact_type === FormattedAnswerTypeV2.JSON
            ? 'view json'
            : 'view table';
        return (
          <RunFactRendererV2
            fact={fact}
            factType={{
              type: FormattedAnswerTypeV2.TABLES_ROW,
              answer: fact?.display_value
            }}
            knowledgeId={worker?.knowledgeId!}
            showIcons
            title={text}
            stepAnswer={answer!}
            onClick={() => onClick(step, TabKeys.Results, true)}
          />
        );
      }

      case FormattedAnswerTypeV2.WORKER: {
        return (
          <RunFactRendererV2
            fact={fact}
            factType={{
              type: FormattedAnswerTypeV2.WORKER,
              answer: AppUtil.safeParseJSON(fact?.display_value, true)
            }}
            knowledgeId={worker?.knowledgeId!}
            onClick={() => onClick(step, TabKeys.Results, true)}
          />
        );
      }

      case FormattedAnswerTypeV2.GEO_JSON_DICT: {
        return (
          <RunFactRendererV2
            fact={fact}
            factType={{
              type: FormattedAnswerTypeV2.GEO_JSON_DICT,
              answer: AppUtil.safeParseJSON(fact?.display_value, true)
            }}
            showIcons
            knowledgeId={worker?.knowledgeId!}
            onClick={() => onClick(step, TabKeys.Results, true)}
          />
        );
      }

      default: {
        if (
          (fact?.fact_type === FormattedAnswerTypeV2.STRING ||
            fact?.fact_type === FormattedAnswerTypeV2.NUMBER ||
            fact?.fact_type === FormattedAnswerTypeV2.BOOLEAN) &&
          fact?.value &&
          typeof fact.value === 'string' &&
          !fact.value.includes('__large_value_') &&
          fact.value.length < 20
        ) {
          return (
            <RunFactRendererV2
              fact={omit(fact, 'locations') as FactAnswer}
              factType={{
                type: FormattedAnswerTypeV2.STRING,
                answer: fact?.value || '-'
              }}
              knowledgeId={worker?.knowledgeId!}
              onClick={() => onClick(step, TabKeys.Results, true)}
            />
          );
        }
        return (
          <Tooltip
            destroyTooltipOnHide
            title="view answer"
            color="#fff"
            overlayInnerStyle={{
              color: 'black'
            }}
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                onClick(step, TabKeys.Results, true);
              }}
            >
              <BulbFilled
                style={{
                  color: '#748f9e',
                  width: '16px',
                  height: '16px',
                  cursor: 'pointer'
                }}
              />
            </span>
          </Tooltip>
        );
      }
    }
  }

  const allFacts = cpts?.map(
    (cpt: FactAnswer) => facts?.[prepareFactKey(cpt.id, step.epoch)]
  );

  const areAllFactsTables = !allFacts?.find(
    (c: FactAnswer) => c?.fact_type !== FormattedAnswerTypeV2.TABLE
  );

  if (areAllFactsTables) {
    return (
      <Badge
        color="#99B3B8"
        count={allFacts?.length > 1 ? allFacts?.length : 0}
        size="small"
      >
        <RunFactRendererV2
          fact={allFacts?.[0]}
          factType={{
            type: FormattedAnswerTypeV2.TABLE,
            answer: allFacts?.map((i: FactAnswer) => i?.display_value)
          }}
          knowledgeId={worker?.knowledgeId!}
          showIcons
          onClick={() => onClick(step, TabKeys.Results, true)}
        />
      </Badge>
    );
  }

  const areAllFactsTablesRows = !allFacts?.find(
    (c: FactAnswer) => c?.fact_type !== FormattedAnswerTypeV2.TABLES_ROW
  );
  if (areAllFactsTablesRows) {
    return (
      <RunFactRendererV2
        fact={allFacts?.[0]}
        factType={{
          type: FormattedAnswerTypeV2.TABLES_ROW,
          answer: allFacts?.[0]?.display_value
        }}
        knowledgeId={worker?.knowledgeId!}
        showIcons
        title="view table"
        tablesRowDataNewFactsModel={allFacts}
        onClick={() => onClick(step, TabKeys.Results, true)}
      />
    );
  }

  const areAllFactsJson = !allFacts?.find(
    (c: FactAnswer) => c?.fact_type !== FormattedAnswerTypeV2.JSON
  );
  if (areAllFactsJson) {
    return (
      <RunFactRendererV2
        fact={allFacts?.[0]}
        factType={{
          type: FormattedAnswerTypeV2.TABLES_ROW,
          answer: allFacts?.[0]?.display_value
        }}
        knowledgeId={worker?.knowledgeId!}
        showIcons
        title="view json"
        tablesRowDataNewFactsModel={allFacts}
        onClick={() => onClick(step, TabKeys.Results, true)}
      />
    );
  }

  const text = `view ${allFacts.length} ${parsedAnswer?.concepts?.name}`;
  return (
    <Tooltip
      destroyTooltipOnHide
      title={text}
      color="#fff"
      overlayInnerStyle={{
        color: 'black'
      }}
    >
      <span
        onClick={(e) => {
          e.stopPropagation();
          onClick(step, TabKeys.Results, true);
        }}
        data-cy="allFacts-bulb"
        style={{ cursor: 'pointer' }}
      >
        <Badge
          color="#99B3B8"
          count={allFacts?.length > 1 ? allFacts?.length : 0}
          size="small"
        >
          <BulbFilled
            style={{ color: '#748f9e', width: '16px', height: '16px' }}
          />
        </Badge>
      </span>
    </Tooltip>
  );
}

export default LineAnswer;
