import React, { useMemo } from 'react';
import { Fact } from '@/generated/API';
import reactStringReplace from 'react-string-replace';
import FormattingUtil, { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import AppUtil from '@/utils/AppUtil';
import { range } from 'lodash/fp';
import { useAppDispatch } from '@/stores/hooks';
import './FactCitation.less';
import { showPopup } from '@/stores/slices/appPopup';
import AppConstants from '@/utils/AppConstants';
import { useApolloClient } from '@apollo/client';
import { GET_LOCAL_DOCUMENT_PREVIEW } from '@/graphql/queries/playground';
import { useS3 } from '@/hooks/useS3';
import { LoadingOutlined } from '@ant-design/icons';
import useLineFact, {
  FactAnswer,
  FACT_STATUS,
  prepareFactKey
} from '@/hooks/useLineFact';

interface IProps {
  fact: Fact;
  factType: {
    type: FormattedAnswerTypeV2;
    answer: any;
  };
  knowledgeId: string | null;
  expandDocumentPreview?: (open: boolean) => void;
}

function FactCitation(props: IProps) {
  const { fact, factType, knowledgeId, expandDocumentPreview } = props;
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const s3 = useS3();

  const cptList: FactAnswer[] = [];
  reactStringReplace(fact.value || '', FormattingUtil.srcRegex, (match) => {
    let matchJson = AppUtil.safeParseJSON(
      AppUtil.safeParseJSON(`"{${match}}"`)
    );
    // Hack to check for proper parenthesis
    if (Object.keys(matchJson).length === 0) {
      matchJson = AppUtil.safeParseJSON(
        AppUtil.safeParseJSON(`"{${match.replaceAll('"', '\\"')}}"`)
      );
    }
    cptList.push({ id: matchJson.id, status: FACT_STATUS.INIT } as FactAnswer);
    return '';
  });

  const { facts } = useLineFact({
    cptList,
    knowledgeId
  });

  const getS3Obj = async (s3Object: {
    bucket?: string;
    key: any;
    filename?: string;
  }) => {
    const updatedPresignedUrl = await s3.getS3PresignedUrl(s3Object.key);
    if (updatedPresignedUrl) {
      const urlObject = await s3.getSignedUrlObject(updatedPresignedUrl.url);
      if (urlObject) {
        return urlObject;
      }
    }
    return null;
  };

  const text = reactStringReplace(
    factType.answer,
    FormattingUtil.srcRegex,
    (match) => {
      const matchJson = AppUtil.safeParseJSON(`{${match}}`);
      const fact = facts[prepareFactKey(matchJson.id)];
      // TODO: Fix useMemo cannot be call inside callback
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const memoList = useMemo(() => {
        if (!fact) {
          return <LoadingOutlined />;
        }

        if (!fact?.locations?.[0]) {
          return null;
        }

        return fact.locations!.map((factLocation: string) => {
          const [pageConfig, locationConfig] =
            AppUtil.safeParseJSON(factLocation);

          if (!pageConfig || !locationConfig) {
            return null;
          }

          const { page } = pageConfig;

          // `page` in the updated location schema has an object. The following logic is for backward compatibility.
          const locationPages =
            typeof page === 'object'
              ? [...range(page.start)(page.end), page.end]
              : [page];

          const { location } = locationConfig;
          const pages = locationPages.map((p) => p + 1);
          const parentFact = fact?.parentFactId
            ? facts[prepareFactKey(fact.parentFactId)]
            : null;

          let s3Parts: any;
          if (parentFact?.value) {
            const parsedValue = FormattingUtil.parseBrainValue(
              parentFact.value
            );

            if (parsedValue !== null) {
              s3Parts = AppUtil.getS3URIParts(parsedValue)!;
            }
          }

          const expandPreview = () => {
            const pdfConfig = {
              pageNumbers: pages,
              highlight: {
                x: location.left * 100,
                y: location.top * 100,
                height: (location.bottom - location.top) * 100,
                width: (location.right - location.left) * 100
              },
              allowViewTypeChange: false
            };
            if (expandDocumentPreview) {
              client.writeQuery({
                query: GET_LOCAL_DOCUMENT_PREVIEW,
                data: {
                  getLocalDocumentPreview: {
                    data: {
                      type: FormattedAnswerTypeV2.S3,
                      title: s3Parts.filename,
                      s3Object: {
                        bucket: s3Parts.bucket,
                        key: s3Parts.key,
                        downloadUrl: s3Parts.key ? getS3Obj(s3Parts) : ''
                      },
                      pdfConfig
                    }
                  }
                }
              });
              expandDocumentPreview?.(true);
            } else {
              dispatch(
                showPopup({
                  popupId: AppConstants.POPUPS.VIEW_S3_FILE,
                  popupParams: {
                    title: s3Parts.filename,
                    s3Object: {
                      bucket: s3Parts.bucket,
                      key: s3Parts.key
                    },
                    pdfConfig
                  }
                })
              );
            }
          };

          return (
            <span
              key={factLocation}
              className="fact-citation"
              onClick={() => {
                expandPreview();
              }}
            >{`[${matchJson.value}]`}</span>
          );
        });
      }, [fact?.locations]);

      return <span>{memoList}</span>;
    }
  );
  return <div className="src-concept-container">{text}</div>;
}

export default FactCitation;
