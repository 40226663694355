//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Component to show graphql errors.
//

// 3rd party libraries
import React from 'react';
import { Alert } from 'antd';

// Component CSS
import './GraphQLErrors.less';

interface IProps {
  errors: any;
}

function GraphQLErrors({ errors }: IProps) {
  if (!errors) {
    return null;
  }

  return (
    <div className="graphql-errors">
      {errors.graphQLErrors.map((value: any, i: number) => (
        <Alert
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          message={value.message}
          type="error"
        />
      ))}
    </div>
  );
}

export default GraphQLErrors;
