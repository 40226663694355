import React from 'react';
import Loader from './Loader';

// Component CSS
import './AuthLoading.less';

// interface IAuthLoadingProps {}

function AuthLoading() {
  return (
    <div className="auth-loading">
      <Loader message="Verifying authentication..." />
    </div>
  );
}

export default AuthLoading;
