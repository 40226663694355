import React from 'react';
import Icon from '@ant-design/icons';

function TableSvg() {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.25 15.15V2.85C2.25 2.51863 2.51863 2.25 2.85 2.25H15.15C15.4814 2.25 15.75 2.51863 15.75 2.85V15.15C15.75 15.4814 15.4814 15.75 15.15 15.75H2.85C2.51863 15.75 2.25 15.4814 2.25 15.15Z"
        stroke="#748F9E"
        strokeWidth="1.5"
      />
      <path d="M2.25 12.375H15.75" stroke="#748F9E" strokeWidth="1.5" />
      <path d="M2.25 9H15.75" stroke="#748F9E" strokeWidth="1.5" />
      <path d="M15.75 5.625H2.25" stroke="#748F9E" strokeWidth="1.5" />
      <path d="M9 15.75V2.25" stroke="#748F9E" strokeWidth="1.5" />
    </svg>
  );
}

export default function TableIcon(props: {
  style?: React.CSSProperties;
  className?: string;
}) {
  return <Icon component={TableSvg} {...props} />;
}
