import * as amplitude from '@amplitude/analytics-browser';
import Amplitude from '@redux-beacon/amplitude';
import { createMiddleware, createMetaReducer } from 'redux-beacon';
import amplitudeEvents from '@/analytics/amplitude-event-definitions';
import { appConfig } from '@/AppConfig';

export interface AnalyticsGlobalProperties {
  department: string | undefined | null;
  isDemoUser: boolean;
  role: string | undefined | null;
  accountType: string | undefined | null;
  organization?: string | null;
}

// TODO: We can create a AnalyticsTracker interface and implement it for AmplitudeTracker and GoogleAnalyticsTracker
class AnalyticsTracker {
  private static globalProperties = {};

  static init = () => {
    amplitude.init(appConfig.amplitude.apiKey, undefined, {
      optOut: appConfig.amplitude.optOut,
      attribution: {
        trackPageViews: true
      }
    });
  };

  static trackEvent = (event: string, properties?: Record<string, any>) => {
    let localProperties = {};
    if (properties) {
      localProperties = { ...properties };
    }
    // Added set
    setTimeout(() => {
      amplitude.track(event, {
        ...AnalyticsTracker.globalProperties,
        ...localProperties
      });
    }, 0);
  };

  static setUserId = (userId: string) => {
    amplitude.setUserId(userId);
  };

  static setGlobalProperties = (properties: Record<string, any>) => {
    // Always reset the global properties, so that we don't have any stale properties
    AnalyticsTracker.globalProperties = { ...properties };
  };
}

const target = Amplitude({ instance: amplitude });
export default AnalyticsTracker;

export const setAnalyticsUserId = (userId: string) => {
  AnalyticsTracker.setUserId(userId);
};

export const trackEvent = (event: string, properties?: Record<string, any>) => {
  AnalyticsTracker.trackEvent(event, properties);
};

export const amplitudeMiddleware = createMiddleware(amplitudeEvents, target);
export const amplitudeMetaReducer = createMetaReducer(amplitudeEvents, target);
