const VERSION = `V1`;
const LOCAL_KEY = `DOC-EXCEPTION-USAGE_COUNT__${VERSION}`;

export const getDocExceptionUsageCount = (): number => {
  const storageValue = localStorage.getItem(LOCAL_KEY);
  return storageValue ? parseInt(storageValue, 10) : 0;
};

const setDocExceptionUsageCount = (value: number): void => {
  localStorage.setItem(LOCAL_KEY, `${value}`);
};

export const incrementDocExceptionUsageCount = (): void => {
  const count = getDocExceptionUsageCount();
  setDocExceptionUsageCount(count + 1);
};
