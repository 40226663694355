import React from 'react';
import Icon from '@ant-design/icons';

function SupportSvg(props: any) {
  const { fill } = props;
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2908 5.00012H6.30073C5.03213 5.00012 4 6.03226 4 7.30086V17.4637C4 18.7323 5.03244 19.7644 6.30073 19.7644H8.48039L8.66398 19.9599C9.69235 21.0518 10.7814 22.303 11.3802 23L14.2927 19.7643H21.2911C22.5597 19.7643 23.5919 18.7322 23.5919 17.4636V7.30073C23.5916 6.03213 22.5592 5 21.2908 5L21.2908 5.00012ZM22.6528 17.4639C22.6528 18.2149 22.0419 18.8259 21.2905 18.8259H13.8742L13.5947 19.1366L11.3932 21.5824C10.8087 20.9141 10.0629 20.0766 9.34771 19.3171L9.16413 19.1217L8.88621 18.826H6.30065C5.54958 18.826 4.93834 18.2151 4.93834 17.464L4.93824 7.3009C4.93824 6.54983 5.54919 5.93859 6.30055 5.93859H21.2906C22.0417 5.93859 22.6529 6.54955 22.6529 7.3009V17.4637L22.6528 17.4639Z"
        fill={fill || '#303048'}
      />
      <path
        d="M13.4815 15.0801C13.1494 15.0801 12.873 15.1778 12.653 15.373C12.4329 15.5681 12.3228 15.8112 12.3228 16.102C12.3228 16.3842 12.4329 16.6295 12.653 16.8371C12.873 17.0366 13.1491 17.1362 13.4815 17.1362C13.8137 17.1362 14.0878 17.0384 14.3038 16.8433C14.5155 16.6482 14.6217 16.401 14.6217 16.1019C14.6217 15.8071 14.5137 15.5641 14.2977 15.3729C14.0818 15.1774 13.8095 15.0801 13.4815 15.0801Z"
        fill={fill || '#303048'}
      />
      <path
        d="M15.4844 8.4394C15.2373 8.25672 14.9478 8.12288 14.6154 8.03757C14.2833 7.95226 13.9241 7.90991 13.5379 7.90991C13.1352 7.90991 12.7449 7.95753 12.3667 8.05307C11.9889 8.14862 11.6399 8.29178 11.3202 8.48286V10.3579C11.6318 10.0837 11.9617 9.87913 12.3106 9.74438C12.6596 9.60932 12.9961 9.54205 13.3197 9.54205C13.4691 9.54205 13.6051 9.56474 13.7276 9.61054C13.8502 9.65633 13.9539 9.71854 14.0392 9.79727C14.1245 9.8763 14.1899 9.97062 14.2353 10.0809C14.2811 10.191 14.3038 10.3103 14.3038 10.4391C14.3038 10.5886 14.2829 10.7245 14.2415 10.8471C14.2001 10.9697 14.1376 11.088 14.0548 11.2022C13.9717 11.3164 13.8712 11.4307 13.7526 11.5449C13.6344 11.6591 13.4962 11.7827 13.3383 11.9156C13.1888 12.0401 13.0579 12.1659 12.9458 12.2925C12.8337 12.4191 12.7403 12.5521 12.6653 12.6912C12.5907 12.8303 12.5346 12.9788 12.4973 13.1366C12.4599 13.2944 12.4412 13.4687 12.4412 13.6598C12.4412 13.7803 12.4528 13.9078 12.4755 14.043C12.4982 14.178 12.5306 14.2994 12.5719 14.4074H14.2103C14.1645 14.3368 14.1285 14.2496 14.1014 14.1456C14.0744 14.0417 14.061 13.9401 14.061 13.8402C14.061 13.6949 14.0796 13.5651 14.117 13.4509C14.1544 13.3367 14.2094 13.2287 14.282 13.1269C14.3548 13.025 14.446 12.9224 14.5561 12.8184C14.6663 12.7145 14.7939 12.6024 14.9393 12.482C15.1553 12.3033 15.3464 12.13 15.5124 11.9619C15.6785 11.7938 15.8177 11.6183 15.9298 11.4355C16.0419 11.2529 16.1272 11.0586 16.1853 10.8532C16.2435 10.6477 16.2725 10.418 16.2725 10.1647C16.2725 9.76601 16.2028 9.42457 16.064 9.14006C15.9252 8.85576 15.7315 8.62201 15.4844 8.43934L15.4844 8.4394Z"
        fill={fill || '#303048'}
      />
    </svg>
  );
}

export default function SupportIcon(props: { style?: React.CSSProperties }) {
  return <Icon component={SupportSvg} {...props} />;
}
