import React, { useEffect, useState } from 'react';
import { Modal, Button, Select, SelectProps, message, Switch } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import styles from './ProcedureExceptionRoutingPopup.module.less';
import {
  AssignmentPolicyType,
  DepartmentUser,
  ListDepartmentCollaboratorsQuery,
  Procedure,
  UpdateProcedureMutation,
  UpdateProcedureMutationVariables
} from '../generated/API';
import { LIST_DEPARTMENT_COLLABORATORS } from '../graphql/departmentCollaborators';
import { useAppSelector } from '../stores/hooks';
import { departmentQuerySelector } from '../stores/slices/department';
import { UPDATE_PROCEDURE } from '../graphql/procedure';

interface IProps {
  onClose: () => void;
  procedure: Procedure;
  onSuccess?: () => void;
}

function ProcedureExceptionRoutingPopup(props: IProps) {
  const { onClose, procedure, onSuccess } = props;

  const defaultProcedureOwner = procedure.owner;
  const defaultAssignmentPolicy = procedure.assignmentPolicy;

  const defaultAssignee = defaultAssignmentPolicy!.collaborator;

  const { department } = useAppSelector(departmentQuerySelector);

  const [assignmentPolicy, setAssignmentPolicy] =
    useState<AssignmentPolicyType>(defaultAssignmentPolicy!.policyType);

  const [assignee, setAssignee] = useState(defaultAssignee);

  const [departmentCollaborators, setDepartmentCollaborators] = useState<
    DepartmentUser[]
  >([]);

  const [fetchDepartmentCollaborators, { loading }] =
    useLazyQuery<ListDepartmentCollaboratorsQuery>(
      LIST_DEPARTMENT_COLLABORATORS,
      {
        onCompleted(data) {
          const collaborators = (data.listDepartmentCollaborators?.items ||
            []) as DepartmentUser[];
          setDepartmentCollaborators(
            collaborators.filter((c) => c.userId !== defaultProcedureOwner)
          );
        },
        onError(error) {
          console.log('list department collaborators error', error);
          message.error('Failed to load department collaborators');
        }
      }
    );

  const [updateProcedure, updateProcedureProps] =
    useMutation<UpdateProcedureMutation>(UPDATE_PROCEDURE, {
      onCompleted(data) {
        const newPolicy = data?.updateProcedure?.assignmentPolicy;
        if (newPolicy) {
          if (newPolicy.policyType === AssignmentPolicyType.PROCEDURE_OWNER) {
            message.success(
              'Process exceptions will be sent to process owner.'
            );
          } else if (
            newPolicy.policyType === AssignmentPolicyType.COLLABORATOR
          ) {
            message.success(
              `Process exceptions will be sent to ${newPolicy.collaborator}.`
            );
          }

          onClose();
          onSuccess?.();
        }
      },
      onError(error) {
        console.log('change procedure owner error', error);
        message.error('Failed to update!');
      }
    });

  useEffect(() => {
    if (
      assignmentPolicy === AssignmentPolicyType.COLLABORATOR &&
      !departmentCollaborators.length
    ) {
      fetchDepartmentCollaborators({
        variables: {
          departmentId: department.id
        }
      });
    }
  }, [assignmentPolicy]);

  const filterOptions = departmentCollaborators.map((user) => ({
    value: user.userId || '',
    label: user.userId || ''
  }));

  const handleFilterOption: SelectProps['filterOption'] = (input, option) => {
    return (option?.label as string)
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  const handleUpdateOwner = () => {
    const variables: UpdateProcedureMutationVariables = {
      input: {
        departmentId: department.id,
        knowledgeId: procedure.knowledgeId,
        id: procedure.id,
        name: procedure.name,
        text: procedure.text,
        language: procedure.language,
        assignmentPolicy: {
          policyType: assignmentPolicy,
          collaborator: assignee
        }
      }
    };

    updateProcedure({
      variables
    });
  };

  const isSubmitDisabled = () => {
    if (assignmentPolicy === AssignmentPolicyType.COLLABORATOR) {
      return assignee === defaultAssignee;
    }
    if (assignmentPolicy === AssignmentPolicyType.PROCEDURE_OWNER) {
      return assignmentPolicy === defaultAssignmentPolicy!.policyType;
    }
    return true;
  };

  const renderCollaboratorsSelect = () => {
    return (
      <div className={styles.collaboratorsWrapper}>
        <div className={styles.label}>Exceptions assignee</div>
        <Select
          loading={loading}
          disabled={loading}
          showSearch
          placeholder="Select assignee"
          optionFilterProp="children"
          filterOption={handleFilterOption}
          className={styles.select}
          options={filterOptions}
          onSelect={setAssignee}
          defaultValue={assignee}
          notFoundContent="Only users who are part of this department can be process owners."
        />
      </div>
    );
  };

  return (
    <Modal
      open
      title="Exceptions routing"
      onCancel={onClose}
      width={500}
      keyboard={false}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          type="primary"
          key="submit"
          disabled={isSubmitDisabled()}
          onClick={handleUpdateOwner}
          loading={loading || updateProcedureProps.loading}
        >
          Save
        </Button>
      ]}
    >
      <div className={styles.content}>
        <div className={styles.label}>
          Process owner: {defaultProcedureOwner}
        </div>
        <div className={styles.switchWrapper}>
          <div>Send exception notification emails to process owner</div>
          <Switch
            checked={assignmentPolicy === AssignmentPolicyType.PROCEDURE_OWNER}
            onChange={(checked) => {
              if (checked) {
                setAssignmentPolicy(AssignmentPolicyType.PROCEDURE_OWNER);
              } else {
                setAssignmentPolicy(AssignmentPolicyType.COLLABORATOR);
              }
            }}
          />
        </div>
        {assignmentPolicy === AssignmentPolicyType.COLLABORATOR &&
          renderCollaboratorsSelect()}
      </div>
    </Modal>
  );
}

export default ProcedureExceptionRoutingPopup;
